import { useState, useRef, useEffect, useMemo } from "react";
import { Card, Row, Col, Stack, Form, Modal } from "react-bootstrap";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faLoader } from "@fortawesome/pro-duotone-svg-icons";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2/dist/sweetalert2";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import domtoimage from "dom-to-image";
import loaderImage from "../assets/images/loader.gif";

const CustomerSurvey2New = () => {
    const init = useRef(false);
    const gridRef = useRef(null);
    const gridRefList = useRef(null);
    const printRef = useRef();
    const { session } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [api_url] = useState(session.hostUrlType === 'cloud_staging' ? 'ws/ws_cs2_staging.php' : 'ws/ws_cs2.php');

    const [startDate, setStartDate] = useState(moment('10/01/2024').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [project, setProject] = useState();
    const [userID, setUserID] = useState(Number(session.role_id) === 1 ? '' : session.user_id);
    const [isHide, setIsHide] = useState(Number(session.company_id) === 251 ? false : true);

    const [surveyChart, setSurveyChart] = useState([]);
    const [cs1Data, setCs1Data] = useState();
    const [responseChart, setResponseChart] = useState([]);
    const [sentChart, setSentChart] = useState([]);
    const [chartColor, setChartColor] = useState([]);
    const [respSentChart, setRespSentChart] = useState([]);
    const [respSentChartStack, setRespSentChartStack] = useState([]);
    const [nonChartRowData, setNonChartRowData] = useState([]);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalLabel, setModalLabel] = useState('');

    const [userList, setUserList] = useState([]);

    const [listColumn, setListColumn] = useState([]);
    const [listRowData, setListRowData] = useState([]);
    const containerStyleList = useMemo(() => ({ width: "100%", height: "400px", paddingTop: 0 }), []);
    const gridStyleList = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [isLoadingResp, setIsLoadingResp] = useState(false);

    const reportColDefList = useMemo(() => {
        return {
          sortable: true,
          unSortIcon: true,
          filter: false,
        };
    }, []);

    const [projectList] = useState([
        {
            label: 'Gamuda Gardens', 
            value: 'gardens', 
            companyID: 180,
            utoken: 'a04fcdaedc1a3e10695d468258e3f217',
            ctoken: '48ade9786a2d11568db7aad9657a90de'
        },
        {
            label: 'Gamuda Jadehills', 
            value: 'jadehill', 
            companyID: 190,
            utoken: '38cecfadf5b89105aa879bab98b5fff0',
            ctoken: '3f684e70f4379ce5dc998a5b1d433751'
        },
        {
            label: 'Gamuda Cove', 
            value: 'cove', 
            companyID: 200,
            utoken: '243d7203cd06bb5d2fadbdafa32e213e',
            ctoken: 'c7a341f4b174827d2f2b88a947554855'
        },
        {
            label: 'Gamuda 257', 
            value: 'twentyfive7', 
            companyID: 203,
            utoken: '38cecfadf5b89105aa879bab98b5fff0',
            ctoken: '73b6a677f19f9c9ef0ba994b35ac8ed6'
        },
        {
            label: 'Gamuda Horizon', 
            value: 'horizon', 
            companyID: 210,
            utoken: '38cecfadf5b89105aa879bab98b5fff0',
            ctoken: '9e326bbe084ccb68e4ec27dab66f7731'
        },
        {
            label: 'Gamuda Bantayan', 
            value: 'bantayan', 
            companyID: 212,
            utoken: 'b3376001d54890bc0858e9a66fd4e0fe',
            ctoken: '19dc1aa5e5767cd6049a4f0fa958d5ec'
        },
        {
            label: 'Gamuda Highpark', 
            value: 'highpark', 
            companyID: 213,
            utoken: 'ea404a7b4d4ca65745305808635ce6c3',
            ctoken: '60a32b0b9656bc7e38e25dd89d65e9d8'
        }
    ]);

    const containerStyle = useMemo(() => ({ width: "100%", height: "300px", paddingTop: 0 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [nonChartColumn] = useState([
        {
            headerName: 'Prospect Name',
            field: 'prospect_name',
            hide: Number(session.role_id) === 1 ? false : true,
            flex: 1,
            onCellClicked: function (params) {
                if (params.data) {
                    window.open(`${session.origin}/prospect/${Number(session.company_id) === 251 && Number(params.data.hq_prospect_id) > 0 ? params.data.hq_prospect_id : params.data.prospect_id}`, '_blank');
                }
            }
        },
        {
            headerName: 'Response',
            field: 'fd_value',
            flex: 1
        }
    ]);

    const getAllSurvey = () => {
        var getProj = projectList.filter(opt => Number(opt.companyID) === Number(session.company_id));

        axios.get(api_url, {
            params: {
                task: 'newcs2',
                ds: startDate,
                de: endDate,
                uid: Number(session.role_id) === 1 ? userID : session.user_id,
                project: Number(session.company_id) !== 251 ? getProj[0].value : project ? project.value : 'all',
                utoken: Number(session.company_id) !== 251 ? session.user_ac_token : project ? project.utoken : session.user_ac_token,
                ctoken: Number(session.company_id) !== 251 ? session.company_token : project ? project.ctoken : session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var totalResp = 0;
                var totalSent = 0;

                var resChartData = [];
                var sentChartData = [];
                var resSentStackChartData = [];

                Object.keys(data.project.response).forEach(key => {
                    // if (Number(data.project.response[key]) !== 0) {
                        resChartData.push({name: key, value: Number(data.project.response[key])});
                    // }
                    resSentStackChartData.push({name: key, Responded: Number(data.project.response[key]), Non_Respond: ''});
                    totalResp = totalResp + Number(data.project.response[key]);
                });

                Object.keys(data.project.sent).forEach((key, i) => {
                    // if (Number(data.project.sent[key]) !== 0) {
                        sentChartData.push({name: key, value: Number(data.project.sent[key])});
                    // }
                    resSentStackChartData[i].Non_Respond = Number(data.project.sent[key]);
                    totalSent = totalSent + Number(data.project.sent[key]);
                });

                var respSentChartData = [
                    {name: Number(session.company_id) !== 251 ? session.company_title : project ? project.label : '', Responded: data.record_count.total_responses, Non_Respond: Number(data.record_count.total_records) - Number(data.record_count.total_responses)},
                    // {name: "Responded", value: data.record_count.total_responses, color: '#ed7d31'},
                    // {name: "Non Respond", value:  Number(data.record_count.total_records) - Number(data.record_count.total_responses), color: '#0089cb'}
                ];

                resChartData = resChartData.sort((a, b) => a.name.localeCompare(b.name));
                sentChartData = sentChartData.sort((a, b) => a.name.localeCompare(b.name));

                setResponseChart(resChartData.sort((a, b) => a.name.localeCompare(b.name)));
                setSentChart(sentChartData);
                setCs1Data(data);
                setRespSentChartStack(resSentStackChartData);
                setRespSentChart(respSentChartData);

                var nonChartArr = [];
                var surveyChartNonGroupArr = [];
                var surveyChartGroupArr = [
                    {
                        label: "1. How well was the information provided to you in aiding your home purchase?",
                        index: 1,
                        isGroup: true,
                        chartData: []
                    },
                    {
                        label: "4. From your visit to our show units, how would you rate these areas?",
                        index: 4,
                        isGroup: true,
                        chartData: []
                    },
                ];

                Object.keys(data.record).forEach(key => {
                    if (Number(data.record[key].group) === 0) {
                        if (data.record[key].title.includes('2.')) {
                            surveyChartNonGroupArr.push(
                                {
                                    label: data.record[key].title,
                                    index: 2,
                                    isGroup: false,
                                    chartData: [
                                        {
                                            name: "Attractive Deals Offered & Financial_Solution",
                                            value: Number(data.record[key].field_Attractive_Deals_Offered___Financial_Solution)
                                        },
                                        {
                                            name: "Brand Reputation Quality",
                                            value: Number(data.record[key].field_Brand_Reputation_Quality)
                                        },
                                        {
                                            name: "Location of the Project",
                                            value: Number(data.record[key].field_Location_of_the_Project)
                                        },
                                        {
                                            name: "Recommended by Family Friends",
                                            value: Number(data.record[key].field_Recommended_by_Family_Friends)
                                        },
                                        {
                                            name: "Township Development",
                                            value: Number(data.record[key].field_Township_Development)
                                        },
                                        {
                                            name: "Township Elements (Medical Centre, Education Institutions, Retail outlets, Malls & Parks)",
                                            value: Number(data.record[key].field_Township_Elements__Medical_Centre__Education_Institutions__Retail_Outlets_Malls___Parks_)
                                        },
                                        {
                                            name: "Customer Experience",
                                            value: Number(data.record[key].field_Customer_Experience)
                                        }
                                    ]
                                }
                            );   
                        }else if(data.record[key].title.includes('3.')) {
                            surveyChartNonGroupArr.push(
                                {
                                    label: data.record[key].title,
                                    index: 3,
                                    isGroup: false,
                                    chartData: [
                                        {
                                            name: 'Single',
                                            value: Number(data.record[key].field_Single)
                                        },
                                        {
                                            name: 'Spouse',
                                            value: Number(data.record[key].field_Spouse)
                                        },
                                        {
                                            name: 'Spouse & Kids',
                                            value: Number(data.record[key]['field_Spouse___Kids'])
                                        },
                                        {
                                            name: 'Parents',
                                            value: Number(data.record[key].field_Parents)
                                        },
                                        {
                                            name: 'Siblings/Friends',
                                            value: Number(data.record[key].field_Siblings_Friends)
                                        },
                                    ]
                                }
                            );
                        }else if(data.record[key].title.includes('5.') || data.record[key].title.includes('6.')) {
                            surveyChartNonGroupArr.push(
                                {
                                    label: data.record[key].title,
                                    index: data.record[key].title.includes('5.') ? 5 : 6,
                                    isGroup: false,
                                    chartData: [
                                        {
                                            name: 'Yes',
                                            value: Number(data.record[key].field_Yes)
                                        },
                                        {
                                            name: 'No',
                                            value: Number(data.record[key].field_No) ? Number(data.record[key].field_No) : 0
                                        }
                                    ]
                                }
                            );
                        }
                    }else{
                        if (Number(data.record[key].group) === 1) {
                            surveyChartGroupArr[0].chartData.push(
                                {
                                    name: data.record[key].title,
                                    Excellent: data.record[key].field_Excellent,
                                    Good: data.record[key].field_Good,
                                    Satisfactory: data.record[key].field_Satisfactory,
                                    Below_Average: data.record[key].field_Below_Average,
                                    Poor: data.record[key].field_Poor
                                }
                            );
                        }else{
                            surveyChartGroupArr[1].chartData.push(
                                {
                                    name: data.record[key].title,
                                    Most_preferred: data.record[key].field_Most_preferred,
                                    Somewhat_preferred: data.record[key].field_Somewhat_preferred,
                                    Neutral: data.record[key].field_Neutral,
                                    Least_preferred: data.record[key].field_Least_preferred,
                                    Not_preferred: data.record[key].field_Not_preferred
                                }
                            );
                        }
                    }
                });

                data.record.q13.fd_value.map(data => {
                    nonChartArr.push(
                        {
                            prospect_id: data.prospect_id,
                            hq_prospect_id: data.hq_prospect_id,
                            prospect_name: data.prospect_name,
                            fd_value: data.fd_value
                        }
                    );
                });

                var newChartArr = [...surveyChartGroupArr, ...surveyChartNonGroupArr];
                newChartArr = newChartArr.sort((a, b) => a.index > b.index ? 1 : -1)

                setSurveyChart(newChartArr);
                setNonChartRowData(nonChartArr);
                setIsLoading(false);
                setIsLoading2(false);
            }else{
                var surveyChartData = [
                    {
                        label: "1. How well was the information provided to you in aiding your home purchase?",
                        index: 1,
                        isGroup: true,
                        chartData: []
                    },
                    {
                        label: "2. What do you consider the most when deciding to purchase a property?",
                        index: 2,
                        isGroup: false,
                        chartData: []
                    },
                    {
                        label: "3. Who are you currently staying with?",
                        index: 3,
                        isGroup: false,
                        chartData: []
                    },
                    {
                        label: "4. From your visit to our show units, how would you rate these areas?",
                        index: 4,
                        isGroup: true,
                        chartData: []
                    },
                    {
                        label: "5. Were you briefed on the benefits of being a GL Friends?",
                        index: 5,
                        isGroup: false,
                        chartData: []
                    },
                    {
                        label: "6. Would you recommend Gamuda Land properties to your family or friends?",
                        index: 6,
                        isGroup: false,
                        chartData: []
                    },
                ];

                setCs1Data("");
                setSurveyChart(surveyChartData);
                setNonChartRowData([]);
                setRespSentChart([]);
                setRespSentChartStack([]);
                setIsLoading(false);
                setIsLoading2(false);
            }
        });
    }

    const getRandomColor = () =>{
        var color = [];
        
        for (var i = 0; i < 10; i++) {
            color.push('#' + Math.floor(Math.random() * 16777215).toString(16));
        }

        setChartColor(color);
    }

    useEffect(() => {
        axios.get("ws/ws_user.php", {
            params: {
                task: "4a",
                utoken: Number(session.company_id) !== 251 ? session.user_ac_token : project ? project.utoken : session.user_ac_token,
                ctoken: Number(session.company_id) !== 251 ? session.company_token : project ? project.ctoken : session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((data) => {
                    return {label: data.user_name, value: data.user_id};
                })
                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }, [project]);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllSurvey();
            getRandomColor();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleHideCard = () =>{
        var val = "";

        if (Number(session.company_id) === 251) {
            val = !project ? false : true;
        }else{
            val = true;
        }

        setIsHide(val);
    }

    const truncateLabel = (label, maxLength = 20) => {
        return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
    };

    const truncateLabel2 = (label, maxLength = 15) => {
        return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
    };

    const handleModal = (type, modalLabel) => {
        if (Number(session.company_id) !== 251 || project) {
            setIsLoadingResp(true);
            var getProj = projectList.filter(opt => Number(opt.companyID) === Number(session.company_id));
            axios.get(api_url, {
                params: {
                    task: type,
                    ds: startDate,
                    de: endDate,
                    project: Number(session.company_id) !== 251 ? getProj[0].value : project ? project.value : '',
                    uid: Number(session.role_id) === 1 ? userID : session.user_id
                }
            })
            .then(res => {
                let data = res.data;

                if (Number(data.status) === 0) {
                    var column = [];

                    if (type === 'CS2_SurveySent') {
                        column.push(
                            {
                                headerName: 'Prospect Name',
                                field: 'prospect_name',
                                flex: 1,
                                onCellClicked: function(params){
                                    if (params.data) {
                                        window.open(`${session.origin}/prospect/${Number(session.company_id) === 251 && Number(params.data.hq_prospect_id) > 0 ? params.data.hq_prospect_id : params.data.prospect_id}`, '_blank');
                                    }
                                }
                            },
                            {
                                headerName: 'Sales Rep',
                                field: 'sales_person',
                                flex: 1
                            },
                            {
                                headerName: 'Date Time Create',
                                field: 'date',
                                flex: 1
                            },
                            {
                                headerName: 'Status',
                                flex: 1,
                                cellRenderer: (params) => {
                                    if (params.data) {
                                        return 'Sent';
                                    }
                                },
                                valueGetter: (params) => {
                                    if (params.data) {
                                        return 'Sent';
                                    }
                                }
                            }
                        );
                    }else if (type === 'CS2_ResponseReceived') {
                        column.push(
                            {
                                headerName: 'Prospect Name',
                                field: 'prospect_name',
                                flex: 1,
                                onCellClicked: function(params){
                                    if (params.data) {
                                        window.open(`${session.origin}/prospect/${Number(session.company_id) === 251 && Number(params.data.hq_prospect_id) > 0 ? params.data.hq_prospect_id : params.data.prospect_id}`, '_blank');
                                    }
                                }
                            },
                            {
                                headerName: 'Phone Number',
                                field: 'contact1',
                                flex: 1
                            },
                            {
                                headerName: 'Sales Rep',
                                field: 'user_name',
                                flex: 1
                            },
                            {
                                headerName: 'Date Time Created',
                                field: 'date',
                                flex: 1
                            },
                            {
                                headerName: 'SPA Date/Lot Date',
                                field: 'spaDate',
                                flex: 1
                            },
                            {
                                headerName: 'Status',
                                field: '',
                                flex: 1,
                                cellRenderer: (params) => {
                                    if (params.data) {
                                        return Number(params.data.status) === 0 ? 'Sent' : 'Responded';
                                    }
                                },
                                valueGetter: (params) => {
                                    if (params.data) {
                                        return Number(params.data.status) === 0 ? 'Sent' : 'Responded';
                                    }
                                }
                            }
                        );
                    }

                    setModalLabel(modalLabel);
                    setListColumn(column);
                    setListRowData(data.record);
                    setIsOpenModal(true);
                    setIsLoadingResp(false);     
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'No record found.'
                    });
                    setModalLabel(modalLabel);
                    setListColumn([]);
                    setListRowData([]);
                    setIsOpenModal(false);
                }
            });
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please choose a project'
            });
        }
    }

    const handleDownload = () =>{

        var params = {
            fileName : `CS2-${modalLabel.replaceAll(' ', '_')}.csv`,
            processCellCallback : function(params) {

                var res = params.value;
                // if(res && res !== undefined && res !== null && res !== '' ){
                //     res = res.replace(/<br>/g, "\r\n");
                //     res = res.replace(/<li>/g, "\r\n");
                //     res = res.replace(/<[^>]+>/g, '');
                //     res = res.replace(/&nbsp;/g, '');
                // }
                
                return res;

            }
        };

        gridRefList.current.api.exportDataAsCsv(params);
    }

    const handleDownloadNonChart = () =>{

        var params = {
            fileName : `CS2-Q7.csv`,
            processCellCallback : function(params) {

                var res = params.value;
                // if(res && res !== undefined && res !== null && res !== '' ){
                //     res = res.replace(/<br>/g, "\r\n");
                //     res = res.replace(/<li>/g, "\r\n");
                //     res = res.replace(/<[^>]+>/g, '');
                //     res = res.replace(/&nbsp;/g, '');
                // }
                
                return res;

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    const setPrint = () => {

        // domtoimage
        //   .toPng(printRef.current)
        //   .then(function (dataUrl) {
        //     const div = document.createElement('div');
        //     div.id = 'imgDiv';
        //     const link = document.createElement('img');
        //     link.src = dataUrl;
        //     link.id = "printimg";

        //     document.body.appendChild(div);
        //     document.getElementById('imgDiv').appendChild(link);

        //     var content = document.getElementById("imgDiv");
        //     var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        //     pri.document.open();
        //     pri.document.write(content.innerHTML);
        //     pri.document.close();
        //     pri.focus();
        //     pri.print();
        //     document.body.removeChild(div);
        //   })
        //   .catch(function (error) {
        //     console.error(error);
        //   });
        domtoimage
        .toPng(printRef.current)
        .then(function (dataUrl) {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = "CS2.png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    return(
        <div style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
            {isLoading ? <Loader/> :
                <div className="p-4">
                    {/* <iframe id="ifmcontentstoprint" style={{width: 0, height: 0, position: 'absolute'}}></iframe> */}
                    <div className="mb-3">
                        <Stack direction="horizontal" gap={2}>
                            {Number(session.company_id) === 251 && 
                            <Form.Group style={{width: '200px'}}>
                                <FormSelect
                                    className="shadow-sm border-0"
                                    placeholder="All Projects"
                                    options={projectList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e) => {setProject(e ? e : '')}} 
                                />
                            </Form.Group>}
                            {(project || Number(session.company_id) !== 251) && Number(session.role_id) === 1 &&
                            <Form.Group style={{width: '200px'}}>
                                <FormSelect
                                    className="shadow-sm border-0"
                                    placeholder="All Users"
                                    options={userList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e) => {setUserID(e ? e.value : '')}}
                                />
                            </Form.Group>}
                            <Form.Group className="ms-auto" style={{width: '200px'}}>
                                <Form.Control
                                    className="shadow-sm border-0"
                                    type="date"
                                    value={startDate}
                                    min={moment("2024-10-1").format('YYYY-MM-DD')}
                                    onChange={(e) => {setStartDate(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '200px'}}>
                                <Form.Control
                                    className="shadow-sm border-0"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {setEndDate(e.target.value)}}
                                />
                            </Form.Group>
                            <button 
                                type="button" 
                                className="btn op-button op-primary-color text-light"
                                onClick={() => {getAllSurvey(); setIsLoading2(true); handleHideCard();}}
                            >
                                Apply
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-light shadow-sm border"
                                onClick={setPrint}
                            >
                                <FontAwesomeIcon icon={faPrint} size="lg"/>
                            </button>
                        </Stack>
                    </div>
                    {isLoading2 ? <div><Loader/></div> :
                        <div ref={printRef}>
                            <div className="mb-4">
                                <Row sm={12} className="mb-4">
                                    {/* {Number(session.company_id) === 251 && <Col>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Total Survey Sent</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }} onClick={() => handleModal('CS2_SurveySent', 'Survey Sent')}>
                                                            {cs1Data.total_survey_sent}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>} */}
                                    <Col>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Total Response Received</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }} onClick={() => handleModal('CS2_ResponseReceived', 'Response Received')}>
                                                            {isLoadingResp ? <img src={loaderImage} width={50} height={'auto'}/> : `${cs1Data ? cs1Data.record_count.total_responses : 0}/${cs1Data ? cs1Data.record_count.total_records: 0}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* <Col sm={3}>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Card Title</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }}>
                                                            3
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3}>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Card Title</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }}>
                                                            4
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col> */}
                                </Row>
                                
                                <Row sm={12}>
                                    {/* <Col sm={6}>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Total Average Mark</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }}>
                                                            {cs1Data.record_count.total_score}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col> */}
                                    <Col>
                                        <Card className="border-0 shadow-sm w-100">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Survey Response Time (Ave Days)</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="py-2 deal-count" style={{ cursor: "pointer" }} >
                                                        <div className="text-danger" style={{ fontSize: "18px" }}>
                                                            0
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-4">
                                <Row sm={12} className="mb-2">
                                    {!isHide && 
                                    <Col sm={6}>
                                        <Card className="shadow-sm border-0 mb-2">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Survey Sent for Each Project</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                {/* <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart 
                                                        data={sentChart}
                                                        layout="vertical"
                                                        width={500}
                                                        height={300}
                                                        margin={{
                                                            top: 15,
                                                            right: 10,
                                                            left: 60,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis  type="number"/>
                                                        <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel2(value)}/>
                                                        <Tooltip />
                                                        <Bar dataKey='value' fill={'#0089cb'} />
                                                    </BarChart>
                                                </ResponsiveContainer> */}
                                                <ResponsiveContainer width="100%" height={400}>
                                                    <PieChart width={400} height={400}>
                                                    <Legend verticalAlign="top" wrapperStyle={{ left: "2%"}} />
                                                    <Pie
                                                        data={sentChart} 
                                                        dataKey="value" 
                                                        cx="50%" 
                                                        cy="50%" 
                                                        innerRadius={40} 
                                                        outerRadius={90}  
                                                        label= {({ name, value }) =>
                                                            {return value !== 0 ? `${name}: ${value} ` : ''}
                                                        }
                                                        labelLine={false}
                                                    >
                                                        {sentChart.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip/>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </Card.Body>
                                        </Card>
                                    </Col>}
                                    {!isHide && 
                                    <Col sm={6}>
                                        <Card className="shadow-sm border-0 mb-2">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Response Received for Each Project</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                {/* <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart 
                                                        data={responseChart}
                                                        layout="vertical"
                                                        width={500}
                                                        height={300}
                                                        margin={{
                                                            top: 15,
                                                            right: 10,
                                                            left: 60,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis  type="number"/>
                                                        <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel2(value)}/>
                                                        <Tooltip />
                                                        <Bar dataKey='value' fill={'#0089cb'} />
                                                    </BarChart>
                                                </ResponsiveContainer> */}
                                                <ResponsiveContainer width="100%" height={400}>
                                                    <PieChart width={400} height={400}>
                                                    <Legend verticalAlign="top" wrapperStyle={{ left: "2%"}} />
                                                    <Pie
                                                        data={responseChart} 
                                                        dataKey="value" 
                                                        cx="50%" 
                                                        cy="50%" 
                                                        innerRadius={40} 
                                                        outerRadius={90}  
                                                        label= {({ name, value }) =>
                                                            {return value !== 0 ? `${name}: ${value} ` : ''}
                                                        }
                                                        labelLine={false}
                                                    >
                                                        {responseChart.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip/>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </Card.Body>
                                        </Card>
                                    </Col>}
                                    {!isHide && 
                                    <Col sm={12}>
                                        <Card className="shadow-sm border-0 mb-2">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Survey Sent and Response Received For Each Project</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart 
                                                        data={respSentChartStack}
                                                        layout="vertical"
                                                        width={500}
                                                        height={300}
                                                        margin={{
                                                            top: 15,
                                                            right: 10,
                                                            left: 60,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis  type="number"/>
                                                        <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel2(value)}/>
                                                        <Legend/>
                                                        <Tooltip />
                                                        <Bar dataKey='Non_Respond' name="Non Respond" stackId={'stack1'} fill={'#ed7d31'} />
                                                        <Bar dataKey='Responded' name="Responded" stackId={'stack1'} fill={'#0089cb'} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </Card.Body>
                                        </Card>
                                    </Col>}
                                </Row>

                                <Row sm={12} className="mb-3">
                                    {(Number(session.company_id) !== 251 || isHide) && 
                                    <Col>
                                        <Card className="shadow-sm border-0 mb-2">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>Survey Sent and Response Received for {isHide && project ? project.label: session.company_title}</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart 
                                                        data={respSentChart}
                                                        layout="vertical"
                                                        width={500}
                                                        height={300}
                                                        margin={{
                                                            top: 15,
                                                            right: 10,
                                                            left: 60,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis  type="number"/>
                                                        <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel2(value)}/>
                                                        <Tooltip />
                                                        {/* <Bar dataKey='value' fill={'#0089cb'} /> */}
                                                        <Legend 
                                                            // payload={
                                                            //     respSentChart.map((item) => ({
                                                            //         id: item.name,
                                                            //         type: 'square',
                                                            //         value: `${item.name}`,
                                                            //         color: item.color
                                                            //     }))
                                                            // }
                                                        />
                                                        {/* <Bar dataKey='value'>
                                                            {
                                                                respSentChart.map((data) => (
                                                                    <Cell fill={data.color} />
                                                                ))
                                                            }
                                                        </Bar> */}
                                                        <Bar dataKey='Non_Respond' name="Non Respond" stackId={'stack1'} fill={'#ed7d31'} />
                                                        <Bar dataKey='Responded' name="Responded" stackId={'stack1'} fill={'#0089cb'} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                {/* <ResponsiveContainer width="100%" height={400}>
                                                    <PieChart width={400} height={400}>
                                                    <Legend verticalAlign="top" wrapperStyle={{ left: "2%"}} />
                                                    <Pie
                                                        data={respSentChart} 
                                                        dataKey="value" 
                                                        cx="50%" 
                                                        cy="50%" 
                                                        innerRadius={40} 
                                                        outerRadius={90}  
                                                        label= {({ name, value }) =>
                                                        `${name}: ${value} `
                                                        }
                                                    >
                                                        {responseChart.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip/>
                                                    </PieChart>
                                                </ResponsiveContainer> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>}
                                </Row>

                                <Row sm={12} className="pb-4">
                                    {surveyChart.length > 0 && surveyChart.map((rec, index) => (
                                        <Col key={index} sm={6}>
                                            <Card className="shadow-sm border-0 mb-2">
                                                <Card.Header>
                                                    <Card.Title as={'h6'}>{rec.label}</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {rec.isGroup && <ResponsiveContainer width="100%" height={300}>
                                                        <BarChart 
                                                            data={rec.chartData}
                                                            layout="vertical"
                                                            width={500}
                                                            height={300}
                                                            margin={{
                                                                top: 15,
                                                                right: 10,
                                                                left: 60,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis  type="number"/>
                                                            <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel(value)}/>
                                                            <Tooltip />
                                                            <Legend/>
                                                            {rec.chartData.length > 0 && Object.keys(rec.chartData[0]).map((key, i) => {
                                                                if (key !== 'name') {
                                                                    return <Bar dataKey={key} stackId="a" name={key.replaceAll("_", " ")} fill={chartColor[i % chartColor.length]} />
                                                                }
                                                            })}
                                                        </BarChart>
                                                    </ResponsiveContainer>}
                                                    {!rec.isGroup && <ResponsiveContainer width="100%" height={300}>
                                                        <BarChart 
                                                            data={rec.chartData}
                                                            layout="vertical"
                                                            width={500}
                                                            height={300}
                                                            margin={{
                                                                top: 15,
                                                                right: 10,
                                                                left: 60,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis  type="number"/>
                                                            <YAxis dataKey="name" type="category" tickFormatter={(value) => truncateLabel2(value)}/>
                                                            <Tooltip />
                                                            <Legend 
                                                                payload={
                                                                    rec.chartData.map((item, i) => ({
                                                                        id: item.name,
                                                                        type: 'square',
                                                                        value: `${item.name}`,
                                                                        color: chartColor[i % chartColor.length]
                                                                    }))
                                                                }
                                                            />
                                                            <Bar dataKey='value'>
                                                                {
                                                                    rec.chartData.map((data, i) => (
                                                                        <Cell fill={chartColor[i % chartColor.length]} />
                                                                    ))
                                                                }
                                                            </Bar>
                                                        </BarChart>
                                                    </ResponsiveContainer>}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                    
                                    <Col sm={6}>
                                        <Card className="shadow-sm border-0 mb-2">
                                            <Card.Header>
                                                <Card.Title as={'h6'}>7. Tell us more! Your input whether it's a compliment or a complaint is incredibly valuable to us.</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="mb-3">
                                                    <Stack direction="horizontal" gap={3}>
                                                        <button
                                                            className="btn btn-light shadow-sm border ms-auto"
                                                            type="button"
                                                            disabled={nonChartRowData.length > 0 ? false : true}
                                                            onClick={handleDownloadNonChart}
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} size="lg"/>
                                                        </button>
                                                    </Stack>
                                                </div>
                                                <div style={containerStyle}>
                                                    <div className={"ag-theme-quartz ag-op ag-op-secondary border rounded shadow-sm"} style={{ ...gridStyle }}>
                                                        <AgGridReact
                                                            ref={gridRef}
                                                            columnDefs={nonChartColumn}
                                                            rowData={nonChartRowData}
                                                            defaultColDef={reportColDef}
                                                            rowHeight={30}
                                                            // pagination={false}
                                                            // paginationPageSize={30}
                                                            paginationPageSizeSelector={false}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>
            }

            <Modal show={isOpenModal} onHide={setIsOpenModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as={'h6'}>{modalLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <Stack direction="horizontal" gap={1}>
                            <Form.Group className="ms-auto" style={{width: '250px'}}>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name and phone number"
                                    onChange={(e) => {
                                        gridRefList.current.api.setQuickFilter(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <button className="btn btn-light shadow-sm border" type="button" onClick={handleDownload}><FontAwesomeIcon icon={faDownload}/></button>
                        </Stack>
                    </div>
                    <div style={containerStyleList}>
                        <div className={"ag-theme-quartz ag-op ag-op-secondary border rounded shadow-sm"} style={{ ...gridStyleList }}>
                            <AgGridReact
                                ref={gridRefList}
                                columnDefs={listColumn}
                                rowData={listRowData}
                                defaultColDef={reportColDefList}
                                rowHeight={40}
                                // pagination={false}
                                // paginationPageSize={30}
                                paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default CustomerSurvey2New;