import { useState, useEffect } from "react";
import { faLinkedin, faYoutube, faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import { useAuth } from "../auth/AuthContext";
import { Col, Collapse, Container, Nav, Navbar, Row, Stack, Form, Button, Modal } from "react-bootstrap";
import { faEnvelope, faEye, faEyeSlash, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { faShieldExclamation } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import axios from "../api/axios";

const Login = () => {
  const { Formik } = formik;
  const { session, invalid, loginUser } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [remember, setRemember] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [hide, setHide] = useState(true);

  const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email("Invalid email format").required("Email is required"),
  });

  useEffect(() => {
    if (session) {
      if (session.auto_load_screen === "booking") {
        navigate("/booking");
      } else if (session.auto_load_screen === "activity") {
        navigate("/activity");
      } else if (session.auto_load_screen === "curation") {
        navigate("/curation");
      } else if (session.auto_load_screen === "user") {
        navigate("/users");
      } else if (session.auto_load_screen === "statistics") {
        navigate("/statistics");
      } else if (session.auto_load_screen === "contacts") {
        navigate("/prospect/list");
      } else if (session.auto_load_screen === "organization") {
        navigate("/organizations/list");
      } else {
        if (Number(session.open_deal_list) === 1) {
          navigate("/deal/view/list");
        } else {
          navigate("/deal/view/pipeline");
        }
      }
    }

    document.title = "Login";
  }, [session, navigate]);

  useEffect(() => {
    if (invalid) {
      setError(true);
      setErrorMessage("Invalid email or password");
    } else {
      setError(false);
      setErrorMessage("");
    }
  }, [invalid]);

  const handleLogin = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      if (!email) {
        setError(true);
        setErrorMessage("Please enter your email.");
      } else if (!password) {
        setError(true);
        setErrorMessage("Please enter your password.");
        //eslint-disable-next-line
      } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setError(true);
        setErrorMessage("Please enter a valid email.");
      }
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const userInfo = { email, password, remember };
    const loginResult = loginUser(userInfo);
    setValidated(true);

    if (!loginResult) {
      setError(true);
      setErrorMessage("Invalid email or password.");
    }
  };

  const handleForgotPassword = async (values) => {
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      const response = await axios.post("https://app.outperformhq.io/cloud/php/forgot_password_r1.php", formData);
      const data = response.data;
      if (data) {
        setIsForgotPassword(false);
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: "Your new password was sent to the given email",
          timer: 2000,
        });
      } else {
        setIsForgotPassword(false);
        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Something wrong with your entry, please try again or contact our developer",
        });
      }
    } catch (error) {
      console.error(error);
      setIsForgotPassword(false);
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "Something wrong with your entry, please try again or contact our developer",
      });
    }
  };

  return (
    <main className="d-flex align-items-center vh-100">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form noValidate validated={validated} onSubmit={handleLogin}>
              <Stack direction="horizontal">
                <div className="m-0">
                  <span className="text-secondary" style={{ fontSize: 20 }}>
                    Welcome back,
                  </span>
                  <br />
                  <span className="">Please sign in to your account.</span>
                </div>
                <div className="ms-auto pt-3">
                  <img src="https://app.outperformhq.io/cloud/lib/img/crm_logo.png" alt="crm_logo" width={180} height="auto" />
                </div>
              </Stack>

              <Collapse in={error}>
                <div>
                  <Alert variant="warning" className="mt-2">
                    <FontAwesomeIcon icon={faShieldExclamation} size="lg" className="me-2" /> {errorMessage}
                  </Alert>
                </div>
              </Collapse>

              <hr className="divider"></hr>

              <Form.Group>
                <Row>
                  <Col>
                    <FloatingLabel controlId="floatingInput" label="Email address">
                      <Form.Control type="email" size="sm" className="op-text-medium" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={() => setError(false)} placeholder="Email Address" required />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <Form.Group>
                      <FloatingLabel controlId="floatingInput" label="Password" className="position-relative">
                        <Form.Control className="op-text-medium" size="sm" type={hide ? "password" : "text"} value={password} onChange={(e) => setPassword(e.target.value)} onFocus={() => setError(false)} placeholder="Password" required />
                        <Button variant="link" className="text-decoration-none text-dark m-0 p-0" style={{ position: "absolute", top: 18, right: 10, cursor: "pointer" }} onClick={() => setHide(!hide)}>
                          {hide ? <FontAwesomeIcon icon={faEyeSlash} size="xl" /> : <FontAwesomeIcon icon={faEye} size="xl" />}
                        </Button>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Check type="checkbox" label="Remember me" onChange={(e) => setRemember(e.target.checked)} />
              </Form.Group>

              <hr className="divider"></hr>
              <button className="btn op-button op-primary-color text-light float-end" type="submit">
                Login to Outperform
              </button>
              <Button type="button" className="link-underline link-underline-opacity-0 float-end h6 op-text-medium" variant="link" onClick={setIsForgotPassword}>
                Recover password
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <Navbar fixed="top" style={{ background: "#eeeeee", padding: 0 }}>
        <Container>
          <Stack direction="horizontal" gap={3}>
            <Navbar.Text>
              <FontAwesomeIcon icon={faEnvelope} className="me-1" size="lg" /> business@nexible.com.my
            </Navbar.Text>
            <Navbar.Text>
              <FontAwesomeIcon icon={faPhone} className="me-1" size="lg" /> +6011 1722 5941
            </Navbar.Text>
          </Stack>
        </Container>
      </Navbar>

      <Navbar fixed="bottom">
        <Container>
          <Navbar.Text>
            <span className="me-1">© 2024 Outperform. All Rights Reserved.</span>
            <a className="text-dark" href="https://www.outperformhq.io/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </Navbar.Text>
          <Stack direction="horizontal" gap={3}>
            <Nav.Item>
              <Nav.Link href="https://www.facebook.com/outperformhq/" target="_blank">
                <FontAwesomeIcon icon={faSquareFacebook} size="2xl" />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="https://www.linkedin.com/company/nexcrm/?originalSubdomain=my" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2xl" />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="https://www.youtube.com/channel/UC6dl4ZSgf0lsOHH1f3i_0dg/featured/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2xl" />
              </Nav.Link>
            </Nav.Item>
          </Stack>
        </Container>
      </Navbar>

      <Modal show={isForgotPassword} onHide={setIsForgotPassword}>
        <Formik
          validationSchema={forgotPasswordSchema}
          onSubmit={handleForgotPassword}
          initialValues={{
            email: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Recover Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Enter your email address to receive instructions</Form.Label>
                  <Form.Control type="email" onChange={(e) => handleChange("email")(e.target.value)} />
                  {errors.email && touched.email && <div className="op-error-message">{errors.email}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="" type="submit" className="op-primary-color text-light">
                  Send
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </main>
  );
};

export default Login;
