import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack, Card } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useReactToPrint } from 'react-to-print';

const AiUsageDashboard = () => {
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const gridRef = useRef();
    const targetRef = useRef();
    const init = useRef(false);
    const [aiUsageData, setAiUsageData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]); // For dynamic columns
    const [rowData, setRowData] = useState([]); // For dynamic row data

    // START/END DATE
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // YYYY-MM-DD

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1); // Subtract one year
    const formattedOneYearAgo = oneYearAgo.toISOString().split('T')[0]; // YYYY-MM-DD

    const [dateRange, setdateRange] = useState({
        ds: formattedOneYearAgo, // Set to one year ago
        de: formattedToday,
    });


    const projectOptions = [
        {
            label: 'All Project',
            value: 'all',
        },
        {
            label: 'Gamuda HQ',
            value: 'hq',
        },
        {
            label: 'Gamuda Gardens',
            value: 'gardens',
        },
        {
            label: 'Gamuda Jadehill',
            value: 'jadehill',
        },
        {
            label: 'Gamuda Cove',
            value: 'cove',
        },
        {
            label: 'TwentyFive7',
            value: 'twentyfive7',
        },
        {
            label: 'Gamuda Bantayan',
            value: 'bantayan',
        }, {
            label: 'Gamuda HighPark',
            value: 'highpark',
        },
        {
            label: 'Gamuda Horizon',
            value: 'horizon',
        }
    ]

    const [selectedProject, setSelectedProject] = useState([projectOptions[0]]);

    const companyNameMapping = {
        251: "Gamuda HQ",
        180: "Gamuda Gardens",
        190: "Gamuda Jadehill",
        200: "Gamuda Cove",
        203: "TwentyFive7",
        212: "Gamuda Bantayan",
        213: "Gamuda Highpark",
        210: "Gamuda Horizon"
    };


    // GET FUNCTION
    // =======================================================================

    // const getAiAgentDashboard = async (ds, de, project) => {
    //     try {
    //         const res = await axios.get("ws/ws_ai_usage.php", {
    //             params: {
    //                 task: "AiUsageDashboard",
    //                 utoken: session.user_ac_token,
    //                 ctoken: session.company_token,
    //                 ds: ds || dateRange.ds || "",
    //                 de: de || dateRange.de || "",
    //                 project: project || ""
    //             }
    //         });

    //         const data = res.data;

    //         if (data.status === 0) {
    //             setAiUsageData(data.record);

    //             // Create column definitions dynamically based on unique months
    //             const allMonths = Array.from(new Set(data.record.map(record => `${record.year}-${record.month}`)));
    //             const columns = allMonths.map(monthKey => ({
    //                 headerName: `${moment(monthKey).format("MMM YYYY")}`,
    //                 field: monthKey,
    //                 sortable: true,
    //                 filter: true,
    //             }));

    //             setColumnDefs([{ headerName: "Project", field: "company", sortable: true, filter: true }, ...columns]);

    //             // Prepare row data by aggregating deal counts for each company across months
    //             const companyDataMap = {};

    //             // Step 1: Populate company data from records
    //             data.record.forEach(record => {
    //                 const companyName = companyNameMapping[record.company_id];
    //                 const columnKey = `${record.year}-${record.month}`;

    //                 if (!companyDataMap[companyName]) {
    //                     companyDataMap[companyName] = { company: companyName };
    //                 }

    //                 // Sum deal counts for this month
    //                 const totalDealCount = record.distinct_blueprint_data.reduce((sum, { deal_count }) => sum + parseInt(deal_count, 10), 0);
    //                 companyDataMap[companyName][columnKey] = totalDealCount;
    //             });

    //             // Step 2: Ensure every company has 0 for missing months
    //             Object.values(companyDataMap).forEach(companyRow => {
    //                 allMonths.forEach(monthKey => {
    //                     if (!companyRow[monthKey]) {
    //                         companyRow[monthKey] = 0; // Add 0 for missing month data
    //                     }
    //                 });
    //             });

    //             // Convert company data to rowData array
    //             const rowData = Object.values(companyDataMap);
    //             setRowData(rowData);
    //         } else {
    //             setRowData([]);
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         console.error("Error fetching data:", error);
    //     }
    // };

    const getAiAgentDashboard = async (ds, de, project) => {
        try {
            const res = await axios.get("ws/ws_ai_usage.php", {
                params: {
                    task: "AiUsageDashboard",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    ds: ds || dateRange.ds || "",
                    de: de || dateRange.de || "",
                    project: project || ""
                }
            });
    
            const data = res.data;
    
            if (data.status === 0) {
                setAiUsageData(data.record);
    
                // Create column definitions dynamically based on unique months
                const allMonths = Array.from(new Set(data.record.map(record => `${record.year}-${record.month}`)));
                const columns = allMonths.map(monthKey => ({
                    headerName: `${moment(monthKey).format("MMM YYYY")}`,
                    field: monthKey,
                    sortable: true,
                    filter: true,
                }));
    
                // Add a custom column for "Total Leads Project"
                columns.push({
                    headerName: "Total Leads Project",
                    field: "totalLeads",
                    sortable: true,
                    filter: true,
                });
    
                setColumnDefs([{ headerName: "Project", field: "company", sortable: true, filter: true }, ...columns]);
    
                // Prepare row data by aggregating deal counts for each company across months
                const companyDataMap = {};
                const monthlyTotals = {}; // To store the total for each month
    
                // Step 1: Populate company data from records
                data.record.forEach(record => {
                    const companyName = companyNameMapping[record.company_id];
                    const columnKey = `${record.year}-${record.month}`;
    
                    if (!companyDataMap[companyName]) {
                        companyDataMap[companyName] = { company: companyName, totalLeads: 0 };
                    }
    
                    // Sum deal counts for this month
                    const totalDealCount = record.distinct_blueprint_data.reduce((sum, { deal_count }) => sum + parseInt(deal_count, 10), 0);
                    companyDataMap[companyName][columnKey] = totalDealCount;
    
                    // Increment the total leads for this project
                    companyDataMap[companyName].totalLeads += totalDealCount;
    
                    // Increment monthly totals
                    if (!monthlyTotals[columnKey]) {
                        monthlyTotals[columnKey] = 0;
                    }
                    monthlyTotals[columnKey] += totalDealCount;
                });
    
                // Step 2: Ensure every company has 0 for missing months
                Object.values(companyDataMap).forEach(companyRow => {
                    allMonths.forEach(monthKey => {
                        if (!companyRow[monthKey]) {
                            companyRow[monthKey] = 0; // Add 0 for missing month data
                        }
                    });
                });
                
                // Step 3: Prepare the total leads row
                const totalLeadsRow = { company: "Total Leads" };
                allMonths.forEach(monthKey => {
                    totalLeadsRow[monthKey] = monthlyTotals[monthKey] || 0;
                });

                // can remove this row if dw to count total for project count
                totalLeadsRow.totalLeads = Object.values(monthlyTotals).reduce((sum, count) => sum + count, 0);
    
                // Convert company data to rowData array
                const rowData = [...Object.values(companyDataMap), totalLeadsRow];
                setRowData(rowData);
            } else {
                setRowData([]);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    

    const handleApplyButtonClick = () => {
        setLoading(true);

        const dateRangeValues = {
            ds: dateRange.ds,
            de: dateRange.de,
        };

        getAiAgentDashboard(dateRangeValues.ds, dateRangeValues.de, selectedProject.value)

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };


    const processChartData = () => {
        const groupedData = {};

        aiUsageData.forEach(record => {
            const { year, month, company_id, distinct_blueprint_data } = record;
            const key = `${year}-${month.padStart(2, "0")}`;

            if (!groupedData[key]) {
                const date = new Date(year, month - 1);
                const formattedDate = `${date.toLocaleString("default", { month: "long" })} ${year}`;
                groupedData[key] = { year, month, formattedDate }; // Initialize with formattedDate
            }

            distinct_blueprint_data.forEach(({ deal_count }) => {
                groupedData[key][company_id] = (groupedData[key][company_id] || 0) + parseInt(deal_count, 10);
            });
        });

        return Object.keys(groupedData).map(key => groupedData[key]);
    };


    const containerStyle = useMemo(() => ({ width: "100%", height: "50vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);


    // DATE RANGE FILTER --------------------------------------------------
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };


    // DOWNLOAD -----------------------------------------------------------

    const handleDownload = () => {
        var params = {
            fileName: "ai-usage-dashboard.csv",
            processCellCallback: function (params) {
                if (params.column.colId === "company_id") {
                    return params.node.data.ds_title;
                } else {
                    var res = params.value;
                    if (res !== undefined && res !== null) {
                        res = String(res).replace(/<br>/g, "\r\n")
                            .replace(/<li>/g, "\r\n")
                            .replace(/<[^>]+>/g, "")
                            .replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    // PRINT DASHBOARD ----------------------------------------------------

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        content: () => targetRef.current,
    });


    // USEEFFECT ----------------------------------------------------------
    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getAiAgentDashboard();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                    console.error(error);
                }
            }
        };

        initData();
    }, [session]);

    const chartData = processChartData();


    return (
        <Container fluid className="p-0 m-0" style={{ height: '90vh', overflowX: 'hidden', overflowY: 'auto' }}>
            <div className="p-4">
                <div className="d-flex justify-content-between mb-4" id="filter">
                    <Form.Group>
                        <FormSelect
                            options={projectOptions}
                            valueDefault={selectedProject}
                            onChange={(selectedOption) => {
                                setSelectedProject(selectedOption);
                            }}
                            placeholder="Select project"
                            border={false}
                            shadow={true}
                            width="200px"
                            isSearchable
                        />
                    </Form.Group>

                    <div className="ml-auto d-flex gap-3">
                        <Form.Group>
                            <Form.Control
                                type="date"
                                placeholder='from'
                                className="shadow-sm border-0"
                                value={dateRange.ds}
                                onChange={(e) => {
                                    onchangeDateRange("ds", e.target.value); // No need for moment here
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="date"
                                placeholder='to'
                                className="shadow-sm border-0"
                                value={dateRange.de}
                                onChange={(e) => {
                                    onchangeDateRange("de", e.target.value); // No need for moment here
                                }}
                            />
                        </Form.Group>

                        <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
                            Apply
                        </Button>

                        <div>
                            <button className="btn btn-dark" onClick={handlePrint} disabled={rowData.length === 0}>
                                Print
                            </button>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <Row ref={targetRef}>
                            <Col xl={12}>
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <div className="font h6 col-lg-10">AI Usage</div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={500}>
                                            {rowData && Object.keys(rowData).length > 0 ? (
                                                <BarChart data={chartData} margin={{ bottom: 70 }}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="formattedDate" interval={0} angle={315} textAnchor="end" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend verticalAlign="top" wrapperStyle={{ top: "-1%", lineHeight: "10px" }} />
                                                    <Bar dataKey="251" stackId="a" fill="#c5aab7" name="Gamuda HQ" />
                                                    <Bar dataKey="180" stackId="a" fill="#f98225" name="Gamuda Gardens" />
                                                    <Bar dataKey="190" stackId="a" fill="#8c5348" name="Gamuda Jadehill" />
                                                    <Bar dataKey="200" stackId="a" fill="#C6B79B" name="Gamuda Cove" />
                                                    <Bar dataKey="203" stackId="a" fill="#027148" name="TwentyFive7" />
                                                    <Bar dataKey="212" stackId="a" fill="#25aff9" name="Gamuda Bantyan" />
                                                    <Bar dataKey="213" stackId="a" fill="#b625f9" name="Gamuda HighPark" />
                                                    <Bar dataKey="210" stackId="a" fill="#f92595" name="Gamuda Horizon" />
                                                </BarChart>
                                            ) : (
                                                <div></div>
                                            )}

                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <div className="mt-4">
                            <button className="btn btn-dark" onClick={handleDownload} disabled={rowData.length === 0}>
                                Download
                            </button>
                        </div>

                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        pagination={true}
                                        domLayout="autoHeight"
                                        suppressRowClickSelection={true}
                                    />
                                </div>
                            </div>
                        </Col>
                    </div>
                )}
            </div>
        </Container>
    );

}

export default AiUsageDashboard;