import loaderImage from "../assets/images/loader.gif";
import preloader from "../assets/images/preloader4.gif";

const Loader = () => {
  return (
    // <div className="position-relative" style={{ height: "calc(100vh - 56px)" }}>
    //   <div className="position-absolute text-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
    //     <img src={loaderImage} alt="loader" width={150} height={150} />
    //   </div>
    // </div>
    // <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}>
    //   <img src={loaderImage} alt="loader" width={150} height={150} />
    // </div>
    <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}>
      <div className="p-3 op-primary-color" style={{ borderRadius: 15 }}>
        <img src={preloader} alt="loader" width={50} height={45} />
      </div>
    </div>
  );
};

export default Loader;
