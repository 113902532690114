import { useState, useRef, useEffect, useCallback, forwardRef, useMemo, useLayoutEffect } from "react";
import { FormSelect, FormCustomField, FormSelectCreatable, FormDate, FormSelectV2, FormCurrency } from "../includes/FormCustom";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button } from "react-bootstrap";
import "../lib/scss/op-deal-details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faChevronDown, faPlus as faPlusSolid } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleLight, faEarthAsia, faIndustryWindows, faPencilAlt, faPlus, faDollarSign, faTimelineArrow } from "@fortawesome/pro-light-svg-icons";
import {
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faPhoneVolume,
  faStopwatch,
  faUser,
  faUsers,
  faSave,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faEnvelope,
  faCalendarDay,
  faBuilding,
  faBallot,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faGlobe,
  faCircleDollar,
  faChevronRight,
  faCircleCheck as faCircleCheckLight,
  faCircleXmark as faCircleXMarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { MobilePrefix, setCurrency, setDateTimeStr } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import ReactHtmlParser from "html-react-parser";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoteEditor from "../includes/NoteEditor";
import { useTranslation } from "react-i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Loader from "../includes/Loader";

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organization_id } = useParams();
  const init = useRef(false);
  const { session, logoutUser } = useAuth();
  const { Formik } = formik;
  const editorRef = useRef(null);
  const prosDealGridRef = useRef();
  const orgProsGridRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditOrganization, setIsEditOrganization] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [orgDetData, setOrgDetData] = useState([]);
  const [analyticData, setAnalyticData] = useState({});
  const [initial, setInitial] = useState("");
  const [initialVal, setInitialVal] = useState({});
  const [editProsCf, setEditProsCf] = useState([]);
  const [editNoteInit, setEditNoteInit] = useState({});
  const [isEditNote, setIsEditNote] = useState(false);
  const [editActivityInit, setEditActivityInit] = useState({});
  const [isEditActivity, setIsEditActivity] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [isMain, setIsMain] = useState("");
  const [isOrganizationTab, setIsOrganizationTab] = useState("contact");
  const [prosDealRowData, setProsDealRowData] = useState([]);
  const [isOrganizationDeal, setIsOrganizationDeal] = useState(false);
  const [getOrgPros, setGetOrgPros] = useState([]);
  const [isOrgPros, setIsOrgPros] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [user, setUser] = useState([]);
  const [imgError, setImgError] = useState(false);
  const [isTransferOwner, setIsTransferOwner] = useState(false);
  const transferOwnerRef = useRef(null);
  const [isAddDeal, setIsAddDeal] = useState(false);
  const [pipeData, setPipeData] = useState([]);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [dealSourceShow, setDealSourceShow] = useState(true);
  const [dealValueShow, setDealValueShow] = useState(true);
  const [expectedCloseDateShow, setExpectedCloseDateShow] = useState(true);
  const [addDealDisabledBtn, setAddDealDisabledBtn] = useState(false);
  const [dealSource, setDealSource] = useState([]);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [openDealRowData, setOpenDealRowData] = useState([]);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [wonDealRowData, setWonDealRowData] = useState([]);
  const [isInbLd, setIsInbLd] = useState(false);
  const [inbLdRowData, setInbLdRowData] = useState([]);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [lostDealRowData, setLostDealRowData] = useState([]);
  const [analyticOpen, setAnalyticOpen] = useState(false);
  const [analyticWon, setAnalyticWon] = useState(false);
  const [analyticInbLd, setAnalyticInbLd] = useState(false);
  const [analyticLost, setAnalyticLost] = useState(false);
  const [timelineFilter, setTimelineFilter] = useState("all");
  const containerRef = useRef(null);
  const [width, setWidth] = useState();

  const durationList = [
    { label: "5 Mins", value: "5min" },
    { label: "10 Mins", value: "10min" },
    { label: "15 Mins", value: "15min" },
    { label: "20 Mins", value: "20min" },
    { label: "30 Mins", value: "30min" },
    { label: "35 Mins", value: "35min" },
    { label: "40 Mins", value: "40min" },
    { label: "45 Mins", value: "45min" },
    { label: "50 Mins", value: "50min" },
    { label: "55 Mins", value: "55min" },
    { label: "1 Hour", value: "60min" },
    { label: "1 Hour 30 Mins", value: "90min" },
    { label: "2 Hours", value: "120min" },
    { label: "2 Hours 30 Mins", value: "150min" },
    { label: "3 Hours", value: "180min" },
    { label: "3 Hours 30 Mins", value: "210min" },
    { label: "4 Hours", value: "240min" },
  ];

  const timings = [
    { label: "08:00", value: "08:00" },
    { label: "08:15", value: "08:15" },
    { label: "08:30", value: "08:30" },
    { label: "08:45", value: "08:45" },
    { label: "09:00", value: "09:00" },
    { label: "09:15", value: "09:15" },
    { label: "09:30", value: "09:30" },
    { label: "09:45", value: "09:45" },
    { label: "10:00", value: "10:00" },
    { label: "10:15", value: "10:15" },
    { label: "10:30", value: "10:30" },
    { label: "10:45", value: "10:45" },
    { label: "11:00", value: "11:00" },
    { label: "11:15", value: "11:15" },
    { label: "11:30", value: "11:30" },
    { label: "11:45", value: "11:45" },
    { label: "12:00", value: "12:00" },
    { label: "12:15", value: "12:15" },
    { label: "12:30", value: "12:30" },
    { label: "12:45", value: "12:45" },
    { label: "13:00", value: "13:00" },
    { label: "13:15", value: "13:15" },
    { label: "13:30", value: "13:30" },
    { label: "13:45", value: "13:45" },
    { label: "14:00", value: "14:00" },
    { label: "14:15", value: "14:15" },
    { label: "14:30", value: "14:30" },
    { label: "14:45", value: "14:45" },
    { label: "15:00", value: "15:00" },
    { label: "15:15", value: "15:15" },
    { label: "15:30", value: "15:30" },
    { label: "15:45", value: "15:45" },
    { label: "16:00", value: "16:00" },
    { label: "16:15", value: "16:15" },
    { label: "16:30", value: "16:30" },
    { label: "16:45", value: "16:45" },
    { label: "17:00", value: "17:00" },
    { label: "17:15", value: "17:15" },
    { label: "17:30", value: "17:30" },
    { label: "17:45", value: "17:45" },
    { label: "18:00", value: "18:00" },
    { label: "18:15", value: "18:15" },
    { label: "18:30", value: "18:30" },
    { label: "18:45", value: "18:45" },
    { label: "19:00", value: "19:00" },
    { label: "19:15", value: "19:15" },
    { label: "19:30", value: "19:30" },
    { label: "19:45", value: "19:45" },
    { label: "20:00", value: "20:00" },
    { label: "20:15", value: "20:15" },
    { label: "20:30", value: "20:30" },
    { label: "20:45", value: "20:45" },
    { label: "21:00", value: "21:00" },
    { label: "21:15", value: "21:15" },
    { label: "21:30", value: "21:30" },
    { label: "21:45", value: "21:45" },
    { label: "22:00", value: "22:00" },
  ];

  const noteSchema = yup.object().shape({
    prosNotes: yup.string().required("This field is required"),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required("Title is required"),
    activity_type: yup.string().required("Activity type is required"),
    activity_user: yup.string().required("User is required"),
  });

  const editNoteSchema = yup.object().shape({
    activity_note: yup.string().required("This field is required"),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required("Title is required"),
    activity_type: yup.string().required("Activity type is required"),
    activity_user: yup.string().required("User is required"),
  });

  const editProsSchema = yup.object().shape({
    orgName: yup.string().required("Name is required"),
  });

  const transferOrganizationSchema = yup.object().shape({
    user: yup.string().required("User is required"),
  });

  const newDealSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealList_error_message")),
    prospect_name: yup.string().required(t("DealList_error_message")),
    pipeline_id: yup.string().required(t("DealList_error_message")),
    prospect_id: yup.string().required(t("DealList_error_message")),
    contact1: yup.string().test("contact1", "Invalid contact number", async function (value) {
      const { contact1_prefix, contact1_disabled } = this.parent || {};
      try {
        if (contact1_disabled === false && value) {
          const countryCode = MobilePrefix.find((record) => record.value === contact1_prefix);
          if (countryCode) {
            const phoneNumber = parsePhoneNumberFromString(`+${contact1_prefix}${value}`, countryCode.region);
            if (!phoneNumber || !phoneNumber.isValid()) {
              return this.createError({ message: "Invalid contact number" });
            }
          } else {
            return this.createError({ message: "Invalid contact number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    contact1_prefix: yup.string().test("contact1_prefix", "Prefix is required", async function (value) {
      const { contact1_disabled, contact1 } = this.parent || {};
      if (contact1_disabled === false && contact1) {
        if (value === "") {
          return this.createError({ message: "Prefix is required" });
        }
      }

      return true;
    }),
  });

  // GET FUNCTION ----------------------------------------------------------------

  const getOrganizationDet = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "12",
          organization: organization_id,
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setOrgDetData(data.record);
        setInitial(data.record.organization_title.split("")[0]);
        if (data.record.prospects_data) {
          setGetOrgPros(data.record.prospects_data);
          setIsOrgPros(true);
        } else {
          setIsOrgPros(false);
          setGetOrgPros([]);
        }
        setIsLoading(false);
      } else {
        logoutUser();
      }
    } catch (error) {
      logoutUser();
    }
  };

  const getIndustry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          company_id: session.company_id,
          task: "getOrganizations",
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.in_title,
        value: record.in_id,
      }));
      setIndustryList(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getCountry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 10,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.country_nicename,
        value: record.country_id,
      }));
      setCountryData(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        const currentUserId = session.user_id;
        const currentUser = data.record.find((user) => user.user_id === currentUserId);
        if (currentUser) {
          data.record = [currentUser, ...data.record.filter((user) => user.user_id !== currentUserId)];
        }

        const recordOptions = data.record.map((rec) => ({
          ...rec,
          value: rec.user_id,
          label: rec.user_name + (Number(rec.user_id) === Number(session.user_id) ? " (You)" : ""),
        }));

        setUser(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEditOrganization = async () => {
    try {
      const res = await axios.get("ws/ws_organization.php", {
        params: {
          task: "3",
          organization: organization_id,
          pipe: "",
        },
      });

      let data = res.data;
      if (data.status === 0) {
        setInitialVal({
          orgName: data.record.organization_title,
          industry: data.record.in_id,
          webUrl: data.record.organization_web_url,
          phoneNumber: data.record.organization_contact,
          orgAddress: data.record.organization_address,
          country: data.record.organization_country_id,
          owner: data.record.owner_id,
          cf_person:
            data.record.custom_field > 0
              ? data.record.custom_field_data.map((record) => ({
                  cf_id: record.cf_id,
                  fd_id: record.fd_id,
                  cf_label: record.cf_label,
                  name: record.cf_id,
                  fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                }))
              : "",
        });

        if (data.record.custom_field > 0) {
          setEditProsCf(data.record.custom_field_data);
        } else {
          setEditProsCf([]);
        }

        setIsEditOrganization(true);
      } else {
        setInitialVal({
          orgName: "",
          industry: "",
          webUrl: "",
          phoneNumber: "",
          orgAddress: "",
          country: "",
          owner: "",
          cf_person: "",
        });

        setEditProsCf([]);
        setIsEditOrganization(true);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const getProsTimeline = () => {
    axios
      .get("ws/ws_organization.php", {
        params: {
          ctoken: session.company_token,
          task: "11",
          organization: organization_id,
          utoken: session.user_ac_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record) {
          setTimelineData(data.record);
        } else {
          setTimelineData([]);
        }
      });
  };

  const getOrganizationDeals = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "13",
          organization: organization_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      let data = response.data;

      const recordOpen = data.record_open || [];
      const recordWon = data.record_won || [];
      const recordLost = data.record_lost || [];

      const allDeals = [...recordOpen, ...recordWon, ...recordLost];

      if (allDeals.length > 0) {
        setIsOrganizationDeal(true);
        setProsDealRowData(allDeals);
      } else {
        setIsOrganizationDeal(false);
        setProsDealRowData([]);
      }
      setAnalyticData(data);

      if (Number(data.total_open) > 0) {
        setIsOpenDeal(true);
        setOpenDealRowData(data.record_open);
      } else {
        setIsOpenDeal(false);
        setOpenDealRowData([]);
      }

      if (Number(data.total_won) > 0) {
        setIsWonDeal(true);
        setWonDealRowData(data.record_won);
      } else {
        setIsWonDeal(false);
        setWonDealRowData([]);
      }

      if (Number(data.total_deleted) > 0) {
        setIsInbLd(true);
        setInbLdRowData(data.record_deleted);
      } else {
        setIsInbLd(false);
        setInbLdRowData([]);
      }

      if (Number(data.total_lost) > 0) {
        setIsLostDeal(true);
        setLostDealRowData(data.record_lost);
      } else {
        setIsLostDeal(false);
        setLostDealRowData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPipeline = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      });

      let data = response.data;

      if (data.status === 0) {
        var pipeData = data.record.map((data) => {
          return { label: data.pipeline_title, value: data.pipeline_id };
        });

        setPipeData(pipeData);
      } else {
        setPipeData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomFieldAddDeal = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "getCFForAddDeal",
          area: "",
          cfpipe: pipelineId,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          v_add: "yes",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        var cfDeal = data.record.filter((record) => {
          return record.cf_area === "deal";
        });

        var cfPerson = data.record.filter((record) => {
          return record.cf_area === "person";
        });
        return {
          cf_deals: cfDeal,
          cf_person: cfPerson,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("/ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              ...record,
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
              contact1: record.contact1 ? record.contact1 : record.contact2 ? record.contact3 : record.contact3 ? record.contact3 : record.contact4 ? record.contact4 : record.contact5 ? record.contact5 : "",
              contact_id: record.contact_id,
              contact1Prefix: record.contact1_prefix ? record.contact1_prefix : "60",
            }));

          var newProspectRecord = data.record.find((record) => Number(record.prospect_id) === 0);
          if (newProspectRecord) {
            prospectRecord.unshift({
              label: inputValue,
              value: 0,
              customLabel: newProspectRecord.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getDealField = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: 8,
          company: session.company_id,
        },
      });

      const data = response.data;

      if (data.record && data.record.length > 0) {
        const organizationField = data.record.find((record) => record.core_title === "organization_title");
        const dealSourceField = data.record.find((record) => record.core_title === "ds_title");
        const dealValueField = data.record.find((record) => record.core_title === "deal_value");
        const expectedCloseDateField = data.record.find((record) => record.core_title === "deal_expected_close_date");
        if (dealSourceField) {
          setDealSourceShow(dealSourceField.setting_field_status === "yes");
        }

        if (dealValueField) {
          setDealValueShow(dealValueField.setting_field_status === "yes");
        }

        if (expectedCloseDateField) {
          setExpectedCloseDateShow(expectedCloseDateField.setting_field_status === "yes");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDealSource = async () => {
    try {
      const response = await axios.get("/ws/ws_source.php", {
        params: {
          task: 2,
          company: session.company_id,
        },
      });

      var data = response.data;

      if (data.status === 0) {
        const dealSourceRecord = data.record
          .filter((record) => record.ds_display === "show")
          .map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

        setDealSource(dealSourceRecord);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDefCsID = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: session.company_id,
          pipeline_id: pipelineId,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record[0].cs_id;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ----------------------------------------------------------------

  const sendProsNote = async (values) => {
    const noteData = new FormData();

    noteData.append("task", "addProspectOrgNotes");
    noteData.append("user", values.user);
    noteData.append("content", values.prosNotes);
    noteData.append("organizationid", organization_id);
    noteData.append("type", "organization");
    noteData.append("utoken", session.user_ac_token);
    noteData.append("ctoken", session.company_token);

    try {
      const res = await axios.post("ws/ws_post_note.php", noteData);

      let data = res.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("OrganizationDetails_success"),
          text: t("OrganizationDetails_successfully_add_note"),
          timer: 1500,
        }).then((result) => {
          setIsActiveTab(false);
          getProsTimeline();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("OrganizationDetails_failed"),
          text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendAddActivity = (values) => {
    const cleanNotes = values.activity_notes.replace(/<\/?p>/g, "");
    const formattedDate = new Date(values.activity_date).toLocaleDateString("en-GB").replace(/\//g, "-");

    axios
      .get("ws/ws_deal.php", {
        params: {
          assign_user: values.activity_user,
          content: cleanNotes,
          create_user: values.activity_user,
          dat: values.activity_type,
          dated: formattedDate,
          deal: "",
          duration: values.activity_dur || "",
          mad: values.activity_mad,
          organization: organization_id,
          task: "2",
          timed: values.activity_time,
          title: values.activity_title,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: t("OrganizationDetails_success"),
          text: t("OrganizationDetails_activity_successfully_created"),
          timer: 1500,
        }).then((result) => {
          setIsActiveTab(false);
          getProsTimeline();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendMarkAsDone = (record) => {
    if (record.record_activity_type_id === 6 || record.record_activity_type_id === 7) {
      Swal.fire({
        icon: "error",
        title: t("OrganizationDetails_restrict"),
        text: t("OrganizationDetails_sorry_this_activity_cant_be_edited"),
      });
    } else {
      var mark = record.record_activity_mad === "yes" ? "no" : "yes";

      axios
        .get("ws/ws_deal.php", {
          params: {
            task: "16",
            activity: record.record_activity_id,
            status: mark,
            user: session.user_id,
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status === 0) {
            setTimelineData((prev) => {
              return prev.map((info) => {
                if (info.record_activity_id === record.record_activity_id) {
                  return {
                    ...info,
                    record_activity_mad: mark,
                  };
                }
                return info;
              });
            });
          } else {
            Swal.fire({
              icon: "error",
              title: t("OrganizationDetails_failed"),
              text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
          });
        });
    }
  };

  const sendEditActivity = async (values) => {
    try {
      const res = await axios.get("ws/ws_deal.php", {
        params: {
          task: 11,
          user: session.user_id,
          dt: values.activity_date,
        },
      });

      let data = res.data;

      if (data.status === 0) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              aid: values.activity_id,
              assign_user: values.activity_user,
              ca_id: 0,
              content: values.activity_note,
              create_user: values.activity_user,
              dat: values.activity_type,
              dated: values.activity_date,
              duration: values.activity_dur,
              mad: values.activity_mad,
              task: "12",
              timed: values.activity_time,
              title: values.activity_title,
            },
          })
          .then((response) => {
            let rec = response.data;

            if (rec.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("OrganizationDetails_success"),
                text: t("OrganizationDetails_activity_successfully_updated"),
                timer: 1500,
              }).then((result) => {
                setIsEditActivity(false);
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("OrganizationDetails_failed"),
                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: t("OrganizationDetails_failed"),
          text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendEditNote = async (values) => {
    var editNoteData = new FormData();

    if (values.activity_type === "organization") {
      editNoteData.append("task", "editProspectOrgNotes");
      editNoteData.append("source", values.activity_type);
      editNoteData.append("user", session.user_id);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("note", values.activity_id);
      editNoteData.append("utoken", session.user_ac_token);
      editNoteData.append("ctoken", session.company_token);
    } else {
      editNoteData.append("task", "editNotes");
      editNoteData.append("note", values.activity_id);
      editNoteData.append("deal", values.activity_dealid);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("utoken", session.user_ac_token);
      editNoteData.append("ctoken", session.company_token);
    }

    try {
      const res = await axios.post("ws/ws_post_note.php", editNoteData);

      let data = res.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("OrganizationDetails_success"),
          text: t("OrganizationDetails_note_successfullt_updated"),
          timer: 1500,
        }).then((result) => {
          setIsEditNote(false);
          getProsTimeline();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("OrganizationDetails_failed"),
          text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendDeleteActivity = (rec) => {
    Swal.fire({
      icon: "warning",
      title: t("OrganizationDetails_are_you_sure"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("OrganizationDetails_yes_delete_it"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: 23,
              activity: rec.record_activity_id,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("OrganizationDetails_success"),
                text: t("OrganizationDetails_successfully_delete_note"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("OrganizationDetails_failed"),
                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const sendDeleteNote = (rec) => {
    var paramsData;

    if (rec.record_type === "Note") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        prospectNote: 0,
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      };
    } else if (rec.record_type === "Note_Organization") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        type: "organization",
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      };
    }

    Swal.fire({
      icon: "warning",
      title: t("OrganizationDetails_are_you_sure"),
      text: t("OrganizationDetails_this_note_will_be_deleted"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("OrganizationDetails_yes_delete_it"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: paramsData,
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("OrganizationDetails_success"),
                text: t("OrganizationDetails_successfully_delete_note"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("OrganizationDetails_failed"),
                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const sendEditOrganization = async (values) => {

    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var organization_id_arr = Array(customField.length).fill(orgDetData.organization_id);
    var fd_values = customField
    .map((record) => {
      if (Array.isArray(record.fd_values)) {
        var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
        return filteredValues.length > 0 ? filteredValues.join("|") : null;
      } else {
        return record.fd_values;
      }
    })
    .filter((value) => value !== null);

    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          address: values.orgAddress,
          company: session.company_id,
          contact: values.phoneNumber,
          country: values.country,
          industry: values.industry,
          organization: organization_id,
          owner: orgDetData.owner_id,
          task: "2",
          title: values.orgName,
          weburl: values.webUrl,
        },
      });

      let data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          await sendEditOrganizationCF(cf_id, cf_label, organization_id_arr, fd_values);
        }

        Swal.fire({
          icon: "success",
          title: t("OrganizationDetails_success"),
          text: "Successfully updated the organization",
          timer: 1500,
        }).then((result) => {
          setIsEditOrganization(false);
          getOrganizationDet();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("OrganizationDetails_oops"),
          text: t("OrganizationDetails_something_wrong_with_your_entry"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditOrganizationCF = async (cf_id, cf_label, organization_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCFV2",
          record: organization_id.join("[-0-]"),
          hq_prospect_id:  "",
          cf_label: cf_label.join("[-0-]"),
          cf: cf_id.join("[-0-]"),
          value: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: session.company_id,
          secret_key: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendTransferOrganization = async (values) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          address: orgDetData.organization_address,
          company: session.company_id,
          contact: orgDetData.organization_contact,
          country: orgDetData.organization_country_id,
          industry: orgDetData.in_id,
          organization: orgDetData.organization_id,
          owner: values.user,
          task: "2",
          title: orgDetData.organization_title,
          weburl: orgDetData.organization_web_url,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Successfully transferred the ownership of the organization",
          timer: 1500,
        }).then(() => {
          transferOwnerRef.current.click();
          getOrganizationDet();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to transfer the ownership of the organization",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteOrganizationConfirmation = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action will delete the organization",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectList_alert_warning_2_confirm"),
      cancelButtonText: t("ProspectList_alert_warning_cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await sendDeleteOrganization();
        if (Number(response) === 0) {
          Swal.fire({
            title: t("ProspectList_alert_success_title"),
            text: "Successfully deleted the organization",
            icon: "success",
            timer: 1500,
          }).then(() => {
            navigate("/organizations/list");
          });
        } else {
          Swal.fire({
            title: t("ProspectList_alert_warning_3_title"),
            text: `Can't delete this organization due to prospect association`,
            icon: "warning",
          });
        }
      }
    });
  };

  const sendDeleteOrganization = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "6",
          organization: orgDetData.organization_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      return response.data.status;
    } catch (error) {
      console.error(error);
    }
  };

  const getDeal = async (deal_id) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "getDealDetails",
          type: "view",
          deal: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record.prospect_id;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDeal = async (values) => {
    setAddDealDisabledBtn(true);
    try {
      if (values.contact1_disabled === false && values.contact1) {
        const response = await axios.get("ws/ws_prospect.php", {
          params: {
            task: "getMobileExists",
            mobile: values.contact1,
            company: session.company_id,
            type: "",
            people: "",
            user: session.user_id,
          },
        });

        const data = response.data;
        if (Number(data.status) === 0) {
          sendCreateDealGlobal(values, orgDetData.organization_id);
        } else {
          setAddDealDisabledBtn(false);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Contact number entered already exists in the system.",
          });
        }
      } else {
        sendCreateDealGlobal(values, orgDetData.organization_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateOrganization = async (organization_title, prospect_id) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "addOrgAndConnectProspect",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          title: organization_title,
          prospect: prospect_id,
          address: "",
          weburl: "",
          industry: "",
          contact: "",
          country: "",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record;
      }

      return 0;
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGlobal = async (values, organization_id) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);
      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      if (Number(values.prospect_id) === 0 || values.prospect_id === "") {
        if (values.contact1 === "") {
          values.contact1 = "";
        } else {
          const countryCode = MobilePrefix.find((record) => record.value === values.contact1_prefix);
          if (countryCode) {
            const phoneNumber = parsePhoneNumberFromString(`+${values.contact1_prefix}${values.contact1}`, countryCode.region);
            if (phoneNumber) {
              values.contact1 = phoneNumber.number.replace("+", "");
            } else {
              values.contact1 = values.contact1_prefix + values.contact1;
            }
          }
        }
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: values.pipeline_id,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: organization_id,
          organization_name: orgDetData.organization_title,
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: values.cs_id,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        if (values.prospect_id && Number(values.prospect_id) > 0) {
          if ((values.organization_id === "" || Number(values.organization_id) === 0) && values.organization_title !== "") {
            await sendCreateOrganization(values.organization_title, values.prospect_id);
          }
        } else {
          if (values.organization_title !== "") {
            const prospect_id = await getDeal(data.record);
            if (prospect_id && Number(prospect_id) > 0) {
              await sendCreateOrganization(values.organization_title, prospect_id);
            }
          }
        }

        getOrganizationDeals();
        Swal.fire({
          icon: "success",
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsAddDeal(false);
    }
  };

  // ONCHANGE FUNCTION ------------------------------------------------------------

  const onchangeActionTabs = (key) => {
    setActiveTab(key);
    setIsActiveTab(false);
  };

  // SET FUNCTION ----------------------------------------------------------------

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note_Prospect" || record.record_type === "Note") {
      icon = faUser;
      content = record.CREATE_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faUser;
      content = record.ASSIGN_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faBuilding;
      content = "";
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      icon = faEnvelopeOpen;
      content = record.record_comment;
    }

    return (
      <Stack direction="horizontal" gap={3}>
        <div>{setDateTimeStr(record.record_date_time)}</div>
        <div className="d-flex">
          {icon ? <FontAwesomeIcon icon={icon} size="lg" className="me-2" /> : ""}
          {content}
        </div>
      </Stack>
    );
  };

  const setCheckImg = () => {
    setImgError(true);
  };

  const setOwnerName = (user_id) => {
    const currentUser = user.find((user) => Number(user.value) === Number(user_id));
    if (currentUser) {
      return currentUser.label;
    }
    return "";
  };

  const setOrganizationCountryValue = () => {
    if (orgDetData?.organization_country_id && Number(orgDetData?.organization_country_id) > 0) {
      const country = countryData.find((data) => Number(data.value) === Number(orgDetData.organization_country_id));
      return <div className="fw-bold">{country ? country.label : "-"}</div>;
    }

    return <div className="fw-bold">-</div>;
  };

  const setCustomFieldValues = (record) => {
    if (record.ctf_title === "Checkbox") {
      if (record.fd_value) {
        return record.fd_value.split(",").map((data, index) => (
          <div className="fw-bold" key={index} style={{ wordBreak: "break-word" }}>
            {data}
          </div>
        ));
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else if (record.ctf_title === "Date") {
      if (record.fd_value) {
        return (
          <div className="fw-bold" style={{ wordBreak: "break-word" }}>
            {moment(record.fd_value).format("LL")}
          </div>
        );
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else {
      return (
        <div className="fw-bold" style={{ wordBreak: "break-word" }}>
          {record.fd_value ? record.fd_value : "-"}
        </div>
      );
    }
  };

  const setTimelineFilterRecord = (record) => {
    if (timelineFilter === "all") {
      return record;
    } else if (timelineFilter === "activity") {
      return (record.record_type === "Activity" || record.record_type === "NoEditActivity") && [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id));
    } else if (timelineFilter === "note") {
      return record.record_type === "Note" || record.record_type === "Note_Prospect" || record.record_type === "Note_Organization";
    } else if (timelineFilter === "file") {
      return record.record_type === "File";
    } else if (timelineFilter === "email") {
      return record.record_type === "Email";
    } else if (timelineFilter === "whatsapp") {
      return (record.record_type === "Activity" || record.record_type === "NoEditActivity") && [8].includes(Number(record.record_activity_type_id));
    } else if (timelineFilter === "changelog") {
      return ["Ownership", "Status", "Archieve", "Reopen", "ProspectTransfer", "OrganizationTransfer"].includes(record.record_type);
    }

    return record;
  };

  const setTimelineFilterCount = (timeline) => {
    if (timelineFilter === "all" || timelineFilter === "changelog") {
      return timeline.filter(setTimelineFilterRecord).length > 0;
    } else if (["activity", "note", "file", "email", "whatsapp"].includes(timelineFilter)) {
      return timeline.filter(setTimelineFilterRecord).length > 0;
    }

    return false;
  };

  const setWidthTimeline = (record) => {
    if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === true) {
      return "90%";
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === false) {
      return "95%";
    } else if ((record.record_type !== "Activity" || record.record_type !== "CustomActivity") && setTimelineAction(record) === true) {
      return "95%";
    } else {
      return "100%";
    }
  };

  const setTimelineAction = (record) => {
    const hasReadWritePermission = Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1;
    if (hasReadWritePermission) {
      if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp") {
        if (record.record_activity_mad === "yes") {
          return false;
        } else {
          return true;
        }
      } else if (record.record_type === "Note" || record.record_type === "Note_Prospect" || record.record_type === "Note_Organization") {
        return true;
      } else if (record.record_type === "File") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setDealSourceTitle = (ds_id) => {
    const source = dealSource.find((data) => Number(data.value) === Number(ds_id));
    return source ? source.label : "";
  };

  const setPhoneNumber = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined || Number(contact) === 0) {
      return "-";
    } else if (prefix === "" || prefix === null || prefix === undefined || Number(prefix) === 0) {
      return contact;
    } else {
      let realPrefix = prefix.replace("+", "");
      var prefix2 = realPrefix.substring(0, 2);
      var prefix3 = realPrefix.substring(0, 3);
      var prefix4 = realPrefix.substring(0, 4);

      if (MobilePrefix.find((prefix) => prefix.value === prefix2)) {
        realPrefix = prefix2;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix3)) {
        realPrefix = prefix3;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix4)) {
        realPrefix = prefix4;
      } else {
        return `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      }

      const contactNumber = `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      console.log(phoneNumber);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
      }

      return contactNumber;
    }
  };

  const setProspectAddress = (record) => {
    let address = "";
    let comma = false;

    if (record.address_line1) {
      address += record.address_line1;

      if (record.address_line2) {
        address += ", ";
        address += record.address_line2;
      }

      if (record.address_line3) {
        address += ", ";
        address += record.address_line3;
      }

      if (record.postal_code) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.postal_code;
      }

      if (Number(record.city_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.city_title ? record.city_title : "");
      }

      if (Number(record.state_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.state_title ? record.state_title : "");
      }

      if (Number(record.country_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.country_title ? record.country_title : "");
      }
    } else {
      address += record.prospect_address;
    }

    return address ? address.toUpperCase() : "";
  };

  const setContactError = (errors, touched) => {
    let error = "";

    if (errors.contact1Prefix && touched.contact1Prefix) {
      error = errors.contact1Prefix;
    }

    if (errors.contact1 && touched.contact1) {
      if (error !== "") {
        error += " & ";
      }
      error += errors.contact1;
    }

    return error ? <div className="op-error-message">{error}</div> : null;
  };

  const setActivityType = (actvity_type, ca_id) => {
    if ([1, 2, 3, 4, 5].includes(Number(actvity_type))) {
      if (Number(actvity_type) === 1) {
        return "Call";
      } else if (Number(actvity_type) === 2) {
        return "Meeting";
      } else if (Number(actvity_type) === 3) {
        return "Task";
      } else if (Number(actvity_type) === 4) {
        return "Whatsapp";
      } else if (Number(actvity_type) === 5) {
        return "Email";
      }
    } else {
      return "Custom Activity";
    }

    return "";
  };

  // EDIT FUNCTION ----------------------------------------------------------------

  const editActivityModal = (rec) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: rec.record_activity_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var timeOri = data.record.activity_timed.split(":");
          var timeNew = `${timeOri[0]}:${timeOri[1]}`;

          const editData = {
            activity_dealid: data.record.deal_id,
            activity_id: data.record.activity_id,
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: timeNew,
            activity_dur: data.record.activity_duration,
            activity_user: data.record.assign_user_id,
            activity_note: data.record.activity_content,
            activity_mad: data.record.activity_mark_as_done,
          };

          setEditActivityInit(editData);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("OrganizationDetails_failed"),
            text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const editNoteModal = (rec) => {
    var type;
    var dealid;
    if (rec.record_type === "Note") {
      type = "deal";
      dealid = rec.deal_id;
    } else {
      type = "organization";
      dealid = "";
    }

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 19,
          note: rec.record_activity_id,
          type: type,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          const editData = {
            activity_dealid: dealid,
            activity_type: type,
            activity_id: rec.record_activity_id,
            activity_note: data.record.note_content,
          };

          setEditNoteInit(editData);
          setIsEditNote(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("OrganizationDetails_failed"),
            text: t("Organization_sorry_cannot_edit_note_at_the_moment"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  // OPEN FUNCNTION -------------------------------------------------------------

  const openDealDetails = (params) => {
    if (params.data.deal_id) {
      window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
    }
  };

  const openProspectDetails = (params) => {
    if (params.data.prospect_id) {
      window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
    }
  };

  // HANDLE FUNCTION ------------------------------------------------------------

  const handleProsTab = (values) => {
    setIsOrganizationTab(values);

    if (values === "contact") {
      setIsMain("");
    } else {
      setIsMain("d-none");
    }
  };

  // RENDER FUNCTION ------------------------------------------------------------

  const RenderActionTab = useCallback(
    ({ tab, active, userListNotes, activityUser }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={noteSchema}
                // onSubmit={sendProsNote}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true); // Set submitting state to true
                  sendProsNote(values)
                    .then(() => {
                      // Handle success
                    })
                    .catch((error) => {
                      console.error("Error submitting form:", error);
                    })
                    .finally(() => {
                      setSubmitting(false); // Reset submitting state
                    });
                }}
                initialValues={{
                  prosNotes: "",
                  user: session.user_id,
                }}
              >
                {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.prosNotes} onChangeValue={(value) => handleChange("prosNotes")(value)} />
                        </Form.Group>
                        {errors.prosNotes && touched.prosNotes && <div className="op-error-message">{errors.prosNotes}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      {/* <button type="submit" className="btn op-button op-primary-color text-light"> */}
                      <button
                        type="submit"
                        className="btn op-button op-primary-color text-light"
                        disabled={isSubmitting}
                        style={{
                          pointerEvents: isSubmitting ? "none" : "auto",
                          opacity: isSubmitting ? 0.6 : 1,
                        }}
                      >
                        {/* Save */}
                        {isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("OrganizationDetails_click_here_to_take_a_notes")}
              </Card.Body>
            );
          }
        case "activity":
          if (active) {
            return (
              <>
                <Formik
                  validationSchema={addActivitySchema}
                  // onSubmit={sendAddActivity}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true); // Disable the button
                    sendAddActivity(values)
                      .then(() => {
                        // Handle successful submission
                      })
                      .catch((error) => {
                        console.error("Error submitting form:", error);
                      })
                      .finally(() => {
                        setSubmitting(false); // Re-enable the button
                      });
                  }}
                  initialValues={{
                    activity_title: "",
                    activity_type: "1",
                    activity_date: moment(new Date()).format("YYYY-MM-DD"),
                    activity_time: setLatestTime(timings).value,
                    activity_dur: "",
                    activity_user: session.user_id,
                    activity_notes: "",
                    activity_mad: "no",
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control type="text" placeholder={t("OrganizationDetails_activity_title") + " *"} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <ButtonGroup className="activity-tab">
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                                  <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                  {t("OrganizationDetails_call")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                                  <FontAwesomeIcon icon={faUsers} className="me-1" />
                                  {t("OrganizationDetails_meeting")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                                  <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                  {t("OrganizationDetails_task")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                                  <FontAwesomeIcon icon={faFlag} className="me-1" />
                                  {t("OrganizationDetails_deadline")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                                  <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                  {t("OrganizationDetails_email")}
                                </ToggleButton>
                              </ButtonGroup>
                              {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Stack direction="horizontal" gap={1}>
                              <Row style={{ width: "100%" }}>
                                <Col sm={4}>
                                  <Form.Group>
                                    <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect placeholder={t("OrganizationDetails_select_time")} options={timings} valueDefault={timings.filter((option) => option.value === values.activity_time)} onChange={(e) => handleChange("activity_time")(e.value)} />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect placeholder={t("OrganizationDetails_select_duration")} options={durationList} onChange={(e) => handleChange("activity_dur")(e.value)} />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Stack>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>

                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelectV2
                                placeholder={t("OrganizationDetails_select_user") + " *"}
                                options={user}
                                value={user.filter((option) => Number(option.value) === Number(values.activity_user))}
                                isSearchable={true}
                                onChange={(e) => {
                                  handleChange("activity_user")(e ? e.value : "");
                                }}
                              />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <Form.Group className="activity-notes">
                                <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                              </Form.Group>
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        {/* <button type="submit" className="btn op-button op-primary-color text-light"> */}
                        <button
                          type="submit"
                          className="btn op-button op-primary-color text-light"
                          disabled={isSubmitting}
                          style={{
                            pointerEvents: isSubmitting ? "none" : "auto",
                            opacity: isSubmitting ? 0.6 : 1,
                          }}
                        >
                          {/* Save */}
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                        <Form.Check
                          type="switch"
                          label="Mark as done"
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              handleChange("activity_mad")("yes");
                            } else {
                              handleChange("activity_mad")("no");
                            }
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              </>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("OrganizationDetails_click_here_to_take_a_notes")}
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note" || record.record_type === "Note_Prospect" || record.record_type === "Note_Organization") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else if (record.record_type === "ProspectTransfer") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Deal") {
      return <FontAwesomeIcon icon={faDollarSign} />;
    } else {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `${t("DealDetails_deal_transfer_to")} ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return <RenderFile url={`${session.hostUrl}/${session.hostUrlType}/${record.record_file_url}`} title={`${record.record_title}.${record.record_file_ext}`} />;
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== ",") {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" className="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note":
      case "Note_Prospect":
      case "Note_Organization":
        element = record.record_title;
        // element = ReactHtmlParser(element);
        console.log(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div style={{ whiteSpace: "pre-wrap", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
      case "Email":
        element = record.record_title;
        break;
      case "ProspectTransfer":
        return (
          <div style={{ overflow: "auto" }}>
            <div>{record.record_description}</div>
          </div>
        );
      case "Deal":
        element = `${record.deal_title} - ${t("ProspectDetail_accordian_timeline_header_deal")}`;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
        break;
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const RenderTimelineDescription = useCallback(({ record }) => {
    const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;
    if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
      if (record_activity_type === "Call" && record_file_url) {
        return (
          <div style={{ padding: "10px 0" }}>
            <RenderAudio url={record_file_url} />
          </div>
        );
      } else if (record_activity_type === "Call" && record_file_url === "") {
        return "";
      } else {
        return (
          <Stack direction="vertical">
            <div className="overflow-auto positon-relative w-100" style={{ whiteSpace: "pre-wrap", maxHeight: 500 }} dangerouslySetInnerHTML={{ __html: record_description }} />
          </Stack>
        );
      }
    } else if (record_type === "Archieve") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
    } else if (record_type === "Email") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
    } else {
      return "";
    }
  }, []);

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const RenderTimelineAction = ({ record }) => {
    const customToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown}>
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={customToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                editActivityModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteActivity(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note" || record.record_type === "Note_Organization") && (
            <Dropdown.Item
              onClick={() => {
                editNoteModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_note")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note" || record.record_type === "Note_Organization") && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteNote(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_note")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const RenderOtherTab = ({ tab }) => {
    switch (tab) {
      case "deal":
        return (
          <div className="section-content" style={{ width: "100%" }}>
            <Container fluid>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact ref={prosDealGridRef} columnDefs={prosDealColumn} rowData={prosDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isOrganizationDeal && (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No deals added yet
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    You can create a new deal that could lead to exciting opportunities!{" "}
                    <a className="text-decoration-none cursor-pointer" onClick={setIsAddDeal}>
                      Create new deal
                    </a>
                  </div>
                </div>
              )}
            </Container>
          </div>
        );

      case "prospect":
        return (
          <div className="section-content" style={{ width: "100%" }}>
            <Container fluid>
              <div style={containerStyleOrgPros}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleOrgPros }}>
                  <AgGridReact
                    ref={orgProsGridRef}
                    columnDefs={orgProsColumn}
                    rowData={getOrgPros}
                    defaultColDef={{ flex: 1 }}
                    // defaultColDef={reportColDef}
                    rowHeight={70}
                    pagination={true}
                    paginationPageSize={30}
                    paginationPageSizeSelector={false}
                  />
                </div>
              </div>
              {!isOrgPros && (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M80 80a64 64 0 1 1 128 0A64 64 0 1 1 80 80zm144 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 64a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 176a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 48l117.3 0C443.5 368 496 420.5 496 485.3c0 5.9-4.8 10.7-10.7 10.7l-330.7 0c-5.9 0-10.7-4.8-10.7-10.7C144 420.5 196.5 368 261.3 368zm0-16C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7l330.7 0c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352l-117.3 0zM512 16a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 144A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm24 48c48.6 0 88 39.4 88 88c0 4.4 3.6 8 8 8s8-3.6 8-8c0-57.4-46.6-104-104-104l-92 0c1.3 5.2 2.4 10.6 3 16l89 0zm-343 0c.7-5.4 1.7-10.8 3-16l-92 0C46.6 192 0 238.6 0 296c0 4.4 3.6 8 8 8s8-3.6 8-8c0-48.6 39.4-88 88-88l89 0z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    It looks like there are no prospects for this organization yet.
                  </div>
                </div>
              )}
            </Container>
          </div>
        );

      default:
        return null;
    }
  };

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
        <Card>
          <Formik
            validationSchema={transferOrganizationSchema}
            onSubmit={sendTransferOrganization}
            initialValues={{
              user: "",
              role_id: "",
              transferOption: 2,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {t("DealDetails_transfer_ownership")}
                  <Button variant="" as={CloseButton} onClick={() => setIsTransferOwner(false)} />
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <FormSelect
                      name="user"
                      value={values.user}
                      onChange={(info) => {
                        handleChange("user")(info.value);
                        handleChange("role_id")(info.role_id);
                      }}
                      options={user}
                      placeholder={t("DealDetails_select_user")}
                    />
                    {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t("DealDetails_save")}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderOrganizationAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <Button ref={ref} variant="light" className="shadow-sm border" onClick={handleClick} aria-label="Toggle dropdown">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={getEditOrganization}>Edit Organization</Dropdown.Item>
          <Dropdown.Item onClick={sendDeleteOrganizationConfirmation}>Delete Organization</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // GRID SETUP ------------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: isOrganizationDeal ? "70vh" : "0vh", paddingTop: 20 }), [isOrganizationDeal]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleOrgPros = useMemo(() => ({ width: "100%", height: isOrgPros ? "70vh" : "0vh", paddingTop: 20 }), [isOrgPros]);
  const gridStyleOrgPros = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleAnalytic = useMemo(() => ({ width: "100%", height: "70vh" }), []);
  const gridStyleAnalytic = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prosDealColumn = [
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("OrganizationDetails_deals"),
      field: "deal_title",
      resizable: true,
      flex: 1,
      onCellClicked: openDealDetails,
    },
    {
      headerName: t("OrganizationDetails_outcome"),
      field: "deal_archieve",
      resizable: true,
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return "Open";
          } else {
            return params.data.deal_archieve;
          }
        }
      },
    },
    {
      headerName: "Deal Value",
      field: "deal_value",
      resizable: true,
      flex: 1,
      maxWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return setCurrency(params.data.deal_value);
        }
      },
    },
    {
      headerName: "Source",
      field: "ds_id",
      resizable: true,
      flex: 1,
      maxWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return setDealSourceTitle(params.data.ds_id);
        }
      },
    },
    {
      headerName: "Owner",
      field: "owner_id",
      resizable: true,
      flex: 1,
      maxWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return setOwnerName(params.data.owner_id);
        }
      },
    },
    {
      headerName: t("OrganizationDetails_expected_close_date"),
      field: "deal_expected_close_date",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_expected_close_date).format("ll");
        }
      },
    },
    {
      headerName: t("OrganizationDetails_remark"),
      field: "archieve_remark",
      resizable: true,
      flex: 1,
    },
  ];

  const orgProsColumn = [
    {
      headerName: "Created",
      field: "prospect_date_time_create",
      resizable: true,
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.prospect_date_time_create).format("lll");
        }
      },
    },
    {
      headerName: t("OrganizationDetails_prospect_name"),
      field: "prospect_name",
      resizable: true,
      width: 300,
      onCellClicked: openProspectDetails,
    },
    {
      headerName: t("ProspectList_headername_2"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div className="d-flex">
              {(params.data.contact1 || (Number(params.data.contact_count) > 0 && params.data.contacts[0])) && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact1_prefix ? params.data.contact1_prefix : "", params.data.contact1 ? params.data.contact1 : Number(params.data.contact_count) > 0 && params.data.contacts[0] ? params.data.contacts[0].contact_num : "")}
                </div>
              )}
              {params.data.contact2 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact2_prefix ? params.data.contact2_prefix : "", params.data.contact2 ? params.data.contact2 : "")}
                </div>
              )}
              {params.data.contact3 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact3_prefix ? params.data.contact3_prefix : "", params.data.contact3 ? params.data.contact3 : "")}
                </div>
              )}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ProspectList_headername_3"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div className="d-flex">
              {params.data.email1 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {params.data.email1}
                </div>
              )}
              {params.data.email2 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {params.data.email2}
                </div>
              )}
            </div>
          );
        }
      },
    },
    { headerName: t("ProspectList_headername_5"), field: "prospect_address", minWidth: 500, cellRenderer: (params) => (params.data ? setProspectAddress(params.data) : "") },
  ];

  const [analyticColumn] = useState([
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_1"),
      field: "deal_title",
      resizable: true,
      flex: 1,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(session.origin + "/deal/" + params.data.deal_id);
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_2"),
      field: "deal_value",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.value) {
          return setCurrency(params.data.deal_value);
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_4"),
      field: "deal_expected_close_date",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_expected_close_date).format("ll");
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_3"),
      field: "archieve_remark",
      resizable: true,
      flex: 1,
    },
  ]);

  // USE EFFECT ------------------------------------------------------------------

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getIndustry();
      getCountry();
      getUser();
      getOrganizationDet();
      getProsTimeline();
      getOrganizationDeals();
      getAllPipeline();
      getDealField();
      getDealSource();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth - 50);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, [containerRef.current]);

  return (
    <Container fluid className="p-0">
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="main">
          
          <div className="section-header position-relative">
            <Stack direction="horizontal" gap={2} className="w-100 pt-3 px-4 mb-2">
              <div className="d-flex">
                <div className="avatar me-2">
                  <div className="avatar-img" style={{ width: 70, height: 70, backgroundColor: "#eee" }}>
                    <div className="avatar-txt text-uppercase" style={{ fontSize: "35px" }}>
                      {initial}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <div className="op-text-bigger fs-5 me-2">{orgDetData.organization_title}</div>
                </div>
              </div>
              <div className="ms-auto" />

              <div className="d-flex align-items-center justify-content-center">
                {imgError ? (
                  <div className="avatar me-2">
                    <div className="avatar-img op-primary-color" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                      <div className="avatar-txt text-uppercase fs-5 text-light">{Number(orgDetData?.user_id) > 0 ? setOwnerName(orgDetData.owner_id).split("")[0] : ""}</div>
                    </div>
                  </div>
                ) : (
                  <div className="me-2">
                    <img className="rounded-circle" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${orgDetData.owner_id}.png?c=${Math.random()}`} width={35} height={35} onError={setCheckImg} />
                  </div>
                )}

                <div className="h-100" style={{ lineHeight: 1.2 }}>
                  <span className="op-text-bigger fw-bold">{setOwnerName(orgDetData.owner_id)}</span>
                  <br />
                  <span className="op-text-medium">Owner</span>
                </div>

                {(Number(session.role_id) === 1 || Number(session.user_id) === Number(orgDetData.owner_id)) && (
                  <Dropdown show={isTransferOwner} align="end" className="ms-2 me-2">
                    <Dropdown.Toggle ref={transferOwnerRef} className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                    <Dropdown.Menu as={RenderTransferOwnership} />
                  </Dropdown>
                )}
              </div>

              {/* <button className="btn op-primary-color text-light" onClick={setIsAddDeal}>
                <FontAwesomeIcon icon={faPlusSolid} className="me-1" /> Deal
              </button> */}

              <RenderOrganizationAction />
            </Stack>

            <ButtonGroup className="activity-tab2 rounded-0 px-4" style={{ width: "100%" }}>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="contact"
                checked={isOrganizationTab === "contact"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("contact");
                }}
              >
                {t("OrganizationDetails_contact")}
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="deal"
                checked={isOrganizationTab === "deal"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("deal");
                }}
              >
                {t("OrganizationDetails_deals")}
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="prospect"
                checked={isOrganizationTab === "prospect"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("prospect");
                }}
              >
                {t("OrganizationDetails_prospect")}
              </ToggleButton>
            </ButtonGroup>
          </div>

          <div className="section-body">
            <div className={`section-sidebar ${isMain}`}>
              <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="0">Summary</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button
                        type="button"
                        className="btn btn-light border"
                        onClick={() => {
                          getEditOrganization();
                        }}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="0">
                    <div className="accordion-body px-0">
                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBuilding} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">{t("OrganizationDetails_address")}</div>
                          <div className="fw-bold">{orgDetData.organization_address !== "" ? orgDetData.organization_address : "-"}</div>
                        </div>
                      </div>

                      <div
                        className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                        onClick={() => {
                          if (orgDetData?.organization_web_url) {
                            window.open(`${orgDetData?.organization_web_url}`, "_blank");
                          } else {
                            Swal.fire({
                              text: "The website URL is currently unavailable. Please check back your organization information to update the website URL or contact support for assistance.",
                              icon: "warning",
                            });
                          }
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faGlobe} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">Website</div>
                            <div className="fw-bold">{orgDetData?.organization_web_url ? orgDetData?.organization_web_url : "-"}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">{t("OrganizationDetails_contact_number")}</div>
                          <div className="fw-bold">{orgDetData.organization_contact !== "" ? orgDetData.organization_contact : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faIndustryWindows} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">{t("OrganizationDetails_industry")}</div>
                          <div className="fw-bold">{orgDetData.in_title !== "" ? orgDetData.in_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faEarthAsia} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Country</div>
                          {setOrganizationCountryValue()}
                        </div>
                      </div>

                      {Number(orgDetData.custom_field) > 0 && (
                        <>
                          {orgDetData.custom_field_data.map((record, index) => (
                            <div className="d-flex p-2 w-100" key={index}>
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faPlus} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">{record.cf_label}</div>
                                {setCustomFieldValues(record)}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t("ProspectDetail_accordian_analytics")}</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body px-0">
                      <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={() => setAnalyticOpen(true)}>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleDollar} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_open) === 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : Number(analyticData.total_open) > 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : t("ProspectDetail_accordian_analytics_content_1_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData?.open_amount ? analyticData.open_amount : 0)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={() => setAnalyticWon(true)}>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleCheckLight} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_won) === 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : Number(analyticData.total_won) > 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : t("ProspectDetail_accordian_analytics_content_3_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData?.won_amount ? analyticData.won_amount : 0)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={() => setAnalyticLost(true)}>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleXMarkLight} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_lost) === 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : Number(analyticData.total_lost) > 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : t("ProspectDetail_accordian_analytics_content_4_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData?.lost_amount ? analyticData.lost_amount : 0)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={() => setAnalyticInbLd(true)}>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faBoxArchive} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">{Number(analyticData.total_deleted) === 1 ? "1 Archieve Deal" : Number(analyticData.total_deleted) > 1 ? `${analyticData.total_deleted} Archieve Deal` : "No Archieve Deal"}</div>
                            <div className="fw-bold">{setCurrency(analyticData?.deleted_amount ? analyticData.deleted_amount : 0)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className={`section-content ${isMain}`} ref={containerRef}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t("OrganizationDetails_notes")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t("OrganizationDetails_activity")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab tab={activeTab} active={isActiveTab} />
                  {/* <RenderActionTab tab={activeTab} active={isActiveTab} userListNotes={userList} activityUser={userList} /> */}
                </Card>

                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("ProspectDetail_accordian_timeline")}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0" style={{ width }}>
                      <div className="accordion-body">
                        <ButtonGroup className="timeline-tab mb-3">
                          <ToggleButton variant="" type="radio" name="timeline_filter" id="timeline_filter_1" value="1" checked={timelineFilter === "all"} onClick={() => setTimelineFilter("all")}>
                            All
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="timeline_filter" id="timeline_filter_2" value="2" checked={timelineFilter === "note"} onClick={() => setTimelineFilter("note")}>
                            Notes ({timelineData.filter((record) => record.record_type === "Note" || record.record_type === "Note_Prospect" || record.record_type === "Note_Organization").length})
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="timeline_filter" id="timeline_filter_2" value="2" checked={timelineFilter === "activity"} onClick={() => setTimelineFilter("activity")}>
                            Activities ({timelineData.filter((record) => (record.record_type === "Activity" || record.record_type === "NoEditActivity") && [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id))).length})
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="timeline_filter" id="timeline_filter_2" value="2" checked={timelineFilter === "changelog"} onClick={() => setTimelineFilter("changelog")}>
                            Changelog
                          </ToggleButton>
                        </ButtonGroup>

                        {timelineData && setTimelineFilterCount(timelineData) ? (
                          <VerticalTimeline layout="1-column-left">
                            {timelineData.filter(setTimelineFilterRecord).map((record, index) => (
                              <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                <Stack direction="horizontal" gap={1} style={{ alignItems: "normal" }}>
                                  <div style={{ width: setWidthTimeline(record) }}>
                                    <div className={`op-text-bigger fw-bold`}>
                                      <RenderTimelineHeader record={record} />
                                    </div>
                                    <RenderTimelineDocument record={record} />
                                  </div>

                                  {(record.record_type === "Activity" || record.record_type === "CustomActivity") && (
                                    <div className="mt-1 me-1" style={{ width: "3%" }}>
                                      <div className="w-100 d-flex justify-content-center float-end" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                        {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                      </div>
                                    </div>
                                  )}

                                  {setTimelineAction(record) ? (
                                    <div style={{ width: "5%" }}>
                                      <div className="float-end">
                                        <RenderTimelineAction record={record} />
                                      </div>
                                    </div>
                                  ) : null}
                                </Stack>
                                <div style={{ fontSize: 11, maxWidth: "95%", wordWrap: "break-word" }}>
                                  <RenderTimelineDescription record={record} />
                                </div>
                              </VerticalTimelineElement>
                            ))}
                          </VerticalTimeline>
                        ) : timelineFilter === "note" ? (
                          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                            <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#a)">
                                <path
                                  d="M72.8 54.813H56.913a2.05 2.05 0 0 0-2.05 2.05V72.75M72.8 56.35V14.325a3.075 3.075 0 0 0-3.075-3.075h-59.45A3.075 3.075 0 0 0 7.2 14.325v55.35a3.075 3.075 0 0 0 3.075 3.075H56.4l16.4-16.4Z"
                                  stroke="#B6BBC3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="m27.33 48.883 6.788 6.789 22.009-22.009 2.541-2.541a2.4 2.4 0 0 0 0-3.394l-3.394-3.394a2.4 2.4 0 0 0-3.394 0l-1.984 1.983 6.787 6.788-1.414 1.414-6.787-6.787L27.33 48.883Zm5.12 7.949-6.28-6.28-2.064 6.191-.697 2.09a.6.6 0 0 0 .76.76l2.087-.697 6.194-2.064Z"
                                  fill="#B6BBC3"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="a">
                                  <path fill="#fff" d="M0 0h80v80H0z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mt-2" style={{ fontSize: 14 }}>
                              No notes added yet
                            </span>
                          </div>
                        ) : timelineFilter === "activity" ? (
                          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                            <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M56 6v8M24 6v8M10 24.5h60M67 70H13a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v54a3 3 0 0 1-3 3Z" stroke="#B6BBC3" stroke-width="2" stroke-linecap="round"></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM24 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                fill="#B6BBC3"
                              ></path>
                            </svg>
                            <span className="mt-2" style={{ fontSize: 14 }}>
                              No schedule activity added yet
                            </span>
                          </div>
                        ) : timelineFilter === "all" || timelineFilter === "changelog" ? (
                          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                            <svg width="80" height="80" fill="#B6BBC3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path d="M144 96l11.3-11.3L208 32l224 0 16 0 0 16 0 96 0 16-16 0-224 0-52.7-52.7L144 96zm288 48l0-96L214.6 48l-48 48 48 48L432 144zM96 96A32 32 0 1 0 32 96a32 32 0 1 0 64 0zM16 96a48 48 0 1 1 96 0A48 48 0 1 1 16 96zM96 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-80 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM64 384a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0 80a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm80-48l11.3-11.3L208 352l224 0 16 0 0 16 0 96 0 16-16 0-224 0-52.7-52.7L144 416zm288 48l0-96-217.4 0-48 48 48 48L432 464zM155.3 267.3L144 256l11.3-11.3L208 192l288 0 16 0 0 16 0 96 0 16-16 0-288 0-52.7-52.7zM214.6 304L496 304l0-96-281.4 0-48 48 48 48z" />
                            </svg>
                            <span className="mt-2" style={{ fontSize: 14 }}>
                              {timelineFilter === "all" ? "No record found" : "No changelog yet"}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Container>
            </div>
            <RenderOtherTab tab={isOrganizationTab} />
          </div>

          <Modal show={isEditOrganization} onHide={setIsEditOrganization} size="lg" backdrop="static">
            <Formik onSubmit={sendEditOrganization} validationSchema={editProsSchema} initialValues={initialVal}>
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_organization_details")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_name")}</Form.Label>
                          <Form.Control type="text" value={values.orgName} isInvalid={errors.orgName && touched.orgName} onChange={handleChange("orgName")} />
                          {errors.orgName && touched.orgName && <div className="op-error-message">{errors.orgName}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_industry")}</Form.Label>
                          <FormSelect
                            options={Array.isArray(industryList) ? industryList : []}
                            placeholder={t("OrganizationDetails_select_industry")}
                            onChange={(e) => {
                              handleChange("industry")(e.value);
                            }}
                            valueDefault={Array.isArray(industryList) ? industryList.find((option) => option.value === String(values.industry)) : undefined}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_website_url")}</Form.Label>
                          <Form.Control type="text" value={values.webUrl} onChange={handleChange("webUrl")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_phone_number")}</Form.Label>
                          <Form.Control type="text" value={values.phoneNumber} onChange={(e) => handleChange("phoneNumber")(e.target.value.replace(/\D/g, ''))} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_organization_address")}</Form.Label>
                          <Form.Control as={"textarea"} rows={3} type="text" onChange={handleChange("orgAddress")} placeholder={t("OrganizationDetails_organization_address")} value={values.orgAddress} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_country")}</Form.Label>
                          <FormSelect
                            options={Array.isArray(countryData) ? countryData : []}
                            placeholder={t("OrganizationDetails_select_country")}
                            onChange={(e) => {
                              handleChange("country")(e.value);
                            }}
                            valueDefault={Array.isArray(countryData) ? countryData.find((option) => option.value === String(values.country)) : undefined}
                          />
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>{t("OrganizationDetails_prospect_owner")}</Form.Label>
                          <FormSelect
                            options={user}
                            placeholder={t("OrganizationDetails_select_owner")}
                            onChange={(e) => {
                              handleChange("owner")(e.value);
                            }}
                            valueDefault={Array.isArray(user) ? user.find((option) => option.value === String(values.owner)) : undefined}
                          />
                        </Form.Group> */}
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Data Fields</div>
                        {Number(editProsCf.length) > 0 ? (
                          <>
                            {editProsCf.map((record, index) => (
                              <FormCustomField
                                key={record.cf_id}
                                id={record.cf_id}
                                label={record.cf_label}
                                options={record.cf_value}
                                type={record.ctf_title}
                                name={record.cf_id}
                                placeholder=""
                                firstOptions={false}
                                valueDefault={false}
                                isClearable={true}
                                value={values.cf_person[index].fd_values}
                                onChange={(info) => {
                                  const updatedCustomFields = [...values.cf_person];
                                  if (record.ctf_title === "Select") {
                                    updatedCustomFields[index].fd_values = info.value;
                                  } else if (record.ctf_title === "Radio") {
                                    if (info.target) {
                                      const { value, checked } = info.target;
                                      updatedCustomFields[index].fd_values = checked ? value : "";
                                    } else {
                                      updatedCustomFields[index].fd_values = "";
                                    }
                                  } else if (record.ctf_title === "Checkbox") {
                                    const { value, checked } = info.target;
                                    if (checked) {
                                      updatedCustomFields[index].fd_values.push(value);
                                    } else {
                                      const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                      if (indexToRemove !== -1) {
                                        updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                      }
                                    }
                                  } else if (record.ctf_title === "Date") {
                                    updatedCustomFields[index].fd_values = info;
                                  } else {
                                    updatedCustomFields[index].fd_values = info.target.value;
                                  }

                                  setFieldValue("cf_person", updatedCustomFields);
                                }}
                              />
                            ))}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No data fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("OrganizationDetails_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setIsEditNote} size="lg">
            <Formik validationSchema={editNoteSchema} onSubmit={sendEditNote} initialValues={editNoteInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_note")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Group className="activity-notes">
                        <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} />
                      </Form.Group>
                      {errors.activity_note && touched.activity_note && <div className="op-error-message">{errors.activity_note}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("OrganizationDetails_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik validationSchema={editActivitySchema} onSubmit={sendEditActivity} initialValues={editActivityInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_activity")} - {setActivityType(values.activity_type, values.ca_id)}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("OrganizationDetails_activity_title") + " *"} value={values.activity_title} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                              <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                              {t("OrganizationDetails_call")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                              <FontAwesomeIcon icon={faUsers} className="me-1" />
                              {t("OrganizationDetails_meeting")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                              <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                              {t("OrganizationDetails_task")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                              <FontAwesomeIcon icon={faFlag} className="me-1" />
                              {t("OrganizationDetails_deadline")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                              {t("OrganizationDetails_email")}
                            </ToggleButton>
                          </ButtonGroup>
                          {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Stack direction="horizontal" gap={1}>
                          <Row style={{ width: "100%" }}>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect placeholder={t("OrganizationDetails_select_time")} options={timings} valueDefault={timings.filter((option) => option.value === values.activity_time)} onChange={(e) => handleChange("activity_time")(e.value)} />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect placeholder={t("OrganizationDetails_select_duration")} options={durationList} valueDefault={durationList.filter((option) => option.value === values.activity_dur)} onChange={(e) => handleChange("activity_dur")(e.value)} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Stack>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>

                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("OrganizationDetails_select_user") + " *"}
                            options={user}
                            valueDefault={user.filter((option) => Number(option.value) === Number(values.activity_user))}
                            isInvalid={errors.activity_user && touched.activity_user}
                            isSearchable={true}
                            onChange={(e) => {
                              handleChange("activity_user")(e ? e.value : "");
                            }}
                          />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <Form.Group className="activity-notes">
                            <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} />
                          </Form.Group>
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label="Mark as done"
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.activity_mad === "yes"}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChange("activity_mad")("yes");
                        } else {
                          handleChange("activity_mad")("no");
                        }
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("OrganizationDetails_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddDeal} onHide={setIsAddDeal} size="lg" backdrop="static">
            <Formik
              validationSchema={newDealSchema}
              onSubmit={sendCreateDeal}
              initialValues={{
                pipeline_id: "",
                cs_id: "",
                deal_title: "New Deal - " + orgDetData.organization_title,
                deal_source: "",
                deal_value: "0",
                expected_close_date: "",
                prospect_name: "",
                prospect_id: "",
                contact1Prefix: "60",
                contact1: "",
                contact_id: "",
                contact1_disabled: false,
                organization: orgDetData.organization_id,
                cf_deals: [],
                cf_person: [],
                cf_deals_record: [],
                cf_person_record: [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealList_add_deal")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col className="w-50 px-4 py-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Pipeline *</Form.Label>
                          <FormSelectV2
                            name="pipeline_id"
                            options={pipeData}
                            isSearchable={true}
                            isInvalid={errors.pipeline_id && touched.pipeline_id}
                            value={pipeData.filter((option) => Number(option.value) === Number(values.pipeline_id))}
                            onChange={async (e) => {
                              setFieldValue("pipeline_id", e ? e.value : "");
                              if (e.value) {
                                const cs_id = await getDefCsID(e.value);
                                setFieldValue("cs_id", cs_id);
                                const customField = await getCustomFieldAddDeal(e.value);
                                if (customField.cf_deals.length > 0) {
                                  setFieldValue("cf_deals_record", customField.cf_deals);
                                  setFieldValue(
                                    "cf_deals",
                                    customField.cf_deals.map((record) => ({ cf_id: record.cf_id, name: record.cf_id, fd_values: record.ctf_title === "Checkbox" ? [] : "", cf_label: record.cf_label, required: Number(record.compulsory_column) === 1 ? true : false }))
                                  );
                                } else {
                                  setFieldValue("cf_deals", []);
                                }

                                if (customField.cf_person.length > 0) {
                                  setFieldValue("cf_person_record", customField.cf_person);
                                  setFieldValue(
                                    "cf_person",
                                    customField.cf_person.map((record) => ({ cf_id: record.cf_id, name: record.cf_id, fd_values: record.ctf_title === "Checkbox" ? [] : "", cf_label: record.cf_label, required: Number(record.compulsory_column) === 1 ? true : false }))
                                  );
                                } else {
                                  setFieldValue("cf_person", []);
                                }
                              } else {
                                setFieldValue("cs_id", "");
                                setFieldValue("customField", []);
                                setFieldValue("cf_deals", []);
                                setFieldValue("cf_person", []);
                              }
                            }}
                          />
                          {errors.pipeline_id && touched.pipeline_id && <div className="op-error-message">{errors.pipeline_id}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DeaList_contact_person")} *</Form.Label>
                          <FormSelectCreatable
                            name="prospect_id"
                            loadOptions={getProspect}
                            isLoading={isFindProspect}
                            isInvalid={errors.prospect_id && touched.prospect_id}
                            onChange={(info) => {
                              setFieldValue("prospect_name", info.label);
                              setFieldValue("prospect_id", info.value);

                              if (Number(info.value) > 0) {
                                setFieldValue("contact1", info.contact1);
                                setFieldValue("contact_id", info.contact_id);
                                setFieldValue("contact1Prefix", info.contact1_prefix);
                                if (info.contact1) {
                                  setFieldValue("contact1_disabled", true);
                                } else {
                                  setFieldValue("contact1_disabled", false);
                                }
                              } else {
                                setFieldValue("contact1", "");
                                setFieldValue("contact1Prefix", "60");
                                setFieldValue("contact_id", "");
                                setFieldValue("contact1_disabled", false);
                              }
                            }}
                          />
                          {errors.prospect_id && touched.prospect_id && <div className="op-error-message">{errors.prospect_id}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealList_deal_title")} *</Form.Label>
                          <Form.Control name="deal_title" value={values.deal_title} type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("deal_title")} />
                          {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                        </Form.Group>

                        {dealSourceShow && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealList_deal_source")}</Form.Label>
                            <FormSelect name="deal_source" options={dealSource} isSearchable={true} placeholder="" shadow={false} onChange={(info) => handleChange("deal_source")(info.value)} />
                          </Form.Group>
                        )}

                        {dealValueShow && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealList_deal_value")}</Form.Label>
                            <FormCurrency name="deal_value" value={values.deal_value} onChange={(e) => handleChange("deal_value")(e.target.value)} />
                          </Form.Group>
                        )}

                        {expectedCloseDateShow && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealList_expected_close_date")}</Form.Label>
                            <FormDate name="expected_close_date" value={values.expected_close_date} onChange={(date) => handleChange("expected_close_date")(date)} />
                          </Form.Group>
                        )}

                        {values.cf_deals_record.length > 0 &&
                          values.cf_deals_record.map((record, index) => (
                            <FormCustomField
                              key={record.cf_id}
                              id={record.cf_id}
                              label={record.cf_label + (Number(record.compulsory_column) === 1 ? " *" : "")}
                              options={record.cf_value}
                              type={record.ctf_title}
                              name={record.cf_id}
                              placeholder=""
                              firstOptions={false}
                              valueDefault={false}
                              isClearable={true}
                              value={values.cf_deals[index].fd_values}
                              onChange={(info) => {
                                const updatedCustomFields = [...values.cf_deals];
                                if (record.ctf_title === "Select") {
                                  updatedCustomFields[index].fd_values = info.value;
                                } else if (record.ctf_title === "Radio") {
                                  const { value, checked } = info.target;
                                  updatedCustomFields[index].fd_values = checked ? value : "";
                                } else if (record.ctf_title === "Checkbox") {
                                  const { value, checked } = info.target;
                                  if (checked) {
                                    updatedCustomFields[index].fd_values.push(value);
                                  } else {
                                    const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                    if (indexToRemove !== -1) {
                                      updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                    }
                                  }
                                } else if (record.ctf_title === "Date") {
                                  updatedCustomFields[index].fd_values = info;
                                } else {
                                  updatedCustomFields[index].fd_values = info.target.value;
                                }

                                setFieldValue("cf_deals", updatedCustomFields);
                              }}
                            />
                          ))}
                      </Col>
                      <Col className="w-50 px-4 py-3">
                        <div className="op horizontal-start divider">{t("DealList_person")}</div>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealList_contact_number")}</Form.Label>
                          <Stack direction="horizontal" className="mb-2" gap={2}>
                            <FormSelectV2
                              name="contact1Prefix"
                              isInvalid={errors.contact1Prefix && touched.contact1Prefix}
                              options={MobilePrefix}
                              isSearchable
                              width="80px"
                              disabled={values.contact1_disabled}
                              value={MobilePrefix.filter((option) => option.value === values.contact1Prefix)}
                              onChange={(e) => setFieldValue("contact1Prefix", e ? e.value : "")}
                            />
                            <Form.Control name="contact1" type="text" isInvalid={errors.contact1 && touched.contact1} value={values.contact1} disabled={values.contact1_disabled} onChange={(e) => setFieldValue("contact1", e.target.value)} />
                          </Stack>
                          {setContactError(errors, touched)}
                        </Form.Group>

                        {values.cf_person_record.length > 0 &&
                          values.cf_person_record.map((record, index) => (
                            <FormCustomField
                              key={record.cf_id}
                              id={record.cf_id}
                              label={record.cf_label + (Number(record.compulsory_column) === 1 ? " *" : "")}
                              options={record.cf_value}
                              type={record.ctf_title}
                              name={record.cf_id}
                              placeholder=""
                              firstOptions={false}
                              valueDefault={false}
                              isClearable={true}
                              value={values.cf_person[index].fd_values}
                              onChange={(info) => {
                                const updatedCustomFields = [...values.cf_person];
                                if (record.ctf_title === "Select") {
                                  updatedCustomFields[index].fd_values = info.value;
                                } else if (record.ctf_title === "Radio") {
                                  const { value, checked } = info.target;
                                  updatedCustomFields[index].fd_values = checked ? value : "";
                                } else if (record.ctf_title === "Checkbox") {
                                  const { value, checked } = info.target;
                                  if (checked) {
                                    updatedCustomFields[index].fd_values.push(value);
                                  } else {
                                    const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                    if (indexToRemove !== -1) {
                                      updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                    }
                                  }
                                } else if (record.ctf_title === "Date") {
                                  updatedCustomFields[index].fd_values = info;
                                } else {
                                  updatedCustomFields[index].fd_values = info.target.value;
                                }

                                setFieldValue("cf_person", updatedCustomFields);
                              }}
                            />
                          ))}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={addDealDisabledBtn}>
                      {addDealDisabledBtn ? <Spinner animation="border" size="sm" /> : t("DealList_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={analyticOpen} onHide={setAnalyticOpen} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_openDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isOpenDeal ? (
                <div className="mt-0" style={containerStyleAnalytic}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleAnalytic, borderRadius: 8 }}>
                    <AgGridReact columnDefs={analyticColumn} rowData={openDealRowData} defaultColDef={reportColDef} rowHeight={50} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for open deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticWon} onHide={setAnalyticWon} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_wonDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isWonDeal ? (
                <div style={containerStyleAnalytic}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleAnalytic, borderRadius: 8 }}>
                    <AgGridReact columnDefs={analyticColumn} rowData={wonDealRowData} defaultColDef={reportColDef} rowHeight={50} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm58.3-13.7c3.1-3.1 8.2-3.1 11.3 0L480 396.7l74.3-74.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3l-80 80c-3.1 3.1-8.2 3.1-11.3 0l-48-48c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for won deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticInbLd} onHide={setAnalyticInbLd} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_inbLd")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isInbLd ? (
                <div style={containerStyleAnalytic}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleAnalytic, borderRadius: 8 }}>
                    <AgGridReact columnDefs={analyticColumn} rowData={inbLdRowData} defaultColDef={reportColDef} rowHeight={50} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M480 48L32 48c-8.8 0-16 7.2-16 16l0 48c0 8.8 7.2 16 16 16l16 0 416 0 16 0c8.8 0 16-7.2 16-16l0-48c0-8.8-7.2-16-16-16zm0 96l-16 0L48 144l-16 0c-17.7 0-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32l448 0c17.7 0 32 14.3 32 32l0 48c0 17.7-14.3 32-32 32zM160 232c0-4.4 3.6-8 8-8l176 0c4.4 0 8 3.6 8 8s-3.6 8-8 8l-176 0c-4.4 0-8-3.6-8-8zm304-56l16 0 0 240c0 35.3-28.7 64-64 64L96 480c-35.3 0-64-28.7-64-64l0-240 16 0 0 240c0 26.5 21.5 48 48 48l320 0c26.5 0 48-21.5 48-48l0-240z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for archived deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticLost} onHide={setAnalyticLost} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_lostDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isLostDeal ? (
                <div style={containerStyleAnalytic}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleAnalytic, borderRadius: 8 }}>
                    <AgGridReact columnDefs={analyticColumn} rowData={lostDealRowData} defaultColDef={reportColDef} rowHeight={50} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm66.3-61.7c3.1-3.1 8.2-3.1 11.3 0L496 356.7l50.3-50.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3L507.3 368l50.3 50.3c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L496 379.3l-50.3 50.3c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L484.7 368l-50.3-50.3c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for lost deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </Container>
  );
};

export default OrganizationDetails;
