import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/pro-duotone-svg-icons";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import { usePermission } from "../auth/PermissionContext";

const DealEngagementReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    // const navigate = useNavigate();
    const { permission } = usePermission();

    const [isLoading, setIsLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [trigger, setTrigger] = useState(0);

    const [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [userID, setUserID] = useState(0);

    const { t } = useTranslation();

    const getAllUser = () => {

        axios.get('ws/ws_user.php',{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((rec)=>{
                    return {label: rec.user_name, value: rec.user_id};
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const getChartData = useCallback(()=>{
        
        axios.get("ws/ws_deal_engagement.php",{
            params:{
                task: "dealEngagementReport",
                ds: startDate,
                de: endDate,
                user: userID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            var chartData = [
                {name: t('Report_DealEngagement_BarName1'), value: Number(data.TOTAL_OPEN_DEALS)},
                {name: t('Report_DealEngagement_BarName2'), value: Number(data.TOTAL_ACTIVE_DEAL)},
                {name: t('Report_DealEngagement_BarName3'), value: Number(data.TOTAL_FRESH_DEAL)},
                {name: t('Report_DealEngagement_BarName4'), value: Number(data.TOTAL_INACTIVE_DEAL)},
                {name: t('Report_DealEngagement_BarName5'), value: Number(data.TOTAL_DEAL_FIRST_CONTACT)},
                {name: t('Report_DealEngagement_BarName6'), value: Number(data.TOTAL_DEAL_FOLLOW_UP)}
            ];

            setChartData(chartData);
        });
    },[startDate, endDate, userID, session]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div>{`${t('Report_DealEngagement_TooltipLabelDeal')}: `}</div>
                        <div>{`${payload[0].value}`}</div>
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [gridColumn] = useState([
        {
            headerName: t('Report_DealEngagement_HeaderName1'),
            field: "date_time_create",
            resizable: true,
            width: 180,
            valueGetter: (params) => {
                if (params.data) {
                    return moment(params.data.date_time_create).format("lll");
                }
            }
        },
        {
            headerName: t('Report_DealEngagement_HeaderName2'),
            field: "user_name",
            resizable: true,
            width: 180
        },
        {
            headerName: t('Report_DealEngagement_HeaderName3'),
            field: "deal_title",
            resizable: true,
            width: 200,
            onCellClicked: function (params) {
                if (params.data) {
                  window.open(`${session.origin}/deal/${params.data.deal_id}`, '_blank');
                }
            },
        },
        {
            headerName: t('Report_DealEngagement_HeaderName4'),
            field: "",
            resizable: true,
            flex: 1,
            valueGetter: (params) => {
                if (params.data) {
                    
                    if ((params.data.record_type === 'deal_won')||(params.data.record_type === 'deal_lost')||(params.data.record_type === 'deal_deleted')) {
                        var str = "";

                        if (params.data.record_type === 'deal_won') {
                            str = "Deal Won";
                        }else if(params.data.record_type === 'deal_lost'){
                            str = "Deal Lost";
                        }else if(params.data.record_type === 'deal_deleted'){
                            str = "Deal Deleted";
                        }

                        return str;

                    }else{

                        var str1 = "";

                        var str2 = "";
                        
                        if (params.data.previous_status === "active") {
                            str1 = "Progressing";
                        }else if(params.data.previous_status === "inactive"){
                            str1 = "Follow Up";
                        }else if(params.data.previous_status === "new"){
                            str1 = "Contact Lead";
                        }

                        if (params.data.new_status === "active") {
                            str2 = "Progressing";
                        }else if(params.data.new_status === "inactive"){
                            str2 = "Follow Up";
                        }else if(params.data.new_status === "new"){
                            str2 = "Contact Lead";
                        }

                        return `${str1} > ${str2}` 
                    }

                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    
                    if ((params.data.record_type === 'deal_won')||(params.data.record_type === 'deal_lost')||(params.data.record_type === 'deal_deleted')) {
                        var str = "";
                        var color = "";

                        if (params.data.record_type === 'deal_won') {
                            str = "Deal Won";
                            color = '#76d276';
                        }else if(params.data.record_type === 'deal_lost'){
                            str = "Deal Lost";
                            color = "#ff7676";
                        }else if(params.data.record_type === 'deal_deleted'){
                            str = "Deal Deleted";
                            color = "#ecb35e";
                        }

                        return (
                            <div className="op-label text-light" style={{backgroundColor: color}}>
                                {str}
                            </div>
                        );

                    }else{

                        var str1 = "";
                        var color1 = "";

                        var str2 = "";
                        var color2 = "";
                        
                        if (params.data.previous_status === "active") {
                            str1 = "Progressing";
                            color1 = "#76d276";
                        }else if(params.data.previous_status === "inactive"){
                            str1 = "Follow Up";
                            color1 = "#ecb35e";
                        }else if(params.data.previous_status === "new"){
                            str1 = "Contact Lead";
                            color1 = "#ff7676";
                        }

                        if (params.data.new_status === "active") {
                            str2 = "Progressing";
                            color2 = "#76d276";
                        }else if(params.data.new_status === "inactive"){
                            str2 = "Follow Up";
                            color2 = "#ecb35e";
                        }else if(params.data.new_status === "new"){
                            str2 = "Contact Lead";
                            color2 = "#ff7676";
                        }

                        return(
                            <>
                                <div className="op-label text-light" style={{backgroundColor: color1}}>
                                    {str1}
                                </div>
                                {" > "}
                                <div className="op-label text-light" style={{backgroundColor: color2}}>
                                    {str2}
                                </div>
                            </>
                        );
                    }

                }
            }
        },
        {
            headerName: t('Report_DealEngagement_HeaderName5'),
            field: "change_remark",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_DealEngagement_HeaderName6'),
            field: "",
            resizable: true,
            width: 250,
            valueGetter: (params) => {
                if (params.data) {

                    if (params.data.record_type === 'activity') {
                        var res = params.data.activity_content;

                        if(res !== undefined && res !== null && res !== ''){
                            res = res.replace(/<br>/g, "\r\n");
                            res = res.replace(/<li>/g, "\r\n");
                            res = res.replace(/<[^>]+>/g, '');
                            res = res.replace(/&nbsp;/g, '');
                        }else{
                            res = "";
                        }

                        return params.data.activity_title && res ? `${params.data.activity_title} | ${res}` : params.data.activity_title ? `${params.data.activity_title} | ${res}` : "";
                    }else if(params.data.record_type === 'note'){
                        var res = params.data.note_content;

                        if(res !== undefined && res !== null && res !== ''){
                            res = res.replace(/<br>/g, "\r\n");
                            res = res.replace(/<li>/g, "\r\n");
                            res = res.replace(/<[^>]+>/g, '');
                            res = res.replace(/&nbsp;/g, '');
                        }else{
                            res = "";
                        }

                        return res;
                    }else if(params.data.record_type === 'email'){
                        return params.data.email_subject;
                    }else if(params.data.record_type === 'call'){
                        if (params.data.audio_url) {
                            return `${params.data.type} call with status ${params.data.status}`;
                        }
                    }else if((params.data.record_type === 'deal_won')||(params.data.record_type === 'deal_lost')||(params.data.record_type === 'deal_deleted')){
                        return params.data.archieve_remark;
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {

                    if (params.data.record_type === 'activity') {
                        var res = params.data.activity_content;

                        if(res !== undefined && res !== null && res !== ''){
                            res = res.replace(/<br>/g, "\r\n");
                            res = res.replace(/<li>/g, "\r\n");
                            res = res.replace(/<[^>]+>/g, '');
                            res = res.replace(/&nbsp;/g, '');
                        }else{
                            res = "";
                        }

                        return params.data.activity_title && res ? `${params.data.activity_title} | ${res}` : params.data.activity_title ? `${params.data.activity_title} | ${res}` : "";
                    }else if(params.data.record_type === 'note'){
                        var res = params.data.note_content;

                        if(res !== undefined && res !== null && res !== ''){
                            res = res.replace(/<br>/g, "\r\n");
                            res = res.replace(/<li>/g, "\r\n");
                            res = res.replace(/<[^>]+>/g, '');
                            res = res.replace(/&nbsp;/g, '');
                        }else{
                            res = "";
                        }

                        return res;
                    }else if(params.data.record_type === 'email'){
                        return params.data.email_subject;
                    }else if(params.data.record_type === 'call'){
                        if (params.data.audio_url) {
                            return(
                                <>
                                    <a href={params.data.audio_url} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faCirclePlay} size="xl" className="me-1"/>
                                    </a>
                                    {` - ${params.data.type} call with status ${params.data.status}`}
                                </>
                            );
                        }
                    }else if((params.data.record_type === 'deal_won')||(params.data.record_type === 'deal_lost')||(params.data.record_type === 'deal_deleted')){
                        return params.data.archieve_remark;
                    }
                }
            }
        },
        {
            headerName: t('Report_DealEngagement_HeaderName7'),
            field: "ds_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_DealEngagement_HeaderName8'),
            field: "cs_title",
            resizable: true,
            width: 250
        },
    ]);

    const gridDataSource = useMemo(()=>{
        return {
            rowCount: undefined,
            getRows: (params) => {
                
                axios.get('ws/ws_deal_engagement.php',{
                    params:{
                        task: "engagementData",
                        ds: startDate,
                        de: endDate,
                        user: userID,
                        startRow: params.startRow,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        setIsGrid(true);
                    }else{
                        setIsGrid(false);
                        Swal.fire({
                            icon: "error",
                            title: t('Report_alert_Title'),
                            text: t('Report_alert_Text')
                        });
                    }

                    setTimeout(() => {
                        var gridInfo = [];
                        var totalRecord = 0;

                        if (Number(data.status) === 0) {
                            gridInfo = data.record;
                            totalRecord = Number(data.totalRecord);
                            setRecordFound(totalRecord);
                        }else{
                            gridInfo = [];
                            totalRecord = 0;
                            setRecordFound(totalRecord);
                        }

                        var rowsThisPage = gridInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);

                });
            }
        }
    },[trigger]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            getChartData();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = () => {
        var params = {
            fileName : 'Deal-Engagement-dataExport.csv',
            processCellCallback : function(params) {

                if(params.column.colId === 'auto_increment_num'){
                    return params.node.data.temprecord_id;
                }else{
                    var res = params.value;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return res;
                }

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <div>
            { isLoading ? (<Loader/>):(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group className="ms-auto" style={{width: '250px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllUser')}
                                    options={userList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e)=>{setUserID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light shadow" onClick={()=>{getChartData(); setTrigger(trigger + 1);}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-4">
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={500}>
                                    <BarChart
                                    width={500}
                                    height={300}
                                    data={chartData}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis type="number"/>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    {/* <Legend /> */}
                                    <Bar dataKey="value" fill="#8884d8"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <div>
                            <Stack className="d-flex" direction="horizontal" gap={1}>
                                <div className="p-2">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                                <button 
                                    className="btn op-button op-primary-color text-light shadow ms-auto"
                                    disabled={permission.getUserPermission && permission.getUserPermission.record && Number(permission.getUserPermission.record[0].rules2) === 0 ? true : false}
                                    onClick={handleDownload}
                                >
                                    {t('Report_Download')}
                                </button>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={gridColumn}
                                    datasource={gridDataSource}
                                    defaultColDef={reportColDef}
                                    rowSelection={"multiple"}
                                    rowModelType={"infinite"}
                                    rowHeight={50}
                                    cacheBlockSize={30}
                                    cacheOverflowSize={2}
                                    maxConcurrentDatasourceRequests={1}
                                    infiniteInitialRowCount={1}
                                    maxBlocksInCache={50}
                                    pagination={true}
                                    paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                    suppressRowClickSelection={true}
                                    animateRows={true}
                                />
                            </div>
                        </div>
                        {!isGrid && <NoRecord message={t('Report_Grid_NoRecord_Message')} description={t('Report_Grid_NoRecord_Description')} width={300} />}
                    </div>
                </Container>
            ) }
        </div>
    );
}

export default DealEngagementReport;