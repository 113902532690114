import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";

const Workflow = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [workflowlist, setWorkflowList] = useState([]);
    const [isAddWorkflow, setisAddWorkflow] = useState(false);
    const [pipeline, setPipeline] = useState([]);
    const [pipelineID, setPipelineID] = useState([]);
    const [combinedStageData, setCombinedStageData] = useState([]);
    const [dsOption, setDSOption] = useState([]);
    const [customField, setCustomField] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);


    // Validation Schema
    const validationSchemaAdd = yup.object().shape({
        trigger_id: yup.string().required("Please choose an option"),
    });

    const validationSchema = yup.object().shape({
        trigger_title: yup.string().required("Please choose an option"),
        action_title: yup.string().required("Please choose an option"),
        pipe_id: yup.string()
            .test('not-zero', 'Please choose an option', value => value !== '0')
            .required("Please choose an option"),
        stage_id: yup.string()
            .test('not-zero', 'Please choose an option', value => value !== '0')
            .required("Please choose an option"),
        deal_source: yup.string()
            .test('not-zero', 'Please choose an option', value => value !== '0')
            .required("Please choose an option"),
        cf: yup.string().required("Please choose an option")
    });


    // custom field trigger
    const fieldTrigger = [
        { value: 'deal_won', label: 'Deal Won' },
        { value: 'deal_lost', label: 'Deal Lost' },
        { value: 'deal_delete', label: 'Deal Delete' },
        { value: 'create_activity', label: 'Create Activity' },
        { value: 'parent_deal_updated', label: 'Parent Deal Updated' },
        { value: 'first_contact_made', label: 'First Contact Made' },
        { value: 'deal_created', label: 'Deal Created' },
        { value: 'api_trigger', label: 'API Trigger' },
        { value: 'manual_deal_created', label: 'Manual Deal Created' },
        { value: 'appointment_qr_scan', label: 'Appointment QR Scan' },
        { value: 'avanser_call_no_answer', label: 'Avanser Call No Answer' },
        { value: 'job_insertion_direct_assign', label: 'Job Insertion Direct Assign' },
        { value: 'gl_spa_signed', label: 'GL Spa Signed' },
        { value: 'bacfree_referral_submitted', label: 'Bacfree Referrel Submitted' },
        { value: 'bacfree_referral_purchased', label: 'Bacfree Referrel Purchased' },
        { value: 'insert_new_activity', label: 'Insert New Activity' },
    ];

    const fieldAction = [
        { value: 'create_deal', label: 'Create Deal' },
        { value: 'create_activity', label: 'Create Activity' },
        { value: 'create_activity_with_cf', label: 'Create Activity with CF' },
        { value: 'unlock_stage', label: 'Unlock Stage' },
        { value: 'send_email', label: 'Send Email' },
        { value: 'send_sms', label: 'Send SMS' },
        { value: 'schedule_deals', label: 'Schedule Deals' },
        { value: 'update_parent_deal', label: 'Update Parent Deal' },
        { value: 'send_user_notification', label: 'Send User Notification' },
        { value: 'create_deal_with_cf', label: 'Create Deal with CF' },
        { value: 'send_lead_to_diff_acc_leadroute', label: 'Send Lead to Diff Acc Leadroute' },
        { value: 'send_lead_to_diff_acc_rotate', label: 'Send Lead to Diff Acc Rotate' },
        { value: 'send_lead_to_specific_user_by_cfid', label: 'Send Lead to Specific User by CFID' },
        { value: 'send_lead_to_agtiv', label: 'Send Lead to AGTIV' },
        { value: 'send_lead_to_agtiv_won', label: 'Send Lead to AGTIV Won' },
        { value: 'send_lead_to_agtiv_lost', label: 'Send Lead to AGTIV Lost' },
        { value: 'create_deal_with_product', label: 'Create Deal with Product' },
        { value: 'sendContactToMailchimp', label: 'Send Contact to Mailchimp' },
        { value: 'sendContactToCuration', label: 'Send Contact to Curation' },
        { value: 'send_whatsapp', label: 'Send Whatsapp' },
        { value: 'schedule_whatsapp', label: 'Schedule Whatsapp' },
        { value: 'start_blueprint_sequence', label: 'Start Blueprint Sequence' },
        { value: 'bacfree_send_sales_order', label: 'Bacfree Send Sales Order' },
        { value: 'bacfree_send_jobsheet', label: 'Bacfree Send Jonsheet' },
        { value: 'create_customer_login', label: 'Create Customer Login' },
        { value: 'progressture_process_kwp', label: 'Progressture Process KWP' },
        { value: 'send_lead_to_mhub', label: 'Send Lead to Mhub' },
        { value: 'send_notification_to_deal_owner', label: 'Send Notification to Deal Owner' },
        { value: 'transfer_lead_to_pipe', label: 'Transfer Lead to Pipe' },
        { value: 'create_chat_contact', label: 'Create Chat Contact' },
        { value: 'transfer_lead_to_diff_team', label: 'Transfer Lead to Diff Team' },
        { value: 'sendMtargetTemplateEmail', label: 'Send Mtarget Template Email' },
        { value: 'schedule_whatsapp_to_introducer', label: 'Schedule Whatsapp to Introducer' },
        { value: 'SF_SendLead', label: 'SF Send Lead' },
        { value: 'SF_SendLeadStaging', label: 'SF Send Lead Staging' },
    ];


    // GET FUNCTION --------------------------------------------------------
    const getWorkflow = () => {
        try {
            axios
                .get("ws/ws_workflow_trigger_setting.php", {
                    params: {
                        task: "getWorkflowTriggerList",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    setWorkflowList(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getPipeline = async () => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            const data = res.data;

            if (data.status === 0) {
                const option = data.record.map(opt => ({
                    value: opt.pipeline_id,
                    label: opt.pipeline_title
                }))

                setPipeline(option);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const getAllPipelineStage = async () => {
        try {
            const res = await axios.get('ws/ws_pipeline.php', {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.pipeline_id,
                    label: record.pipeline_title,
                }));
                setPipelineID(recordOptions);

                let allStageRecords = [];

                for (const record of data.record) {
                    const csRecords = await getCSData(record.pipeline_id);
                    allStageRecords = allStageRecords.concat(csRecords);
                }

                var csOption = allStageRecords.map((record) => ({
                    value: record.cs_id,
                    label: record.cs_title,
                    cs_token: record.cs_token
                }));
                setCombinedStageData(csOption)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCSData = async (pipeid) => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getStages",
                    pipeid: pipeid,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                return data.record; // Return the records array
            } else {
                return [];
            }

        } catch {

        }
    }

    const getDsData = async () => {
        try {
            const res = await axios.get('ws/ws_source.php', {
                params: {
                    task: 2,
                    company: session.company_id
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.ds_id,
                    label: record.ds_title,
                    ds_token: record.ds_token,
                }));
                setDSOption(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCustomField = async () => {
        try {
            let originalData = [];

            const resDeal = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "deal"
                }
            });

            let data = resDeal.data;

            if (data.record) {
                originalData = data.record;
            }

            const resPerson = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "person"
                }
            });

            let data2 = resPerson.data;

            if (data2.record) {
                originalData = originalData.concat(data2.record);
            }

            var recordOptions = originalData.map((record) => ({
                value: record.cf_id,
                label: record.cf_label,
                area: record.cf_area,
            }));

            setCustomField(recordOptions);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        { headerName: "Trigger Title", field: "trigger_title", width: 300 },
        { headerName: "Workflow Title", field: "workflow_title", width: 300 },
        { headerName: "Action Title", field: "action_title", width: 300 },
        { headerName: "Pipeline Title", field: "pipeline_title", width: 300 },
        { headerName: "Direct Source Title", field: "ds_title", width: 300 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get("ws/ws_workflow_trigger_setting.php", {
                params: {
                    task: "updateWorkflowTrigger",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    trigger_id: values.trigger_id,
                    trigger_title: values.trigger_title,
                    action_id: values.action_id,
                    action_title: values.action_title,
                    pipe_id: values.pipe_id,
                    deal_source: values.deal_source,
                    cf: values.cf
                }
            });

            const data = response.data;

            if (data.status === 0) {
                setShowModal(false);
                getWorkflow();
                Swal.fire({
                    icon: "success",
                    title: "Successfully Update",
                    text: "Successfully update a record!",
                    timer: 1500,
                });
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.trigger_id) {
                    sendDelete(params);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get('ws/ws_workflow_trigger_setting.php', {
                params: {
                    task: "deleteWorkflowTrigger",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    trigger_id: params.trigger_id
                }
            });

            if (response.data.status == '0') {
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getWorkflow();
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const response = await axios.get("ws/ws_workflow_trigger_setting.php", {
                params: {
                    task: "setWorkflowTrigger",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    trigger_id: values.trigger_id,
                    action_id: values.action_id,
                    // trigger_title: values.trigger_title,
                    pipe_id: values.pipeline_id,
                    deal_source: values.deal_source,
                    cf: values.custom_field
                }
            });

            const data = response.data;

            if (data.status === 0) {
                setisAddWorkflow(false);
                getWorkflow();
                Swal.fire({
                    icon: "success",
                    title: "Successful",
                    text: "Successfully add a record!",
                    timer: 1500,
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };


    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getWorkflow();
            getPipeline();
            getAllPipelineStage();
            getDsData();
            getCustomField();
        }
    }, []);

    const toggleAddWorkflowModal = () => {
        setisAddWorkflow(prevState => !prevState);
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddWorkflowModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Workflow
                    </button>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : workflowlist} rowHeight={85} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Add function */}

                    <Modal show={isAddWorkflow} onHide={toggleAddWorkflowModal} size="md">
                        <Formik
                            initialValues={{
                                trigger_id: "",
                                action_id: "",
                                pipeline_id: "",
                                deal_source: "",
                                custom_field: "",
                            }}
                            validationSchema={validationSchemaAdd}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add WorkFlow</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Trigger</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={fieldTrigger}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('trigger_id', selectedOption.value);
                                                }}
                                                name="trigger_id"
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.trigger_id && !!errors.trigger_id}
                                            />
                                            {errors.trigger_id && touched.trigger_id && <div className="op-error-message">{errors.trigger_id}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Action</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={fieldAction}
                                                value={values.action}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('action_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Pipeline</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={pipeline}
                                                value={values.pipeline}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('pipeline_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Deal Source</Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select an option"
                                                    options={dsOption}
                                                    value={values.deal_source}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('deal_source', selectedOption.value);
                                                    }}
                                                    isClearable
                                                    isSearchable
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>Select Custom Field</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={customField.map(item => ({ value: item.value, label: `${item.area} - ${item.label}` }))}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('custom_field', selectedOption ? selectedOption.value : '');
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                trigger_id: currentData ? currentData.trigger_id : "",
                                trigger_title: currentData ? currentData.trigger_title : "",
                                action_id: currentData ? currentData.action_id : "",
                                action_title: currentData ? currentData.action_title : "",
                                // pipe: currentData ? currentData.pipe_id == 0 : "",
                                // pipe_id: currentData && (currentData.pipe_id === 0 || currentData.pipe_id === null) ? "" : currentData?.pipe_id || "",
                                pipe_id: currentData && (currentData.pipe_id === 0 || currentData.pipe_id === null) ? "" : currentData?.pipe_id || "",
                                deal_source: currentData ? currentData.deal_source : "",
                                cf: currentData ? currentData.cf : "",
                                company_id: currentData ? currentData.company_id : ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                        // enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Trigger</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={fieldTrigger}
                                                valueDefault={fieldTrigger.find(opt => opt.value === values.trigger_title)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('trigger_title', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={errors.trigger_title && touched.trigger_title}
                                            />
                                            {errors.trigger_title && touched.trigger_title && <div className="op-error-message">{errors.trigger_title}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Action</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={fieldAction}
                                                valueDefault={fieldAction.find(opt => opt.value === values.action_title)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('action_title', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={errors.action_title && touched.action_title}
                                            />
                                            {errors.action_title && touched.action_title && <div className="op-error-message">{errors.action_title}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Pipeline</Form.Label>
                                            {console.log(values)}
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={pipeline}
                                                valueDefault={pipeline.find(opt => opt.value === values.pipe_id) ?? ""}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('pipe_id', selectedOption ? selectedOption.value : '');
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={errors.pipe_id && touched.pipe_id}
                                            />
                                            {errors.pipe_id && touched.pipe_id && <div className="op-error-message">{errors.pipe_id}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Deal Source</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={dsOption}
                                                valueDefault={dsOption.find(opt => opt.value === values.deal_source)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('deal_source', selectedOption ? selectedOption.value : '');
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.deal_source && touched.deal_source}
                                            />
                                            {errors.deal_source && touched.deal_source && <div className="op-error-message">{errors.deal_source}</div>}
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>Select Custom Field</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={customField.map(item => ({ value: item.value, label: `${item.area} - ${item.label}` }))}
                                                valueDefault={customField.find(opt => opt.value === values.cf)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('cf', selectedOption ? selectedOption.value : '');
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.cf && touched.cf}
                                            />
                                            {errors.cf && touched.cf && <div className="op-error-message">{errors.cf}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                </div>
            )}
        </Container>
    );
}

export default Workflow;
