import { useMemo, useRef, useState, useCallback } from "react";
import QuillEditor, { Quill } from "react-quill";
import Swal from "sweetalert2/dist/sweetalert2";
import axios from "../api/axios";

const customDivFormat = (Quill) => {
  const Block = Quill.import("blots/block");
  class DivBlot extends Block {}
  DivBlot.tagName = "div";
  Quill.register(DivBlot);
};

const NoteEditor = ({ value = "", onChangeValue, height = 150 }) => {
  const [hide, setHide] = useState(false);
  const quill = useRef();
  customDivFormat(Quill);
  const imageHandler = useCallback(() => {
   
    const quillEditor = quill.current.getEditor();
    const imgTags = quillEditor.getContents().ops.filter((op) => op.insert && op.insert.image).length;
    if (imgTags >= 15) {
      Swal.fire({
        title: "Image Limit Reached",
        text: `You can only insert up to 15 images.`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }


    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.click();
    input.onchange = async () => {
      const file = input.files?.[0];
      if (!file) return;
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please upload a valid image file (JPEG, PNG, or GIF).",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      try {
        const imageUrl = await convertFileToBase64(file);
        const newImageUrl = await uploadImage(imageUrl);
        const img = new Image();
        img.src = newImageUrl;
        img.onload = () => {
          const { scaledWidth, scaledHeight } = scaleImage(img, 200);
          const quillEditor = quill.current.getEditor();
          const range = quillEditor.getSelection(true);
          quillEditor.insertEmbed(range.index, "image", newImageUrl);

          const editorContainer = document.querySelector(".ql-editor");
          const insertedImage = editorContainer.querySelector(`img[src="${newImageUrl}"]`);
          if (insertedImage) {
            insertedImage.setAttribute("width", scaledWidth);
            insertedImage.setAttribute("height", scaledHeight);
          }
        };
      } catch (error) {
        console.error("Image handling failed:", error);
        Swal.fire({
          title: "Error",
          text: error.message || "There was an issue uploading the image.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };
  }, []);

  const scaleImage = (img, maxDimension) => {
    const { width, height } = img;
    const scaledWidth = width > 200 ? 200 : width;
    const scaledHeight = height > 200 ? 200 : height;

    let actualHeight = scaledHeight;
    let actualWidth = scaledWidth;

    if (scaledWidth > scaledHeight && scaledWidth > maxDimension) {
      actualWidth = scaledWidth > maxDimension ? "200" : `${scaledWidth}`;
      actualHeight = scaledWidth > maxDimension ? "auto" : `${scaledHeight}`;
    } else if (scaledHeight > scaledWidth && scaledHeight > maxDimension) {
      actualHeight = scaledHeight > maxDimension ? "200" : `${scaledHeight}`;
      actualWidth = scaledHeight > maxDimension ? "auto" : `${scaledWidth}`;
    } else if (scaledWidth > scaledHeight && scaledHeight < maxDimension) {
      actualHeight = "auto";
      actualWidth = "200";
    } else if (scaledWidth < scaledHeight && scaledWidth < maxDimension) {
      actualHeight = "200";
      actualWidth = "auto";
    } else {
      actualHeight = "200";
      actualWidth = "200";
    }

    return {
      scaledWidth: actualWidth,
      scaledHeight: actualHeight,
    };
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error("Failed to read the file."));
      reader.readAsDataURL(file);
    });
  };

  const uploadImage = async (imageUrl) => {
    const formData = new FormData();
    const mimeType = imageUrl.match(/data:image\/([a-zA-Z]+);base64,/)[1];
    formData.append("image", imageUrl.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2]);
    formData.append("mimeType", mimeType);
    const apiUrl = "https://app.outperformhq.io/cloud_staging/php/note_editor_upload.php";

    try {
      const response = await axios.post(apiUrl, formData);
      if (!response.data.image) {
        throw new Error("Invalid response from the server.");
      }
      return `https://app.outperformhq.io/cloud_staging${response.data.image}`;
    } catch (error) {
      throw new Error("Failed to upload the image.");
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ["bold", "italic", "underline", "strike"], ["link", "image"], [{ list: "ordered" }, { list: "bullet" }], [{ indent: "-1" }, { indent: "+1" }, { align: [] }], ["clean"]],
        handlers: { image: imageHandler },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [imageHandler]
  );

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "align", "link", "image", "color", "clean", "alt", "height", "width", "style", "size"];
  const handleChange = (content, delta, source, editor) => {
    onChangeValue(content, delta, source, editor);
  };

  if (hide === false) {
    return (
      <div className="w-100 op-note-editor">
        <QuillEditor ref={(el) => (quill.current = el)} customToolbarPosition="bottom" theme="snow" value={value} formats={formats} modules={modules} bounds={"#root"} onChange={handleChange} />
      </div>
    );
  }
};

export default NoteEditor;
