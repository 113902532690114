import React, { useState, useEffect, useRef, forwardRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Dropdown, Modal, CloseButton, Card, Offcanvas } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact, useGridFilter } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { FilePond, registerPlugin } from "react-filepond";
import * as XLSX from "xlsx";
import { faFilter, faFilterList } from "@fortawesome/pro-duotone-svg-icons";

const RedemptionVoucher = () => {
  registerPlugin(FilePondPluginFileValidateType);
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [voucherList, setVoucherList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState({ label: "All Category", value: "" });
  const [vendorList, setVendorList] = useState([]);
  const [vendorList2, setVendorList2] = useState([]);
  const [vendorList3, setVendorList3] = useState([]);
  const [vendorData, setVendorData] = useState({ label: "All Vendor", value: "", category: "" });
  const [statusData, setStatusData] = useState({ label: "All Status", value: "" });
  const [isVendor, setIsVendor] = useState(true);
  const [isNewVoucher, setIsNewVoucher] = useState(false);
  const [isBulkVoucher, setIsBulkVoucher] = useState(false);
  const [bulkVoucherCount, setBulkVoucherCount] = useState(0);
  const [bulkVoucherData, setBulkVoucherData] = useState([]);
  const [bulkVoucherRecord, setBulkVoucherRecord] = useState([]);
  const [isSuccess, setIsSuccess] = useState(0);
  const [prospectData, setProspectData] = useState("");
  const [voucher1Data, setVoucher1Data] = useState("");
  const [isFilter, setIsFilter] = useState();
  const [voucherCount, setVoucherCount] = useState(0);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [bulkUpdateCount, setBulkUpdateCount] = useState(0);
  const [bulkUpdateData, setBulkUpdateData] = useState([]);
  const [bulkUpdateRecord, setBulkUpdateRecord] = useState([]);

  const [statusList] = useState([
    { label: "All Status", value: "" },
    { label: "Available", value: "available" },
    { label: "Assigned", value: "assign" },
    { label: "Redeemed", value: "redeem" },
    { label: "Expired", value: "expired" },
    { label: "Error", value: "error" },
  ]);

  const [bulkVoucherStatus] = useState([
    { label: "Available (Recycling Voucher)", value: "available" },
    { label: "Redeemed", value: "redeem" },
    { label: "Error", value: "error" },
  ]);

  // FORMIK SCHEMA ======================================================

  const newVoucherSchema = yup.object().shape({
    voucher1: yup.string().required("This field is required"),
    vendor: yup.string().required("This field is required"),
    expired_date: yup.string().required("This field is required"),
  });

  // GET FUNCTION =======================================================

  // const getVoucher = async () => {
  //   try {
  //     const response = await axios.get("ext/glRedemption/api_crud_voucher.php", {
  //       params: {
  //         task: "VoucherList",
  //         vendor_id: "",
  //         voucher_status: "",
  //         utoken: session.user_ac_token,
  //         ctoken: session.company_token,
  //       },
  //     });

  //     const data = response.data;
  //     if (data.status === 0) {
  //       data.record.forEach((record) => {
  //         let vouchers = record.voucher_code;
  //         vouchers = record.popcorn_voucher ? `${vouchers} /n ${record.popcorn_voucher}` : vouchers;
  //         vouchers = record.voucher3 ? `${vouchers} /n ${record.voucher3}` : vouchers;
  //         vouchers = record.voucher4 ? `${vouchers} /n ${record.voucher4}` : vouchers;
  //         vouchers = record.voucher5 ? `${vouchers} /n ${record.voucher5}` : vouchers;
  //         vouchers = record.voucher6 ? `${vouchers} /n ${record.voucher6}` : vouchers;
  //         vouchers = record.voucher7 ? `${vouchers} /n ${record.voucher7}` : vouchers;
  //         record.vouchers = vouchers;
  //       });
  //       setVoucherList(data.record);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getCategory = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "CategoryList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.category_name,
          value: record.category_id,
        }));

        recordOptions.unshift({
          label: "All Category",
          value: "",
        });

        setCategoryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVendor = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_vendor.php", {
        params: {
          task: "VendorList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.vendor_name,
          value: record.vendor_id,
          category: record.category_id,
        }));

        recordOptions.unshift({
          label: "All Vendor",
          value: "",
          category: "",
        });

        const recordOptions2 = data.record.map((record) => ({
          label: record.vendor_name,
          value: record.vendor_id,
        }));

        setVendorList(recordOptions);
        setVendorList2(recordOptions);
        setVendorList3(recordOptions2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBulkVoucher = async (values) => {
    try {
      var recordReader = new FileReader();
      recordReader.onload = () => {
        var recordFileData = recordReader.result;
        var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
        var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Redemption Voucher"]);
        var recordData = recordRow;
        setBulkVoucherCount(recordRow.length);

        var headerRows = [];
        var recordWorksheet = recordWorkBook.Sheets["Redemption Voucher"];

        if (recordWorksheet) {
          var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
          for (var R = range.s.r; R <= range.e.r; ++R) {
            var row = [];
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cellAddress = XLSX.utils.encode_cell({
                r: R,
                c: C,
              });
              var cell = recordWorksheet[cellAddress];
              var cellValue = cell ? cell.v : "";
              row.push(cellValue);
            }
            headerRows.push(row);
          }
        }

        if (!recordWorksheet) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Redemption Voucher'.</p></div>`,
          });
          return;
        }

        var expectedColumns = ["Voucher 1", "Voucher 2", "Voucher 3", "Voucher 4", "Voucher 5", "Voucher 6", "Voucher 7", "Expired Date"];
        var currentColumns = headerRows[0];

        if (currentColumns) {
          var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
          if (missingColumns.length > 0) {
            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
            for (var i = 0; i < missingColumns.length; i++) {
              alertMessage += `<li>${missingColumns[i]}</li>\n`;
            }
            alertMessage += `</ul></div>`;
            Swal.fire({
              icon: "warning",
              html: alertMessage,
            });
            return;
          }
        }

        if (!recordRow.length) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
          });
          return;
        }

        for (let i = 0; i < recordData.length; i++) {
          recordData[i].index = i + 1;
        }

        const bulkData = recordData.map(({ "Voucher 1": voucher1 = "", "Voucher 2": voucher2 = "", "Voucher 3": voucher3 = "", "Voucher 4": voucher4 = "", "Voucher 5": voucher5 = "", "Voucher 6": voucher6 = "", "Voucher 7": voucher7 = "", "Expired Date": voucher_expired = "", index: index }) => ({
          voucher1,
          voucher2,
          voucher3,
          voucher4,
          voucher5,
          voucher6,
          voucher7,
          voucher_expired,
          index,
        }));

        setBulkVoucherData(bulkData);
      };
      recordReader.readAsArrayBuffer(values.file[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getBulkUpdate = async (values) => {
    try {
      var totalRecord = 0;
      var recordReader = new FileReader();
      recordReader.onload = function () {
        var recordFileData = recordReader.result;
        var recordWorkBook = XLSX.read(recordFileData, {
          type: "binary",
        });
        var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Redemption Voucher"]);
        var recordData = recordRow;
        totalRecord = recordRow.length;
        setBulkUpdateCount(recordRow.length);
        var headerRows = [];
        var recordWorksheet = recordWorkBook.Sheets["Redemption Voucher"];

        if (recordWorksheet) {
          var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
          for (var R = range.s.r; R <= range.e.r; ++R) {
            var row = [];
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cellAddress = XLSX.utils.encode_cell({
                r: R,
                c: C,
              });
              var cell = recordWorksheet[cellAddress];
              var cellValue = cell ? cell.v : "";
              row.push(cellValue);
            }
            headerRows.push(row);
          }
        }

        if (!recordWorksheet) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Redemption Voucher'.</p></div>`,
          });
          return;
        }

        var expectedColumns = ["Voucher 1"];
        var currentColumns = headerRows[0];
        if (currentColumns) {
          var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
          if (missingColumns.length > 0) {
            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
            for (var i = 0; i < missingColumns.length; i++) {
              alertMessage += `<li>${missingColumns[i]}</li>\n`;
            }
            alertMessage += `</ul></div>`;
            Swal.fire({
              icon: "warning",
              html: alertMessage,
            });
            return;
          }
        }

        if (!recordRow.length) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
          });
          return;
        }

        for (let i = 0; i < recordData.length; i++) {
          recordData[i].index = i + 1;
        }

        const recordInfo = recordData.map(({ "Voucher 1": voucher1 = "", index: index }) => ({
          voucher1,
          index,
        }));

        setBulkUpdateData(recordInfo);
      };
    } catch (error) {
      console.error(error);
    }

    recordReader.readAsArrayBuffer(values.file[0]);
  };

  //  AG GRID FUNCTION ==================================================

  const voucherRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const containerStyle2 = useMemo(() => ({ width: "100%", height: "50vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const voucherColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
      onCellClicked: (params) => {
        if (params.data && params.data.prospect_id && Number(params.data.prospect_id) !== 0) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: "Voucher 1",
      field: "voucher_code",
    },
    {
      headerName: "Category",
      field: "category_name",
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
    },
    {
      headerName: "Status",
      field: "voucher_status",
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_status == "available") {
            return <div className="op-label bg-success text-light">Available</div>;
          } else if (params.data.voucher_status == "assign") {
            return <div className="op-label bg-warning text-light">Assigned</div>;
          } else if (params.data.voucher_status == "redeem") {
            return <div className="op-label bg-primary text-light">Redeemed</div>;
          } else if (params.data.voucher_status == "expired") {
            return <div className="op-label bg-secondary text-light">Expired</div>;
          } else if (params.data.voucher_status == "error") {
            return <div className="op-label bg-danger text-light">Error</div>;
          } else {
            return "";
          }
        }
      },
    },

    {
      headerName: "Assigned Date",
      field: "voucher_assign_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_assign_date_time !== "0000-00-00 00:00:00") {
            const newDate = new Date(params.data.voucher_assign_date_time);
            return moment(newDate).format("LLL");
          }
          return "";
        }
      },
    },

    {
      headerName: "Redemption Available Until",
      field: "redemption_expiry_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.redemption_expiry_date_time !== "0000-00-00 00:00:00") {
            const newDate = new Date(params.data.redemption_expiry_date_time);
            return moment(newDate).format("LLL");
          }
          return "";
        }
      },
    },
    {
      headerName: "Expiry Date",
      field: "voucher_expired",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_expired !== "0000-00-00 00:00:00") {
            const newDate = new Date(params.data.voucher_expired);
            return moment(newDate).format("LLL");
          }
          return "";
        }
      },
    },
    {
      headerName: "Location",
      field: "locations",
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
    },
  ];

  const voucherDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_crud_voucher.php", {
            params: {
              task: "VoucherListV2",
              vendor_id: vendorData.value,
              category_id: categoryData.value,
              voucher_status: statusData.value,
              prospect_name: prospectData,
              voucher1: voucher1Data,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setVoucherCount(Number(data.totalRecord));
              } else {
                {
                  setVoucherCount(Number(0));
                }
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, vendorData, statusData, prospectData, voucher1Data, isSuccess]);

  const voucherColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const voucherRowId = useCallback(function (params) {
    return params.data.voucher_id.toString();
  }, []);

  const bulkVoucherRef = useRef();
  const bulkVoucherColumn = [
    {
      headerName: "No",
      field: "",
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Voucher 1",
      field: "voucher1",
    },
    {
      headerName: "Voucher 2",
      field: "voucher2",
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
    },
    {
      headerName: "Expiry Date",
      field: "voucher_expired",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_expired !== "0000-00-00 00:00:00" || params.data.voucher_expired !== "") {
            return moment(new Date(params.data.voucher_expired)).format("LLL");
          }
          return "";
        }
      },
    },
  ];

  const bulkVoucherRowId = useCallback(function (params) {
    return params.data.voucher1.toString();
  }, []);

  const bulkVoucherSelect = useCallback(() => {
    setBulkVoucherRecord(bulkVoucherRef.current.api.getSelectedRows());
  }, []);

  const bulkUpdateRef = useRef();
  const bulkUpdateColumn = [
    {
      headerName: "No",
      field: "",
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Voucher 1",
      field: "voucher1",
    },
  ];

  const bulkUpdateRowId = useCallback(function (params) {
    return params.data.voucher1.toString();
  }, []);

  const bulkUpdateSelect = useCallback(() => {
    setBulkUpdateRecord(bulkUpdateRef.current.api.getSelectedRows());
  }, []);

  // SEND FUNCTION ======================================================

  const sendNewVoucher = async (values) => {
    try {
      var formData = new FormData();
      formData.append("task", "NewVoucher");
      formData.append("voucher1", values.voucher1);
      formData.append("voucher2", values.voucher2);
      formData.append("voucher3", values.voucher3);
      formData.append("voucher4", values.voucher4);
      formData.append("voucher5", values.voucher5);
      formData.append("voucher6", values.voucher6);
      formData.append("voucher7", values.voucher7);
      formData.append("voucher_expired", values.expired_date);
      formData.append("vendor_id", values.vendor);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);

      const response = await axios.post("ext/glRedemption/api_crud_voucher.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsNewVoucher(false);
        setIsSuccess(Math.random());
        if (data.error_count === 0) {
          Swal.fire({
            icon: "success",
            text: "Successfully added a new voucher",
          });
        } else {
          var alertMessage = `<h6 className="op-text-medium">${data.success_count} successfully added and ${data.error_count} is failed to updated</h6><div className="reason"><h6 className="op-text-medium">Reason:</h6><p>There is duplicated voucher in the template:</p><ul>`;
          for (var i = 0; i < data.error.length; i++) {
            alertMessage += `<li>${data.error[i]}</li>\n`;
          }
          alertMessage += `</ul></div>`;

          Swal.fire({
            icon: data.success_count > 0 ? "success" : "warning",
            html: alertMessage,
          });
        }
      } else {
        setIsNewVoucher(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendBulkVoucher = async (values) => {
    try {
      if (bulkVoucherRecord.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "No record has been selected, please choose which voucher record that you want to bulk upload",
        });
        return;
      }

      var voucher1Arr = bulkVoucherRecord.map((item) => {
        return item.voucher1;
      });

      var voucher2Arr = bulkVoucherRecord.map((item) => {
        return item.voucher2;
      });

      var voucher3Arr = bulkVoucherRecord.map((item) => {
        return item.voucher3;
      });

      var voucher4Arr = bulkVoucherRecord.map((item) => {
        return item.voucher4;
      });

      var voucher5Arr = bulkVoucherRecord.map((item) => {
        return item.voucher5;
      });

      var voucher6Arr = bulkVoucherRecord.map((item) => {
        return item.voucher6;
      });

      var voucher7Arr = bulkVoucherRecord.map((item) => {
        return item.voucher7;
      });

      var voucherExpiredArr = bulkVoucherRecord.map((item) => {
        return item.voucher_expired;
      });

      var voucher1Val = voucher1Arr.join("|");
      var voucher2Val = voucher2Arr.join("|");
      var voucher3Val = voucher3Arr.join("|");
      var voucher4Val = voucher4Arr.join("|");
      var voucher5Val = voucher5Arr.join("|");
      var voucher6Val = voucher6Arr.join("|");
      var voucher7Val = voucher7Arr.join("|");
      var voucherExpiredVal = voucherExpiredArr.join("|");

      var vendorArr = [];
      for (let i = 0; i < voucher1Arr.length; i++) {
        vendorArr.push(values.vendor);
      }

      var vendorVal = vendorArr.join("|");
      if (voucher1Arr.includes("") || voucherExpiredArr.includes("")) {
        Swal.fire({
          icon: "warning",
          text: "Please make sure all compulsory input are filled in and provided.",
        });
        return;
      }

      if (!voucher1Val || !voucher2Val || !voucher3Val || !voucher4Val || !voucher5Val || !voucher6Val || !voucher7Val || !voucherExpiredVal || !vendorVal) {
        Swal.fire({
          icon: "warning",
          text: "Please make sure all compulsory input are filled in and provided.",
        });
        return;
      }

      var formData = new FormData();
      formData.append("task", "NewVoucher");
      formData.append("voucher1", voucher1Val);
      formData.append("voucher2", voucher2Val);
      formData.append("voucher3", voucher3Val);
      formData.append("voucher4", voucher4Val);
      formData.append("voucher5", voucher5Val);
      formData.append("voucher6", voucher6Val);
      formData.append("voucher7", voucher7Val);
      formData.append("voucher_expired", voucherExpiredVal);
      formData.append("vendor_id", vendorVal);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);

      const response = await axios.post("ext/glRedemption/api_crud_voucher.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsBulkVoucher(false);
        setBulkVoucherCount(0);
        setBulkVoucherData([]);
        setBulkVoucherRecord([]);
        setIsSuccess(Math.random());
        if (data.error_count === 0) {
          Swal.fire({
            icon: "success",
            text: "Successfully created a new voucher",
          });
        } else {
          var alertMessage = `<p className="op-text-medium text-start">${data.success_count} successfully added and ${data.error_count} failed to be added. This voucher is already in the system: </p><ul>`;
          for (var i = 0; i < data.error.length; i++) {
            alertMessage += `<li className="op-text-medium text-start">${data.error[i]}</li>\n`;
          }
          alertMessage += `</ul>`;

          Swal.fire({
            icon: data.success_count > 0 ? "success" : "warning",
            html: alertMessage,
          });
        }
      } else {
        setIsBulkVoucher(false);
        setBulkVoucherCount(0);
        setBulkVoucherData([]);
        setBulkVoucherRecord([]);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendBulkUpdate = async (values) => {
    try {
      if (bulkUpdateRecord.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "No record has been selected, please choose which voucher record that you want to bulk upload",
        });
        return;
      }

      var voucherCodeArr = bulkUpdateRecord.map((item) => {
        return item.voucher1;
      });

      var voucherCodeVal = voucherCodeArr.join("|");
      if (!voucherCodeVal || !values.vendor || !values.status) {
        Swal.fire({
          icon: "warning",
          text: "Please make sure all compulsory input are filled in and provided.",
        });
        return;
      }

      var formData = new FormData();
      formData.append("task", "BulkUpdate");
      formData.append("voucher_code", voucherCodeVal);
      formData.append("vendor_id", values.vendor);
      formData.append("voucher_status", values.status);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      const response = await axios.post("ext/glRedemption/api_crud_voucher.php", formData);
      const data = response.data;
      setIsBulkUpdate(false);
      setBulkUpdateCount(0);
      setBulkUpdateData([]);
      setBulkUpdateRecord([]);
      Swal.fire({
        icon: "success",
        text: `${data.success_count} successfully updated and ${data.error_count} is failed to updated`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setTotalFilter = () => {
    let vendorCount = vendorData.value ? 1 : 0;
    let categoryCount = categoryData.value ? 1 : 0;
    let statusCount = statusData.value ? 1 : 0;
    let prospect = prospectData ? 1 : 0;
    let voucher = voucher1Data ? 1 : 0;

    return vendorCount + categoryCount + statusCount + prospect + voucher;
  };

  // RENDER FUNCTION ====================================================

  const RenderVoucherAction = forwardRef(({ onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <Button ref={ref} variant="light" className="shadow-sm" onClick={openDropdown}>
        <FontAwesomeIcon icon={faEllipsis} size="lg" />
      </Button>
    );
  });

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          // await getVoucher();
          await getCategory();
          await getVendor();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12} className="mb-3">
              <Stack direction="horizontal" gap={2}>
                <Button variant="" className="btn op-primary-color text-light" onClick={setIsNewVoucher}>
                  New Voucher
                </Button>
                <Dropdown size={100}>
                  <Dropdown.Toggle as={RenderVoucherAction} />
                  <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0 }}>
                    <Dropdown.Item onClick={setIsBulkVoucher}>Bulk Voucher</Dropdown.Item>
                    <Dropdown.Item onClick={setIsBulkUpdate}>Bulk Update</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Stack className="ms-auto p-0" direction="horizontal" gap={3}>
                  <p className="m-0">{voucherCount === 0 ? "No record" : voucherCount === 1 ? "1 record" : `${voucherCount} records`}</p>
                  <div className="position-relative">
                    <Button variant="light" className="shadow-sm" onClick={setIsFilter}>
                      <FontAwesomeIcon icon={faFilterList} size="lg" />
                    </Button>
                    {setTotalFilter() ? (
                      <div style={{ position: "absolute", top: -8, right: -8 }}>
                        <span className="badge text-bg-primary rounded">{setTotalFilter()}</span>
                      </div>
                    ) : null}
                  </div>
                </Stack>
              </Stack>
            </Col>
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={voucherRef}
                    columnDefs={voucherColumn}
                    datasource={voucherDataSource}
                    defaultColDef={voucherColDef}
                    getRowId={voucherRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Offcanvas show={isFilter} onHide={setIsFilter} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Voucher Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <FormSelect
              options={categoryList}
              valueDefault={categoryData}
              onChange={(e) => {
                setCategoryData(e);
                setIsVendor(false);
                setVendorData({ label: "All Vendor", value: "", category: "" });
                setTimeout(() => setIsVendor(true), 1);
              }}
            />
          </Form.Group>

          {isVendor && (
            <Form.Group className="mb-2">
              <FormSelect options={categoryData.value ? vendorList.filter((record) => Number(record.category) === Number(categoryData.value) || record.category === "") : vendorList} valueDefault={vendorData} onChange={setVendorData} />
            </Form.Group>
          )}

          <Form.Group className="mb-2">
            <FormSelect options={statusList} valueDefault={statusData} onChange={setStatusData} />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Control value={prospectData} placeholder="Prospect name" onChange={(e) => setProspectData(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Control value={voucher1Data} placeholder="Voucher 1" onChange={(e) => setVoucher1Data(e.target.value)} />
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={isNewVoucher} onHide={setIsNewVoucher} size="lg">
        <Formik
          validationSchema={newVoucherSchema}
          onSubmit={sendNewVoucher}
          initialValues={{
            voucher1: "",
            voucher2: "",
            voucher3: "",
            voucher4: "",
            voucher5: "",
            voucher6: "",
            voucher7: "",
            vendor: "",
            expired_date: "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Voucher</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Voucher 1</Form.Label>
                  <Form.Control name="voucher1" type="text" value={values.voucher1} isInvalid={errors.voucher1 && touched.voucher1} onChange={handleChange("voucher1")} />
                  {errors.voucher1 && touched.voucher1 && <div className="op-error-message">{errors.voucher1}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 2</Form.Label>
                  <Form.Control name="voucher2" type="text" value={values.voucher2} isInvalid={errors.voucher2 && touched.voucher2} onChange={handleChange("voucher2")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 3</Form.Label>
                  <Form.Control name="voucher1" type="text" value={values.voucher3} isInvalid={errors.voucher3 && touched.voucher3} onChange={handleChange("voucher3")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 4</Form.Label>
                  <Form.Control name="voucher1" type="text" value={values.voucher4} isInvalid={errors.voucher4 && touched.voucher4} onChange={handleChange("voucher4")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 5</Form.Label>
                  <Form.Control name="voucher5" type="text" value={values.voucher5} isInvalid={errors.voucher5 && touched.voucher5} onChange={handleChange("voucher5")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 6</Form.Label>
                  <Form.Control name="voucher6" type="text" value={values.voucher6} isInvalid={errors.voucher6 && touched.voucher6} onChange={handleChange("voucher6")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Voucher 7</Form.Label>
                  <Form.Control name="voucher7" type="text" value={values.voucher7} isInvalid={errors.voucher7 && touched.voucher7} onChange={handleChange("voucher7")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Vendor</Form.Label>
                  <FormSelect options={vendorList2} onChange={(e) => setFieldValue("vendor", e.value)} />
                  {errors.vendor && touched.vendor && <div className="op-error-message">{errors.vendor}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Expiry Date</Form.Label>
                  <input className="form-control" type="datetime-local" name="expired_date" onChange={handleChange("expired_date")} />
                  {errors.expired_date && touched.expired_date && <div className="op-error-message">{errors.expired_date}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isBulkVoucher} onHide={setIsBulkVoucher} size="xl">
        <Formik onSubmit={getBulkVoucher} initialValues={{ file: [], vendor: "", vendor_show: true, submit_disabled: true }}>
          {({ handleSubmit, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title as={"h6"}>Bulk Voucher</Modal.Title>
                <CloseButton
                  onClick={() => {
                    setIsBulkVoucher(false);
                    setBulkVoucherCount(0);
                    setBulkVoucherData([]);
                    setBulkVoucherRecord([]);
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xxl={6}>
                    <Form.Group>
                      <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        name="file"
                        credits={false}
                        acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        instantUpload={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "file",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          setFieldValue("file", []);
                          setFieldValue("vendor_show", false);
                          setFieldValue("vendor", "");
                          setFieldValue("submit_disabled", true);
                          setBulkVoucherCount(0);
                          setBulkVoucherData([]);
                          setBulkVoucherRecord([]);
                          setTimeout(() => setFieldValue("vendor_show", true), 1);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xxl={6}>
                    {values.vendor_show && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={vendorList3}
                          name="vendor"
                          placeholder="Select vendor"
                          disabled={values.file.length === 0}
                          onChange={(e) => {
                            setFieldValue("vendor", e.value);
                            setFieldValue("preview_disabled", false);
                          }}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="d-flex">
                      <Button type="submit" variant="" className="op-primary-color text-light" disabled={values.vendor === ""} onClick={() => setFieldValue("submit_disabled", false)}>
                        Preview
                      </Button>
                      <Button type="button" variant="success" className="ms-2" onClick={() => window.open("https://www.nexcrmapis.com/cloud_staging/ext/glRedemption/images/Outperform_Redemption_Voucher_Template.xlsx", "_blank")}>
                        Template
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col xxl={12} className="mb-3">
                    <p className="m-0 text-center">Please note, this file uploader only accepts excel files (xls or xlsx). Please download and use our template and enter your list into it.</p>
                  </Col>
                  {bulkVoucherCount > 0 && (
                    <Col xxl={12}>
                      <p className="mb-2">{bulkVoucherCount === 0 ? "No record" : bulkVoucherCount === 1 ? "1 record" : `${bulkVoucherCount} records`}</p>
                      <div style={containerStyle2}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                          <AgGridReact
                            ref={bulkVoucherRef}
                            rowSelection="multiple"
                            rowData={bulkVoucherData}
                            columnDefs={bulkVoucherColumn}
                            getRowId={bulkVoucherRowId}
                            onSelectionChanged={bulkVoucherSelect}
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={false}
                            suppressRowClickSelection={true}
                            animateRows={true}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="" className="op-primary-color text-light" disabled={values.submit_disabled} onClick={() => sendBulkVoucher(values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isBulkUpdate} onHide={setIsBulkUpdate} size="xl">
        <Formik onSubmit={getBulkUpdate} initialValues={{ file: [], vendor: "", status: "", vendor_show: true, status_show: true, submit_disabled: true }}>
          {({ handleSubmit, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title as={"h6"}>Bulk Update</Modal.Title>
                <CloseButton
                  onClick={() => {
                    setIsBulkUpdate(false);
                    setBulkUpdateCount(0);
                    setBulkUpdateData([]);
                    setBulkUpdateRecord([]);
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xxl={6}>
                    <Form.Group>
                      <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        name="file"
                        credits={false}
                        acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        instantUpload={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "file",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          setFieldValue("file", []);
                          setFieldValue("vendor_show", false);
                          setFieldValue("status_show", false);
                          setFieldValue("vendor", "");
                          setFieldValue("status", "");
                          setFieldValue("submit_disabled", true);
                          setBulkUpdateCount(0);
                          setBulkUpdateData([]);
                          setBulkUpdateRecord([]);
                          setTimeout(() => {
                            setFieldValue("vendor_show", true);
                            setFieldValue("status_show", true);
                          }, 1);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xxl={6}>
                    {values.vendor_show && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={vendorList3}
                          name="vendor"
                          placeholder="Select vendor"
                          disabled={values.file.length === 0}
                          onChange={(e) => {
                            setFieldValue("vendor", e.value);
                          }}
                        />
                      </Form.Group>
                    )}

                    {values.status_show && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={bulkVoucherStatus}
                          name="vendor"
                          placeholder="Select status"
                          disabled={values.vendor === ""}
                          onChange={(e) => {
                            setFieldValue("status", e.value);
                          }}
                        />
                      </Form.Group>
                    )}
                  </Col>

                  <Col xxl={12} className="d-flex justify-content-center align-items-center mb-2">
                    <Form.Group className="d-flex">
                      <Button type="submit" variant="" className="op-primary-color text-light" disabled={values.vendor === "" || values.status === ""} onClick={() => setFieldValue("submit_disabled", false)}>
                        Preview
                      </Button>
                      <Button type="button" variant="success" className="ms-2" onClick={() => window.open("https://www.nexcrmapis.com/cloud_staging/ext/glRedemption/images/Outperform_Redemption_Voucher_Update_Template.xlsx", "_blank")}>
                        Template
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col xxl={12} className="mb-3">
                    <p className="m-0 text-center">Please note, this file uploader only accepts excel files (xls or xlsx). Please download and use our template and enter your list into it.</p>
                  </Col>
                  {bulkUpdateCount > 0 && (
                    <Col xxl={12}>
                      <p className="mb-2">{bulkUpdateCount === 0 ? "No record" : bulkUpdateCount === 1 ? "1 record" : `${bulkUpdateCount} records`}</p>
                      <div style={containerStyle2}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                          <AgGridReact
                            ref={bulkUpdateRef}
                            rowSelection="multiple"
                            rowData={bulkUpdateData}
                            columnDefs={bulkUpdateColumn}
                            getRowId={bulkUpdateRowId}
                            onSelectionChanged={bulkUpdateSelect}
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={false}
                            suppressRowClickSelection={true}
                            animateRows={true}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="" className="op-primary-color text-light" disabled={values.submit_disabled} onClick={() => sendBulkUpdate(values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default RedemptionVoucher;
