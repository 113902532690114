import { useState, useEffect, useRef } from "react";
import { bookingDefaultFormat, jointBuyerDefaultFormat, mainBuyerDefaultFormat, modulesDefaultFormat } from "./booking/BookingFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleX, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import { useAuth } from "../auth/AuthContext";
import { FormCustomField, FormSelect } from "../includes/FormCustom";
import { Container, Row, Col, Stack, Button, Card, Nav, Form, Modal, ListGroup } from "react-bootstrap";
import { MobilePrefix, financingList, firstTimeBuyerList, paymentMethodList, salutationList, identityTypeList, occupationList, genderList, raceList, nationalityList, maritalStatusList, relationList, bumiputeraList, setCurrency } from "../lib/js/Function";
import { faHouseBuilding, faHouseChimneyWindow, faHouse, faHouseCircleCheck, faUser, faHandshake, faFolder, faEdit, faFilePdf, faFileLines, faPenNib, faCircleArrowDown, faBook, faTrashAlt, faRotateRight, faPencilAlt } from "@fortawesome/pro-light-svg-icons";
import { faCircleStar } from "@fortawesome/pro-regular-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import moment from "moment";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import SignaturePad from "react-signature-canvas";
import BookingUploadDocuments from "./booking/BookingUploadDocuments";
import BookingDownloadDocuments from "./booking/BookingDownloadDocuments";
import EditBuyer from "./booking/EditBuyer";
import EditBookingInformation from "./booking/EditBookingInformation";
import EditPaymentDetails from "./booking/EditPaymentDetails";
import EditSpaInformation from "./booking/EditSpaInformation";
import EditSolicitorInformation from "./booking/EditSolicitorInformation";
import EditFinancierInformation from "./booking/EditFinancierInformation";
import CreateAdditionalBuyer from "./booking/CreateAdditionalBuyer";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import Viewer from "react-viewer";
import "../lib/scss/bookingDetails.scss";
import "../lib/scss/op-step.scss";
import BookingLog from "./booking/BookingLog";
import { immediateToast } from "izitoast-react";
import iziToast from "izitoast";
import MaybankBanner from "../assets/maybank/Maybank-Home2u_1.jpg";

const BookingDetails = () => {
  registerPlugin(FilePondPluginPdfPreview, FilePondPluginImagePreview, FilePondPluginFileValidateType);
  const navigate = useNavigate();
  const { Formik, useFormikContext } = formik;
  const { session, logoutUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("booking");
  const { booking_id, deal_id } = useParams();
  const init = useRef(false);
  const [bookingData, setBookingData] = useState({});
  const [activeStage, setActiveStage] = useState(0);
  const [isEditBookingInformation, setIsEditBookingInformation] = useState(false);
  const [isEditPayment, setIsEditPayment] = useState(false);
  const [buyerData, setBuyerData] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [isEditBuyer, setIsEditBuyer] = useState(false);
  const [isEditSpa, setIsEditSpa] = useState(false);
  const [isEditSolicitor, setIsEditSolicitor] = useState(false);
  const [isEditFinancier, setIsEditFinancier] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [isSignature, setIsSignature] = useState(false);
  const [modules, setModules] = useState(modulesDefaultFormat);
  const [customFieldData, setCustomFieldData] = useState([]);
  const [isAddInfo, setIsAddInfo] = useState(false);
  const [stage, setStage] = useState([]);
  const signCanvas = useRef(null);
  const [isUploadDocuments, setIsUploadDocuments] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isDownloadDocuments, setIsDownloadDocuments] = useState(false);
  const [financingOptions, setFinancingOptions] = useState(financingList);
  const [prefixOptions, setPrefixOptions] = useState(MobilePrefix);
  const [raceOptions, setRaceOptions] = useState(raceList);
  const [firstTimeBuyerOptions, setFirstTimeBuyerOptions] = useState(firstTimeBuyerList);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState(paymentMethodList);
  const [salutationOptions, setSalutationOptions] = useState(salutationList);
  const [identityTypeOptions, setIdentityTypeOptions] = useState(identityTypeList);
  const [occupationOptions, setOccupationOptions] = useState(occupationList);
  const [genderOptions, setGenderOptions] = useState(genderList);
  const [nationalityOptions, setNationalityOptions] = useState(nationalityList);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState(maritalStatusList);
  const [relationOptions, setRelationOptions] = useState(relationList);
  const [bumiputeraOptions, setBumiputeraOptions] = useState(bumiputeraList);
  const [bookingFormat, setBookingFormat] = useState(bookingDefaultFormat);
  const [mainFormat, setMainFormat] = useState(mainBuyerDefaultFormat);
  const [jointFormat, setJointFormat] = useState(jointBuyerDefaultFormat);
  const [isCreateAddBuyer, setIsCreateAddBuyer] = useState(false);
  const [isPaymentReceipt, setIsPaymentReceipt] = useState(false);
  const [isId, setIsId] = useState(false);
  const [id, setId] = useState("");
  const [isApproval, setIsApproval] = useState(false);
  const [approvalData, setApprovalData] = useState({});
  const [signatureData, setSignatureData] = useState({ signature: "", signature_order: "", document: "", document_id: "" });
  const [addInfo, setAddInfo] = useState({ custom_field: [] });
  const [buyerRecord, setBuyerRecord] = useState([]);
  const [spaRecord, setSpaRecord] = useState({});
  const [updateState, setUpdateState] = useState({ buyer: true, approval: true, stage: true, custom_field: true, custom_form: true, documents: true, spa: true, booking: true });
  const customFieldSubSchema = yup.object().shape({ fd_values: yup.string().when("required", { is: 1, then: () => yup.string().required("The field is required") }) });
  const customFieldSchema = yup.object().shape({ custom_field: yup.array().of(customFieldSubSchema) });
  const cancelBookingSchema = yup.object().shape({ lostReasonID: yup.string().required("This field is required") });
  const [buyerNo, setBuyerNo] = useState(0);
  const [logRecord, setLogRecord] = useState([]);
  const [permission, setPermission] = useState({});
  const [lostReason, setLostReason] = useState([]);
  const [isCancelBooking, setIsCancelBooking] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [isEditBookingDates, setIsEditBookingDates] = useState(false);
  const [bookingDatesData, setBookingDatesData] = useState({
    booking_status: "",
    booking_reserved_date: "",
    booking_booked_date: "",
    booking_spa_sold_date: "",
    booking_cancelled_date: "",
  });

  const bookingDatesSchema = yup.object().shape({
    booking_reserved_date: yup.string().when("booking_status", {
      is: (val) => ["booked", "booking_confirmed", "spa_signed", "spa_stamped", "spa_sold", "cancelled"].includes(val),
      then: () => yup.string().required("The field is required"),
    }),

    booking_booked_date: yup.string().when("booking_status", {
      is: (val) => ["booking_confirmed", "spa_signed", "spa_stamped", "spa_sold", "cancelled"].includes(val),
      then: () => yup.string().required("The field is required"),
    }),
    booking_spa_sold_date: yup.string().when("booking_status", {
      is: (val) => ["spa_sold"].includes(val),
      then: () => yup.string().required("The field is required"),
    }),
    booking_cancelled_date: yup.string().when("booking_status", {
      is: (val) => ["cancelled"].includes(val),
      then: () => yup.string().required("The field is required"),
    }),
  });

  // GET FUNCTION ----------------------------------------------------------

  const getBooking = async (update = { buyer: true, approval: true, stage: true, custom_field: true, custom_form: true, documents: true, spa: true, booking: true }) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "ViewBooking",
          rea_booking_id: booking_id,
          deal_id: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        const currentUpdate = { ...update };

        if (currentUpdate.buyer) {
          currentUpdate.buyer = false;
          setBuyerRecord(data.buyer_record);
        }

        if (currentUpdate.approval) {
          currentUpdate.approval = false;
          setIsApproval(data.approval ? true : false);
          setApprovalData(data.approval || {});
        }

        if (currentUpdate.stage) {
          currentUpdate.stage = false;
          setStageSetup(data.stages, data.record, data?.spa_record);
          setBookingDatesData({
            booking_status: data.record.booking_status,
            booking_reserved_date: data.record.booking_reserved_date === "0000-00-00 00:00:00" ? "" : data.record.booking_reserved_date,
            booking_booked_date: data.record.booking_booked_date === "0000-00-00 00:00:00" ? "" : data.record.booking_booked_date,
            booking_spa_sold_date: data.record.booking_spa_sold_date === "0000-00-00 00:00:00" ? "" : data.record.booking_spa_sold_date,
            booking_cancelled_date: data.record.booking_cancelled_date === "0000-00-00 00:00:00" ? "" : data.record.booking_cancelled_date,
          });
        }

        if (currentUpdate.custom_field) {
          currentUpdate.custom_field = false;
          setCustomFieldData(data.custom_field.length > 0 ? data.custom_field : []);
          setAddInfo({ custom_field: data.custom_field.map((record) => ({ cf_id: record.cf_id, cf_label: record.cf_label, fd_values: record.fd_value || "", required: Number(record.compulsory_column) })) || [] });
        }

        if (currentUpdate.custom_form) {
          currentUpdate.custom_form = false;
          setSetupDocument(data.document);
        }

        if (currentUpdate.documents) {
          currentUpdate.documents = false;
          setDocuments(data.document_uploaded);
        }

        if (currentUpdate.spa) {
          currentUpdate.spa = false;
          setSpaRecord(data.spa_record || {});
        }

        if (currentUpdate.booking) {
          currentUpdate.booking = false;
          setBookingData(data.record);
        }

        setUpdateState(currentUpdate);
        getBookingLog();
        getBookingForm(data.document);

        if (!init.current) {
          setPermission(data.permission);
          setCountrySetup(data.country);
          setStateSetup(data.state);
          setCitySetup(data.city);
          setModules(data.modules);
          setSetupLostReason(data.lost_reason);
          setBookingFormat(data.booking_format);
          setMainFormat(data.main_buyer_format);
          setJointFormat(data.joint_buyer_format);
          setSetupOptions();
        }
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBookingLog = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "BookingLogs",
          rea_booking_id: booking_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setLogRecord(data.record);
      } else {
        setLogRecord([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBookingForm = async (documents) => {
    documents.forEach((record) => {
      axios.get(`${record.form_url}?booking_id=${booking_id}`);
    });
  };

  // SEND FUNCTION ---------------------------------------------------------

  const sendNextStage = (record, index) => {
    const showSwal = (text, confirmButtonText, cancelButtonText, confirmCallback) => {
      Swal.fire({
        icon: "question",
        text: text,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          confirmCallback();
        }
      });
    };

    const handleStatusChange = (status, textAdmin, textUser, confirmCallbackAdmin, confirmCallbackUser) => {
      if (Number(session.role_id) === 1) {
        showSwal(textAdmin, "Approve", "Reject", confirmCallbackAdmin);
      } else {
        showSwal(textUser, "Yes", "No", confirmCallbackUser);
      }
    };

    const validateBookingConfirmed = () => {
      handleStatusChange("booking_confirmed", "Would you like to proceed with confirming this booking?", "Would you like to notify admin to confirm this booking?", sendUnitConfirmed, () => {
        const title = `Request - ${bookingData.project_name}`;
        const remark = `Booked Request for ${bookingData.phase_name} - ${bookingData.unit_number} Pending Approval (${setCurrency(bookingData.nett_price)})`;
        sendApprovalRequest(title, remark, "booking_confirmed");
      });
    };

    const validateSpaSigned = () => {
      handleStatusChange("spa_signed", "Would you like to proceed with SPA signed?", "Would you like to notify admin to proceed with SPA signed?", sendSpaSigned, () => {
        const title = `Request - ${bookingData.project_name}`;
        const remark = `Spa Signed Request for ${bookingData.phase_name} - ${bookingData.unit_number} Pending Approval (${setCurrency(bookingData.nett_price)})`;
        sendApprovalRequest(title, remark, "spa_signed");
      });
    };

    const validateSpaStamped = () => {
      handleStatusChange("spa_stamped", "Would you like to proceed with SPA stamped?", "Would you like to notify admin to proceed with SPA stamped?", sendSpaStamped, () => {
        const title = `Request - ${bookingData.project_name}`;
        const remark = `Spa Stamped Request for ${bookingData.phase_name} - ${bookingData.unit_number} Pending Approval (${setCurrency(bookingData.nett_price)})`;
        sendApprovalRequest(title, remark, "spa_stamped");
      });
    };

    const validateFunctions = {
      booking_confirmed: validateBookingConfirmed,
      spa_signed: validateSpaSigned,
      spa_stamped: validateSpaStamped,
    };

    if (Number(session.booking_disable_status_changes) === 1) {
      iziToast.warning({
        title: "Caution",
        message: "Cannot change the status because the booking has been restricted",
        timeout: 10000,
      });
    } else if (bookingData.booking_status === "cancelled") {
      iziToast.warning({
        title: "Caution",
        message: "Cannot change the status because the booking has been cancelled",
        timeout: 10000,
      });
    } else if (bookingData.booking_status === "spa_sold") {
      iziToast.warning({
        title: "Caution",
        message: "Cannot change the status because the booking has been sold",
        timeout: 10000,
      });
    } else {
      if (index - activeStage === 1 && validateFunctions[record.status]) {
        validateFunctions[record.status]();
      } else {
        iziToast.warning({
          title: "Caution",
          message: "Status change is not allowed",
          timeout: 10000,
        });
      }
    }
  };

  const sendUnitSold = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "SoldUnit",
          owner_id: bookingData.user_id,
          deal_id: bookingData.deal_id,
          booking_status: bookingData.booking_status,
          rea_unit_id: bookingData.rea_unit_id,
          rea_booking_id: booking_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        const remark = `Booking - Sold: ${bookingData.unit_number} (${bookingData.phase_name})`;
        // await sendNotificationAdmin(remark);
        getBooking();
        if (isApproval) {
          sendApproveRequest();
        }

        iziToast.success({
          title: "Success",
          message: "Successfully mark unit as sold",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Failed to mark unit as sold, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendUnitCancel = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "CancelBooking",
          owner_id: bookingData.user_id,
          deal_id: bookingData.deal_id,
          booking_status: bookingData.booking_status,
          rea_unit_id: bookingData.rea_unit_id,
          rea_booking_id: booking_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          remark: values.remark,
          lost_reason_id: values.lostReasonID,
          sales_rea_id: spaRecord.sales_rea_id,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setIsCancelBooking(false);
        if (isApproval) {
          await sendApproveRequest();
        } else {
          const currentUpdate = { ...updateState };
          currentUpdate.stage = true;
          currentUpdate.booking = true;
          getBooking(currentUpdate);
        }

        iziToast.success({
          title: "Success",
          message: "Successfully mark booking as cancelled",
          timeout: 10000,
        });

        navigate("/booking/list", { replace: true });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Failed to mark booking as cancelled, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendUnitConfirmed = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateStages",
          owner_id: bookingData.user_id,
          deal_id: bookingData.deal_id,
          booking_status: bookingData.booking_status,
          rea_unit_id: bookingData.rea_unit_id,
          rea_booking_id: booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (isApproval) {
          await sendApproveRequest();
        } else {
          const currentUpdate = { ...updateState };
          currentUpdate.stage = true;
          currentUpdate.booking = true;
          getBooking(currentUpdate);
        }

        iziToast.success({
          title: "Success",
          message: "Successfully mark booking as confimed",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Failed to mark booking as confirmed, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendSpaSigned = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateStages",
          owner_id: bookingData.user_id,
          deal_id: bookingData.deal_id,
          booking_status: bookingData.booking_status,
          rea_unit_id: bookingData.rea_unit_id,
          rea_booking_id: booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (isApproval) {
          await sendApproveRequest();
        } else {
          const currentUpdate = { ...updateState };
          currentUpdate.stage = true;
          currentUpdate.booking = true;
          getBooking(currentUpdate);
        }

        const currentUpdate = { ...updateState };
        currentUpdate.stage = true;
        getBooking(currentUpdate);
        sendApproveRequest();
        iziToast.success({
          title: "Success",
          message: "Successfully moved to SPA signed",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Failed to move to SPA signed, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendSpaStamped = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateStages",
          owner_id: bookingData.user_id,
          deal_id: bookingData.deal_id,
          booking_status: bookingData.booking_status,
          rea_unit_id: bookingData.rea_unit_id,
          rea_booking_id: booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (isApproval) {
          await sendApproveRequest();
        } else {
          const currentUpdate = { ...updateState };
          currentUpdate.stage = true;
          currentUpdate.booking = true;
          getBooking(currentUpdate);
        }

        sendApproveRequest();
        iziToast.success({
          title: "Successful",
          message: "Successfully moved to SPA stamped",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Failed to move to SPA stamped, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendSignature = async () => {
    try {
      const canvas = signCanvas.current.getTrimmedCanvas();
      const context = canvas.getContext("2d");
      context.globalCompositeOperation = "destination-over";
      context.fillStyle = "#FFFFFF";
      context.fillRect(0, 0, canvas.width, canvas.height);
      const imageUrl = canvas.toDataURL("image/jpeg");
      const fileType = "jpg";
      const mimeType = "image/jpeg";

      const formData = new FormData();
      formData.append("task", "CreateSignature");
      formData.append("booking_id", booking_id);
      formData.append("booking_form_id", signatureData.document_id);
      formData.append("signature_order", signatureData.signature_order);
      formData.append("user_id", session.user_id);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("file_type", fileType);
      formData.append("mime_type", mimeType);
      formData.append("data", imageUrl);
      formData.append("company_id", session.company_id);

      const response = await axios.post(`${session.baseUrl}api/api_booking_signature.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        const currentUpdate = { ...updateState };
        currentUpdate.custom_form = true;
        getBooking(currentUpdate);
        setIsSignature(false);
        iziToast.success({
          title: "Success",
          message: "Successfully created the signature",
          timeout: 10000,
        });
      } else {
        setIsSignature(false);
        iziToast.error({
          title: "Caution",
          message: "Something wrong with your entry, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
      setIsSignature(false);
    }
  };

  const sendDeleteSignature = async (signature_id) => {
    try {
      const response = await axios.get("ws/ws_rea_booking_form.php", {
        params: {
          task: "RemoveSignature",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          booking_signature_id: signature_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getBooking();
        iziToast.success({
          title: "Successful",
          message: "Successfully removed the signature",
          timeout: 10000,
        });
      } else {
        setIsEditFinancier(false);
        iziToast.error({
          title: "Caution",
          message: "Failed to remove signature, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      setIsEditFinancier(false);
      console.log(error);
    }
  };

  const sendCustomField = async (values) => {
    try {
      var cf_id = [];
      var fd_value = [];
      var cf_label = [];
      var bookings = Array(values.custom_field.length).fill(booking_id);

      if (customFieldData.length > 0) {
        cf_id = values.custom_field.map((record) => record.cf_id);
        cf_label = values.custom_field.map((record) => record.cf_label);
        fd_value = values.custom_field.map((record) => (Array.isArray(record.fd_values) ? record.fd_values.join(",") : record.fd_values));
      }

      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateAdditionalInfo",
          rea_booking_id: booking_id,
          cf_id: cf_id.join("[-0-]"),
          fd_value: fd_value.join("[-0-]"),
          cf_label: cf_label.join("[-0-]"),
          record: bookings.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const currentUpdate = { ...updateState };
        currentUpdate.custom_field = true;
        getBooking(currentUpdate);
        setIsAddInfo(false);
        iziToast.success({
          title: "Success",
          message: "The additional information was updated successfully",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "There is something wrong with your entry, please try again or contact our developer",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendApprovalRequest = async (title, remark, action) => {
    try {
      if (isApproval) {
        iziToast.warning({
          title: "Caution",
          message: "Unable to notify the admin at the moment due to an existing pending request approval",
          timeout: 10000,
        });
      } else {
        const response = await axios.get("ws/ws_rea_approval.php", {
          params: {
            task: "NewApprovalRequest",
            user_tag_id: bookingData.user_tag_id,
            deal_id: deal_id,
            booking_id: booking_id,
            request_action: action,
            request_remark: remark,
            request_title: title,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const currentUpdate = { ...updateState };
          currentUpdate.approval = true;
          getBooking(currentUpdate);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRejectRequest = async () => {
    try {
      const response = await axios.get("ws/ws_rea_approval.php", {
        params: {
          task: "RejectApprovalRequest",
          booking_approval_id: approvalData.booking_approval_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const currentUpdate = { ...updateState };
        currentUpdate.approval = true;
        await getBooking(currentUpdate);
        iziToast.success({
          title: "Success",
          message: "Successfully reject the current approval",
          timeout: 10000,
        });
        // await sendNotificationAdmin(remark);
      } else {
        iziToast.error({
          title: "Caution",
          message: "Something wrong with your entry, please try again or contact our IT support",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCancelApproval = async () => {
    try {
      const response = await axios.get("ws/ws_rea_approval.php", {
        params: {
          task: "CancelApprovalRequest",
          booking_approval_id: approvalData.booking_approval_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const currentUpdate = { ...updateState };
        currentUpdate.approval = true;
        await getBooking(currentUpdate);
        iziToast.success({
          title: "Success",
          message: "Successfully cancel the current approval request",
          timeout: 10000,
        });
      } else {
        iziToast.error({
          title: "Caution",
          message: "Something wrong with your entry, please try again or contact our IT support",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendApproveRequest = async () => {
    try {
      const response = await axios.get("ws/ws_rea_approval.php", {
        params: {
          task: "ApproveBookingRequest",
          booking_approval_id: approvalData.booking_approval_id,
          request_approval: "approved",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const currentUpdate = { ...updateState };
        currentUpdate.stage = true;
        currentUpdate.approval = true;
        currentUpdate.booking = true;
        getBooking(currentUpdate);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteDocument = async (bookingForm) => {
    try {
      Swal.fire({
        icon: "question",
        text: `Are you sure you want to remove ${bookingForm.form_title}?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.get("ws/ws_rea_booking_form.php", {
            params: {
              task: "DeleteDocument",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              booking_form_id: bookingForm.booking_form_id,
            },
          });

          const data = response.data;
          if (data.status === 0) {
            const recordUpdate = { ...updateState };
            recordUpdate.documents = true;
            getBooking(recordUpdate);
            immediateToast("success", { title: "Success", message: "File has completely removed", timer: 10000 });
          } else {
            immediateToast("warning", { title: "Unfortunately", message: data.message, timer: 10000 });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteBuyer = async (buyerRecord) => {
    try {
      Swal.fire({
        icon: "question",
        text: `Are you sure you want to remove ${buyerRecord.buyer_name}?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.get("ws/ws_rea_booking.php", {
            params: {
              task: "DeleteBuyer",
              rea_booking_buyer_id: buyerRecord.rea_booking_buyer_id,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          });

          const data = response.data;
          if (data.status === 0) {
            const currentUpdate = { ...updateState };
            currentUpdate.buyer = true;
            getBooking(currentUpdate);
            immediateToast("success", { title: "Success", message: `${buyerRecord.buyer_name} has been removed`, timer: 10000 });
          } else {
            immediateToast("warning", { title: "Unfortunately", message: data.message, timer: 10000 });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditBookingDates = async (values) => {
    const date_backup = { ...bookingDatesData };
    setBookingDatesData((prev) => ({
      ...prev,
      booking_reserved_date: values.booking_reserved_date,
      booking_booked_date: values.booking_booked_date,
      booking_spa_sold_date: values.booking_spa_sold_date,
      booking_cancelled_date: values.booking_cancelled_date,
    }));

    const booking_record = { ...bookingData };
    booking_record.booking_reserved_date = values.booking_reserved_date;
    booking_record.booking_booked_date = values.booking_booked_date;
    booking_record.booking_spa_sold_date = values.booking_spa_sold_date;
    booking_record.booking_cancelled_date = values.booking_cancelled_date;
    setBookingData(booking_record);

    const reserved_index = stage.findIndex((stage) => stage.status === "booked");
    const booked_index = stage.findIndex((stage) => stage.status === "booking_confirmed");
    const spa_sold_index = stage.findIndex((stage) => stage.status === "spa_sold");
    const stages_record = [...stage];
    stages_record[reserved_index].date = values.booking_reserved_date;
    stages_record[booked_index].date = values.booking_booked_date;
    stages_record[spa_sold_index].date = values.booking_spa_sold_date;
    setStage(stages_record);
    setIsEditBookingDates(false);

    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "EditBookingDates",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          booking_id: booking_id,
          booking_status: bookingData.booking_status,
          booking_reserved_date: values.booking_reserved_date === "0000-00-00 00:00:00" ? "" : values.booking_reserved_date,
          booking_booked_date: values.booking_booked_date === "0000-00-00 00:00:00" ? "" : values.booking_booked_date,
          booking_spa_sold_date: values.booking_spa_sold_date === "0000-00-00 00:00:00" ? "" : values.booking_spa_sold_date,
          booking_cancelled_date: values.booking_cancelled_date === "0000-00-00 00:00:00" ? "" : values.booking_cancelled_date,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        immediateToast("success", { title: "Success", message: "Booking dates has been updated", timer: 10000 });
      } else {
        setBookingDatesData(date_backup);
        immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // OPEN FUNCTION ---------------------------------------------------------

  const openEditBuyer = async (record, index) => {
    setBuyerData(record);
    setBuyerNo(index + 1);
    setIsEditBuyer(true);
  };

  const openSignature = (document, document_id, signature, signature_order) => {
    setSignatureData({
      signature: signature,
      signature_order: signature_order,
      document_id: document_id,
      document: document,
    });

    setIsSignature(true);
  };

  const openDocuments = (record) => {
    window.open(`https://app-r1.outperformhq.io/assets/rea_booking/${session.company_id}/booking_forms/${record.form_title}_${booking_id}.pdf`, "_blank");
  };

  const openDocumentsUploaded = (record) => {
    window.open(`${record.form_url}`, "_blank");
  };

  const openIdBuyer = (record, mode) => {
    const file = mode === "front" ? record.buyer_id_front : record.buyer_id_back;
    const fileSplit = file.split(".");
    const fileType = fileSplit[fileSplit.length - 1];
    if (fileType === "pdf") {
      window.open(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${file}`, "_blank");
    } else {
      setId(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${file}`);
      setIsId(true);
    }
  };

  const openReceipt = () => {
    const fileSplit = bookingData.payment_slip.split(".");
    const fileType = fileSplit[fileSplit.length - 1];
    if (fileType === "pdf") {
      window.open(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/payment_slip/${bookingData.payment_slip}`, "_blank");
    } else {
      setIsPaymentReceipt(true);
    }
  };

  // ONCHANGE FUNCTION -----------------------------------------------------

  const onchangeActionTabs = (key) => {
    setActiveTab(key);
  };

  // SET FUNCTION ----------------------------------------------------------

  const setSetupOptions = async () => {
    const optionsMapping = {
      financingList: setFinancingOptions,
      raceList: setRaceOptions,
      prefixList: setPrefixOptions,
      firstTimeBuyerList: setFirstTimeBuyerOptions,
      paymentMethodList: setPaymentMethodOptions,
      salutationList: setSalutationOptions,
      identityTypeList: setIdentityTypeOptions,
      occupationList: setOccupationOptions,
      genderList: setGenderOptions,
      nationalityList: setNationalityOptions,
      maritalStatusList: setMaritalStatusOptions,
      relationList: setRelationOptions,
      bumiputeraList: setBumiputeraOptions,
    };

    try {
      const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: session.company_id,
          filename: "booking_modules",
        },
      });

      const data = response.data;

      if (Number(data.status) === 0) {
        for (const [key, setter] of Object.entries(optionsMapping)) {
          if (data.record[key] !== undefined) {
            setter(data.record[key]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setStageSetup = (stageRecord, booking, spa_record) => {
    const stageMap = {
      reserved: { title: "Reserved", date: "booking_reserved_date", status: "booked" },
      booked: { title: Number(session.role_id) !== 1 && booking.booking_status === "booked" ? "Request For Booked" : "Booked", date: "booking_booked_date", status: "booking_confirmed" },
      spa_signed: { title: Number(session.role_id) !== 1 && booking.booking_status === "booking_confirmed" ? "Request for SPA Signed" : "SPA Signed", date: "booking_spa_signed_date", status: "spa_signed" },
      spa_stamped: { title: Number(session.role_id) !== 1 && booking.booking_status === "spa_signed" ? "Request for SPA Stamped" : "SPA Stamped", date: "booking_spa_stamped_date", status: "spa_stamped" },
      spa_sold: { title: "Sold", date: "booking_spa_sold_date", status: "spa_sold" },
    };

    const setDates = (key) => {
      if (key === "spa_signed" && spa_record && spa_record?.spaDate && spa_record?.spaDate !== "0000-00-00 00:00:00") {
        return spa_record?.spaDate;
      }

      if (key === "spa_stamped" && spa_record && spa_record?.spaStampDate && spa_record?.spaStampDate !== "0000-00-00 00:00:00") {
        return spa_record?.spaStampDate;
      }

      return booking[stageMap[key].date];
    }

    const stageList = Object.keys(stageMap).reduce((list, key) => {
      if (Number(stageRecord[key]) === 1) {
        list.push({ title: stageMap[key].title, date: setDates(key, booking), status: stageMap[key].status });
      }
      return list;
    }, []);

    setStage(stageList);
    const activeStageIndex = stageList.findIndex((stage) => stage.status === booking.booking_status);
    setActiveStage(activeStageIndex !== -1 ? activeStageIndex : "");
  };

  const setSetupDocument = (documents) => {
    const documentArray = documents.map((document) => {
      if (document.signature_count > 0) {
        const signatures = {};
        for (let i = 1; i <= Number(document.signature_count); i++) {
          const signature = Array.isArray(document.signature_record) ? document.signature_record.find((record) => Number(record.signature_order) === i) : null;
          signatures[`signature_${i}_title`] = `Purchaser ${i}`;
          signatures[`signature_${i}_data`] = signature || null;
        }
        return { ...document, ...signatures };
      } else {
        return document;
      }
    });

    setDocumentList(documentArray || []);
  };

  const setCountrySetup = (data) => {
    const options = data.map((record) => ({ label: record.country_title, value: record.country_title }));
    options.unshift({ label: "Select Country", value: "" });
    setCountry(options);
  };

  const setStateSetup = (data) => {
    const options = data.map((record) => ({ label: record.state_title, value: record.state_title, country: record.country_title }));
    options.unshift({ label: "Select State", value: "", country: "" });
    setState(options);
  };

  const setCitySetup = (data) => {
    const options = data.map((record) => ({ label: record.city_title, value: record.city_title, state: record.state_title }));
    options.unshift({ label: "Select City", value: "", state: "" });
    setCity(options);
  };

  const setPercentage = (input) => {
    if (input) {
      return Math.floor(input) + "%";
    }
    return "";
  };

  const setFinancing = (input) => {
    if (input === "bank") {
      return "Bank";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "government_loan") {
      return "Government Loan";
    } else if (input === "staff_loan") {
      return "Staff Loan";
    } else if (input === "other") {
      return "Rent To Own";
    }
  };

  const setFirstTimeBuyer = (input) => {
    if (input === "yes") {
      return "Yes";
    } else if (input === "no") {
      return "No";
    }
  };

  const setPaymentMethod = (input) => {
    if (input === "bank_draft") {
      return "Online Banking";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "cc") {
      return "Credit Card";
    } else if (input === "cheque") {
      return "Cheque";
    } else if (input === "others") {
      return "Others";
    }
  };

  const setBuyerShow = (role, column) => {
    if (role === "main") {
      return Number(mainFormat[`${column}_show`]) === 1;
    } else {
      return Number(jointFormat[`${column}_show`]) === 1;
    }
  };

  const setBuyerLabel = (role, column) => {
    if (role === "main") {
      return mainFormat[`${column}_label`];
    } else {
      return jointFormat[`${column}_label`];
    }
  };

  const setBumiputera = (input) => {
    if (Number(input) === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const setMobileNumber = (prefix, number) => {
    if (number) {
      if (number.length > 15) {
        return `+${prefix} ${number}`;
      } else if (number && number !== "0") {
        const contactNumber = `+${prefix}${number}`;
        const phoneNumber = parsePhoneNumber(contactNumber);
        return phoneNumber.formatInternational();
      } else {
        return "";
      }
    }

    return "";
  };

  const setShowSection = (conditions) => {
    return conditions.some((condition) => Number(condition) === 1);
  };

  const setFieldLabel = (label, required) => {
    return `${label} ${Number(required) === 1 ? "*" : ""}`;
  };

  const setShowAction = (checkpoint, bookingStatus) => {
    const status = {
      reserved: bookingStatus === "booked",
      booking_confirmed: bookingStatus === "booked" || bookingStatus === "booking_confirmed",
      spa_signed: bookingStatus === "booked" || bookingStatus === "booking_confirmed" || bookingStatus === "spa_signed",
      spa_stamped: bookingStatus === "booked" || bookingStatus === "booking_confirmed" || bookingStatus === "spa_signed" || bookingStatus === "spa_stamped",
      spa_sold: bookingStatus === "booked" || bookingStatus === "booking_confirmed" || bookingStatus === "spa_signed" || bookingStatus === "spa_stamped" || bookingStatus === "spa_sold",
    };

    if (bookingStatus !== "cancelled") {
      return Number(session.role_id) === 1 ? true : status[checkpoint];
    } else {
      return false;
    }
  };

  const setSetupLostReason = (list) => {
    const options = list.map((record) => ({ label: record.reason_title, value: record.lost_reason_id }));
    setLostReason(options);
  };

  const setCheckImg = () => {
    setImgError(true);
  };

  const setFloatDigit = (input) => {
    const cleaned = input.replace(/[^\d.]/g, '');
    const number = parseFloat(cleaned);
    return number.toFixed(2);
  }

  // RENDER FUNCTION -------------------------------------------------------

  const RenderBooking = () => {
    const bookingInformation = [
      { label: bookingFormat.ori_price_label, show: Number(bookingFormat.ori_price_show), value: bookingData.unit_selling_price_ori ? setCurrency(setFloatDigit(bookingData.unit_selling_price_ori)) : "-" },
      { label: bookingFormat.selling_price_label, show: Number(bookingFormat.selling_price_show), value: setCurrency(bookingData.unit_selling_price) || "-" },
      { label: bookingFormat.net_price_label, show: Number(bookingFormat.net_price_show), value: setCurrency(bookingData.nett_price) || "-" },
      { label: bookingFormat.bumi_discount_label, show: Number(bookingFormat.bumi_discount_show), value: setPercentage(bookingData.bumi_package) || "-" },
      { label: bookingFormat.rebate_package_label, show: Number(bookingFormat.rebate_package_show), value: bookingData.rebate_title ? bookingData.rebate_title : "-" },
      { label: bookingFormat.bumiputera_label, show: Number(bookingFormat.bumiputera_show), value: setBumiputera(buyerRecord[0].buyer_bumiputera) || "-" },
      { label: bookingFormat.financing_label, show: Number(bookingFormat.financing_show), value: bookingData.financing ? setFinancing(bookingData.financing) : "-" },
      { label: bookingFormat.first_time_buyer_label, show: Number(bookingFormat.first_time_buyer_show), value: bookingData.first_time_buyer ? setFirstTimeBuyer(bookingData.first_time_buyer) : "-" },
    ];

    const paymentDetails = [
      { label: bookingFormat.booking_fee_label, show: Number(bookingFormat.booking_fee_show), value: setCurrency(bookingData.booking_deposit) || "-" },
      { label: bookingFormat.payment_method_label, show: Number(bookingFormat.payment_method_show), value: setPaymentMethod(bookingData.payment_method) || "-" },
      { label: bookingFormat.bank_label, show: Number(bookingFormat.bank_show), value: bookingData.bank_name || "-" },
      { label: bookingFormat.payment_reference_label, show: Number(bookingFormat.payment_reference_show), value: bookingData.payment_ref || "-" },
    ];

    return (
      <>
        {setShowSection([bookingFormat.selling_price_show, bookingFormat.net_price_show, bookingFormat.bumi_discount_show, bookingFormat.rebate_package_show, bookingFormat.financing_show, bookingFormat.first_time_buyer_show]) && (
          <Card className="shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">Booking Information</span>
              {setShowAction(permission.update_booking_info, bookingData.booking_status) && (
                <Button variant="light" className="border shadow-sm me-2" onClick={setIsEditBookingInformation}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                {bookingInformation.map((record, index) => {
                  const visibleCount = bookingInformation.filter((item) => item.show === 1).length;
                  const visibleIndex = bookingInformation.slice(0, index + 1).filter((item) => item.show === 1).length;
                  const isLastThreeVisible = visibleCount - visibleIndex < 3;
                  return (
                    <Col xxl={4} className={`${!isLastThreeVisible ? "mb-3" : ""} ${record.show === 1 ? "" : "d-none"}`} key={index}>
                      <Form.Label>
                        <span className="fw-bold text-uppercase">{record.label}</span> <br />
                        <span className="text-muted text-uppercase">{record.value || "-"}</span>
                      </Form.Label>
                    </Col>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        )}

        {setShowSection([bookingFormat.booking_fee_show, bookingFormat.payment_method_show, bookingFormat.payment_reference_show, bookingFormat.payment_receipt_show]) && (
          <Card className="mt-3 shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">Payment Details</span>
              {setShowAction(permission.update_payment_info, bookingData.booking_status) && (
                <Button variant="light" className="border shadow-sm me-2" onClick={setIsEditPayment}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                {paymentDetails.map((record, index) => (
                  <Col xxl={4} className={`${record.show === 1 ? "" : "d-none"}`} key={index}>
                    <Form.Label>
                      <span className="fw-bold text-uppercase">{record.label}</span> <br />
                      <span className="text-muted text-uppercase">{record.value || "-"}</span>
                    </Form.Label>
                  </Col>
                ))}
              </Row>

              <Row>
                {Number(bookingFormat.payment_method_show) === 1 && (
                  <Col xxl={6} className="mt-3">
                    {bookingData.payment_slip ? (
                      <Form.Group>
                        <Form.Label className="fw-bold text-uppercase">{bookingFormat.payment_receipt_label}</Form.Label>
                        {bookingData.payment_slip && (
                          <div className="cursor-pointer" onClick={openReceipt}>
                            <FilePond
                              allowMultiple={false}
                              allowProcess={false}
                              allowRemove={false}
                              allowDrop={false}
                              allowRevert={false}
                              name={`idFrontName`}
                              maxFiles={1}
                              credits={false}
                              instantUpload={false}
                              files={[{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/payment_slip/${bookingData.payment_slip}`, options: { type: "remote" } }]}
                            />
                          </div>
                        )}
                      </Form.Group>
                    ) : (
                      <Form.Label>
                        <span className="fw-bold text-uppercase">{bookingFormat.payment_receipt_label}</span> <br />
                        <span className="text-muted text-uppercase">{bookingData.payment_slip || "-"}</span>
                      </Form.Label>
                    )}
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        )}

        {(setShowSection([bookingFormat.source_show]) || customFieldData.length > 0) && (
          <Card className="mt-3 shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">Additional Information</span>
              {setShowAction(permission.update_additional_info, bookingData.booking_status) && customFieldData.length > 0 && (
                <Button variant="light" className="border shadow-sm me-2" onClick={setIsAddInfo}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                {Number(bookingFormat.source_show) === 1 && (
                  <Col xxl={4} className="mb-3">
                    <Form.Label>
                      <span className="fw-bold text-uppercase">{bookingFormat.source_label}</span> <br />
                      <span className="text-muted text-uppercase">{bookingData.ds_title || "-"}</span>
                    </Form.Label>
                  </Col>
                )}

                {customFieldData.map((record, index) => (
                  <Col xxl={4} key={index} className="mb-3">
                    <Form.Label>
                      <span className="fw-bold text-uppercase">{record.cf_label}</span> <br />
                      <span className="text-muted text-uppercase">{record.fd_value || "-"}</span>
                    </Form.Label>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        )}
      </>
    );
  };

  const RenderBuyerContainer = () => {
    return (
      <>
        {setShowAction(permission.create_additional_buyer, bookingData.booking_status) && (
          <Button variant="" className="op-primary-color text-light mb-3" onClick={setIsCreateAddBuyer}>
            Create Additional Buyer
          </Button>
        )}

        {buyerRecord.length > 0 &&
          buyerRecord.map((record, index) => (
            <Card key={index} className="shadow-sm mb-3">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="text-uppercase op-text-bigger fw-bold">Buyer {index + 1}</span>
                <div>
                  {setShowAction(permission.update_buyer, bookingData.booking_status) && (
                    <Button variant="light" className="border shadow-sm me-2" onClick={() => openEditBuyer(record, index)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}

                  {setShowAction(permission.update_buyer, bookingData.booking_status) && record.buyer_role !== "main" && (
                    <Button variant="light" className="border shadow-sm" onClick={() => sendDeleteBuyer(record)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  )}
                </div>
              </Card.Header>
              <Card.Body>{RenderBuyer(record)}</Card.Body>
            </Card>
          ))}
      </>
    );
  };

  const RenderBuyer = (buyer) => {
    const buyerInformation = [
      { label: setBuyerLabel(buyer.buyer_role, "salutation"), show: setBuyerShow(buyer.buyer_role, "salutation"), value: buyer.buyer_salutation },
      { label: setBuyerLabel(buyer.buyer_role, "fullname"), show: setBuyerShow(buyer.buyer_role, "fullname"), value: buyer.buyer_name },
      { label: setBuyerLabel(buyer.buyer_role, "identification_type"), show: setBuyerShow(buyer.buyer_role, "identification_type"), value: buyer.buyer_id_type },
      { label: setBuyerLabel(buyer.buyer_role, "identification_number"), show: setBuyerShow(buyer.buyer_role, "identification_number"), value: buyer.buyer_id_number },
      { label: setBuyerLabel(buyer.buyer_role, "occupation"), show: setBuyerShow(buyer.buyer_role, "occupation"), value: buyer.buyer_occupation },
      { label: setBuyerLabel(buyer.buyer_role, "monthly_income"), show: setBuyerShow(buyer.buyer_role, "monthly_income"), value: setCurrency(buyer.buyer_income) },
      { label: setBuyerLabel(buyer.buyer_role, "dob"), show: setBuyerShow(buyer.buyer_role, "dob"), value: buyer.buyer_dob ? moment(new Date(buyer.buyer_dob)).format("ll") : "" },
      { label: setBuyerLabel(buyer.buyer_role, "gender"), show: setBuyerShow(buyer.buyer_role, "gender"), value: buyer.buyer_gender },
      { label: setBuyerLabel(buyer.buyer_role, "race"), show: setBuyerShow(buyer.buyer_role, "race"), value: buyer.buyer_race },
      { label: setBuyerLabel(buyer.buyer_role, "nationality"), show: setBuyerShow(buyer.buyer_role, "nationality"), value: buyer.buyer_nationality },
      { label: setBuyerLabel(buyer.buyer_role, "marital_status"), show: setBuyerShow(buyer.buyer_role, "marital_status"), value: buyer.buyer_marital },
      { label: setBuyerLabel(buyer.buyer_role, "email_address"), show: setBuyerShow(buyer.buyer_role, "email_address"), value: buyer.buyer_email },
      { label: setBuyerLabel(buyer.buyer_role, "mobile_number"), show: setBuyerShow(buyer.buyer_role, "mobile_number"), value: setMobileNumber(buyer.buyer_mobile_prefix, buyer.buyer_mobile_number) },
      { label: setBuyerLabel(buyer.buyer_role, "office_number"), show: setBuyerShow(buyer.buyer_role, "office_number"), value: setMobileNumber(buyer.buyer_house_prefix, buyer.buyer_house_number) },
      { label: setBuyerLabel(buyer.buyer_role, "fax_number"), show: setBuyerShow(buyer.buyer_role, "fax_number"), value: buyer.buyer_fax_number },
      { label: setBuyerLabel(buyer.buyer_role, "address1"), show: setBuyerShow(buyer.buyer_role, "address1"), value: buyer.buyer_address1 },
      { label: setBuyerLabel(buyer.buyer_role, "address2"), show: setBuyerShow(buyer.buyer_role, "address2"), value: buyer.buyer_address2 },
      { label: setBuyerLabel(buyer.buyer_role, "address3"), show: setBuyerShow(buyer.buyer_role, "address3"), value: buyer.buyer_address3 },
      { label: setBuyerLabel(buyer.buyer_role, "postcode"), show: setBuyerShow(buyer.buyer_role, "postcode"), value: buyer.buyer_postcode },
      { label: setBuyerLabel(buyer.buyer_role, "country"), show: setBuyerShow(buyer.buyer_role, "country"), value: buyer.buyer_country },
      { label: setBuyerLabel(buyer.buyer_role, "state"), show: setBuyerShow(buyer.buyer_role, "state"), value: buyer.buyer_state },
      { label: setBuyerLabel(buyer.buyer_role, "city"), show: setBuyerShow(buyer.buyer_role, "city"), value: buyer.buyer_city },
      { label: setBuyerLabel(buyer.buyer_role, "sst_number"), show: setBuyerShow(buyer.buyer_role, "sst_number"), value: buyer.sst_number },
      { label: setBuyerLabel(buyer.buyer_role, "tax_number"), show: setBuyerShow(buyer.buyer_role, "tax_number"), value: buyer.tax_number },
      { label: setBuyerLabel(buyer.buyer_role, "emergency_contact_fullname"), show: setBuyerShow(buyer.buyer_role, "emergency_contact_fullname"), value: buyer.emergency_name },
      { label: setBuyerLabel(buyer.buyer_role, "emergency_contact_relation"), show: setBuyerShow(buyer.buyer_role, "emergency_contact_relation"), value: buyer.emergency_relation },
      { label: setBuyerLabel(buyer.buyer_role, "emergency_contact_mobile_number"), show: setBuyerShow(buyer.buyer_role, "emergency_contact_mobile_number"), value: setMobileNumber(buyer.emergency_prefix, buyer.emergency_number) },
      { label: setBuyerLabel(buyer.buyer_role, "emergency_contact_email"), show: setBuyerShow(buyer.buyer_role, "emergency_contact_email"), value: buyer.emergency_email },
    ];

    return (
      <>
        <Row>
          {buyerInformation.map((record, index) => (
            <Col xxl={4} className={`mb-3 ${record.show ? "" : "d-none"}`} key={index}>
              <Form.Label>
                <span className="fw-bold text-uppercase">{record.label}</span> <br />
                <span className="text-muted text-uppercase">{record.value || "-"}</span>
              </Form.Label>
            </Col>
          ))}
        </Row>

        <Row>
          {setBuyerShow(buyer.buyer_role, "identification_card_front") && (
            <Col xxl={6}>
              <Form.Group>
                <Form.Label className="fw-bold text-uppercase">{setBuyerLabel(buyer.buyer_role, "identification_card_front")}</Form.Label>
                {buyer.buyer_id_front && buyer.buyer_id_front !== "-" && (
                  <div className="cursor-pointer" onClick={() => openIdBuyer(buyer, "front")}>
                    <FilePond
                      allowMultiple={false}
                      allowProcess={false}
                      allowDrop={false}
                      allowRevert={false}
                      allowRemove={false}
                      credits={false}
                      instantUpload={false}
                      maxFiles={1}
                      files={[{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${buyer.buyer_id_front}`, options: { type: "remote" } }]}
                    />
                  </div>
                )}
              </Form.Group>
            </Col>
          )}

          {setBuyerShow(buyer.buyer_role, "identification_card_back") && (
            <Col xxl={6}>
              <Form.Group>
                <Form.Label className="fw-bold text-uppercase">{setBuyerLabel(buyer.buyer_role, "identification_card_back")}</Form.Label>
                {buyer.buyer_id_back && buyer.buyer_id_back !== "-" && (
                  <div className="cursor-pointer" onClick={() => openIdBuyer(buyer, "back")}>
                    <FilePond
                      allowMultiple={false}
                      allowProcess={false}
                      allowDrop={false}
                      allowRevert={false}
                      allowRemove={false}
                      credits={false}
                      instantUpload={false}
                      maxFiles={1}
                      files={[{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${buyer.buyer_id_back}`, options: { type: "remote" } }]}
                    />
                  </div>
                )}
              </Form.Group>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const RenderSpa = () => {
    return (
      <>
        {Number(modules.is_spa_info) === 1 && (
          <Card className="shadow-sm mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">SPA Information</span>
              {setShowAction(permission.update_spa_info, bookingData.booking_status) && (
                <Button variant="'light" className="border shadow-sm" onClick={setIsEditSpa}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">SPA Date</span> <br />
                    <span className="text-muted text-uppercase">
                      {spaRecord.spaDate !== "0000-00-00 00:00:00" ? moment(new Date(spaRecord.spaDate)).format("ll") : bookingData.booking_spa_signed_date !== "0000-00-00 00:00:00" ? moment(new Date(bookingData.booking_spa_signed_date)).format("ll") : "-"}
                    </span>
                  </Form.Label>
                </Col>
                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">SPA Stamped Date</span> <br />
                    <span className="text-muted text-uppercase">
                      {spaRecord.spaStampDate !== "0000-00-00 00:00:00" ? moment(new Date(spaRecord.spaStampDate)).format("ll") : bookingData.booking_spa_stamped_date !== "0000-00-00 00:00:00" ? moment(new Date(bookingData.booking_spa_stamped_date)).format("ll") : "-"}
                    </span>
                  </Form.Label>
                </Col>
                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">SPA Reference</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord.spaRef || "-"}</span>
                  </Form.Label>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        {Number(modules.is_solicitor_info) === 1 && (
          <Card className="shadow-sm mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">Solicitor Information</span>
              {setShowAction(permission.update_solicitor_info, bookingData.booking_status) && (
                <Button variant="'light" className="border shadow-sm" onClick={setIsEditSolicitor}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Solicitor Name</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord.solicitorName || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Solicitor Contact Number</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord.solicitorContactNum || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Solicitor Address</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord.solicitorAddress || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Solicitor PIC</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord.solicitorPic || "-"}</span>
                  </Form.Label>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        {Number(modules.is_financier_info) === 1 && (
          <Card className="shadow-sm mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span className="text-uppercase op-text-bigger fw-bold">Financier Information</span>
              {setShowAction(permission.update_financier_info, bookingData.booking_status) && (
                <Button variant="'light" className="border shadow-sm" onClick={setIsEditFinancier}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xxl={4} className="mb-3">
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Financier Name</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord?.financierName || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4} className="mb-3">
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Financier PIC</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord?.financierPic || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4} className="mb-3">
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Financier PIC Contact</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord?.financierPicContact || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Financier Branch</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord?.financierBranch || "-"}</span>
                  </Form.Label>
                </Col>

                <Col xxl={4}>
                  <Form.Label>
                    <span className="fw-bold text-uppercase">Financier Branch Address</span> <br />
                    <span className="text-muted text-uppercase">{spaRecord?.financierBranchAddress || "-"}</span>
                  </Form.Label>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </>
    );
  };

  const RenderDocument = () => {
    return (
      <div>
        <Stack direction="horizontal" className="mb-3">
          <Button variant="light" className="border shadow-sm me-2" onClick={setIsDownloadDocuments}>
            <FontAwesomeIcon icon={faCircleArrowDown} size="xl" />
          </Button>
          {setShowAction(permission.upload_documents, bookingData.booking_status) && (
            <Button variant="" className="op-primary-color text-light text-uppercase" onClick={setIsUploadDocuments}>
              Upload Documents
            </Button>
          )}
        </Stack>

        {documentList.length > 0 && (
          <Row className="mb-3">
            <Col xxl={12} className="mb-2">
              <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center border-0" style={{ backgroundColor: "#ddd" }}>
                  <span className="text-uppercase op-text-bigger fw-bold">Custom Form Documents</span>
                </Card.Header>
              </Card>
            </Col>
            {documentList.map((record, index) => (
              <Col xxl={6} key={index} className="mb-3">
                <Card className="border h-100 shadow-sm">
                  <Card.Header className="text-uppercase d-flex justify-content-between align-items-center">
                    <span className="op-text-bigger fw-bold text-uppercase">
                      <FontAwesomeIcon icon={faFileLines} size="lg" className="me-2" />
                      {record.form_title}
                    </span>
                    <div>
                      <Button variant="light" className="shadow-sm border text-uppercase me-2" onClick={() => axios.get(`${record.form_url}?booking_id=${booking_id}`)}>
                        <FontAwesomeIcon icon={faRotateRight} size="lg" />
                      </Button>
                      <Button variant="light" className="shadow-smF border text-uppercase" onClick={() => openDocuments(record)}>
                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                      </Button>
                    </div>
                  </Card.Header>
                  {!(bookingData.booking_status === "spa_sold" || bookingData.booking_status === "cancelled") && (
                    <Card.Body>
                      {record.signature_count > 0 ? Array.from({ length: record.signature_count }, (_, i) => i).map((_, i) => RenderSignatureButton(record[`signature_${i + 1}_title`], i, record)) : <div className="d-flex justify-content-center align-items-center h-100">No Signature</div>}
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        )}

        {documents.length > 0 && (
          <Row>
            <Col xxl={12} className="mb-2">
              <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center border-0" style={{ backgroundColor: "#ddd" }}>
                  <span className="text-uppercase op-text-bigger fw-bold">Uploaded Documents</span>
                </Card.Header>
              </Card>
            </Col>
            {documents.map((record, index) => (
              <Col xxl={6} key={index} className="mb-3">
                <Card className="border h-100 shadow-sm">
                  <Card.Header className="d-flex justify-content-between align-items-center border-0">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faFileLines} size="xl" className="me-3" />
                      <p className="mb-0" style={{ lineHeight: 1.2 }}>
                        <span className="op-text-bigger fw-bold text-uppercase">{record.form_title} </span> <br />
                        <span style={{ fontSize: 11, color: "#999" }}>
                          {record.user_name} - {moment(new Date(record.date_time_create)).format("LLL")}
                        </span>
                      </p>
                    </div>
                    <div>
                      <Button variant="light" className="shadow-sm border text-uppercase me-2" onClick={() => openDocumentsUploaded(record)}>
                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                      </Button>
                      <Button variant="light" className="shadow-sm border text-uppercase" onClick={() => sendDeleteDocument(record)}>
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                      </Button>
                    </div>
                  </Card.Header>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
    );
  };

  const RenderActionTab = (tab) => {
    switch (tab) {
      case "booking":
        return RenderBooking();
      case "buyer":
        return RenderBuyerContainer();
      case "spa":
        return RenderSpa();
      case "documents":
        return RenderDocument();
      case "log":
        return <BookingLog logRecord={logRecord} bookingFormat={bookingFormat} buyerFormat={mainFormat} />;
      default:
        return null;
    }
  };

  const RenderSignatureButton = (title, index, record) => {
    const signatureData = record[`signature_${index + 1}_data`];
    if (buyerRecord[index]) {
      return (
        <div key={index} className="d-flex justify-content-between align-items-center w-100 mb-1">
          <div>
            <Button
              variant="light"
              className="text-uppercase"
              style={{ backgroundColor: "#ddd" }}
              disabled={signatureData}
              onClick={() => {
                if (setShowAction(permission.update_signature, bookingData.booking_status)) {
                  openSignature(record.form_title, record.booking_form_id, title, index + 1);
                }
              }}
            >
              <FontAwesomeIcon icon={faPenNib} size="lg" className="me-2" />
              {title}
            </Button>

            {signatureData && (
              <span className="text-uppercase">
                <FontAwesomeIcon icon={faCircleCheck} size="lg" className="ms-2" /> Signed on {moment(new Date(signatureData.date_time_create)).format("ll")}
              </span>
            )}
          </div>

          {setShowAction(permission.update_signature, bookingData.booking_status) && signatureData && <FontAwesomeIcon icon={faCircleX} size="lg" className="cursor-pointer" onClick={() => sendDeleteSignature(signatureData.booking_signature_id)} />}
        </div>
      );
    }
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  // USEEFFECT FUNCTION ----------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getBooking(updateState);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, [booking_id, init.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      init.current = false;
      setLoading(false);
    }
  }, [booking_id]);

  useEffect(() => {
    if (booking_id === "" || deal_id === "") {
      logoutUser();
    }
  }, [booking_id, deal_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid style={{ padding: 0 }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="booking-view">
          <section className="section-header">
            <Row>
              <Col xxl={12} className={isApproval ? "pt-2 px-5" : "py-3 px-5"}>
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="op-text-bigger fs-5 me-2 text-uppercase">{buyerRecord[0].buyer_name}</div>
                    </div>

                    <Stack className="mt-1" direction="horizontal" gap={3}>
                      <div className="d-flex align-items-center text-uppercase">
                        <FontAwesomeIcon icon={faHouseBuilding} className="me-2" /> {bookingData.project_name}
                      </div>

                      <div className="d-flex align-items-center text-uppercase">
                        <FontAwesomeIcon icon={faHouseBuilding} className="me-2" /> {bookingData.phase_name}
                      </div>

                      <div className="d-flex align-items-center text-uppercase">
                        <FontAwesomeIcon icon={faHouseChimneyWindow} className="me-2" /> {bookingData.unit_title} {bookingData.unit_type}
                      </div>

                      <div className="d-flex align-items-center text-uppercase">
                        <FontAwesomeIcon icon={faHouse} className="me-2" /> {bookingData.unit_number}
                      </div>
                    </Stack>
                  </div>

                  {imgError ? (
                    <div className="avatar ms-auto">
                      <div className="avatar-img op-primary-color" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                        <div className="avatar-txt text-uppercase fs-5 text-light">{bookingData.user_name.split("")[0]}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="ms-auto">
                      <img className="rounded-circle" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${bookingData.user_id}.png`} width={35} height={35} onError={setCheckImg} />
                    </div>
                  )}

                  <div className="h-100 me-3" style={{ lineHeight: 0 }}>
                    <span className="op-text-bigger fw-bold">{bookingData.user_name}</span>
                  </div>

                  <div className="d-flex">
                    {bookingData.booking_status !== "spa_sold" && bookingData.booking_status !== "cancelled" && Number(session.booking_disable_status_changes) === 0 && (
                      <Button
                        variant="success"
                        className="me-2 text-uppercase"
                        onClick={() => {
                          if (Number(session.role_id) === 1) {
                            Swal.fire({
                              icon: "question",
                              text: "Would you like to proceed with marking this unit as sold?",
                              showCancelButton: true,
                              confirmButtonText: "Approve",
                              cancelButtonText: "Reject",
                              cancelButtonColor: "#d33",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                if (isApproval && approvalData.request_action !== "spa_sold") {
                                  Swal.fire({
                                    icon: "warning",
                                    text: "Unable to mark the booking as sold at the moment due to an existing pending request approval",
                                  });
                                } else {
                                  sendUnitSold();
                                }
                              }
                            });
                          } else {
                            Swal.fire({
                              icon: "question",
                              text: "Would you like to notify admin to marking this unit as sold?",
                              showCancelButton: true,
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                              cancelButtonColor: "#d33",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = `Request - ${bookingData.project_name}`;
                                const remark = `Sold Request for ${bookingData.phase_name} - ${bookingData.unit_number} Pending Approval (${setCurrency(bookingData.nett_price)})`;
                                sendApprovalRequest(title, remark, "spa_sold");
                              }
                            });
                          }
                        }}
                      >
                        {Number(session.role_id) === 1 ? "Mark as Sold" : "Request for Sold"}
                      </Button>
                    )}

                    {bookingData.booking_status !== "cancelled" && Number(session.booking_disable_status_changes) === 0 && (
                      <Button
                        variant="danger"
                        className="me-2 text-uppercase"
                        onClick={() => {
                          if (Number(session.role_id) === 1) {
                            if (isApproval && approvalData.request_action !== "cancelled") {
                              iziToast.warning({
                                title: "Caution",
                                message: "Unable to cancel the booking at the moment due to an existing pending request approval",
                                timeout: 10000,
                              });
                            } else {
                              setIsCancelBooking(true);
                            }
                          } else {
                            Swal.fire({
                              icon: "question",
                              text: "Would you like to notify admin to cancelling this booking?",
                              showCancelButton: true,
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                              cancelButtonColor: "#d33",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = `Request - ${bookingData.project_name}`;
                                const remark = `Cancel Booking Request for ${bookingData.phase_name} - ${bookingData.unit_number} Pending Approval (${setCurrency(bookingData.nett_price)})`;
                                sendApprovalRequest(title, remark, "cancelled");
                              }
                            });
                          }
                        }}
                      >
                        {Number(session.role_id) === 1 ? "Cancel Booking" : "Request for Cancellation"}
                      </Button>
                    )}

                    {bookingData.booking_status === "spa_sold" && (
                      <Button className="me-2 text-uppercase" variant="success" disabled>
                        Sold
                      </Button>
                    )}

                    {bookingData.booking_status === "cancelled" && (
                      <Button className="me-2 text-uppercase" variant="danger" disabled>
                        Cancelled
                      </Button>
                    )}

                    {isApproval && Number(session.booking_disable_status_changes) === 0 && Number(session.role_id) === 1 && (
                      <Button
                        variant="danger"
                        className="me-2 text-uppercase"
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            text: "Are you sure you want to reject the current user request?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            cancelButtonColor: "#d33",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              sendRejectRequest();
                            }
                          });
                        }}
                      >
                        Reject Request
                      </Button>
                    )}

                    {isApproval && Number(session.booking_disable_status_changes) === 0 && Number(session.role_id) !== 1 && (
                      <Button
                        variant="danger"
                        className="me-2 text-uppercase"
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            text: "Are you sure you want to cancel the current request?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            cancelButtonColor: "#d33",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              sendCancelApproval();
                            }
                          });
                        }}
                      >
                        Cancel Request
                      </Button>
                    )}

                    <Button variant="light" className="shadow-sm border" onClick={() => setIsEditBookingDates(true)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                  </div>
                </Stack>
              </Col>
              <Col xxl={12} className="px-5">
                {isApproval && (
                  <div className="d-flex align-items-center mb-0 mt-2 mb-1">
                    <div className="me-1">
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#ffc107" }} />
                    </div>

                    <p className="m-0" style={{ fontSize: 10, lineHeight: 0 }}>
                      {approvalData.request_remark}
                    </p>
                  </div>
                )}
                <div className="op steps w-100 m-0">
                  {stage.map((record, index) => (
                    <button className={`link step ${index <= activeStage ? "active" : ""} text-uppercase `} key={index} onClick={() => sendNextStage(record, index)}>
                      {index <= activeStage && <FontAwesomeIcon className="me-3" icon={faCircleCheck} style={{ color: "#fff" }} size="xl" />}
                      <div className="content">
                        <div className="title text-start text-uppercase">{record.title}</div>
                        {index <= activeStage && record.date !== "0000-00-00 00:00:00" && <div className="description d-flex text-light text-uppercase">{moment(new Date(record.date)).format("ll")}</div>}
                      </div>
                    </button>
                  ))}
                </div>
              </Col>
            </Row>
          </section>
          <section className="section-content">
            {/* style={{ width: [372].includes(Number(session.company_id)) ? "23%" : "17%" }} */}
            <section className="page-start" style={{ width: "17%" }}>
              <div className="h-100 overflow-auto op-scrollbar" style={{ width: "100%" }}>
                <Nav variant="pills" className="flex-column sidebar-tab bg-light py-4 px-2 w-100" defaultActiveKey="booking" onSelect={onchangeActionTabs}>
                  <Nav.Item className="mb-2">
                    <Nav.Link eventKey="booking">
                      <div className="d-flex align-items-center py-1">
                        <span className="me-2 text-center" style={{ width: 25 }}>
                          <FontAwesomeIcon icon={faHouseCircleCheck} size="xl" className="me-2" />
                        </span>
                        <span>Booking Information</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="mb-2">
                    <Nav.Link eventKey="buyer">
                      <div className="d-flex align-items-center py-1">
                        <span className="me-2 text-center" style={{ width: 25 }}>
                          <FontAwesomeIcon icon={faUser} size="xl" className="me-2" />
                        </span>
                        <span>Buyer Information</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  {setShowSection([modules.is_spa_info, modules.is_solicitor_info, modules.is_financier_info]) && (
                    <Nav.Item className="mb-2">
                      <Nav.Link eventKey="spa">
                        <div className="d-flex align-items-center py-1">
                          <span className="me-2 text-center" style={{ width: 25 }}>
                            <FontAwesomeIcon icon={faHandshake} size="xl" className="me-2" />
                          </span>
                          <span>SPA information</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {setShowSection([modules.is_document]) && (
                    <Nav.Item className="mb-2">
                      <Nav.Link eventKey="documents">
                        <div className="d-flex align-items-center py-1">
                          <span className="me-2 text-center" style={{ width: 25 }}>
                            <FontAwesomeIcon icon={faFolder} size="xl" className="me-2" />
                          </span>
                          <span>Documents</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item className="mb-2">
                    <Nav.Link eventKey="log">
                      <div className="d-flex align-items-center py-1">
                        <span className="me-2 text-center" style={{ width: 25 }}>
                          <FontAwesomeIcon icon={faBook} size="xl" className="me-2" />
                        </span>
                        <span>Audit History</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  {/* {[372].includes(Number(session.company_id)) && (
                    <Nav.Item className="mb-2">
                      <Card className="shadow-sm">
                        <Card.Img variant="top" src={MaybankBanner} />
                        <Card.Body>
                          <Card.Title as="h6" className="fw-bold">
                            Maybank Home<sup>2</sup>u
                          </Card.Title>
                          <p>Finance your dream home with Maybank without going to the bank</p>
                          <ListGroup className="border-0">
                            <ListGroup.Item className="border-0 p-0 d-flex mb-2">
                              <FontAwesomeIcon icon={faCircleStar} size="xl" className="me-2" color="#FF9900" /> <span> Easy online application process</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="border-0 p-0 d-flex mb-2">
                              <FontAwesomeIcon icon={faCircleStar} size="xl" className="me-2" color="#FF9900" /> <span> Guided application with our dedicated Maybank consultant</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="border-0 p-0 d-flex mb-2">
                              <FontAwesomeIcon icon={faCircleStar} size="xl" className="me-2" color="#FF9900" /> <span> Track your application status via Maybank2u</span>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between bg-light border-0">
                          <Button variant="link" className="text-decoration-none" onClick={() => window.open("https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/services/digital_banking/mae-property.page")}>
                            Read More
                          </Button>
                          <Button variant="" className="op-primary-color text-light" onClick={() => navigate(`/maybank-financing/${booking_id}/${deal_id}`)}>
                            Apply Now
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Nav.Item>
                  )} */}
                </Nav>
              </div>
            </section>
            <section className="page-end">
              <Container fluid className="py-4">
                {RenderActionTab(activeTab)}
              </Container>
            </section>
          </section>
        </div>
      )}

      <Modal show={isEditBookingInformation} onHide={setIsEditBookingInformation} backdrop="static">
        <EditBookingInformation
          bumiputera={buyerRecord.length > 0 ? buyerRecord[0].buyer_bumiputera : ""}
          bookingData={bookingData}
          bookingFormat={bookingFormat}
          financingOptions={financingOptions}
          firstTimeBuyerOptions={firstTimeBuyerOptions}
          bumiputeraOptions={bumiputeraOptions}
          modules={modules}
          onDone={() => {
            const currentUpdate = { ...updateState };
            currentUpdate.booking = true;
            currentUpdate.buyer = true;
            getBooking(currentUpdate);
            setIsEditBookingInformation(false);
          }}
        />
      </Modal>

      <Modal show={isEditPayment} onHide={setIsEditPayment} backdrop="static">
        <EditPaymentDetails
          bumiputera={buyerRecord.length > 0 ? buyerRecord[0].buyer_bumiputera : ""}
          bookingData={bookingData}
          bookingFormat={bookingFormat}
          paymentMethodOptions={paymentMethodOptions}
          modules={modules}
          onDone={() => {
            const currentUpdate = { ...updateState };
            currentUpdate.booking = true;
            getBooking(currentUpdate);
            setIsEditPayment(false);
          }}
        />
      </Modal>

      <Modal show={isAddInfo} onHide={setIsAddInfo} backdrop="static">
        <Formik onSubmit={sendCustomField} initialValues={addInfo} validationSchema={customFieldSchema}>
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <Modal.Header closeButton>
                <Modal.Title className="text-uppercase" as={"h6"}>
                  Additional Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-4">
                {customFieldData.length > 0 &&
                  customFieldData.map((record, index) => {
                    return (
                      <FormCustomField
                        id={record.cf_id}
                        name={`custom_field[${index}].fd_values`}
                        label={setFieldLabel(record.cf_label, record.compulsory_column)}
                        error={errors.custom_field && errors.custom_field[index] && errors.custom_field[index].fd_values && touched.custom_field && touched.custom_field[index] && touched.custom_field[index].fd_values ? errors.custom_field[index].fd_values : ""}
                        options={record.cf_value}
                        type={record.ctf_title}
                        fd_values={record.fd_values}
                        placeholder=""
                        firstOptions={false}
                        isClearable={true}
                        value={values.custom_field[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.custom_field];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info.value, cf_label: record.cf_label, required: Number(record.compulsory_column) };
                          } else if (record.ctf_title === "Radio") {
                            updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info.target.checked ? info.target.value : "", cf_label: record.cf_label, required: Number(record.compulsory_column) };
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info, cf_label: record.cf_label, required: Number(record.compulsory_column) };
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            const currentValues = values.custom_field[index].fd_values;
                            const updatedValues = currentValues === "" ? [] : [...currentValues.split(",")];
                            if (checked) {
                              updatedValues.push(value);
                            } else {
                              const indexToRemove = updatedValues.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedValues.splice(indexToRemove, 1);
                              }
                            }

                            updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: updatedValues.join("|"), cf_label: record.cf_label, required: Number(record.compulsory_column) };
                          } else {
                            updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info.target.value, cf_label: record.cf_label, required: Number(record.compulsory_column) };
                          }
                          setFieldValue("custom_field", updatedCustomFields);
                        }}
                      />
                    );
                  })}
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEditBuyer} onHide={setIsEditBuyer} size="xl" backdrop="static">
        <EditBuyer
          record={buyerData}
          buyer_no={buyerNo}
          bookingData={bookingData}
          mainFormat={mainFormat}
          jointFormat={jointFormat}
          salutationOptions={salutationOptions}
          prefixOptions={prefixOptions}
          raceOptions={raceOptions}
          identityTypeOptions={identityTypeOptions}
          occupationOptions={occupationOptions}
          genderOptions={genderOptions}
          nationalityOptions={nationalityOptions}
          maritalStatusOptions={maritalStatusOptions}
          relationOptions={relationOptions}
          country={country}
          state={state}
          city={city}
          onDone={() => {
            getBooking();
            setIsEditBuyer(false);
          }}
        />
      </Modal>

      <Modal show={isCreateAddBuyer} onHide={setIsCreateAddBuyer} size="xl" backdrop="static">
        <CreateAdditionalBuyer
          bookingData={bookingData}
          jointFormat={jointFormat}
          salutationOptions={salutationOptions}
          prefixOptions={prefixOptions}
          raceOptions={raceOptions}
          identityTypeOptions={identityTypeOptions}
          occupationOptions={occupationOptions}
          genderOptions={genderOptions}
          nationalityOptions={nationalityOptions}
          maritalStatusOptions={maritalStatusOptions}
          relationOptions={relationOptions}
          buyerRecord={buyerRecord}
          country={country}
          state={state}
          city={city}
          onDone={(result) => {
            setIsCreateAddBuyer(false);
            if (result) {
              const currentUpdate = { ...updateState };
              currentUpdate.buyer = true;
              getBooking(currentUpdate);
            }
          }}
        />
      </Modal>

      <Modal show={isEditSpa} onHide={setIsEditSpa} backdrop="static">
        <EditSpaInformation
          bookingData={bookingData}
          spaRecord={spaRecord}
          onDone={() => {
            const currentUpdate = { ...updateState };
            currentUpdate.spa = true;
            currentUpdate.stage = true;
            getBooking(currentUpdate);
            setIsEditSpa(false);
          }}
        />
      </Modal>

      <Modal show={isEditSolicitor} onHide={setIsEditSolicitor} backdrop="static">
        <EditSolicitorInformation
          bookingData={bookingData}
          spaRecord={spaRecord}
          onDone={() => {
            const currentUpdate = { ...updateState };
            currentUpdate.spa = true;
            getBooking(currentUpdate);
            setIsEditSolicitor(false);
          }}
        />
      </Modal>

      <Modal show={isEditFinancier} onHide={setIsEditFinancier} backdrop="static">
        <EditFinancierInformation
          bookingData={bookingData}
          spaRecord={spaRecord}
          onDone={() => {
            const currentUpdate = { ...updateState };
            currentUpdate.spa = true;
            getBooking(currentUpdate);
            setIsEditFinancier(false);
          }}
        />
      </Modal>

      <Modal show={isCancelBooking} onHide={setIsCancelBooking} backdrop="static">
        <Formik onSubmit={sendUnitCancel} initialValues={{ remark: "", lostReasonID: "" }} validationSchema={cancelBookingSchema}>
          {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <Modal.Header closeButton>
                <Modal.Title className="text-uppercase" as={"h6"}>
                  Cancel Booking
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-4">
                <Form.Group className="mb-3">
                  <Form.Label>Cancel Reason</Form.Label>
                  <FormSelect options={lostReason} isClearable={true} onChange={(info) => setFieldValue("lostReasonID", info.value)} />
                  {errors.lostReasonID && touched.lostReasonID && <div className="op-error-message">{errors.lostReasonID}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control as="textarea" value={values.remark} rows={3} onChange={handleChange("remark")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isSignature} onHide={setIsSignature} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase" as={"h6"}>
            Signature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <SignaturePad ref={signCanvas} canvasProps={{ className: "border rounded w-100", style: { height: 300 } }} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-uppercase" variant="" style={{ background: "#ddd" }} onClick={() => signCanvas.current.clear()}>
            Clear
          </Button>
          <Button variant="" className="op-primary-color text-light text-uppercase" onClick={() => sendSignature()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUploadDocuments} onHide={setIsUploadDocuments} size="lg" backdrop="static">
        <BookingUploadDocuments
          projectid={bookingData.rea_project_id}
          phaseid={bookingData.rea_phase_id}
          bookingid={booking_id}
          onSubmit={() => {
            const newUpdateState = { ...updateState };
            newUpdateState.documents = true;
            getBooking(newUpdateState);
            setIsUploadDocuments(false);
          }}
        />
      </Modal>

      <Modal show={isDownloadDocuments} onHide={setIsDownloadDocuments} size="lg" backdrop="static">
        <BookingDownloadDocuments bookingid={booking_id} buyerRecord={buyerRecord} bookingData={bookingData} documents={documentList} uploadedDocuments={documents} />
      </Modal>

      <Modal show={isEditBookingDates} onHide={setIsEditBookingDates} backdrop="static">
        <Formik onSubmit={sendEditBookingDates} initialValues={bookingDatesData} validationSchema={bookingDatesSchema}>
          {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <Modal.Header closeButton>
                <Modal.Title className="text-uppercase" as={"h6"}>
                  Update Booking Dates
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-4">
                {["booked", "booking_confirmed", "spa_signed", "spa_stamped", "spa_sold", "cancelled"].includes(bookingData.booking_status) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Reserved Date</Form.Label>
                    <Form.Control type="date" value={moment(new Date(values.booking_reserved_date)).format("YYYY-MM-DD")} onChange={handleChange("booking_reserved_date")} />
                    {errors.booking_reserved_date && touched.booking_reserved_date && <div className="op-error-message">{errors.booking_reserved_date}</div>}
                  </Form.Group>
                )}

                {["booking_confirmed", "spa_signed", "spa_stamped", "spa_sold", "cancelled"].includes(bookingData.booking_status) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Booked Date</Form.Label>
                    <Form.Control type="date" value={moment(new Date(values.booking_booked_date)).format("YYYY-MM-DD")} onChange={handleChange("booking_booked_date")} />
                    {errors.booking_booked_date && touched.booking_booked_date && <div className="op-error-message">{errors.booking_booked_date}</div>}
                  </Form.Group>
                )}

                {["spa_sold"].includes(bookingData.booking_status) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Spa Sold Date</Form.Label>
                    <Form.Control type="date" value={moment(new Date(values.booking_spa_sold_date)).format("YYYY-MM-DD")} onChange={handleChange("booking_spa_sold_date")} />
                    {errors.booking_spa_sold_date && touched.booking_spa_sold_date && <div className="op-error-message">{errors.booking_spa_sold_date}</div>}
                  </Form.Group>
                )}

                {["cancelled"].includes(bookingData.booking_status) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Cancelled Date</Form.Label>
                    <Form.Control type="date" value={moment(new Date(values.booking_cancelled_date)).format("YYYY-MM-DD")} onChange={handleChange("booking_cancelled_date")} />
                    {errors.booking_cancelled_date && touched.booking_cancelled_date && <div className="op-error-message">{errors.booking_cancelled_date}</div>}
                  </Form.Group>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Viewer visible={isPaymentReceipt} onClose={() => setIsPaymentReceipt(false)} images={[{ src: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/payment_slip/${bookingData.payment_slip}`, alt: "" }]} drag={false} />
      <Viewer visible={isId} onClose={() => setIsId(false)} images={[{ src: id, alt: "" }]} drag={false} />
    </Container>
  );
};

export default BookingDetails;
