import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas, FormLabel } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const Blueprint2 = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [blueprint2List, setBlueprint2List] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [isAddBlueprint, setisAddBlueprint] = useState(false);

    const [wabalist, setWabaList] = useState([]);
    const [filter, setFilter] = useState([]);
    const [customField, setCustomField] = useState([]);
    const [selectedQ5, setSelectedQ5] = useState([]);


    const aiProviderOptions = [
        { value: 'openai', label: 'OpenAI' },
        { value: 'google', label: 'Google' },
    ];

    // Validation Schema
    const validationSchema = yup.object().shape({
        main_prompt: yup
            .string()
            .required("Main prompt is required"),
        ai_model: yup.string().required("AI Model is required"),
    });


    // GET FUNCTION --------------------------------------------------------
    const getBlueprint2 = async () => {
        try {
            const res = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, {
                params: {
                    task: "getBlueprint2Settings",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            let data = res.data;

            if (data.record) {
                setBlueprint2List(data.record);
                setLoading(false);
            } else {
                setBlueprint2List([]);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getWabaList = async () => {
        try {
            const res = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "getWABAInteractive",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            let data = res.data;

            if (data.record) {
                setWabaList(data.record);
                setLoading(false);
            } else {
                setWabaList([]);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getCustomField = async () => {
        try {
            let originalData = [];

            const resDeal = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "deal"
                }
            });

            let data = resDeal.data;

            if (data.record) {
                originalData = data.record;
            }

            const resPerson = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "person"
                }
            });

            let data2 = resPerson.data;

            if (data2.record) {
                originalData = originalData.concat(data2.record);
            }

            setCustomField(originalData);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.data.date_time_create;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Main Prompt",
            field: "",
            width: 400,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.main_prompt) {
                        return (
                            <Form.Group className="col-sm-12">
                                <Form.Control
                                    style={{ resize: "none", color: 'black', backgroundColor: '#ffff', border: "none" }}
                                    as="textarea"
                                    rows={6}
                                    disabled={true}
                                    value={params.data.main_prompt}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        { headerName: "Date Time Create", field: "", width: 150, cellRenderer: setDateFormat },
        { headerName: "Vector DB Index", field: "vector_db_index", width: 150 },
        { headerName: "Vector Namespace", field: "vector_namespace", width: 250 },
        {
            headerName: "AI Factsheet",
            field: "ai_factsheet",
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.ai_factsheet) {
                        return (
                            <Form.Group className="col-sm-12">
                                <Form.Control
                                    style={{ resize: "none", color: 'black', backgroundColor: '#ffff', border: "none" }}
                                    as="textarea"
                                    rows={6}
                                    disabled={true}
                                    value={params.data.ai_factsheet}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        { headerName: "Intents", field: "intents", width: 300 },
        { headerName: "AI Model", field: "ai_model", width: 150 },
        { headerName: "Meetup Link", field: "meetup_link", width: 250 },
        {
            headerName: "QA Extraction Prompt",
            field: "qa_extraction_prompt",
            width: 300,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.qa_extraction_prompt) {
                        return (
                            <Form.Group className="col-sm-12">
                                <Form.Control
                                    style={{ resize: "none", color: 'black', backgroundColor: '#ffff', border: "none" }}
                                    as="textarea"
                                    rows={6}
                                    disabled={true}
                                    value={params.data.qa_extraction_prompt}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        { 
            headerName: "QA Extraction Model", 
            field: "qa_extraction_model", 
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        { headerName: "AI Provider", field: "ai_provider", width: 150 },
        {
            headerName: "Validation Instruction",
            field: "validation_instruction",
            width: 300,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.validation_instruction) {
                        return (
                            <Form.Group className="col-sm-12">
                                <Form.Control
                                    style={{ resize: "none", color: 'black', backgroundColor: '#ffff', border: "none" }}
                                    as="textarea"
                                    rows={6}
                                    disabled={true}
                                    value={params.data.validation_instruction}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        {
            headerName: "Question 1",
            field: "qa_q1_question",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: "Question 2",
            field: "qa_q2_question",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: "Question 3",
            field: "qa_q3_question",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: "Question 4",
            field: "qa_q4_question",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: "Question 5",
            field: "qa_q5_question",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        const extractBodyText = (id) => {
            const item = wabalist.find(opt => opt.whatsapp_interactive_id === id);
            return item ? item.body_text : "";
        };

        const initialData = {
            ...params,
            qa_q1_body_text: extractBodyText(params.qa_q1_whatsapp_interactive_id),
            qa_q2_body_text: extractBodyText(params.qa_q2_whatsapp_interactive_id),
            qa_q3_body_text: extractBodyText(params.qa_q3_whatsapp_interactive_id),
            qa_q4_body_text: extractBodyText(params.qa_q4_whatsapp_interactive_id),
            qa_q5_body_text: extractBodyText(params.qa_q5_whatsapp_interactive_id),
            qa_q1_whatsapp_interactive_id: (params.qa_q1_whatsapp_interactive_id == '0' || params.qa_q1_whatsapp_interactive_id === null || params.qa_q1_whatsapp_interactive_id === undefined) ? "" : params.qa_q1_whatsapp_interactive_id,
            qa_q2_whatsapp_interactive_id: (params.qa_q2_whatsapp_interactive_id == '0' || params.qa_q2_whatsapp_interactive_id === null || params.qa_q2_whatsapp_interactive_id === undefined) ? "" : params.qa_q2_whatsapp_interactive_id,
            qa_q3_whatsapp_interactive_id: (params.qa_q3_whatsapp_interactive_id == '0' || params.qa_q3_whatsapp_interactive_id === null || params.qa_q3_whatsapp_interactive_id === undefined) ? "" : params.qa_q3_whatsapp_interactive_id,
            qa_q4_whatsapp_interactive_id: (params.qa_q4_whatsapp_interactive_id == '0' || params.qa_q4_whatsapp_interactive_id === null || params.qa_q4_whatsapp_interactive_id === undefined) ? "" : params.qa_q4_whatsapp_interactive_id,
            qa_q5_whatsapp_interactive_id: (params.qa_q5_whatsapp_interactive_id == '0' || params.qa_q5_whatsapp_interactive_id === null || params.qa_q5_whatsapp_interactive_id === undefined) ? "" : params.qa_q5_whatsapp_interactive_id,
            qa_qa1_cfid: (params.qa_qa1_cfid == '0' || params.qa_qa1_cfid === null || params.qa_qa1_cfid === undefined) ? "" : params.qa_qa1_cfid,
            qa_qa2_cfid: (params.qa_qa2_cfid == '0' || params.qa_qa2_cfid === null || params.qa_qa2_cfid === undefined) ? "" : params.qa_qa2_cfid,
            qa_qa3_cfid: (params.qa_qa3_cfid == '0' || params.qa_qa3_cfid === null || params.qa_qa3_cfid === undefined) ? "" : params.qa_qa3_cfid,
            qa_qa4_cfid: (params.qa_qa4_cfid == '0' || params.qa_qa4_cfid === null || params.qa_qa4_cfid === undefined) ? "" : params.qa_qa4_cfid,
            qa_qa5_cfid: (params.qa_qa5_cfid == '0' || params.qa_qa5_cfid === null || params.qa_qa5_cfid === undefined) ? "" : params.qa_qa5_cfid,
        };

        setCurrentData(initialData)
        // setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            const formData = new FormData();

            formData.append("task", "updateBlueprint2Settings");
            formData.append("utoken", session.user_ac_token);
            formData.append("ctoken", session.company_token);
            formData.append("main_prompt", values.main_prompt);
            formData.append("vector_db_index", values.vector_db_index);
            formData.append("vector_namespace", values.vector_namespace);
            formData.append("ai_factsheet", values.ai_factsheet);
            formData.append("ai_model", values.ai_model);
            formData.append("qa_extraction_prompt", values.qa_extraction_prompt);
            formData.append("qa_extraction_model", values.qa_extraction_model);
            formData.append("qa_q1_question", values.qa_q1_question);
            formData.append("qa_qa1_cfid", values.qa_qa1_cfid);
            formData.append("qa_q1_question_id", values.qa_q1_question_id);
            formData.append("qa_q1_whatsapp_interactive_id", values.qa_q1_whatsapp_interactive_id);
            formData.append("qa_q2_question", values.qa_q2_question);
            formData.append("qa_qa2_cfid", values.qa_qa2_cfid);
            formData.append("qa_q2_question_id", values.qa_q2_question_id);
            formData.append("qa_q2_whatsapp_interactive_id", values.qa_q2_whatsapp_interactive_id);
            formData.append("qa_q3_question", values.qa_q3_question);
            formData.append("qa_qa3_cfid", values.qa_qa3_cfid);
            formData.append("qa_q3_question_id", values.qa_q3_question_id);
            formData.append("qa_q3_whatsapp_interactive_id", values.qa_q3_whatsapp_interactive_id);
            formData.append("qa_q4_question", values.qa_q4_question);
            formData.append("qa_qa4_cfid", values.qa_qa4_cfid);
            formData.append("qa_q4_question_id", values.qa_q4_question_id);
            formData.append("qa_q4_whatsapp_interactive_id", values.qa_q4_whatsapp_interactive_id);
            formData.append("qa_q5_question", values.qa_q5_question);
            formData.append("qa_qa5_cfid", values.qa_qa5_cfid);
            formData.append("qa_q5_question_id", values.qa_q5_question_id);
            formData.append("qa_q5_whatsapp_interactive_id", values.qa_q5_whatsapp_interactive_id);
            formData.append("blueprint2_id", values.blueprint2_id);
            formData.append("ai_provider", values.ai_provider);
            formData.append("validation_instruction", values.validation_instruction);
            formData.append("meetup_link", values.meetup_link);

            const response = await axios.post(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, formData);
            const data = response.data;

            if (data.status == '0') {
                setShowModal(false);
                getBlueprint2();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.blueprint2_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, {
                params: {
                    task: "deleteBlueprint2Settings",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    blueprint2_id: params.blueprint2_id,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getBlueprint2();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const formData = new FormData();

            formData.append("task", "setBlueprint2Settings");
            formData.append("utoken", session.user_ac_token);
            formData.append("ctoken", session.company_token);
            formData.append("main_prompt", values.main_prompt);
            formData.append("vector_db_index", values.vector_db_index);
            formData.append("vector_namespace", values.vector_namespace);
            formData.append("ai_factsheet", values.ai_factsheet);
            formData.append("ai_model", values.ai_model);
            formData.append("qa_extraction_prompt", values.qa_extraction_prompt);
            formData.append("qa_extraction_model", values.qa_extraction_model);
            formData.append("meetup_link", values.meetup_link);
            formData.append("qa_q1_question", values.qa_q1_question || "");
            formData.append("qa_qa1_cfid", values.qa_qa1_cfid || "");
            formData.append("qa_q1_question_id", values.qa_q1_question_id || "");
            formData.append("qa_q1_whatsapp_interactive_id", values.qa_q1_whatsapp_interactive_id || "");
            formData.append("qa_q2_question", values.qa_q2_question || "");
            formData.append("qa_qa2_cfid", values.qa_qa2_cfid || "");
            formData.append("qa_q2_question_id", values.qa_q2_question_id || "");
            formData.append("qa_q2_whatsapp_interactive_id", values.qa_q2_whatsapp_interactive_id || "");
            formData.append("qa_q3_question", values.qa_q3_question || "");
            formData.append("qa_qa3_cfid", values.qa_qa3_cfid || "");
            formData.append("qa_q3_question_id", values.qa_q3_question_id || "");
            formData.append("qa_q3_whatsapp_interactive_id", values.qa_q3_whatsapp_interactive_id || "");
            formData.append("qa_q4_question", values.qa_q4_question || "");
            formData.append("qa_qa4_cfid", values.qa_qa4_cfid || "");
            formData.append("qa_q4_question_id", values.qa_q4_question_id || "");
            formData.append("qa_q4_whatsapp_interactive_id", values.qa_q4_whatsapp_interactive_id || "");
            formData.append("qa_q5_question", values.qa_q5_question || "");
            formData.append("qa_qa5_cfid", values.qa_qa5_cfid || "");
            formData.append("qa_q5_question_id", values.qa_q5_question_id);
            formData.append("qa_q5_whatsapp_interactive_id", values.qa_q5_whatsapp_interactive_id || "");
            formData.append("ai_provider", values.ai_provider || "");
            formData.append("validation_instruction", values.validation_instruction || "");

            const response = await axios.post(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, formData);
            const data = response.data;

            if (response.data.status == '0') {
                setisAddBlueprint(false);
                getBlueprint2();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // ONCHANGE -----------------------------------------------------

    const onchangeWaba = (option) => {
        setFilter((prevState) => ({ ...prevState, option: option }));
    };


    // USEEFFECT ----------------------------------------------------

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getBlueprint2();
                await getWabaList();
                await getCustomField();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };

        if (!init.current) {
            init.current = true;
            fetchData();
        }
    }, []);

    const toggleAddBlueprintModal = () => {
        setisAddBlueprint(prevState => !prevState);
    };

    const handleRoleChange = (selectedOption) => {
        setSelectedQ5(selectedOption);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddBlueprintModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Blueprint
                    </button>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : blueprint2List} rowHeight={150} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Add */}

                    <Modal show={isAddBlueprint} onHide={toggleAddBlueprintModal}>
                        <Formik
                            initialValues={{
                                main_prompt: "",
                                vector_db_index: "",
                                vector_namespace: "",
                                ai_factsheet: "",
                                ai_model: "",
                                qa_extraction_prompt: "",
                                qa_extraction_model: "",
                                qa_q1_question_id: "",
                                qa_q1_question: "",
                                qa_q1_whatsapp_interactive_id: "",
                                qa_qa1_cfid: "",
                                qa_q2_question_id: "",
                                qa_q2_question: "",
                                qa_q2_whatsapp_interactive_id: "",
                                qa_qa2_cfid: "",
                                qa_q3_question_id: "",
                                qa_q3_question: "",
                                qa_q3_whatsapp_interactive_id: "",
                                qa_qa3_cfid: "",
                                qa_q4_question_id: "",
                                qa_q4_question: "",
                                qa_q4_whatsapp_interactive_id: "",
                                qa_qa4_cfid: "",
                                qa_q5_question_id: "",
                                qa_q5_question: "",
                                qa_q5_whatsapp_interactive_id: "",
                                qa_qa5_cfid: "",
                                ai_provider: "",
                                validation_instruction: "",
                                meetup_link: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Blueprint</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Main Prompt
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="main_prompt"
                                                value={values.main_prompt}
                                                onChange={handleChange}
                                                isInvalid={touched.main_prompt && !!errors.main_prompt}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.main_prompt}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Vector DB Index
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_db_index"
                                                value={values.vector_db_index}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Vector Namespace
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_namespace"
                                                value={values.vector_namespace}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                AI Factsheet
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="ai_factsheet"
                                                value={values.ai_factsheet}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                AI Model
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="ai_model"
                                                value={values.ai_model}
                                                onChange={handleChange}
                                                isInvalid={touched.ai_model && !!errors.ai_model}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ai_model}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                QA Extraction Prompt
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="qa_extraction_prompt"
                                                value={values.qa_extraction_prompt}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                QA Extraction Model
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="qa_extraction_model"
                                                value={values.qa_extraction_model}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                AI Provider
                                            </Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select AI Provider"
                                                    value={values.ai_provider}
                                                    options={aiProviderOptions}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('ai_provider', selectedOption.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Meetup Link
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="meetup_link"
                                                value={values.meetup_link}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Validation Instruction
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="validation_instruction"
                                                value={values.validation_instruction}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 1</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q1_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q1_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q1_question"
                                                        className="mb-3"
                                                        value={values.qa_q1_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({
                                                            value: item.whatsapp_interactive_id,
                                                            label: item.body_text,
                                                        }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_q1_whatsapp_interactive_id', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_qa1_cfid', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 2</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q2_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q2_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q2_question"
                                                        className="mb-3"
                                                        value={values.qa_q2_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_q2_whatsapp_interactive_id', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_qa2_cfid', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 3</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q3_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q3_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q3_question"
                                                        className="mb-3"
                                                        value={values.qa_q3_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_q3_whatsapp_interactive_id', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_qa3_cfid', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 4</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q4_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q4_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q4_question"
                                                        className="mb-3"
                                                        value={values.qa_q4_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_q4_whatsapp_interactive_id', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_qa4_cfid', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 5</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q5_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q5_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>


                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q5_question"
                                                        className="mb-3"
                                                        value={values.qa_q5_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_q5_whatsapp_interactive_id', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('qa_qa5_cfid', selectedOption ? selectedOption.value : '');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    {/* Update */}

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                main_prompt: currentData ? currentData.main_prompt : "",
                                vector_db_index: currentData ? currentData.vector_db_index : "",
                                vector_namespace: currentData ? currentData.vector_namespace : "",
                                meetup_link: currentData ? currentData.meetup_link : "",
                                ai_factsheet: currentData ? currentData.ai_factsheet : "",
                                ai_model: currentData ? currentData.ai_model : "",
                                qa_extraction_prompt: currentData ? currentData.qa_extraction_prompt : "",
                                qa_extraction_model: currentData ? currentData.qa_extraction_model : "",
                                qa_q1_question: currentData ? currentData.qa_q1_question : "",
                                qa_q2_question: currentData ? currentData.qa_q2_question : "",
                                qa_q3_question: currentData ? currentData.qa_q3_question : "",
                                qa_q4_question: currentData ? currentData.qa_q4_question : "",
                                qa_q5_question: currentData ? currentData.qa_q5_question : "",
                                qa_q1_whatsapp_interactive_id: currentData ? currentData.qa_q1_whatsapp_interactive_id : "",
                                qa_q2_whatsapp_interactive_id: currentData ? currentData.qa_q2_whatsapp_interactive_id : "",
                                qa_q3_whatsapp_interactive_id: currentData ? currentData.qa_q3_whatsapp_interactive_id : "",
                                qa_q4_whatsapp_interactive_id: currentData ? currentData.qa_q4_whatsapp_interactive_id : "",
                                qa_q5_whatsapp_interactive_id: currentData ? currentData.qa_q5_whatsapp_interactive_id : "",
                                qa_q1_question_id: currentData ? currentData.qa_q1_question_id : "",
                                qa_q2_question_id: currentData ? currentData.qa_q2_question_id : "",
                                qa_q3_question_id: currentData ? currentData.qa_q3_question_id : "",
                                qa_q4_question_id: currentData ? currentData.qa_q4_question_id : "",
                                qa_q5_question_id: currentData ? currentData.qa_q5_question_id : "",
                                qa_q1_body_text: currentData ? currentData.qa_q1_body_text : "",
                                qa_q2_body_text: currentData ? currentData.qa_q2_body_text : "",
                                qa_q3_body_text: currentData ? currentData.qa_q3_body_text : "",
                                qa_q4_body_text: currentData ? currentData.qa_q4_body_text : "",
                                qa_q5_body_text: currentData ? currentData.qa_q5_body_text : "",
                                qa_qa1_cfid: currentData ? currentData.qa_qa1_cfid : "",
                                qa_qa2_cfid: currentData ? currentData.qa_qa2_cfid : "",
                                qa_qa3_cfid: currentData ? currentData.qa_qa3_cfid : "",
                                qa_qa4_cfid: currentData ? currentData.qa_qa4_cfid : "",
                                qa_qa5_cfid: currentData ? currentData.qa_qa5_cfid : "",
                                blueprint2_id: currentData ? currentData.blueprint2_id : "",
                                ai_provider: currentData ? currentData.ai_provider : "",
                                validation_instruction: currentData ? currentData.validation_instruction : ""
                            }}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Main Prompt
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="main_prompt"
                                                value={values.main_prompt}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector DB Index</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_db_index"
                                                value={values.vector_db_index}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector Namespace</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_namespace"
                                                value={values.vector_namespace}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>AI Factsheet</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="ai_factsheet"
                                                value={values.ai_factsheet}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>AI Model</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="ai_model"
                                                value={values.ai_model}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>QA Extraction Prompt</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="qa_extraction_prompt"
                                                value={values.qa_extraction_prompt}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>QA Extraction Model</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="qa_extraction_model"
                                                value={values.qa_extraction_model}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                AI Provider
                                            </Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select AI Provider"
                                                    options={aiProviderOptions}
                                                    valueDefault={
                                                        values.ai_provider
                                                            ? {
                                                                label: aiProviderOptions.find(item => item.value === values.ai_provider)?.label || '',
                                                                value: values.ai_provider
                                                            }
                                                            : ""
                                                    }
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('ai_provider', selectedOption.value);
                                                    }}
                                                    isClearable={true}
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Meetup Link</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="meetup_link"
                                                value={values.meetup_link}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Validation Instruction
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="validation_instruction"
                                                value={values.validation_instruction}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 1</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q1_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q1_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q1_question"
                                                        className="mb-3"
                                                        value={values.qa_q1_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        valueDefault={
                                                            values.qa_q1_whatsapp_interactive_id
                                                                ? {
                                                                    label: wabalist.find(item => item.whatsapp_interactive_id === values.qa_q1_whatsapp_interactive_id)?.body_text || '',
                                                                    value: values.qa_q1_whatsapp_interactive_id
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_q1_whatsapp_interactive_id', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>


                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        valueDefault={
                                                            values.qa_qa1_cfid
                                                                ? {
                                                                    label: customField.find(item => item.cf_id === values.qa_qa1_cfid)?.cf_label || '',
                                                                    value: values.qa_qa1_cfid
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_qa1_cfid', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 2</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q2_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q2_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q2_question"
                                                        className="mb-3"
                                                        value={values.qa_q2_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        // valueDefault={values.qa_q2_whatsapp_interactive_id}
                                                        valueDefault={
                                                            values.qa_q2_whatsapp_interactive_id
                                                                ? {
                                                                    label: wabalist.find(item => item.whatsapp_interactive_id === values.qa_q2_whatsapp_interactive_id)?.body_text || '',
                                                                    value: values.qa_q2_whatsapp_interactive_id
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_q2_whatsapp_interactive_id', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custm Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        valueDefault={
                                                            values.qa_qa2_cfid
                                                                ? {
                                                                    label: customField.find(item => item.cf_id === values.qa_qa2_cfid)?.cf_label || '',
                                                                    value: values.qa_qa2_cfid
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_qa2_cfid', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 3</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q3_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q3_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q3_question"
                                                        className="mb-3"
                                                        value={values.qa_q3_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        // valueDefault={values.qa_q3_whatsapp_interactive_id}
                                                        valueDefault={
                                                            values.qa_q3_whatsapp_interactive_id
                                                                ? {
                                                                    label: wabalist.find(item => item.whatsapp_interactive_id === values.qa_q3_whatsapp_interactive_id)?.body_text || '',
                                                                    value: values.qa_q3_whatsapp_interactive_id
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_q3_whatsapp_interactive_id', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        valueDefault={
                                                            values.qa_qa3_cfid
                                                                ? {
                                                                    label: customField.find(item => item.cf_id === values.qa_qa3_cfid)?.cf_label || '',
                                                                    value: values.qa_qa3_cfid
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_qa3_cfid', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 4</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q4_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q4_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q4_question"
                                                        className="mb-3"
                                                        value={values.qa_q4_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        valueDefault={
                                                            values.qa_q4_whatsapp_interactive_id
                                                                ? {
                                                                    label: wabalist.find(item => item.whatsapp_interactive_id === values.qa_q4_whatsapp_interactive_id)?.body_text || '',
                                                                    value: values.qa_q4_whatsapp_interactive_id
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_q4_whatsapp_interactive_id', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        valueDefault={
                                                            values.qa_qa4_cfid
                                                                ? {
                                                                    label: customField.find(item => item.cf_id === values.qa_qa4_cfid)?.cf_label || '',
                                                                    value: values.qa_qa4_cfid
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_qa4_cfid', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>

                                        <section className="border pt-3 ps-3 pe-3 pb-1 rounded mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Question 5</Form.Label>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question ID
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q5_question_id"
                                                        className="mb-3"
                                                        value={values.qa_q5_question_id}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Question
                                                    </FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="qa_q5_question"
                                                        className="mb-3"
                                                        value={values.qa_q5_question}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Whatsapp Interactive
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={wabalist.map(item => ({ value: item.whatsapp_interactive_id, label: item.body_text }))}
                                                        valueDefault={
                                                            values.qa_q5_whatsapp_interactive_id
                                                                ? {
                                                                    label: wabalist.find(item => item.whatsapp_interactive_id === values.qa_q5_whatsapp_interactive_id)?.body_text || '',
                                                                    value: values.qa_q5_whatsapp_interactive_id
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_q5_whatsapp_interactive_id', e ? e.value : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <FormLabel>
                                                        Select Custom Field
                                                    </FormLabel>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={customField.map(item => ({ value: item.cf_id, label: `${item.cf_area} - ${item.cf_label}` }))}
                                                        valueDefault={
                                                            values.qa_qa5_cfid
                                                                ? {
                                                                    label: customField.find(item => item.cf_id === values.qa_qa5_cfid)?.cf_label || '',
                                                                    value: values.qa_qa5_cfid
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('qa_qa5_cfid', e ? e.value : "")
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </section>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            )}
        </Container>
    );
}

export default Blueprint2;
