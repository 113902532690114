import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, Modal, OverlayTrigger, Placeholder, Row, Spinner, Stack, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold, faCode, faExclamationTriangle, faImage, faItalic, faPencil, faRotateRight, faStrikethrough, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faImage as faImageSolid } from "@fortawesome/pro-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { FormSelect } from "../includes/FormCustom";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import * as formik from "formik";
import WSBackground from "../assets/ws_background.png";
import "../lib/scss/op-ws-campaign-settings.scss";
import moment from "moment";
import { immediateToast } from "izitoast-react";
import Swal from "sweetalert2/dist/sweetalert2";

const SettingsWsCampaignTemplate360 = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [gridRecord, setGridRecord] = useState([]);
  const [chatInstanceList, setChatInstanceList] = useState([]);
  const [chatInstanceData, setChatInstanceData] = useState({ label: "", value: "" });
  const [chatInstanceShow, setChatInstanceShow] = useState(false);
  const [isAddNewTemplate, setIsAddNewTemplate] = useState(false);
  const messageContentRef = useRef(null);
  const [templateTypeList] = useState([
    { label: "Standard (text only)", value: "text" },
    { label: "Media & Interactive", value: "media" },
  ]);

  const [formValues, setFormValues] = useState({
    template_name: "",
    template_type: "text",
    message_content: "",
    message_preview: "",
    media: "",
    chat_instance_id: "",
    params: 0,
    character_count: 0,
    warning: false,
    warning_message: [],
    section: 1,
    params_example: [],
    params_header_example: "",
  });

  const [imgError, setImgError] = useState(false);

  // GET FUNCTION ==============================================================

  const getChatInstance = async () => {
    try {
      const response = await axios.get(`ws/ws_ai_campaign.php`, {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          company_id: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordData = data.record.map((item) => ({ ...item, label: `${item.instance_title} - ${item.user_number}`, value: item.chat_instance_id }));
        setChatInstanceList(recordData);
        setChatInstanceData(recordData[0]);
        await getWsCampaignTemplateList(data.record[0].chat_instance_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWsCampaignTemplateList = async (chatInstanceId) => {
    try {
      const response = await axios.get(`ws/ws_waba_interactive.php`, {
        params: {
          task: "GetWsCampaignTemplateList",
          chat_instance_id: chatInstanceId,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      setGridRecord(data.status === 0 && data.record.length > 0 ? data.record : []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddNewTemplate(false);
      setChatInstanceShow(true);
      setLoading2(false);
      setLoadingBtn(false);
    }
  };

  // SEND FUNCTION =============================================================

  const sendBackToEditor = (values) => {
    values.section = 1;
    values.message_preview = "";
    values.params_example = Array.from({ length: values.params }, () => "");
    values.params_header_example = "";
    setTimeout(() => (messageContentRef.current.textContent = values.message_content), 100);
    setFormValues({ ...values });
  };

  const sendValidateNewTemplate = async (values) => {
    values.section = 2;
    values.message_preview = values.message_content;
    setFormValues({ ...values });
  };

  const sendAddWsCampaignTemplate = async (values) => {
    setLoadingBtn(true);
    try {
      const formData = new FormData();
      formData.append("task", "CreateWsCampaignTemplate");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("chat_instance_id", values.chat_instance_id);
      formData.append("template_name", values.template_name);
      formData.append("template_type", values.template_type);
      formData.append("template_message", values.message_content);
      formData.append("template_params", values.params);
      formData.append("template_params_example", values.params_example.join("[-0-]"));
      formData.append("template_params_header", values.params_header_example);

      const response = await axios.post(`ws/ws_waba_interactive.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        setChatInstanceData(chatInstanceList.find((record) => Number(record.value) === Number(values.chat_instance_id)));
        await getWsCampaignTemplateList(values.chat_instance_id);
        immediateToast("success", { title: "Success", message: "Template has been created", timer: 10000 });
      } else {
        immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtn(false);
      setIsAddNewTemplate(false);
      setFormValues({ template_name: "", template_type: "text", message_content: "", message_preview: "", media: "", chat_instance_id: "", params: 0, character_count: 0, warning: false, warning_message: [], section: 1, params_example: [], params_header_example: "" });
    }
  };

  const sendDeleteWsCampaignTemplate = async (values) => {
    Swal.fire({
      html: `
      <div>
        <p style="text-align: left; font-size: 14px;">Are you sure you want to delete all language templates with the name <strong>${values.whatsapp_template_name}</strong>?</p>
        <p style="text-align: left; font-size: 14px;">Please note:</p>
        <ul>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">If that message template name exists in multiple languages, all languages will be deleted.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">Messages that have been sent but not delivered will attempt to be delivered for 30 days.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">If a message from an approved message template is sent 30 days after deletion, you will receive a "Structure Unavailable" error and the customer will not receive the message.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">Once deleted, the name of an approved template cannot be used again for 30 days. Use a different name to create future templates.</li>
          </ul>
      </div>`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#fff",
      cancelButtonTextColor: "#000",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete all",
      reverseButtons: true,
      buttonsStyling: true,
      allowOutsideClick: false,
      customClass: { cancelButton: "op-ws-campaign-settings-modal-cancel-button" },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const backupRecord = [...gridRecord];
        setGridRecord((prevState) => prevState.filter((record) => record.whatsapp_template_op_id !== values.whatsapp_template_op_id));
        try {
          const formData = new FormData();
          formData.append("task", "DeleteWsCampaignTemplate");
          formData.append("utoken", session.user_ac_token);
          formData.append("ctoken", session.company_token);
          formData.append("chat_instance_id", values.chat_instance_id);
          formData.append("whatsapp_template_op_id", values.whatsapp_template_op_id);
          formData.append("template_name", values.whatsapp_template_name);
          const response = await axios.post(`ws/ws_waba_interactive.php`, formData);
          const data = response.data;
          if (data.status === 0) {
            immediateToast("success", { title: "Success", message: "Template has been deleted", timer: 10000 });
          } else {
            setGridRecord(backupRecord);
            immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  // ONCHANGE FUNCTION ==========================================================

  const onchangeChatInstance = async (e) => {
    setLoading2(true);
    setChatInstanceData(e);
    await getWsCampaignTemplateList(e.value, false);
  };

  // SET FUNCTION ==============================================================

  const setFormatMessages = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    let formattedText = input.replace(regBold, "<strong>$1</strong>").replace(regItalic, "<em>$1</em>").replace(regStrikethrough, "<s>$1</s>").replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  // USE EFFECT ================================================================

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getChatInstance();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formValues.section === 1 && messageContentRef.current && formValues.message_content) {
      messageContentRef.current.textContent = formValues.message_content;
    }
  }, [formValues.section, messageContentRef.current, formValues.message_content]);

  // GRID SETUP ==============================
  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    {
      headerName: "No",
      field: "index",
      maxWidth: 70,
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params) {
          return params.rowIndex + 1;
        }

        return null;
      },
    },
    {
      headerName: "Interactive Template",
      field: "whatsapp_template_name",
      filter: true,
    },
    {
      headerName: "Status",
      field: "waba_status",
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data.waba_status === "approved") {
          return (
            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(25, 135, 84, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}>
              Approved
            </div>
          );
        } else if (params.data.waba_status === "submitted") {
          return (
            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(108, 117, 125, 0.3)", color: "#6c757d", padding: "5px 10px", borderRadius: 5 }}>
              Submitted
            </div>
          );
        } else if (params.data.waba_status === "rejected") {
          return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">This template contains content that does not comply with WhatsApp's policies. We recommend you to read the Template guidelines</Tooltip>}>
              <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(220, 53, 70, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}>
                Rejected
              </div>
            </OverlayTrigger>
          );
        } else if (params.data.waba_status === "created") {
          return (
            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(13, 109, 253, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}>
              Created
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center border text-capitalize" style={{ backgroundColor: "fff", color: "#000", padding: "5px 10px", borderRadius: 5 }}>
              {params.data.waba_status}
            </div>
          );
        }
      },
    },
    {
      headerName: "Preview",
      field: "message",
      flex: 1,
    },
    {
      headerName: "",
      field: "",
      pinned: "right",
      maxWidth: 100,
      cellRenderer: (params) => {
        if (params) {
          return (
            <div className="d-flex justify-content-center align-items-center w-100">
              <Button variant="light" className="border shadow-sm" onClick={() => sendDeleteWsCampaignTemplate(params.data)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.whatsapp_template_op_id.toString();
  }, []);

  return (
    <Container fluid className="p-4 position-relative op-ws-campaign-settings" style={{ height: "calc(100vh - 56px)" }}>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col sm={12} className="d-flex justify-content-between align-items-center mb-3">
            <Stack direction="horizontal" gap={2} className="w-100">
              <Button
                variant=""
                className="op-primary-color text-light"
                onClick={() => {
                  setFormValues({
                    template_name: "",
                    template_type: "text",
                    message_content: "",
                    message_preview: "",
                    media: "",
                    params: 0,
                    character_count: 0,
                    warning: false,
                    warning_message: [],
                    section: 1,
                    params_example: [],
                    chat_instance_id: chatInstanceData.value,
                    params_header_example: "",
                  });

                  setIsAddNewTemplate(true);
                }}
              >
                Add Interactive Template
              </Button>
              <Button variant="link" className="text-decoration-none" style={{ color: "#007bff" }} onClick={() => window.open("https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/", "_blank")}>
                Guidelines
              </Button>
              <div className="ms-auto" />
              <p className="m-0">{`${gridRecord.length > 1 ? `${gridRecord.length} records` : gridRecord.length === 1 ? `${gridRecord.length} record` : "No record"}`}</p>
              {chatInstanceShow ? (
                <FormSelect options={chatInstanceList} valueDefault={chatInstanceData} onChange={onchangeChatInstance} width="250px" isSearchable />
              ) : (
                <Placeholder animation="glow">
                  <Placeholder bg="secondary" style={{ width: 250, height: 30, borderRadius: 8 }} />{" "}
                </Placeholder>
              )}

              <Button
                variant="light"
                className="border shadow-sm"
                onClick={() => {
                  setLoading2(true);
                  getWsCampaignTemplateList(chatInstanceData.value);
                }}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </Button>
            </Stack>
          </Col>
          {loading2 ? (
            <Col sm={12}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact ref={gridRef} rowSelection="multiple" rowData={gridRecord} rowHeight={70} columnDefs={gridColumn} getRowId={gridRowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}

      <Modal show={isAddNewTemplate} onHide={setIsAddNewTemplate} size="xl" backdrop="static">
        {formValues.section === 1 && (
          <Formik onSubmit={sendValidateNewTemplate} initialValues={formValues}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Add Whatsapp Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col sm={7}>
                      <Form.Group className="mb-3">
                        <Form.Label>Chat Instance Number</Form.Label>
                        <FormSelect valueDefault={chatInstanceList.find((record) => record.value === values.chat_instance_id)} options={chatInstanceList} onChange={(e) => setFieldValue("chat_instance_id", e.value)} />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                          value={values.template_name}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("template_name", value.toLowerCase().replace(/\s/g, ""));
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Template Type</Form.Label>
                        <FormSelect valueDefault={templateTypeList.find((record) => record.value === values.template_type)} options={templateTypeList} onChange={(e) => setFieldValue("template_type", e.value)} />
                      </Form.Group>

                      {values.template_type === "media" && (
                        <Form.Group className="mb-3">
                          <Form.Label>Template Content</Form.Label>
                          <Card>
                            <Card.Header className="d-flex align-items-center">
                              <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
                                Header
                              </div>
                              <FormSelect valueDefault={{ label: "Image", value: "image" }} options={[{ label: "Image", value: "image" }]} placeholder="Image" disabled width="200px" />
                            </Card.Header>
                            <Card.Body className="d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon icon={faImage} size="10x" color="#ddd" />
                            </Card.Body>
                          </Card>
                        </Form.Group>
                      )}

                      <Form.Group className="mb-3">
                        {values.template_type === "text" && <Form.Label>Template Content</Form.Label>}
                        <Card>
                          <Card.Header className="d-flex align-items-center">
                            <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
                              Body
                            </div>
                            <Button
                              variant="light"
                              className="border shadow-sm me-2"
                              onClick={() => {
                                messageContentRef.current.focus();
                                const selection = window.getSelection();
                                if (selection.rangeCount > 0 && messageContentRef.current.focus) {
                                  const range = selection.getRangeAt(0);
                                  const selectedText = selection.toString();
                                  const starText = `*${selectedText}*`;
                                  range.deleteContents();
                                  range.insertNode(document.createTextNode(starText));
                                  setFieldValue("message_content", messageContentRef.current.textContent);
                                  setFieldValue("character_count", messageContentRef.current.textContent.length);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faBold} />
                            </Button>

                            <Button
                              variant="light"
                              className="border shadow-sm me-2"
                              onClick={() => {
                                messageContentRef.current.focus();
                                const selection = window.getSelection();
                                if (selection.rangeCount > 0 && messageContentRef.current.focus) {
                                  const range = selection.getRangeAt(0);
                                  const selectedText = selection.toString();
                                  const italicText = `_${selectedText}_`;
                                  range.deleteContents();
                                  range.insertNode(document.createTextNode(italicText));
                                  setFieldValue("message_content", messageContentRef.current.textContent);
                                  setFieldValue("character_count", messageContentRef.current.textContent.length);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faItalic} />
                            </Button>

                            <Button
                              variant="light"
                              className="border shadow-sm me-2"
                              onClick={() => {
                                messageContentRef.current.focus();
                                const selection = window.getSelection();
                                if (selection.rangeCount > 0 && messageContentRef.current.focus) {
                                  const range = selection.getRangeAt(0);
                                  const selectedText = selection.toString();
                                  const strikethroughText = `~${selectedText}~`;
                                  range.deleteContents();
                                  range.insertNode(document.createTextNode(strikethroughText));
                                  setFieldValue("message_content", messageContentRef.current.textContent);
                                  setFieldValue("character_count", messageContentRef.current.textContent.length);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faStrikethrough} />
                            </Button>

                            <Button
                              variant="light"
                              className="border shadow-sm me-2"
                              onClick={() => {
                                messageContentRef.current.focus();
                                const selection = window.getSelection();
                                if (selection.rangeCount > 0 && messageContentRef.current.focus) {
                                  const range = selection.getRangeAt(0);
                                  const selectedText = selection.toString();
                                  const codeText = `\`\`\`${selectedText}\`\`\``;
                                  range.deleteContents();
                                  range.insertNode(document.createTextNode(codeText));
                                  setFieldValue("message_content", messageContentRef.current.textContent);
                                  setFieldValue("character_count", messageContentRef.current.textContent.length);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faCode} />
                            </Button>

                            <Button
                              variant="light"
                              className="border shadow-sm"
                              onClick={() => {
                                const newVariable = values.message_content.length > 0 ? ` {{${values.params + 1}}}` : `{{${values.params + 1}}}`;
                                messageContentRef.current.textContent += newVariable;
                                setFieldValue("message_content", messageContentRef.current.textContent);
                                setFieldValue("character_count", messageContentRef.current.textContent.length);
                                setFieldValue("params", values.params + 1);
                                setFieldValue(
                                  "params_example",
                                  Array.from({ length: values.params + 1 }, () => "")
                                );
                              }}
                            >
                              Add Variable
                            </Button>
                          </Card.Header>
                          <Card.Body className="d-flex justify-content-center align-items-center position-relative">
                            <div
                              ref={messageContentRef}
                              dir="ltr"
                              contentEditable="true"
                              placeholder="Type your template body text here..."
                              className="w-100 mb-4"
                              style={{ border: "none", outline: "none", whiteSpace: "pre-line" }}
                              onKeyDown={(e) => {
                                const value = messageContentRef.current.textContent;
                                if (value.length >= 1024 && e.key !== "Backspace" && e.key !== "Delete") {
                                  e.preventDefault();
                                }

                                if (e.key === "Enter") {
                                  e.preventDefault(); // Prevent the default action
                                  const selection = window.getSelection();
                                  const range = selection.getRangeAt(0);
                                  const customLineBreak = document.createTextNode("\n");
                                  const spacer = document.createTextNode("\u00A0");
                                  range.deleteContents();
                                  range.insertNode(customLineBreak);
                                  range.collapse(false);
                                  range.insertNode(spacer);
                                  // range.setStartAfter(spacer);
                                  // range.setEndAfter(spacer);
                                  selection.removeAllRanges();
                                  selection.addRange(range);
                                  setFieldValue("message_content", messageContentRef.current.textContent);
                                }

                                if (e.ctrlKey && e.key === "b") {
                                  const selection = window.getSelection();
                                  if (selection.rangeCount > 0) {
                                    const range = selection.getRangeAt(0);
                                    const selectedText = selection.toString();
                                    const starText = `*${selectedText}*`;
                                    range.deleteContents();
                                    range.insertNode(document.createTextNode(starText));
                                    setFieldValue("message_content", messageContentRef.current.textContent);
                                  }
                                }

                                if (e.ctrlKey && e.key === "i") {
                                  const selection = window.getSelection();
                                  if (selection.rangeCount > 0) {
                                    const range = selection.getRangeAt(0);
                                    const selectedText = selection.toString();
                                    const starText = `*${selectedText}*`;
                                    range.deleteContents();
                                    range.insertNode(document.createTextNode(starText));
                                    setFieldValue("message_content", messageContentRef.current.textContent);
                                  }
                                }

                                if (e.ctrlKey && e.shiftKey && e.key === "x") {
                                  const selection = window.getSelection();
                                  if (selection.rangeCount > 0) {
                                    const range = selection.getRangeAt(0);
                                    const selectedText = selection.toString();
                                    const strikethroughText = `~${selectedText}~`;
                                    range.deleteContents();
                                    range.insertNode(document.createTextNode(strikethroughText));
                                    setFieldValue("message_content", messageContentRef.current.textContent);
                                  }
                                }

                                if (e.ctrlKey && e.shiftKey && e.key === "c") {
                                  const selection = window.getSelection();
                                  if (selection.rangeCount > 0) {
                                    const range = selection.getRangeAt(0);
                                    const selectedText = selection.toString();
                                    const codeText = `\`\`\`${selectedText}\`\`\``;
                                    range.deleteContents();
                                    range.insertNode(document.createTextNode(codeText));
                                    setFieldValue("message_content", messageContentRef.current.textContent);
                                  }
                                }
                              }}
                              onInput={(e) => {
                                const value = e.currentTarget.textContent;
                                if (value.length > 1024) return;
                                setFieldValue("message_content", value);
                                const variableCount = (value.match(/{{\d+}}/g) || []).length;
                                setFieldValue("params", variableCount);
                                setFieldValue(
                                  "params_example",
                                  Array.from({ length: variableCount }, () => "")
                                );
                                setFieldValue("character_count", value.length);

                                const lastVariableMatch = values.message_content.match(/{{\d+}}(.*)/);
                                const alertMessage = [];
                                if (lastVariableMatch) {
                                  const lastVariableText = lastVariableMatch[1];
                                  if (lastVariableText.length === 0 || lastVariableText.length <= 5) {
                                    alertMessage.push("This template contains too many variable parameters relative to the message length. You need to decrease the number of variable parameters or increase the message length.");
                                  }

                                  const lastVariableText2 = lastVariableMatch[1].trim();
                                  if (lastVariableText2.length === 0) {
                                    alertMessage.push("The template body starting or ending with a placeholder is not the best practice.");
                                  }
                                }

                                const variableStartMatch = value.match(/{{\d+}}/);
                                if (variableStartMatch) {
                                  const variableStartIndex = variableStartMatch.index;
                                  const variableStartText = value.substring(0, variableStartIndex);
                                  if (variableStartText.length === 0) {
                                    if (alertMessage.find((message) => message === "The template body starting or ending with a placeholder is not the best practice.") === undefined) {
                                      alertMessage.push("The template body starting or ending with a placeholder is not the best practice.");
                                    }
                                  }
                                }

                                setFieldValue("warning", alertMessage.length > 0);
                                setFieldValue("warning_message", alertMessage);
                              }}
                            />
                            {values.message_content && (
                              <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px" }}>
                                {values.character_count} / 1024 characters
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Form.Group>
                      {values.warning && (
                        <Form.Group className="mb-3">
                          <Alert variant="warning" className="w-100">
                            <ul className="m-0">
                              {values.warning_message.map((message, index) => (
                                <li key={index}>{message}</li>
                              ))}
                            </ul>
                          </Alert>
                        </Form.Group>
                      )}
                    </Col>
                    <Col sm={5}>
                      <div className="border w-100 px-4 py-4">
                        <h6>Preview</h6>
                        <div className="rounded position-relative p-2" style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}>
                          <div className="ws-bubble">
                            {values.template_type === "media" && (
                              <div className="ws-bubble-header">
                                <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                              </div>
                            )}
                            <p className="ws-bubble-message" dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_content) }} />
                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" type="button" onClick={() => setIsAddNewTemplate(false)}>
                    Discard Changes
                  </Button>
                  <Button variant="" type="submit" className="btn op-button op-primary-color text-light shadow" disabled={values.message_content === "" || values.chat_instance_id === "" || values.template_type === "" || values.message_content.length > 1024}>
                    Preview and Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}

        {formValues.section === 2 && (
          <Formik onSubmit={sendAddWsCampaignTemplate} initialValues={formValues}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Template Preview</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <h6>Example values</h6>

                      {values.template_type === "media" && (
                        <Form.Group className={`mb-3 mt-4`}>
                          <Form.Label>Header image URL</Form.Label>
                          <Form.Control
                            value={values.params_header_example}
                            onChange={(e) => {
                              setFieldValue("params_header_example", e.target.value);
                            }}
                          />
                        </Form.Group>
                      )}

                      {values.params > 0 ? (
                        <>
                          {values.params_example.map((params_body, index) => (
                            <Form.Group className={`mb-3 ${index === 0 && values.template_type !== "media" ? "mt-4" : ""}`} key={index}>
                              <Form.Label>{`Body: {{${index + 1}}}`}</Form.Label>
                              <Form.Control
                                value={params_body}
                                onChange={(e) => {
                                  const paramsValue = [...values.params_example];
                                  paramsValue[index] = e.target.value;

                                  let message_content = values.message_content;
                                  paramsValue.forEach((record, idx) => {
                                    if (record) {
                                      message_content = message_content.replace(`{{${idx + 1}}}`, record);
                                    }
                                  });

                                  setFieldValue("params_example", paramsValue);
                                  setFieldValue("message_preview", message_content);
                                }}
                              />
                            </Form.Group>
                          ))}
                        </>
                      ) : values.template_type !== "media" && values.params === 0 ? (
                        <div className="h-100 mt-5">
                          {`You haven't used any variable placeholders in your text.`} <br /> <br />
                          {`In order to add a variable, go back to the editor and insert {{#}} at the given position, where # represents the variable index, which needs to start at {{1}}.`} <br /> <br />
                          {`For more information please refer to our Template Manager Documentation.`}
                        </div>
                      ) : null}
                    </Col>
                    <Col sm={6}>
                      <div className="border w-100 px-4 py-4">
                        <div className="rounded position-relative p-2" style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}>
                          <div className="ws-bubble">
                            {values.template_type === "media" && values.params_header_example === "" ? (
                              <div className="ws-bubble-header">
                                <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                              </div>
                            ) : values.template_type === "media" && values.params_header_example !== "" ? (
                              <img src={values.params_header_example} alt="header" className="w-100" onLoad={() => setImgError(false)} onError={() => setImgError(true)} style={{ objectFit: "cover" }} />
                            ) : null}
                            <p className="ws-bubble-message" dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_preview) }} />
                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {imgError && values.params_header_example !== "" && (
                    <Alert variant="danger" className="w-100">
                      Please provide examples for all your variables.
                    </Alert>
                  )}

                  {values.params > 0 && (
                    <Alert variant="warning" className="w-100">
                      The provided example values will be submitted to Meta as part of the template submission process. It is required to add example values for all variables in order to submit the template.
                    </Alert>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="" type="button" disabled={loadingBtn} onClick={() => sendBackToEditor(values)}>
                    Back to editor
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="btn op-button op-primary-color text-light shadow"
                    disabled={values.section === 1 || (values.template_type === "media" && values.params_header_example === "") || (values.params_example.length > 0 && values.params_example.includes("")) || imgError || loadingBtn}
                  >
                    {loadingBtn ? (
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save Template"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </Container>
  );
};

export default SettingsWsCampaignTemplate360;
