import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup, Card } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import FormSelect from "../includes/FormSelect";
// import { useTranslation } from "react-i18next";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { faRightLeft } from "@fortawesome/sharp-solid-svg-icons";
import {ReactSortable} from 'react-sortablejs';


const MarketingForm = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    // const { t } = useTranslation();

    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState({});

    const [userList, setUserList] = useState([]);
    const [AgentList, setAgentList] = useState([]);
    const [pipeList, setPipeList] = useState([]);
    const [dsList, setDsList] = useState([]);

    const [isImg, setIsImg] = useState(true);

    const consentTypeList = [
        {label: "PDPA", value: "PDPA"}
    ];

    var cfDeal = [];
    var cfPerson = [];
    const [CFList, setCFList] = useState([]);
    const [selCFList, setSelCFList] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 5 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [gridColumn] = useState([
        {
            headerName: "Title",
            field: "form_title",
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Slug",
            field: "url_title",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="clickable" onClick={() => {handleOpenForm(params.data)}}>
                            {params.data.url_title}
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Username",
            field: "user_name",
            resizable: true,
            width: 180
        },
        {
            headerName: "Source",
            field: "ds_title",
            resizable: true,
            width: 180
        },
        {
            headerName: "View Count",
            field: "view_count",
            resizable: true,
            width: 120
        },
        {
            headerName: 'Submitted',
            field: 'survey_submitted',
            resizable: true,
            width: 120
        },
        {
            headerName: "Version",
            field: "version",
            resizable: true,
            width: 90,
            cellRenderer: (params) => {
                if (params.data) {
                    return Number(params.data.version) === 0 ? "V1" : "V2";
                }
            }
        },
        {
            headerName: "Notify",
            field: "notify",
            resizable: true,
            pinned: "right",
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex align-items-center justify-content-center">
                            <Form.Check
                                type="switch"
                                className="d-flex align-items-center justify-content-center"
                                checked={Number(params.data.notify) === 1 ? true : false}
                                onChange={() => {handleEnable(params.data)}}
                            />
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Active",
            field: "inactive",
            resizable: true,
            pinned: "right",
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex align-items-center justify-content-center">
                            <Form.Check
                                type="switch"
                                className="d-flex align-items-center justify-content-center"
                                checked={Number(params.data.inactive) === 0 ? true : false}
                                onChange={() => {handleActive(params.data)}}
                            />
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Action",
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        var api="";
        if (session.hostUrlType === 'cloud_staging') {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }

        axios.get(api,{
            params: {
                task: "getAllFormsV3",
                mode: "Marketing",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                
                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            });
        });
    }

    const getAllUser = () => {
        axios.get("ws/ws_user.php", {
            params: {
                task: '4b',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map((data) => {
                    return {label: data.user_name, value: data.user_id};
                });
                var dataList2 = data.record.map((data) => {
                    return {label: data.user_name, value: data.user_id};
                });
                setAgentList(dataList2);
                dataList.unshift({
                    label: 'User Selection',
                    value: '999999'
                });

                dataList.unshift({
                    label: 'All Users',
                    value: '0'
                });

                setUserList(dataList);
                setAgentList(dataList2);
            }else{
                setAgentList([]);
                setUserList([]);
            }
        })
        .catch(error => {
            setUserList([]);
        });
    }

    const getAllPipeline = () => {
        axios.get("ws/ws_pipeline.php", {
            params: {
                task: 'getAllPipelines',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map((data) => {
                    return {label: data.pipeline_title, value: data.pipeline_id};
                });

                setPipeList(dataList);
            }else{
                setPipeList([]);
            }
        })
        .catch(error => {
            setPipeList([]);
        });
    }

    const getAllDS = () => {
        axios.get("ws/ws_source.php", {
            params: {
                task: 2,
                disp: 'show',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map((data) => {
                    return {label: data.ds_title, value: data.ds_id};
                });

                dataList.unshift({
                    label: 'All Source',
                    value: '0'
                });

                setDsList(dataList);
            }else{
                setDsList([]);
            }
        })
        .catch(error => {
            setDsList([]);
        });
    }

    const getDealCF = () => {
        axios.get('ws/ws_custom_field.php',{
            params: {
                task: '5',
                area: 'deal',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                cfDeal = data.record.map((data) => {
                    return {name: data.cf_label, id: data.cf_id};
                });
            }else{
                cfDeal = [];
            }
        })
        .catch(error => {
            cfDeal = [];
        });
    }

    const getPersonCF = () => {
        axios.get('ws/ws_custom_field.php', {
            params: {
                task: '5',
                area: 'person',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                cfPerson = data.record.map((data) => {
                    return {name: data.cf_label, id: data.cf_id};
                });
            }else{
                cfPerson = [];
            }
        })
        .catch(error => {
            cfPerson = [];
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
            getAllUser();
            getAllPipeline();
            getAllDS();
            getDealCF();
            getPersonCF();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('form_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    const handleOpenForm = (params) => {
        if (session.hostUrlType === 'cloud_staging') {
            if (Number(params.version) === 1) {
                window.open(`${session.hostUrl}/form/f5/${params.url_title}/${params.form_hash}/0`, "_blank");
            }else{
                window.open(`${session.hostUrl}/form/f4/${params.url_title}/${params.form_hash}/0`, "_blank");
            }
        }else{
            if (Number(params.version) === 1) {
                window.open(`${session.hostUrl}/form/f2/${params.url_title}/${params.form_hash}/0`, "_blank");
            }else{
                window.open(`${session.hostUrl}/form/f1/${params.url_title}/${params.form_hash}`, "_blank");
            }
        }
    }

    const handleEnable = (params) => {
        var enable = Number(params.notify) === 0 ? 1 : 0;

        var api = "";

        if (session.hostUrlType === "cloud_staging") {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }

        axios.get(api,{
            params: {
                task: 'formNotify',
                formid: params.reg_form_id,
                mode: enable,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const handleActive = (params) => {
        var active = Number(params.inactive) === 0 ? 1 : 0;

        var api = "";

        if (session.hostUrlType === "cloud_staging") {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }
        
        axios.get(api, {
            params: {
                task: 'formActive',
                formid: params.reg_form_id,
                mode: active,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const addSchema = yup.object().shape({
        form_title: yup.string()
        .matches(
        /^[^\/\\,\.\?"':;\[\]{}]*$/,
        `Cannot contains this character / \ , . ? " ' : ; [ ] { }`
        )
        .required("This field is required")
    });

    const sendAdd = (values) => {
        var api = "";

        if (session.hostUrlType === "cloud_staging") {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }

        axios.get(api, {
            params: {
                task: 'addTemplateV3',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                title: values.form_title,
                mode: 'Marketing'
              }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully added',
                    timer: 1500
                })
                .then(result => {
                    getGridData();
                    setIsAdd(false);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        form_title: yup.string()
        .matches(
            /^[^\/\\,\.\?"':;\[\]{}]*$/,
            `Cannot contains this character / \ , . ? " ' : ; [ ] { }`
        )
        .required('This field is required'),
        slug: yup.string()
        .matches(
            /^[^\/\\,\.\?"':;\[\]{}]*$/,
            `Cannot contains this character / \ , . ? " ' : ; [ ] { }`
        )
        .required('This field is required'),
        pipe_id: yup.string().required('This field is required'),
        ds_id: yup.string().required('This field is required'),
        user_id: yup.string().required('This field is required'),
        consent_needed: yup.string().required('This field is required'),
        consent_type: yup.string().notRequired().when('consent_needed', ([consent_needed], schema) => {
            if (Number(consent_needed) === 1)
                return yup.string().required('This field is required');
            return schema;
        }),
        consent_text: yup.string().notRequired().when('consent_needed', ([consent_needed], schema) => {
            if (Number(consent_needed) === 1)
                return yup.string().required('This field is required');
            return schema;
        })
    });

    const handleEdit = (params) => {
        setIsImg(params.banner_img ? true : false);
        // console.log(params);
        setEditInitVal({
            form_id: params.reg_form_id,
            form_title: params.form_title,
            slug: params.url_title,
            pipe_id: Number(params.pipeline_id) === 0 ? '' : params.pipeline_id,
            ds_id: params.ds_id,
            user_id: params.user_id,
            email_compulsory: params.email_compulsory,
            consent_needed: params.consent_needed,
            consent_type: params.consent_1_type,
            consent_text: params.consent_1_text,
            published: params.published,
            banner_img_ori: params.banner_img,
            banner_img: null,
            agent: params.contributor_id && Number(params.contributor_id) > 0 ? 1 : 0,
            agent_userID: params.contributor_id ? params.contributor_id : 0,
            appointment: params.appointment_needed,
            form_version: Number(params.version) === 0 ? 'V1' : 'V2',
            form_cf_count: params.cf_count
        });

        if (Number(params.version) === 1) {
            
            var cfParams = [];
            var cfList = [];
            var cfSel = [];

            if (Number(params.cf_count) > 0) {
                params.cf_record.forEach((data) => {
                    cfParams.push(data.cf_id);
                });
            }

            // console.log(cfParams);
            
            if (cfDeal.length > 0) {
                cfDeal.forEach(data => {
                    if (!cfParams.includes(data.id)) {
                        cfList.push(data);
                    }else{
                        cfSel.push(data);
                    }
                });
            }

            if (cfPerson.length > 0) {
                cfPerson.forEach(data => {
                    if (!cfParams.includes(data.id)) {
                        cfList.push(data);
                    }else{
                        cfSel.push(data);
                    }
                });
            }

            if (cfSel.length > 0) {
                const sortByObject = cfParams.reduce((obj, item, index) => {
                    return {
                    ...obj,
                    [item]: index,
                    };
                }, {});

                cfSel.sort((a,b) => sortByObject[a.id] - sortByObject[b.id]);
            }
            
            setCFList(cfList);
            setSelCFList(cfSel);

        }

        setIsEdit(true);
    }

    const sendEdit = (values) => {
        var allowEdit = 1;

        if (!isImg) {
            if (!values.banner_img) {
                Swal.fire({
                    icon: 'error',
                    title: "Failed",
                    text: "Please insert image"
                });
                allowEdit = 0;
            }else{
                allowEdit = 1;
            }
        }

        if (allowEdit) {
            
            if (values.form_version === 'V2') {
                triggerSortCfApi(values.form_id, values.form_cf_count);
                triggerEdit(values);
            }else{
                triggerEdit(values);
            }
        }
    }

    const triggerSortCfApi = async(formID, count) =>{
        var cf_id = [];
        var task = Number(count) > 0 ? 'UpdateAndSortCustomField' : 'InsertAndSortCustomField';

        if (selCFList.length > 0) {
            selCFList.forEach((data) => {
                cf_id.push(data.id);
            });
        }

        // console.log(formID);
        // console.log(task);
        // console.log(cf_id.toString().replaceAll(',','|'));

        var api = "";

        if (session.hostUrlType === "cloud_staging") {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }

        try {
            const res = await axios.get(api, {
                params : {
                    reg_form_id: formID,
                    task: task,
                    cf_id: cf_id.toString().replaceAll(',','|'),
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            let data = res.data;

            if (data) {
                
            }else{

            }

        } catch (error) {
            
        }
    }

    const triggerEdit = async(values) => {
        // console.log(values);
        var params = new FormData();

        params.append('app', '');
        params.append('form_title', values.form_title);
        params.append('form_slug', values.slug);
        params.append('source_cat', values.ds_id);
        params.append('user_id', values.user_id);
        params.append('form_id', values.form_id);
        params.append('mode', 'Marketing');
        params.append('pipeline_id', values.pipe_id);
        params.append('chat_instance_id', '');
        params.append('published', values.published);
        params.append('file_available', isImg ? 0 : 1);
        params.append('file_title', values.banner_img_ori);
        params.append('file', values.banner_img ? values.banner_img[0] : null);
        params.append('whatsapp_rsvp_message', '');
        params.append('whatsapp_qr_name', '');
        params.append('whatsapp_survey_message', '');
        params.append('associated_survey', '');
        params.append('consent_needed', values.consent_needed);
        params.append('consent_1_type', values.consent_type);
        params.append('consent_1_text', values.consent_text);
        params.append('event_date', '');
        params.append('event_time', '');
        params.append('event_location', '');
        params.append('event_create_lead', '');
        params.append('event_user_tag', '');
        params.append('event_lead_assigned_source_token', '');
        params.append('form_description', '');
        params.append('appointment_needed', values.appointment);
        params.append('contributor_id', values.agent_userID);
        params.append('email_compulsory', values.email_compulsory);
        params.append('user_ac_token', session.user_ac_token);
        params.append('company_token', session.company_token);

        try {

            const res = await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/document_form_marketingV2.php`, params);

            let data = res.data

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully updated',
                    timer: 1500
                })
                .then(result => {
                    getGridData();
                    setIsEdit(false);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: data.message
                });
            }
            
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }

    }

    const handleDelete = (params) => {
        var api = "";

        if (session.hostUrlType === "cloud_staging") {
            api = "https://www.nexcrmapis.com/form/registration_api_staging.php";
        }else{
            api = "https://www.nexcrmapis.com/form/registration_api.php";
        }
        
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'This form will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get(api, {
                    params: {
                        task: 'delForm',
                        form_id: params.reg_form_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 1) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Cannot delete this form due to dependencies'
                        });
                    }else{
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Successfully deleted',
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }
                });
            }
        });
    }

    return(
        <Container fluid>
            {isLoading ? <Loader/> : 
                <div>
                    <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center my-3">
                        <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>Add Form</button>
                        <Form.Group style={{width: "300px"}}>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Search by title"
                                    type="text"
                                    onChange={(e) => {handleChange(e.target.value)}}
                                />
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon"/>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <div className="ms-auto">{Number(recordFound) === 1 ? `${recordFound} record found.` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}</div>
                    </Stack>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={gridColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                            />
                        </div>
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    form_title: ""
                                }
                            }
                        >
                            {({ handleSubmit, setFieldValue, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>Add Form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>Form Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.form_title && touched.form_title}
                                                onChange={(e) => {
                                                    setFieldValue('form_title', e.target.value);
                                                }}
                                            />
                                            {errors.form_title && touched.form_title && <div className="op-error-message">{errors.form_title}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>Edit Form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.form_title}
                                                isInvalid={errors.form_title && touched.form_title}
                                                onChange={(e) => {setFieldValue('form_title', e.target.value)}}
                                            />
                                            {errors.form_title && touched.form_title && <div className="op-error-message">{errors.form_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.slug}
                                                isInvalid={errors.slug && touched.slug}
                                                onChange={(e) => {setFieldValue('slug', e.target.value)}}
                                            />
                                            {errors.slug && touched.slug && <div className="op-error-message">{errors.slug}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Pipeline</Form.Label>
                                            <FormSelect
                                                options={pipeList}
                                                valueDefault={pipeList.filter(opt => Number(opt.value) === Number(values.pipe_id))}
                                                isInvalid={errors.pipe_id && touched.pipe_id}
                                                placeholder="Select Pipeline"
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('pipe_id', e ? e.value : '')}}
                                            />
                                            {errors.pipe_id && touched.pipe_id && <div className="op-error-message">{errors.pipe_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Deal Source</Form.Label>
                                            <FormSelect
                                                options={dsList}
                                                valueDefault={dsList.filter(opt => Number(opt.value) === Number(values.ds_id))}
                                                isInvalid={errors.ds_id && touched.ds_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('ds_id', e ? e.value : '')}}
                                            />
                                            {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>User</Form.Label>
                                            <FormSelect
                                                options={userList}
                                                valueDefault={userList.filter(opt => Number(opt.value) === Number(values.user_id))}
                                                isInvalid={errors.user_id && touched.user_id}
                                                placeholder="Select User"
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('user_id', e ? e.value : '')}}
                                            />
                                            {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Do you want to enable email as compulsory?</Form.Label>
                                            <Stack direction="horizontal" gap={3} className="mt-2">
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="emailReq"
                                                    checked={Number(values.email_compulsory) === 1}
                                                    onChange={() => {setFieldValue('email_compulsory', 1)}}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="emailReq"
                                                    checked={Number(values.email_compulsory) === 0}
                                                    onChange={() => {setFieldValue('email_compulsory', 0)}}
                                                />
                                            </Stack>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Do you want to create consent?</Form.Label>
                                            <Stack direction="horizontal" gap={3} className="mt-3">
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="consentReq"
                                                    checked={Number(values.consent_needed) === 1}
                                                    onChange={() => {
                                                        setFieldValue('consent_needed', 1);
                                                    }}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="consentReq"
                                                    checked={Number(values.consent_needed) === 0}
                                                    onChange={() => {
                                                        setFieldValue('consent_needed', 0);
                                                        setFieldValue('consent_type', '');
                                                        setFieldValue('consent_text', '');
                                                    }}
                                                />
                                            </Stack>
                                        </Form.Group>
                                        {Number(values.consent_needed) === 1 && <Form.Group className="mb-3">
                                            <Form.Label>Consent Type</Form.Label>
                                            <FormSelect
                                                placeholder="Choose consent type"
                                                options={consentTypeList}
                                                valueDefault={consentTypeList.filter(opt => opt.value === values.consent_type)}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.consent_type && touched.consent_type}
                                                onChange={(e) => {setFieldValue('consent_type', e ? e.value : '')}}
                                            />
                                            {errors.consent_type && touched.consent_type && <div className="op-error-message">{errors.consent_type}</div>}
                                        </Form.Group>}
                                        {Number(values.consent_needed) === 1 && <Form.Group className="mb-3">
                                            <Form.Label>Consent Text</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.consent_text}
                                                isInvalid={errors.consent_text && touched.consent_text}
                                                onChange={(e) => {setFieldValue('consent_text', e.target.value)}}
                                            />
                                            {errors.consent_text && touched.consent_text && <div className="op-error-message">{errors.consent_text}</div>}
                                        </Form.Group>}
                                        <Form.Group className="mb-3">
                                            <Form.Label>Do you want to publish this marketing form?</Form.Label>
                                            <Stack direction="horizontal" gap={3} className="mt-3">
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="publishReq"
                                                    checked={values.published === 'Yes'}
                                                    onChange={() => {setFieldValue('published', 'Yes')}}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="publishReq"
                                                    checked={values.published === 'No'}
                                                    onChange={() => {setFieldValue('published', 'No')}}
                                                />
                                            </Stack>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Do you have contributor for this marketing form?</Form.Label>
                                            <Stack direction="horizontal" gap={3} className="mt-3">
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="agent"
                                                    checked={values.agent === 1}
                                                    onChange={() => {setFieldValue('agent', 1)}}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="agent"
                                                    checked={values.agent === 0}
                                                    onChange={() => {setFieldValue('agent', 0); setFieldValue('agent_userID','')}}
                                                />
                                            </Stack>
                                        </Form.Group>

                                        {values.agent === 1 &&
                                        <Form.Group className="mb-3">
                                            <Form.Label>Contributor</Form.Label>
                                            <FormSelect
                                                placeholder="Agent"
                                                options={AgentList}
                                                valueDefault={AgentList.filter(opt => Number(opt.value) === Number(values.agent_userID))}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('agent_userID', e ? e.value : 0)}}
                                            />
                                        </Form.Group>}

                                        <Form.Group className="mb-3">
                                            <Form.Label>Do you want to create an appointment?</Form.Label>
                                            <Stack direction="horizontal" gap={3} className="mt-3">
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="appo"
                                                    checked={Number(values.appointment) === 1}
                                                    onChange={() => {setFieldValue('appointment', 1)}}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="appo"
                                                    checked={Number(values.appointment) === 0}
                                                    onChange={() => {setFieldValue('appointment', 0)}}
                                                />
                                            </Stack>
                                        </Form.Group>

                                        {values.form_version === 'V2' && <Form.Group>
                                            <div className='mb-3' style={{position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%"}}>
                                                <Card style={{width: '45%'}}>
                                                    <Card.Header className="text-center">Custom Field (Deals & Person)</Card.Header>
                                                    <Card.Body style={{height: '350px', overflow: 'auto'}}>
                                                        <ReactSortable list={CFList} setList={setCFList} group="shared" style={{height: '100%'}}>
                                                            {CFList.map((item, index) => (
                                                                <Card key={index} className="mb-2" style={{cursor: "grab"}}>
                                                                    <Card.Body>{item.name}</Card.Body>
                                                                </Card>
                                                            ))}
                                                        </ReactSortable>
                                                    </Card.Body>
                                                </Card>

                                                <div>
                                                    <FontAwesomeIcon icon={faRightLeft}/>
                                                </div>

                                                <Card style={{width: '45%'}}>
                                                    <Card.Header className="text-center">Selected Custom Field</Card.Header>
                                                    <Card.Body style={{height: '350px', overflow: 'auto'}}>
                                                        <ReactSortable list={selCFList} setList={setSelCFList} group="shared" style={{height: '100%'}}>
                                                            {(selCFList.length > 0) && selCFList.map((item, index) => (
                                                                <Card key={index} className="mb-2" style={{cursor: "grab"}}>
                                                                    <Card.Body>{item.name}</Card.Body>
                                                                </Card>
                                                            ))}
                                                        </ReactSortable>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Form.Group>}
                                        
                                        <Form.Group className="mb-3">
                                            <Form.Label>Image: </Form.Label>
                                            {isImg && <div>
                                                <img src={`${session.hostUrl}/form/${values.banner_img_ori}`} width={'100%'} alt="form"/>
                                            </div>}
                                            {!isImg && <FilePond
                                                allowMultiple={false}
                                                maxFiles={1}
                                                name="file"
                                                files={values.banner_img}
                                                credits={false}
                                                acceptedFileTypes={["image/jpg", "image/png", "image/jpeg"]}
                                                onupdatefiles={(fileItems) => {
                                                    if (fileItems && fileItems.length) {
                                                        if (fileItems[0].file.type === "image/jpg" || fileItems[0].file.type === "image/jpeg" || fileItems[0].file.type === "image/png") {
                                                            setFieldValue(
                                                                "banner_img",
                                                                fileItems.map((fileItem) => fileItem.file)
                                                            );
                                                        }
                                                    }
                                                }}
                                                onremovefile={() => {
                                                    setFieldValue(
                                                        "banner_img",
                                                        null
                                                    );
                                                }}
                                                
                                            />
                                            }
                                            <Stack direction="horizontal" gap={1} className="mt-1">
                                                {isImg && <button type='button' className="btn btn-primary ms-auto" onClick={() => {setIsImg(false)}}>Change Image</button>}
                                                {!isImg && values.banner_img_ori && <button type='button' className="btn btn-danger ms-auto" onClick={() => {setIsImg(true); setFieldValue("banner_img", null)}}>Cancel</button>}
                                            </Stack>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            }
        </Container>
    );
}

export default MarketingForm;