import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";

const WorkflowAction = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [workflowActionlist, setWorkflowActionList] = useState([]);
    const [user, setUser] = useState([]);
    const [isAddWorkflow, setisAddWorkflow] = useState(false);
    const [pipeline, setPipeline] = useState([]);
    const [chatInstance, setChatInstance] = useState([]);
    const [pipelineID, setPipelineID] = useState([]);
    const [combinedStageData, setCombinedStageData] = useState([]);
    const [dsOption, setDSOption] = useState([]);
    const [email, setEmail] = useState([]);
    const [customField, setCustomField] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);


    // Validation Schema
    const addValidationSchema = yup.object().shape({
        action_title: yup.string().required("This field is required")
    });

    const editValidationSchema = yup.object().shape({
        action_title: yup.string().required("This field is required"),
        pipe_id: yup.string().required("This field is required"),
        cs_id: yup.string()
            .test('not-zero', 'This field is required', value => value !== '0')
            .required("Please choose an option"),
        chat_instance_id: yup.string()
            .test('not-zero', 'This field is required', value => value !== '0')
            .required("Please choose an option"),
        user_id: yup.string()
            .test('not-zero', 'This field is required', value => value !== '0')
            .required("Please choose an option"),
        email_template_id: yup.string()
            .test('not-zero', 'This field is required', value => value !== '0')
            .required("Please choose an option"),
        fixed_time: yup.string().required("This field is required"),
        // start_after_days: yup.string().required("This field is required"),
        // start_after_minutes: yup.string().required("This field is required"),
        // param_1: yup.string().required("This field is required"),
        // param_2: yup.string().required("This field is required"),
        // param_3: yup.string().required("This field is required"),
        // param_4: yup.string().required("This field is required"),
        // param_5: yup.string().required("This field is required"),
        // secret_key: yup.string().required("This field is required"),
        // ds_token: yup.string().required("This field is required"),
        // short_url_link: yup.string().required("This field is required"),
        // short_url_title: yup.string().required("This field is required"),
        // user_tag: yup.string().required("This field is required"),
    });



    const fieldAction = [
        { value: 'create_deal', label: 'Create Deal' },
        { value: 'create_activity', label: 'Create Activity' },
        { value: 'create_activity_with_cf', label: 'Create Activity with CF' },
        { value: 'unlock_stage', label: 'Unlock Stage' },
        { value: 'send_email', label: 'Send Email' },
        { value: 'send_sms', label: 'Send SMS' },
        { value: 'schedule_deals', label: 'Schedule Deals' },
        { value: 'update_parent_deal', label: 'Update Parent Deal' },
        { value: 'send_user_notification', label: 'Send User Notification' },
        { value: 'create_deal_with_cf', label: 'Create Deal with CF' },
        { value: 'send_lead_to_diff_acc_leadroute', label: 'Send Lead to Diff Acc Leadroute' },
        { value: 'send_lead_to_diff_acc_rotate', label: 'Send Lead to Diff Acc Rotate' },
        { value: 'send_lead_to_specific_user_by_cfid', label: 'Send Lead to Specific User by CFID' },
        { value: 'send_lead_to_agtiv', label: 'Send Lead to AGTIV' },
        { value: 'send_lead_to_agtiv_won', label: 'Send Lead to AGTIV Won' },
        { value: 'send_lead_to_agtiv_lost', label: 'Send Lead to AGTIV Lost' },
        { value: 'create_deal_with_product', label: 'Create Deal with Product' },
        { value: 'sendContactToMailchimp', label: 'Send Contact to Mailchimp' },
        { value: 'sendContactToCuration', label: 'Send Contact to Curation' },
        { value: 'send_whatsapp', label: 'Send Whatsapp' },
        { value: 'schedule_whatsapp', label: 'Schedule Whatsapp' },
        { value: 'start_blueprint_sequence', label: 'Start Blueprint Sequence' },
        { value: 'bacfree_send_sales_order', label: 'Bacfree Send Sales Order' },
        { value: 'bacfree_send_jobsheet', label: 'Bacfree Send Jonsheet' },
        { value: 'create_customer_login', label: 'Create Customer Login' },
        { value: 'progressture_process_kwp', label: 'Progressture Process KWP' },
        { value: 'send_lead_to_mhub', label: 'Send Lead to Mhub' },
        { value: 'send_notification_to_deal_owner', label: 'Send Notification to Deal Owner' },
        { value: 'transfer_lead_to_pipe', label: 'Transfer Lead to Pipe' },
        { value: 'create_chat_contact', label: 'Create Chat Contact' },
        { value: 'transfer_lead_to_diff_team', label: 'Transfer Lead to Diff Team' },
        { value: 'sendMtargetTemplateEmail', label: 'Send Mtarget Template Email' },
        { value: 'schedule_whatsapp_to_introducer', label: 'Schedule Whatsapp to Introducer' },
        { value: 'SF_SendLead', label: 'SF Send Lead' },
        { value: 'SF_SendLeadStaging', label: 'SF Send Lead Staging' },
    ];


    // GET FUNCTION --------------------------------------------------------
    const getWorkflowAction = () => {
        try {
            axios
                .get("ws/ws_workflow_action_setting.php", {
                    params: {
                        task: "getWorkflowActionList",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    setWorkflowActionList(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getUser = async () => {
        try {
            const res = await axios.get("ws/ws_user.php", {
                params: {
                    task: "4a",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    user_tag: ""
                }
            })
            const data = res.data;

            const transformedData = data.record.map(user => ({
                label: user.user_name,
                value: user.user_id
            }));

            setUser(transformedData);
        } catch {

        }
    }

    const getPipeline = async () => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            const data = res.data;

            if (data.status === 0) {
                const option = data.record.map(opt => ({
                    value: opt.pipeline_id,
                    label: opt.pipeline_title
                }))

                setPipeline(option);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const getChatInstance = async () => {
        try {
            const res = await axios.get("ws/ws_blueprint2.php", {
                params: {
                    task: "getChatInstance",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                const option = data.record.map(opt => ({
                    value: opt.chat_instance_id,
                    label: opt.instance_title
                }))

                setChatInstance(option);
            }
        } catch {

        }
    }

    const getAllPipelineStage = async () => {
        try {
            const res = await axios.get('ws/ws_pipeline.php', {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.pipeline_id,
                    label: record.pipeline_title,
                }));
                setPipelineID(recordOptions);

                let allStageRecords = [];

                for (const record of data.record) {
                    const csRecords = await getCSData(record.pipeline_id);
                    allStageRecords = allStageRecords.concat(csRecords);
                }

                var csOption = allStageRecords.map((record) => ({
                    value: record.cs_id,
                    label: record.cs_title,
                }));

                setCombinedStageData(csOption)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCSData = async (pipeid) => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getStages",
                    pipeid: pipeid,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                return data.record; // Return the records array
            } else {
                return [];
            }

        } catch {

        }
    }

    const getDsData = async () => {
        try {
            const res = await axios.get('ws/ws_source.php', {
                params: {
                    task: 2,
                    company: session.company_id
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.ds_id,
                    label: record.ds_title,
                    ds_token: record.ds_token,
                }));
                setDSOption(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getEmailTemplate = async () => {
        try {
            const res = await axios.get("ws/ws_email.php", {
                params: {
                    task: "getAllEmailTemplate",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.email_template_id,
                    label: record.email_template_title,
                }));
                setEmail(recordOptions);
            }
        } catch {

        }
    }

    const getCustomField = async () => {
        try {
            let originalData = [];

            const resDeal = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "deal"
                }
            });

            let data = resDeal.data;

            if (data.record) {
                originalData = data.record;
            }

            const resPerson = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                params: {
                    task: "5",
                    company: session.company_id,
                    area: "person"
                }
            });

            let data2 = resPerson.data;

            if (data2.record) {
                originalData = originalData.concat(data2.record);
            }

            var recordOptions = originalData.map((record) => ({
                value: record.cf_id,
                label: record.cf_label,
                area: record.cf_area,
            }));

            setCustomField(recordOptions);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // Grid Column Renderer

    const userRenderer = (params) => {
        const userid = params.data.user_id;
        const getUserLabel = user.find(u => u.value === userid);

        return getUserLabel ? getUserLabel.label : "-";
    }

    const pipelineRenderer = (params) => {
        const pipeid = params.data.pipe_id;
        const getPipelineLabel = pipeline.find(p => p.value === pipeid);

        return getPipelineLabel ? getPipelineLabel.label : "-";
    }

    const chatInstanceRenderer = (params) => {
        const chatid = params.data.chat_instance_id;
        const getChatInstanceLabel = chatInstance.find(p => p.value === chatid);

        return getChatInstanceLabel ? getChatInstanceLabel.label : "-";
    }

    const stageRenderer = (params) => {
        const csid = params.data.cs_id;
        const getCSLabel = combinedStageData.find(c => c.value === csid);

        return getCSLabel ? getCSLabel.label : "-";
    }


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        { headerName: "Action Title", field: "action_title", width: 300 },
        { headerName: "User", field: "user_id", width: 200, cellRenderer: userRenderer },
        { headerName: "Pipeline Title", field: "pipe_id", width: 300, cellRenderer: pipelineRenderer },
        { headerName: "Chat Instance", field: "chat_instance", width: 300, cellRenderer: chatInstanceRenderer },
        { headerName: "Stage", field: "cs_id", width: 250, cellRenderer: stageRenderer },
        { headerName: "Deal Source", field: "ds_token", width: 250, valueFormatter: (params) => params.value ? params.value : '-' },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get("ws/ws_workflow_action_setting.php", {
                params: {
                    task: "updateWorkflowAction",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    action_id: values.action_id,
                    action_title: values.action_title,
                    chat_instance_id: values.chat_instance_id,
                    cs_id: values.cs_id,
                    ds_token: values.ds_token,
                    email_template_id: values.email_template_id,
                    fixed_time: values.fixed_time,
                    param_1: values.param_1,
                    param_2: values.param_2,
                    param_3: values.param_3,
                    param_4: values.param_4,
                    param_5: values.param_5,
                    pipe_id: values.pipe_id,
                    secret_key: values.secret_key,
                    short_url_link: values.short_url_link,
                    short_url_title: values.short_url_title,
                    start_after_days: values.start_after_days,
                    start_after_minutes: values.start_after_minutes,
                    user_id: values.user_id,
                    user_tag: values.user_tag,
                }
            })

            const data = response.data;

            if (data.status === 0) {
                setShowModal(false);
                getWorkflowAction();
                Swal.fire({
                    icon: "success",
                    title: "Successfully Update",
                    text: "Successfully update a record!",
                    timer: 1500,
                });
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.action_id) {
                    sendDelete(params);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get('ws/ws_workflow_action_setting.php', {
                params: {
                    task: "deleteWorkflowAction",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    action_id: params.action_id
                }
            });

            if (response.data.status == '0') {
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getWorkflowAction();
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const response = await axios.get("ws/ws_workflow_action_setting.php", {
                params: {
                    task: "setWorkflowAction",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    action_title: values.action_title
                }
            });

            const data = response.data;

            if (data.status === 0) {
                setisAddWorkflow(false);
                getWorkflowAction();
                Swal.fire({
                    icon: "success",
                    title: "Successful",
                    text: "Successfully add a record!",
                    timer: 1500,
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };


    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getWorkflowAction();
            getUser();
            getPipeline();
            getChatInstance();
            getAllPipelineStage();
            getDsData();
            getEmailTemplate();
        }
    }, []);

    const toggleAddWorkflowModal = () => {
        setisAddWorkflow(prevState => !prevState);
    };

    const convertTo24HourFormat = (timeString) => {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);

        if (period === 'PM' && hours < 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddWorkflowModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Action
                    </button>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : workflowActionlist} rowHeight={85} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddWorkflow} onHide={toggleAddWorkflowModal} size="md">
                        <Formik
                            initialValues={{
                                action_title: "",
                            }}
                            validationSchema={addValidationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Action</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Action Title</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={fieldAction}
                                                value={values.action_title}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('action_title', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.action_title && !!errors.action_title}
                                            />
                                            {errors.action_title && touched.action_title && (
                                                <div className="op-error-message">{errors.action_title}</div>
                                            )}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                action_id: currentData ? currentData.action_id : "",
                                action_title: currentData ? currentData.action_title : "",
                                pipe_id: currentData ? currentData.pipe_id : "",
                                cs_id: currentData ? currentData.cs_id : "",
                                chat_instance_id: currentData ? currentData.chat_instance_id : "",
                                user_id: currentData ? currentData.user_id : "",
                                email_template_id: currentData ? currentData.email_template_id : "",
                                start_after_days: currentData && currentData.start_after_days !== 0 ? currentData.start_after_days : "",
                                start_after_minutes: currentData && currentData.start_after_minutes !== 0 ? currentData.start_after_minutes : "",
                                fixed_time: currentData ? currentData.fixed_time : "",
                                param_1: currentData ? currentData.param_1 : "",
                                param_2: currentData ? currentData.param_2 : "",
                                param_3: currentData ? currentData.param_3 : "",
                                param_4: currentData ? currentData.param_4 : "",
                                param_5: currentData ? currentData.param_5 : "",
                                secret_key: currentData ? currentData.secret_key : "",
                                ds_token: currentData ? currentData.ds_token : "",
                                short_url_link: currentData ? currentData.short_url_link : "",
                                short_url_title: currentData ? currentData.short_url_title : "",
                                user_tag: currentData ? currentData.user_tag : "",
                            }}
                            validationSchema={editValidationSchema}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Action Title</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={fieldAction}
                                                valueDefault={fieldAction.find(opt => opt.value === values.action_title)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('action_title', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.action_title && !!errors.action_title}
                                            />
                                            {errors.action_title && touched.action_title && (
                                                <div className="op-error-message">{errors.action_title}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Pipeline</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={pipeline}
                                                valueDefault={pipeline.find(opt => opt.value === values.pipe_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('pipe_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.pipe_id && !!errors.pipe_id}
                                            />
                                            {errors.pipe_id && touched.pipe_id && (
                                                <div className="op-error-message">{errors.pipe_id}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Stage</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={combinedStageData}
                                                valueDefault={combinedStageData.find(opt => opt.value === values.cs_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('cs_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.cs_id && !!errors.cs_id}
                                            />
                                            {errors.cs_id && touched.cs_id && (
                                                <div className="op-error-message">{errors.cs_id}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Chat Instance</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={chatInstance}
                                                valueDefault={chatInstance.find(opt => opt.value === values.chat_instance_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('chat_instance_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.chat_instance_id && !!errors.chat_instance_id}
                                            />
                                            {errors.chat_instance_id && touched.chat_instance_id && (
                                                <div className="op-error-message">{errors.chat_instance_id}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>User</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={user}
                                                valueDefault={user.find(opt => opt.value === values.user_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('user_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.user_id && !!errors.user_id}
                                            />
                                            {errors.user_id && touched.user_id && (
                                                <div className="op-error-message">{errors.user_id}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Email Template</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={email}
                                                valueDefault={email.find(opt => opt.value === values.email_template_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('email_template_id', selectedOption.value);
                                                }}
                                                isClearable
                                                isSearchable
                                                isInvalid={touched.email_template_id && !!errors.email_template_id}
                                            />
                                            {errors.email_template_id && touched.email_template_id && (
                                                <div className="op-error-message">{errors.email_template_id}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Start After Days</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="start_after_days"
                                                value={values.start_after_days}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                isInvalid={touched.start_after_days && !!errors.start_after_days}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.start_after_days}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Start After Minutes</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="start_after_minutes"
                                                value={values.start_after_minutes}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                isInvalid={touched.start_after_minutes && !!errors.start_after_minutes}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.start_after_minutes}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Fixed Time</Form.Label>
                                            <div>
                                                <Form.Control
                                                    type="time"
                                                    name="fixed_time"
                                                    value={values.fixed_time ? convertTo24HourFormat(values.fixed_time) : ''}
                                                    onChange={handleChange}
                                                    isInvalid={touched.fixed_time && !!errors.fixed_time}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fixed_time}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Param 1</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="param_1"
                                                value={values.param_1}
                                                onChange={handleChange}
                                            // isInvalid={touched.param_1 && !!errors.param_1}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.param_1}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Param 2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="param_2"
                                                value={values.param_2}
                                                onChange={handleChange}
                                            // isInvalid={touched.param_2 && !!errors.param_2}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.param_2}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Param 3</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="param_3"
                                                value={values.param_3}
                                                onChange={handleChange}
                                            // isInvalid={touched.param_3 && !!errors.param_3}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.param_3}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Param 4</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="param_4"
                                                value={values.param_4}
                                                onChange={handleChange}
                                            // isInvalid={touched.param_4 && !!errors.param_4}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.param_4}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Param 5</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="param_5"
                                                value={values.param_5}
                                                onChange={handleChange}
                                            // isInvalid={touched.param_5 && !!errors.param_5}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.param_5}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Deal Source</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="ds_token"
                                                value={values.ds_token}
                                                onChange={handleChange}
                                            // isInvalid={touched.ds_token && !!errors.ds_token}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.ds_token}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Secret Key</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="secret_key"
                                                value={values.secret_key}
                                                onChange={handleChange}
                                            // isInvalid={touched.secret_key && !!errors.secret_key}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.secret_key}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>User Tag</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="user_tag"
                                                value={values.user_tag}
                                                onChange={handleChange}
                                            // isInvalid={touched.user_tag && !!errors.user_tag}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.user_tag}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Short URL Link</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="short_url_link"
                                                value={values.short_url_link}
                                                onChange={handleChange}
                                            // isInvalid={touched.short_url_link && !!errors.short_url_link}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.short_url_link}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Short URL Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="short_url_title"
                                                value={values.short_url_title}
                                                onChange={handleChange}
                                            // isInvalid={touched.short_url_title && !!errors.short_url_title}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.short_url_title}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                </div>
            )}
        </Container>
    );
}

export default WorkflowAction;
