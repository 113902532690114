import { useContext, useState, useEffect, createContext } from "react";

import axios from "../api/axios";
import Loader from "../includes/Loader";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PermissionContext = createContext();

export const PermissionProvider = ({ params, children }) => {
  const { session } = useAuth();
  const location = useLocation();
  const [permission, setPermission] = useState({});
  const { curation_id, prospect_id } = params;
  const [permissionRule, setPermissionRule] = useState({
    company_id: session.company_id,
    date_time_create: "0000-00-00 00:00:00",
    inactive: "0",
    module_level_permission: "1",
    permission_matrix_module_id: "0",
    permission_module_id: "0",
    rules1: "1",
    rules2: "1",
    rules3: "1",
    rules4: "1",
    rules5: "1",
    rules6: "1",
    rules7: "1",
    rules8: "1",
    rules9: "1",
    rules10: "1",
    rules11: "1",
    rules12: "1",
    rules13: "1",
    rules14: "1",
    rules15: "1",
    rules16: "1",
    rules17: "1",
    rules18: "1",
    rules19: "1",
    rules20: "1",
    rules21: "1",
    rules22: "1",
    rules23: "1",
    rules24: "1",
    rules25: "1",
    rules26: "1",
    rules27: "1",
    rules28: "1",
    rules29: "1",
    rules30: "1",
    user_id: session.user_id,
    user_tag_id: "0",
  });

  const [settingsPermission, setSettingsPermission] = useState({});
  const [sidebarPermission, setSidebarPermission] = useState({});
  const [prospectPermission, setProspectPermission] = useState({});
  const [reportPermission, setReportPermission] = useState({});
  const [statisticPermission, setStatisticPermission] = useState({});
  const [modulesPermission, setModulesPermission] = useState({});
  const [userPermission, setUserPermission] = useState({});
  const [navbarPermission, setNavbarPermission] = useState({});
  const [bookingPermission, setBookingPermission] = useState({});

  useEffect(() => {
    const initData = async () => {
      try {
        var module_id = 0;
        switch (location.pathname) {
          case "/report-deal":
            module_id = 6;
            break;
          case "/deal/view/list":
          case "/deal/view/pipeline":
            module_id = 1;
            break;
          case "/activity/list":
          case "/activity/calendar":
            module_id = 2;
            break;
          case "/curation/list":
          case `/curation/${curation_id}`:
            module_id = 3;
            break;
          case `/prospect/${prospect_id}`:
            const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];
            module_id = gamuda.includes(Number(session.company_id)) ? 5 : 25;
            break;
          case `/report-deal`:
            module_id = 6;
            break;
          case `/report-contact`:
            module_id = 7;
            break;
          case `/report-activity`:
            module_id = 8;
            break;
          case `/report-productivity`:
            module_id = 9;
            break;
          case `/report-duplicate-lead`:
            module_id = 10;
            break;
          case `/report-transfer-lead`:
            module_id = 11;
            break;
          case `/report-lead-route`:
            module_id = 12;
            break;
          case `/report-lead-acceptance`:
            module_id = 13;
            break;
          case `/report-deal-performance`:
            module_id = 14;
            break;
          case `/report-deal-engagement`:
            module_id = 15;
            break;
          case `/report-marketing-analytics`:
            module_id = 16;
            break;
          case `/report-inbound-analytics`:
            module_id = 17;
            break;
          case `/report-schedule-deal`:
            module_id = 18;
            break;
          case `/settings/survey-settings`:
            module_id = 27;
            break;
          default:
            module_id = 0;
            break;
        }

        await getPermission(module_id);
        await getPermissionContainer();
      } catch (error) {
        console.error(error);
      }
    };

    initData();
  }, [location.pathname, curation_id, prospect_id]);

  const getPermission = async (module_id = 0) => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "getUserPermission",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          permission_module_id: module_id,
        },
      });

      const data = response.data;
      setPermission(data);
      if (Number(data.getUserPermission.status) === 0) {
        setPermissionRule(data.getUserPermission.record[0]);
      } else {
        setPermissionRule({
          company_id: session.company_id,
          date_time_create: "0000-00-00 00:00:00",
          inactive: "0",
          module_level_permission: "1",
          permission_matrix_module_id: "0",
          permission_module_id: "0",
          rules1: "1",
          rules2: "1",
          rules3: "1",
          rules4: "1",
          rules5: "1",
          rules6: "1",
          rules7: "1",
          rules8: "1",
          rules9: "1",
          rules10: "1",
          rules11: "1",
          rules12: "1",
          rules13: "1",
          rules14: "1",
          rules15: "1",
          rules16: "1",
          rules17: "1",
          rules18: "1",
          rules19: "1",
          rules20: "1",
          user_id: session.user_id,
          user_tag_id: "0",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissionContainer = async () => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "get_user_permission_container",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const default_record = {
        company_id: session.company_id,
        date_time_create: "0000-00-00 00:00:00",
        inactive: "0",
        module_level_permission: "1",
        permission_matrix_module_id: "0",
        permission_module_id: "0",
        rules1: "1",
        rules2: "1",
        rules3: "1",
        rules4: "1",
        rules5: "1",
        rules6: "1",
        rules7: "1",
        rules8: "1",
        rules9: "1",
        rules10: "1",
        rules11: "1",
        rules12: "1",
        rules13: "1",
        rules14: "1",
        rules15: "1",
        rules16: "1",
        rules17: "1",
        rules18: "1",
        rules19: "1",
        rules20: "1",
        rules21: "1",
        rules22: "1",
        rules23: "1",
        rules24: "1",
        rules25: "1",
        rules26: "1",
        rules27: "1",
        rules28: "1",
        rules29: "1",
        rules30: "1",
      };

      if (Number(data.status) === 0) {
        const settings_permission = data.record.find((item) => Number(item.permission_module_id) === 24);
        const sidebar_permission = data.record.find((item) => Number(item.permission_module_id) === 23);
        const contact_permission = data.record.find((item) => Number(item.permission_module_id) === 29);
        const report_permission = data.record.find((item) => Number(item.permission_module_id) === 31);
        const statistic_permission = data.record.find((item) => Number(item.permission_module_id) === 30);
        const modules_permission = data.record.find((item) => Number(item.permission_module_id) === 33);
        const user_permission = data.record.find((item) => Number(item.permission_module_id) === 32);
        const navbar_permission = data.record.find((item) => Number(item.permission_module_id) === 28);
        const booking_permission = data.record.find((item) => Number(item.permission_module_id) === 26);

        if (settings_permission) {
          setSettingsPermission(settings_permission);
        } else {
          setSettingsPermission(default_record);
        }

        if (sidebar_permission) {
          setSidebarPermission(sidebar_permission);
        } else {
          setSidebarPermission(default_record);
        }

        if (contact_permission) {
          setProspectPermission(contact_permission);
        } else {
          setProspectPermission(default_record);
        }

        if (report_permission) {
          setReportPermission(report_permission);
        } else {
          setReportPermission(default_record);
        }

        if (statistic_permission) {
          setStatisticPermission(statistic_permission);
        } else {
          setStatisticPermission(default_record);
        }

        if (modules_permission) {
          setModulesPermission(modules_permission);
        } else {
          setModulesPermission(default_record);
        }

        if (user_permission) {
          setUserPermission(user_permission);
        } else {
          setUserPermission(default_record);
        }

        if (navbar_permission) {
          setNavbarPermission(navbar_permission);
        } else {
          setNavbarPermission(default_record);
        }

        if (booking_permission) {
          setBookingPermission(booking_permission);
        } else {
          setBookingPermission(default_record);
        }
      } else {
        setSettingsPermission(default_record);
        setSidebarPermission(default_record);
        setProspectPermission(default_record);
        setReportPermission(default_record);
        setStatisticPermission(default_record);
        setModulesPermission(default_record);
        setUserPermission(default_record);
        setNavbarPermission(default_record);
        setBookingPermission(default_record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const contextData = { permission, permissionRule, settingsPermission, sidebarPermission, prospectPermission, reportPermission, statisticPermission, modulesPermission, userPermission, navbarPermission, bookingPermission };
  return <PermissionContext.Provider value={contextData}>{children}</PermissionContext.Provider>;
};

export const usePermission = () => {
  return useContext(PermissionContext);
};

export default PermissionContext;
