import { useState, useRef, useEffect, useCallback, forwardRef, useLayoutEffect, useMemo, memo } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Dropdown,
  Card,
  Nav,
  Accordion,
  useAccordionButton,
  CloseButton,
  Form,
  ToggleButton,
  ButtonGroup,
  Modal,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  ToggleButtonGroup,
  Image,
  Placeholder,
  Offcanvas,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faEllipsis,
  faStar,
  faChevronDown,
  faTriangleExclamation,
  faCircleXmark,
  faBrowser,
  faRobot,
  faPhoneHangup,
  faArrowRight,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBuildings,
  faCircle as faCircleLight,
  faPlus,
  faExpand,
  faRotateRight,
  faChevronRight,
  faCircleDollar,
  faStarExclamation,
  faStar as faStar2,
  faSparkles,
  faHandHoldingHand,
  faPencilAlt,
  faTrashAlt,
  faEarthAsia,
  faGlobe,
  faTimelineArrow,
} from "@fortawesome/pro-light-svg-icons";
import {
  faGlobePointer,
  faBriefcase,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faPenToSquare,
  faPhoneVolume,
  faRightLeft,
  faStopwatch,
  faUser,
  faUsers,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faPaperclipVertical,
  faEnvelope,
  faCalendarDay,
  faBarsStaggered,
  faBuilding,
  faShieldExclamation,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faFloppyDisk,
  faHouseBuilding,
  faHouseChimneyWindow,
  faHouse,
  faLocationDot,
  faBox,
  faMicrophone,
  faIndustryWindows,
  faBrowser as faBrowser2,
  faBullhorn,
  faClipboard,
  faList,
  faWandMagicSparkles,
  faCircleCheck as faCircleChecklight,
  faCircleXmark as faCircleXmarklight,
} from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { useNavigate, useParams } from "react-router-dom";
import { FilePond } from "react-filepond";
import { MobilePrefix, prefixList, setCurrency, setDateStr, setDateTimeStr } from "../lib/js/Function";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import "../lib/scss/op-deal-details.scss";
import "../lib/scss/op-step.scss";
import "../lib/scss/op-rating.scss";
import "react-vertical-timeline-component/style.min.css";
import "../lib/css/op-label.css";
import ReactHtmlParser from "html-react-parser";
import { FormSelectCreatable, FormCustomField, FormSelect, FormDate, FormCurrency, FormSelectV2 } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { debounce } from "lodash";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import ProductComponent from "./deal_action/ProductComponent";
import { Fab, Action } from "react-tiny-fab";
import AudioNotesV2 from "./ai_action/AudioNotesV2";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";
import MeetupLog from "./timeline_component/meetupLog";
import { io } from "socket.io-client";
import ReferralComponent from "./deal_action/ReferralComponent";
import LogoOP from "../assets/images/outperform-logo.png";
import LogoOP2 from "../assets/images/outperform_logo_menu2.svg";
import Draggable from "react-draggable";
import { Device } from "@twilio/voice-sdk";
import { parsePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";

const newDate = new Date();
const latestDate = moment(newDate).format("YYYY-MM-DD");

const durationList = [
  { label: "5 Mins", value: "5" },
  { label: "10 Mins", value: "10" },
  { label: "15 Mins", value: "15" },
  { label: "20 Mins", value: "20" },
  { label: "30 Mins", value: "30" },
  { label: "35 Mins", value: "35" },
  { label: "40 Mins", value: "40" },
  { label: "45 Mins", value: "45" },
  { label: "50 Mins", value: "50" },
  { label: "55 Mins", value: "55" },
  { label: "1 Hour", value: "60" },
  { label: "1 Hour 30 Mins", value: "90" },
  { label: "2 Hours", value: "120" },
  { label: "2 Hours 30 Mins", value: "150" },
  { label: "3 Hours", value: "180" },
  { label: "3 Hours 30 Mins", value: "210" },
  { label: "4 Hours", value: "240" },
];

const timings = [
  { label: "8:00", value: "8:00" },
  { label: "8:15", value: "8:15" },
  { label: "8:30", value: "8:30" },
  { label: "8:45", value: "8:45" },
  { label: "9:00", value: "9:00" },
  { label: "9:15", value: "9:15" },
  { label: "9:30", value: "9:30" },
  { label: "9:45", value: "9:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];

const DealDetails = () => {
  const [meetingDurationList, setMeetingDurationList] = useState([]);
  const [meetingTimingsList, setMeetingTimingsList] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { deal_id } = useParams();
  const { Formik, useFormikContext } = formik;
  const editorRef = useRef();
  const initialized = useRef(false);
  const containerRef = useRef(null);
  const [loading2, setLoading2] = useState(true);
  const [activeTab, setActiveTab] = useState("notes");
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [isActiveTabLoading, setIsActiveTabLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isContributor, setIsContributor] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isWon, setIsWon] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [isChangeProspect, setIsChangeProspect] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const { session, logoutUser } = useAuth();
  const [deal, setDeal] = useState(null);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const [statusOverdue, setStatusOverdue] = useState(false);
  const [booking, setBooking] = useState([]);
  const [lostReason, setLostReason] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [callAnalytics, setCallAnalytics] = useState([]);
  const [rating, setRating] = useState(null);
  const [hoverRating, setHoverRating] = useState(null);
  const [pipeBtn, setPipeBtn] = useState({ won: "", lost: "" });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [dealSource, setDealSource] = useState([]);
  const [isEditDeal, setIsEditDeal] = useState(false);
  const [isAddContributor, setIsAddContributor] = useState(false);
  const [isAddForm, setisAddForm] = useState(false);
  const [form, setForm] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [userActivity, setUserActivity] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [whatsappTemplate, setWhatsappTemplate] = useState([]);
  const [isEditActivity, setIsEditActivity] = useState(false);
  const [initEditActivity, setInitEditActivity] = useState(null);
  const [initEditMeeting, setInitEditMeeting] = useState(null);
  const [isTransferOwner, setIsTransferOwner] = useState(false);
  const [isEditNote, setisEditNote] = useState(false);
  const [initEditNotes, setInitEditNotes] = useState(null);
  const [isDeleteDeal, setIsDeleteDeal] = useState();
  const [width, setWidth] = useState("100%");
  const [leadScoreList, setLeadScoreList] = useState([]);
  const [isLeadScore, setIsLeadScore] = useState(false);
  const [isEditDealInfo, setIsEditDealInfo] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [isArchievedDeal, setIsArchievedDeal] = useState(false);
  const [dealProduct, setDealProduct] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isAddProduct, setisAddProduct] = useState(false);
  const [isCallLead, setIsCallLead] = useState(false);
  const [isOrganizationAllowEditContact, setIsOrganizationAllowEditContact] = useState(true);
  const [isProspectAllowEditContact, setIsProspectAllowEditContact] = useState(true);
  const [isFindOrganization, setIsFindOrganization] = useState(false);
  const [findOrganizationData, setFindOrganizationData] = useState([]);
  const [isEditOrganization, setIsEditOrganization] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [countryOrganizationList, setCountryOrganizationList] = useState([]);
  const [organizationUser, setOrganizationUser] = useState([]);
  const [isAddOrganization, setIsAddOrganization] = useState(false);
  const [meetupLink, setMeetupLink] = useState({});
  const [meetingData, setMeetingData] = useState({});
  const [isCancelMeetup, setIsCancelMeetup] = useState(false);
  const [cancelMeetupReasonList, setCancelMeetupReasonList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [isTimeline, setIsTimeline] = useState(true);
  const [dealProductV2, setDealProductV2] = useState([]);
  const [aiFollowUpData, setAiFollowUpData] = useState({ agentNotes: "", audioFilename: "", whatsappMessage: "" });
  const [modalLoading, setModalLoading] = useState(true);
  const [isCall, setIsCall] = useState(false);
  const [callPosition, setCallPosition] = useState({ x: 0, y: 0 });
  const [callMode, setCallMode] = useState(false);
  const [callStatus, setCallStatus] = useState("ringing");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isEditSource, setIsEditSource] = useState(false);
  const [isAiTimelineLoading, setIsAiTimelineLoading] = useState(false);
  const [aiTimelineSummaryData, setAiTimelineSummaryData] = useState({ extraNotes: "", timelineSummary: "" });
  const [isEditMeeting, setIsEditMeeting] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [smartLinkList, setSmartLinkList] = useState([]);
  const [leadScore, setLeadScore] = useState(0);
  const [leadScoreUpdate, setLeadScoreUpdate] = useState(false);
  const [firstTimeline, setFirstTimeline] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [isDuplicateList, setIsDuplicateList] = useState(false);
  const [duplicateLoadingBtn, setDuplicateLoadingBtn] = useState(false);
  const [duplicateRecord, setDuplicateRecord] = useState([]);
  const [prospectSession, setProspectSession] = useState({
    company_id: session.company_id,
    company_token: session.company_token,
    role_id: session.role_id,
    user_ac_token: session.user_ac_token,
    user_id: session.user_id,
    user_name: session.user_name,
  });
  const [isLoadingEditProspect, setIsLoadingEditProspect] = useState(false);
  const [isLoadingEditSummary, setIsLoadingEditSummary] = useState(false);
  const [isLoadingChangeProspect, setIsLoadingChangeProspect] = useState(false);
  const [isLoadingEditSource, setIsLoadingEditSource] = useState(false);
  const [isLoadingEditOrganization, setIsLoadingEditOrganization] = useState(false);
  const [isLoadingAddOrganization, setIsLoadingAddOrganization] = useState(false);
  const [isLoadingEditDeal, setIsLoadingEditDeal] = useState(false);
  const [timelineFilter, setTimelineFilter] = useState("all");
  const [isFindOrg, setIsFindOrg] = useState(false);
  const [customActivityList, setCustomActivityList] = useState([]);

  const [editProspectData, setEditProspectData] = useState({
    prospect_id: "",
    prospect_name: "",
    address1: "",
    address2: "",
    address3: "",
    postcode: "",
    designation: { label: "", value: "" },
    country: { label: "", value: "" },
    state: { label: "", value: "" },
    city: { label: "", value: "" },
    owner: { label: "", value: "" },
    deal_linked: false,
    contact: [],
    contact_prefix: [],
    email: [],
    cf_person: [],
  });

  const transferDealSchema = yup.object().shape({
    user: yup.string().required("User is required"),
  });

  const aiFollowUpSchema = yup.object().shape({
    remark: yup.string().required("Must have something to send to the person"),
  });

  const changeProspectSchema = yup.object().shape({
    prospect: yup.string().required(t("DealDetails_error_message")),
  });

  const editProspectContactSchema = yup.object().shape({
    contact_prefix: yup.string().test("contact_prefix", "Prefix is required", async function (value) {
      const { contact } = this.parent || {};
      try {
        if (contact && !value) {
          return this.createError({ message: "Prefix is required" });
        }
        return true;
      } catch (error) {
        return false;
      }
    }),
    contact: yup.string().test("contact", "Contact number format already exist", async function (value) {
      const { index, contact_prefix, contact_prefix_region } = this.parent || {};
      try {
        if (value && !contact_prefix) {
          return this.createError({ message: "Invalid contact number" });
        }

        if (value && value !== deal[`contact${index}`] && contact_prefix_region) {
          const phoneNumber = parsePhoneNumberFromString(value, contact_prefix_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid contact number" });
          }
        }

        if (value && value !== deal[`contact${index}`]) {
          const result = await debounceDuplicateContact(value, index);
          if (result === false) {
            return this.createError({ message: "Contact number already exist" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
  });

  const editProspectEmailSchema = yup.object().shape({
    email: yup.string().email("Invalid email address"),
  });

  const editProspectSchema = yup.object().shape({
    prospect_name: yup.string().required(t("DealDetails_error_message")),
    owner: yup.object().shape({ value: yup.string().required(t("DealDetails_error_message")) }),
    contact: yup.array().of(editProspectContactSchema),
    email: yup.array().of(editProspectEmailSchema),
  });

  const editSourceSchema = yup.object().shape({
    ds_id: yup.string().required(t("DealDetails_error_message")),
  });

  const addContributorSchema = yup.object().shape({
    user_id: yup.string().required(t("DealDetails_error_message")),
  });

  const addFormSchema = yup.object().shape({
    form_id: yup.string().required(t("DealDetails_error_message")),
  });

  const notesSchema = yup.object().shape({
    notes: yup.string().required(t("DealDetails_error_message")),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("DealDetails_error_message")),
    activity_user: yup.string().required(t("DealDetails_error_message")),
  });

  const addMeeetingSchema = yup.object().shape({
    activity_title: yup.string().required(t("DealDetails_error_message")),
    activity_user: yup.string().required(t("DealDetails_error_message")),
    activity_duration: yup.string().when("activity_type", {
      is: (val) => Number(val) === 2,
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("DealDetails_error_message")),
    activity_user: yup.string().required(t("DealDetails_error_message")),
  });

  const fileUploadSchema = yup.object().shape({
    file: yup.array().min(1, t("DealDetails_error_message")),
    title: yup.string().required(t("DealDetails_error_message")),
  });

  const addMessageschema = yup.object().shape({
    email_content: yup.string().required(t("DealDetails_error_message")),
    email_template: yup.string().when("message_type", {
      is: "email",
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
    whatsapp_template: yup.string().when("message_type", {
      is: "whatsapp",
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
    email_subject: yup.string().when(["message_type", "email_template"], {
      is: (messageType, emailTemplate) => messageType === "email" && emailTemplate,
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editNotesSchema = yup.object().shape({
    notes: yup.string().required(t("DealDetails_error_message")),
  });

  const editDealInfoSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealDetails_error_message")),
    deal_added: yup.string().required(t("DealDetails_error_message")),
    deal_close: yup.string().when("deal_archieve", {
      is: (value) => Number(session.deal_details_date_change) === 0 && (value === "won" || value === "lost"),
      then: yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editOrganizationSchema = yup.object().shape({
    organization_title: yup.string().required(t("DealDetails_error_message")),
  });

  const cancelMeetupSchema = yup.object().shape({
    reason: yup.string().required(t("DealDetails_error_message")),
  });

  const lostReasonSchema = yup.object().shape({
    lostReasonID: yup.string().when([], {
      is: () => Number(session.lost_reason_compulsory) === 1,
      then: () => yup.string().required("The field is required"),
    }),
  });

  // TWILIO SETUP ----------------------------------------------------------------

  const [twilioDevice, setTwilioDevice] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [twilioConn, setTwilioConn] = useState(null);
  const [callSID, setCallSID] = useState("");
  const [callTimer, setCallTimer] = useState("00:00");
  const [callStart, setCallStart] = useState("connect");
  const timeRef = useRef(0);
  const timerRef = useRef(null);

  const getTwilioToken = async (account_sid, api_key_sid, api_key_secret, outgoing_app_sid) => {
    const response = await axios.get("https://app.outperformhq.io:50303/api/twilio/token", {
      params: {
        identity: "userid_" + prospectSession.user_id,
        userid: prospectSession.user_id,
        account_sid: account_sid,
        api_key_sid: api_key_sid,
        api_key_secret: api_key_secret,
        outgoing_app_sid: outgoing_app_sid,
      },
    });

    const data = await response.data;
    return data.token;
  };

  const initializeTwilioDevice = async (account_sid, api_key_sid, api_key_secret, outgoing_app_sid) => {
    try {
      if (twilioDevice === null) {
        const token = await getTwilioToken(account_sid, api_key_sid, api_key_secret, outgoing_app_sid);
        const device = new Device(token);

        setTwilioDevice(device);

        // Listen for registered event
        device.on("registered", () => {});

        // Listen for error event
        device.on("error", (error) => {
          console.error("Twilio Device error:", error);
        });

        // Listen for incoming calls
        device.on("incoming", (call) => {});

        // Catch if the device fails to initialize
        device.on("ready", () => {});

        // Add a .catch for potential promise failures during initialization
        device
          .register()
          .then(() => console.log("Device registration successful"))
          .catch((error) => console.error("Device registration failed:", error));
      }
    } catch (error) {
      console.error("Error initializing Twilio Device:", error);
    }
  };

  const getTwilioCred = async () => {
    //call api to get twilio cred
    var account_sid = "";
    var api_key_sid = "";
    var api_key_secret = "";
    var outgoing_app_sid = "";

    try {
      const response = await axios.get("ws/ws_twilio.php", {
        params: {
          task: "getTwilioSettings",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        account_sid = response.data.record[0].account_sid;
        api_key_sid = response.data.record[0].api_key_sid;
        api_key_secret = response.data.record[0].api_key_secret;
        outgoing_app_sid = response.data.record[0].outgoing_app_sid;
        setTrackingNumber(response.data.record[0].twilio_number);
        initializeTwilioDevice(account_sid, api_key_sid, api_key_secret, outgoing_app_sid);
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const sendTwilioCallLead = async () => {
    let contact_person = deal.contact1;
    let country_code = deal.contact1_prefix === "" || deal.contact1_prefix === "60" ? "60" : "";

    if (contact_person === "") {
      return Swal.fire({
        icon: "warning",
        text: "The provided phone number is not valid and cannot be used to make a call.",
      });
    }

    if (country_code === "") {
      return Swal.fire({
        icon: "warning",
        text: "The prospect country code is not allowed to use this call method.",
      });
    }

    let num_to_call = "";
    let parsed_number = parsePhoneNumber(contact_person, "MY");
    if (parsed_number.isValid()) {
      num_to_call = `+${country_code}${parsed_number.nationalNumber}`;
    } else {
      return Swal.fire({
        icon: "warning",
        text: "The provided phone number is not valid and cannot be used to make a call.",
      });
    }

    setCallStatus("connecting");
    setCallStart("connect");
    timeRef.current = 0;
    timerRef.current = null;
    setIsCall(true);

    const params = { To: num_to_call };
    const connection_t = await twilioDevice.connect({ params });
    setTwilioConn(connection_t);

    connection_t.on("accept", (connection) => {
      const callSid = connection.parameters.CallSid;
      setCallSID(callSid);
      axios.get("ws/ws_twilio.php", {
        params: {
          task: "setTwilioCall",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          call_sid: callSid,
          prospect_id: deal.prospect_id,
          call_type: "outbound",
          caller_number: trackingNumber,
          answer_number: deal.contact1,
          tracking_number: trackingNumber,
        },
      });
    });

    connection_t.on("ringing", () => {
      setCallStatus("ringing");
    });

    connection_t.on("disconnect", () => {
      setCallStatus("disconnect");
      setCallStart("stop");
      setCallMode(false);
      setIsCall(false);
      setTimeout(() => {
        getTimeline(false, prospectSession, "sini");
      }, 10000);
    });

    connection_t.on("cancel", () => {
      setCallStatus("cancel");
    });
  };

  const sendHangUpCall = () => {
    setIsCall(false);
    setCallMode(false);
    if (twilioConn) {
      twilioConn.disconnect(); // Disconnect the call
      setTwilioConn(null); // Clear the connection
    }
  };

  const getCallStatus = (status) => {
    if (status === "connecting") {
      return "Connecting...";
    } else if (status === "ringing") {
      return "Ringing...";
    } else if (status === "disconnected") {
      return "Disconnected";
    } else if (status === "error") {
      return "Unavailable";
    } else if (status === "reject") {
      return "Unavailable";
    } else if (status === "accept") {
      return "Answered";
    }

    return "Connecting...";
  };

  // GET FUNCTION ----------------------------------------------------------------

  const getLeadScore = async (prospect, user_info) => {
    try {
      const response = await axios.get("ws/ws_lead_score.php", {
        params: {
          task: "getLeadScore",
          deal_id: deal_id,
          prospect_id: prospect,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const { scoreMatrixDetails } = response.data;
      let leadScoreCount = 0;

      if (scoreMatrixDetails && scoreMatrixDetails.length > 0) {
        for (let i = 0; i < scoreMatrixDetails.length; i++) {
          if (Number(scoreMatrixDetails[i].score) > 0 && Number(scoreMatrixDetails[i].score) <= Number(scoreMatrixDetails[i].maxScore)) {
            leadScoreCount += Number(scoreMatrixDetails[i].score);
          }
        }
      }

      setLeadScore(leadScoreCount);
    } catch (error) {
      console.error(error);
    }
  };

  const getDealLinkedUser = async () => {
    try {
      const response = await axios.get("ws/ws_linked_user.php", {
        params: {
          task: "GetDealLinkedUser",
          deal: deal_id,
          user_email: session.user_email,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setProspectSession(data.record);
        await getDeals(data.record, true);
        getUser(data.record);
        getCrmStatus(data.record);
        getLostReason(data.record);
        getBooking(data.record);
        getCountry(data.record);
        getState(data.record);
        getCity(data.record);
        getDealSource(data.record);
        getForm(data.record);
        getEmailTemplate(data.record);
        getWhatsappTemplate(data.record);
        getIndustry(data.record);
        getOrganizationCountry(data.record);
        getMeetup(data.record);
        getCancelMeetupReason(data.record);
        getSmartLink(data.record);
        getCustomActivity(data.record);
        if (session.layout_mode === "real-estate" || Number(session.product_feature) === 1) {
          getAllProduct(data.record);
          getProductV2(data.record);
        }

        getTimeline(false, data.record);
        setIsLoading(false);
        initialized.current = true;
      } else {
        setProspectSession(session);
        await getDeals(session, true);
        getUser(session);
        getCrmStatus(session);
        getLostReason(session);
        getBooking(session);
        getCountry(session);
        getState(session);
        getCity(session);
        getDealSource(session);
        getForm(session);
        getEmailTemplate(session);
        getWhatsappTemplate(session);
        getIndustry(session);
        getOrganizationCountry(session);
        getMeetup(session);
        getCancelMeetupReason(session);
        getSmartLink(session);
        if (session.layout_mode === "real-estate" || Number(session.product_feature) === 1) {
          getAllProduct(session);
          getProductV2(session);
        }

        getTimeline(false, session);
        setIsLoading(false);
        initialized.current = true;
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    }
  };

  const getDeals = async (user_info, firstload = false) => {
    console.log("getDeals", firstload);
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "getDealDetails",
          type: "view",
          deal: deal_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setDeal(data.record);
        setRating(Number(data.record.deal_priority));
        const contributor =
          Number(data.record.contributor_count) > 0 && data.record.contributor.find((record) => record.user_id === data.record.owner_id);
        setIsContributor(Number(data.record.contributor_count) > 0 && !contributor);
        if (Number(session.contributor_no_edit_contact) === 1) {
          if (Number(data.record.prospect_owner) !== Number(user_info.user_id)) {
            setIsProspectAllowEditContact(false);
          }

          if (Number(data.record.organization_owner) !== Number(user_info.user_id)) {
            setIsOrganizationAllowEditContact(false);
          }
        }
        setIsOwner(true);
        getAnalytics(data.record, user_info);
        getLeadScore(data.record.prospect_id, user_info);
        if (firstload) {
          getAiTimelineSummary(user_info, data.record.prospect_id);
        }
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    } finally {
      setImgError(false);
    }
  };

  const getUser = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          record.label = record.user_name + " (" + record.role_title + ")";
          record.value = record.user_id;
        });

        var userNotes = data.record.map((record) => ({
          label: record.label,
          value: record.value,
        }));

        userNotes.unshift({
          label: t("DealDetails_none"),
          value: "",
        });

        var userActivityRecord = data.record.map((record) => ({
          label: record.label,
          value: record.value,
        }));

        userActivityRecord.unshift({
          label: t("DealDetails_select_user"),
          value: "",
        });

        var organizationUserRecord = data.record.map((record) => ({
          label: record.label,
          value: record.value,
        }));

        setOrganizationUser(organizationUserRecord);
        setUserActivity(userActivityRecord);
        setUserNotes(userNotes);
        setUser(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCrmStatus = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: user_info.company_id,
          dealid: deal_id,
        },
      });

      const data = response.data;

      if (data.record) {
        setPipeBtn((prevState) => ({
          ...prevState,
          won: data.pipe_naming_won,
          lost: data.pipe_naming_lost,
        }));
        setStatus(data.record);
        data.record.forEach((record) => {
          if (Number(record.stageOverdue) > 1) {
            return setStatusOverdue(true);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLostReason = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_deal_close.php", {
        params: {
          task: "getLostReason",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          record.label = record.reason_title;
          record.value = record.lost_reason_id;
        });
        setLostReason(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBooking = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_rea.php", {
        params: {
          task: "BookingDeal",
          deal_id: deal_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setBooking(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAnalytics = async (deals, user_info) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "prospectHistory",
          prospect: deals.prospect_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      setAnalytics(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTimeline = async (socket = false, user_info) => {
    if (firstTimeline) {
      setFirstTimeline(false);
    } else {
      setLoadingTimeline(true);
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 13,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        if (data.record) {
          data.record.forEach((record) => {
            record.meetup_record_description = "";
          });

          const processedRecords = await Promise.all(
            data.record.map(async (activity, index) => {
              if (activity.record_type === "Activity" && Number(activity.record_activity_type_id) === 2) {
                const [meetupCancel, meetupReschedule] = await Promise.all([
                  getMeetupCancel(activity.record_activity_id, user_info),
                  getMeetupReschedule(activity.record_activity_id, user_info),
                ]);

                if (meetupCancel.status === 0 && Number(meetupCancel.record.cancel) === 1) {
                  let desc = `Cancelled by ${meetupCancel.record.cancel_user_name} on ${moment(new Date(meetupCancel.record.cancel_date_time)).format(
                    "LLL"
                  )}`;
                  if (meetupCancel.record.cancel_reason) {
                    desc += `\nReason: ${meetupCancel.record.cancel_reason}`;
                  }
                  if (meetupCancel.record.cancel_remark) {
                    desc += `\nRemark: ${meetupCancel.record.cancel_remark}`;
                  }
                  activity.meetup_record_description = desc;
                  activity.mad_show = false;
                  activity.meeting_scheduler = true;
                } else if (meetupReschedule.status === 0) {
                  if (meetupReschedule.record.length > 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    const previousMeetupRecord = meetupReschedule.record[1];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name} \nPrevious schedule on ${moment(
                        new Date(previousMeetupRecord.chosen_date + " " + previousMeetupRecord.chosen_time)
                      ).format("LLL")}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    }
                  } else if (meetupReschedule.record.length === 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    } else {
                      activity.meetup_record_description = "";
                    }
                  } else {
                    activity.meetup_record_description = "";
                  }
                  if (index === data.record.length - 1) {
                    activity.mad_show = true;
                  } else {
                    activity.mad_show = false;
                  }

                  activity.meeting_scheduler = true;
                } else {
                  activity.mad_show = true;
                  activity.meetup_record_description = "";
                  activity.meeting_scheduler = false;
                }

                activity.meetup_cancel = meetupCancel;
                activity.meetup_reschedule = meetupReschedule;
              } else {
                activity.meetup_record_description = "";
                activity.meeting_scheduler = false;
              }

              if (!initialized.current || socket) {
                if (
                  (activity.record_type === "Activity" || activity.record_type === "CustomActivity" || activity.record_type === "NoEditActivity") &&
                  activity.record_activity_type === "Call" &&
                  activity.record_file_url
                ) {
                  try {
                    if (socket) setCallAnalytics([]);
                    const sentimentAnalysis = await getSentimentAnalysis(activity, user_info);
                    if (Number(sentimentAnalysis.call_insight_status) === 1) {
                      const newCallInsight = {
                        record_activity_id: activity.record_activity_id,
                        analytics_param_1: sentimentAnalysis.analytics_param_1 || "",
                        analytics_param_2: sentimentAnalysis.analytics_param_2 || "",
                        analytics_param_3: sentimentAnalysis.analytics_param_3 || "",
                        analytics_param_4: sentimentAnalysis.analytics_param_4 || "",
                        analytics_param_5: sentimentAnalysis.analytics_param_5 || "",
                        analytics_param_6: sentimentAnalysis.analytics_param_6 || "",
                        analytics_param_7: sentimentAnalysis.analytics_param_7 || "",
                        analytics_param_8: sentimentAnalysis.analytics_param_8 || "",
                        analytics_param_9: sentimentAnalysis.analytics_param_9 || "",
                        analytics_param_10: sentimentAnalysis.analytics_param_10 || "",
                        analytics_field_1: sentimentAnalysis.analytics_field_1 || "",
                        analytics_field_2: sentimentAnalysis.analytics_field_2 || "",
                        analytics_field_3: sentimentAnalysis.analytics_field_3 || "",
                        analytics_field_4: sentimentAnalysis.analytics_field_4 || "",
                        analytics_field_5: sentimentAnalysis.analytics_field_5 || "",
                        analytics_field_6: sentimentAnalysis.analytics_field_6 || "",
                        analytics_field_7: sentimentAnalysis.analytics_field_7 || "",
                        analytics_field_8: sentimentAnalysis.analytics_field_8 || "",
                        analytics_field_9: sentimentAnalysis.analytics_field_9 || "",
                        analytics_field_10: sentimentAnalysis.analytics_field_10 || "",
                        ai_summary: sentimentAnalysis.ai_summary || "",
                        loading: false,
                      };
                      setCallAnalytics((prevCallAnalytics) => [...prevCallAnalytics, { ...newCallInsight }]);
                    }
                  } catch (error) {
                    console.error("Error fetching sentiment analysis:", error);
                  }
                }
              }

              activity.mad_show = false;
              return activity;
            })
          );

          const meetingRecord = processedRecords.filter((record) => Number(record.record_activity_type_id) === 2);
          if (meetingRecord && meetingRecord.length > 0) {
            const meetingRecordLatest = getLatestRecord(meetingRecord);
            processedRecords.forEach((record) => {
              if (record.record_activity_id === meetingRecordLatest.record_activity_id) {
                record.mad_show = true;
              }
            });
          }

          setTimeline(processedRecords);
        } else {
          setTimeline([]);
        }
      } else {
        setTimeline([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
      setIsTimeline(true);
      setLoadingTimeline(false);
    }
  };

  function getLatestRecord(records) {
    if (records.length === 0) return null;

    return records.reduce((latest, record) => {
      const latestDate = new Date(latest.record_date_time_created);
      const currentDate = new Date(record.record_date_time_created);
      return currentDate > latestDate ? record : latest;
    });
  }

  const getMeetupCancel = async (activity_id, user_info) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupInfo",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetupReschedule = async (activity_id, user_info) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupRescheduleLog",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getSentimentAnalysis = (activity, user_info) => {
    return new Promise((resolve, reject) => {
      axios
        .get("ws/ws_sentiment_analysis.php", {
          params: {
            task: "get_call_insight_timeline_v2",
            utoken: user_info.user_ac_token,
            ctoken: user_info.company_token,
            call_data_id: activity.record_activity_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getExistingProspect = async (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectsForListbox",
          company: prospectSession.company_id,
          uid: prospectSession.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = Object.values(data.record)
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
            }));

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getExistingDesignation = async (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: prospectSession.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getDuplicateContact = async (contact, index) => {
    let isDuplicateExists;

    const contactFields = [`contact1`, `contact2`, `contact3`, `contact4`];
    const fieldToCheck = contactFields[index - 1];

    if (index >= 1 && index <= 3 && deal[fieldToCheck] === contact) {
      return true;
    }

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          task: "13",
          company: prospectSession.company_id,
          // people: deal.prospect_id,
          user: prospectSession.user_id,
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) === 0;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const getDealSource = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_source.php", {
        params: {
          task: "getAllSource_show_mobile",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.ds_title,
          value: record.ds_id,
        }));

        setDealSource(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getForm = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_custom_form.php", {
        params: {
          task: "getAllCustomForms",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.custom_form.map((record) => ({
          label: record.form_title,
          value: record.custom_form_id,
        }));

        setForm(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debounceDuplicateContact = debounce(getDuplicateContact, 1000);

  const getCountry = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.country_title,
        value: record.country_id,
      }));
      setCountry(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getState = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;

      const options = data.record.map((item) => ({
        label: item.state_title,
        value: item.state_id,
        country: item.country_id,
      }));
      setState(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getCity = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.city_title,
        value: record.city_id,
        state: record.state_id,
      }));
      setCity(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmailTemplate = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_email.php", {
        params: {
          task: "getEmailTemplate",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOption = data.record.map((record) => ({
          label: record.email_template_title,
          value: record.email_template_id,
        }));

        setEmailTemplate(recordOption);
      } else {
        setEmailTemplate([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWhatsappTemplate = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_custom_interaction.php", {
        params: {
          task: "getWhatsappInteractions",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          record.interaction_title = record.interaction_title.replace("\n", "%0a");
        });

        const recordOption = data.record.map((record) => ({
          label: record.interaction_name,
          value: record.custom_interaction_id,
          template: record.interaction_title,
        }));

        setWhatsappTemplate(recordOption);
      } else {
        setWhatsappTemplate([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProduct = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          task: "getDealProducts",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const updatedProducts = data.product.map((record) => ({
          deal_product_id: record.deal_product_id,
          deal_id: record.deal_id,
          product_id: record.product_id,
          total_qty: record.total_qty,
          product_title: record.product_title,
          discount: record.discount,
          remark: record.remark,
          final_price: record.final_price,
          serial_num: record.serial_num,
          product_price: (Number(record.final_price) + Number(record.discount)) / Number(record.total_qty),
        }));

        setCurrentUser(response);
        setProductCount(data.totalProduct);
        setDealProduct(updatedProducts);
      } else {
        setCurrentUser(response);
        setDealProduct([]);
        setProductCount(0);
      }
    } catch (error) {
      console.error(error);
      setDealProduct([]);
      setProductCount(0);
    }
  };

  const getProductV2 = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          task: "getDealProductsV2",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (data?.product && data?.product.length > 0) {
          const updatedProducts = data.product.map((record) => ({
            deal_product_id: record.deal_product_id,
            deal_id: record.deal_id,
            product_id: record.product_id,
            total_qty: record.total_qty,
            product_title: record.product_title,
            discount: record.discount,
            remark: record.remark,
            final_price: record.final_price,
            serial_num: record.serial_num,
            product_price: record.final_price,
            commission: record.commission,
            default_price: record.default_price,
            product_cat_id: record.product_cat_id,
          }));

          setProductCount(data.totalProduct);
          setDealProduct(updatedProducts);
        } else {
          setProductCount(0);
          setDealProduct([]);
        }
      } else {
        setProductCount(0);
        setDealProduct([]);
      }
    } catch (error) {
      console.error(error);
      setDealProductV2([]);
    }
  };

  const getAllProduct = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          bu_id: 0,
          ctoken: user_info.company_token,
          task: "getAllProducts",
          utoken: user_info.user_ac_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setProductList(data.prod_cat);
      }
    } catch {
      setProductCount(0);
    }
  };

  const getOrganizationData = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_gplaces.php", {
        params: {
          task: "getPlaces",
          placeTitle: encodeURIComponent(deal.organization_title),
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        setFindOrganizationData(data.record);
        setIsFindOrganization(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIndustry = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "getOrganizations",
          company_id: user_info.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.in_title,
          value: record.in_id,
        }));

        setIndustryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationCountry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 10,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.country_nicename,
          value: record.country_id,
        }));
        setCountryOrganizationList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetup = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupLink",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
          deal_id: deal_id,
        },
      });

      const data = response.data;
      setMeetupLink(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCancelMeetupReason = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupCancelReason",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOption = data.record.map((record) => ({
          label: record.cancel_reason,
          value: record.cancel_reason_id,
        }));

        setCancelMeetupReasonList(recordOption);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingAvailability = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkMeetingAvailability",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          duration: values.activity_duration,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const findRecord = data.record.find((record) => record.activity_mark_as_done === "no");
        if (findRecord) {
          const newDate = new Date(findRecord.activity_dated + " " + findRecord.activity_timed);
          const momentDate = moment(newDate).format("LLL");
          Swal.fire({
            icon: "warning",
            text: `You have an existing meeting scheduled with ${findRecord.prospect_name} on ${momentDate}. Kindly arrange an alternative date and time for this meeting.`,
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingDealStatus = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkActiveMeeting",
          deal_id: deal_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        return true;
      } else {
        Swal.fire({
          icon: "warning",
          text: `You have an active meeting scheduled in this deal.`,
        });
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAiFollowUp = async () => {
    setModalLoading(true);
    try {
      const formData = new FormData();
      formData.append("task", "getAIFollowUp");
      formData.append("deal_id", deal_id);
      formData.append("prospect_id", deal.prospect_id);
      formData.append("utoken", prospectSession.user_ac_token);
      formData.append("ctoken", prospectSession.company_token);
      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/ws/ws_ai_notes.php`, formData);
      const data = response.data;
      if (Number(data.status) === 0) {
        data.whatsappMessage = ReactHtmlParser(data.whatsappMessage);
        setAiFollowUpData(data);
      } else {
        setAiFollowUpData({
          agentNotes: "",
          audioFilename: "",
          whatsappMessage: "",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const getAiTimelineSummary = async (user_info, prospect_id) => {
    setIsAiTimelineLoading(true);
    try {
      const formData = new FormData();
      formData.append("deal_id", deal_id);
      formData.append("prospect_id", prospect_id);
      formData.append("utoken", user_info.user_ac_token);
      formData.append("ctoken", user_info.company_token);
      formData.append("task", "getAITimelineSummary");

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/ws/ws_ai_notes.php`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "PHPSESSID=bnvfehdifa5b15mk4bdq4vteg6",
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setAiTimelineSummaryData(data);
      } else {
        setAiTimelineSummaryData({ extraNotes: "Please contact our support team for assistance.", timelineSummary: "Something went wrong" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (firstTime) {
        setFirstTime(false);
        setTimeout(() => {
          setIsAiTimelineLoading(false);
        }, 500);
      } else {
        setIsAiTimelineLoading(false);
      }
    }
  };

  const getSmartLink = async (user_info) => {
    try {
      const response = await axios.get(`${session.hostUrl}/docs/smart_link_api.php`, {
        params: {
          task: "getLinks",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setSmartLinkList(data.record.map((record) => ({ label: record.smart_link_title, value: record.smart_link_template_id, ...record })));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDuplicateList = async (prospect) => {
    setIsDuplicateList(true);
    setDuplicateLoadingBtn(true);
    try {
      const response = await axios.get("ws/ws_deal_duplicate.php", {
        params: {
          task: "GetDuplicateContact",
          prospect: prospect,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setDuplicateRecord(data.record);
      } else {
        setDuplicateRecord([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDuplicateLoadingBtn(false);
    }
  };

  const getExistOrg = (inputValue, callback) => {
    setIsFindOrg(false);
    axios
      .get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: session.company_id,
          q: inputValue,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (Number(data.status) === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.organization_id) > 0;
            })
            .map((record) => ({
              label: record.organization_title,
              value: record.organization_id,
              customLabel: "",
              organization_address: record.organization_address ? record.organization_address : "",
              organization_web_url: record.organization_web_url ? record.organization_web_url : "",
              organization_contact: record.organization_contact ? record.organization_contact : "",
              organization_country_id: record.organization_country_id ? record.organization_country_id : "",
              in_id: record.in_id ? record.in_id : "",
            }));

          var newOptions = data.record.find((record) => Number(record.organization_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              organization_address: "",
              organization_web_url: "",
              organization_contact: "",
              organization_country_id: "",
              in_id: "",
            });
          }

          callback(options);
          setIsFindOrg(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindOrg(true);
        }
      });
  };

  const getCustomActivity = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 41,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setCustomActivityList(data.record.map((record) => ({ label: record.ca_label, value: record.ca_id })));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION -----------------------------------------------------------

  const onchangeActionTabs = (key) => {
    setIsActiveTabLoading(false);
    setDisabledBtn(false);
    setIsSubmitting(false);
    setActiveTab(key);

    if (key === "ai_follow_up") {
      setIsActiveTab(true);
      getAiFollowUp();
    } else {
      if (isActiveTab) {
        setIsActiveTabLoading(true);
        setTimeout(() => {
          setIsActiveTabLoading(false);
        }, 500);
      }
    }

    // setIsActiveTab(false);
  };

  const onchangePriority = (priority) => {
    if ((deal.deal_archieve === "" && Number(deal.owner_id) === Number(prospectSession.user_id)) || Number(prospectSession.role_id) === 1) {
      setRating(priority);
      axios.get("ws/ws_deal.php", {
        params: {
          task: 34,
          priority: priority,
          deal: deal_id,
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_you_dont_have_permission_to_do_this_action"),
      });
    }
  };

  const onchangeStage = async (stage) => {
    const currentStageIndex = deal.cs_index;
    const currentStageId = deal.cs_id;
    setDeal((prev) => ({ ...prev, cs_index: stage.cs_index, cs_id: stage.cs_id }));

    try {
      const response = await axios.get("ws/ws_stage.php", {
        params: {
          task: "checkStageEnabled",
          csid: stage.cs_id,
          dealid: deal_id,
          prospectid: deal.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await sendMoveStage(stage, currentStageIndex, currentStageId);
      } else {
        setDeal((prev) => ({ ...prev, cs_index: currentStageIndex, cs_id: currentStageId }));
        var element = "";
        data.record.forEach((record) => {
          element += `<li style="text-align: left; font-size: 13px; margin-bottom: 10px;">${record.title}</li>`;
        });

        Swal.fire({
          icon: "warning",
          html: `
            <p style="text-align: center; font-size: 14px;">The stage is currently locked. To proceed, please ensure that the following conditions are met:</p>
             <ul>${element}</ul>
          `,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ---------------------------------------------------------------

  const sendMoveStage = async (stage, currentStageIndex, currentStageId) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 7,
          user: prospectSession.user_id,
          deal: deal_id,
          status: stage.cs_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await getTimeline(false, prospectSession);
      } else {
        setDeal((prev) => ({ ...prev, cs_index: currentStageIndex, cs_id: currentStageId }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendTransferDeal = async (values) => {
    setDeal((prev) => ({ ...prev, owner_id: values.user, prospect_owner: values.user }));
    setIsTransferOwner(false);

    try {
      await axios.get("ws/ws_deal.php", {
        params: {
          task: "transferOwnership",
          deal: deal_id,
          user: values.user,
          transferoption: 2,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      getTimeline(false, prospectSession);
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_deal_has_been_transferred"),
        timer: 2000,
      });

      if (Number(prospectSession.user_id) !== Number(values.user)) {
        await sendTransferDealInfo(values);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendTransferDealInfo = async (values) => {
    try {
      await axios.get("ws/ws_system.php", {
        params: {
          task: 5,
          action: 1,
          type: "deal",
          type_id: deal_id,
          sender: prospectSession.user_id,
          sender_type: prospectSession.role_id,
          receiver: values.user,
          receiver_type: values.role_id,
          company: prospectSession.company_id,
          prefix: "transfer",
        },
      });

      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_deal_has_been_transferred"),
        timer: 2000,
      });

      setIsTransferOwner(false);
      getDeals(prospectSession);
      getTimeline(false, prospectSession);
    } catch (error) {
      console.error(error);
    }
  };

  const sendDealWon = async (values) => {
    let productCount = 0;
    if (Number(deal.final_conversion) === 1) {
      productCount = productCount;
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: prospectSession.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: prospectSession.company_id,
          lostReasonID: "",
          productsAdded: productCount,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        getAnalytics(prospectSession);
        getTimeline(false, prospectSession);
        setIsWon(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_has_been_marked_as_won"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDealLost = async (values) => {
    let productCount = 0;
    if (Number(deal.final_conversion) === 1) {
      productCount = productCount;
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: prospectSession.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: prospectSession.company_id,
          lostReasonID: values.lostReasonID,
          productsAdded: productCount,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        getAnalytics(prospectSession);
        getTimeline(false, prospectSession);
        setIsLost(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_has_been_marked_as_lost"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteDeal = async (values) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: prospectSession.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: prospectSession.company_id,
          lostReasonID: "",
          productsAdded: "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsDeleteDeal(false);
        getDeals(prospectSession);
        getAnalytics(prospectSession);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          text: t("DealDetails_the_deal_has_been_deleted"),
          timer: 1500,
        });
      } else {
        setIsDeleteDeal(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendReopenDeal = async () => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "reopenDeal",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          user: prospectSession.user_id,
          deal: deal_id,
          productsAdded: productCount,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getAnalytics(prospectSession);
        getDeals(prospectSession);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_successfully_reopen"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendChangeProspect = async (values) => {
    setIsLoadingChangeProspect(true);
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "changeDealProspect",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          dealid: deal_id,
          prospectid: values.prospect,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        Swal.fire({
          icon: "success",
          text: t("DealDetails_prospect_updated_successfully_changes_have_been_applied"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: t("DealDetails_the_prospect_chosen_has_not_been_changed"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingChangeProspect(false);
      setIsChangeProspect(false);
    }
  };

  const sendEditProspect = async (values) => {
    setIsLoadingEditProspect(true);
    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var prospect_id = Array(customField.length).fill(Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : deal.prospect_id);

    var fd_values = customField.map((record) => {
      if (Array.isArray(record.fd_values)) {
        var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
        return filteredValues.length > 0 ? filteredValues.join("|") : null;
      } else {
        return record.fd_values;
      }
    });

    try {
      await sendEditProspectData(values);
      if (customField.length > 0) {
        await sendEditProspectCF(values, cf_id, cf_label, prospect_id, fd_values);
      }

      const response_prospect_extra = await sendEditProspectExtra(values);
      const status = response_prospect_extra ? 0 : 1;

      if (status === 0) {
        getDeals(prospectSession);
        setIsEditProspect(false);
        return Swal.fire({
          icon: "success",
          text: t("DealDetails_the_prospect_has_been_updated_successfully"),
          timer: 1500,
        });
      } else {
        getDeals(prospectSession);
        setIsEditProspect(false);
        setIsLoadingEditProspect(false);
        return Swal.fire({
          icon: "error",
          text: "Prospect updated successfuly but we are unable to update prospect contact details, we are detecting duplicate contact details",
        });
      }
    } catch (error) {
      console.error(error);
      setIsLoadingEditProspect(false);
    }
  };

  const sendEditProspectData = async (values) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: values.designation.label,
          des: values.designation.value,
          name: values.prospect_name,
          organization: deal?.organization_id ? deal?.organization_id : "",
          prospect_address: "",
          prospect: Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : deal.prospect_id,
          owner_id: values.owner.value,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectExtra = async (values) => {
    const contacts = values.contact.map((contact) => ({ contact: contact.contact, contactPrefix: contact.contact_prefix }));
    const contact1 = contacts[0] ? contacts[0] : { contact: "", contactPrefix: "" };
    const contact2 = contacts[1] ? contacts[1] : { contact: "", contactPrefix: "" };
    const contact3 = contacts[2] ? contacts[2] : { contact: "", contactPrefix: "" };

    const emails = values.email.map((email) => email.email);
    const email1 = emails[0] || "";
    const email2 = emails[1] || "";

    try {
      const response = await axios.get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : deal.prospect_id,
          contact1_prefix: contact1.contactPrefix,
          contact1: contact1.contact,
          contact1_old: deal.contact1,
          contact2_prefix: contact2.contactPrefix,
          contact2: contact2.contact,
          contact3_prefix: contact3.contactPrefix,
          contact3: contact3.contact,
          email1: email1,
          email2: email2,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.state && values.state?.value ? values.state.value : "",
          city_id: values.city && values.city?.value ? values.city.value : "",
          postcode: values.postcode,
          country_id: values.country && values.country?.value ? values.country.value : "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectCF = async (values, cf_id, cf_label, prospect_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCFV2",
          record: prospect_id.join("[-0-]"),
          hq_prospect_id: Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : "",
          cf_label: cf_label.join("[-0-]"),
          cf: cf_id.join("[-0-]"),
          value: fd_values.join("[-0-]"),
          user_id: prospectSession.user_id,
          company_id: prospectSession.company_id,
          secret_key: prospectSession.company_token,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditDeal = async (values) => {
    setIsLoadingEditDeal(true);
    var customField = values.cf_deal;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var deal_id = Array(customField.length).fill(deal.deal_id);
    var fd_values = customField.map((record) => {
      if (Array.isArray(record.fd_values)) {
        var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
        return filteredValues.length > 0 ? filteredValues.join("|") : null;
      } else {
        return record.fd_values;
      }
    });

    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCFV2",
          secret_key: prospectSession.company_token,
          value: fd_values.join("[-0-]"),
          cf: cf_id.join("[-0-]"),
          record: deal_id.join("[-0-]"),
          label: cf_label.join("[-0-]"),
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        Swal.fire({
          icon: "success",
          text: t("DealDetails_the_prospect_has_been_updated_successfully"),
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_there_is_some_of_information_has_been_updated_but_there_is_something_wrong_with_your_entry_please_try_again"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEditDeal(false);
      setIsEditDeal(false);
    }
  };

  const sendAddContributor = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "addDealContributor",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          dealid: deal.deal_id,
          userid: values.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            text: t("DealDetails_the_contributer_addition_is_success"),
            timer: 1500,
          });
          getDeals(prospectSession);
          setIsAddContributor(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_the_user_chosen_has_already_been_added_to_this_deal"),
          });
          getDeals(prospectSession);
          setIsAddContributor(false);
        }
      });
  };

  const sendAddForm = (values) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "addCustomFormV2",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          deal: deal.deal_id,
          custom_form_id: values.form_id,
          custom_form_title: values.form_title,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            text: t("DealDetails_the_form_addition_is_success"),
            timer: 1500,
          });
          getDeals(prospectSession);
          setisAddForm(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_the_form_chosen_has_already_been_added_to_this_deal"),
          });
          getDeals(prospectSession);
          setisAddForm(false);
        }
      });
  };

  const sendNewNotes = async (values) => {
    setDisabledBtn(true);
    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.notes = values.notes.replace(images[index], newImgTag);
      });
    }

    values.notes = values.notes.replace(/^(<div>|<p>|<\/div>|<\/p>)+|(<div>|<p>|<\/div>|<\/p>)+$/g, "");

    const formData = new FormData();
    formData.append("task", "postNote");
    formData.append("region", session.country);
    formData.append("content", values.notes);
    formData.append("notify_user", values.user);
    formData.append("deal", deal_id);
    formData.append("utoken", prospectSession.user_ac_token);
    formData.append("ctoken", prospectSession.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_notes"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddActivity = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addNewActivity",
          region: session.country,
          create_user: prospectSession.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: values.ca_id,
          assign_user: values.activity_user,
          deal: deal_id,
          people: deal.prospect_id,
          organization: "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (values.google_calendar) {
          const date = moment(values.activity_date, "DD-MM-YYYY");
          const newDateStart = moment(date.format("YYYY-MM-DD") + " " + values.activity_type, "YYYY-MM-DD HH:mm");
          const dateStart = newDateStart.toISOString();
          const formattedDateStart = dateStart.substring(0, 19) + "Z";
          const duration = values.activity_duration ? parseInt(values.activity_duration.match(/\d+/)[0]) : 60;
          const newDateEnd = newDateStart.clone().add(duration, "minutes");
          const dateEnd = newDateEnd.toISOString();
          const formattedDateEnd = dateEnd.substring(0, 19) + "Z";
          const title = values.activity_title + " with " + deal.prospect_name;
          const googleCalendarLink =
            "https://www.google.com/calendar/render?action=TEMPLATE&text=" +
            encodeURIComponent(title) +
            "&details=" +
            encodeURIComponent(values.activity_notes) +
            "&location=&dates=" +
            formattedDateStart +
            "/" +
            formattedDateEnd;
          window.open(googleCalendarLink);
        }
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);
        setIsSubmitting(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
      setIsActiveTab(false);
      setIsSubmitting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddMeetingScheduler = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const checkDealMeeting = await getMeetingDealStatus();
      if (!checkDealMeeting) {
        setIsSubmitting(false);
        return;
      }

      const checkMeetingAvailability = await getMeetingAvailability(values);
      if (!checkMeetingAvailability) {
        setIsSubmitting(false);
        return;
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addNewActivity",
          region: session.country,
          create_user: prospectSession.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: "",
          assign_user: values.activity_user,
          deal: deal_id,
          people: deal.prospect_id,
          organization: "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (values.google_calendar) {
          const date = moment(values.activity_date, "DD-MM-YYYY");
          const newDateStart = moment(date.format("YYYY-MM-DD") + " " + values.activity_type, "YYYY-MM-DD HH:mm");
          const dateStart = newDateStart.toISOString();
          const formattedDateStart = dateStart.substring(0, 19) + "Z";
          const duration = values.activity_duration ? parseInt(values.activity_duration.match(/\d+/)[0]) : 60;
          const newDateEnd = newDateStart.clone().add(duration, "minutes");
          const dateEnd = newDateEnd.toISOString();
          const formattedDateEnd = dateEnd.substring(0, 19) + "Z";
          const title = values.activity_title + " with " + deal.prospect_name;
          const googleCalendarLink =
            "https://www.google.com/calendar/render?action=TEMPLATE&text=" +
            encodeURIComponent(title) +
            "&details=" +
            encodeURIComponent(values.activity_notes) +
            "&location=&dates=" +
            formattedDateStart +
            "/" +
            formattedDateEnd;
          window.open(googleCalendarLink);
        }

        setLeadScoreUpdate(true);
        sendCreateMeetup(values, data.record);
      } else {
        setIsActiveTab(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
      setIsActiveTab(false);
      setIsSubmitting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddFile = async (values) => {
    setDisabledBtn(true);
    const formData = new FormData();
    formData.append("deal_id", deal_id);
    formData.append("user_id", prospectSession.user_id);
    formData.append("file_title", values.title);
    formData.append("file", values.file[0]);

    try {
      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_file.php`, formData);
      const data = response.data;

      if (data.status === 0) {
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);

        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_upload_the_file"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);

        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
    }
  };

  const sendAddMessages = (values) => {
    setDisabledBtn(true);
    if (values.message_type === "email") {
      sendEmail(values);
    } else {
      if (values.smartlink !== "") {
        sendSmartLink(values);
      } else {
        sendWhatsapp(values);
      }
    }
  };

  const sendEmail = async (values) => {
    try {
      let receiverEmail = "";
      if (deal.email1) {
        receiverEmail = deal.email1;
      } else if (deal.email2) {
        receiverEmail = deal.email2;
      } else if (deal.prospect_email_info && deal.prospect_email_info.length > 0) {
        receiverEmail = deal.prospect_email_info[0].email_title;
      }

      let modifiedEmailContent = values.email_content
        .split("../textarea_upload/")
        .join(`${session.hostUrl}/${session.hostUrlType}/textarea_upload/`)
        .split("../../ext/")
        .join(`${session.hostURL}/${session.hostUrlType}/ext/`)
        .split("../")
        .join(`${session.hostURL}/${session.hostUrlType}`);

      const formData = new FormData();
      formData.append("task", "sendEmail");
      formData.append("emailContent", modifiedEmailContent);
      formData.append("deal", deal_id);
      formData.append("receiverEmail", receiverEmail);
      formData.append("receiverName", deal.prospect_name);
      formData.append("subject", values.email_subject);
      formData.append("utoken", prospectSession.user_ac_token);
      formData.append("ctoken", prospectSession.company_token);
      await axios.post("ws/ws_email_post.php", formData);
      setLeadScoreUpdate(true);
      setIsActiveTab(false);
      getTimeline(false, prospectSession);
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_the_email_has_been_scheduled"),
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendWhatsapp = async (values, smartLinkHash = "") => {
    let numToContact = "";
    if (deal.contact1) {
      numToContact = deal.contact1;
    } else if (deal.contact2) {
      numToContact = deal.contact2;
    } else if (deal.prospect_contact_count > 0) {
      numToContact = deal.prospect_contact_info[0].contact_num;
    } else {
      Swal.fire({
        icon: "warning",
        text: "There is no contact number for this prospect",
        timer: 20000,
      });
    }

    if (numToContact.charAt(0) === "0") {
      numToContact = "6" + numToContact;
    }

    numToContact = numToContact.replace(/-/g, "");
    numToContact = numToContact.replace(/ /g, "");

    var message = values.email_content.replace(/<[^>]*>/g, "");
    if (smartLinkHash !== "") {
      message = message + "\n\n" + `${session.hostUrl}/docs/i/${smartLinkHash}`;
    }

    // const whatsapp = `https://wa.me/${numToContact}?text=${message}`;

    // window.open(whatsapp);

    window.open(`https://web.whatsapp.com/send?phone=${numToContact}&text=${message}`);

    try {
      await axios.get("ws/ws_custom_interaction.php", {
        params: {
          task: "setInteraction",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          interaction_id: values.whatsapp_template,
          source: "deal",
          type_id: deal_id,
          prospect_id: deal.prospect_id,
          message: message,
          hashid: smartLinkHash,
        },
      });
      setLeadScoreUpdate(true);
      setIsActiveTab(false);
      getTimeline(false, prospectSession);
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_the_whatsapp_message_has_been_setup"),
        timer: 1500,
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendSmartLink = async (values) => {
    try {
      const response = await axios.get(`https://www.nexcrmapis.com/docs/smart_link_api.php`, {
        params: {
          task: "setLink",
          deal_id: deal_id,
          template_id: values.smartlink,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      sendWhatsapp(values, data.record);
    } catch (error) {
      console.error(error);
    }
  };

  const sendMarkAsDone = async (record) => {
    try {
      var okToMark = setCheckOkToEdit(record.record_create_user_id, record.record_assign_user_id);
      if (okToMark) {
        var newValue = record.record_activity_mad === "yes" ? "no" : "yes";
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 16,
            activity: record.record_activity_id,
            status: newValue,
            user: prospectSession.user_id,
            deal_id: deal_id,
          },
        });

        setLeadScoreUpdate(true);
        getTimeline(false, prospectSession);
      } else {
        Swal.fire({
          icon: "warning",
          title: t("DealDetails_failed"),
          text: t("DealDetails_sorry_you_cant_edit_this_task"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditActivity = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 12,
          create_user: prospectSession.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          ca_id: values.ca_id,
          assign_user: values.activity_user,
          aid: values.record_activity_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 12,
            create_user: prospectSession.user_id,
            dated: values.activity_date,
            timed: values.activity_time,
            duration: values.activity_duration,
            title: values.activity_title,
            content: values.activity_notes,
            mad: values.mad ? "yes" : "no",
            dat: values.activity_type,
            deal: deal_id,
            ca_id: values.ca_id,
            assign_user: values.activity_user,
            aid: values.record_activity_id,
          },
        });

        setIsEditActivity(false);
        setIsSubmitting(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_update_the_activity"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        setIsEditActivity(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      setIsEditActivity(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendEditMeeting = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const checkMeetingAvailability = await getMeetingAvailability(values);
      if (!checkMeetingAvailability) {
        setIsSubmitting(false);
        return;
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 12,
          create_user: prospectSession.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          ca_id: values.ca_id,
          assign_user: values.activity_user,
          aid: values.record_activity_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 12,
            create_user: prospectSession.user_id,
            dated: values.activity_date,
            timed: values.activity_time,
            duration: values.activity_duration,
            title: values.activity_title,
            content: values.activity_notes,
            mad: values.mad ? "yes" : "no",
            dat: values.activity_type,
            deal: deal_id,
            ca_id: values.ca_id,
            assign_user: values.activity_user,
            aid: values.record_activity_id,
          },
        });

        if (
          Number(values.record_activity_type_id) === 2 &&
          meetingData.meetup_cancel.status === 0 &&
          Number(meetingData.meetup_cancel.record.cancel) === 0
        ) {
          sendRescheduleMeetup(values);
        } else {
          setIsEditMeeting(false);
          setIsSubmitting(false);
          getTimeline(false, prospectSession);
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_update_the_activity"),
            timer: 1500,
          });
        }
      } else {
        setIsActiveTab(false);
        setIsEditMeeting(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      setIsEditMeeting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendDeleteActivity = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: `${t("DealDetails_please_confirm_that_you_would_like_to_remove")} ${record.activity_title}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.get("ws/ws_deal.php", {
            params: {
              task: "deleteActivityV2",
              activity: record.record_activity_id,
              utoken: prospectSession.user_ac_token,
              ctoken: prospectSession.company_token,
            },
          });

          getTimeline(false, prospectSession);
          Swal.fire({
            icon: t("DealDetails_success"),
            title: t("DealDetails_the_activity_has_been_removed"),
            timer: 1500,
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const sendEditNotes = async (values) => {
    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.notes = values.notes.replace(images[index], newImgTag);
      });
    }

    values.notes = values.notes.replace(/^(<div>|<p>|<\/div>|<\/p>)+|(<div>|<p>|<\/div>|<\/p>)+$/g, "");

    const formData = new FormData();
    formData.append("task", "editNotes");
    formData.append("content", values.notes);
    formData.append("note", values.record_activity_id);
    formData.append("deal", deal_id);
    formData.append("utoken", prospectSession.user_ac_token);
    formData.append("ctoken", prospectSession.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setisEditNote(false);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_updated_the_notes"),
          timer: 1500,
        });
      } else {
        setisEditNote(false);
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendDeleteNotes = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: t("Please confirm that you would like to remove this note"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "24",
              note: record.record_activity_id,
              prospectNote: "",
              utoken: prospectSession.user_ac_token,
              ctoken: prospectSession.company_token,
            },
          })
          .then((response) => {
            getTimeline(false, prospectSession);
            Swal.fire({
              icon: "success",
              title: t("DealDetails_the_activity_has_been_removed"),
              timer: 1500,
            });
          });
      }
    });
  };

  const sendDeleteFiles = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: t("DealDetails_please_confirm_that_you_would_like_to_remove_this_file"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "25",
              file: record.record_activity_id,
            },
          })
          .then((response) => {
            getTimeline(false, prospectSession);
            Swal.fire({
              icon: "success",
              title: t("DealDetails_the_activity_has_been_removed"),
              timer: 1500,
            });
          });
      }
    });
  };

  const sendEditDealInfo = async (values) => {
    setIsLoadingEditSummary(true);
    const backup_data = { ...deal };
    var updateCloseDate = 0;
    if (deal.deal_archieve === "won" || deal.deal_archieve === "lost") {
      updateCloseDate = 1;
    }

    setDeal((prev) => ({
      ...prev,
      deal_title: values.deal_title,
      deal_value: values.deal_price,
      deal_expected_close_date: values.deal_expected_close,
      deal_date_time_create: values.deal_added,
      deal_archieve: values.deal_archieve,
      deal_archieve_won_log: prev.deal_archieve === "won" ? values.deal_close : "0000-00-00 00:00:00",
      deal_archieve_lost_log: prev.deal_archieve === "lost" ? values.deal_close : "0000-00-00 00:00:00",
      deal_archieve_deleted_log: prev.deal_archieve === "deleted" ? values.deal_close : "0000-00-00 00:00:00",
    }));

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 4,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          title: values.deal_title,
          value: values.deal_price,
          close: values.deal_expected_close,
          added: values.deal_added,
          archieve: values.deal_archieve,
          dealclosed: values.deal_close,
          deal: deal.deal_id,
          updateAddedDate: 1,
          updateExpireDate: 1,
          updateCloseDate: updateCloseDate,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_has_been_updated"),
          timer: 2000,
        });
      } else {
        setDeal(backup_data);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEditSummary(false);
      setIsEditDealInfo(false);
    }
  };

  const sendDeleteContributor = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "delContributor",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          dealid: deal_id,
          userid: record.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_error_api_message"),
          });
        } else {
          getDeals(prospectSession);
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_removed_the_contributor"),
            timer: 2000,
          });
        }
      });
  };

  const sendDeleteForm = (record) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "removeCustomForm",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          deal: deal_id,
          custom_form_deal_id: record.custom_form_deal_id,
          custom_form_id: record.custom_form_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_error_api_message"),
          });
        } else {
          getDeals(prospectSession);
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_removed_the_form"),
            timer: 2000,
          });
        }
      });
  };

  const sendChooseOrganization = async (record) => {
    try {
      const dealRecord = {
        ...deal,
        organization_title: record.name,
        organization_address: record.address,
        organization_web_url: record.website,
        organization_contact: record.phone,
      };
      setDeal(dealRecord);

      await axios.get("ws/ws_organization.php", {
        params: {
          task: "updateOrgData",
          organization: deal.organization_id,
          title: encodeURIComponent(record.name),
          address: record.address,
          weburl: record.website,
          industry: deal.in_id,
          contact: encodeURIComponent(record.phone),
          country: deal.organization_country_id,
          latitude: record.lat,
          longitude: record.lon,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      setIsFindOrganization(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sendCallLead = async () => {
    setIsCallLead(true);

    let contactNumber = "";
    const prospectContactInfo = deal.prospect_contact_info;

    for (let i = 1; i <= 5; i++) {
      const contact = deal[`contact${i}`];
      if (contact) {
        contactNumber = contact;
        break;
      } else if (prospectContactInfo && prospectContactInfo.length > 0) {
        contactNumber = prospectContactInfo[0].contact_num;
      }
    }

    if (!contactNumber) {
      setIsCallLead(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_there_arent_any_contact_numbers"),
      });
      return;
    }

    try {
      await axios.get("ws/ws_avanser.php", {
        params: {
          task: 1,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          contact: contactNumber,
        },
      });

      setTimeout(() => setIsCallLead(false), 10000);
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditOrganization = async (values) => {
    setIsLoadingEditOrganization(true);
    try {
      var customField = values.cf_organization;
      var cf_id = customField.map((record) => record.name);
      var organization_id = Array(customField.length).fill(deal.organization_id);
      var cf_label = customField.map((record) => record.cf_label);
      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 2,
          organization: deal.organization_id,
          company: prospectSession.company_id,
          title: values.organization_title,
          address: values.organization_address,
          weburl: values.organization_web_url,
          industry: values.in_id,
          contact: values.organization_contact,
          country: values.organization_country_id,
          owner: values.owner_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          const response2 = await axios.get("ws/ws_custom_field.php", {
            params: {
              task: "updateOrAddCFV2",
              secret_key: prospectSession.company_token,
              value: fd_values.join("[-0-]"),
              cf: cf_id.join("[-0-]"),
              record: organization_id.join("[-0-]"),
              label: cf_label.join("[-0-]"),
              utoken: prospectSession.user_ac_token,
              ctoken: prospectSession.company_token,
            },
          });

          const data2 = response2.data;
          if (data2.status === 0) {
            Swal.fire({
              icon: "success",
              text: "Successfully updated the organization",
              timer: 2000,
            });
            getDeals(prospectSession);
            setIsEditOrganization(false);
          } else {
            Swal.fire({
              icon: "success",
              text: "There is some of information has been updated but there is something wrong with your entry, please try again to update or contact our support IT",
              timer: 2000,
            });
            getDeals(prospectSession);
            setIsEditOrganization(false);
          }
        } else {
          Swal.fire({
            icon: "success",
            text: "Successfully updated the organization",
            timer: 2000,
          });
          getDeals(prospectSession);
          setIsEditOrganization(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEditOrganization(false);
    }
  };

  const sendNewOrganization = async (values) => {
    setIsLoadingAddOrganization(true);
    if (values.organization_id && Number(values.organization_id) > 0) {
      await sendConnectOrganization(values.organization_id);
    } else {
      await sendCreateOrganization(values);
    }
  };

  const sendCreateOrganization = async (values) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "addOrgAndConnectProspect",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          title: values.organization_title,
          address: values.organization_address ? values.organization_address : "",
          weburl: values.organization_web_url ? values.organization_web_url : "",
          industry: values.in_id ? values.in_id : "",
          contact: values.organization_contact ? values.organization_contact : "",
          country: values.organization_country_id ? values.organization_country_id : "",
          prospect: deal.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        Swal.fire({
          icon: "success",
          text: "Successfully created the organization",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Sorry, this organization name already exists!",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAddOrganization(false);
      setIsAddOrganization(false);
    }
  };

  const sendConnectOrganization = async (organization_id) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: deal.designation_title,
          des: deal.designation_id,
          name: deal.prospect_name,
          organization: organization_id,
          prospect_address: "",
          prospect: Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : deal.prospect_id,
          owner_id: deal.prospect_owner,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        Swal.fire({
          icon: "success",
          text: "Successfully created the organization",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Sorry, this organization name already exists!",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAddOrganization(false);
      setIsAddOrganization(false);
    }
  };

  const sendCancelMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "cancelMeetup",
          deal_id: deal_id,
          activity_id: meetingData.record_activity_id,
          cancel_reason: values.reason,
          cancel_remark: values.remark,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getTimeline(false, prospectSession);
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "success",
          text: "Successfully cancel the appointment",
          timer: 2000,
        });
      } else {
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRescheduleMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "rescheduleMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsEditActivity(false);
        setIsSubmitting(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_update_the_activity"),
          timer: 1500,
        });
      } else {
        setIsEditActivity(false);
        setIsSubmitting(false);
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateMeetup = async (values, activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "createMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          activity_id: activity_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status == 0) {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      } else {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false, prospectSession);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  };

  const sendAiFollowUp = async (values) => {
    try {
      if (deal.contact1 === "") {
        return Swal.fire({
          icon: "warning",
          text: "Thio prospect does not have contact number",
        });
      }

      if (deal.contact1_prefix === "") {
        return Swal.fire({
          icon: "warning",
          text: "Thio prospect does not have a country code attach to the contact number",
        });
      }

      window.open(`https://web.whatsapp.com/send?phone=${deal.contact1_prefix}${deal.contact1}&text=${values.remark}`);
      // const whatsapp = `https://wa.me/${deal.contact1_prefix}${deal.contact1}?text=${values.remark}`;
      // window.open(whatsapp, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditSource = async (values) => {
    setIsLoadingEditSource(true);
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 35,
          deal: deal.deal_id,
          source: values.ds_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(prospectSession);
        Swal.fire({
          icon: "success",
          text: "Successfully updated the source",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "You are not allowed to update the source",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEditSource(false);
      setIsEditSource(false);
    }
  };

  const sendDeleteOrganizationConfirmation = async () => {
    Swal.fire({
      icon: "warning",
      title: "Pay attention!",
      text: "You are about to unlink this organization from this prospect. This action cannot be undone.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        sendDeleteOrganization();
      }
    });
  };

  const sendDeleteOrganization = async () => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: deal.designation_title,
          des: deal.designation_id,
          name: deal.prospect_name,
          organization: "",
          prospect_address: "",
          prospect: Number(deal.hq_prospect_id) > 0 ? deal.hq_prospect_id : deal.prospect_id,
          owner_id: deal.prospect_owner,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          text: "Successfully unlinked the organization",
          timer: 2000,
        }).then(() => {
          setDeal((prev) => ({
            ...prev,
            organization_id: 0,
            organization_title: "",
            organization_address: "",
            organization_web_url: "",
            organization_contact: "",
            organization_country_id: "",
            in_id: "",
          }));
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET CHECK OWNER -------------------------------------------------------------

  const setCheckOwner = () => {
    if (deal.deal_archieve === "" && (isOwner || Number(prospectSession.role_id) === 1)) {
      return true;
    } else {
      return false;
    }
  };

  const setCheckValidUser = () => {
    var valid = true;
    if (deal.deal_archieve === "" && (Number(deal.owner_id) === Number(prospectSession.user_id) || Number(prospectSession.role_id) === 1)) {
      valid = true;
    } else if (deal.deal_archieve === "" && isContributor) {
      valid = true;
    } else {
      valid = false;
    }

    if (Number(prospectSession.role_id) === 1) {
      valid = true;
    }
    return valid;
  };

  const setCheckValidAdminUser = () => {
    if (Number(prospectSession.role_id) === 1) {
      return true;
    } else {
      return false;
    }
  };

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note") {
      content = record.CREATE_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      content = record.ASSIGN_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && deal.organization_title !== "0") {
      content = deal.organization_title;
      icon = <FontAwesomeIcon icon={faBuilding} size="lg" className="me-2" />;
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      content = record.record_comment;
      icon = <FontAwesomeIcon icon={faEnvelopeOpen} size="lg" className="me-2" />;
    }

    return (
      <Stack direction="horizontal" gap={3}>
        {Number(record.record_activity_type_id) === 2 ? (
          <div className="d-flex align-items-center">
            {meetupLink.status === 0 && <MeetupLog dealID={deal_id} activityID={record.record_activity_id} />}
            <div className="ms-2">{setDateTimeStr(record.record_date_time)}</div>
          </div>
        ) : (
          <div>{setDateTimeStr(record.record_date_time)}</div>
        )}

        <div className="d-flex">
          {icon}
          {content}
        </div>
      </Stack>
    );
  };

  const setCheckOkToEdit = (uid, uid2) => {
    var valid = true;
    if (isContributor) {
      if (uid !== prospectSession.user_id) {
        valid = false;
      }

      if (uid2 !== null && uid2 !== prospectSession.user_id) {
        valid = false;
      }
    }
    return valid;
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else {
      return "";
    }
  };

  const setDealTitle = (input) => {
    const maxLength = 50;
    if (input.length > maxLength) {
      const truncatedInput = input.substring(0, maxLength) + "...";
      return (
        <OverlayTrigger placement="bottom" trigger={["hover", "hover"]} overlay={<Tooltip>{input}</Tooltip>}>
          <span>{truncatedInput}</span>
        </OverlayTrigger>
      );
    } else {
      return input;
    }
  };

  const setTimelineAction = (record) => {
    const hasReadWritePermission = Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1;
    if (hasReadWritePermission) {
      if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp") {
        if (
          (Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1) ||
          record.record_activity_mad === "yes"
        ) {
          return false;
        } else {
          return true;
        }
      } else if (record.record_type === "Note") {
        return true;
      } else if (record.record_type === "File") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setWidthTimeline = (record) => {
    if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === true) {
      return "90%";
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === false) {
      return "95%";
    } else if ((record.record_type !== "Activity" || record.record_type !== "CustomActivity") && setTimelineAction(record) === true) {
      return "95%";
    } else {
      return "100%";
    }
  };

  const setGenerateDurationList = (increment) => {
    const durationList = [];
    const maxDuration = 4 * 60; // 4 hours in minutes

    const addDuration = (minutes) => {
      if (minutes < 60) {
        durationList.push({ label: `${minutes} Mins`, value: `${minutes}` });
      } else {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        if (mins === 0) {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""}`, value: `${minutes}` });
        } else {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""} ${mins} Mins`, value: `${minutes}` });
        }
      }
    };

    for (let i = increment; i <= maxDuration; i += increment) {
      addDuration(i);
    }

    return durationList;
  };

  const setGenerateTimings = (startHour, endHour, interval) => {
    const timings = [];
    let currentTime = new Date();
    currentTime.setHours(startHour, 0, 0, 0);
    while (currentTime.getHours() < endHour || (currentTime.getHours() === endHour && currentTime.getMinutes() === 0)) {
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timings.push({ label: timeString, value: timeString });
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }
    return timings;
  };

  const setTimelineMAD = (record) => {
    if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      if (Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1) {
        return false;
      } else {
        if (Number(record.record_activity_type_id) === 8) return false;
        return true;
      }
    } else {
      return false;
    }
  };

  const setCheckImg = () => {
    setImgError(true);
  };

  const setInitial = (input) => {
    var str = "";
    if (/\s/.test(input)) {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    } else {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    }
    return str;
  };

  const setFormatMessage = (input) => {
    var regLink = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var regLink2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var regLink3 = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/gim;
    var regEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    var regBreakLine = /(?:\r\n|\r|\n)/g;
    var regImage = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(?:jpg|jpeg|png|gif)\b)/gim;
    var regBold = /\*\*(.*?)\*\*/g; // Matches text wrapped in double asterisks for bold
    var regItalic = /_(.*?)_/g; // Matches text wrapped in underscores for italic

    var tag = input.toString().replace(/\\'/g, "'");

    // Replace line breaks with <br> tags
    tag = tag.replace(regBreakLine, "<br>");

    // Replace image URLs with placeholders
    let imgPlaceholders = [];
    tag = tag.replace(regImage, (match) => {
      let placeholder = `{{img_placeholder_${imgPlaceholders.length}}}`;
      imgPlaceholders.push({ placeholder, url: match });
      return placeholder;
    });

    // Replace email addresses with mailto: links
    tag = tag.replace(regEmail, '<a href="mailto:$1" target="_blank">$1</a>');

    // Replace full URLs with <a> tags
    tag = tag.replace(regLink, '<a href="$1" target="_blank">$1</a>');

    // Replace www links with <a> tags
    tag = tag.replace(regLink2, '$1<a href="https://$2" target="_blank">$2</a>');

    // Replace domain-like strings with <a> tags
    tag = tag.replace(regLink3, '<a href="https://www.$1" target="_blank">$1</a>');

    // Replace bold text with <strong> tags
    tag = tag.replace(regBold, "<strong>$1</strong>");

    // Replace italic text with <em> tags
    tag = tag.replace(regItalic, "<em>$1</em>");

    // Replace placeholders with <a> and <img> tags
    imgPlaceholders.forEach(({ placeholder, url }) => {
      tag = tag.replace(placeholder, `<a href="${url}" target="_blank"><img src="${url}" alt="Image" style="width:150px; height:auto;" /></a>`);
    });

    return tag;
  };

  const setPhoneNumber = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined || Number(contact) === 0) {
      return "-";
    } else if (prefix === "" || prefix === null || prefix === undefined || Number(prefix) === 0) {
      return contact;
    } else {
      let realPrefix = prefix.replace("+", "");
      var prefix2 = realPrefix.substring(0, 2);
      var prefix3 = realPrefix.substring(0, 3);
      var prefix4 = realPrefix.substring(0, 4);

      if (MobilePrefix.find((prefix) => prefix.value === prefix2)) {
        realPrefix = prefix2;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix3)) {
        realPrefix = prefix3;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix4)) {
        realPrefix = prefix4;
      } else {
        return `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      }

      const contactNumber = `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
      }

      return contactNumber;
    }
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const ScrollToFirstError2 = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        const findFirstError = (obj, keyPath = "") => {
          if (obj === undefined || obj === null) {
            return "";
          }
          return Object.keys(obj).reduce((acc, key) => {
            if (acc) return acc;
            const isArrayIndex = !isNaN(key);
            const newKeyPath = keyPath ? `${keyPath}${isArrayIndex ? `[${key}]` : `.${key}`}` : key;
            if (typeof obj[key] === "string") return newKeyPath;
            return findFirstError(obj[key], newKeyPath);
          }, "");
        };
        const firstErrorKey = findFirstError(errors);
        const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const setTimelineFilterRecord = (record) => {
    if (timelineFilter === "all") {
      return record;
    } else if (timelineFilter === "activity") {
      return (
        (record.record_type === "Activity" || record.record_type === "NoEditActivity") &&
        [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id))
      );
    } else if (timelineFilter === "note") {
      return record.record_type === "Note";
    } else if (timelineFilter === "file") {
      return record.record_type === "File";
    } else if (timelineFilter === "email") {
      return record.record_type === "Email";
    } else if (timelineFilter === "whatsapp") {
      return (record.record_type === "Activity" || record.record_type === "NoEditActivity") && [8].includes(Number(record.record_activity_type_id));
    } else if (timelineFilter === "changelog") {
      return ["Ownership", "Status", "Archieve", "Reopen", "ProspectTransfer"].includes(record.record_type);
    }

    return record;
  };

  const setTimelineFilterCount = (timeline) => {
    if (timelineFilter === "all" || timelineFilter === "changelog") {
      return true;
    } else if (["activity", "note", "file", "email", "whatsapp"].includes(timelineFilter)) {
      return timeline.filter(setTimelineFilterRecord).length > 0;
    }

    return false;
  };

  const setProspectAddress = (record) => {
    let address = "";
    let comma = false;

    if (record.address_line1) {
      address += record.address_line1;

      if (record.address_line2) {
        address += ", ";
        address += record.address_line2;
      }

      if (record.address_line3) {
        address += ", ";
        address += record.address_line3;
      }

      if (record.postal_code) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.postal_code;
      }

      if (record.city_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.city_title;
      }

      if (record.state_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.state_title;
      }

      if (record.country_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.country_title;
      }
    } else {
      address += record.prospect_address;
    }

    return address ? address.toUpperCase() : "-";
  };

  const setEditProspectContactError = (errors, touched, index) => {
    let error = "";

    if (
      errors.contact &&
      errors.contact[index] &&
      errors.contact[index].contact_prefix &&
      touched.contact &&
      touched.contact[index] &&
      touched.contact[index].contact_prefix
    ) {
      error = errors.contact[index].contact_prefix;
    }

    if (
      errors.contact &&
      errors.contact[index] &&
      errors.contact[index].contact &&
      touched.contact &&
      touched.contact[index] &&
      touched.contact[index].contact
    ) {
      if (error !== "") {
        error += " & ";
      }
      error += errors.contact[index].contact;
    }

    return error ? <div className="op-error-message mb-2">{error}</div> : null;
  };

  const setOrganizationCountryValue = () => {
    if (deal?.organization_country_id && Number(deal?.organization_country_id) > 0) {
      const country = countryOrganizationList.find((data) => Number(data.value) === Number(deal?.organization_country_id));
      return <div className="fw-bold">{country ? country.label : "-"}</div>;
    }

    return <div className="fw-bold">-</div>;
  };

  const setOrganizationIndustryValue = () => {
    if (deal?.in_id && Number(deal?.in_id) > 0) {
      const industry = industryList.find((data) => Number(data.value) === Number(deal?.in_id));
      return <div className="fw-bold">{industry ? industry.label : "-"}</div>;
    }

    return <div className="fw-bold">-</div>;
  };

  const setCustomFieldValues = (record) => {
    if (record.ctf_title === "Checkbox") {
      if (record.fd_value) {
        return record.fd_value.split(",").map((data, index) => (
          <div className="fw-bold" key={index} style={{ wordBreak: "break-word" }}>
            {data}
          </div>
        ));
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else if (record.ctf_title === "Date") {
      if (record.fd_value) {
        return (
          <div className="fw-bold" style={{ wordBreak: "break-word" }}>
            {moment(record.fd_value).format("LL")}
          </div>
        );
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else {
      return (
        <div className="fw-bold" style={{ wordBreak: "break-word" }}>
          {record.fd_value ? record.fd_value : "-"}
        </div>
      );
    }
  };

  const setCheckMessageType = (record) => {
    if (record.message_type === "email") {
      if (deal.email1 === "" && deal.email2 === "" && deal.prospect_email_count === 0) {
        return false;
      } else {
        return true;
      }
    } else if (record.message_type === "whatsapp") {
      if (deal.contact1 === "" && deal.contact2 === "" && deal.prospect_contact_count === 0) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  };

  const setRenderMessage = (message_type) => {
    if (message_type === "email") {
      return (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
          <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 21.5a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v37a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3v-37Z"
              stroke="#B6BBC3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="m17.5 26 18.816 14.635a6 6 0 0 0 7.368 0L62.5 26"
              stroke="#B6BBC3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <span className="mt-2" style={{ fontSize: 14 }}>
            <strong>No email address set up</strong>
            <p>This prospect currently does not have an email address configured in the system</p>
          </span>
        </div>
      );
    } else if (message_type === "whatsapp") {
      return (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
          <svg fill="#B6BBC3" width={80} height={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
          </svg>
          <span className="mt-2" style={{ fontSize: 14 }}>
            <strong>No contact number set up</strong>
            <p>This prospect currently does not have a contact number configured in the system</p>
          </span>
        </div>
      );
    }

    return null;
  };

  const setActivityType = (actvity_type, ca_id) => {
    if ([1, 2, 3, 4, 5].includes(Number(actvity_type))) {
      if (Number(actvity_type) === 1) {
        return "Call";
      } else if (Number(actvity_type) === 2) {
        return "Meeting";
      } else if (Number(actvity_type) === 3) {
        return "Task";
      } else if (Number(actvity_type) === 4) {
        return "Whatsapp";
      } else if (Number(actvity_type) === 5) {
        return "Email";
      }
    } else {
      const customActivityLabel = customActivityList.find((data) => Number(data.value) === Number(ca_id));
      return customActivityLabel ? customActivityLabel.label : "";
    }

    return "";
  };

  // MODAL TRIGGER ---------------------------------------------------------------

  const modalEditActivity = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: record.record_activity_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var activityTime = data.record.activity_timed.split(":");
          var activityTimeValue = activityTime[0] + ":" + activityTime[1];
          const editValue = {
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: activityTimeValue,
            activity_time_val: activityTimeValue,
            activity_duration: data.record.activity_duration,
            activity_notes: data.record.activity_content,
            activity_notes2: data.record.activity_content,
            activity_linked: "deal",
            activity_user: data.record.assign_user_id,
            deal_id: deal_id,
            prospect_id: deal.prospect_id,
            ca_id: data.record.ca_id,
            deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
            mad: data.record.activity_mark_as_done === "yes" ? true : false,
            ca_id: data.record.ca_id,
            record_activity_id: record.record_activity_id,
            record_activity_type_id: record.record_activity_type_id,
          };

          setInitEditActivity(editValue);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_activity_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  const modalEditMeeting = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: record.record_activity_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var activityTime = data.record.activity_timed.split(":");
          var activityTimeValue = activityTime[0] + ":" + activityTime[1];
          const editValue = {
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: activityTimeValue,
            activity_time_val: activityTimeValue,
            activity_duration: data.record.activity_duration,
            activity_notes: data.record.activity_content,
            activity_notes2: data.record.activity_content,
            activity_linked: "deal",
            activity_user: data.record.assign_user_id,
            deal_id: deal_id,
            prospect_id: deal.prospect_id,
            mad: data.record.activity_mark_as_done === "yes" ? true : false,
            ca_id: data.record.ca_id,
            record_activity_id: record.record_activity_id,
            record_activity_type_id: record.record_activity_type_id,
          };

          setInitEditMeeting(editValue);
          setMeetingData(record);
          setIsEditMeeting(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_activity_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  const modalEditNotes = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 19,
          type: "deal",
          note: record.record_activity_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const editValue = {
            notes: data.record.note_content,
            notes2: data.record.note_content,
            notes_id: data.record.note_id,
            user_id: data.record.user_id,
            record_activity_id: record.record_activity_id,
          };

          setInitEditNotes(editValue);
          setisEditNote(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_notes_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderDealAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <Button ref={ref} variant="light" className="shadow-sm border" onClick={handleClick} aria-label="Toggle dropdown">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={setIsEditDealInfo}>{t("DealDetails_edit_deal")}</Dropdown.Item>
          {Number(prospectSession.role_id) === 1 && <Dropdown.Item onClick={setIsDeleteDeal}>{t("DealDetails_delete_deal")}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderOrganizationAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <button
          ref={ref}
          variant="light"
          className="btn btn-light border"
          onClick={handleClick}
          aria-label="Toggle dropdown"
          style={{ borderLeft: "none", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={sendDeleteOrganizationConfirmation}>Unlink this organization</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderTimelineAction = ({ record }) => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown} className="float-end" style={{ backgroundColor: "transparent" }}>
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") &&
            record.record_activity_type !== "whatsapp" &&
            record.meeting_scheduler === false && (
              <Dropdown.Item onClick={() => modalEditActivity(record)}>{t("DealDetails_edit_activity")}</Dropdown.Item>
            )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") &&
            record.record_activity_type !== "whatsapp" &&
            record.meeting_scheduler === true && <Dropdown.Item onClick={() => modalEditMeeting(record)}>Edit Meeting</Dropdown.Item>}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") &&
            record.record_activity_type !== "whatsapp" &&
            record.meeting_scheduler === false &&
            Number(session.readwrite_permission) === 0 && (
              <Dropdown.Item onClick={() => sendDeleteActivity(record)}>{t("DealDetails_delete_activity")}</Dropdown.Item>
            )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") &&
            record.record_activity_type !== "whatsapp" &&
            record.meeting_scheduler === true &&
            Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteActivity(record)}>Delete Meeting</Dropdown.Item>}
          {record.record_type === "Note" && <Dropdown.Item onClick={() => modalEditNotes(record)}>Edit Note</Dropdown.Item>}
          {record.record_type === "Note" && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item onClick={() => sendDeleteNotes(record)}>{t("DealDetails_delete_note")}</Dropdown.Item>
          )}
          {record.record_type === "File" && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item onClick={() => sendDeleteFiles(record)}>{t("DealDetails_delete_file")}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const RenderActionTab = useCallback(
    ({ tab, active, activeLoading, userListNotes, activityUser, isSubmitting, disabled, followUpLoading, followUpData, customActivityList }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={notesSchema}
                onSubmit={sendNewNotes}
                initialValues={{
                  notes: "",
                  user: Number(prospectSession.user_id),
                  deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <NoteEditor value={values.notes} onChangeValue={(value) => handleChange("notes")(value)} />
                        {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FormSelect
                          options={userListNotes}
                          disabled={values.deal_linked}
                          valueDefault={userListNotes.find((record) => Number(record.value) === Number(values.user))}
                          placeholder={t("DealDetails_select_user")}
                          onChange={(e) => handleChange("user")(e.value)}
                        />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between align-items-center">
                      {Number(session.ai_note) === 1 && (
                        <AudioNotesV2
                          session={session}
                          deal_id={deal_id}
                          prospect_id={deal.prospect_id}
                          onSubmit={(response) => {
                            if (response.message) {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.message : response.message);
                            } else if (response.transcript) {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.transcript : response.transcript);
                            } else {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.error : response.error);
                            }
                          }}
                        />
                      )}
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={disabled}>
                        {disabled ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setDisabledBtn(false);
                  setIsSubmitting(false);
                  setIsActiveTabLoading(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_take_a_notes")}
              </Card.Body>
            );
          }
        case "activity":
          if (active) {
            if (activeLoading) {
              return (
                <Form>
                  <Card.Body id="notes_collapse">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Placeholder as="p" className="mb-0" animation="glow">
                            <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 26 }} />
                          </Placeholder>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Placeholder as="p" className="mb-0" animation="glow">
                            <Placeholder xs={6} size="sm" bg="secondary" className="rounded" style={{ height: 26 }} />
                          </Placeholder>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Placeholder as="p" className="mb-0" animation="glow">
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />{" "}
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />{" "}
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />
                        </Placeholder>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Placeholder as="p" className="mb-0" animation="glow">
                            <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 26 }} />
                          </Placeholder>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Placeholder as="p" className="mb-0" animation="glow">
                          <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 120 }} />
                        </Placeholder>
                      </div>
                    </div>
                  </Card.Body>
                </Form>
              );
            } else {
              return (
                <Formik
                  validationSchema={addActivitySchema}
                  onSubmit={sendAddActivity}
                  initialValues={{
                    activity_title: "",
                    activity_type: "1",
                    activity_date: latestDate,
                    activity_time: setLatestTime(timings).value,
                    activity_time_show: true,
                    activity_time_val: setLatestTime(timings),
                    activity_duration: "",
                    activity_duration_show: true,
                    activity_notes: "",
                    activity_linked: "deal",
                    activity_user: prospectSession.user_id,
                    deal_id: "",
                    prospect_id: "",
                    ca_id: "",
                    mad: false,
                    google_calendar: false,
                    deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                  }}
                >
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={values.activity_title}
                                placeholder={t("DealDetails_activity_title") + " *"}
                                isInvalid={errors.activity_title && touched.activity_title}
                                onChange={handleChange("activity_title")}
                              />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <Form.Group className="d-flex">
                            <ButtonGroup className="activity-tab me-2">
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_1"
                                value="1"
                                checked={values.activity_type === "1"}
                                onChange={() => {
                                  setFieldValue("activity_type", "1");
                                  setFieldValue("ca_id", "");
                                }}
                              >
                                <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                {t("DealDetails_call")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_2"
                                value="2"
                                checked={values.activity_type === "2"}
                                onChange={() => {
                                  setFieldValue("activity_type", "2");
                                  setFieldValue("ca_id", "");
                                }}
                              >
                                <FontAwesomeIcon icon={faUsers} className="me-1" />
                                {t("DealDetails_meeting")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_3"
                                value="3"
                                checked={values.activity_type === "3"}
                                onChange={() => {
                                  setFieldValue("activity_type", "3");
                                  setFieldValue("ca_id", "");
                                }}
                              >
                                <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                {t("DealDetails_task")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_4"
                                value="4"
                                checked={values.activity_type === "4"}
                                onChange={() => {
                                  setFieldValue("activity_type", "4");
                                  setFieldValue("ca_id", "");
                                }}
                              >
                                <FontAwesomeIcon icon={faFlag} className="me-1" />
                                {t("DealDetails_deadline")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_5"
                                value="5"
                                checked={values.activity_type === "5"}
                                onChange={() => {
                                  setFieldValue("activity_type", "5");
                                  setFieldValue("ca_id", "");
                                }}
                              >
                                <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                {t("DealDetails_email")}
                              </ToggleButton>
                            </ButtonGroup>
                            {customActivityList.length > 0 && (
                              <FormSelectV2
                                placeholder="Custom Activity"
                                width="200px"
                                value={customActivityList.find((record) => Number(record.value) === Number(values.ca_id))}
                                options={customActivityList}
                                onChange={(e) => {
                                  setFieldValue("activity_title", e.label);
                                  setFieldValue("ca_id", e.value);
                                  setFieldValue("activity_type", "");
                                }}
                              />
                            )}
                          </Form.Group>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={timings}
                                  valueDefault={setLatestTime(timings)}
                                  placeholder={t("DealDetails_select_time")}
                                  onChange={(e) => handleChange("activity_time")(e.value)}
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={durationList}
                                  placeholder={t("DealDetails_select_duration")}
                                  onChange={(e) => handleChange("activity_duration")(e.value)}
                                />
                                {errors.activity_duration && touched.activity_duration && (
                                  <div className="op-error-message">{errors.activity_duration}</div>
                                )}
                              </Form.Group>
                            </Row>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelect
                                placeholder={t("DealDetails_select_user") + " *"}
                                disabled={values.deal_linked}
                                options={activityUser}
                                valueDefault={activityUser.find((record) => Number(record.value) === Number(values.activity_user))}
                                onChange={(e) => handleChange("activity_user")(e.value)}
                              />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                        </button>
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_mark_as_done")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "mad", value: e.target.checked },
                            });
                          }}
                        />
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_add_to_google_calendar")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "google_calendar", value: e.target.checked },
                            });
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              );
            }
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setIsActiveTabLoading(false);
                  setDisabledBtn(false);
                  setIsSubmitting(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_add_an_activity")}
              </Card.Body>
            );
          }
        case "files":
          if (active) {
            return (
              <Formik
                validationSchema={fileUploadSchema}
                onSubmit={sendAddFile}
                initialValues={{
                  file: [],
                  title: "",
                }}
              >
                {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder={t("DealDetails_file_title")} onChange={handleChange("title")} />
                        {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FilePond
                          allowMultiple={false}
                          maxFiles={1}
                          branding={false}
                          onupdatefiles={(fileItems) => {
                            setFieldValue(
                              "file",
                              fileItems.map((fileItem) => fileItem.file)
                            );
                          }}
                        />
                        {errors.file && touched.file && <div className="op-error-message">{errors.file}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={disabled}>
                        {disabled ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setIsActiveTabLoading(false);
                  setDisabledBtn(false);
                  setIsSubmitting(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_upload_files")}
              </Card.Body>
            );
          }
        case "messages":
          if (active) {
            return (
              <Formik
                validationSchema={addMessageschema}
                initialValues={{
                  message_type: "email",
                  email_template: "",
                  whatsapp_template: "",
                  email_subject: "",
                  email_content: "",
                  email_content_val: "",
                  smartlink: "",
                }}
                onSubmit={sendAddMessages}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body id="notes_collapse">
                      <Form.Group className="mb-3">
                        <ToggleButtonGroup
                          className="activity-tab"
                          defaultValue="email"
                          type="radio"
                          name="message_type"
                          onChange={(value) => {
                            setFieldValue("message_type", value);
                            setFieldValue("email_template", "");
                            setFieldValue("email_subject", "");
                            if (value === "whatsapp") {
                              var userContact = "";
                              for (var i = 0; i < user.length; i++) {
                                if (user[i].user_id === deal.owner_id) {
                                  userContact = user[i].user_contact;
                                  break;
                                }
                              }

                              const link_pName = deal.prospect_name.replaceAll(" ", "_");
                              const link_dTitle = deal.deal_title.replaceAll(" ", "_");
                              const link_userName = deal.user_name.replaceAll(" ", "_");
                              const template = whatsappTemplate[0];
                              let content = template.template;
                              if (deal.meetup_available === 1) {
                                const meetupStr = `https://app.outperformhq.io/meetup/${deal.meetup_info.hash.substring(0, 8)}/${
                                  deal.meetup_info.slug
                                }/${deal.meetup_info.base10}`;
                                content = content.replace("<meetup_link>", meetupStr);
                              }

                              content = content.replace("<prospect_name>", deal?.prospect_name || "");
                              content = content.replace("<deal_title>", deal?.deal_title || "");
                              content = content.replace("<prospect_address>", deal?.prospect_address || "");
                              content = content.replace("<ds_title>", deal?.ds_title || "");
                              content = content.replace("<deal_hash>", deal?.hash || "");
                              content = content.replace("<organization_title>", deal?.organization_title || "");
                              content = content.replace("<organization_address>", deal?.organization_address || "");
                              content = content.replace("<user_name>", deal?.user_name || "");
                              content = content.replace("<user_contact>", userContact);
                              content = content.replace("<designation_title>", deal?.designation_title || "");
                              content = content.replace("<source_category_title>", deal?.source_category_title || "");
                              content = content.replace(
                                "<prospect_contact>",
                                deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : ""
                              );

                              content = content.replace("<prospect_name_link>", link_pName);
                              content = content.replace("<deal_title_link>", link_dTitle);
                              content = content.replace("<user_name_link>", link_userName);
                              content = content.replace("%0a", "");

                              setFieldValue("whatsapp_template", template.value);
                              setFieldValue("email_content", content);
                              setFieldValue("email_content_val", content);
                            } else {
                              setFieldValue("whatsapp_template", "");
                              setFieldValue("email_content", "");
                              setFieldValue("email_content_val", "");
                            }
                          }}
                        >
                          <ToggleButton variant="" id="message_type_1" value="email">
                            <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-2" />
                            {t("DealDetails_email")}
                          </ToggleButton>
                          <ToggleButton variant="" id="message_type_2" value="whatsapp">
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-1" />
                            {t("DealDetails_whatsapp")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Form.Group>

                      {setCheckMessageType(values) ? (
                        <>
                          {values.message_type === "email" && (
                            <Form.Group className="mb-3">
                              <FormSelect
                                options={emailTemplate}
                                placeholder={t("DealDetails_choose_template")}
                                onChange={(e) => {
                                  setFieldValue("email_template", e.value);
                                  axios
                                    .get("ws/ws_email.php", {
                                      params: {
                                        task: "getTemplateData",
                                        utoken: prospectSession.user_ac_token,
                                        ctoken: prospectSession.company_token,
                                        template_id: e.value,
                                      },
                                    })
                                    .then((response) => {
                                      const data = response.data;
                                      if (data.status === 0) {
                                        setFieldValue("email_subject", data.record.email_subject);
                                        setFieldValue("email_content", data.record.email_message);
                                        setFieldValue("email_content_val", data.record.email_message);
                                      }
                                    });
                                }}
                              />
                              {values.message_type === "email" && errors.email_template && touched.email_template && (
                                <div className="op-error-message">{errors.email_template}</div>
                              )}
                            </Form.Group>
                          )}

                          {values.message_type === "whatsapp" && (
                            <Form.Group className="mb-3">
                              <FormSelectV2
                                options={whatsappTemplate}
                                value={whatsappTemplate.find((record) => Number(record.value) === Number(values.whatsapp_template))}
                                placeholder={t("DealDetails_choose_template")}
                                onChange={(e) => {
                                  var userContact = "";
                                  for (var i = 0; i < user.length; i++) {
                                    if (user[i].user_id === deal.owner_id) {
                                      userContact = user[i].user_contact;
                                      break;
                                    }
                                  }
                                  var link_pName = deal.prospect_name.replaceAll(" ", "_");
                                  var link_dTitle = deal.deal_title.replaceAll(" ", "_");
                                  var link_userName = deal.user_name.replaceAll(" ", "_");

                                  if (deal.meetup_available === 1) {
                                    var meetupStr = `https://app.outperformhq.io/meetup/${deal.meetup_info.hash.substring(0, 8)}/${
                                      deal.meetup_info.slug
                                    }/${deal.meetup_info.base10}`;
                                    e.template = e.template.replace("<meetup_link>", meetupStr);
                                  }

                                  e.template = e.template.replace("<prospect_name>", deal?.prospect_name || "");
                                  e.template = e.template.replace("<deal_title>", deal?.deal_title || "");
                                  e.template = e.template.replace("<prospect_address>", deal?.prospect_address || "");
                                  e.template = e.template.replace("<ds_title>", deal?.ds_title || "");
                                  e.template = e.template.replace("<deal_hash>", deal?.hash || "");
                                  e.template = e.template.replace("<organization_title>", deal?.organization_title || "");
                                  e.template = e.template.replace("<organization_address>", deal?.organization_address || "");
                                  e.template = e.template.replace("<user_name>", deal?.user_name || "");
                                  e.template = e.template.replace("<user_contact>", userContact);
                                  e.template = e.template.replace("<designation_title>", deal?.designation_title || "");
                                  e.template = e.template.replace("<source_category_title>", deal?.source_category_title || "");
                                  e.template = e.template.replace(
                                    "<prospect_contact>",
                                    deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : ""
                                  );

                                  e.template = e.template.replace("<prospect_name_link>", link_pName);
                                  e.template = e.template.replace("<deal_title_link>", link_dTitle);
                                  e.template = e.template.replace("<user_name_link>", link_userName);
                                  e.template = e.template.replace("%0a", "");

                                  setFieldValue("whatsapp_template", e.value);
                                  setFieldValue("email_content", e.template);
                                  setFieldValue("email_content_val", e.template);
                                }}
                              />
                              {values.message_type === "whatsapp" && errors.whatsapp_template && touched.whatsapp_template && (
                                <div className="op-error-message">{errors.whatsapp_template}</div>
                              )}
                            </Form.Group>
                          )}

                          {values.message_type === "email" && (
                            <Form.Group className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder={t("DealDetails_email_subject")}
                                value={values.email_subject}
                                onChange={handleChange("email_subject")}
                              />
                              {errors.email_subject && touched.email_subject && <div className="op-error-message">{errors.email_subject}</div>}
                            </Form.Group>
                          )}

                          {(values.message_type === "email" || values.message_type === "whatsapp") && (
                            <Form.Group className="activity-notes">
                              <NoteEditor value={values.email_content} onChangeValue={(value) => handleChange("email_content")(value)} />
                              {errors.email_content && touched.email_content && <div className="op-error-message">{errors.email_content}</div>}
                            </Form.Group>
                          )}

                          {values.message_type === "whatsapp" && (
                            <Form.Group className="my-3">
                              <FormSelect
                                options={smartLinkList}
                                placeholder={"Choose Smart Link"}
                                onChange={(e) => setFieldValue("smartlink", e.value)}
                              />
                            </Form.Group>
                          )}
                        </>
                      ) : (
                        setRenderMessage(values.message_type)
                      )}
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                      <Button
                        variant={values.message_type === "whatsapp" ? "success" : ""}
                        type="submit"
                        className={values.message_type === "whatsapp" ? "" : "op-primary-color text-light"}
                        disabled={disabled}
                      >
                        {disabled ? (
                          <Spinner animation="border" size="sm" />
                        ) : values.message_type === "whatsapp" ? (
                          <>
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-2" />
                            Send to Whatsapp
                          </>
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setIsActiveTabLoading(false);
                  setDisabledBtn(false);
                  setIsSubmitting(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_send_messages")}
              </Card.Body>
            );
          }
        case "ai_follow_up":
          if (active) {
            if (followUpLoading) {
              return (
                <>
                  <Card.Body id="notes_collapse">
                    <Form.Label className="fw-bold">Insight</Form.Label>
                    <Placeholder as="p" className="mb-0" animation="glow">
                      <Placeholder xs={8} size="sm" bg="secondary" />
                    </Placeholder>
                    <Placeholder as="p" className="mb-0" animation="glow">
                      <Placeholder xs={6} size="sm" bg="secondary" />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={4} size="sm" bg="secondary" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={12} bg="secondary" style={{ height: 200 }} />
                    </Placeholder>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-row-reverse align-items-center">
                    <Spinner animation="border" size="sm" />
                  </Card.Footer>
                </>
              );
            } else {
              return (
                <Formik validationSchema={aiFollowUpSchema} initialValues={{ remark: followUpData?.whatsappMessage }} onSubmit={sendAiFollowUp}>
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">Insight</Form.Label> <br />
                          <Form.Label className="text-muted">{followUpData?.agentNotes}</Form.Label>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            value={values.remark}
                            onChange={handleChange("remark")}
                            style={{ height: 200, resize: "none" }}
                          />
                          {errors.remark && touched.remark && <div className="op-error-message">{errors.remark}</div>}
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="icon-link icon-link-hover text-decoration-none" onClick={getAiFollowUp}>
                          Regenerate
                        </Button>

                        <Button variant="success" type="submit">
                          <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-2" />
                          Send to Whatsapp
                        </Button>
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              );
            }
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  getAiFollowUp();
                  setIsActiveTab(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Click here to send AI Follow Up...
              </Card.Body>
            );
          }
        case "meeting_scheduler":
          if (active) {
            if (activeLoading) {
              return (
                <Form>
                  <Card.Body id="notes_collapse">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Placeholder as="p" className="mb-0" animation="glow">
                            <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 26 }} />
                          </Placeholder>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Placeholder as="p" className="mb-0" animation="glow">
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />{" "}
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />{" "}
                          <Placeholder xs={4} size="sm" bg="secondary" className="rounded" style={{ height: 26, width: "30%" }} />
                        </Placeholder>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Placeholder as="p" className="mb-0" animation="glow">
                            <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 26 }} />
                          </Placeholder>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Placeholder as="p" className="mb-0" animation="glow">
                          <Placeholder xs={12} size="sm" bg="secondary" className="rounded" style={{ height: 120 }} />
                        </Placeholder>
                      </div>
                    </div>
                  </Card.Body>
                </Form>
              );
            } else {
              return (
                <Formik
                  validationSchema={addMeeetingSchema}
                  onSubmit={sendAddMeetingScheduler}
                  initialValues={{
                    activity_title: "",
                    activity_type: "2",
                    activity_date: latestDate,
                    activity_time: setLatestTime(timings).value,
                    activity_time_show: true,
                    activity_time_val: setLatestTime(meetingTimingsList).value,
                    activity_duration: "60",
                    activity_duration_show: true,
                    activity_notes: "",
                    activity_linked: "deal",
                    activity_user: prospectSession.user_id,
                    deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                    deal_id: "",
                    prospect_id: "",
                    mad: false,
                    google_calendar: false,
                  }}
                >
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder={"Meeting title *"}
                                isInvalid={errors.activity_title && touched.activity_title}
                                onChange={handleChange("activity_title")}
                              />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={meetingTimingsList}
                                  valueDefault={setLatestTime(meetingTimingsList)}
                                  placeholder={t("DealDetails_select_time")}
                                  onChange={(e) => handleChange("activity_time")(e.value)}
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={[{ label: "1 Hour", value: "60" }]}
                                  valueDefault={{ label: "1 Hour", value: "60" }}
                                  disabled
                                  placeholder={t("DealDetails_select_duration") + " *"}
                                  onChange={(e) => handleChange("activity_duration")(e.value)}
                                />
                                {errors.activity_duration && touched.activity_duration && (
                                  <div className="op-error-message">{errors.activity_duration}</div>
                                )}
                              </Form.Group>
                            </Row>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelect
                                placeholder={t("DealDetails_select_user") + " *"}
                                disabled={values.deal_linked}
                                options={activityUser}
                                valueDefault={activityUser.find((record) => Number(record.value) === Number(values.activity_user))}
                                onChange={(e) => handleChange("activity_user")(e.value)}
                              />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                        </button>
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_mark_as_done")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "mad", value: e.target.checked },
                            });
                          }}
                        />
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_add_to_google_calendar")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "google_calendar", value: e.target.checked },
                            });
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              );
            }
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setIsActiveTabLoading(false);
                  setDisabledBtn(false);
                  setIsSubmitting(false);
                }}
                style={{ cursor: "pointer" }}
              >
                Click here to set meeting with scheduler
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }}
        className={`${className} animate slideIn`}
        aria-labelledby={labeledBy}
      >
        <Card>
          <Formik
            validationSchema={transferDealSchema}
            onSubmit={sendTransferDeal}
            initialValues={{
              user: "",
              role_id: "",
              transferOption: 2,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {t("DealDetails_transfer_ownership")}
                  <Button variant="" as={CloseButton} onClick={() => setIsTransferOwner(false)} />
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <FormSelect
                      name="user"
                      value={values.user}
                      onChange={(info) => {
                        handleChange("user")(info.value);
                        handleChange("role_id")(info.role_id);
                      }}
                      isInvalid={errors.user && touched.user}
                      options={user}
                      placeholder={t("DealDetails_select_user")}
                    />
                    {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      name="transferOption"
                      value={2}
                      onChange={handleChange("transferOption")}
                      label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t("DealDetails_deal")} & ${t("DealDetails_prospect")}`}
                      checked
                    />
                    {session.layout_mode === "real_estate" && (
                      <Form.Check
                        type="radio"
                        name="transferOption"
                        value={1}
                        onChange={handleChange("transferOption")}
                        label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t("DealDetails_deal")} ${t("DealDetails_only")}`}
                      />
                    )}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t("DealDetails_save")}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else if (record.record_type === "ProspectTransfer") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `${t("DealDetails_deal_transfer_to")} ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return (
          <RenderFile
            url={`${session.hostUrl}/${session.hostUrlType}/${record.record_file_url}`}
            title={`${record.record_title}.${record.record_file_ext}`}
          />
        );
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== ",") {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" className="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note":
        element = record.record_title;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div style={{ whiteSpace: "pre-wrap", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
      case "Email":
        element = record.record_title;
        break;
      case "ProspectTransfer":
        return (
          <div style={{ overflow: "auto" }}>
            <div>{record.record_description}</div>
          </div>
        );
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const refreshCallInsight = useCallback(
    async (index, record) => {
      const { record_file_url, record_activity_id } = record;

      const findLoading = callAnalytics.find((record) => record.loading === true);
      if (findLoading) {
        return Swal.fire({
          icon: "warning",
          text: "Sorry, there is a audio in the processing, please wait and try again later",
        });
      }

      const callAnalyticsUpdate = [...callAnalytics];
      callAnalyticsUpdate[index].loading = true;
      setCallAnalytics(callAnalyticsUpdate);

      try {
        await axios.get(`${session.hostUrl}/${session.hostUrlType}/ws/ws_sentiment_analysis.php`, {
          params: {
            task: "call_insight_v3",
            url: record_file_url,
            call_data_id: record_activity_id,
            contact_number: "",
            prospect_id: deal.prospect_id,
            company_id: prospectSession.company_id,
            user_id: deal.owner_id,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        const callAnalyticsUpdate = [...callAnalytics];
        callAnalyticsUpdate[index].loading = false;
        setCallAnalytics(callAnalyticsUpdate);
      }
    },
    [deal, session, callAnalytics]
  );

  const RenderTimelineDescription = useCallback(
    ({ record, insight }) => {
      const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;
      if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
        if (record_activity_type === "Call" && record_file_url) {
          var isLabel = insight.find((record) => record.record_activity_id === record_activity_id);
          var isLabelIndex = insight.findIndex((record) => record.record_activity_id === record_activity_id);

          return (
            <div style={{ padding: "10px 0" }}>
              <RenderAudio url={record_file_url} />
              {isLabel && (
                <div className="mt-2">
                  {isLabel.analytics_field_1 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_1}: {isLabel.analytics_param_1}
                    </div>
                  )}

                  {isLabel.analytics_field_2 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_2}: {isLabel.analytics_param_2}
                    </div>
                  )}

                  {isLabel.analytics_field_3 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_3}: {isLabel.analytics_param_3}
                    </div>
                  )}

                  {isLabel.analytics_field_4 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_4}: {isLabel.analytics_param_4}
                    </div>
                  )}

                  {isLabel.analytics_field_5 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_5}: {isLabel.analytics_param_5}
                    </div>
                  )}

                  {isLabel.analytics_field_6 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_6}: {isLabel.analytics_param_6}
                    </div>
                  )}

                  {isLabel.analytics_field_7 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_7}: {isLabel.analytics_param_7}
                    </div>
                  )}

                  {isLabel.analytics_field_8 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_8}: {isLabel.analytics_param_8}
                    </div>
                  )}

                  {isLabel.analytics_field_9 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_9}: {isLabel.analytics_param_9}
                    </div>
                  )}

                  {isLabel.analytics_field_10 && (
                    <div className="op label mb-2">
                      {isLabel.analytics_field_10}: {isLabel.analytics_param_10}
                    </div>
                  )}

                  {isLabel.ai_summary && (
                    <div className="op label mb-2 w-100" style={{ lineHeight: 1.5 }}>
                      {isLabel.ai_summary}
                    </div>
                  )}

                  <div
                    className="shadow-sm rounded-circle bg-light d-flex justify-content-center border cursor-pointer"
                    style={{ position: "absolute", bottom: 10, right: 10, padding: 5 }}
                  >
                    {isLabel.loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faRotateRight} size="lg" onClick={() => refreshCallInsight(isLabelIndex, record)} />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        } else if (record_activity_type === "Call" && record_file_url === "") {
          return "";
        } else {
          return (
            <Stack direction="vertical">
              {Number(record.record_activity_type_id) === 2 && (
                <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record.meetup_record_description) }} />
              )}
              <div
                className="overflow-auto positon-relative w-100"
                style={{ whiteSpace: "pre-wrap", maxHeight: 500 }}
                dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }}
              />
            </Stack>
          );
        }
      } else if (record_type === "Archieve") {
        return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
      } else if (record_type === "Email") {
        return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
      } else {
        return "";
      }
    },
    [callAnalytics]
  );

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const RenderUTM = ({ record }) => {
    let utm = "";
    if (record.utm_campaign) {
      utm = record.utm_campaign;
    }

    if (record.utm_content) {
      utm = utm ? `${utm} - ${record.utm_content}` : record.utm_content;
    }

    if (utm !== "") {
      return (
        <div className="d-flex p-2 w-100">
          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
            <FontAwesomeIcon icon={faGlobePointer} size="xl" />
          </div>
          <div className="w-100">
            <div className="text-muted">UTM</div>
            <div className="fw-bold">{utm}</div>
          </div>
        </div>
      );
    }
  };

  // GRID SETUP -------------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const leadScoreColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Score Category",
      field: "scoreCat",
      flex: 1,
    },
    {
      headerName: "Score",
      field: "score",
      minWidth: 80,
      cellClass: "center",
      pinned: "right",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.data.score;
        }
      },
    },
  ];

  const openDealColumn = [
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      flex: 1,
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
      flex: 1,
    },
  ];

  const wonDealColumn = [
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      flex: 1,
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
      flex: 1,
    },
  ];

  const lostDealColumn = [
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      flex: 1,
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
      flex: 1,
    },
  ];

  const archievedDealColumn = [
    {
      headerName: "Created",
      field: "deal_date_time_created",
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_created).format("lll");
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      flex: 1,
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
      flex: 1,
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
      flex: 1,
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const rowId = useMemo(() => {
    return (params) => params.index;
  }, []);

  // OPEN FUNCTION ----------------------------------------------------------------

  const openLeadScore = async () => {
    try {
      const response = await axios.get("ws/ws_lead_score.php", {
        params: {
          task: "getLeadScore",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const { scoreMatrixDetails } = response.data;
      const leadScoreList = [];

      if (scoreMatrixDetails && scoreMatrixDetails.length > 0) {
        for (let i = 0; i < scoreMatrixDetails.length; i++) {
          if (Number(scoreMatrixDetails[i].score) > 0 && Number(scoreMatrixDetails[i].score) <= Number(scoreMatrixDetails[i].maxScore)) {
            leadScoreList.push(scoreMatrixDetails[i]);
          }
        }
      }

      setLeadScoreList(leadScoreList);
      setIsLeadScore(true);
    } catch (error) {
      console.error(error);
    }
  };

  const openForm = (record) => {
    if (record.form_url) {
      window.open(`${record.form_url}?utoken=${prospectSession.user_ac_token}&ctoken=${prospectSession.company_token}&deal=${deal_id}`, "_blank");
    } else {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_form_url_isnt_defined"),
      });
    }
  };

  const openEditProspect = () => {
    setIsLoadingEditProspect(false);
    const contacts = [];
    const emails = [];
    let customFields = [];

    const contactNumbers = [deal.contact1, deal.contact2, deal.contact3];
    const contactCountryCode = [deal.contact1_prefix, deal.contact2_prefix, deal.contact3_prefix];

    const findContactPrefix = (contact) => {
      contact = contact.replace(/\s+/g, "");
      contact = contact.replace("+", "");

      var prefix2 = contact.substring(0, 2);
      var prefix3 = contact.substring(0, 3);
      var prefix4 = contact.substring(0, 4);

      const findPrefix2 = MobilePrefix.find((prefix) => prefix.value === prefix2);
      const findPrefix3 = MobilePrefix.find((prefix) => prefix.value === prefix3);
      const findPrefix4 = MobilePrefix.find((prefix) => prefix.value === prefix4);

      if (findPrefix2) {
        return findPrefix2;
      } else if (findPrefix3) {
        return findPrefix3;
      } else if (findPrefix4) {
        return findPrefix4;
      }

      return { label: "", value: "", regionCode: "" };
    };

    contactNumbers.forEach((contact, index) => {
      if (index === 0) {
        const contactPrefix = contactCountryCode[0]
          ? MobilePrefix.find((record) => record.value === contactCountryCode[0])
          : findContactPrefix(contact);
        contacts.push({
          contact_prefix: contactPrefix.value,
          contact_prefix_label: contactPrefix.label,
          contact_prefix_region: contactPrefix.regionCode,
          contact: contact
            ? contact
            : deal.prospect_contact_count > 0 && deal.prospect_contact_info[0]?.contact_num
            ? deal.prospect_contact_info[0].contact_num
            : "",
          main: true,
          index: 1,
        });
      } else {
        if (contact) {
          const contactPrefix = contactCountryCode[index]
            ? MobilePrefix.find((record) => record.value === contactCountryCode[index])
            : findContactPrefix(contact);
          contacts.push({
            contact_prefix: contactPrefix.value,
            contact_prefix_label: contactPrefix.label,
            contact_prefix_region: contactPrefix.regionCode,
            contact: contact,
            main: false,
            index: index + 1,
          });
        }
      }
    });

    const emailAddress = [deal.email1, deal.email2];
    emailAddress.forEach((email, index) => {
      if (index === 0) {
        emails.push({
          email: email
            ? email
            : deal.prospect_email_count > 0 && deal.prospect_email_info[0]?.email_title
            ? deal.prospect_email_info[0].email_title
            : "",
        });
      } else {
        if (email) {
          emails.push({ email: email });
        }
      }
    });

    if (Number(deal.custom_field_person) > 0) {
      customFields = deal.custom_field_person_data.map((record) => ({
        cf_id: record.cf_id,
        name: record.cf_id,
        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
        cf_label: record.cf_label,
      }));
    }

    setEditProspectData({
      prospect_id: deal.prospect_id,
      prospect_name: deal.prospect_name ? deal.prospect_name : "",
      address1: deal.address_line1 ? deal.address_line1 : "",
      address2: deal.address_line2 ? deal.address_line2 : "",
      address3: deal.address_line3 ? deal.address_line3 : "",
      postcode: deal.postal_code ? deal.postal_code : "",
      designation: { label: deal.designation_title ? deal.designation_title : "", value: deal.designation_id ? deal.designation_id : "" },
      country: Number(deal.country_id) > 0 ? country.find((record) => Number(record.value) === Number(deal.country_id)) : "",
      state: Number(deal.state_id) > 0 ? state.find((record) => Number(record.value) === Number(deal.state_id)) : "",
      state_show: true,
      city: Number(deal.city_id) > 0 ? city.find((record) => Number(record.value) === Number(deal.city_id)) : "",
      city_show: true,
      owner: user.find((record) => Number(record.value) === Number(deal.prospect_owner)),
      deal_linked: false,
      contact: contacts,
      email: emails,
      cf_person: customFields,
    });

    setTimeout(() => {
      setIsEditProspect(true);
    }, 100);
  };
  // USEEFFECT FUNCTION -----------------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        try {
          await getDealLinkedUser();
        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    initializeData();
  }, [initialized.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prospectSession.user_ac_token && initialized.current) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const modalWidth = 400;
      const modalHeight = 300;
      const x = (windowWidth - modalWidth) / 2;
      const y = (windowHeight - modalHeight) / 2;

      setCallPosition({ x, y });
      getTwilioCred();
    }
  }, [initialized.current, prospectSession]);

  useEffect(() => {
    if (initialized.current) {
      setIsLoading(true);
      initialized.current = false;
    }
  }, [deal_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth - 50);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, [containerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (session) {
      setMeetingDurationList(setGenerateDurationList(Number(session.activity_duration_setting)));
      setMeetingTimingsList(setGenerateTimings(8, 22, Number(session.activity_duration_setting)));
    }
  }, [session]);

  useEffect(() => {
    if (initialized.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=deal_" + deal_id,
      });

      socket.on("chat message", function (msg) {
        setIsTimeline(false);
        getDeals(prospectSession);
        getTimeline(true, prospectSession);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [initialized.current]);

  useEffect(() => {
    if (initialized.current && deal && Number(deal?.prospect_id) > 0) {
      const prospect_socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=prospect_" + deal.prospect_id,
      });

      prospect_socket.on("chat message", function (msg) {
        setIsTimeline(false);
        getDeals(prospectSession);
        getTimeline(true, prospectSession);
      });

      return () => {
        prospect_socket.disconnect();
      };
    }
  }, [initialized.current, deal]);

  useEffect(() => {
    if (callSID !== "") {
      const twilioSocket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=callsid_" + callSID,
      });

      twilioSocket.on("chat message", function (msg) {
        setCallStatus("connected");
        setCallStart("start");

        if (msg === "disconnect") {
          sendHangUpCall();
        }
      });

      return () => {
        twilioSocket.disconnect();
      };
    }
  }, [callSID]);

  useEffect(() => {
    if (callStart === "start") {
      timerRef.current = setInterval(() => {
        timeRef.current += 1;
        const hours = String(Math.floor(timeRef.current / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((timeRef.current % 3600) / 60)).padStart(2, "0");
        const seconds = String(timeRef.current % 60).padStart(2, "0");
        if (hours !== "00") {
          setCallTimer(`${hours}:${minutes}:${seconds}`);
        } else {
          setCallTimer(`${minutes}:${seconds}`);
        }
      }, 1000);
    } else if (callStart === "stop") {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [callStart]);

  useEffect(() => {
    if (leadScoreUpdate) {
      getLeadScore(deal.prospect_id, prospectSession);
      setLeadScoreUpdate(false);
    }
  }, [leadScoreUpdate]);

  return (
    <Container fluid style={{ padding: 0 }}>
      {isLoading || loading2 ? (
        <Loader />
      ) : (
        <div className="main">
          <div className="section-header">
            <Row>
              <Col xxl={12} className="py-3 px-5">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="op-text-bigger fs-5 me-2">{setDealTitle(deal.deal_title)}</div>
                      <div
                        className={`op-label me-2 text-light d-flex align-items-center py-2 ${
                          deal.deal_status === "new"
                            ? "bg-danger"
                            : deal.deal_status === "inactive"
                            ? "bg-warning"
                            : deal.deal_status === "active"
                            ? "bg-success"
                            : ""
                        }`}
                        style={{ padding: "5px 10px", borderRadius: 5, position: "relative", fontSize: 10 }}
                      >
                        {deal.deal_status === "new"
                          ? t("DealList_contact_lead")
                          : deal.deal_status === "inactive"
                          ? t("DealList_follow_up")
                          : deal.deal_status === "active"
                          ? t("DealList_progressing")
                          : ""}
                      </div>
                      {session.subscription_tier && Number(session.subscription_tier) > 2 && (
                        <div className="op-label circular op-primary-color text-light cursor-pointer" onClick={openLeadScore}>
                          {leadScore}
                        </div>
                      )}
                      <div className="d-flex align-items-center ms-2">
                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1;
                          return (
                            <label key={index}>
                              <input
                                className="d-none"
                                type="radio"
                                name="rating"
                                value={currentRating}
                                onClick={() => onchangePriority(currentRating)}
                              />
                              <FontAwesomeIcon
                                icon={faStar}
                                style={{ color: currentRating <= (hoverRating || rating) ? "#ffd43b" : "#ddd", cursor: "pointer" }}
                                onMouseEnter={() => setHoverRating(currentRating)}
                                onMouseLeave={() => setHoverRating(null)}
                              />
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="ms-auto" />

                  {(Number(session.avanser) === 1 || Number(session.twilio) === 1) && (
                    <Button
                      variant="light"
                      className="shadow-sm border rounded-circle me-3 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        if (Number(session.twilio) === 1) {
                          sendTwilioCallLead();
                        } else {
                          sendCallLead();
                        }
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-center" style={{ padding: "5px 0px" }}>
                        <FontAwesomeIcon icon={faPhone} size="lg" />
                      </div>
                    </Button>
                  )}

                  <div className="d-flex align-items-center justify-content-center">

                    {imgError ? (
                      <div className="avatar me-2">
                        <div className="avatar-img op-primary-color" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                          <div className="avatar-txt text-uppercase fs-5 text-light">{deal.user_name.split("")[0]}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="me-2">
                        <img
                          className="rounded-circle"
                          alt="owner_image"
                          src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${deal.owner_id}.png`}
                          width={35}
                          height={35}
                          onError={setCheckImg}
                        />
                      </div>
                    )}

                    <div className="h-100" style={{ lineHeight: 1.2 }}>
                      <span className="op-text-bigger fw-bold">{deal?.user_name ? deal.user_name : ""}</span> <br />
                      <span className="op-text-medium">{!isOwner ? t("DealDetails_contributor") : t("DealDetails_owner")}</span>
                    </div>

                    {Number(prospectSession.role_id) === 1 && (
                      <>
                        <Dropdown show={isTransferOwner} align="end" className="ms-2">
                          <Dropdown.Toggle className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                          <Dropdown.Menu as={RenderTransferOwnership} />
                        </Dropdown>
                      </>
                    )}
                  </div>

                  {deal.deal_archieve === "" && (
                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-success me-2"
                        onClick={() => {
                          if (Number(deal.won_cs) === 0 || deal.won_cs === null) {
                            setIsWon(true);
                          } else if (Number(deal.won_cs) === Number(deal.cs_id)) {
                            setIsWon(true);
                          } else {
                            setIsWon(false);
                            const message =
                              Number(session.naming_scheme) === 1
                                ? `Oops, you can't convert this ${session.top_menu_deal}`
                                : `Oops, you can't convert this deal yet.`;
                            Swal.fire({ icon: "warning", title: message, text: deal.won_cs_message });
                          }
                        }}
                      >
                        {pipeBtn.won}
                      </button>
                      <button type="button" className="btn btn-danger me-2" onClick={setIsLost}>
                        {pipeBtn.lost}
                      </button>
                    </div>
                  )}

                  {deal.deal_archieve !== "" && (
                    <>
                      {(Number(deal.owner_id) === Number(prospectSession.user_id) || Number(prospectSession.role_id) === 1) && (
                        <button className="btn btn-info text-light" onClick={() => sendReopenDeal()}>
                          Reopen
                        </button>
                      )}
                      {deal.deal_archieve === "won" && (
                        <div
                          pointerEvents="none"
                          className="bg-success text-light rounded"
                          style={{ padding: "0.375rem 0.75rem", fontSize: "0.75rem", cursor: "default" }}
                        >
                          {t("DealDetails_won")}
                        </div>
                      )}

                      {deal.deal_archieve === "lost" && (
                        <div
                          pointerEvents="none"
                          className="bg-danger text-light rounded"
                          style={{ padding: "0.375rem 0.75rem", fontSize: "0.75rem", cursor: "default" }}
                        >
                          {t("DealDetails_lost")}
                        </div>
                      )}

                      {deal.deal_archieve === "deleted" && (
                        <div
                          pointerEvents="none"
                          className="bg-danger text-light rounded"
                          style={{ padding: "0.375rem 0.75rem", fontSize: "0.75rem", cursor: "default" }}
                        >
                          {t("DealDetails_deleted")}
                        </div>
                      )}

                      {deal.deal_archieve === "won" &&
                        Number(deal.pipeline_id) === Number(session.deal_custom_action_pipe) &&
                        Number(prospectSession.role_id) === 1 && (
                          <div
                            pointerEvents="none"
                            className="bg-info text-light rounded"
                            style={{ padding: "0.375rem 0.75rem", fontSize: "0.75rem", cursor: "default" }}
                          >
                            {session.deal_custom_action}
                          </div>
                        )}
                    </>
                  )}

                  <RenderDealAction />
                </Stack>
              </Col>
              <Col xxl={12} className="pb-3 px-5">
                <div className="op steps w-100">
                  {status &&
                    deal &&
                    status.map((record, index) => (
                      <button
                        key={index}
                        className={`link step ${record.cs_index <= deal.cs_index ? "active" : ""}`}
                        style={{ paddingTop: statusOverdue ? "0.7rem" : "1rem", paddingBottom: statusOverdue ? "0.7rem" : "1rem" }}
                        onClick={() => onchangeStage(record)}
                      >
                        {record.cs_index <= deal.cs_index && (
                          <FontAwesomeIcon className="me-3" icon={faCircleCheck} style={{ color: "#fff" }} size="xl" />
                        )}{" "}
                        <br />
                        <div className="content">
                          <div className="title text-start">{record.cs_title}</div>
                          {Number(record.max_days) > 0 && Number(record.stageDuration) > Number(record.max_days) && (
                            <div className="description d-flex text-light" style={{ fontSize: 10 }}>
                              <FontAwesomeIcon icon={faCircleExclamation} className="me-1" style={{ color: "#fff", marginTop: 1 }} size="sm" />
                              {t("DealDetails_overdue_by_count_days", { count: record.stageOverdue })}
                            </div>
                          )}
                        </div>
                      </button>
                    ))}
                </div>
              </Col>
            </Row>
          </div>

          <div className="section-body">
            <div className="section-sidebar">
              <Accordion defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} alwaysOpen>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="9">Summary</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      {(setCheckValidUser() || setCheckValidAdminUser()) && isProspectAllowEditContact && (
                        <button
                          type="button"
                          className="btn btn-light border rounded"
                          onClick={() => {
                            setIsLoadingEditSummary(false);
                            setIsEditDealInfo(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      )}
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="9">
                    <div className="accordion-body px-0">
                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faCircleDollar} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Deal Value</div>
                          <div className="fw-bold">{deal.deal_value ? setCurrency(deal.deal_value) : "RM 0.00"}</div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBarsStaggered} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Pipeline</div>
                          <div className="fw-bold">{deal.pipeline_title ? deal.pipeline_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faFlag} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Expected Close Date</div>
                          <div className="fw-bold">
                            {deal.deal_expected_close_date ? setDateStr(deal.deal_expected_close_date) : t("DealDetails_no_date")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>

                {(Number(deal.final_conversion) === 1 || Number(deal.real_estate_final) === 1) &&
                  Number(session.subscription_tier) > 2 &&
                  Number(session.booking_module) === 1 &&
                  session.layout_mode === "real-estate" && (
                    <div className="sidebar-accordion">
                      <div className="d-flex justify-content-between align-items-center">
                        <RenderAccordionButton eventKey="0">{t("DealDetails_unit_booking")}</RenderAccordionButton>
                        <div className="btn-group shadow-sm">
                          {booking.length > 0 ? (
                            <Button
                              className="text-capitalize text-light"
                              variant={setStatusColor(booking[booking.length - 1].booking_status)}
                              style={{ fontSize: 12 }}
                            >
                              {booking[booking.length - 1].booking_status}
                            </Button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-light border"
                              onClick={() => navigate(`/booking/project/${deal_id}/${deal.prospect_id}`)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </div>
                      </div>

                      <Accordion.Collapse eventKey="0">
                        <div className="accordion-body px-0">
                          <div className="d-flex align-items-center p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faBuildings} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">Project</div>
                              <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].project_name : "-"}</div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faHouseBuilding} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">Phase</div>
                              <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].phase_name : "-"}</div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faHouseChimneyWindow} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">Unit Type</div>
                              <div className="fw-bold">
                                {booking.length > 0 ? `${booking[booking.length - 1].unit_title} ${booking[booking.length - 1].unit_type}` : "-"}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faHouse} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">Unit Number</div>
                              <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].unit_number : "-"}</div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  )}

                {session.layout_mode === "standard" && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("DealDetails_product")}</RenderAccordionButton>
                      {Number(prospectSession.role_id) === 1 && (
                        <div className="btn-group shadow-sm">
                          <button type="button" className="btn btn-light border" onClick={setisAddProduct}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      )}
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body px-0">
                        {Number(productCount) > 0 ? (
                          dealProduct.map((record, index) => (
                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faBox} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="fw-bold">
                                  {record.product_title} - {record.total_qty}x
                                </div>
                                {Number(record.discount) > 0 && (
                                  <div className="fw-bold">
                                    <span className="text-muted">Discount -</span> {setCurrency(record.discount)}
                                  </div>
                                )}
                                <div className="fw-bold">
                                  <span className="text-muted">Final Price -</span> {setCurrency(record.final_price)}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t("DealDetails_prospect")}</RenderAccordionButton>
                    {(setCheckValidUser() || setCheckValidAdminUser()) &&
                      isProspectAllowEditContact &&
                      Number(prospectSession.company_id) === Number(session.company_id) && (
                        <div className="btn-group shadow-sm">
                          {Number(prospectSession.company_id) === Number(session.company_id) && (
                            <button
                              type="button"
                              className="btn btn-light border"
                              onClick={() => {
                                setIsLoadingChangeProspect(false);
                                setIsChangeProspect(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faRightLeft} />
                            </button>
                          )}

                          <button type="button" className="btn btn-light border" onClick={openEditProspect}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </div>
                      )}
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body px-0">
                      <div
                        className="d-flex justify-content-between align-items-center p-2 w-100 cursor-pointer hover-bg-secondary rounded"
                        onClick={() => {
                          if (isProspectAllowEditContact) {
                            window.open(`${session.origin}/prospect/${deal.prospect_id}`, "_blank");
                          } else {
                            Swal.fire({
                              text: "You are not allowed to view this prospect",
                              icon: "warning",
                            });
                          }
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faUser} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">{t("DealDetails_prospect_name")}</div>
                            <div className="fw-bold">{deal.prospect_name ? deal.prospect_name : "-"}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBriefcase} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Designation</div>
                          <div className="fw-bold">{deal.designation_title ? deal.designation_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Contact Number</div>
                          <div className="fw-bold">
                            {setPhoneNumber(
                              deal.contact1_prefix,
                              deal.contact1 ? deal.contact1 : deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : ""
                            )}
                          </div>
                          {deal.contact2 && <div className="fw-bold">{setPhoneNumber(deal.contact2_prefix, deal.contact2 ? deal.contact2 : "")}</div>}
                          {deal.contact3 && <div className="fw-bold">{setPhoneNumber(deal.contact3_prefix, deal.contact3 ? deal.contact3 : "")}</div>}
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faEnvelope} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Email</div>
                          <div className="fw-bold">
                            {deal.email1 ? deal.email1 : deal.prospect_email_count > 0 ? deal.prospect_email_info[0].email_title : "-"}
                          </div>
                          {deal.email2 && <div className="fw-bold">{deal.email2 ? deal.email2 : ""}</div>}
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Address</div>
                          <div className="fw-bold">{setProspectAddress(deal)}</div>
                        </div>
                      </div>

                      {deal &&
                        deal.custom_field_person > 0 &&
                        deal.custom_field_person_data.map((record, index) => (
                          <div className="d-flex p-2 w-100" key={index}>
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted"> {record.cf_label}</div>
                              {setCustomFieldValues(record)}
                            </div>
                          </div>
                        ))}

                      {Number(prospectSession.role_id) === 1 && (
                        <div
                          className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer"
                          onClick={() => window.open(`${session.origin}/settings/fields/person`, "_blank")}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Create additional field</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="10">Source</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button
                        type="button"
                        className="btn btn-light border"
                        disabled={duplicateLoadingBtn}
                        onClick={() => getDuplicateList(deal.prospect_id)}
                      >
                        {duplicateLoadingBtn ? <Spinner animation="border" variant="primary" size="sm" /> : "Duplicate List"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light border"
                        onClick={() => {
                          setIsLoadingEditSource(false);
                          setIsEditSource(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="10">
                    <div className="accordion-body px-0">
                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBullhorn} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Deal Source</div>
                          <div className="fw-bold">{deal.ds_title ? deal.ds_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faHandHoldingHand} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Business Unit</div>
                          <div className="fw-bold">{deal.unit_title ? deal.unit_title : "-"}</div>
                        </div>
                      </div>

                      <RenderUTM record={deal} />
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(session.company_type_id) === 0 && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="6">Organization</RenderAccordionButton>
                      {(setCheckValidUser() || setCheckValidAdminUser()) &&
                        isOrganizationAllowEditContact &&
                        Number(prospectSession.company_id) === Number(session.company_id) && (
                          <div className="btn-group shadow-sm">
                            {deal?.organization_title && (
                              <button type="button" className="btn btn-light border" onClick={() => getOrganizationData(prospectSession)}>
                                Find Info
                              </button>
                            )}

                            {deal?.organization_title && (
                              <button
                                type="button"
                                className="btn btn-light border"
                                onClick={() => {
                                  setIsLoadingEditOrganization(false);
                                  setIsEditOrganization(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </button>
                            )}

                            {deal?.organization_title && <RenderOrganizationAction />}

                            {!deal?.organization_title && (
                              <button
                                type="button"
                                className="btn btn-light border"
                                onClick={() => {
                                  setIsLoadingAddOrganization(false);
                                  setIsAddOrganization(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            )}
                          </div>
                        )}
                    </div>

                    <Accordion.Collapse eventKey="6">
                      <div className="accordion-body px-0">
                        {deal?.organization_title ? (
                          <>
                            <div
                              className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                              onClick={() => {
                                if (isOrganizationAllowEditContact) {
                                  window.open(`${session.origin}/organizations/${deal?.organization_id}`, "_blank");
                                } else {
                                  Swal.fire({
                                    text: "You are not allowed to view this organization",
                                    icon: "warning",
                                  });
                                }
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faBuildings} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">Organization Name</div>
                                  <div className="fw-bold">{deal?.organization_title ? deal?.organization_title : "-"}</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Address</div>
                                <div className="fw-bold">{deal?.organization_address ? deal?.organization_address : "-"}</div>
                              </div>
                            </div>

                            <div
                              className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                              onClick={() => {
                                if (deal?.organization_web_url) {
                                  window.open(`${deal?.organization_web_url}`, "_blank");
                                } else {
                                  Swal.fire({
                                    text: "The website URL is currently unavailable. Please check back your organization information to update the website URL or contact support for assistance.",
                                    icon: "warning",
                                  });
                                }
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faGlobe} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">Website</div>
                                  <div className="fw-bold">{deal?.organization_web_url ? deal?.organization_web_url : "-"}</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faPhone} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Contact</div>
                                <div className="fw-bold">{deal?.organization_contact ? deal?.organization_contact : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faIndustryWindows} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Industry</div>
                                {setOrganizationIndustryValue()}
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faEarthAsia} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Country</div>
                                {setOrganizationCountryValue()}
                              </div>
                            </div>

                            {deal.custom_field_organization > 0 &&
                              deal.custom_field_organization_data.map((record, index) => (
                                <div className="d-flex p-2 w-100" key={index}>
                                  <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                    <FontAwesomeIcon icon={faPlus} size="xl" />
                                  </div>
                                  <div className="w-100">
                                    <div className="text-muted">{record.cf_label}</div>
                                    {setCustomFieldValues(record)}
                                  </div>
                                </div>
                              ))}

                            {Number(prospectSession.role_id) === 1 && (
                              <div
                                className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer"
                                onClick={() => window.open(`${session.origin}/settings/fields/organization`, "_blank")}
                              >
                                <div className="d-flex align-items-center w-100">
                                  <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                    <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                                  </div>
                                  <div className="w-100">
                                    <div className="link-primary">Create additional field</div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="2">Details</RenderAccordionButton>
                    {deal && deal.custom_field > 0 && (
                      <div className="btn-group shadow-sm">
                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            setIsLoadingEditDeal(false);
                            setIsEditDeal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      </div>
                    )}
                  </div>

                  <Accordion.Collapse eventKey="2">
                    <div className="accordion-body px-0">
                      {deal &&
                        deal.custom_field > 0 &&
                        deal.custom_field_data.map((record, index) => (
                          <div key={index}>
                            {record.cf_view_detail === "yes" && (
                              <div className="d-flex p-2 w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faPlus} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">{record.cf_label}</div>
                                  {setCustomFieldValues(record)}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      {Number(prospectSession.role_id) === 1 && (
                        <div
                          className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer"
                          onClick={() => window.open(`${session.origin}/settings/fields/deal`, "_blank")}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Create additional field</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(Number(session.subscription_tier) > 2) && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="3">{t("DealDetails_analytics")}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="3">
                      <div className="accordion-body px-0">
                        <div
                          className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                          onClick={setIsOpenDeal}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleDollar} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_count_inbound_lead", { count: analytics.total_open })}</div>
                              <div className="fw-bold">{setCurrency(analytics.open_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                          onClick={setIsWonDeal}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleChecklight} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">
                                {t("DealDetails_count_sales_final_conversion_pipeline", { count: analytics.total_won })}
                              </div>
                              <div className="fw-bold">{setCurrency(analytics.won_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                          onClick={setIsLostDeal}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleXmarklight} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_dropped_deals", { count: analytics.total_lost })}</div>
                              <div className="fw-bold">{setCurrency(analytics.lost_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                          onClick={setIsArchievedDeal}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faBoxArchive} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_count_archieved_deals", { count: analytics.total_deleted })}</div>
                              <div className="fw-bold">{setCurrency(analytics.deleted_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="4">{t("DealDetails_contributor")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      {Number(prospectSession.company_id) === Number(session.company_id) && (
                        <button type="button" className="btn btn-light border" onClick={setIsAddContributor}>
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="4">
                    <div className="accordion-body px-0">
                      {Number(deal.contributor_count) > 0 ? (
                        deal.contributor.map((record, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faUserTie} size="xl" />
                              </div>
                              <div className="w-100">{record.user_name}</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleXmark} size="lg" onClick={() => sendDeleteContributor(record)} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="5">{t("DealDetails_forms")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button type="button" className="btn btn-light border" onClick={setisAddForm}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="5">
                    <div className="accordion-body px-0">
                      {Number(deal.totalFormsCreated) > 0 ? (
                        deal.custom_form_created.map((record, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faBrowser} size="xl" />
                              </div>
                              <div className="w-100 cursor-pointer" onClick={() => openForm(record)}>
                                {record.form_deal_title}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleXmark} size="lg" onClick={() => sendDeleteForm(record)} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(session.company_id) === 287 && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="7">Referral</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            if (session.hostUrlType === "cloud_staging") {
                              window.open(`${session.hostUrl}/form/rt_staging/${deal.prospect_token}`, "_blank");
                            } else {
                              window.open(`${session.hostUrl}/form/rt/${deal.prospect_token}`, "_blank");
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faList} size="lg" />
                        </button>

                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            var prospectType = "";
                            deal.custom_field_person_data.forEach((data) => {
                              if (Number(data.cf_id) === 6078 || Number(data.cf_id) === 6864) {
                                prospectType = data.fd_value;
                              }
                            });

                            if (prospectType.toLocaleLowerCase() === "customer") {
                              if (session.hostUrlType === "cloud_staging") {
                                window.open(`${session.hostUrl}/form/rc_staging/${deal.prospect_token}/${prospectSession.company_token}`, "_blank");
                              } else {
                                window.open(`${session.hostUrl}/form/rc/${deal.prospect_token}/${prospectSession.company_token}`, "_blank");
                              }
                            } else if (prospectType.toLocaleLowerCase() === "agent") {
                              if (session.hostUrlType === "cloud_staging") {
                                window.open(`${session.hostUrl}/form/ra_staging/${deal.prospect_token}/${prospectSession.company_token}`, "_blank");
                              } else {
                                window.open(`${session.hostUrl}/form/ra/${deal.prospect_token}/${prospectSession.company_token}`, "_blank");
                              }
                            } else {
                              Swal.fire({
                                icon: "warning",
                                title: "Alert",
                                text: "Please fill in prospect type",
                              });
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faClipboard} size="lg" />
                        </button>
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="7">
                      <div className="accordion-body px-0">
                        <ReferralComponent prospectID={deal.prospect_id} dealID={deal_id} />
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="8">Meetup</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="8">
                    <div className="accordion-body px-0">
                      {meetupLink.status === 0 ? (
                        <div
                          className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer"
                          onClick={() => window.open(meetupLink.meetup_link, "_blank")}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faLink} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Link</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className="section-content position-relative" ref={containerRef}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t("DealDetails_notes")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t("DealDetails_activity")}
                        </Nav.Link>
                      </Nav.Item>

                      {Number(meetupLink.status) === 0 && (
                        <Nav.Item>
                          <Nav.Link eventKey="meeting_scheduler">
                            <FontAwesomeIcon icon={faCalendarClock} className="me-2" size="lg" />
                            Meeting Scheduler
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      <Nav.Item>
                        <Nav.Link eventKey="files">
                          <FontAwesomeIcon icon={faPaperclipVertical} className="me-2" size="lg" />
                          {t("DealDetails_files")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="messages">
                          <FontAwesomeIcon icon={faEnvelope} className="me-2" size="lg" />
                          {t("DealDetails_messages")}
                        </Nav.Link>
                      </Nav.Item>

                      {Number(session.ai_note) === 1 && (
                        <Nav.Item>
                          <Nav.Link eventKey="ai_follow_up">
                            <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" size="lg" />
                            AI Follow Up
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab
                    tab={activeTab}
                    customActivityList={customActivityList}
                    active={isActiveTab}
                    activeLoading={isActiveTabLoading}
                    userListNotes={userNotes}
                    activityUser={userActivity}
                    isSubmitting={isSubmitting}
                    disabled={disabledBtn}
                    followUpLoading={modalLoading}
                    followUpData={aiFollowUpData}
                  />
                </Card>

                {Number(session.ai_note) === 1 && (
                  <Card className="mt-2 border-0">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex align-items-center mb-2">
                          <FontAwesomeIcon icon={faSparkles} size="xl" className="me-2" />
                          <div className="fw-bold" style={{ fontSize: 15 }}>
                            AI Timeline Summary
                          </div>
                        </div>
                        {isAiTimelineLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <Button
                            variant="link"
                            className="p-0 text-decoration-none"
                            onClick={() => getAiTimelineSummary(prospectSession, deal.prospect_id)}
                          >
                            Regenerate
                          </Button>
                        )}
                      </div>
                      <Row>
                        <Col sm={7}>
                          {isAiTimelineLoading ? (
                            <Card className="border-0">
                              <Card.Body>
                                <Placeholder as={Card.Text} animation="glow" className="mb-0">
                                  <Placeholder xs={7} bg="secondary" /> <Placeholder xs={4} bg="secondary" /> <Placeholder xs={4} bg="secondary" />
                                  <Placeholder xs={6} bg="secondary" /> <Placeholder xs={8} bg="secondary" />
                                </Placeholder>
                              </Card.Body>
                            </Card>
                          ) : (
                            <Card className="border-0">
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  <div dangerouslySetInnerHTML={{ __html: setFormatMessage(aiTimelineSummaryData.timelineSummary) }} />{" "}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          )}
                        </Col>

                        <Col sm={5}>
                          {isAiTimelineLoading ? (
                            <Card style={{ backgroundColor: "#eee" }}>
                              <Card.Body>
                                <Placeholder as={Card.Text} animation="glow" className="mb-0">
                                  <Placeholder xs={7} bg="secondary" /> <Placeholder xs={4} bg="secondary" /> <Placeholder xs={4} bg="secondary" />
                                  <Placeholder xs={6} bg="secondary" /> <Placeholder xs={8} bg="secondary" />
                                </Placeholder>
                              </Card.Body>
                            </Card>
                          ) : aiTimelineSummaryData.extraNotes ? (
                            <Card style={{ backgroundColor: "#eee" }}>
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  <div dangerouslySetInnerHTML={{ __html: setFormatMessage(aiTimelineSummaryData.extraNotes) }} />{" "}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}

                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("DealDetails_timeline")} </RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0" style={{ width: width }}>
                      <div className="accordion-body">
                        {loadingTimeline ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                              <Spinner animation="border" role="status" size="sm">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </div>
                        ) : (
                          <>
                            <ButtonGroup className="timeline-tab mb-3">
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_1"
                                value="1"
                                checked={timelineFilter === "all"}
                                onClick={() => setTimelineFilter("all")}
                              >
                                All
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "note"}
                                onClick={() => setTimelineFilter("note")}
                              >
                                Notes ({timeline.filter((record) => record.record_type === "Note").length})
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "activity"}
                                onClick={() => setTimelineFilter("activity")}
                              >
                                Activities (
                                {
                                  timeline.filter(
                                    (record) =>
                                      (record.record_type === "Activity" || record.record_type === "NoEditActivity") &&
                                      [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id))
                                  ).length
                                }
                                )
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "email"}
                                onClick={() => setTimelineFilter("email")}
                              >
                                Email ({timeline.filter((record) => record.record_type === "Email").length})
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "whatsapp"}
                                onClick={() => setTimelineFilter("whatsapp")}
                              >
                                Whatsapp (
                                {
                                  timeline.filter(
                                    (record) =>
                                      (record.record_type === "Activity" || record.record_type === "NoEditActivity") &&
                                      [8].includes(Number(record.record_activity_type_id))
                                  ).length
                                }
                                )
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "file"}
                                onClick={() => setTimelineFilter("file")}
                              >
                                Files ({timeline.filter((record) => record.record_type === "File").length})
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="timeline_filter"
                                id="timeline_filter_2"
                                value="2"
                                checked={timelineFilter === "changelog"}
                                onClick={() => setTimelineFilter("changelog")}
                              >
                                Changelog
                              </ToggleButton>
                            </ButtonGroup>
                            {isTimeline && (
                              <>
                                {timeline && setTimelineFilterCount(timeline) ? (
                                  <VerticalTimeline layout="1-column-left">
                                    {timeline.filter(setTimelineFilterRecord).map((record, index) => (
                                      <VerticalTimelineElement
                                        key={index}
                                        date={setTimelineDateTime(record)}
                                        iconStyle={{ background: "#fff" }}
                                        icon={<RenderTimelineIcon record={record} />}
                                      >
                                        <Stack direction="horizontal" gap={1} style={{ alignItems: "normal" }}>
                                          <div style={{ width: setWidthTimeline(record) }}>
                                            <div className="op-text-bigger fw-bold">
                                              <RenderTimelineHeader record={record} />
                                            </div>
                                            <RenderTimelineDocument record={record} />
                                          </div>

                                          {setTimelineMAD(record) && (
                                            <div className="mt-1 me-1" style={{ width: "3%" }}>
                                              <div
                                                className="w-100 d-flex justify-content-center float-end"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => sendMarkAsDone(record)}
                                              >
                                                <FontAwesomeIcon
                                                  icon={record.record_activity_mad === "yes" ? faCircleCheck : faCircleLight}
                                                  size="2xl"
                                                />
                                              </div>
                                            </div>
                                          )}

                                          {record.record_type === "Activity" &&
                                            Number(record.record_activity_type_id) === 2 &&
                                            meetupLink.status === 0 &&
                                            record.meetup_cancel.status === 0 &&
                                            Number(record.meetup_cancel.record.cancel) === 0 &&
                                            record.record_activity_mad === "no" &&
                                            record.meeting_scheduler && (
                                              <div className="mt-1 me-2" style={{ width: "3%" }}>
                                                <div
                                                  className="w-100 d-flex justify-content-center"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setMeetingData(record);
                                                    setIsCancelMeetup(true);
                                                  }}
                                                >
                                                  <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#dc3545" }} />
                                                </div>
                                              </div>
                                            )}

                                          {setTimelineAction(record) && (
                                            <div style={{ width: "5%" }}>
                                              <div className="float-end">
                                                <RenderTimelineAction record={record} />
                                              </div>
                                            </div>
                                          )}
                                        </Stack>

                                        <div style={{ fontSize: 11, maxWidth: "100%", wordWrap: "break-word" }}>
                                          <RenderTimelineDescription record={record} insight={callAnalytics} />
                                        </div>
                                      </VerticalTimelineElement>
                                    ))}

                                    {["all", "changelog"].includes(timelineFilter) && (
                                      <VerticalTimelineElement
                                        date={moment(new Date(deal.deal_date_time_create)).format("lll")}
                                        iconStyle={{ background: "#fff" }}
                                        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                      >
                                        <div className="op-text-bigger fw-bold">{t("DealDetails_deal_created")}</div>
                                      </VerticalTimelineElement>
                                    )}
                                  </VerticalTimeline>
                                ) : timelineFilter === "note" ? (
                                  <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                                    <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#a)">
                                        <path
                                          d="M72.8 54.813H56.913a2.05 2.05 0 0 0-2.05 2.05V72.75M72.8 56.35V14.325a3.075 3.075 0 0 0-3.075-3.075h-59.45A3.075 3.075 0 0 0 7.2 14.325v55.35a3.075 3.075 0 0 0 3.075 3.075H56.4l16.4-16.4Z"
                                          stroke="#B6BBC3"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="m27.33 48.883 6.788 6.789 22.009-22.009 2.541-2.541a2.4 2.4 0 0 0 0-3.394l-3.394-3.394a2.4 2.4 0 0 0-3.394 0l-1.984 1.983 6.787 6.788-1.414 1.414-6.787-6.787L27.33 48.883Zm5.12 7.949-6.28-6.28-2.064 6.191-.697 2.09a.6.6 0 0 0 .76.76l2.087-.697 6.194-2.064Z"
                                          fill="#B6BBC3"
                                        ></path>
                                      </g>
                                      <defs>
                                        <clipPath id="a">
                                          <path fill="#fff" d="M0 0h80v80H0z"></path>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <span className="mt-2" style={{ fontSize: 14 }}>
                                      No notes added yet
                                    </span>
                                  </div>
                                ) : timelineFilter === "activity" ? (
                                  <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                                    <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M56 6v8M24 6v8M10 24.5h60M67 70H13a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v54a3 3 0 0 1-3 3Z"
                                        stroke="#B6BBC3"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M24 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM24 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                        fill="#B6BBC3"
                                      ></path>
                                    </svg>
                                    <span className="mt-2" style={{ fontSize: 14 }}>
                                      No schedule activity added yet
                                    </span>
                                  </div>
                                ) : timelineFilter === "file" ? (
                                  <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                                    <svg width="86" height="86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="m53.75 8.6.707-.707a1 1 0 0 0-.707-.293v1Zm17.2 17.2h1a1 1 0 0 0-.293-.707l-.707.707Zm0 48.375h1-1ZM18.275 9.6h33.863v-2H18.275v2Zm33.863 0h1.612v-2h-1.612v2ZM69.95 25.8v1.613h2V25.8h-2Zm0 1.613v46.762h2V27.413h-2ZM53.043 9.307l17.2 17.2 1.414-1.414-17.2-17.2-1.414 1.414ZM67.725 76.4h-49.45v2h49.45v-2ZM16.05 74.175v-62.35h-2v62.35h2ZM18.275 7.6a4.225 4.225 0 0 0-4.225 4.225h2c0-1.229.996-2.225 2.225-2.225v-2ZM69.95 74.175a2.225 2.225 0 0 1-2.225 2.225v2a4.225 4.225 0 0 0 4.225-4.225h-2ZM18.275 76.4a2.225 2.225 0 0 1-2.225-2.225h-2a4.225 4.225 0 0 0 4.225 4.225v-2Zm34.863-51.137V8.6h-2v16.663h2Zm17.812 1.15H54.288v2H70.95v-2Zm-19.812-1.15a3.15 3.15 0 0 0 3.15 3.15v-2a1.15 1.15 0 0 1-1.15-1.15h-2Z"
                                        fill="#B6BBC3"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M38 43v13a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V43h6.417a.583.583 0 0 0 .418-.989L43.418 30.176a.582.582 0 0 0-.824-.011l-4.024 4.136c-2.468 2.538-7.405 7.71-7.405 7.71a.582.582 0 0 0 .418.99H38Z"
                                        fill="#B6BBC3"
                                      ></path>
                                      <path d="M34 63h18" stroke="#B6BBC3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    <span className="mt-2" style={{ fontSize: 14 }}>
                                      No files added yet
                                    </span>
                                  </div>
                                ) : timelineFilter === "email" ? (
                                  <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                                    <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M10 21.5a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v37a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3v-37Z"
                                        stroke="#B6BBC3"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="m17.5 26 18.816 14.635a6 6 0 0 0 7.368 0L62.5 26"
                                        stroke="#B6BBC3"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                    <span className="mt-2" style={{ fontSize: 14 }}>
                                      No emails created yet
                                    </span>
                                  </div>
                                ) : timelineFilter === "whatsapp" ? (
                                  <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                                    <svg fill="#B6BBC3" width={80} height={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                                    </svg>
                                    <span className="mt-2" style={{ fontSize: 14 }}>
                                      No Whatsapp messages yet
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Container>
            </div>
          </div>

          <Modal show={isWon} onHide={setIsWon} size="md">
            <Formik
              onSubmit={sendDealWon}
              initialValues={{
                remark: "",
                deal_archieve: "won",
              }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isLost} onHide={setIsLost} size="md">
            <Formik
              onSubmit={sendDealLost}
              validationSchema={lostReasonSchema}
              initialValues={{
                remark: "",
                deal_archieve: "lost",
                lostReasonID: "",
              }}
            >
              {({ handleSubmit, handleChange, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Lost Reason {Number(session.lost_reason_compulsory) === 1 ? "*" : ""}</Form.Label>
                      <FormSelect
                        options={lostReason}
                        placeholder={t("DealDetails_select_reason")}
                        onChange={(info) => handleChange("lostReasonID")(info.value)}
                      />
                      {errors.lostReasonID && touched.lostReasonID && <div className="op-error-message">{errors.lostReasonID}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isDeleteDeal} onHide={setIsDeleteDeal} size="md">
            <Formik
              onSubmit={sendDeleteDeal}
              initialValues={{
                remark: "",
                deal_archieve: "deleted",
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isChangeProspect} onHide={setIsChangeProspect}>
            <Formik
              onSubmit={sendChangeProspect}
              validationSchema={changeProspectSchema}
              initialValues={{
                prospect: "",
              }}
            >
              {({ handleSubmit, handleChange, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton={isLoadingChangeProspect ? false : true}>
                    <Modal.Title as={"h6"}>{t("DealDetails_change_prospect")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <FormSelectCreatable
                        placeholder={t("DealDetails_find_prospect")}
                        loadOptions={getExistingProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect && touched.prospect}
                        width="100%"
                        onChange={(info) => {
                          handleChange({
                            target: { name: "prospect", value: info.value },
                          });
                        }}
                      />
                      {errors.prospect && touched.prospect && <div className="op-error-message">{errors.prospect}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingChangeProspect}>
                      {isLoadingChangeProspect ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditProspect} onHide={setIsEditProspect} size="lg" backdrop="static">
            <Formik onSubmit={sendEditProspect} validationSchema={editProspectSchema} initialValues={editProspectData}>
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <ScrollToFirstError2 />
                  <Modal.Header closeButton={isLoadingEditProspect ? false : true}>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_prospect")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealDetails_prospect_name")} *</Form.Label>
                          <Form.Control
                            name="prospect_name"
                            type="text"
                            value={values.prospect_name}
                            isInvalid={errors.prospect_name && touched.prospect_name}
                            onChange={handleChange("prospect_name")}
                          />
                          {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealDetails_designation")}</Form.Label>
                          <FormSelectCreatable
                            name="designation_title"
                            loadOptions={getExistingDesignation}
                            isLoading={isFindDesignation}
                            withValue
                            value={values.designation.label}
                            width="100%"
                            onChange={(e) => setFieldValue("designation", e)}
                          />
                        </Form.Group>

                        {Number(prospectSession.role_id) === 1 && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealDetails_prospect_owner")} *</Form.Label>
                            <FormSelect
                              name="owner"
                              options={user}
                              disabled={values.deal_linked}
                              valueDefault={values.owner}
                              isInvalid={errors.owner && touched.owner}
                              onChange={(e) => setFieldValue("owner", e)}
                            />
                            {errors.owner && errors.owner.value && touched.owner && touched.owner.value && (
                              <div className="op-error-message">{errors.owner.value}</div>
                            )}
                          </Form.Group>
                        )}

                        <Form.Group className="mb-3">
                          <Form.Label>Contact Number</Form.Label>
                          {values.contact.map((record, index) => (
                            <div className="w-100" key={index}>
                              <Stack direction="horizontal" className="mb-2" gap={2}>
                                <FormSelectV2
                                  name={`contact[${index}].contact_prefix`}
                                  options={MobilePrefix}
                                  isSearchable
                                  isInvalid={
                                    errors.contact &&
                                    errors.contact[index] &&
                                    errors.contact[index].contact_prefix &&
                                    touched.contact &&
                                    touched.contact[index] &&
                                    touched.contact[index].contact_prefix
                                  }
                                  value={MobilePrefix.find((record) => Number(record.value) === Number(values.contact[index].contact_prefix))}
                                  width="80px"
                                  onChange={(e) => {
                                    const updatedContact = [...values.contact];
                                    updatedContact[index].contact_prefix = e.value;
                                    updatedContact[index].contact_prefix_label = e.label;
                                    updatedContact[index].contact_prefix_region = e.regionCode;
                                    setFieldValue("contact", updatedContact);
                                  }}
                                />
                                <Form.Control
                                  name={`contact[${index}].contact`}
                                  type="text"
                                  value={record.contact}
                                  isInvalid={
                                    errors.contact &&
                                    errors.contact[index] &&
                                    errors.contact[index].contact &&
                                    touched.contact &&
                                    touched.contact[index] &&
                                    touched.contact[index].contact
                                  }
                                  onChange={(e) => {
                                    const updatedContact = [...values.contact];
                                    updatedContact[index].contact = e.target.value.replace(/\D/g, "");
                                    setFieldValue("contact", updatedContact);
                                  }}
                                />

                                {index > 0 && (
                                  <Button
                                    variant="light"
                                    className="border"
                                    onClick={() => {
                                      const updatedContact = [...values.contact];
                                      updatedContact.splice(index, 1);
                                      updatedContact.forEach((record, index) => (record.index = index + 1));
                                      setFieldValue("contact", updatedContact);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                )}
                              </Stack>
                              {setEditProspectContactError(errors, touched, index)}
                            </div>
                          ))}

                          {values.contact.length < 3 && (
                            <Button
                              style={{ textDecoration: "none", padding: 0 }}
                              variant="link"
                              onClick={() => {
                                setFieldValue("contact", [
                                  ...values.contact,
                                  {
                                    contact_prefix: "",
                                    contact_prefix_label: "",
                                    contact_prefix_region: "",
                                    contact: "",
                                    main: false,
                                    index: values.contact.length + 1,
                                  },
                                ]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> Add contact
                            </Button>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Email Address</Form.Label>
                          {values.email.map((record, index) => (
                            <div className="w-100" key={index}>
                              <Stack direction="horizontal" className="mb-2" gap={2} key={index}>
                                <Form.Control
                                  name={`email[${index}].email`}
                                  type="email"
                                  value={record.email}
                                  isInvalid={
                                    errors.email &&
                                    errors.email[index] &&
                                    errors.email[index].email &&
                                    touched.email &&
                                    touched.email[index] &&
                                    touched.email[index].email
                                  }
                                  onChange={(e) => {
                                    const updatedEmail = [...values.email];
                                    updatedEmail[index].email = e.target.value;
                                    setFieldValue("email", updatedEmail);
                                  }}
                                />

                                {index > 0 && (
                                  <Button
                                    variant="light"
                                    className="border"
                                    onClick={() => {
                                      const updatedEmail = [...values.email];
                                      updatedEmail.splice(index, 1);
                                      setFieldValue("email", updatedEmail);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                )}
                              </Stack>
                              {errors.email &&
                                errors.email[index] &&
                                errors.email[index].email &&
                                touched.email &&
                                touched.email[index] &&
                                touched.email[index].email && <div className="op-error-message">{errors.email[index].email}</div>}
                            </div>
                          ))}

                          {values.email.length < 2 && (
                            <Button
                              style={{ textDecoration: "none", padding: 0 }}
                              variant="link"
                              onClick={() => {
                                setFieldValue("email", [...values.email, { email: "" }]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> Add email
                            </Button>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Address Line</Form.Label>
                          <Form.Control name="address1" className="mb-2" type="text" value={values.address1} onChange={handleChange("address1")} />
                          <Form.Control name="address2" className="mb-2" type="text" value={values.address2} onChange={handleChange("address2")} />
                          <Form.Control name="address3" type="text" value={values.address3} onChange={handleChange("address3")} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealDetails_postal_code")}</Form.Label>
                          <Form.Control name="postcode" type="number" value={values.postcode} onChange={handleChange("postcode")} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealDetails_country")}</Form.Label>
                          <FormSelect
                            name="country_id"
                            options={country}
                            valueDefault={values.country}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => {
                              setFieldValue("country", e);
                              if (values.state !== "") {
                                setFieldValue("state_show", false);
                                setFieldValue("state", "");
                              }

                              if (values.city !== "") {
                                setFieldValue("city_show", false);
                                setFieldValue("city", "");
                              }

                              setTimeout(() => {
                                setFieldValue("state_show", true);
                                setFieldValue("city_show", true);
                              }, 100);
                            }}
                          />
                        </Form.Group>

                        {values.state_show && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealDetails_state")}</Form.Label>
                            <FormSelect
                              name="state_id"
                              options={
                                (values.country !== "" || Number(values.country.value)) > 0
                                  ? state.filter((record) => record.country === values.country.value)
                                  : state
                              }
                              valueDefault={values.state}
                              disabled={values.country === "" || values.country.value === ""}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => {
                                setFieldValue("state", e.value ? e : "");
                                if (values.city !== "") {
                                  setFieldValue("city", "");
                                  setFieldValue("city_show", false);
                                  setTimeout(() => {
                                    setFieldValue("city_show", true);
                                  }, 100);
                                }
                              }}
                            />
                          </Form.Group>
                        )}
                        {values.city_show && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("DealDetails_city")}</Form.Label>
                            <FormSelect
                              name="city_id"
                              disabled={values.state === "" || values.state.value === ""}
                              options={
                                values.state !== "" || Number(values.state.value) > 0
                                  ? city.filter((record) => record.state === values.state.value)
                                  : city
                              }
                              valueDefault={values.city}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => setFieldValue("city", e.value ? e : "")}
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Data Fields</div>
                        {deal.custom_field_person > 0 ? (
                          <>
                            {deal.custom_field_person_data.map((record, index) => (
                              <FormCustomField
                                key={record.cf_id}
                                id={record.cf_id}
                                label={record.cf_label}
                                options={record.cf_value}
                                type={record.ctf_title}
                                name={record.cf_id}
                                placeholder=""
                                firstOptions={false}
                                valueDefault={false}
                                isClearable={true}
                                value={values.cf_person[index].fd_values}
                                onChange={(info) => {
                                  const updatedCustomFields = [...values.cf_person];
                                  if (record.ctf_title === "Select") {
                                    updatedCustomFields[index].fd_values = info.value;
                                  } else if (record.ctf_title === "Radio") {
                                    if (info) {
                                      const { value, checked } = info.target;
                                      updatedCustomFields[index].fd_values = checked ? value : "";
                                    } else {
                                      updatedCustomFields[index].fd_values = "";
                                    }
                                  } else if (record.ctf_title === "Checkbox") {
                                    const { value, checked } = info.target;
                                    if (checked) {
                                      updatedCustomFields[index].fd_values.push(value);
                                    } else {
                                      const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                      if (indexToRemove !== -1) {
                                        updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                      }
                                    }
                                  } else if (record.ctf_title === "Date") {
                                    updatedCustomFields[index].fd_values = info;
                                  } else {
                                    updatedCustomFields[index].fd_values = info.target.value;
                                  }

                                  setFieldValue("cf_person", updatedCustomFields);
                                }}
                              />
                            ))}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No data fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" disabled={isLoadingEditProspect} className="btn op-button op-primary-color text-light shadow">
                      {isLoadingEditProspect ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditDeal} onHide={setIsEditDeal} backdrop="static">
            <Formik
              onSubmit={sendEditDeal}
              initialValues={{
                cf_deal:
                  deal && deal.custom_field && deal.custom_field > 0
                    ? deal.custom_field_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton={isLoadingEditDeal ? false : true}>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    {deal &&
                      deal.custom_field > 0 &&
                      deal.custom_field_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_deal[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_deal];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              if (info) {
                                const { value, checked } = info.target;
                                updatedCustomFields[index].fd_values = checked ? value : "";
                              } else {
                                updatedCustomFields[index].fd_values = "";
                              }
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_deal", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingEditDeal}>
                      {isLoadingEditDeal ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddContributor} onHide={setIsAddContributor}>
            <Formik
              onSubmit={sendAddContributor}
              validationSchema={addContributorSchema}
              initialValues={{
                user_id: Number(prospectSession.company_id) === Number(session.company_id) ? prospectSession.user_id : "",
                deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_add_contributor")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_user")} *</Form.Label>
                      <FormSelect
                        options={user}
                        disabled={values.deal_linked}
                        valueDefault={user.find((record) => Number(record.value) === Number(values.user_id))}
                        onChange={(e) => handleChange("user_id")(e.value)}
                      />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddForm} onHide={setisAddForm}>
            <Formik
              onSubmit={sendAddForm}
              validationSchema={addFormSchema}
              initialValues={{
                form_id: "",
                form_title: "",
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_add_form")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_form")} *</Form.Label>
                      <FormSelect
                        options={form}
                        onChange={(e) => {
                          setFieldValue("form_id", e ? e.value : "");
                          setFieldValue("form_title", e ? e.label : "");
                        }}
                      />
                      {errors.form_id && touched.form_id && <div className="op-error-message">{errors.form_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik validationSchema={editActivitySchema} initialValues={initEditActivity} onSubmit={sendEditActivity}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>
                      {t("DealDetails_edit_activity")} - {setActivityType(values.activity_type, values.ca_id)}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={t("DealDetails_activity_title") + " *"}
                            value={values.activity_title}
                            isInvalid={errors.activity_title && touched.activity_title}
                            onChange={handleChange("activity_title")}
                          />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={timings}
                              valueDefault={timings.find((record) => record.value === values.activity_time)}
                              placeholder={t("DealDetails_select_time")}
                              onChange={(e) => handleChange("activity_time")(e.value)}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={durationList}
                              valueDefault={durationList.find((record) => record.value === values.activity_duration)}
                              placeholder={t("DealDetails_select_duration")}
                              onChange={(e) => handleChange("activity_duration")(e.value)}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("DealDetails_select_user") + " *"}
                            disabled={values.deal_linked}
                            options={userActivity}
                            valueDefault={userActivity.find((record) => Number(record.value) === Number(values.activity_user))}
                            onChange={(e) => handleChange("activity_user")(e.value)}
                          />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        label={t("DealDetails_mark_as_done")}
                        className="d-flex justify-content-center align-items-center me-3"
                        checked={values.mad}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "mad", value: e.target.checked },
                          });
                        }}
                      />
                      <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isSubmitting}>
                        {t("DealDetails_save")}
                      </button>
                    </div>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditMeeting} onHide={setIsEditMeeting} size="lg">
            <Formik validationSchema={editActivitySchema} initialValues={initEditMeeting} onSubmit={sendEditMeeting}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Meeting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={t("DealDetails_activity_title")}
                            value={values.activity_title}
                            isInvalid={errors.activity_title && touched.activity_title}
                            onChange={handleChange("activity_title")}
                          />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={Number(values.activity_type) === 2 ? meetingTimingsList : timings}
                              valueDefault={
                                Number(values.activity_type) === 2
                                  ? meetingTimingsList.find((record) => record.value === values.activity_time)
                                  : timings.find((record) => record.value === values.activity_time)
                              }
                              placeholder={t("DealDetails_select_time")}
                              onChange={(e) => handleChange("activity_time")(e.value)}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={Number(values.activity_type) === 2 ? meetingDurationList : durationList}
                              valueDefault={
                                Number(values.activity_type) === 2
                                  ? meetingDurationList.find((record) => record.value === values.activity_duration)
                                  : durationList.find((record) => record.value === values.activity_duration)
                              }
                              placeholder={t("DealDetails_select_duration")}
                              onChange={(e) => handleChange("activity_duration")(e.value)}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("DealDetails_select_user")}
                            options={userActivity}
                            valueDefault={userActivity.find((record) => record.value === values.activity_user)}
                            onChange={(e) => handleChange("activity_user")(e.value)}
                          />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label={t("DealDetails_mark_as_done")}
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.mad}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "mad", value: e.target.checked },
                        });
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isSubmitting}>
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setisEditNote} size="lg">
            <Formik onSubmit={sendEditNotes} validationSchema={editNotesSchema} initialValues={initEditNotes}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_notes")} </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <NoteEditor value={values.notes} onChangeValue={(value) => handleChange("notes")(value)} />
                      {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isLeadScore} onHide={setIsLeadScore} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>
                {t("DealDetails_rubric_score")} ({leadScore})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {leadScoreList.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact
                      rowData={leadScoreList}
                      columnDefs={leadScoreColumn}
                      getRowId={rowId}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isEditDealInfo} onHide={setIsEditDealInfo} backdrop="static">
            <Formik
              onSubmit={sendEditDealInfo}
              validationSchema={editDealInfoSchema}
              initialValues={{
                deal_title: deal.deal_title || "",
                deal_price: deal.deal_value || 0,
                deal_expected_close:
                  deal && deal.deal_expected_close_date !== "0000-00-00" ? moment(new Date(deal.deal_expected_close_date)).format("YYYY-MM-DD") : "",
                deal_added: moment(new Date(deal.deal_date_time_create)).format("YYYY-MM-DD"),
                deal_close:
                  deal.deal_archieve === "won"
                    ? moment(new Date(deal.deal_archieve_won_log)).format("YYYY-MM-DD")
                    : deal.deal_archieve === "lost"
                    ? moment(new Date(deal.deal_archieve_lost_log)).format("YYYY-MM-DD")
                    : "",
                deal_archieve: deal.deal_archieve,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton={isLoadingEditSummary ? false : true}>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal_information")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_title")} *</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.deal_title}
                        isInvalid={errors.deal_title && touched.deal_title}
                        onChange={(e) => handleChange("deal_title")(e.target.value)}
                      />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_price")}</Form.Label>
                      <FormCurrency
                        type="text"
                        value={values.deal_price}
                        onChange={(e) => {
                          handleChange("deal_price")(e);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_expected_close")} </Form.Label>
                      <FormDate
                        value={values.deal_expected_close}
                        onChange={(date) => handleChange("deal_expected_close")(date)}
                      />
                    </Form.Group>

                    {Number(session.deal_details_date_change) === 0 && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealDetails_deal_added")} *</Form.Label>
                      <FormDate
                        value={values.deal_added}
                        isInvalid={errors.deal_title && touched.deal_title}
                        onChange={(date) => handleChange("deal_added")(date)}
                      />
                        {errors.deal_added && touched.deal_added && <div className="op-error-message">{errors.deal_added}</div>}
                      </Form.Group>
                    )}

                    {(deal.deal_archieve === "won" || deal.deal_archieve === "lost") && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealDetails_deal_closed")} *</Form.Label>
                        <FormDate
                          value={values.deal_close}
                          onChange={(date) => handleChange("deal_close")(date)}
                        />
                        {errors.deal_close && touched.deal_close && <div className="op-error-message">{errors.deal_close}</div>}
                      </Form.Group>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingEditSummary}>
                      {isLoadingEditSummary ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isOpenDeal} onHide={setIsOpenDeal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_open_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_open && analytics.record_open.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyle }}>
                    <AgGridReact
                      rowData={analytics.record_open}
                      columnDefs={openDealColumn}
                      getRowId={rowId}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for open deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isWonDeal} onHide={setIsWonDeal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_won_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_won && analytics.record_won.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyle }}>
                    <AgGridReact
                      rowData={analytics.record_won}
                      columnDefs={wonDealColumn}
                      getRowId={rowId}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm58.3-13.7c3.1-3.1 8.2-3.1 11.3 0L480 396.7l74.3-74.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3l-80 80c-3.1 3.1-8.2 3.1-11.3 0l-48-48c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for won deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isLostDeal} onHide={setIsLostDeal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_lost_deals")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_lost && analytics.record_lost.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyle }}>
                    <AgGridReact
                      rowData={analytics.record_lost}
                      columnDefs={lostDealColumn}
                      getRowId={rowId}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm66.3-61.7c3.1-3.1 8.2-3.1 11.3 0L496 356.7l50.3-50.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3L507.3 368l50.3 50.3c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L496 379.3l-50.3 50.3c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L484.7 368l-50.3-50.3c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for lost deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isArchievedDeal} onHide={setIsArchievedDeal} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_deleted_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_deleted && analytics.record_deleted.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyle }}>
                    <AgGridReact
                      rowData={analytics.record_deleted}
                      columnDefs={archievedDealColumn}
                      getRowId={rowId}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M480 48L32 48c-8.8 0-16 7.2-16 16l0 48c0 8.8 7.2 16 16 16l16 0 416 0 16 0c8.8 0 16-7.2 16-16l0-48c0-8.8-7.2-16-16-16zm0 96l-16 0L48 144l-16 0c-17.7 0-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32l448 0c17.7 0 32 14.3 32 32l0 48c0 17.7-14.3 32-32 32zM160 232c0-4.4 3.6-8 8-8l176 0c4.4 0 8 3.6 8 8s-3.6 8-8 8l-176 0c-4.4 0-8-3.6-8-8zm304-56l16 0 0 240c0 35.3-28.7 64-64 64L96 480c-35.3 0-64-28.7-64-64l0-240 16 0 0 240c0 26.5 21.5 48 48 48l320 0c26.5 0 48-21.5 48-48l0-240z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for archived deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <ProductComponent
            deal={deal}
            onShow={isAddProduct}
            onHide={setisAddProduct}
            session={prospectSession}
            productList={productList}
            dealProductList={dealProduct}
            productCount={productCount}
            onSubmit={() => {
              getProductV2(prospectSession);
              getTimeline(false, prospectSession);
            }}
          />

          <Modal show={isCallLead} onHide={setIsCallLead}>
            <Modal.Body>
              <div className="d-flex flex-column-reverse positon-relative w-100">
                <CloseButton className="ms-auto" onClick={() => setIsCallLead(false)} />
              </div>
              <div className="call-lead">
                <div className="call-pulse">
                  <FontAwesomeIcon icon={faPhone} beatFade size="3x" style={{ color: "#fff" }} />
                </div>
              </div>
              <h5 className="text-center call-text">
                {t("DealDetails_calling")} {deal.prospect_name}
              </h5>
            </Modal.Body>
          </Modal>

          <Modal show={isFindOrganization} onHide={setIsFindOrganization} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Find Organization Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {findOrganizationData.length > 0 ? (
                <ListGroup as="ol" numbered>
                  {findOrganizationData.map((record, index) => (
                    <ListGroup.Item as="li" key={index} className="d-flex align-items-start">
                      <div className="ms-3">
                        <div className="fw-bold">{record.name}</div>
                        <p className="op-text-small">{record.address}</p>
                        <Button variant="" className="op-primary-color text-light" onClick={() => sendChooseOrganization(record)}>
                          Use Data
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isEditOrganization} onHide={setIsEditOrganization} size="lg" backdrop="static">
            <Formik
              onSubmit={sendEditOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_title: deal.organization_title ? deal.organization_title : "",
                in_id: deal.in_id && Number(deal.in_id) > 0 ? deal.in_id : "",
                organization_web_url: deal.organization_web_url ? deal.organization_web_url : "",
                organization_contact: deal.organization_contact ? deal.organization_contact : "",
                organization_address: deal.organization_address ? deal.organization_address : "",
                organization_country_id: deal.organization_country_id && Number(deal.organization_country_id) > 0 ? deal.organization_country_id : "",
                owner_id: deal.organization_owner ? deal.organization_owner : 0,
                deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                cf_organization:
                  deal && deal.custom_field_organization > 0
                    ? deal.custom_field_organization_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <Modal.Header closeButton={isLoadingEditOrganization ? false : true}>
                    <Modal.Title as={"h6"}>Edit Organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Organization Name *</Form.Label>
                          <Form.Control value={values.organization_title} name="organization_title" onChange={handleChange("organization_title")} />
                          {errors.organization_title && touched.organization_title && (
                            <div className="op-error-message">{errors.organization_title}</div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Industry</Form.Label>
                          <FormSelect
                            name="in_id"
                            options={industryList}
                            valueDefault={values.in_id ? industryList.find((record) => Number(record.value) === Number(values.in_id)) : ""}
                            onChange={(e) => handleChange("in_id")(e.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Website URL</Form.Label>
                          <Form.Control
                            value={values.organization_web_url}
                            name="organization_web_url"
                            onChange={handleChange("organization_web_url")}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            value={values.organization_contact}
                            name="organization_contact"
                            onChange={handleChange("organization_contact")}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Organization Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={values.organization_address}
                            name="organization_address"
                            onChange={handleChange("organization_address")}
                            style={{ height: 100, resize: "none" }}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Country</Form.Label>
                          <FormSelect
                            name="organization_country_id"
                            options={countryOrganizationList}
                            valueDefault={
                              values.organization_country_id
                                ? countryOrganizationList.find((record) => Number(record.value) === Number(values.organization_country_id))
                                : ""
                            }
                            onChange={(e) => handleChange("organization_country_id")(e.value)}
                          />
                        </Form.Group>

                        {Number(prospectSession.role_id) === 1 && (
                          <Form.Group className="mb-3">
                            <Form.Label>Organization Owner</Form.Label>
                            <FormSelect
                              name="owner_id"
                              disabled={values.deal_linked}
                              options={organizationUser}
                              valueDefault={
                                values.owner_id ? organizationUser.find((record) => Number(record.value) === Number(values.owner_id)) : ""
                              }
                              onChange={(e) => handleChange("owner_id")(e.value)}
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Custom Fields</div>
                        {deal && deal.custom_field_organization > 0 ? (
                          <>
                            {deal.custom_field_organization_data.map((record, index) => (
                              <FormCustomField
                                key={record.cf_id}
                                id={record.cf_id}
                                label={record.cf_label}
                                options={record.cf_value}
                                type={record.ctf_title}
                                name={record.cf_id}
                                placeholder=""
                                firstOptions={false}
                                valueDefault={false}
                                isClearable={true}
                                value={values.cf_organization[index].fd_values}
                                onChange={(info) => {
                                  const updatedCustomFields = [...values.cf_organization];
                                  if (record.ctf_title === "Select") {
                                    updatedCustomFields[index].fd_values = info.value;
                                  } else if (record.ctf_title === "Radio") {
                                    const { value, checked } = info.target;
                                    updatedCustomFields[index].fd_values = checked ? value : "";
                                  } else if (record.ctf_title === "Checkbox") {
                                    const { value, checked } = info.target;
                                    if (checked) {
                                      updatedCustomFields[index].fd_values.push(value);
                                    } else {
                                      const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                      if (indexToRemove !== -1) {
                                        updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                      }
                                    }
                                  } else if (record.ctf_title === "Date") {
                                    updatedCustomFields[index].fd_values = info;
                                  } else {
                                    updatedCustomFields[index].fd_values = info.target.value;
                                  }

                                  setFieldValue("cf_organization", updatedCustomFields);
                                }}
                              />
                            ))}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No custom fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingEditOrganization}>
                      {isLoadingEditOrganization ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddOrganization} onHide={setIsAddOrganization}>
            <Formik
              onSubmit={sendNewOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_id: "",
                organization_title: "",
                in_id: "",
                organization_web_url: "",
                organization_contact: "",
                organization_address: "",
                organization_country_id: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <Modal.Header closeButton={isLoadingAddOrganization ? false : true}>
                    <Modal.Title as={"h6"}>Link an organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Organization *</Form.Label>
                      <FormSelectCreatable
                        loadOptions={getExistOrg}
                        isInvalid={errors.organization_title && touched.organization_title}
                        isLoading={isFindOrg}
                        width="100%"
                        onChange={(info) => {
                          setFieldValue("organization_id", info.value);
                          setFieldValue("organization_title", info.label);
                          setFieldValue("organization_address", info.organization_address);
                          setFieldValue("organization_web_url", info.organization_web_url);
                          setFieldValue("organization_contact", info.organization_contact);
                          setFieldValue("organization_country_id", info.organization_country_id);
                          setFieldValue("in_id", info.in_id);
                        }}
                      />
                      {errors.organization_title && touched.organization_title && <div className="op-error-message">{errors.organization_title}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingAddOrganization}>
                      {isLoadingAddOrganization ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isCancelMeetup} onHide={setIsCancelMeetup}>
            <Formik
              onSubmit={sendCancelMeetup}
              validationSchema={cancelMeetupSchema}
              initialValues={{
                remark: "",
                reason: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Appointment Cancellation Reason</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Cancel Reason</Form.Label>
                      <FormSelect name="reason" options={cancelMeetupReasonList} onChange={(e) => setFieldValue("reason", e.label)} />
                      {errors.reason && touched.reason && <div className="op-error-message">{errors.reason}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={values.organization_title}
                        name="organization_title"
                        onChange={handleChange("remark")}
                        style={{ height: 100, resize: "none" }}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal
            show={isCall}
            onHide={setIsCall}
            centered={true}
            dialogAs={({ children, ...props }) => {
              if (callMode) {
                return (
                  <Draggable handle=".header-call2" bounds="body">
                    <div style={{ ...props.style, position: "absolute", width: 200, top: `56px`, left: `60px` }} defaultposition={callPosition}>
                      <Modal.Dialog style={{ marginTop: 0, marginBottom: 0 }}>{children}</Modal.Dialog>
                    </div>
                  </Draggable>
                );
              }

              return (
                <Draggable handle=".header-call" bounds="body">
                  <div
                    style={{ ...props.style, position: "absolute", width: 500, top: `${callPosition.y}px`, left: `${callPosition.x}px` }}
                    defaultposition={callPosition}
                  >
                    <Modal.Dialog style={{ marginTop: 0, marginBottom: 0 }}>{children}</Modal.Dialog>
                  </div>
                </Draggable>
              );
            }}
            enforceFocus={false}
            backdrop={false}
          >
            {callMode ? (
              <Modal.Body className="p-0">
                <Modal.Header className="header-call2" style={{ padding: 10 }}>
                  <Image src={LogoOP2} height={15} width="auto" />
                  <Button variant="link" size="sm" className="text-dark" style={{ padding: 0 }} onClick={() => setCallMode(false)}>
                    <FontAwesomeIcon icon={faExpand} />
                  </Button>
                </Modal.Header>

                <div style={{ height: 50, width: "10%%", margin: "0 10px 0 10px", borderRadius: 8 }}>
                  <div className="d-flex align-items-center h-100">
                    <span>
                      <div className="d-flex align-items-center">
                        <div
                          className="rounded-circle op-primary-color d-flex align-items-center justify-content-center text-light mb-0"
                          style={{ width: 30, height: 30 }}
                        >
                          {setInitial(deal.prospect_name)}
                        </div>
                        <div className="text-center fw-bold ms-2" style={{ fontSize: 13 }}>
                          {deal.prospect_name}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div style={{ padding: "0 10px 10px 10px" }} className="d-flex justify-content-between align-items-center">
                  <div className="text-center" style={{ fontSize: 12 }}>
                    {callStart === "connect" ? getCallStatus(callStatus) : callTimer}
                  </div>
                  <Button variant="danger" className="rounded-circle p-2" onClick={sendHangUpCall}>
                    <FontAwesomeIcon icon={faPhoneHangup} size="lg" color="#fff" />
                  </Button>
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body className="p-0">
                <Stack direction="horizontal" className="header-call" style={{ padding: 10 }}>
                  <Image src={LogoOP} height={15} width="auto" />
                  <div className="ms-auto d-flex align-items-center">
                    <CloseButton onClick={() => setCallMode(true)} />
                  </div>
                </Stack>
                <div style={{ height: 300, width: "10%%", margin: "0 10px 0 10px", backgroundColor: "#eee", borderRadius: 8 }}>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <span>
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="rounded-circle op-primary-color d-flex align-items-center justify-content-center text-light fs-1 mb-2"
                          style={{ width: 100, height: 100 }}
                        >
                          {setInitial(deal.prospect_name)}
                        </div>
                      </div>
                      <div className="text-center fw-bold" style={{ fontSize: 20 }}>
                        {deal.prospect_name}
                      </div>
                      <div className="text-center" style={{ fontSize: 17 }}>
                        {callStart === "connect" ? getCallStatus(callStatus) : callTimer}
                      </div>
                    </span>
                  </div>
                </div>
                <div style={{ padding: 10 }} className="d-flex justify-content-center align-items-center">
                  {callStart !== "stop" && (
                    <Button variant="danger" className="rounded-circle p-3" onClick={sendHangUpCall}>
                      <FontAwesomeIcon icon={faPhoneHangup} size="xl" color="#fff" />
                    </Button>
                  )}
                </div>
              </Modal.Body>
            )}
          </Modal>

          <Modal show={isEditSource} onHide={setIsEditSource} backdrop="static">
            <Formik
              onSubmit={sendEditSource}
              validationSchema={editSourceSchema}
              initialValues={{
                dealSource: deal && Number(deal.ds_id) > 0 ? { label: deal.ds_title, value: deal.ds_id } : "",
                ds_id: deal && Number(deal.ds_id) > 0 ? deal.ds_id : "",
                ds_title: deal && Number(deal.ds_id) > 0 ? deal.ds_title : "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton={isLoadingEditSource ? false : true}>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_source")} *</Form.Label>
                      <FormSelect
                        options={dealSource}
                        valueDefault={values.dealSource}
                        isInvalid={errors.ds_id && touched.ds_id}
                        onChange={(e) => {
                          setFieldValue("ds_title", e.label);
                          setFieldValue("ds_id", e.value);
                        }}
                      />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingEditSource}>
                      {isLoadingEditSource ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Offcanvas show={isDuplicateList} onHide={setIsDuplicateList} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Duplicate List</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {duplicateLoadingBtn ? (
                <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                  <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              ) : (
                <>
                  {duplicateRecord.length > 0 ? (
                    <ListGroup>
                      {duplicateRecord.map((record, index) => (
                        <ListGroup.Item className="border mb-3 d-flex align-items-center" key={index}>
                          <div
                            className="d-flex justify-content-center align-items-center me-2"
                            style={{ width: 30, height: 30, borderRadius: 50, border: "1px solid #999" }}
                          >
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>

                          <div>
                            <div>{record.ds_title}</div>
                            <div className="text-muted">{moment(record.date_time_create).format("lll")}</div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                      <div className="text-center">No duplicate record found</div>
                    </div>
                  )}
                </>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      )}
    </Container>
  );
};

export default DealDetails;
