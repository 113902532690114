import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import Loader from "../../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

const SurveySummary = ({prospect_id, contact, onSuccess}) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRefCS1 = useRef();
    const gridRefCS2 = useRef();
    const gridRefSurveySummCount = useRef();

    const [surveySummModal, setSurveySummModal] = useState(false);
    const [surveySummCountRowData, setSurveySummCountRowData] = useState([]);
    const [surveySummCountColumn, setSurveySummCountColumn] = useState([]);

    const containerStyleSurveySumm = useMemo(() => ({ width: "100%", height:  '50vh', paddingTop: 5 }), []);
    const gridStyleSurveySumm = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [cs1RowData, setCs1RowData] = useState([]);
    const [cs2RowData, setCs2RowData] = useState([]);

    const reportColDef = useMemo(() => {
        return {
          sortable: true,
          unSortIcon: true,
          filter: false,
        };
    }, []);

    const containerStyleCS1 = useMemo(() => ({ width: "100%", height:  '28vh', paddingTop: 5 }), []);
    const gridStyleCS1 = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [cs1Column] = useState([
        {
            headerName: 'Project',
            field: 'company_title',
            flex: 1
        },
        {
            headerName: 'RA',
            field: 'user_name',
            flex: 1
        },
        {
            headerName: '1st Submission Date',
            field: 'survey_submission_date_time',
            flex: 1
        },
        {
            headerName: 'Response Date',
            field: 'response_date_time',
            flex: 1
        },
        {
            headerName: 'Number of Survey Sent',
            field: 'survey_sent_count',
            flex: 1,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div style={{cursor: 'pointer'}}>
                            {params.data.survey_sent_count}
                        </div>
                    );
                }
            },
            onCellClicked: function(params){
                if (params.data) {
                    handleModal(params.data, 'cs1');
                }
            }
        },
        {
            headerName: 'Result',
            flex: 1,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <button 
                            className="btn btn-light border shadow-sm" 
                            type="button" 
                            onClick={() => {handleResentSurvey(params.data, 'cs1')}}
                            disabled={Number(session.company_id) !== 251 && Number(session.role_id) === 1 ? true : Number(params.data.survey_sent_count) >= 5 ? true : false}
                        >
                            Resend Survey
                            {/* <FontAwesomeIcon icon={faArrowsRotate} size="lg"/> */}
                        </button>
                    );
                }
            }
        },
    ]);

    const containerStyleCS2 = useMemo(() => ({ width: "100%", height:  '28vh', paddingTop: 5 }), []);
    const gridStyleCS2 = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [cs2Column] = useState([
        {
            headerName: 'Project',
            field: 'company_title',
            flex: 1
        },
        {
            headerName: 'RA',
            field: 'user_name',
            flex: 1
        },
        {
            headerName: '1st Submission Date',
            field: 'survey_submission_date_time',
            flex: 1
        },
        {
            headerName: 'Response Date',
            field: 'response_date_time',
            flex: 1
        },
        {
            headerName: 'Number of Survey Sent',
            field: 'survey_sent_count',
            flex: 1,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div style={{cursor: 'pointer'}}>
                            {params.data.survey_sent_count}
                        </div>
                    );
                }
            },
            onCellClicked: function(params){
                if (params.data) {
                    handleModal(params.data, 'cs2');
                }
            }
        },
        {
            headerName: 'Result',
            flex: 1,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <button 
                            className="btn btn-light border shadow-sm" 
                            type="button" 
                            onClick={() => {handleResentSurvey(params.data, 'cs2')}}
                            disabled={Number(session.company_id) !== 251 && Number(session.role_id) === 1 ? true : Number(params.data.survey_sent_count) >= 5 ? true : false}
                        >
                            Resend Survey
                            {/* <FontAwesomeIcon icon={faArrowsRotate} size="lg"/> */}
                        </button>
                    );
                }
            }
        },
    ]);

    const getCS1List = () => {
        axios.get('/ws/ws_survey_summary.php', {
            params: {
                task: 'surveysummarylist_cs1',
                prospect_id: prospect_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setCs1RowData(data.record);
            }else{
                setCs1RowData([]);
            }
        });
    }

    const getCS2List = () => {
        axios.get('ws/ws_survey_summary.php', {
            params: {
                task: 'surveysummarylist_cs2',
                prospect_id: prospect_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setCs2RowData(data.record);
            }else{
                setCs2RowData([]);
            }
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getCS1List();
            getCS2List();
        }
    }, []);

    const handleModal = (dataset, type) => {
        axios.get('ws/ws_survey_summary.php', {
            params: {
                task: type === 'cs1' ? 'surveysummarycount_cs1' : 'surveysummarycount_cs2',
                deal_id: dataset.deal_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var column = [
                    {
                        headerName: 'Issued By',
                        field: 'submitted_user',
                        flex: 1
                    },
                    {
                        headerName: 'Submission Date',
                        field: 'submission_date',
                        flex: 1
                    },
                    // {
                    //     headerName: 'Submitted By',
                    //     field: 'submitted_user',
                    //     flex: 1
                    // }
                ];
                
                setSurveySummCountColumn(column);
                setSurveySummCountRowData(data.record);
                setSurveySummModal(true);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'No record found.'
                });

                var column = [
                    {
                        headerName: 'Issued By',
                        field: 'submitted_user',
                        flex: 1
                    },
                    {
                        headerName: 'Submission Date',
                        field: 'submission_date',
                        flex: 1
                    },
                    // {
                    //     headerName: 'Submitted By',
                    //     field: 'submitted_user',
                    //     flex: 1
                    // }
                ];

                setSurveySummCountColumn(column);
                setSurveySummCountRowData([]);
                setSurveySummModal(false);
            }
        });
    }

    const handleResentSurvey = (dataset, type) => {
        Swal.fire({
            title: `Do you want to send out the ${type === 'cs1' ? 'Customer Survey 1 - Upon visit sales gallery?' : 'Customer Survey 2 - Upon SPA sign?'}`,
            html: `<div>With contact number: ${contact}</div><div>You have ${5 - Number(dataset.survey_sent_count)} attempt left</div>`,
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
        })
        .then(result => {
            if (result.isConfirmed) { 
                axios.get("ws/ws_survey_summary.php", {
                    params: {
                        task: type === 'cs1' ? 'surveysummarycountdeal_cs1' : 'surveysummarycountdeal_cs2',
                        deal_id: dataset.deal_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
        
                    if (Number(data.status) === 0) {
                        triggerResendApi(dataset);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: data.error
                        });
                    }
                });      
            }
        })
    }

    const triggerResendApi = (dataset) => {
        axios.get("ws/ws_survey_summary.php", {
            params: {
                task: 'surveysummaryresent',
                deal_id: dataset.deal_id,
                chat_send_id: dataset.chat_send_id,
                submitted_user_id: session.user_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success'
                })
                .then(() => {
                    onSuccess();
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed'
                });
            }
        });
    }

    return(
        <div>
            <div className="mb-2">
                <Card>
                    <Card.Header className="rounded border-0 shadow-sm" style={{backgroundColor: "#fff", padding: 0}}>
                        <div style={{padding: "0px 20px"}}>
                            <Stack direction="horizontal" gap={1}>
                                <h6 className="mt-2">Customer Survey 1 - Upon visit sales gallery</h6>
                            </Stack>
                        </div>
                    </Card.Header>
                </Card>

                <div style={containerStyleCS1}>
                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleCS1 }}>
                        <AgGridReact
                            ref={gridRefCS1}
                            columnDefs={cs1Column}
                            rowData={cs1RowData}
                            defaultColDef={reportColDef}
                            rowHeight={45}
                        />
                    </div>
                </div>
            </div>

            <div>
                <Card>
                    <Card.Header className="rounded border-0 shadow-sm" style={{backgroundColor: "#fff", padding: 0}}>
                        <div style={{padding: "0px 20px"}}>
                            <Stack direction="horizontal" gap={1}>
                                <h6 className="mt-2">Customer Survey 2 - Upon SPA sign</h6>
                            </Stack>
                        </div>
                    </Card.Header>
                </Card>

                <div style={containerStyleCS2}>
                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleCS2 }}>
                        <AgGridReact
                            ref={gridRefCS2}
                            columnDefs={cs2Column}
                            rowData={cs2RowData}
                            defaultColDef={reportColDef}
                            rowHeight={45}
                        />
                    </div>
                </div>
            </div>

            <Modal show={surveySummModal} onHide={setSurveySummModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as={'h6'}>Survey sent list</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={containerStyleSurveySumm}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleSurveySumm }}>
                            <AgGridReact
                                ref={gridRefSurveySummCount}
                                columnDefs={surveySummCountColumn}
                                rowData={surveySummCountRowData}
                                defaultColDef={reportColDef}
                                rowHeight={50}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default SurveySummary;