import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Form, Row, Col, Button, Card, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FormSelect, FormDate } from "../includes/FormCustom";
import moment from "moment";
import * as formik from "formik";
import Swal from "sweetalert2";
import "../lib/css/SummaryOfLeads.css";
import { useTranslation } from "react-i18next";

const SummaryLeads = () => {
  const { t } = useTranslation();
  const targetRef = useRef();
  const init = useRef(false);
  const { session } = useAuth();
  const gridRef = useRef();
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [summarylist, setSummaryList] = useState({});
  const [summaryuserlist, setSummaryUserList] = useState([]);
  const [source, setSource] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [sourceCategory, setSourceCategory] = useState([]);
  const [selectedSourceCategory, setSelectedSourceCategory] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  const [allSources, setAllSources] = useState([]);

  // START/END DATE
  const today = new Date();
  const formattedToday = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

  const oneYearAgo = new Date();
  oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
  const formattedOneYearAgo = `${oneYearAgo.getDate() + 1}-${oneYearAgo.getMonth() + 1}-${oneYearAgo.getFullYear()}`;

  const [dateRange, setdateRange] = useState({
    ds: formattedOneYearAgo,
    de: formattedToday,
  });

  // GET FUNCTION --------------------------------------------------------
  const getSurveyList = async () => {
    try {
      const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_deal_report.php`, {
        params: {
          task: "GetDealStats",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: dateRange.ds || "",
          de: dateRange.de || "",
          business_unit_id: selectedBusinessUnit.value || "",
          source_category_id: selectedSourceCategory.value || "",
          ds_id: selectedSource.value || "",
        },
      });

      const { data } = response;

      // Add index to each item
      const indexedData = (data.record_user || []).map((item, index) => ({
        ...item,
        index: index + 1,
      }));

      setSummaryList(data.record || {});
      // setSummaryUserList(data.record_user || []);
      setSummaryUserList(indexedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getSource = async () => {
    try {
      const response = await axios.get("ws/ws_source.php", {
        params: {
          task: "2",
          company: session.company_id,
        },
      });

      if (response.data.status == "0") {
        // Source category option
        const recordOptions = response.data.source_cat.map((source) => ({
          value: source.source_category_id,
          label: source.source_category_title,
          source_cat_id: source.source_category_id,
        }));

        recordOptions.unshift({ value: "", label: "All Source Category" });
        setSourceCategory(recordOptions);

        // Source option
        const sourceOptions = response.data.record.map((source) => ({
          value: source.ds_id,
          label: source.ds_title,
          source_cat_id: source.source_category_id,
        }));

        sourceOptions.unshift({ value: "", label: "All Source" });

        setAllSources(sourceOptions); // Store all sources
        setSource(sourceOptions); // Initially, show all sources
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBusinessUnit = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          company: session.company_id,
          task: "getAllBU",
        },
      });

      if (response.data.status == "0") {
        // Business unit option
        const businessOptions = response.data.record.map((unit) => ({
          value: unit.business_unit_id,
          label: unit.unit_title,
        }));

        businessOptions.unshift({ value: "", label: "All Business Unit" });
        setBusinessUnit(businessOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDealReport = (params) => {
    if (params && params.data && params.data.user_id) {
      sessionStorage.setItem(
        "dealReportParams",
        JSON.stringify({
          user_id: params.data.user_id,
          lead_status: params.colDef.field,
          dateRange: dateRange,
          source: selectedSource,
          source_category: selectedSourceCategory,
          business_unit: selectedBusinessUnit,
          outcome: "open",
        })
      );

      window.open(`${session.origin}/report-deal`, "_blank");

      sessionStorage.removeItem("dealReportParams");
    } else {
      sessionStorage.setItem(
        "dealReportParams",
        JSON.stringify({
          user_id: "",
          lead_status: params.status,
          dateRange: dateRange,
          source: selectedSource,
          source_category: selectedSourceCategory,
          business_unit: selectedBusinessUnit,
          outcome: "open",
        })
      );

      window.open(`${session.origin}/report-deal`, "_blank");

      sessionStorage.removeItem("dealReportParams");
    }
  };

  // GRID FUNCTION ------------------------------------------------------
  const containerStyle = useMemo(() => ({ width: "100%", height: "65vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: t("SummaryOfLeads_no"),
      field: "index",
      headerClass: "center",
      valueFormatter: (params) => params.value,
      maxWidth: 75,
      cellClass: "center",
      cellStyle: { cursor: "pointer", textAlign: "center" },
      sortable: false,
      filter: false,
    },
    {
      headerName: t("SummaryOfLeads_get_ra"),
      field: "user_name",
      width: 250,
      // headerClass: "center",
      // cellClass: "center",
      cellStyle: { cursor: "pointer", textAlign: "center" },
    },
    {
      headerName: t("SummaryOfLeads_progressing"),
      field: "active",
      width: 240,
      headerClass: "center",
      cellClass: "center leads-count",
      cellStyle: { cursor: "pointer", textAlign: "center" },
      onCellClicked: openDealReport,
    },
    {
      headerName: t("SummaryOfLeads_need_follow_up"),
      field: "inactive",
      width: 240,
      headerClass: "center",
      cellClass: "center leads-count",
      cellStyle: { cursor: "pointer", textAlign: "center" },
      onCellClicked: openDealReport,
    },
    {
      headerName: t("SummaryOfLeads_not_contacted"),
      field: "new",
      width: 240,
      headerClass: "center",
      cellClass: "center leads-count",
      cellStyle: { cursor: "pointer", textAlign: "center" },
      onCellClicked: openDealReport,
    },
  ];

  // DATE RANGE FILTER --------------------------------------------------
  const onchangeDateRange = (mode, value) => {
    setdateRange((prevState) => ({
      ...prevState,
      [mode]: value,
    }));
  };

  // Apply Button -------------------------------------------------------
  const handleApplyButtonClick = async () => {
    setLoading(true);

    try {
      await getSurveyList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // USEEFFECT ----------------------------------------------------------
  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getSurveyList();
          await getSource();
          await getBusinessUnit();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }
    };

    initData();
  }, [session]);

  // Calculate total received leads -------------------------------------
  const totalReceivedLeads = (summarylist.new || 0) + (summarylist.active || 0) + (summarylist.inactive || 0);

  const handleSortChanged = () => {
    gridRef.current.api.forEachNodeAfterFilterAndSort((node, index) => {
      node.data.index = index + 1;
    });
    gridRef.current.api.refreshCells({ columns: ["index"] });
  };

  const handleFilterChanged = () => {
    gridRef.current.api.forEachNodeAfterFilterAndSort((node, index) => {
      node.data.index = index + 1;
    });
    gridRef.current.api.refreshCells({ columns: ["index"] });
  };

  // ----------------------handle download

  const handleDownload = () => {
    var params = {
      fileName: "summary-status.csv",
      processCellCallback: function (params) {
        if (params.column.colId === "index") {
          return params.node.data.index;
        } else if (params.column.colId === "user_name") {
          return params.node.data.user_name;
        } else {
          var res = params.value;

          if (typeof res === "string") {
            res = res.replace(/<br>/g, "\r\n");
            res = res.replace(/<li>/g, "\r\n");
            res = res.replace(/<[^>]+>/g, "");
            res = res.replace(/&nbsp;/g, "");
          }

          return res;
        }
      },
    };

    gridRef.current.api.exportDataAsCsv(params);
  };

  return (
    <Container fluid className="p-0 m-0">
      <div className="p-4">
        <Stack className="mb-3" direction="horizontal" gap={3}>
          <Form.Group>
            <FormSelect
              options={sourceCategory}
              valueDefault={selectedSourceCategory.value}
              onChange={(selectedOption) => {
                setSelectedSourceCategory(selectedOption);

                // Filter the sources from the full list (allSources)
                const filteredSources = allSources.filter((src) => {
                  return src.source_cat_id == selectedOption.value || selectedOption.value === "";
                });

                // Set the filtered sources back to the state
                setSource(filteredSources);
              }}
              placeholder={t("SummaryOfLeads_select_source_category")}
              border={false}
              shadow={true}
              width="200px"
              isSearchable
            />
          </Form.Group>

          <Form.Group>
            <FormSelect
              options={source}
              valueDefault={selectedSource.value}
              onChange={(selectedOption) => {
                setSelectedSource(selectedOption);
              }}
              placeholder={t("SummaryOfLeads_select_source")}
              border={false}
              shadow={true}
              width="200px"
              isSearchable
            />
          </Form.Group>

          <Form.Group>
            <FormSelect
              options={businessUnit}
              valueDefault={selectedBusinessUnit.value}
              onChange={(selectedOption) => {
                setSelectedBusinessUnit(selectedOption);
              }}
              placeholder={t("SummaryOfLeads_select_business_unit")}
              border={false}
              shadow={true}
              width="200px"
              isSearchable
            />
          </Form.Group>

          <Form.Group className="ms-auto">
            <FormDate placeholder={t("SummaryOfLeads_from")} className="shadow-sm border-0" value={dateRange.ds ? moment(dateRange.ds, "DD-MM-YYYY").format("DD/MM/YYYY") : ""} onChange={(date) => onchangeDateRange("ds", date)} />
          </Form.Group>

          <Form.Group>
            <FormDate placeholder={t("SummaryOfLeads_to")} className="shadow-sm border-0" value={dateRange.de ? moment(dateRange.de, "DD-MM-YYYY").format("DD/MM/YYYY") : ""} onChange={(date) => onchangeDateRange("de", date)} />
          </Form.Group>

          <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
            {t("SummaryOfLeads_apply")}
          </Button>

          <Button variant="" className="op-primary-color text-light" onClick={handleDownload}>
            Download
          </Button>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          <div>
            <Stack direction="horizontal" gap={3}>
              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-2">
                    {t("SummaryOfLeads_total_received_leads")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2">
                      <div className="text-primary" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => openDealReport({ status: "" })}>
                        {totalReceivedLeads}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-2">
                    {t("SummaryOfLeads_progressing")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2">
                      <div className="text-primary" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => openDealReport({ status: "active" })}>
                        {summarylist.active || 0}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-2">
                    {t("SummaryOfLeads_follow_up")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2">
                      <div className="text-primary" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => openDealReport({ status: "inactive" })}>
                        {summarylist.inactive || 0}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-2">
                    {t("SummaryOfLeads_not_contacted")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2">
                      <div className="text-primary" style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => openDealReport({ status: "new" })}>
                        {summarylist.new || 0}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Stack>

            <Row>
              <Col lg={12}>
                <div className="d-flex justify-content-center">
                  <div style={containerStyle}>
                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                      <AgGridReact ref={gridRef} defaultColDef={{ flex: 1 }} columnDefs={gridColumn} rowData={loading ? [] : summaryuserlist} rowHeight={50} pagination={true} onSortChanged={handleSortChanged} onFilterChanged={handleFilterChanged} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SummaryLeads;
