import { useState, useEffect, useMemo, useCallback, useRef, StrictMode } from "react";
import { Col, Container, Row, Stack, Dropdown, Form, Offcanvas, Modal, OverlayTrigger, Spinner } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faFilterList, faBullhorn } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { FormSelect, FormSelectCreatable, FormCustomField, FormDate } from "../includes/FormCustom";
import axios from "../api/axios";
import { setCurrency, setRelativeTime } from "../lib/js/Function";
import "../lib/scss/op-deal.scss";
import moment from "moment";
import NoRecord from "../includes/NoRecord";
import Loader from "../includes/Loader";
import { useAuth } from "../auth/AuthContext";
import "../lib/scss/op-deal.scss";
import { useTranslation } from "react-i18next";
import LoaderAction from "../includes/LoaderAction";
import iziToast from "izitoast";
import { usePermission } from "../auth/PermissionContext";
import { useDeal } from "../auth/DealContext";
import { AG_GRID_LOCALE_VN, AG_GRID_LOCALE_EN } from "@ag-grid-community/locale";
import { createRoot } from "react-dom/client";
import { ClientSideRowModelModule, ModuleRegistry } from "ag-grid-community";
import preloader from "../assets/images/preloader4.gif";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

var startDate = new Date();
var endDate = new Date();
startDate.setDate(endDate.getDate() - 600);

const DealList = () => {
  const {
    getPipeline,
    dealCount,
    setDealCount,
    setIsPipeline,
    ísPipeline,
    pipelineData,
    pipelineList,
    pipeline,
    loading2,
    getStage,
    setStageFilterOpts,
    stageFilterOpts,
    setFirstLoad,
    firstLoad,
    setStageDefaultData,
    stageDefaultData,
    setIsDisabledCreateDeal,
    isDisabledCreateDeal,
    setCustomFieldDeal,
    customFieldDeal,
    setCustomFieldPerson,
    customFieldPerson,
    setAddDeal,
    addDeal,
    dealRef,
    setRowData,
    rowData,
    setRowCount,
    rowCount,
    setIsRowSelected,
    isRowSelected,
    setIsChecked,
    isChecked,
    setIsIndeterminate,
    isIndeterminate,
    onchangePipeline,
    setIsFilter,
    isFilter,
    setIsEmail,
    isEmail,
    setIsTransfer,
    isTransfer,
    setIsInteraction,
    isInteraction,
    setIsTransferPipe,
    isTransferPipe,
    refresh,
    setRefresh,
    interationLoading,
    setInterationLoading,
  } = useDeal();
  const { session } = useAuth();
  const { t } = useTranslation();
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [initLoad, setInitLoad] = useState(false);
  const initialized = useRef(false);
  const filterInit = useRef(false);
  const [user, setUser] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [interaction, setInteraction] = useState([]);
  const [isDeal, setIsDeal] = useState(true);
  const [userFilterOpts, setUserFilterOpts] = useState([]);
  const [businessUnitFilterOpts, setBusinessUnitFilterOpts] = useState([]);
  const [dealSourceFilterOpts, setDealSourceFilterOpts] = useState([]);
  const [statusFilterOpts, setStatusFilterOpts] = useState([]);
  const [dealCustomField, setDealCustomField] = useState([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const [dealRowData, setDealRecord] = useState([]);
  const [loadingGrid, setLoadingGrid] = useState(true);

  const [gridInfo, setGridInfo] = useState({
    currentPage: 1,
    totalPage: 1,
    totalRecord: 0,
    pageSize: 100,
  });

  const [filter, setFilter] = useState({
    search: "",
    ds: moment(startDate).format("YYYY-MM-DD"),
    de: moment(endDate).format("YYYY-MM-DD"),
    user: { label: t("DealList_all_user"), value: "All" },
    business_unit: { label: t("DealList_all_business_unit"), value: "All" },
    deal_source: { label: t("DealList_all_source"), value: "All" },
    stage: { label: t("DealList_all_stage"), value: "All" },
    status: { label: t("DealList_all_status"), value: "All" },
    custom_field: [],
  });

  const transferDealSchema = yup.object().shape({
    user: yup.string().required("User is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const emailSchema = yup.object().shape({
    email_template_name: yup.string().required("Email template is required"),
    campaign_title: yup.string().required("Campaign title is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const smartInteractionSchema = yup.object().shape({
    interaction: yup.string().required("Smart interaction template is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const transferPipeSchema = yup.object().shape({
    pipeline: yup.string().required("This field is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  // GET FUNCTION --------------------------------------------------------------

  const getDeal = useCallback(async (page, filter, pipelineData, firstLoad = false) => {
    const cf_id = filter.custom_field.map((record) => record.cf_id);
    const cf_value = filter.custom_field.map((record) => record.fd_values);
    try {
      const response = await axios.get("ws/ws_deallisting.php", {
        params: {
          task: 1,
          prospectname: filter.search,
          ds: filter.ds,
          de: filter.de,
          pipeid: pipelineData.value,
          user: filter.user.value === "All" ? 0 : filter.user.value,
          source: filter.deal_source.value === "All" ? 0 : filter.deal_source.value,
          stage: filter.stage.value === "All" ? 0 : filter.stage.value,
          dealstatus: filter.status.value === "All" ? 0 : filter.status.value,
          bu_id: filter.business_unit.value === "All" ? 0 : filter.business_unit.value,
          cfid: cf_id.join(","),
          cfvalue: cf_value.join(","),
          startRow: (Number(page) === 1 ? 0 : page - 1) * 100,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setRowCount(Number(data.totalRecord)); // Set row count to a maximum of 100
        setDealCount(Number(data.totalRecord));
        setDealRecord(data.record.slice(0, 100));

        if (firstLoad) {
          setGridInfo({
            currentPage: 1,
            totalPage: Math.ceil(Number(data.totalRecord) / 100),
            totalRecord: Number(data.totalRecord),
            pageSize: 100,
          });
        }
      } else {
        setDealRecord([]);
        setRowCount(0);
        setDealCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeal(true);
      setInitLoad(true);
    }
  }, []);

  const getUser = () => {
    axios
      .get("/ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const userRecord = data.record.map((record) => ({
            label: record.user_name,
            value: record.user_id,
          }));

          const userRecordFilter = data.record.map((record) => ({
            label: record.user_name,
            value: record.user_id,
          }));

          userRecordFilter.unshift({
            label: t("DealList_all_user"),
            value: "All",
          });

          setUserFilterOpts(userRecordFilter);
          setUser(userRecord);
        }
      });
  };

  const getEmailTemplate = () => {
    axios
      .get("/ws/ws_email.php", {
        params: {
          task: "getEmailTemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const emailRecord = data.record.map((record) => ({
            label: record.email_template_title,
            value: record.email_template_id,
          }));

          setEmailTemplate(emailRecord);
        }
      });
  };

  const getSmartInteraction = () => {
    axios
      .get("/ws/ws_custom_interaction.php", {
        params: {
          task: "getInteractions",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const interactionRecord = data.record.map((record) => ({
            label: record.interaction_name,
            value: record.custom_interaction_id,
          }));

          setInteraction(interactionRecord);
        }
      });
  };

  const getDealSource = () => {
    axios
      .get("/ws/ws_source.php", {
        params: {
          task: 2,
          company: session.company_id,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const filterRecord = data.record
            .filter((record) => record.ds_display === "show")
            .map((record) => ({
              label: record.ds_title,
              value: record.ds_id,
            }));

          filterRecord.unshift({
            label: t("DealList_all_source"),
            value: "All",
          });

          setDealSourceFilterOpts(filterRecord);
        }
      });
  };

  const getBusinessUnit = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllBU",
          company: session.company_id,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        const filterRecord = data.record.map((record) => ({
          label: record.unit_title,
          value: record.business_unit_id,
        }));

        filterRecord.unshift({
          label: t("DealList_all_business_unit"),
          value: "All",
        });

        setBusinessUnitFilterOpts(filterRecord);
      } else {
        setBusinessUnitFilterOpts([{ label: t("DealList_all_business_unit"), value: "All" }]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomFieldDeal = () => {
    axios
      .get("/ws/ws_custom_field.php", {
        params: {
          task: "5a",
          company: session.company_id,
          area: "deal",
          type: "select",
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            if (record.ctf_title === "Checkbox") {
              record.ctf_title = "Select";
            } else if (record.ctf_title === "Radio") {
              record.ctf_title = "Select";
            }
          });

          setFilter((prevState) => ({
            ...prevState,
            custom_field: data.record.map((record) => ({
              cf_id: record.cf_id,
              name: record.cf_id,
              fd_values: "-",
            })),
          }));

          filterInit.current = true;
          setDealCustomField(data.record);
        } else {
          filterInit.current = true;
        }
      });
  };

  // SEND FUNCTION -------------------------------------------------------------

  const sendTransferDeal = (values) => {
    var deal_id = rowData.map((record) => {
      return record.data.deal_id;
    });

    axios
      .get("/ws/ws_deal.php", {
        params: {
          task: "setBulkDealTransfer",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          source: "deal",
          type: deal_id.toString(),
          user_id: values.user,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setRefresh(Math.random());
          setIsTransfer(false);
          onchangePipeline(pipelineData);
        } else {
          setIsTransfer(false);
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_alert_error_transfer_deal"),
          });
        }
      });
  };

  const sendBulkEmail = (values) => {
    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    const formData = new FormData();
    formData.append("task", "sendBulkEmail");
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("emailTemplate", values.email_template_name);
    formData.append("prospect", prospect_id.toString());
    formData.append("campaignTitle", values.campaign_title);

    axios.post("/ws/ws_email_post.php", formData).then((response) => {
      var data = response.data;
      if (data.status === 0) {
        setRefresh(Math.random());
        setIsEmail(false);
        onchangePipeline(pipelineData);
      } else {
        setIsEmail(false);
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: "Your account isnt enabled for bulk email",
        });
      }
    });
  };

  const sendSmartInteraction = (values) => {
    var deal_id = rowData.map((record) => {
      return record.data.deal_id;
    });

    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    axios
      .get("/ws/ws_custom_interaction.php", {
        params: {
          task: "setBulkInteraction",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          source: "deal",
          type: deal_id.toString(),
          prospect: prospect_id.toString(),
          interaction_id: values.interaction,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setRefresh(Math.random());
          setIsInteraction(false);
          onchangePipeline(pipelineData);
        } else {
          setIsInteraction(false);
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_alert_error"),
          });
        }
      });
  };

  const sendFilter = (values) => {
    setFilter(values);
    setIsFilter(false);
  };

  const sendClearFilter = () => {
    setFilter({
      search: "",
      ds: moment(startDate).format("YYYY-MM-DD"),
      de: moment(endDate).format("YYYY-MM-DD"),
      user: { label: "All User", value: "All" },
      business_unit: { label: "All Business Unit", value: "All" },
      deal_source: { label: "All Source", value: "All" },
      stage: { label: "All Stage", value: "All" },
      status: { label: "All Status", value: "All" },
      custom_field: dealCustomField.map((record) => ({
        cf_id: record.cf_id,
        name: record.cf_id,
        fd_values: "-",
      })),
    });
    setIsFilter(false);
  };

  const sendTransferDealToPipeline = async (values) => {
    setIsTransferPipe(false);
    setLoadingAction(true);
    try {
      var deal_id = rowData.map((record) => record.data.deal_id);
      var prospect_id = rowData.map((record) => record.data.prospect_id);
      const formData = new FormData();
      formData.append("task", "setBulkDealPipeTransfer");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("deal", deal_id.toString());
      formData.append("prospect", prospect_id.toString());
      formData.append("pipe_id", values.pipeline);

      const response = await axios.post("ws/ws_deal_pipe_transfer.php", formData);
      const data = response.data;
      if (Number(data.status) === 0) {
        setRefresh(Math.random());
        setIsPipeline(false);
        onchangePipeline(values.pipelineArr);
      } else {
        iziToast.error({
          title: "Caution",
          message: "Something went wrong with your entry, please try again",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsPipeline(true);
        setLoadingAction(false);
      }, 100);
    }
  };

  // RENDER FUNCTION -----------------------------------------------------------

  const RenderOwner = (params) => {
    if (params.data) {
      const { deal_id, owner_id, owner_name } = params.data;
      return (
        <div className="d-flex align-items-center">
          <div id={`img-${deal_id.toString()}`}>
            <img
              className="d-block rounded-circle me-2"
              alt="owner_image"
              src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${owner_id}.png?`}
              width={30}
              height={30}
              onError={() => handleError(deal_id)}
            />
          </div>
          <div id={`avatar-${deal_id.toString()}`} className="d-none avatar me-2">
            <div className="avatar-img op-primary-color" style={{ width: 30, height: 30, backgroundColor: "#eee" }}>
              <div className="avatar-txt text-uppercase fs-5 text-light">{owner_name.charAt(0)}</div>
            </div>
          </div>
          {owner_name}
        </div>
      );
    }
  };

  const RenderSource = (params) => {
    if (params.data) {
      const iconTitle = params.data.icon_title;
      const defaultImage = `${session.hostUrl}/${session.hostUrlType}/assets/source_cat/default.jpg`;

      if (params.data.ds_title && params.data.utm_content) {
        return (
          <div>
            <img
              className="rounded-circle me-2"
              width={30}
              height={30}
              src={iconTitle ? `${session.hostUrl}/${session.hostUrlType}/assets/source_cat/${iconTitle}` : defaultImage}
              alt="defaultImg"
            ></img>
            {params.data.ds_title}
            {params.data.utm_content ? ` | UTM: ${params.data.utm_content}` : ""}
          </div>
        );
      } else if (params.data.ds_title) {
        return (
          <div>
            <img
              className="rounded-circle me-2"
              width={30}
              height={30}
              src={iconTitle ? `${session.hostUrl}/${session.hostUrlType}/assets/source_cat/${iconTitle}` : defaultImage}
              alt="defaultImg"
            ></img>
            {params.data.ds_title}
            {params.data.utm_content ? ` | UTM: ${params.data.utm_content}` : ""}
          </div>
        );
      }
    }

    return null;
  };

  const RenderLoadingOverlay = `
    <span class="ag-overlay-no-rows-center">
      <div class="rounded-circle border shadow d-flex justify-content-center align-items-center" style="height: 30px; width: 30px;">
        <div class="spinner-border" role="status" style="width: 1rem; height: 1rem; border-width: 2px;">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </span>
  `;

  // GRID SETUP ----------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: isDeal && dealCount > 0 ? "73vh" : "0vh", paddingTop: 20 }), [isDeal, dealCount]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const dealColumn = [
    {
      headerName: "",
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: false,
      width: 50,
    },
    {
      headerName: t("DealList_date"),
      field: "deal_date_time_create",
      resizable: true,
      width: 150,
      cellRenderer: (params) => {
        if (params.value) {
          return setRelativeTime(params.data.deal_date_time_create, session.default_lang);
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealList_owner"),
      field: "owner_name",
      width: 150,
      resizable: true,
      hide: false,
      cellRenderer: RenderOwner,
    },
    {
      headerName: t("DealList_title"),
      field: "deal_title",
      resizable: true,
      minWidth: 300,
      hide: false,
      cellStyle: { cursor: "pointer" },
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
          if (Number(params.data.require_attention) === 1) {
            params.data.require_attention = 0;
            params.api.applyTransaction({ update: [params.data] });
          }
        }
      },
    },
    {
      headerName: "Business Unit",
      field: "business_unit",
      resizable: true,
      hide: false,
      cellRenderer: (params) => {
        if (params.data) {
          return params.data.business_unit;
        }
      },
    },
    {
      headerName: t("DealList_source"),
      field: "ds_title",
      resizable: true,
      hide: false,
      cellRenderer: RenderSource,
    },
    {
      headerName: t("DealList_stage"),
      field: "cs_title",
      resizable: true,
      width: 200,
      hide: false,
      cellRenderer: (params) => {
        if (params.data) {
          return params.data.cs_title;
        }
      },
    },
    {
      headerName: t("DealList_status"),
      field: "deal_status",
      width: 180,
      resizable: true,
      hide: false,
      cellRenderer: function (params) {
        if (params.data) {
          var stageColor = "";
          var stageText = "";

          switch (params.data.deal_status) {
            case "new":
              stageColor = "bg-danger";
              stageText = t("DealList_contact_lead");
              break;
            case "inactive":
              stageColor = "bg-warning";
              stageText = t("DealList_follow_up");
              break;
            case "active":
              stageColor = "bg-success";
              stageText = t("DealList_progressing");
              break;
            default:
              stageColor = "bg-secondary";
              stageText = params.data.deal_status;
              break;
          }

          return (
            <>
              <div className="op-label circular text-light" style={{ backgroundColor: "#a333c8" }}>
                {params.data.prospect_lead_score}
              </div>
              <div className={`op-label text-light ${stageColor}`}>{stageText}</div>
            </>
          );
        }
      },
    },
    {
      headerName: t("DealList_last_updated"),
      field: "deal_status_last_updated",
      resizable: true,
      hide: false,
      cellRenderer: (params) => {
        if (params.data) {
          return setRelativeTime(params.data.deal_status_last_updated, session.default_lang);
        }
      },
    },
    {
      headerName: t("DealList_latest_notes"),
      field: "notes",
      resizable: true,
      hide: false,
      cellClass: "overflow",
      cellStyle: { lineHeight: 1.2, padding: "10px 0" },
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.total_notes !== undefined && params.data.total_notes !== null) {
            if (params.data.total_notes === 1 && params.data.notes) {
              return <div dangerouslySetInnerHTML={{ __html: params.data.notes[0].note_content }}></div>;
            }
          }
        }
      },
    },
    {
      headerName: t("DealList_prospect_name"),
      field: "prospect_name",
      resizable: true,
      hide: false,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
          if (Number(params.data.require_attention) === 1) {
            params.data.require_attention = 0;
            params.api.applyTransaction({ update: [params.data] });
          }
        }
      },
    },
    {
      headerName: t("DealList_organization"),
      field: "organization_title",
      resizable: true,
      hide: false,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
          if (Number(params.data.require_attention) === 1) {
            params.data.require_attention = 0;
            params.api.applyTransaction({ update: [params.data] });
          }
        }
      },
    },
    {
      headerName: t("DealList_value"),
      field: "deal_value",
      resizable: true,
      hide: false,
      cellRenderer: function (params) {
        if (params.data) {
          return setCurrency(params.data.deal_value, session.default_lang);
        }
      },
    },
  ];

  const dealColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      enableCellChangeFlash: false,
    };
  }, []);

  const dealRowId = useCallback(function (params) {
    return params.data.temprecord_id.toString();
  }, []);

  // HANDLE FUNCTION ----------------------------------------------------

  const handleError = (deal_id) => {
    const imgElement = document.getElementById(`img-${deal_id}`);
    const avatarElement = document.getElementById(`avatar-${deal_id}`);

    if (imgElement && avatarElement) {
      imgElement.classList.add("d-none");
      avatarElement.classList.remove("d-none");
    }
  };

  // ON GRID FUNCTION --------------------------------------------------------

  const onGridPreviousPage = () => {
    if (Number(gridInfo.currentPage) > 1) {
      setDealRecord([]);
      getDeal(Number(gridInfo.currentPage) - 1, filter, pipelineData, false);
      setGridInfo({
        ...gridInfo,
        currentPage: Number(gridInfo.currentPage) - 1,
      });
    }
  };

  const onGridNextPage = () => {
    if (Number(gridInfo.currentPage) < Number(gridInfo.totalPage)) {
      setDealRecord([]);
      getDeal(Number(gridInfo.currentPage) + 1, filter, pipelineData, false);
      setGridInfo({
        ...gridInfo,
        currentPage: Number(gridInfo.currentPage) + 1,
      });
    }
  };

  const onGridSelectionChanged = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  // SET FUNCTION ------------------------------------------------------------

  const setCurrentPageInfo = () => {
    const currentPageSizeStart = gridInfo.pageSize * (gridInfo.currentPage - 1) + 1;
    const currentPageSizeEnd =
      gridInfo.currentPage * gridInfo.pageSize > gridInfo.totalRecord ? gridInfo.totalRecord : gridInfo.currentPage * gridInfo.pageSize;
    const totalRecord = gridInfo.totalRecord;

    return `${currentPageSizeStart} to ${currentPageSizeEnd} of ${totalRecord}`;
  };

  // RENDER FUNCTION -----------------------------------------------------------

  useEffect(() => {
    if (!initialized.current) {
      getPipeline();
      getUser();
      getEmailTemplate();
      getSmartInteraction();
      getDealSource();
      getBusinessUnit();
      getCustomFieldDeal();

      var statusArray = [
        { label: t("DealList_all_status"), value: "All" },
        { label: t("DealList_progressing"), value: "active" },
        { label: t("DealList_contact_lead"), value: "new" },
        { label: t("DealList_follow_up"), value: "inactive" },
      ];

      setStatusFilterOpts(statusArray);
      initialized.current = true;
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!filterInit.current) return;
    setDealRecord([]);
    getDeal(1, filter, pipelineData, true);
  }, [filterInit.current, filter, pipelineData, refresh]);

  // RETURN JSX ----------------------------------------------------------------

  return (
    <Container fluid className="position-relative p-0 px-4">
      {loading && loading2 ? (
        <div style={{ height: "calc(100vh - 200px)" }}>
          <Loader />
        </div>
      ) : (
        <Row>
          <Col xxl={12}>
            {initLoad === false && (
              <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            )}
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  localeText={session.default_lang === "vi" ? AG_GRID_LOCALE_VN : AG_GRID_LOCALE_EN}
                  ref={dealRef}
                  rowData={dealRowData}
                  columnDefs={dealColumn}
                  defaultColDef={dealColDef}
                  rowSelection="multiple"
                  rowHeight={80}
                  getRowId={dealRowId}
                  suppressRowClickSelection={true}
                  animateRows={true}
                  rowStyle={{ backgroundColor: "#fff" }}
                  onGridReady={() => getDeal(1, filter, pipelineData, true)}
                  overlayNoRowsTemplate={RenderLoadingOverlay}
                  onSelectionChanged={onGridSelectionChanged}
                  getRowStyle={(params) => {
                    if (params.data && Number(params.data.require_attention) === 1) {
                      return { backgroundColor: "rgba(220, 53, 69, 0.2)" };
                    }
                  }}
                />

                {dealRowData.length > 0 && (
                  <div className="ag-paging-panel ag-unselectable">
                    <span className="ag-paging-row-summary-panel">
                      <span className="ag-paging-row-summary-panel-number">{setCurrentPageInfo()}</span>
                    </span>
                    <span className="ag-paging-page-summary-panel">
                      <div className="ag-button ag-paging-button cursor-pointer" onClick={onGridPreviousPage}>
                        <span className="ag-icon ag-icon-previous"></span>
                      </div>
                      <span className="ag-paging-description">
                        <span id="ag-14-start-page-number" className="ag-paging-number">
                          Page {gridInfo.currentPage} of {gridInfo.totalPage}
                        </span>
                      </span>
                      <div className="ag-button ag-paging-button cursor-pointer" onClick={onGridNextPage}>
                        <span className="ag-icon ag-icon-next"></span>
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </div>

            {(!isDeal || Number(dealCount) === 0) && initLoad && (
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                </svg>
                <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                  {t("DealList_no_record_message")}
                </div>
                <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                  {t("DealList_no_record_description")}
                </div>
              </div>
            )}
          </Col>
        </Row>
      )}

      <Offcanvas show={isTransfer} placement={"end"} onHide={setIsTransfer}>
        <Formik
          validationSchema={transferDealSchema}
          onSubmit={(values) => {
            setInterationLoading(true);
            sendTransferDeal(values);
          }}
          initialValues={{
            user: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_transfer_deal")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold">
                  {" "}
                  {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}{" "}
                </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="user"
                    value={values.user}
                    onChange={(info) => {
                      handleChange("user")(info.value);
                    }}
                    isInvalid={errors.user && touched.user}
                    options={user}
                    placeholder={t("DealList_select_user")}
                  />
                  {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control
                    name="confirmation"
                    value={values.confirmation}
                    onChange={handleChange("confirmation")}
                    isInvalid={errors.confirmation && touched.confirmation}
                    type="text"
                    placeholder={t("DealList_bulk_confirmation_placeholder")}
                  />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={interationLoading}>
                  {interationLoading ? <Spinner animation="border" variant="light" size="sm" /> : t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isEmail} placement={"end"} onHide={setIsEmail}>
        <Formik
          validationSchema={emailSchema}
          onSubmit={(values) => {
            setInterationLoading(true);
            sendBulkEmail(values);
          }}
          initialValues={{
            email_template_name: "",
            campaign_title: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_bulk_email")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold">
                  {" "}
                  {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}
                </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="email_template_name"
                    onChange={(info) => {
                      handleChange("email_template_name")(info.value);
                    }}
                    isInvalid={errors.email_template_name && touched.email_template_name}
                    options={emailTemplate}
                    placeholder={t("DealList_select_email_template")}
                  />
                  {errors.email_template_name && touched.email_template_name && <div className="op-error-message">{errors.email_template_name}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    name="campaign_title"
                    value={values.campaign_title}
                    onChange={handleChange("campaign_title")}
                    isInvalid={errors.campaign_title && touched.campaign_title}
                    type="text"
                    placeholder={t("DealList_campaign_title")}
                  />
                  {errors.campaign_title && touched.campaign_title && <div className="op-error-message">{errors.campaign_title}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control
                    name="confirmation"
                    value={values.confirmation}
                    onChange={handleChange("confirmation")}
                    isInvalid={errors.confirmation && touched.confirmation}
                    type="text"
                    placeholder={t("DealList_bulk_confirmation_placeholder")}
                  />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={interationLoading}>
                  {interationLoading ? <Spinner animation="border" variant="light" size="sm" /> : t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isInteraction} placement={"end"} onHide={setIsInteraction}>
        <Formik
          validationSchema={smartInteractionSchema}
          onSubmit={(values) => {
            setInterationLoading(true);
            sendSmartInteraction(values);
          }}
          initialValues={{
            interaction: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_smart_interaction")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold">
                  {" "}
                  {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}
                </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="interaction"
                    onChange={(info) => {
                      handleChange("interaction")(info.value);
                    }}
                    isInvalid={errors.interaction && touched.interaction}
                    options={interaction}
                    placeholder={t("DealList_select_smart_interaction_template")}
                  />
                  {errors.interaction && touched.interaction && <div className="op-error-message">{errors.interaction}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control
                    name="confirmation"
                    value={values.confirmation}
                    onChange={handleChange("confirmation")}
                    isInvalid={errors.confirmation && touched.confirmation}
                    type="text"
                    placeholder={t("DealList_bulk_confirmation_placeholder")}
                  />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={interationLoading}>
                  {interationLoading ? <Spinner animation="border" variant="light" size="sm" /> : t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isFilter} placement={"end"} onHide={setIsFilter}>
        <Formik onSubmit={sendFilter} initialValues={filter}>
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_filters")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    value={values.search}
                    placeholder={t("DealList_search_deal_prospect_organization")}
                    onChange={handleChange("search")}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="date"
                    value={values.ds}
                    onChange={(e) => {
                      var newDate = new Date(e.target.value);
                      var momentDate = moment(newDate).format("YYYY-MM-DD");
                      handleChange("ds")(momentDate);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="date"
                    value={values.de}
                    onChange={(e) => {
                      var newDate = new Date(e.target.value);
                      var momentDate = moment(newDate).format("YYYY-MM-DD");
                      handleChange("de")(momentDate);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={userFilterOpts}
                    valueDefault={values.user}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("user", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={businessUnitFilterOpts}
                    valueDefault={values.business_unit}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("business_unit", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={dealSourceFilterOpts}
                    valueDefault={values.deal_source}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("deal_source", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={stageFilterOpts}
                    valueDefault={values.stage}
                    onChange={(e) => {
                      setFieldValue("stage", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={statusFilterOpts}
                    valueDefault={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e);
                    }}
                  />
                </Form.Group>

                {dealCustomField.map((record, index) => (
                  <FormCustomField
                    key={record.cf_id}
                    id={record.cf_id}
                    label={record.cf_label}
                    options={record.cf_value}
                    type={record.ctf_title}
                    placeholder=""
                    firstOptions={true}
                    value={values.custom_field[index].fd_values}
                    onChange={(info) => {
                      const updatedCustomFields = [...values.custom_field];

                      if (record.ctf_title === "Select") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.value,
                        };
                      } else if (record.ctf_title === "Radio") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.checked ? info.target.value : "",
                        };
                      } else if (record.ctf_title === "Checkbox") {
                        const { value, checked } = info.target;
                        const currentValues = typeof values.custom_field[index] === "string" ? values.custom_field[index] : "";
                        const updatedValues = [...currentValues.split(",")];

                        if (checked) {
                          updatedValues.push(value);
                        } else {
                          const indexToRemove = updatedValues.indexOf(value);
                          if (indexToRemove !== -1) {
                            updatedValues.splice(indexToRemove, 1);
                          }
                        }

                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: updatedValues.join(","),
                        };
                      } else {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.value,
                        };
                      }

                      setFieldValue("custom_field", updatedCustomFields);
                    }}
                  />
                ))}
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <button type="submit" className="btn op-button op-primary-color text-light me-2">
                    {t("DealList_search")}
                  </button>
                  <button type="button" className="btn bg-body-secondary" onClick={sendClearFilter}>
                    {t("DealList_clear")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isTransferPipe} placement={"end"} onHide={setIsTransferPipe}>
        <Formik
          validationSchema={transferPipeSchema}
          onSubmit={(values) => {
            setInterationLoading(true);
            sendTransferDealToPipeline(values);
          }}
          initialValues={{ pipeline: "", pipelineArr: { label: "", value: "" }, confirmation: "" }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Transfer Deal to Another Pipeline</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold">
                  {" "}
                  {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}{" "}
                </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="pipeline"
                    placeholder="Select Pipeline"
                    value={values.pipeline}
                    isInvalid={errors.pipeline && touched.pipeline}
                    options={pipelineList.filter((record) => Number(record.value) !== Number(pipelineData.value))}
                    onChange={(info) => {
                      setFieldValue("pipelineArr", info);
                      handleChange("pipeline")(info.value);
                    }}
                  />
                  {errors.pipeline && touched.pipeline && <div className="op-error-message">{errors.pipeline}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control
                    name="confirmation"
                    value={values.confirmation}
                    onChange={handleChange("confirmation")}
                    isInvalid={errors.confirmation && touched.confirmation}
                    type="text"
                    placeholder={t("DealList_bulk_confirmation_placeholder")}
                  />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={interationLoading}>
                  {interationLoading ? <Spinner animation="border" variant="light" size="sm" /> : t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      {loadingAction && <LoaderAction />}
    </Container>
  );
};

export default DealList;
