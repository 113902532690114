import { useContext, useState, useEffect, createContext, useRef, forwardRef } from "react";

import axios from "../api/axios";
import { useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { t } from "i18next";
import Swal from "sweetalert2/dist/sweetalert2";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faBullhorn } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { usePermission } from "./PermissionContext";

const DealContext = createContext();

export const DealProvider = ({ children }) => {
  // DEAL List =================================================
  const { permissionRule } = usePermission();
  const { session } = useAuth();
  const [dealCount, setDealCount] = useState(0);
  const [ísPipeline, setIsPipeline] = useState(false);
  const [pipelineData, setPipelineData] = useState({ label: "", value: "" });
  const [pipelineList, setPipelineList] = useState([]);
  const [pipeline, setPipeline] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [stageFilterOpts, setStageFilterOpts] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [stageDefaultData, setStageDefaultData] = useState(0);
  const [isDisabledCreateDeal, setIsDisabledCreateDeal] = useState(false);
  const [customFieldDeal, setCustomFieldDeal] = useState([]);
  const [customFieldPerson, setCustomFieldPerson] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const dealRef = useRef(null);
  const [isFilter, setIsFilter] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [isInteraction, setIsInteraction] = useState(false);
  const [isTransferPipe, setIsTransferPipe] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [interationLoading, setInterationLoading] = useState(true);
  const [addDeal, setAddDeal] = useState({
    deal_title: "",
    deal_source: "",
    deal_value: "0",
    expected_close_date: "",
    prospect_name: "",
    prospect_id: "",
    contact1: "",
    contact_id: "",
    contact1_prefix: "60",
    contact1_prefix_disabled: false,
    contact1_disabled: false,
    cf_deals: [],
    cf_person: [],
  });

  // PIPELINE VIEW =================================================
  const [pipeData, setPipeData] = useState({ label: "", value: "" });
  const [pipeList, setPipeList] = useState([]);
  const [pipeStats, setPipeStats] = useState({});
  const [pipeRecord, setPipeRecord] = useState([]);
  const [isPipe, setIsPipe] = useState(false);
  const [pipeUser, setPipeUser] = useState([]);
  const [pipeUserData, setPipeUserData] = useState({ label: t("DealList_all_user"), value: "all" });
  const [isPipeUser, setIsPipeUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNoRecord, setIsNoRecord] = useState(false);
  const kanbanRef = useRef(null);
  const [dealCache, setDealCache] = useState(null);

  // DEAL List =================================================
  const getPipeline = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const pipelineRecord = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        const pipelineRecord2 = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        pipelineRecord2.unshift({ label: "Select Pipeline", value: "" });
        setPipelineList(pipelineRecord2);

        pipelineRecord.unshift({
          label: t("DealList_all_pipeline"),
          value: "All",
        });

        var selectedPipeline = {};
        if (pipelineData && pipelineData?.value !== "") {
          selectedPipeline = pipelineData;
        } else {
          if (Number(session.pipe_pipeid) > 0) {
            const pipeline_record = pipelineRecord.find((record) => record.value === session.pipe_pipeid);
            selectedPipeline = pipeline_record ? pipeline_record : pipelineRecord[0];
          } else {
            selectedPipeline = pipelineRecord[0];
          }
        }

        setPipelineData(selectedPipeline);
        setPipeline(pipelineRecord);
        getStage(selectedPipeline?.value);
        setIsPipeline(true);
        setLoading2(false);
      } else {
        setPipeline([]);
        setIsPipeline(false);
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStage = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: session.company_id,
          pipeline_id: pipelineId,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const stageRecord = data.record.map((record) => ({
          label: record.cs_title,
          value: record.cs_id,
        }));

        stageRecord.unshift({
          label: t("DealList_all_stage"),
          value: "All",
        });

        setStageFilterOpts(stageRecord);

        if (!firstLoad) {
          setFirstLoad(true);
          setStageDefaultData(data.record[0].cs_id);
        }

        if (Number(data.manual_deal_creation_disable) === 1) {
          setIsDisabledCreateDeal(true);
        }

        // getCustomField(pipelineId);
      } else {
        if (pipelineId !== "All") {
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_no_stage"),
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onchangePipeline = (info) => {
    if (Number(rowCount) > 0) {
      dealRef.current.api.deselectAll();
      setIsRowSelected(false);
      setIsChecked(false);
      setIsIndeterminate(false);
      setRowCount(0);
      setRowData([]);
    }

    setPipelineData(info);
    if (info.value !== "All") {
      getStage(info.value);
      setPipeData(info);
    }
  };

  const RenderBulkActionToggle = forwardRef(({ children, onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    const renderTooltip = (props) => <Tooltip {...props}>Bulk Action</Tooltip>;

    return (
      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <button ref={ref} onClick={openDropdown} className="btn btn-light shadow-sm">
          <FontAwesomeIcon icon={faBullhorn} size="lg" />
          {children}
        </button>
      </OverlayTrigger>
    );
  });

  const RenderBulkActionMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Dropdown.Header className="fw-semibold op-text-medium">{t("DealList_bulk_action")}</Dropdown.Header>
        {Number(permissionRule.rules2) === 1 && <Dropdown.Item onClick={openTransferDeal}>{t("DealList_transfer_all_selected")}</Dropdown.Item>}
        {Number(permissionRule.rules3) === 1 && <Dropdown.Item onClick={openBulkEmail}>{t("DealList_email_all_selected")}</Dropdown.Item>}
        {Number(permissionRule.rules4) === 1 && <Dropdown.Item onClick={openBulkInteraction}>{t("DealList_bulk_note_or_activity")}</Dropdown.Item>}
        {Number(permissionRule.rules5) === 1 && <Dropdown.Item>{t("DealList_power_dialer")}</Dropdown.Item>}
        <Dropdown.Item
          onClick={() => {
            setInterationLoading(false);
            setIsTransferPipe(true);
          }}
        >
          Transfer Deals To Another Pipeline
        </Dropdown.Item>
      </div>
    );
  });

  const openTransferDeal = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_100"),
      });
    } else {
      setInterationLoading(false);
      setIsTransfer(true);
    }
  };

  const openBulkEmail = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_100"),
      });
    } else {
      setInterationLoading(false);
      setIsEmail(true);
    }
  };

  const openBulkInteraction = () => {
    if (rowCount > 10) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_10"),
      });
    } else {
      setInterationLoading(false);
      setIsInteraction(true);
    }
  };

  // PIPELINE VIEW =================================================
  const getDeals = async (startRow = 0, cs_id, pipeline_id, user_id) => {
    try {
      const endDate = moment();
      const startDate = endDate.clone().subtract(10, "years");
      const response = await axios.get("ws/ws_deallisting.php", {
        params: {
          task: 1,
          prospectname: "",
          ds: startDate.format("YYYY-MM-DD"),
          de: endDate.format("YYYY-MM-DD"),
          pipeid: pipeline_id,
          user: user_id,
          source: 0,
          stage: cs_id,
          dealstatus: 0,
          bu_id: 0,
          cfid: "",
          cfvalue: "",
          startRow: startRow,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPipe = async () => {
    setLoading(true);
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOpts = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        let records = { label: "", value: "" };
        const recordData = recordOpts.find((record) => Number(record.value) === Number(session.pipe_pipeid));
        if (recordData) {
          records = recordData;
        } else {
          records = recordOpts[0];
        }

        if (pipeData.value === "") {
          setPipeData(records);
        }

        setPipeRecord(recordOpts);
        setIsPipe(true);
        getPipe(pipeData.value === "" ? records.value : pipeData.value, pipeUserData.value === "" ? "all" : pipeUserData.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPipe = async (pipe_id, user_id) => {
    try {
      const response = await axios.get("ws/ws_deal_pipeline.php", {
        params: {
          task: "PipeViewAnalytics",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          pipe: pipe_id,
          user: user_id === "all" ? "" : user_id,
        },
      });

      const data = response.data;
      setPipeStats(data);
      if (data.status === 0) {
        const updatedRecords = await Promise.all(
          data.record.stage_count > 0
            ? data.record.stage_record.map(async (record) => {
                const deals = await getDeals(0, record.cs_id, pipe_id, user_id === "all" ? 0 : user_id);
                return {
                  ...record,
                  id: record.cs_id,
                  title: record.cs_title,
                  customer: Number(deals.status) === 0 ? deals.record.map((rec) => ({ ...rec, id: rec.deal_id.toString(), title: rec.deal_title })) : [],
                  totalRecord: record.deal_count,
                  currentTotalRecord: Number(deals.status) === 0 ? deals.record.length : 0,
                };
              })
            : []
        );

        setPipeList(updatedRecords);
        setLoading(false);
        setIsNoRecord(false);
      } else {
        setLoading(false);
        setIsNoRecord(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPipeUser = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const userRecord = data.record.map((record) => ({
          label: `${record.user_name} (${record.role_title})`,
          value: record.user_id,
        }));

        userRecord.unshift({
          label: t("DealList_all_user"),
          value: "all",
        });

        setPipeUser(userRecord);
        setIsPipeUser(true);
      } else {
        setPipeUser([]);
        setIsPipeUser(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onchangePipe = (values) => {
    const element = document.getElementById("kanban-view");
    element?.scrollIntoView({ behavior: "smooth" });

    setLoading(true);
    setIsNoRecord(false);
    setPipeData(values);
    setPipelineData(values);
    getPipe(values.value, pipeUserData.value);
  };

  const onchangePipeUser = (values) => {
    const element = document.getElementById("kanban-view");
    element?.scrollIntoView({ behavior: "smooth" });

    setLoading(true);
    setIsNoRecord(false);
    setPipeUserData(values);
    getPipe(pipeData.value, values.value);
  };

  const contextData = {
    getPipeline,
    dealCount,
    setDealCount,
    setIsPipeline,
    ísPipeline,
    pipelineData,
    pipelineList,
    pipeline,
    loading2,
    getStage,
    setStageFilterOpts,
    stageFilterOpts,
    setFirstLoad,
    firstLoad,
    setStageDefaultData,
    stageDefaultData,
    setIsDisabledCreateDeal,
    isDisabledCreateDeal,
    setCustomFieldDeal,
    customFieldDeal,
    setCustomFieldPerson,
    customFieldPerson,
    setAddDeal,
    addDeal,
    dealRef,
    setRowData,
    rowData,
    setRowCount,
    rowCount,
    setIsRowSelected,
    isRowSelected,
    setIsChecked,
    isChecked,
    setIsIndeterminate,
    isIndeterminate,
    onchangePipeline,
    setIsFilter,
    isFilter,
    RenderBulkActionToggle,
    RenderBulkActionMenu,
    setIsEmail,
    isEmail,
    setIsTransfer,
    isTransfer,
    setIsInteraction,
    isInteraction,
    setIsTransferPipe,
    isTransferPipe,
    refresh,
    setRefresh,
    interationLoading,
    setInterationLoading,
    // pipe view
    pipeUserData,
    pipeData,
    setPipeData,
    pipeList,
    setPipeList,
    getPipe,
    pipeStats,
    setPipeStats,
    setPipeRecord,
    pipeRecord,
    isPipe,
    setIsPipe,
    getAllPipe,
    onchangePipe,
    getPipeUser,
    setPipeUser,
    pipeUser,
    isPipeUser,
    setIsPipeUser,
    onchangePipeUser,
    loading,
    setLoading,
    isNoRecord,
    kanbanRef,
    getDeals,
    dealCache,
    setDealCache,
  };

  return <DealContext.Provider value={contextData}>{children}</DealContext.Provider>;
};

export const useDeal = () => {
  return useContext(DealContext);
};

export default DealContext;
