import { faBars, faChartSimple, faFilterList, faGear } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Button, ButtonGroup, Col, Container, Dropdown, Modal, OverlayTrigger, Row, Stack, Tooltip, Form, Spinner } from "react-bootstrap";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDeal } from "../auth/DealContext";
import { FormCurrency, FormCustomField, FormDate, FormSelect, FormSelectCreatable, FormSelectV2 } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import { useEffect, useState } from "react";
import { MobilePrefix, setCurrency } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import moment from "moment";
import { usePermission } from "../auth/PermissionContext";
import AddDealDuplicate from "./gamuda/AddDealDuplicate";
import parsePhoneNumberFromString from "libphonenumber-js";

const DealView = () => {
  const { permissionRule } = usePermission();
  const { Formik, useFormikContext } = formik;
  const { dealCount, ísPipeline, pipelineData, pipeline, stageDefaultData, rowCount, isRowSelected, onchangePipeline, setIsFilter, RenderBulkActionToggle, RenderBulkActionMenu, setRefresh, setLoading, setDealCache } = useDeal();
  const renderTooltip = (props, text) => <Tooltip {...props}>{text}</Tooltip>;
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useAuth();
  const { pipeData, pipeStats, pipeRecord, pipeUserData, isPipe, onchangePipe, pipeUser, isPipeUser, onchangePipeUser } = useDeal();
  const [modalAddDeal, setModalAddDeal] = useState(false);
  const [dealSource, setDealSource] = useState([]);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [customFieldDeal, setCustomFieldDeal] = useState([]);
  const [customFieldPerson, setCustomFieldPerson] = useState([]);
  const [addDealDisabledBtn, setAddDealDisabledBtn] = useState(false);
  const [isDupContact, setisDupContact] = useState(false);
  const [dupContactData, setDupContactData] = useState({});
  const [organizationShow, setOrganizationShow] = useState(true);
  const [dealSourceShow, setDealSourceShow] = useState(true);
  const [dealValueShow, setDealValueShow] = useState(true);
  const [expectedCloseDateShow, setExpectedCloseDateShow] = useState(true);
  const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];
  const [isFindOrg, setIsFindOrg] = useState(false);

  const [addDeal, setAddDeal] = useState({
    deal_title: "",
    deal_source: "",
    deal_value: "0",
    expected_close_date: "",
    prospect_name: "",
    prospect_id: "",
    contact1: "",
    contact_id: "",
    contact1_prefix: "60",
    contact1_prefix_disabled: false,
    contact1_disabled: false,
    organization_title: "",
    organization_backup: "",
    organization_id: "",
    organization_disabled: false,
    organization_new: true,
    cf_deals: [],
    cf_person: [],
  });

  const customFieldSchema = yup.object().shape({
    fd_values: yup.mixed().test('is-valid', 'The field is required', value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return typeof value === 'string' && value.trim() !== '';
    }),
  });

  const newDealSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealList_error_message")),
    prospect_name: yup.string().required(t("DealList_error_message")),
    prospect_id: yup.string().required(t("DealList_error_message")),
    contact1: yup.string().test("contact1", "Invalid contact number", async function (value) {
      const { contact1_prefix, contact1_disabled } = this.parent || {};
      try {
        // if (!value) {
        //   return this.createError({ message: t("DealList_error_message") });
        // }

        if (contact1_disabled === false && value) {
          const countryCode = MobilePrefix.find((record) => record.value === contact1_prefix);
          if (countryCode) {
            const phoneNumber = parsePhoneNumberFromString(`+${contact1_prefix}${value}`, countryCode.region);
            if (!phoneNumber || !phoneNumber.isValid()) {
              return this.createError({ message: "Invalid contact number" });
            }
          } else {
            return this.createError({ message: "Prefix is required" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    contact1_prefix: yup.string().test("contact1_prefix", "Prefix is required", async function (value) {
      const { contact1_disabled, contact1 } = this.parent || {};
      if (contact1_disabled === false && contact1) {
        if (value === "") {
          return this.createError({ message: "Prefix is required" });
        } 
      }

      return true;
    }),
    // cf_deals: yup.array().of(customFieldSchema),
    // cf_person: yup.array().of(customFieldSchema),
  });

  const setPipelineViewRecord = (stats) => {
    if (Number(stats.status) === 0) {
      if (Number(stats.record.total_deal_count) > 1) {
        return `${setCurrency(stats.record.total_deal_value, session.default_lang)} &bull; ${t("DealList_record_many", { count: stats.record.total_deal_count })}`;
      } else if (Number(stats.record.total_deal_count) === 1) {
        return `${setCurrency(stats.record.total_deal_value, session.default_lang)} &bull;  ${t("DealList_record_one", { count: stats.record.total_deal_count })}`;
      }
    }

    return t("DealList_record_none");
  };

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("/ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              ...record,
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
              contact1: record.contact1 ? record.contact1 : record.contact2 ? record.contact3 : record.contact3 ? record.contact3 : record.contact4 ? record.contact4 : record.contact5 ? record.contact5 : "",
              contact_id: record.contact_id,
              contact1_prefix: record.contact1_prefix,
              organization_id: record.organization_id ? Number(record.organization_id) : "",
              organization_title: record.organization_title ? record.organization_title : "",
            }));

          var newProspectRecord = data.record.find((record) => Number(record.prospect_id) === 0);
          if (newProspectRecord) {
            prospectRecord.unshift({
              label: inputValue,
              value: 0,
              customLabel: newProspectRecord.msg,
              contact1: "",
              contact_id: "",
              contact1_prefix: "",
              organization_id: "",
              organization_title: "",
            });
          }

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getExistOrg = (inputValue, callback) => {
    setIsFindOrg(false);
    axios
      .get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: session.company_id,
          q: inputValue,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (Number(data.status) === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.organization_id) > 0;
            })
            .map((record) => ({
              label: record.organization_title,
              value: record.organization_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.organization_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindOrg(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindOrg(true);
        }
      });
  };

  const getCustomField = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "getCFForAddDeal",
          area: "",
          cfpipe: pipelineId,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          v_add: "yes",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var cfDeal = data.record.filter((record) => {
          return record.cf_area === "deal";
        });

        var cfPerson = data.record.filter((record) => {
          return record.cf_area === "person";
        });

        setCustomFieldDeal(cfDeal);
        setCustomFieldPerson(cfPerson);

        setAddDeal((prevState) => ({
          ...prevState,
          cf_deals: cfDeal.map((record) => ({
            cf_id: record.cf_id,
            cf_label: record.cf_label,
            name: record.cf_id,
            fd_values: record.ctf_title === "Checkbox" ? [] : "",
          })),
          cf_person: cfPerson.map((record) => ({
            cf_id: record.cf_id,
            cf_label: record.cf_label,
            name: record.cf_id,
            fd_values: record.ctf_title === "Checkbox" ? [] : "",
          })),
        }));
      } else {
        setCustomFieldDeal([]);
        setCustomFieldPerson([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDealSource = () => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: "getAllSource_show_mobile",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const dealSourceRecord = data.record
            .filter((record) => record.ds_display === "show")
            .map((record) => ({
              label: record.ds_title,
              value: record.ds_id,
            }));

          setDealSource(dealSourceRecord);
        }
      });
  };

  const getDealField = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: 8,
          company: session.company_id,
        },
      });

      const data = response.data;

      if (data.record && data.record.length > 0) {
        const organizationField = data.record.find((record) => record.core_title === "organization_title");
        const dealSourceField = data.record.find((record) => record.core_title === "ds_title");
        const dealValueField = data.record.find((record) => record.core_title === "deal_value");
        const expectedCloseDateField = data.record.find((record) => record.core_title === "deal_expected_close_date");

        if (organizationField) {
          setOrganizationShow(organizationField.setting_field_status === "yes");
        }

        if (dealSourceField) {
          setDealSourceShow(dealSourceField.setting_field_status === "yes");
        }

        if (dealValueField) {
          setDealValueShow(dealValueField.setting_field_status === "yes");
        }

        if (expectedCloseDateField) {
          setExpectedCloseDateShow(expectedCloseDateField.setting_field_status === "yes");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDeal = async (deal_id) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "getDealDetails",
          type: "view",
          deal: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record;
      }

      return null;
    } catch (error) {
      console.error(error);
    }
  }
  
  const sendConnectOrgProspect = async (values, organization_id) => {
    try {
      await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: values.designation_title,
          des: values.designation_id,
          name: values.prospect_name,
          organization: organization_id,
          prospect_address: "",
          prospect: Number(values.hq_prospect_id) > 0 ? values.hq_prospect_id : values.prospect_id,
          owner_id: values.prospect_owner,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  const sendCreateOrganization = async (values) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          q: values.organization_title,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record[0].organization_id;
      }

      return 0;
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDeal = async (values) => {
    setAddDealDisabledBtn(true);
    try {
      if (values.contact1_disabled === false && values.contact1) {
        const response = await axios.get("ws/ws_prospect.php", {
          params: {
            task: "getMobileExists",
            mobile: values.contact1,
            company: session.company_id,
            type: "",
            people: "",
            user: session.user_id,
          },
        });

        const data = response.data;
        if (Number(data.status) === 0) {
          
          var organization_id = "";
          if (Number(session.company_type_id) === 0) {
            if (values.organization_new === false && values.organization_id && Number(values.organization_id) > 0) {
              organization_id = values.organization_id;
            } else {
              if (values.organization_new === true && values.organization_title !== "") {
                organization_id = await sendCreateOrganization(values);
              }
            }
          }

          if (gamuda.includes(Number(session.company_id))) {
            sendCreateDealGamuda(values, organization_id);
          } else {
            sendCreateDealGlobal(values, organization_id);
          }
        } else {
          if (gamuda.includes(Number(session.company_id))) {
            setDupContactData(values);
            setisDupContact(true);
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed",
              text: "Contact number entered already exists in the system.",
            });
          }
        }
      } else {
        var organization_id = "";
        if (Number(session.company_type_id) === 0) {
          if (values.organization_new === false && values.organization_id && Number(values.organization_id) > 0) {
            organization_id = values.organization_id;
          }
        }

        if (gamuda.includes(Number(session.company_id))) {
          sendCreateDealGamuda(values, organization_id);
        } else {
          sendCreateDealGlobal(values, organization_id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGlobal = async (values, organization_id) => {
    setAddDealDisabledBtn(false);
    try {
      var customField = values.cf_deals.concat(values.cf_person);
      var cf_id = customField.map((record) => record.name);
      var cf_label = customField.map((record) => record.cf_label);
      var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      }).filter((value) => value !== null);

      var pipeid = pipelineData.value === "All" ? 0 : pipelineData.value;

      if (location.pathname === "/deal/view/pipeline") {
        pipeid = pipeData.value === "All" ? 0 : pipeData.value;
      } else {
        pipeid = pipelineData.value === "All" ? 0 : pipelineData.value;
      }

      if (Number(values.prospect_id) === 0 || values.prospect_id === "") {
        if (values.contact1 === "") {
          values.contact1 = "";
        } else {
          const countryCode = MobilePrefix.find((record) => record.value === values.contact1_prefix);
          if (countryCode) {
            const phoneNumber = parsePhoneNumberFromString(`+${values.contact1_prefix}${values.contact1}`, countryCode.region);
            if (phoneNumber) {
              values.contact1 = phoneNumber.number.replace("+", "");
            } else {
              values.contact1 = values.contact1_prefix + values.contact1;
            }
          }
        }
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipeid,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: organization_id,
          organization_name: values.organization_title,
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          cflabel_arr: cf_label.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {

        if (values.organization_new === true && values.organization_title !== "") {
          const deal_record = await getDeal(data.record);
          if (deal_record) {
            await sendConnectOrgProspect(deal_record, organization_id);
          } 
        }
        
        setDealCache(Math.random());
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGamuda = async (values, organization_id) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);

      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipelineData.value === "All" ? 0 : pipelineData.value,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: "",
          organization_name: "",
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setDealCache(Math.random());
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          title: t("DealList_successful"),
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setContactError = (errors, touched) => {
    let error = "";

    if (errors.contact1_prefix && touched.contact1_prefix) {
      error = errors.contact1_prefix;
    }

    if (errors.contact1 && touched.contact1) {
      if (error !== "") {
        error += " & ";
      }
      error += errors.contact1;
    }

    return error ? <div className="op-error-message">{error}</div> : null;
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const ScrollToFirstError2 = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        const findFirstError = (obj, keyPath = "") => {
          if (obj === undefined || obj === null) {
            return "";
          }
          return Object.keys(obj).reduce((acc, key) => {
            if (acc) return acc;
            const isArrayIndex = !isNaN(key);
            const newKeyPath = keyPath ? `${keyPath}${isArrayIndex ? `[${key}]` : `.${key}`}` : key;
            if (typeof obj[key] === "string") return newKeyPath;
            return findFirstError(obj[key], newKeyPath);
          }, "");
        };
        const firstErrorKey = findFirstError(errors);
        const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  useEffect(() => {
    getDealField();
    getDealSource();
    if (location.pathname === "/deal/view" || location.pathname === "/deal/view/") {
      if (Number(session.open_deal_list) === 1) {
        getCustomField(pipelineData.value);
        navigate("/deal/view/list");
      } else {
        getCustomField(pipeData.value);
        navigate("/deal/view/pipeline");
      }
    } else if (location.pathname === "/deal/view/pipeline") {
      setLoading(true);
    } else {
      if (Number(session.open_deal_list) === 1) {
        getCustomField(pipelineData.value);
      } else {
        getCustomField(pipeData.value);
      }
    }
  }, [location.pathname]);

  return (
    <Container fluid className="position-relative p-0">
      <div className="bg-light d-flex align-items-center w-100 px-4" style={{ borderTop: "1px solid #ddd", height: 70 }}>
        <Stack className="w-100 h-100" direction="horizontal" gap={2}>
          <ButtonGroup className="activity-tab">
            <NavLink to="/deal/view/list" className="btn btn-outline" activeclassname="active">
              <FontAwesomeIcon icon={faBars} size="lg" />
            </NavLink>
            <NavLink to="/deal/view/pipeline" className="btn btn-outline" activeclassname="active">
              <FontAwesomeIcon icon={faChartSimple} size="lg" rotation={180} />
            </NavLink>
          </ButtonGroup>

          {Number(permissionRule.rules1) === 1 && (
            <button
              type="button"
              className="btn op-button op-primary-color text-light shadow-sm"
              onClick={() => {
                setAddDealDisabledBtn(false);
                setModalAddDeal(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("DealList_deal")}
            </button>
          )}

          {location.pathname === "/deal/view/list" ? (
            <>
              {isRowSelected && (
                <>
                  <Dropdown>
                    <Dropdown.Toggle as={RenderBulkActionToggle}></Dropdown.Toggle>
                    <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                  </Dropdown>
                  <h6 className="op-text-medium mt-2 me-2">{t("DealList_row_selected", { count: rowCount })}</h6>
                </>
              )}

              <h6 className="ms-auto op-text-medium mt-2 me-2"> {dealCount === 1 ? t("DealList_record_one", { count: dealCount }) : dealCount > 1 ? t("DealList_record_many", { count: dealCount }) : t("DealList_record_none")}</h6>
              {Number(permissionRule.rules6) === 1 && ísPipeline && <FormSelectV2 options={pipeline} value={pipelineData} onChange={onchangePipeline} shadow={true} border={true} placeholder="Select Pipeline" width={250} />}
              {Number(permissionRule.rules7) === 1 && (
                <OverlayTrigger placement="bottom" overlay={(props) => renderTooltip(props, "Filters")}>
                  <button className="btn btn-light shadow-sm border" onClick={setIsFilter}>
                    <FontAwesomeIcon icon={faFilterList} size="lg" />
                  </button>
                </OverlayTrigger>
              )}
            </>
          ) : (
            <>
              <div className="ms-auto" />
              <div className="ms-auto op-text-medium mt-2 me-2" dangerouslySetInnerHTML={{ __html: setPipelineViewRecord(pipeStats) }} />
              {Number(permissionRule.rules6) === 1 && isPipe && <FormSelectV2 options={pipeRecord} value={pipeData} onChange={onchangePipe} shadow={true} border={true} placeholder="Select Pipeline" width={250} />}
              {isPipeUser && <FormSelectV2 options={pipeUser} value={pipeUserData} onChange={onchangePipeUser} shadow={true} border={true} placeholder="Select User" width={250} />}
              {Number(session.role_id) === 1 && (
                <OverlayTrigger placement="bottom" overlay={(props) => renderTooltip(props, "Pipeline Settings")}>
                  <Button variant="light" className="border shadow-sm" onClick={() => navigate("/settings/pipeline")}>
                    <FontAwesomeIcon icon={faGear} size="xl" />
                  </Button>
                </OverlayTrigger>
              )}
            </>
          )}
        </Stack>
      </div>

      <div style={{ height: "calc(100vh - 126px)" }}>
        <Outlet />
      </div>

      <Modal show={modalAddDeal} onHide={setModalAddDeal} size="lg">
        <Formik validationSchema={newDealSchema} onSubmit={sendCreateDeal} initialValues={addDeal}>
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <ScrollToFirstError2 />
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>{t("DealList_add_deal")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col className="w-50 px-4 py-3">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DeaList_contact_person")} *</Form.Label>
                      <FormSelectCreatable
                        name="prospect_id"
                        loadOptions={getProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect_id && touched.prospect_id}
                        onChange={(info) => {
                          setFieldValue("prospect_name", info.label);
                          setFieldValue("prospect_id", info.value);
                          setFieldValue("deal_title", "New Deal - " + info.label);
                          if (Number(info.value) > 0) {
                            setFieldValue("contact1", info.contact1);
                            setFieldValue("contact_id", info.contact_id);
                            setFieldValue("contact1_prefix", info.contact1_prefix ? info.contact1_prefix : "60");

                            if (info.contact1) {
                              setFieldValue("contact1_disabled", true);
                              setFieldValue("contact1_prefix_disabled", true);
                            } else {
                              setFieldValue("contact1_disabled", false);
                              setFieldValue("contact1_prefix_disabled", false);
                            }

                            if (info.organization_id && Number(info.organization_id) > 0) {
                              setFieldValue("organization_title", info.organization_title);
                              setFieldValue("organization_id", info.organization_id);
                              setFieldValue("organization_disabled", true);
                              setFieldValue("organization_backup", info.organization_title);
                              setFieldValue("organization_new", organizationShow ? false : false);
                            } else {
                              setFieldValue("organization_title", "");
                              setFieldValue("organization_id", "");
                              setFieldValue("organization_disabled", false);
                              setFieldValue("organization_backup", "");
                              setFieldValue("organization_new", organizationShow ? true : false);
                            }
                          } else {
                            setFieldValue("contact1", "");
                            setFieldValue("contact_id", "");
                            setFieldValue("contact1_prefix", "");
                            setFieldValue("contact1_disabled", false);
                            setFieldValue("contact1_prefix_disabled", false);
                            setFieldValue("organization_title", "");
                            setFieldValue("organization_id", "");
                            setFieldValue("organization_disabled", false);
                            setFieldValue("organization_new", false);
                            setFieldValue("organization_backup", "");
                          }
                        }}
                      />
                      {errors.prospect_id && touched.prospect_id && <div className="op-error-message">{errors.prospect_id}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_deal_title")} *</Form.Label>
                      <Form.Control name="deal_title" value={values.deal_title} type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("deal_title")} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>

                    {dealSourceShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_deal_source")}</Form.Label>
                        <FormSelectV2 name="deal_source" options={dealSource} value={dealSource.find((record) => Number(record.value) === Number(values.deal_source))} isSearchable={true} placeholder="" shadow={false} onChange={(info) => handleChange("deal_source")(info.value)} />
                      </Form.Group>
                    )}

                    {dealValueShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_deal_value")}</Form.Label>
                        <FormCurrency name="deal_value" currency={session.country} value={values.deal_value} onChange={(e) => handleChange("deal_value")(e)} />
                      </Form.Group>
                    )}

                    {expectedCloseDateShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_expected_close_date")}</Form.Label>
                        <FormDate name="expected_close_date" onChange={(date) => handleChange("expected_close_date")(date)} />
                      </Form.Group>
                    )}

                    {customFieldDeal.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label + (Number(record.compulsory_column) === 1 ? " *" : "")}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={`cf_deals[${index}].fd_values`}
                        isInvalid={errors.cf_deals && errors.cf_deals[index] && errors.cf_deals[index].fd_values && touched.cf_deals && touched.cf_deals[index] && touched.cf_deals[index].fd_values}
                        error={errors.cf_deals && errors.cf_deals[index] && errors.cf_deals[index].fd_values && touched.cf_deals && touched.cf_deals[index] && touched.cf_deals[index].fd_values ? errors.cf_deals[index].fd_values : ""}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_deals[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_deals];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_deals", updatedCustomFields);
                        }}
                      />
                      
                    ))}
                  </Col>
                  <Col className="w-50 px-4 py-3">
                    <div className="op horizontal-start divider">{t("DealList_person")}</div>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_contact_number")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <FormSelectV2
                          name="contact1_prefix"
                          options={MobilePrefix}
                          value={MobilePrefix.find((record) => record.value === values.contact1_prefix)}
                          disabled={values.contact1_prefix_disabled}
                          isSearchable
                          isInvalid={errors.contact1_prefix && touched.contact1_prefix}
                          width="80px"
                          onChange={(e) => setFieldValue("contact1_prefix", e.value)}
                        />

                          <Form.Control
                            name="contact1"
                            type="text"
                            value={values.contact1}
                            disabled={values.contact1_disabled}
                            isInvalid={errors.contact1 && touched.contact1}
                            onChange={(e) => setFieldValue("contact1", e.target.value.replace(/\D/g, ''))}
                          />
                      </Stack>
                      {setContactError(errors, touched)}
    
                    </Form.Group>

                    {organizationShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>Organization</Form.Label>
                        <FormSelectCreatable 
                          loadOptions={getExistOrg} 
                          isLoading={isFindOrg} 
                          disabled={values.organization_disabled}
                          placeholder={values.organization_title ? values.organization_title : ""}
                          width="100%" 
                          onChange={(info) => {setFieldValue("organization_id", info.value); setFieldValue("organization_title", info.label); }} 
                        />
                        {errors.organization_id && touched.organization_id && <div className="op-error-message">{errors.organization_id}</div>}
                      </Form.Group>
                    )}

                    {customFieldPerson.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label + (Number(record.compulsory_column) === 1 ? " *" : "")}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={`cf_person[${index}].fd_values`}
                        isInvalid={errors.cf_person && errors.cf_person[index] && errors.cf_person[index].fd_values && touched.cf_person && touched.cf_person[index] && touched.cf_person[index].fd_values}
                        error={errors.cf_person && errors.cf_person[index] && errors.cf_person[index].fd_values && touched.cf_person && touched.cf_person[index] && touched.cf_person[index].fd_values ? errors.cf_person[index].fd_values : ""}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_person[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_person];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_person", updatedCustomFields);
                        }}
                      />
                    ))}
                  </Col>
                  <div className="op vertical fluid divider"></div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={addDealDisabledBtn}>
                  {addDealDisabledBtn ? <Spinner animation="border" size="sm" /> : t("DealList_submit")}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {isDupContact && (
        <AddDealDuplicate
          isOpen={setisDupContact}
          dataSet={dupContactData}
          onSuccess={() => {
            setRefresh(Math.random());
            setModalAddDeal(false);
          }}
          stageDefaultData={stageDefaultData}
          pipelineData={pipelineData}
        />
      )}
    </Container>
  );
};

export default DealView;
