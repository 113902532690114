import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Container, Form, Spinner, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import { FormSelect } from "../includes/FormCustom";

const UserPermission = () => {
  const init = useRef(false);
  const { session } = useAuth();
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isGrid, setIsGrid] = useState(false);
  const [recordFound, setRecordFound] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [modulesData, setModulesData] = useState();
  const [permissionID, setPermissionID] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [gridColumn, setGridColumn] = useState([]);

  // GET FUNCTION ----------------------------------------------------------------

  const getModules = async () => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "list_module_info_v2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const exclude_module = [];

        if (Number(session.curation_enabled) === 0) {
          exclude_module.push(3);
        }

        if (![180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412].includes(Number(session.company_id))) {
          exclude_module.push(5);
        }

        if (Number(session.booking_module) === 0) {
          exclude_module.push(26);
        }

        const option_record = data.record.filter((opt) => !exclude_module.includes(Number(opt.permission_module_id))).map((opt) => ({ label: opt.module_title, value: opt.permission_module_id }));
        setModuleList(option_record);
        setModulesData(option_record[0]);
        await getPermissions(option_record[0].value);
      } else {
        setModuleList([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissions = async (id) => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "listv4",
          permission_module_id: id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        data.record.sort((a, b) => a.user_name.localeCompare(b.user_name));
        const currentUserId = session.user_id;
        const currentUser = data.record.find((user) => user.user_id === currentUserId);

        if (currentUser) {
          data.record = [currentUser, ...data.record.filter((user) => user.user_id !== currentUserId)];
        }

        const gridColumn = [{ headerName: "Users", field: "user_name", flex: 1, pinned: "left", filter: true, maxWidth: 180, cellRenderer: (params) => `${params.data.user_name} ${session.user_id === params.data.user_id ? " (You)" : ""}` }];
        for (let index = 1; index <= 30; index++) {
          if (Number(id) === 23 && index === 7) {
            if (Number(session.curation_enabled) !== 0) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 23 && index === 5) {
            if (Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 23 && index === 6) {
            if (Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 23 && index === 8) {
            if (Number(session.booking_module) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 29 && index === 2) {
            if (Number(session.company_type_id) !== 2) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 29 && (index === 3 || index === 4)) {
            if ([251, 180, 200, 190, 203, 202, 212, 213, 210].includes(Number(session.company_id))) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 30 && index === 2) {
            if (Number(session.lead_aging) === 1 && Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 30 && index === 3) {
            if (Number(session.blueprint_ai) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 30 && (index === 4 || index === 5)) {
            if ([180, 200, 190, 202, 203, 212, 213, 251, 210].includes(Number(session.company_id))) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 31 && index === 1) {
            if (Number(session.booking_incoming_log) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 31 && (index === 2 || index === 3 || index === 4)) {
            if (Number(session.company_id) === 251) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 31 && index === 5) {
            if (Number(session.layout_mode) === "real-estate" && (Number(session.company_lead_assignment_autoreroute) === 1 || Number(session.company_lead_assignment_autoreroute) === 0)) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 31 && index === 19) {
            if (Number(session.inbound_analytics) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 31 && index === 21) {
            if (Number(session.lead_transfer_report) !== 0) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 1) {
            if (Number(session.whatsapp_conversation) !== 0) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && (index === 2 || index === 3)) {
            if (Number(session.avanser_enabled) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 4) {
            if (Number(session.whatsapp_campaign) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 6) {
            if (Number(session.event_rsvp) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 7) {
            if (Number(session.survey) === 1 && Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 8) {
            if (Number(session.company_id) === 251) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 10) {
            if (Number(session.srm_dashboard) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 11) {
            if (Number(session.blueprint_ai) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && index === 12) {
            if (Number(session.usage_ai) === 1 && Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 33 && (index === 13 || index === 14)) {
            if ([180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412].includes(Number(session.company_id))) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 28 && index === 1) {
            if (Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 24 && index === 2) {
            if (Number(session.survey) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 24 && index === 26) {
            if (Number(session.product_feature) === 1 && session.layout_mode !== "real-estate") {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 24 && index === 5) {
            if (Number(session.company_id) === 251) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 26 && (index === 1 || index === 2 || index === 5)) {
            if (Number(session.booking_viewer_access) === 0) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 26 && index === 4) {
            if (Number(session.booking_viewer_access) === 0) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else if (Number(id) === 26 && index === 7) {
            if (Number(session.role_id) === 1) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          } else {
            if (data.record[0][`rules${index}_title`]) {
              gridColumn.push({
                headerName: data.record[0][`rules${index}_title`],
                field: `rules${index}`,
                headerClass: "center",
                cellClass: "center",
                minWidth: 180,
                hide: check_booking_restricted(data.record[0][`rules${index}_title`]),
                cellRenderer: (params) => RenderGridAction(params, index),
              });
            }
          }
        }

        setGridColumn(gridColumn);
        setRowData(data.record);
        setRecordFound(data.record.length);
        setIsGrid(true);
        setIsLoading(false);
      } else {
        setRowData([]);
        setRecordFound(0);
        setIsGrid(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // RENDER FUNCTION -----------------------------------------------------------

  const RenderGridAction = (params) => {
    if (params.value !== undefined) {
      return (
        <Form.Group>
          <Form.Check type="switch" checked={Number(params.value) === 1 ? true : false} onChange={() => handleClick(params)} />
        </Form.Group>
      );
    }
  };

  // SET FUNCTION -------------------------------------------------------------

  const check_booking_restricted = (rules) => {
    const booking_restricted = ["Booking"];
    const find_rule = booking_restricted.find((rule) => rule === rules);
    if (find_rule) {
      if (Number(session.booking_module) === 1) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  // HANDLE FUNCTION -----------------------------------------------------------

  const handleClick = async (params) => {
    const values = params.data;
    const field = params.colDef.field;
    const rule_index = field.replace("rules", "");
    const updatedValues = Number(values[field]) === 1 ? 0 : 1;
    const rowNode = gridRef.current.api.getRowNode(values.user_id.toString());
    rowNode.setData({ ...rowNode.data, [field]: updatedValues });

    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "add_module_v2",
          permission_user: values.user_id,
          permission_module_id: values.permission_module_id,
          permission_rules: rule_index,
          permission_rules_values: updatedValues,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) !== 0) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Please try again or contact your IT Support",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async (data) => {
    setModulesData(data);
    try {
      await getPermissions(data.value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (text) => {
    const searchText = text;
    const filterInstance = gridRef.current.api.getFilterInstance("user_name");
    filterInstance.setModel({
      type: "contains",
      filter: searchText,
    });
    gridRef.current.api.onFilterChanged();
    setRecordFound(gridRef.current.api.getModel().getRowCount());
  };

  // AG GRID FUNCTION -----------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "67vh" : "0vh", paddingTop: 10 }), [isGrid]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      cellDataType: true,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.user_id.toString();
  }, []);

  useEffect(() => {
    const init_ready = async () => {
      if (!init.current) {
        try {
          await getModules();
          init.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    };

    init_ready();
  }, [init.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="px-0">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-3 mb-3">
            <Stack direction="horizontal" gap={1} className="mb-2">
              <Form.Group style={{ width: 250 }}>
                <FormSelect placeholder="select module" isSearchable={true} valueDefault={modulesData} options={moduleList} onChange={handleChange} />
              </Form.Group>

              <Form.Group className="ms-auto" style={{ width: 250 }}>
                <Form.Control
                  type="text"
                  placeholder="Search user"
                  value={searchVal}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchVal(e.target.value);
                  }}
                />
              </Form.Group>
            </Stack>
            <div className="mb-3" style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={rowData} defaultColDef={reportColDef} rowHeight={50} pagination={false} getRowId={gridRowId} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default UserPermission;
