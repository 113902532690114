import React, { useState, useEffect, useRef, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas, Stack } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { faTrash, faPlus, faPencil, faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const KlMetro = () => {
    const { session } = useAuth();
    const { Formik } = formik;
    const [loading, setLoading] = useState(false);
    const gridRef = useRef();
    const targetRef = useRef();
    const init = useRef(false);
    const [getData, setGetData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);


    // START/END DATE
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // YYYY-MM-DD

    const [dateRange, setdateRange] = useState({
        de: formattedToday,
    });



    const validationSchemaUpdate = yup.object().shape({
        total_manual_activity_entry: yup.string().required("This field is required"),
    });

    // GET FUNCTION

    const fetchData = async () => {
        try {
            const res = await axios.get("https://www.nexcrmapis.com/meetup/klmetro/kl_metro_routing_algo.php", {
                params: {
                    task: "getResultsByDate",
                    date: dateRange.de
                    // date: '2025-01-15'
                },
            });

            const data = res.data;

            if (data.status === 0) {
                setGetData(data.records);
            } else {
                setGetData([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.value;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        { headerName: "Queue Date", field: "que_date", width: 200 },
        { headerName: "Username", field: "user_name", width: 300 },
        { headerName: "Total Actual Appointment", field: "total_actual_appointment", width: 200 },
        { headerName: "Total Appointment", field: "total_appointment", width: 200 },
        { headerName: "Total Manual Activity Entry", field: "total_manual_activity_entry", width: 200 },
        { headerName: "Current User Selected", field: "current_user_selected", width: 200 },
        { headerName: "Total Appointment Routed", field: "total_appointment_routed", width: 200 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];


    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get('https://www.nexcrmapis.com/meetup/klmetro/kl_metro_routing_algo.php', {
                params: {
                    task: "updateManualEntry",
                    user_que_id: values.user_que_id,
                    total_manual_activity_entry: values.total_manual_activity_entry,
                    user_id: session.user_id
                }
            });

            const data = response.data;

            console.log(data)

            if (response.data.status == '0') {
                setShowModal(false);
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    //  DATE RANGE FILTER
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const handleApplyButtonClick = () => {
        setLoading(true);

        const dateRangeValues = {
            de: dateRange.de,
        };

        fetchData(dateRangeValues.de,);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };


    // USEEFFECT ----------------------------------------------------

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            fetchData();
        }
    }, []);


    return (
        <div p-0 m-0>
            {loading ? (
                <Loader />
            ) : (
                <div className="mt-3" style={{ height: "calc(100vh - 56px)", overflowY: "auto", overflowX: "clip" }}>
                    <Stack
                        direction="horizontal"
                        style={{
                            padding: '10px 30px',
                            justifyContent: 'end'
                        }}
                        gap={2}
                    >
                        <Form.Group>
                            <Form.Control
                                type="date"
                                placeholder='to'
                                className="shadow-sm border-0"
                                value={dateRange.de}
                                onChange={(e) => {
                                    onchangeDateRange("de", e.target.value); // No need for moment here
                                }}
                            />
                        </Form.Group>

                        <Button
                            variant=""
                            className="op-primary-color text-light"
                            onClick={handleApplyButtonClick}
                        >
                            Apply
                        </Button>
                    </Stack>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        rowData={loading ? [] : getData}
                                        rowHeight={75}
                                        pagination={true}
                                        onCellClicked={onCellClicked}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                user_que_id: currentData ? currentData.user_que_id : "",
                                total_manual_activity_entry: currentData ? currentData.total_manual_activity_entry : "",
                            }}
                            validationSchema={validationSchemaUpdate}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Total Manual Activity Entry</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="total_manual_activity_entry"
                                                value={values.total_manual_activity_entry}
                                                onChange={handleChange}
                                                isInvalid={touched.total_manual_activity_entry && !!errors.total_manual_activity_entry}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.total_manual_activity_entry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            )}
        </div>
    )

}

export default KlMetro