import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas, FormLabel } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";
import SettingBlueprint1Element from "./SettingBlueprint1Element";

const MySwal = withReactContent(Swal);

const Blueprint1 = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [blueprint1List, setBlueprint1List] = useState([]);
    const [userList, setUserList] = useState([]);
    const [instanceList, setInstanceList] = useState([]);
    const [isAddBlueprint, setisAddBlueprint] = useState(false);
    const [pipelineID, setPipelineID] = useState([]);
    const [combinedCsData, setCombinedCsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState([]);

    const [isBlueprintData, setIsBlueprintData] = useState(false);
    const [blueprintData, setBlueprintData] = useState([]);



    // SCHEMA ------------------------------------------------------

    const validationSchema = yup.object().shape({
        title: yup.string().required("Title is required"),
    });

    const status = [
        { value: 'new', label: 'New' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
    ];


    // GET FUNCTION --------------------------------------------------------
    const getBlueprint1 = async () => {
        try {
            const res = await axios.get(`ws/ws_blueprint_setting.php`, {
                params: {
                    task: "blueprint1List",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            let data = res.data;

            if (data.record) {
                setBlueprint1List(data.record);
                setLoading(false);
            } else {
                setBlueprint1List([]);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }


    const getUserList = async () => {
        try {
            const res = await axios.get('ws/ws_user.php', {
                params: {
                    task: "4a",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    user_tag: ""
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.user_id,
                    label: record.user_name,
                }));

                setUserList(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getChatInstance = async () => {
        try {
            const res = await axios.get('ws/ws_blueprint2.php', {
                params: {
                    task: "getChatInstance",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.chat_instance_id,
                    label: record.instance_title,
                }));

                setInstanceList(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const getAllPipelines = async () => {
        try {
            const res = await axios.get('ws/ws_pipeline.php', {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.pipeline_id,
                    label: record.pipeline_title,
                }));
                setPipelineID(recordOptions);

                let allCSRecords = [];

                for (const record of data.record) {
                    const csRecords = await getCSData(record.pipeline_id);
                    allCSRecords = allCSRecords.concat(csRecords);
                }

                var csOption = allCSRecords.map((record) => ({
                    value: record.cs_id,
                    label: record.cs_title,
                    cs_token: record.cs_token
                }));
                setCombinedCsData(csOption)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCSData = async (pipeid) => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getStages",
                    pipeid: pipeid,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                return data.record; // Return the records array
            } else {
                return [];
            }

        } catch {

        }
    }


    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.data.date_time_created;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    const setUsername = (params) => {
        const user_id = params.data.user_id;

        if (user_id) {
            const user = userList.find((item) => item.value === user_id);
            return user ? user.label : "-";
        } else {
            return "-";
        }
    };

    const setChatInstance = (params) => {
        const instance_id = params.data.chat_instance_id;

        if (instance_id) {
            const instance = instanceList.find((item) => item.value === instance_id);
            return instance ? instance.label : "-";
        } else {
            return "-";
        }
    };


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Title",
            field: "title",
            width: 400,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'auto', 'line-height': '1.2' },
            onCellClicked: function (params) {
                if (params.data) {
                    setIsBlueprintData(true);
                    setBlueprintData(params.data);
                }
            }
        },
        {
            headerName: "Date Time Create",
            field: "",
            width: 200,
            cellRenderer: setDateFormat
        },
        {
            headerName: "Username",
            field: "",
            width: 200,
            cellRenderer: setUsername
        },
        {
            headerName: "Chat Instance",
            field: "",
            width: 200,
            cellRenderer: setChatInstance
        },
        {
            headerName: "Status",
            field: "qualified_deal_status",
            width: 150
        },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };


    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            axios.get("ws/ws_blueprint_setting.php", {
                params: {
                    task: 'setBlueprint1',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    title: values.title || '',
                    user_id: values.user_id || 0,
                    chat_instance_id: values.chat_instance_id || 0,
                    sender_id: values.sender || 0,
                    cs_id: values.stage || 0,
                    status: values.status || '',
                    transfer: 0
                }
            }).then((res) => {
                if (res.data.status === 0) {
                    setisAddBlueprint(false);
                    getBlueprint1();

                    MySwal.fire({
                        icon: 'success',
                        title: 'Successfully Added',
                        text: 'Record has been added successfully',
                        timer: 1500
                    });
                } else {
                    console.error(res.data.message);
                }
            })


        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };


    // UPDATE FUNCTION ----------------------------------------------------

    const handleUpdate = async (values) => {
        
        try {
            const response = await axios.get(`ws/ws_blueprint_setting.php`, {
                params: {
                    task: "updateBlueprint1",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    blueprint_id: values.blueprint_id,
                    title: values.title,
                    user_id: values.user_id,
                    chat_instance_id: values.chat_instance_id,
                    sender_id: values.sender,
                    cs_id: values.stage,
                    status: values.status,
                    transfer: 0
                }
            });

            if (response.data.status === 0) {
                setShowModal(false);
                getBlueprint1();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
    

    // UseEffect

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getBlueprint1();
                await getUserList();
                await getChatInstance();
                await getAllPipelines();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };

        if (!init.current) {
            init.current = true;
            fetchData();
        }
    }, []);


    const toggleAddBlueprintModal = () => {
        setisAddBlueprint(prevState => !prevState);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddBlueprintModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Blueprint
                    </button>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        rowData={loading ? [] : blueprint1List}
                                        rowHeight={150}
                                        pagination={true}
                                        onCellClicked={onCellClicked}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>


                    {/* Add function */}

                    <Modal show={isAddBlueprint} onHide={toggleAddBlueprintModal}>
                        <Formik
                            initialValues={{
                                title: "",
                                user_id: "",
                                chat_instance_id: "",
                                sender: "",
                                stage: "",
                                status: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Blueprint</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Title
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange("title")}
                                                name="title"
                                                placeholder="Please enter a title"
                                                isInvalid={touched.title && !!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Username</Form.Label>
                                            <FormSelect
                                                options={userList}
                                                placeholder="Please choose a user"
                                                isSearchable
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleChange("user_id")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Chat Instance</Form.Label>
                                            <FormSelect
                                                options={instanceList}
                                                placeholder="Please choose a chat instance"
                                                isSearchable
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleChange("chat_instance_id")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Sender</Form.Label>
                                            <FormSelect
                                                options={userList}
                                                placeholder="Please choose a sender"
                                                isSearchable
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleChange("sender")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Stage</Form.Label>
                                            <FormSelect
                                                options={combinedCsData.map(option => ({
                                                    value: option.value,
                                                    label: `${option.label} (${option.cs_token})`,
                                                    cs_token: option.cs_token,
                                                }))}
                                                placeholder="Please choose a chat instance"
                                                isSearchable
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleChange("stage")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Status</Form.Label>
                                            <FormSelect
                                                options={status}
                                                placeholder="Please choose a status"
                                                isSearchable
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    handleChange("status")(e.value)
                                                }} />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>


                    {/* Edit function */}

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Formik
                            initialValues={{
                                blueprint_id: currentData ? currentData.blueprint_id : "",
                                title: currentData ? currentData.title : "",
                                user_id: currentData ? currentData.user_id : "",
                                chat_instance_id: currentData ? currentData.chat_instance_id : "",
                                sender: currentData ? currentData.sender_id : "",
                                stage: currentData ? currentData.qualified_cs_id : "",
                                status: currentData ? currentData.qualified_deal_status : "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Edit Blueprint</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Title
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange("title")}
                                                name="title"
                                                value={values.title}
                                                placeholder="Please enter a title"
                                                isInvalid={touched.title && !!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Username</Form.Label>
                                            <FormSelect
                                                options={userList}
                                                placeholder="Please choose a user"
                                                isSearchable
                                                autoComplete="off"
                                                valueDefault={
                                                    values.user_id
                                                        ? {
                                                            label: userList.find(item => item.value === values.user_id)?.label || '',
                                                            value: values.user_id
                                                        }
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("user_id")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Chat Instance</Form.Label>
                                            <FormSelect
                                                options={instanceList}
                                                placeholder="Please choose a chat instance"
                                                isSearchable
                                                autoComplete="off"
                                                valueDefault={
                                                    values.chat_instance_id
                                                        ? {
                                                            label: instanceList.find(item => item.value === values.chat_instance_id)?.label || '',
                                                            value: values.chat_instance_id
                                                        }
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("chat_instance_id")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Sender</Form.Label>
                                            <FormSelect
                                                options={userList}
                                                placeholder="Please choose a sender"
                                                isSearchable
                                                autoComplete="off"
                                                valueDefault={
                                                    values.sender
                                                        ? {
                                                            label: userList.find(item => item.value === values.sender)?.label || '',
                                                            value: values.sender
                                                        }
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("sender")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Stage</Form.Label>
                                            <FormSelect
                                                options={combinedCsData.map(option => ({
                                                    value: option.value,
                                                    label: `${option.label} (${option.cs_token})`,
                                                    cs_token: option.cs_token,
                                                }))}
                                                placeholder="Please choose a chat instance"
                                                isSearchable
                                                autoComplete="off"
                                                valueDefault={
                                                    values.stage
                                                        ? {
                                                            label: combinedCsData.find(item => item.value === values.stage)?.label || '',
                                                            value: values.stage
                                                        }
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("stage")(e.value)
                                                }} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Status</Form.Label>
                                            <FormSelect
                                                options={status}
                                                placeholder="Please choose a status"
                                                isSearchable
                                                autoComplete="off"
                                                valueDefault={
                                                    values.status !== null && values.status !== undefined && values.status !== ""
                                                        ? {
                                                            label: status.find(item => item.value === values.status)?.label || '',
                                                            value: values.status
                                                        }
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("status")(e.value)
                                                }} />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Offcanvas
                        show={isBlueprintData}
                        onHide={setIsBlueprintData}
                        placement="bottom"
                        style={{
                            left: 57,
                            height: '100vh',
                        }}
                        >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title as={"h6"}>Blueprint Sequence</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ backgroundColor: "rgb(243, 243, 243)" }}>
                            <SettingBlueprint1Element blueprintData={blueprintData} />
                        </Offcanvas.Body>
                    </Offcanvas>

                </div>
            )}
        </Container>
    );

}

export default Blueprint1;