import { useState, useEffect, useMemo, useRef, forwardRef, useCallback } from "react";
import { Stack, Form, Dropdown, Offcanvas, Container, InputGroup, FormCheck } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import FormSelect from "../includes/FormSelect";
import "../lib/scss/op-report-style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
// import { useNavigate } from "react-router-dom";
import Loader from "../includes/Loader";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { usePermission } from "../auth/PermissionContext";
import { parsePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { MobilePrefix } from "../lib/js/Function";
import * as XLSX from "xlsx";

const DealReport = () => {
  const { Formik } = formik;
  
  // const navigate = useNavigate();
  const { t } = useTranslation();

  const init = useRef(false);
  const gridRef = useRef();
  const [loading, setLoading] = useState(true);
  const [isReport, setIsReport] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(1, "year").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [userTagID, setUserTagID] = useState("");
  const [userID, setUserID] = useState("");
  const [buID, setBuID] = useState("");
  const [dsCatID, setDsCatID] = useState("");
  const [dsID, setDsID] = useState("");
  const [csID, setCsID] = useState("");
  const [pipeID, setPipeID] = useState("");
  const [outcomeVal, setOutcomeVal] = useState("");
  const [leadStatVal, setLeadStatVal] = useState("");
  const [activityNeeded, setActivityNeeded] = useState(0);
  const [showAllFd, setShowAllFd] = useState(0);
  const [showContact, setShowContact] = useState(0);
  const { session } = useAuth();
  const { permission } = usePermission();
  const [userTagList, setUserTagList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [buList, setBuList] = useState([]);
  const [dsCatList, setDsCatList] = useState([]);
  const [dsList, setDsList] = useState([]);
  const [csList, setCsList] = useState([]);
  const [pipeList, setPipeList] = useState([]);
  const [trigger, setTrigger] = useState("");
  const [recordFound, setRecordFound] = useState("");
  const [curationList, setCurationList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  // const [isSelectAll, setIsSelectAll] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [isAddToCur, setIsAddToCur] = useState(false);
  const [isUTM, setIsUTM] = useState(false);
  const [storageParams, setStorageParams] = useState({});

  const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "80vh", paddingTop: 20 }), [isReport]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const handleClick = () => {
    if (isIndeterminate) {
      if (gridRef.current.api) {
        gridRef.current.api.forEachNode((node) => node.setSelected(false));
      }
    } else {
      setIsChecked((prevChecked) => {
        const newChecked = !prevChecked;
        setIsIndeterminate(false);

        if (gridRef.current.api) {
          gridRef.current.api.forEachNode((node) => node.setSelected(newChecked));
        }

        return newChecked;
      });
    }
  };

  const dealRowId = useCallback(function (params) {
    return params.data.deal_id.toString();
  }, []);

  const onReportSelected = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;
    const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

    setIsChecked(allSelected);
    setIsIndeterminate(atLeastOneSelected && !allSelected);

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  const getAllTeam = (loginData) => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "getUserTags",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var teamData = data.record.map((data) => {
            return { label: data.user_tag_title, value: data.user_tag_id };
          });

          setUserTagList(teamData);
        } else {
          setUserTagList([]);
        }
      });
  };

  useEffect(() => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          user_tag: userTagID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var userData = data.record.map((data) => {
            return { label: data.user_name, value: data.user_id };
          });

          setUserList(userData);
        } else {
          setUserList([]);
        }
      });
  }, [userTagID, session]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getAllUser = useMemo(() => {
  //   axios
  //     .get("ws/ws_user.php", {
  //       params: {
  //         task: "4a",
  //         user_tag: userTagID,
  //         utoken: session.user_ac_token,
  //         ctoken: session.company_token,
  //       },
  //     })
  //     .then((res) => {
  //       let data = res.data;

  //       if (data.status === 0) {
  //         var userData = data.record.map((data) => {
  //           return { label: data.user_name, value: data.user_id };
  //         });

  //         setUserList(userData);
  //       } else {
  //         setUserList([]);
  //       }
  //     });
  // }, [userTagID, session]);

  const getAllBU = (loginData) => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllBU",
          company: loginData.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var buData = data.record.map((data) => {
            return { label: data.unit_title, value: data.business_unit_id };
          });

          setBuList(buData);
        } else {
          setBuList([]);
        }
      })
      .catch((error) => {
        setBuList([]);
      });
  };

  const getAllSource = (loginData) => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: "2",
          company: loginData.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var dsData = data.record.map((data) => {
            return { label: data.ds_title, value: data.ds_id, catID: data.source_category_id };
          });

          setDsList(dsData);

          var dsCatData = data.source_cat.map((data) => {
            return { label: data.source_category_title, value: data.source_category_id };
          });

          setDsCatList(dsCatData);
        } else {
          setDsList([]);
          setDsCatList([]);
        }
      });
  };

  const getAllStage = (loginData) => {
    axios
      .get("ws/ws_pipeline.php", {
        params: {
          task: "3",
          company: loginData.company_id,
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var stageData = data.record.map((data) => {
            return { label: data.cs_title, value: data.cs_id };
          });

          setCsList(stageData);
        } else {
          setCsList([]);
        }
      });
  };

  const getAllPipeline = (loginData) => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: loginData.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var pipeData = data.record.map((data) => {
            return { label: data.pipeline_title, value: data.pipeline_id };
          });

          setPipeList(pipeData);
        } else {
          setPipeList([]);
        }
      });
  };

  const statusList = [
    { label: t("Report_Deal_statusList_Won"), value: "won" },
    { label: t("Report_Deal_statusList_Lost"), value: "lost" },
    { label: t("Report_Deal_statusList_Deleted"), value: "deleted" },
    { label: t("Report_Deal_statusList_Open"), value: "open" },
  ];

  const leadStatusList = [
    { label: t("Report_Deal_LeadStatusList_NotContacted"), value: "new" },
    { label: t("Report_Deal_LeadStatusList_NeedFollowUp"), value: "inactive" },
    { label: t("Report_Deal_LeadStatusList_Progressing"), value: "active" },
  ];

  const getAllCuration = (loginData) => {
    axios
      .get("ws/ws_curation.php", {
        params: {
          task: "listCurationV2",
          mode: "deal_report",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var curationData = data.record.map((data) => {
            return { label: data.curation_title, value: data.curation_id };
          });
          var newArray = [{ label: t("Report_CreateNewCuration"), value: "create" }];
          var combineArray = [...newArray, ...curationData];
          setCurationList(combineArray);
        } else {
          setCurationList([{ label: t("Report_CreateNewCuration"), value: "create" }]);
        }
      });
  };

  const setProspectContact = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined || Number(contact) === 0) {
      return "";
    } else if (prefix === "" || prefix === null || prefix === undefined || Number(prefix) === 0) {
      let contactNumber = contact.replace(/\D/g, "");
      let mobile_region = "MY";
      const prefix_region_1 = MobilePrefix.find((prefix) => prefix.value === contactNumber.substring(0, 2));
      const prefix_region_2 = MobilePrefix.find((prefix) => prefix.value === contactNumber.substring(0, 3));
      const prefix_region_3 = MobilePrefix.find((prefix) => prefix.value === contactNumber.substring(0, 4));

      if (prefix_region_1 || prefix_region_2 || prefix_region_3) {
        mobile_region = prefix_region_1 ? prefix_region_1.regionCode : prefix_region_2 ? prefix_region_2.regionCode : prefix_region_3.regionCode;
      }

      const phoneNumber = parsePhoneNumberFromString(contactNumber, mobile_region);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational().replaceAll(" ", "");
      }

      return contactNumber;
    }

    let realPrefix = prefix.replace(/\D/g, "");
    let mobile_region = "MY";
    let mobile_prefix = MobilePrefix.find((prefix) => prefix.value === realPrefix);
    if (mobile_prefix) {
      mobile_region = mobile_prefix.regionCode;
    }

    const phoneNumber = parsePhoneNumberFromString(contact, mobile_region);
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatInternational().replaceAll(" ", "");
    }

    return `+${realPrefix}${contact}`;
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      setLoading(false);
      getAllTeam(session);
      //getAllUser(session);
      getAllBU(session);
      getAllSource(session);
      getAllStage(session);
      getAllPipeline(session);
      getAllCuration(session);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [reportColumn] = useState([
    {
      // headerComponent: CustomHeaderCheckbox,
      headerName: "",
      field: "",
      checkboxSelection: true,
      resizable: false,
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return "";
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName1"),
      field: "deal_date_time_create",
      resizable: true,
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          return moment(params.data.deal_date_time_create).format("lll");
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName2"),
      field: "deal_title",
      resizable: true,
      width: 200,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName3"),
      field: "owner_name",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName4"),
      field: "",
      resizable: true,
      width: 300,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.ACTIVITY_LIST.length > 0) {
            var activityList = params.data.ACTIVITY_LIST.map((data) => {
              return data.activity_title;
            });

            return activityList.toString().replaceAll(",", " ");
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.ACTIVITY_LIST.length > 0) {
            var activityList = params.data.ACTIVITY_LIST.map((data) => {
              return data.activity_title;
            });

            if (activityList.length > 1) {
              return (
                <div style={{ overflow: "auto", height: "60px", width: "280px" }}>
                  {activityList.map((data, i) => (
                    <div key={i}>{ReactHtmlParser(data)}</div>
                  ))}
                </div>
              );
            } else {
              return (
                <div>
                  {activityList.map((data, i) => (
                    <div key={i}>{ReactHtmlParser(data)}</div>
                  ))}
                </div>
              );
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName5"),
      field: "deal_value",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName6"),
      field: "cs_title",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName7"),
      field: "pipe_title",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName8"),
      field: "archieve_remark",
      resizable: true,
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          var value = "";
          if (params.data.deal_archieve) {
            value = `${params.data.deal_archieve}`;
            if (params.data.lost_reason && params.data.deal_archieve === "lost") {
              value = value + ` | ${t("Report_Deal_Reason")}: ${params.data.lost_reason}`;
            }

            if (params.data.archieve_remark) {
              value = value + ` | ${t("Report_Deal_Remark")}: ${params.data.archieve_remark}`;
            }
          }

          return value;
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName9"),
      field: "deal_status",
      resizable: true,
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.deal_status === "new") {
            return t("Report_Deal_LeadStatusList_NotContacted");
          } else if (params.data.deal_status === "inactive") {
            return t("Report_Deal_LeadStatusList_NeedFollowUp");
          } else if (params.data.deal_status === "active") {
            return t("Report_Deal_LeadStatusList_Progressing");
          } else {
            return "";
          }
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName10"),
      field: "prospect_name",
      resizable: true,
      width: 200,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName11"),
      resizable: true,
      width: 200,
      hide: true,
      field: "contact1",
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.contact1) {
            return setProspectContact(params.data.contact1_prefix, params.data.contact1).replace("+", "");
          }
        }
      },
    },
    {
      headerName: t("Report_Deal_HeaderName12"),
      resizable: true,
      width: 200,
      hide: true,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.prospect_email) {
            return params.data.prospect_email;
          }
        }
      },
    },
    {
      headerName: "Source Category",
      field: "source_category",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName13"),
      field: "ds_title",
      resizable: true,
      width: 200,
    },
    {
      headerName: "Business Unit",
      field: "business_unit",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName14"),
      field: "utm_source",
      hide: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName15"),
      field: "utm_medium",
      hide: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName16"),
      field: "utm_campaign",
      hide: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName17"),
      field: "utm_term",
      hide: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: t("Report_Deal_HeaderName18"),
      field: "utm_content",
      hide: true,
      resizable: true,
      width: 200,
    },
    { field: "deal_id", headerName: "Deal ID", hide: true },
    { field: "prospect_id", headerName: "Prospect ID", hide: true },
  ]);

  // const defaultExcelExportParams = useMemo(() => {
  //   return {
  //     autoConvertFormulas: true,
  //     processCellCallback: (params) => {
  //       const field = params.column.getColDef().field;
  //       return field === "deal_title" ? `=HYPERLINK("${session.origin}/deal/${params.data.deal_id}")` : params.data.deal_title;
  //     },
  //   }
  // }, []);

  // const excelStyles = useMemo(() => {
  //   return [
  //     {
  //       id: "hyperlinks",
  //       font: {
  //         underline: "Single",
  //         color: "#358ccb",
  //       },
  //     },
  //   ];
  // }, []);

  const handleClearSession = () => {
    sessionStorage.removeItem("dealReportParams");
  };

  const reportDataSource = useMemo(() => {
    const formatDateString = (dateStr) => {
      const dateParts = dateStr.split("-");
      if (dateParts.length === 3 && dateParts[2].length === 4) {
        const [day, month, year] = dateParts;
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
      return dateStr;
    };

    const storedParamsJson = sessionStorage.getItem("dealReportParams");
    const storedParams = storedParamsJson ? JSON.parse(storedParamsJson) : {};

    // Convert dateRange if needed
    if (storedParams.dateRange) {
      storedParams.dateRange.ds = formatDateString(storedParams.dateRange.ds);
      storedParams.dateRange.de = formatDateString(storedParams.dateRange.de);
    }

    setStorageParams(storedParams);

    if (storedParams && storedParams.dateRange && storedParams.dateRange.ds) {
      setStartDate(moment(new Date(storedParams.dateRange.ds)).format("YYYY-MM-DD"));
    }

    if (storedParams && storedParams.dateRange && storedParams.dateRange.de) {
      setEndDate(moment(new Date(storedParams.dateRange.de)).format("YYYY-MM-DD"));
    }

    if (storedParams && storedParams.user_id) {
      setUserID(storedParams.user_id);
    }

    if (storedParams && storedParams.source && storedParams.source.value) {
      setDsID(storedParams.source.value);
    }

    if (storedParams && storedParams.outcome) {
      setOutcomeVal(storedParams.outcome);
    }

    if (storedParams && storedParams.source_category && storedParams.source_category.value) {
      setDsCatID(storedParams.source_category.value);
    }

    if (storedParams && storedParams.business_unit && storedParams.business_unit.value) {
      setBuID(storedParams.business_unit.value);
    }

    if (storedParams && storedParams.lead_status) {
      setLeadStatVal(storedParams.lead_status);
    }

    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get(`ws/ws_dealreport.php`, {
            params: {
              task: "V2",
              ds: (storedParams && storedParams.dateRange && storedParams.dateRange.ds) || startDate,
              de: (storedParams && storedParams.dateRange && storedParams.dateRange.de) || endDate,
              user: Number(session.role_id) !== 1 ? session.user_id : storedParams && storedParams.user_id ? storedParams.user_id : userID.toString().replaceAll(",", "|"),
              source:
                storedParams && storedParams.source && storedParams.source.value ? storedParams.source.value : dsID.toString().replaceAll(",", "|"),
              stage: csID.toString().replaceAll(",", "|"),
              status: storedParams && storedParams.outcome ? storedParams.outcome : outcomeVal.toString().replaceAll(",", "|"),
              pipeid: pipeID,
              cfid: "",
              cfvalue: "",
              startRow: params.startRow,
              cfNeeded: showAllFd,
              activityNeeded: activityNeeded,
              prospectname: searchVal,
              contactNeeded: showContact,
              sourcecat:
                storedParams && storedParams.source_category && storedParams.source_category.value ? storedParams.source_category.value : dsCatID,
              bu_id: storedParams && storedParams.business_unit && storedParams.business_unit.value ? storedParams.business_unit.value : buID,
              leadstatus: storedParams && storedParams.lead_status ? storedParams.lead_status : leadStatVal.toString().replaceAll(",", "|"),
              user_tag: userTagID,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              if (Number(data.totalRecord) > 0) {
                setIsReport(true);
              } else {
                setIsReport(false);
              }
            } else {
              setIsReport(false);

              Swal.fire({
                icon: "error",
                title: t("Report_alert_Title"),
                text: t("Report_alert_Text"),
              });
            }

            setTimeout(() => {
              var reportInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                reportInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setRecordFound(totalRecord);
                if (Number(data.deal_custom_field_cf_info_total) > 0) {
                  var colDef = [];
                  data.deal_custom_field_cf_info.forEach((data) => {
                    if (data.cf_area === "person") {
                      colDef.push({
                        headerName: data.cf_label,
                        field: "",
                        width: 200,
                        hide: true,
                        valueGetter: (params) => {
                          if (params.data !== undefined) {
                            if (Number(params.data.prospect_custom_field) > 0) {
                              for (let index = 0; index < params.data.prospect_custom_field; index++) {
                                if (params.data.prospect_custom_field_data[index].cf_id === data.cf_id) {
                                  return params.data.prospect_custom_field_data[index].fd_value;
                                }
                              }
                            } else {
                              return "";
                            }
                          } else {
                            return "";
                          }
                        },
                      });
                    } else if (data.cf_area === "deal") {
                      colDef.push({
                        headerName: data.cf_label,
                        field: "",
                        width: 200,
                        hide: true,
                        valueGetter: (params) => {
                          if (params.data !== undefined) {
                            if (Number(params.data.deal_custom_field) > 0) {
                              for (let index = 0; index < params.data.deal_custom_field; index++) {
                                if (params.data.deal_custom_field_data[index].cf_id === data.cf_id) {
                                  return params.data.deal_custom_field_data[index].fd_value;
                                }
                              }
                            } else {
                              return "";
                            }
                          } else {
                            return "";
                          }
                        },
                      });
                    }
                  });

                  if (data.SHOW_CF === 0) {
                    colDef.forEach((data) => {
                      if (data.headerName !== "" && data.field === "") {
                        data.hide = true;
                      }
                    });

                    gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                  } else {
                    colDef.forEach((data) => {
                      if (data.headerName !== "" && data.field === "") {
                        data.hide = false;
                      }
                    });

                    gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                  }
                }

                if (data.SHOW_ACTIVITY === 0) {
                  reportColumn.forEach((data) => {
                    if (data.headerName === "All Activities") {
                      data.hide = true;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                } else {
                  reportColumn.forEach((data) => {
                    if (data.headerName === "All Activities") {
                      data.hide = false;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                }

                if (data.SHOW_PROSPECT_CONTACT === 0) {
                  reportColumn.forEach((data) => {
                    if (data.headerName === "Prospect Contact") {
                      data.hide = true;
                    }

                    if (data.headerName === "Prospect Email") {
                      data.hide = true;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                } else {
                  reportColumn.forEach((data) => {
                    if (data.headerName === "Prospect Contact") {
                      data.hide = false;
                    }

                    if (data.headerName === "Prospect Email") {
                      data.hide = false;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                }

                if (isUTM) {
                  reportColumn.forEach((data) => {
                    if (data.headerName.includes("UTM")) {
                      data.hide = false;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                } else {
                  reportColumn.forEach((data) => {
                    if (data.headerName.includes("UTM")) {
                      data.hide = true;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...(Array.isArray(colDef) && colDef.length > 0 ? colDef : [])]);
                }
              } else {
                reportInfo = [];
                totalRecord = 0;
                setRecordFound(totalRecord);
              }

              var rowsThisPage = reportInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);

              handleClearSession();
            }, 500);
          });
      },
    };
  }, [trigger, session]); // eslint-disable-line react-hooks/exhaustive-deps

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const RenderBulkActionToggle = forwardRef(({ children, onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <button ref={ref} onClick={openDropdown} className="btn btn-light shadow">
        <FontAwesomeIcon icon={faBullhorn} size="lg" />
        {children}
      </button>
    );
  });

  const RenderBulkActionMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Dropdown.Header className="fw-semibold op-text-medium">{t("Report_BulkAction")}</Dropdown.Header>
        <Dropdown.Item onClick={openAddToCuration} disabled={Number(session.company_id) === 251 ? true : false}>
          {t("Report_Add_To_Curation")}
        </Dropdown.Item>
      </div>
    );
  });

  const addToCurSchema = yup.object().shape({
    curation_id: yup.string().required(t("Report_RequiredFd")),
    curation_title: yup
      .string()
      .notRequired()
      .when("curation_id", ([curation_id], schema) => {
        if (curation_id === "create") return yup.string().required(t("Report_RequiredFd"));
        return schema;
      }),
  });

  const openAddToCuration = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("Report_addToCur_maxRecord_alert_Title"),
        text: t("Report_addToCur_maxRecord_alert_Text"),
      });
    } else {
      setIsAddToCur(true);
    }
  };

  const handleDownload = () => {
    const gridApi = gridRef.current.api;
    const columnApi = gridRef.current.columnApi;
    const visibleColumns = columnApi
      .getAllDisplayedColumns()
      .slice(1)
      .filter((col) => col.isVisible());

    const columnWidths = visibleColumns.map((col) => ({ wch: 30 }));
    const columnHeaders = visibleColumns.map((col) => col.getColDef().headerName);
    const columnFields = visibleColumns.map((col) => col.getColId());

    const rowData = [];
    gridApi.forEachNode((node) => {
      const formattedRow = {};
      columnFields.forEach((field, index) => {
        let value = gridApi.getValue(field, node);
        value = value ? value.trim() : value;

        if (field === "deal_title") {
          const dealId = gridApi.getValue("deal_id", node);
          const url = `https://app-r1.outperformhq.io/${session.origin}/deal/${dealId}`;
          value = { text: value, hyperlink: url };
          formattedRow[columnHeaders[index]] = {
            text: value.text,
            hyperlink: url,
            l: { Target: url },
            v: value.text,
          };
        } else if (field === "prospect_name") {
          const prospectId = gridApi.getValue("prospect_id", node);
          const url = `https://app-r1.outperformhq.io/${session.origin}/prospect/${prospectId}`;
          value = { text: value, hyperlink: url };
          formattedRow[columnHeaders[index]] = {
            text: value.text,
            hyperlink: url,
            l: { Target: url },
            v: value.text,
          };
        } else {
          formattedRow[columnHeaders[index]] = value;
        }

        console.log("Debugging: ", {
          field,
          header: columnHeaders[index],
          value,
          assignedValue: formattedRow[columnHeaders[index]],
        });
      });
      rowData.push(formattedRow);
    });

    const worksheet = XLSX.utils.json_to_sheet(rowData, { header: columnHeaders });
    worksheet["!cols"] = columnWidths;
    rowData.forEach((row, rowIndex) => {
      columnFields.forEach((field, colIndex) => {
        const cell = worksheet[XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 })];
        if (field === "deal_title" && typeof row[columnHeaders[colIndex]] === "object") {
          cell.l = { Target: row[columnHeaders[colIndex]].hyperlink };
          cell.v = row[columnHeaders[colIndex]].text;
        }
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "grid-data.xlsx");
  };

  const test = (data) => {
    const setHyperLink = (header, value) => {
      if (["Deal Title", "Prospect Name"].includes(header.trim())) {
        return `=HYPERLINK("https://app-r1.outperformhq.io/${session.origin}/${
          header.trim() === "Deal Title" ? "deal" : "prospect"
        }/${value}", "${value}")`;
      } else {
        return value;
      }
    };

    // Split the CSV data into rows
    const rows = data.split("\n").map((row) => row.split('","'));
    const headers = rows[0];
    const jsonData = rows.slice(1).map((row) => {
      const rowData = {};
      for (let index = 1; index < row.length; index++) {
        rowData[headers[index] ? headers[index].trim().replace(/"/g, "") : ""] = row[index]
          ? setHyperLink(headers[index], row[index].replace(/"/g, ""))
          : "";
      }
      return rowData;
    });

    // Create a new workbook
    const columnWidths = headers.map((row) => {
      return { wch: 30 };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    worksheet["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Deal Report");

    // Generate a file and trigger download
    XLSX.writeFile(workbook, "deal_report.xlsx");
  };

  const sendAddCuration = async (values) => {
    if (values.curation_id === "create") {
      triggerCreateCuration(values);
    } else {
      triggerAddToCuration(values);
    }
  };

  const triggerCreateCuration = (values) => {
    axios
      .get("ws/ws_curation.php", {
        params: {
          task: "addCurationV2",
          curation_title: values.curation_title,
          curation_source: "deal_report",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          values.curation_id = data.record;
          triggerAddToCuration(values);
        } else {
          Swal.fire({
            icon: "error",
            title: t("Report_addToCur_Alert_Failed_Title"),
            text: t("Report_addToCur_Alert_Failed_Text"),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const triggerAddToCuration = async (values) => {
    var prospectID = rowData.map((record) => {
      return record.data.prospect_id;
    });

    var curationApiData = new FormData();

    curationApiData.append("task", "addCurationProspect");
    curationApiData.append("curation", values.curation_id);
    curationApiData.append("prospects", prospectID.toString());
    curationApiData.append("utoken", session.user_ac_token);
    curationApiData.append("ctoken", session.company_token);

    try {
      let response = await axios.post("ws/ws_curation.php", curationApiData);

      let data = response.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("Report_addToCur_Alert_Success_Title"),
          text: t("Report_addToCur_Alert_Success_Text"),
          timer: 1500,
        }).then((result) => {
          setIsAddToCur(false);
          gridRef.current.api.forEachNode((node) => node.setSelected(false));
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("Report_addToCur_Alert_Failed_Title"),
          text: t("Report_addToCur_Alert_Failed_Text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const handleDisable = () => {
    console.log(permission);
    var permissionReturn = permission.getUserPermission && permission.getUserPermission.record && Number(permission.getUserPermission.record[0].rules5) === 0 ? true : false
    console.log(permissionReturn);
    return permissionReturn
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="report-main">
          <div className="report-filter">
            <section className="header shadow-sm">
              <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t("Report_FilterCriteria")}</div>
            </section>

            <section className="content">
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={t("Report_Deal_SearchByDealProsOrg")}
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={(storageParams && storageParams.dateRange && storageParams.dateRange.ds) || startDate}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={(storageParams && storageParams.dateRange && storageParams.dateRange.de) || endDate}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              {Number(session.role_id) === 1 && <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllTeam")}
                  options={userTagList}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    setUserTagID(e ? e.value : "");
                  }}
                />
              </Form.Group>}
              {Number(session.role_id) === 1 && <Form.Group className="mb-3">
                <FormSelect
                  options={userList}
                  placeholder={t("Report_AllUser")}
                  value={userList.find((option) => Number(option.value) === Number(userID))}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e !== null
                      ? setUserID(
                          e.map((d) => {
                            return d.value;
                          })
                        )
                      : setUserID("");
                  }}
                />
              </Form.Group>}
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllBU")}
                  options={buList}
                  // value={buList.find(option => option.value == storageParams.business_unit.value)}
                  value={buList.find((option) => option.value === buID)}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    setBuID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllDSCat")}
                  options={dsCatList}
                  // value={dsCatList.find(option => option.value == storageParams.source_category.value)}
                  value={dsCatList.find((option) => Number(option.value) === Number(dsCatID))}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    setDsCatID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllDS")}
                  options={dsCatID ? dsList.filter((option) => Number(option.catID) === Number(dsCatID)) : dsList}
                  value={
                    dsCatID
                      ? dsList.filter((option) => Number(option.catID) === Number(dsCatID) && Number(option.value) === Number(dsID))
                      : dsList.find((option) => Number(option.value) === Number(dsID))
                  }
                  isClearable={true}
                  isSearchable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e !== null
                      ? setDsID(
                          e.map((d) => {
                            return d.value;
                          })
                        )
                      : setDsID("");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllStage")}
                  options={csList}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e !== null
                      ? setCsID(
                          e.map((d) => {
                            return d.value;
                          })
                        )
                      : setCsID("");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllOutcome")}
                  options={statusList}
                  value={statusList.find((option) => option.value === outcomeVal)}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e !== null
                      ? setOutcomeVal(
                          e.map((d) => {
                            return d.value;
                          })
                        )
                      : setOutcomeVal("");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllPipeline")}
                  options={pipeList}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    setPipeID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllLeadStatus")}
                  options={leadStatusList}
                  value={leadStatusList.find((option) => option.value === leadStatVal)}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e !== null
                      ? setLeadStatVal(
                          e.map((d) => {
                            return d.value;
                          })
                        )
                      : setLeadStatVal("");
                  }}
                />
              </Form.Group>
              <div className="op horizontal-start divider op-text-medium my-3">{t("Report_CustomField")}</div>
              <Form.Group className="mb-1">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowActivity")}
                  disabled={
                    permission.getUserPermission &&
                    permission.getUserPermission.record &&
                    Number(permission.getUserPermission.record[0].rules1) === 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    e.target.checked === true ? setActivityNeeded(1) : setActivityNeeded(0);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowAllField")}
                  disabled={
                    permission.getUserPermission &&
                    permission.getUserPermission.record &&
                    Number(permission.getUserPermission.record[0].rules2) === 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    e.target.checked === true ? setShowAllFd(1) : setShowAllFd(0);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowProspectContact")}
                  disabled={
                    permission.getUserPermission &&
                    permission.getUserPermission.record &&
                    Number(permission.getUserPermission.record[0].rules3) === 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    e.target.checked === true ? setShowContact(1) : setShowContact(0);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowUTM")}
                  onChange={(e) => {
                    e.target.checked === true ? setIsUTM(true) : setIsUTM(false);
                  }}
                />
              </Form.Group>
            </section>

            <section className="footer">
              <div className="d-flex justify-content-center align-items-center w-100">
                <button
                  className="btn op-button op-primary-color text-light shadow me-2"
                  disabled={
                    permission.getUserPermission &&
                    permission.getUserPermission.record &&
                    Number(permission.getUserPermission.record[0].rules4) === 0
                      ? true
                      : false
                  }
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                >
                  {t("Report_ViewReport")}
                </button>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={
                    handleDisable()
                  }
                  onClick={handleDownload}
                >
                  {t("Report_Download")}
                </button>
              </div>
            </section>
          </div>

          <div className="report-content">
            <section className="w-100">
              <Stack direction="horizontal">
                {isReport && (
                  <div className="op-label bg-light shadow-sm">
                    <FormCheck type="checkbox" onClick={handleClick} checked={isChecked} />
                  </div>
                )}
                {isRowSelected === true ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={RenderBulkActionToggle}></Dropdown.Toggle>
                      <Dropdown.Menu className="shadow border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                    </Dropdown>
                    <h6 className="op-text-medium mt-2 ms-2">
                      {rowCount} {rowCount > 1 ? "rows" : "row"} {t("Report_Selected")}
                    </h6>
                  </>
                ) : null}
                {isReport && (
                  <div className="p-2 ms-auto">
                    {Number(recordFound) === 1
                      ? t("Report_Record_one", { count: recordFound })
                      : Number(recordFound) > 1
                      ? t("Report_Record_other", { count: recordFound })
                      : t("Report_NoRecord")}
                  </div>
                )}
              </Stack>
            </section>
            <section className="w-100">
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={gridRef}
                    onSelectionChanged={onReportSelected}
                    columnDefs={reportColumn}
                    datasource={reportDataSource}
                    defaultColDef={reportColDef}
                    // defaultExcelExportParams={defaultExcelExportParams}
                    // excelStyles={excelStyles}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={50}
                    cacheBlockSize={10000}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={1}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={200}
                    getRowId={dealRowId}
                    pagination={true}
                    paginationPageSize={10000}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    suppressExcelExport={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      <Offcanvas show={isAddToCur} onHide={setIsAddToCur} backdrop="static" placement="end">
        <Formik
          validationSchema={addToCurSchema}
          onSubmit={sendAddCuration}
          initialValues={{
            curation_id: "",
            curation_title: "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Report_Add_To_Curation")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold mb-3">
                  {rowCount > 1 ? rowCount + ` ${t("Report_Offcanvas_records")}` : rowCount + ` ${t("Report_Offcanvas_record")}`}{" "}
                  {t("Report_Effected")}
                </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="curation_id"
                    placeholder={t("Report_Offcanvas_SelectCur")}
                    options={curationList}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("curation_id", e ? e.value : "");
                      setFieldValue("curation_title", "");
                    }}
                    isInvalid={errors.curation_id && touched.curation_id}
                  />
                  {errors.curation_id && touched.curation_id && <div className="op-error-message">{errors.curation_id}</div>}
                </Form.Group>
                {values.curation_id === "create" && (
                  <Form.Group>
                    <Form.Label>{t("Report_CurationTitle")}</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={errors.curation_title && touched.curation_title}
                      onChange={(e) => {
                        setFieldValue("curation_title", e.target.value);
                      }}
                    />
                    {errors.curation_title && touched.curation_title && <div className="op-error-message">{errors.curation_title}</div>}
                  </Form.Group>
                )}
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("Report_Offcanvas_Footer")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </Container>
  );
};

export default DealReport;
