export const routeLevelAccess = (location, session, params) => {
  const { deal_id, prospect_id, chat_instance_id, rea_unit_id, booking_id, rea_project_id, rea_phase_id, level_id, carpark_id, curation_id } = params;

  let access = true;
  switch (location) {
    case "/curation":
    case `/curation/list`:
    case `/curation/${curation_id}`:
      access = Number(session.curation_enabled) !== 0;
      break;
    case "/lead-aging":
      // access = [251, 180, 190, 202, 203, 212, 213, 341, 106, 2, 400, 409, 210, 200, 385].includes(Number(session.company_id));
      access = Number(session.lead_aging) === 1 && Number(session.role_id) === 1;
      break;
    case "/ai-outreach":
      access = Number(session.blueprint_ai) === 1;
      break;
    case "/report-transfer-lead":
      access = Number(session.role_id) === 1 && Number(session.lead_transfer_report) !== 0;
      break;
    case "/report-inbound-analytics":
      access = Number(session.role_id) === 1 && Number(session.inbound_analytics) === 1;
      break;
    case "/report-inbound-lead-performance":
      access =
        Number(session.role_id) === 1 &&
        Number(session.layout_mode) === "real-estate" &&
        (Number(session.company_lead_assignment_autoreroute) === 1 || Number(session.company_lead_assignment_autoreroute) === 0);
      break;
    case "/report-incoming-log-booking":
      access = Number(session.booking_incoming_log) === 1;
      break;
    case "/report-contact":
    // case "/report-deal":
    case "/report-duplicate-lead":
    case "/report-productivity":
    case "/report-activity":
    case "/report-deal-engagement":
    case "/report-deal-performance":
    case "/report-lead-acceptance":
    case "/report-lead-route":
    case "/report-marketing-analytics":
    case "/report-schedule-deal":
    case "/users":
    case "/users/list":
    case "/users/permission":
    case "/users/user-login-redirection":
      access = Number(session.role_id) === 1;
      break;
    case `/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`:
    case `/booking-details/${booking_id}/${deal_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`:
    case `/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`:
    case `/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${level_id}`:
    case `/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${carpark_id}`:
    case `/booking/dashboard`:
    case `/booking/list`:
    case `/booking/project/${deal_id}/${prospect_id}`:
    case `/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`:
    case `/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`:
      access = Number(session.booking_module) === 1;
      break;
    case `/conversation`:
    case `/conversation/${chat_instance_id}`:
    case `/settings/waba`:
    case `/settings/blueprint2`:
    case `/settings/conversation-flow`:
      access = Number(session.whatsapp_conversation) !== 0;
      break;
    case `/call-analytics`:
    case `/call-insight`:
      access = Number(session.avanser_enabled) === 1;
      break;
    case `/settings/deal-source`:
    case `/settings/source-category`:
    case `/settings/business-unit`:
    case `/settings/avanser-call`:
    case `/settings/device-notification`:
    case `/settings/facebook-token`:
    case `/settings/lost-reason`:
      access = Number(session.role_id) === 1;
      break;
    case `/gamuda/redemption/dashboard`:
    case `/gamuda/redemption/voucher`:
    case `/gamuda/redemption/category`:
    case `/gamuda/redemption/vendor`:
    case `/gamuda/redemption/email`:
    case `/gamuda/report-incoming-log`:
    case `/gamuda/report-outgoing-log`:
    case `/gamuda/report-gl-play`:
    case `/gamuda/segment/${curation_id}`:
      access = Number(session.company_id) === 251;
      break;
    case `/gamuda/srm-dashboard`:
      access = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412].includes(Number(session.company_id)) && Number(session.srm_dashboard) === 1;
      break;
    case `/settings/products`:
      access = Number(session.product_feature) === 1 && session.layout_mode !== "real-estate";
      break;
    case `/settings/bulk-update`:
      access = Number(session.company_id) === 251;
      break;
    case `/gamuda/list-tier`:
      access = [251, 180, 200, 190, 203, 202, 212, 213, 210].includes(Number(session.company_id));
      break;
    case `/event-rsvp`:
      access = Number(session.event_rsvp) === 1;
      break;
    case `/survey-dashboard`:
    case `/settings/survey-settings`:
      access = Number(session.survey) === 1 && Number(session.role_id) === 1;
      break;
    case `/settings/ws-campaign-template-360`:
    case `/whatsapp-campaign`:
      access = Number(session.whatsapp_campaign) === 1 && Number(session.role_id) === 1;
      break;
    case `/ai-usage-dashboard`:
      access = Number(session.usage_ai) === 1 && Number(session.role_id) === 1;
      break;
    case `/chatbot-conversation`:
      access = Number(session.blueprint_ai) === 1;
      break;
    case `/cs1`:
    case `/cs2`:
    case `/cs1-v2`:
    case `/cs2-v2`:
      access = Number(session.customer_survey) === 1;
      break;
    case `/queue-rotation-system`:
      access =
        (Number(session.company_id) === 430 && session.hostUrlType === "cloud") ||
        (Number(session.company_id) === 106 && session.hostUrlType === "cloud_staging");
      break;
    default:
      access = true;
      break;
  }
  return access;
};

export const routePath = (location, params, t) => {
  const {
    deal_id,
    prospect_id,
    chat_instance_id,
    rea_unit_id,
    booking_id,
    rea_project_id,
    rea_phase_id,
    level_id,
    carpark_id,
    curation_id,
    organization_id,
    key,
    project_id,
    phase_id,
    unit_number,
  } = params;
  const paths = {
    [`/deal/view/list`]: t("Container_deal_listing"),
    [`/deal/view/pipeline`]: t("Container_deal_pipeline"),
    [`/deal/${deal_id}`]: t("Container_deal_details"),
    [`/prospect/list`]: t("Container_contact"),
    [`/prospect/${prospect_id}`]: t("Container_contact_details"),
    [`/activity/list`]: t("Container_activity"),
    [`/activity/calendar`]: t("Container_calendar"),
    [`/curation`]: "Curation",
    [`/curation/list`]: "Curations",
    [`/curation/${curation_id}`]: "Curation Details",
    [`/users`]: t("Container_user"),
    [`/users/list`]: "Users",
    [`/users/permission`]: "User Permission",
    [`/users/user-login-redirection`]: "User Login Redirection",
    [`/report-contact`]: t("Container_contact_report"),
    [`/report-deal`]: t("Container_deal_report"),
    [`/report-transfer-lead`]: t("Container_transfer_lead_report"),
    [`/report-duplicate-lead`]: t("Container_duplicate_lead_report"),
    [`/report-productivity`]: t("Container_productivity_report"),
    [`/report-activity`]: t("Container_activity_report"),
    [`/report-deal-engagement`]: t("Container_deal_engagement_report"),
    [`/report-deal-performance`]: t("Container_deal_performance_report"),
    [`/report-inbound-analytics`]: t("Container_inbound_analytics_report"),
    [`/report-inbound-lead-performance`]: "Inbound Lead Performance",
    [`/report-lead-acceptance`]: t("Container_lead_acceptance_report"),
    [`/report-lead-route`]: t("Container_lead_routing"),
    [`/report-marketing-analytics`]: t("Container_marketing_analytics"),
    [`/report-schedule-deal`]: t("Container_schedule_deal_report"),
    [`/report-open-deal`]: t("Container_status_summary_report"),
    [`/report-won-deal`]: t("Container_won_deal_report"),
    [`/report-lost-deal`]: t("Container_lost_deal_report"),
    [`/report-login`]: "Login Report",
    [`/report-incoming-log-booking`]: "Booking Incoming Log Report",
    [`/report-sms-campaign`]: "SMS Campaign",
    [`/whatsapp-campaign`]: "Whatsapp Campaign",
    [`/statistics`]: "Statistics",
    [`/lead-aging`]: "Lead Aging Report",
    [`/ai-outreach`]: "AI Outreach Dashboard",
    [`/call-insight`]: "Call Insight Dashboard",
    [`/call-analytics`]: "Call Analytics",
    [`/conversation`]: "Conversation",
    [`/conversation/${chat_instance_id}`]: "Conversation",
    [`/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`]: "Booking Form",
    [`/booking-details/${booking_id}/${deal_id}`]: "Booking Details",
    [`/sales-chart/unit/${deal_id}/${prospect_id}`]: "Sales Kit",
    [`/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`]: "Sales Kit",
    [`/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`]: "Unit Details",
    [`/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(level_id)}`]: "Sales Kit Level",
    [`/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(carpark_id)}`]: "Sales Kit Car Park",
    [`/booking/dashboard`]: "Booking Dashboard",
    [`/booking/list`]: "Booking",
    [`/booking/project/${deal_id}/${prospect_id}`]: "Project",
    [`/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`]: "Phase",
    [`/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`]: "Phase Details",
    [`/booking/approval`]: "Booking Approval",
    [`/booking/interest`]: "Interest",
    [`/booking/unit-price`]: "Unit Price",
    [`/settings/facebook-token`]: t("Container_facebook_token_settings"),
    [`/settings/business-unit`]: t("Container_business_unit_settings"),
    [`/settings/source-category`]: t("Container_source_category_settings"),
    [`/settings/lost-reason`]: t("Container_lost_reason_settings"),
    [`/settings/device-notification`]: t("Container_device_notification_settings"),
    [`/settings/avanser-call`]: t("Container_avanser_call_settings"),
    [`/settings/deal-source`]: t("Container_deal_source_settings"),
    [`/settings/pipeline`]: t("Container_pipeline_settings"),
    [`/settings/fields/deal`]: t("Container_custom_field"),
    [`/settings/fields/person`]: t("Container_custom_field"),
    [`/settings/fields/organization`]: t("Container_custom_field"),
    [`/settings/fields/booking`]: t("Container_custom_field"),
    [`/settings/fb-connection`]: t("Container_fb_connection"),
    [`/settings/permission`]: t("Container_permission_settings"),
    [`/settings/survey-settings`]: "Survey Settings",
    [`/organizations/list`]: "Organizations",
    [`/organizations/${organization_id}`]: "Organization Details",
    [`/event-rsvp`]: "Event RSVP",
    [`/survey-dashboard`]: "Survey Dashboard",
    [`/cs1`]: "Customer Survey 1",
    [`/cs2`]: "Customer Survey 2",
    [`/cs1-v2`]: "Customer Survey 1 V2",
    [`/cs2-v2`]: "Customer Survey 2 V2",
    [`/campaign-dashboard`]: t("Container_campaign_dashboard"),
    [`/settings/email-template`]: t("Container_email_template_settings"),
    [`/settings/whatsapp-template`]: t("Container_whatsapp_template_settings"),
    [`/settings/smartlink-template`]: t("Container_smartlink_template_settings"),
    [`/settings/fb-form`]: t("Container_facebook_forms"),
    [`/settings/team`]: t("Container_team_settings"),
    [`/settings/meetup`]: t("Container_meetup_settings"),
    [`/settings/notification`]: t("Container_notification_settings"),
    [`/settings/waba`]: "Waba Settings",
    [`/settings/blueprint2`]: "Blueprint2 Settings",
    [`/settings/conversation-flow`]: "Conversation Flow Settings",
    [`/settings/marketing-form`]: t("Container_marketing_form_settings"),
    [`/settings/lead-score`]: t("Container_lead_score_settings"),
    [`/settings/campaign-settings`]: t("Container_campaign_settings"),
    [`/settings/upload-leads`]: t("Container_upload_leads"),
    [`/settings/chat-instance`]: t("Container_chat_instance"),
    [`/settings/ws-campaign-template-360`]: "Whatsapp Campaign Template",
    [`/gamuda/list-tier`]: "Gamuda Tier",
    [`/gamuda/redemption/dashboard`]: "Redemption Dashboard",
    [`/gamuda/redemption/voucher`]: "Redemption Voucher",
    [`/gamuda/redemption/category`]: "Redemption Category",
    [`/gamuda/redemption/vendor`]: "Redemption Vendor",
    [`/gamuda/redemption/email`]: "Redemption Email Subject",
    [`/gamuda/list-tier`]: "Gamuda Tier",
    [`/gamuda/report-incoming-log`]: "Incoming Log",
    [`/gamuda/report-outgoing-log`]: "Outgoing Log",
    [`/gamuda/report-gl-play`]: "GL Play",
    [`/gamuda/srm-dashboard`]: "SRM Dashboard",
    [`/rea-sales-kit/${key}`]: "Sales Kit",
    [`/rea-sales-kit/${key}/project`]: "Project",
    [`/rea-sales-kit/${key}/${project_id}`]: "Project Details",
    [`/rea-sales-kit/${key}/${project_id}/${phase_id}`]: "Phase Details",
    [`/rea-sales-kit/${key}/${project_id}/${phase_id}/saleschart`]: "Sales Chart",
    [`/rea-sales-kit/${key}/${project_id}/${phase_id}/${unit_number}`]: "Unit Details",
    [`/admin-settings/waba`]: "Waba Settings",
    [`/admin-settings/blueprint2`]: "Blueprint2 Settings",
    [`/admin-settings/conversation-flow`]: "Conversation Flow Settings",
    [`/admin-settings/project`]: "Project Settings",
    [`/admin-settings/phase`]: "Phase Settings",
    [`/admin-settings/type`]: "Unit Type Settings",
    [`/admin-settings/unit`]: "Unit Settings",
    [`/admin-settings/modules`]: "Booking Modules",
    [`/admin-settings/coords`]: "Sales Chart Coords",
    [`/admin-settings/form-format`]: "Booking Form Format",
    [`/admin-settings/facilities`]: "Facilties",
    [`/admin-settings/amenities`]: "Amenities",
    [`/admin-settings/files`]: "Brochures and Files",
    [`/admin-settings/contact`]: "Contacts",
    [`/admin-settings/fact-sheet`]: "AI Fact Sheet",
    [`/settings/products`]: "Products",
    [`/settings/bulk-update`]: "Bulk Contacts",
    [`/settings/getresponse-connection`]: t("Container_getresponse_connection"),
    [`/lead-analytic`]: "Lead Analytic",
    [`/chatbot-conversation`]: "Chatbot Conversation",
    [`/ai-usage-dashboard`]: "AI Usage Dashboard",
    [`/queue-rotation-system`]: "Queue Rotation System",
    default: "Outperform",
  };

  return paths[location] || paths.default;
};

export const routeChild = (location, params) => {
  const { deal_id, prospect_id, rea_unit_id, booking_id, rea_project_id, rea_phase_id, level_id, carpark_id, curation_id } = params;
  let access = true;
  switch (location) {
    case `/booking-details/${booking_id}/${deal_id}`:
    case `/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`:
    case `/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`:
    case `/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(level_id)}`:
    case `/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(carpark_id)}`:
    case `/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`:
    case `/curation/${curation_id}`:
    case `/gamuda/segment/${curation_id}`:
      access = true;
      break;
    default:
      access = false;
      break;
  }
  return access;
};
