import { useState, useRef, useEffect, useCallback, forwardRef, useMemo, useLayoutEffect } from "react";
import { FormSelectCreatable, FormCustomField, FormSelect, FormCurrency } from "../includes/FormCustom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Stack,
  Dropdown,
  Card,
  Nav,
  Accordion,
  useAccordionButton,
  CloseButton,
  Form,
  ToggleButton,
  ButtonGroup,
  Modal,
  Spinner,
  Button,
  ListGroup,
} from "react-bootstrap";
import "../lib/scss/op-deal-details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faChevronDown, faCircleXmark, faPlus as faPlusSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  faArrowsRotate,
  faBuildings,
  faChevronRight,
  faCircleDollar,
  faCircle as faCircleLight,
  faGlobe,
  faIndustryWindows,
  faTimelineArrow,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import {
  faGlobePointer,
  faBriefcase,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faPencilAlt,
  faPhoneVolume,
  faRightLeft,
  faStopwatch,
  faUser,
  faUsers,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faPaperclipVertical,
  faEnvelope,
  faCalendarDay,
  faBarsStaggered,
  faBuilding,
  faShieldExclamation,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faFloppyDisk,
  faHouseBuilding,
  faHouseChimneyWindow,
  faHouse,
  faLocationDot,
  faDollarSign,
  faEnvelopesBulk,
  faFax,
  faBrowser as faBrowser2,
  faShieldCheck,
  faPlus,
  faList,
  faCircleCheck as faCircleCheckLight,
  faCircleXmark as faCircleXMarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { MobilePrefix, setCurrency, setDateTimeStr } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import ReactHtmlParser from "html-react-parser";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { useNavigate } from "react-router-dom";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";
import { parsePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { debounce } from "lodash";
import FsLightbox from "fslightbox-react";

const durationList = [
  { label: "5 Mins", value: "5min" },
  { label: "10 Mins", value: "10min" },
  { label: "15 Mins", value: "15min" },
  { label: "20 Mins", value: "20min" },
  { label: "30 Mins", value: "30min" },
  { label: "35 Mins", value: "35min" },
  { label: "40 Mins", value: "40min" },
  { label: "45 Mins", value: "45min" },
  { label: "50 Mins", value: "50min" },
  { label: "55 Mins", value: "55min" },
  { label: "1 Hour", value: "60min" },
  { label: "1 Hour 30 Mins", value: "90min" },
  { label: "2 Hours", value: "120min" },
  { label: "2 Hours 30 Mins", value: "150min" },
  { label: "3 Hours", value: "180min" },
  { label: "3 Hours 30 Mins", value: "210min" },
  { label: "4 Hours", value: "240min" },
];

const timings = [
  { label: "08:00", value: "08:00" },
  { label: "08:15", value: "08:15" },
  { label: "08:30", value: "08:30" },
  { label: "08:45", value: "08:45" },
  { label: "09:00", value: "09:00" },
  { label: "09:15", value: "09:15" },
  { label: "09:30", value: "09:30" },
  { label: "09:45", value: "09:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];

const ProspectDetails = () => {
  const { prospect_id } = useParams();
  const init = useRef(false);
  const { session, logoutUser } = useAuth();
  const [prospectSession, setProspectSession] = useState({
    company_id: session.company_id,
    company_token: session.company_token,
    role_id: session.role_id,
    user_ac_token: session.user_ac_token,
    user_id: session.user_id,
    user_name: session.user_name,
  });
  const { Formik, useFormikContext } = formik;
  const editorRef = useRef(null);
  const prosDealGridRef = useRef();
  const edmGridRef = useRef();
  const auHisGridRef = useRef();
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const openDealGridRef = useRef();
  const wonDealGridRef = useRef();
  const inbLdGridRef = useRef();
  const lostDealGridRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDeal, setIsAddDeal] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isAddContr, setIsAddContr] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const [isFindOrg, setIsFindOrg] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [prosDetData, setProsDetData] = useState([]);
  const [analyticData, setAnalyticData] = useState({});
  const [initial, setInitial] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedContr, setSelectedContr] = useState([]);
  const [initialVal, setInitialVal] = useState({});
  const [editNoteInit, setEditNoteInit] = useState({});
  const [isEditNote, setIsEditNote] = useState(false);
  const [editActivityInit, setEditActivityInit] = useState({});
  const [isEditActivity, setIsEditActivity] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [pipeData, setPipeData] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [isMain, setIsMain] = useState("");
  const [isProsTab, setIsProsTab] = useState("contact");
  const [contactPrefData, setContactPrefData] = useState({});
  const [isEditContactPref, setIsEditContactPref] = useState(false);
  const [prosDealRowData, setProsDealRowData] = useState([]);
  const [isProsDeal, setIsProsDeal] = useState(false);
  const [edmRowData, setEdmRowData] = useState([]);
  const [isEdm, setIsEdm] = useState(false);
  const [auHisRowData, setAuHisRowData] = useState([]);
  const [isAuHis, setIsAuHis] = useState(false);
  const [analyticOpen, setAnalyticOpen] = useState(false);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [openDealRowData, setOpenDealRowData] = useState([]);
  const [analyticWon, setAnalyticWon] = useState(false);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [wonDealRowData, setWonDealRowData] = useState([]);
  const [analyticInbLd, setAnalyticInbLd] = useState(false);
  const [isInbLd, setIsInbLd] = useState(false);
  const [inbLdRowData, setInbLdRowData] = useState([]);
  const [analyticLost, setAnalyticLost] = useState(false);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [lostDealRowData, setLostDealRowData] = useState([]);
  const [width, setWidth] = useState("100%");
  const [imgError, setImgError] = useState(false);
  const [isTransferOwner, setIsTransferOwner] = useState(false);
  const [isContributor, setIsContributor] = useState(false);
  const [isProspectAllowEditContact, setIsProspectAllowEditContact] = useState(true);
  const [isOrganizationAllowEditContact, setIsOrganizationAllowEditContact] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [isLoadingEditOrganization, setIsLoadingEditOrganization] = useState(false);
  const [isLoadingAddOrganization, setIsLoadingAddOrganization] = useState(false);
  const [isEditOrganization, setIsEditOrganization] = useState(false);
  const [isFindOrganization, setIsFindOrganization] = useState(false);
  const [findOrganizationData, setFindOrganizationData] = useState([]);
  const [isAddOrganization, setIsAddOrganization] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [countryOrganizationList, setCountryOrganizationList] = useState([]);
  const [organizationData, setOrganizationData] = useState({});
  const transferOwnerRef = useRef(null);
  const [timelineFilter, setTimelineFilter] = useState("all");
  const [activityLoading, setActivityLoading] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);

  var contactPrefList = [
    { label: t("ProspectDetail_contactPrefList_label_1"), value: "1" },
    { label: t("ProspectDetail_contactPrefList_label_2"), value: "0" },
  ];

  const noteSchema = yup.object().shape({
    prosNotes: yup.string().required(t("ProspectDetail_required_field")),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("ProspectDetail_required_field")),
    activity_type: yup.string().required(t("ProspectDetail_required_field")),
    activity_user: yup.string().required(t("ProspectDetail_required_field")),
  });

  const editNoteSchema = yup.object().shape({
    activity_note: yup.string().required(t("ProspectDetail_required_field")),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("ProspectDetail_required_field")),
    activity_type: yup.string().required(t("ProspectDetail_required_field")),
    activity_user: yup.string().required(t("ProspectDetail_required_field")),
  });

  const addDealSchema = yup.object().shape({
    pipe_id: yup.string().required("Pipeline is required"),
    deal_title: yup.string().required("Deal title is required"),
    ds_id: yup.string().required("Deal source is required"),
  });

  const editProspectContactSchema = yup.object().shape({
    contact_prefix: yup.string().test("contact_prefix", "Prefix is required", async function (value) {
      const { contact } = this.parent || {};
      try {
        if (contact && !value) {
          return this.createError({ message: "Prefix is required" });
        }
        return true;
      } catch (error) {
        return false;
      }
    }),
    contact: yup.string().test("contact", "Contact number format already exist", async function (value) {
      const { index, contact_prefix, contact_prefix_region } = this.parent || {};
      try {
        if (value && !contact_prefix) {
          return this.createError({ message: "Invalid contact number" });
        }

        if (value && value !== prosDetData[`contact${index}`] && contact_prefix_region) {
          const phoneNumber = parsePhoneNumberFromString(value, contact_prefix_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid contact number" });
          }
        }

        if (value && value !== prosDetData[`contact${index}`]) {
          const result = await debounceDuplicateContact(value, index);
          if (result === false) {
            return this.createError({ message: "Contact number already exist" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
  });

  const editProspectEmailSchema = yup.object().shape({
    email: yup.string().email("Invalid email address"),
  });

  const editProspectSchema = yup.object().shape({
    prospect_name: yup.string().required(t("DealDetails_error_message")),
    // owner: yup.object().shape({ value: yup.string().required(t("DealDetails_error_message")) }),
    contact: yup.array().of(editProspectContactSchema),
    email: yup.array().of(editProspectEmailSchema),
  });

  const addContrSchema = yup.object().shape({
    contr_user_id: yup.string().required("Please Choose user"),
  });

  const transferProspectSchema = yup.object().shape({
    user: yup.string().required("User is required"),
  });

  const editOrganizationSchema = yup.object().shape({
    organization_title: yup.string().required(t("DealDetails_error_message")),
  });

  // GET FUNCTION ----------------------------------------------------------------

  const getprospectDet = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_prospect_view.php", {
        params: {
          task: "viewProspectDetailsV2",
          prospect: prospect_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        if (Number(session.company_type_id) !== 2) {
          if (data.record.organization_id && Number(data.record.organization_id) > 0) {
            await getOrganization(user_info, data.record.organization_id);
          }
        }

        setProsDetData(data.record);
        setInitial(data.record.prospect_name.split("")[0]);

        const contributor =
          Number(data.record.contributor_count) > 0 && data.record.contributor.find((record) => record.user_id === data.record.owner_id);
        setIsContributor(Number(data.record.contributor_count) > 0 && !contributor);
        if (Number(session.contributor_no_edit_contact) === 1) {
          if (Number(data.record.prospect_owner) !== Number(user_info.user_id)) {
            setIsProspectAllowEditContact(false);
          }

          if (Number(data.record.organization_owner) !== Number(user_info.user_id)) {
            setIsOrganizationAllowEditContact(false);
          }
        }

        setIsOwner(true);

        var contriID = [];

        if (data.record.contributor_count > 0) {
          data.record.contributor.map((data) => {
            contriID.push(data.user_id);
          });
          setSelectedContr(contriID);
        } else {
          setSelectedContr([]);
        }
        setIsLoading(false);
        getEdm(data.record, user_info);
      } else {
        setProsDetData([]);
        setInitial("");
        setSelectedContr([]);
        setIsLoading(false);
        getEdm("", user_info);
        logoutUser();
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    }
  };

  const getEditProspectV2 = async () => {
    const contacts = [];
    const emails = [];
    let customFields = [];

    const findContactPrefix = (contact) => {
      contact = contact.replace(/\s+/g, "");
      contact = contact.replace("+", "");

      var prefix2 = contact.substring(0, 2);
      var prefix3 = contact.substring(0, 3);
      var prefix4 = contact.substring(0, 4);

      const findPrefix2 = MobilePrefix.find((prefix) => prefix.value === prefix2);
      const findPrefix3 = MobilePrefix.find((prefix) => prefix.value === prefix3);
      const findPrefix4 = MobilePrefix.find((prefix) => prefix.value === prefix4);

      if (findPrefix2) {
        return findPrefix2;
      } else if (findPrefix3) {
        return findPrefix3;
      } else if (findPrefix4) {
        return findPrefix4;
      }

      return { label: "", value: "", regionCode: "" };
    };

    const contactNumbers = [prosDetData.contact1, prosDetData.contact2, prosDetData.contact3];
    const contactCountryCode = [prosDetData.contact1_prefix, prosDetData.contact2_prefix, prosDetData.contact3_prefix];
    contactNumbers.forEach((contact, index) => {
      if (index === 0) {
        const contactPrefix = contactCountryCode[0]
          ? MobilePrefix.find((record) => record.value === contactCountryCode[0])
          : findContactPrefix(contact);
        contacts.push({
          contact_prefix: contactPrefix.value,
          contact_prefix_label: contactPrefix.label,
          contact_prefix_region: contactPrefix.regionCode,
          contact: contact
            ? contact
            : Number(prosDetData.contact_count) > 0 && prosDetData.contacts[0]?.contact_num
            ? prosDetData.contacts[0].contact_num
            : "",
          main: true,
          index: 1,
        });
      } else {
        if (contact) {
          const contactPrefix = contactCountryCode[index]
            ? MobilePrefix.find((record) => record.value === contactCountryCode[index])
            : findContactPrefix(contact);
          contacts.push({
            contact_prefix: contactPrefix.value,
            contact_prefix_label: contactPrefix.label,
            contact_prefix_region: contactPrefix.regionCode,
            contact: contact,
            main: false,
            index: index + 1,
          });
        }
      }
    });

    const emailAddress = [prosDetData.email1, prosDetData.email2];
    emailAddress.forEach((email, index) => {
      if (index === 0) {
        emails.push({
          email: email ? email : Number(prosDetData.email_count) > 0 && prosDetData.emails[0]?.email_title ? prosDetData.emails[0].email_title : "",
        });
      } else {
        if (email) {
          emails.push({ email: email });
        }
      }
    });

    if (Number(prosDetData.custom_field) > 0 && prosDetData.custom_field_data.filter((data) => data.cf_view_detail === "yes").length > 0) {
      customFields = prosDetData.custom_field_data
        .filter((data) => data.cf_view_detail === "yes")
        .map((record) => ({
          cf_id: record.cf_id,
          name: record.cf_id,
          fd_values: record.ctf_title === "Checkbox" ? (record.fd_value ? record.fd_value.split(",") : []) : record.fd_value,
          cf_label: record.cf_label,
        }));
    }

    setInitialVal({
      prospect_id: Number(prosDetData.hq_prospect_id) > 0 ? prosDetData.hq_prospect_id : prosDetData.prospect_id,
      deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
      prospect_name: prosDetData.prospect_name,
      designation: { label: prosDetData.designation_title, value: prosDetData.designation_id },
      organization: { label: prosDetData.organization_title, value: prosDetData.organization_id },
      owner: { label: prosDetData.owner_name, value: prosDetData.owner_id },
      contact: contacts,
      email: emails,
      address1: prosDetData.address_line1 ? prosDetData.address_line1 : prosDetData.prospect_address,
      address2: prosDetData.address_line2,
      address3: prosDetData.address_line3,
      postcode: prosDetData.postal_code,
      country: Number(prosDetData.country_id) > 0 ? countryData.find((record) => Number(record.value) === Number(prosDetData.country_id)) : "",
      state: Number(prosDetData.state_id) > 0 ? stateData.find((record) => Number(record.value) === Number(prosDetData.state_id)) : "",
      city: Number(prosDetData.city_id) > 0 ? cityData.find((record) => Number(record.value) === Number(prosDetData.city_id)) : "",
      cf_person: customFields,
      state_show: true,
      city_show: true,
    });

    setTimeout(() => {
      setIsEditProspect(true);
    }, 100);
  };

  const getProsAnalytic = async (user_info) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "21",
          prospect: prospect_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        setAnalyticData(data);

        if (Number(data.total_open) > 0) {
          setIsOpenDeal(true);
          setOpenDealRowData(data.record_open);
        } else {
          setIsOpenDeal(false);
          setOpenDealRowData([]);
        }

        if (Number(data.total_won) > 0) {
          setIsWonDeal(true);
          setWonDealRowData(data.record_won);
        } else {
          setIsWonDeal(false);
          setWonDealRowData([]);
        }

        if (Number(data.total_inbound) > 0) {
          setIsInbLd(true);
          setInbLdRowData(data.record_inbound);
        } else {
          setIsInbLd(false);
          setInbLdRowData([]);
        }

        if (Number(data.total_lost) > 0) {
          setIsLostDeal(true);
          setLostDealRowData(data.record_lost);
        } else {
          setIsLostDeal(false);
          setLostDealRowData([]);
        }
      })
      .catch((error) => {
        setIsOpenDeal(false);
        setOpenDealRowData([]);
        setIsWonDeal(false);
        setWonDealRowData([]);
        setIsInbLd(false);
        setInbLdRowData([]);
      });
  };

  const getAllUSer = async (user_info) => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var userData = data.record.map((data) => {
            return { label: data.user_name, value: data.user_id, role_id: data.role_id };
          });

          setUserList(userData);
        } else {
          setUserList([]);
        }
      });
  };

  const getAllPipeline = async (user_info) => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: user_info.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var pipeData = data.record.map((data) => {
            return { label: data.pipeline_title, value: data.pipeline_id };
          });

          setPipeData(pipeData);
        } else {
          setPipeData([]);
        }
      });
  };

  const getAllSource = async (user_info) => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: "getAllSource_show_mobile",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var sourceData = data.record.map((data) => {
            return { label: data.ds_title, value: data.ds_id };
          });

          setDsData(sourceData);
        } else {
          setDsData([]);
        }
      });
  };

  const getCountry = async (user_info) => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.country_title,
          value: record.country_id,
        }));
        setCountryData(options);
      });
  };

  const getState = async (user_info) => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.state_title,
          value: record.state_id,
          country: record.country_id,
        }));
        setStateData(options);
      });
  };

  const getCity = async (user_info) => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.city_title,
          value: record.city_id,
          state: record.state_id,
        }));
        setCityData(options);
      });
  };

  const getAddDealCF = async (pipeID) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "getCFForAddDeal",
          area: "",
          cfpipe: pipeID,
          company: prospectSession.company_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          v_add: "yes",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record.filter((record) => record.cf_area === "deal");
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProsTimeline = async (user_info) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectTimeline",
          prospect: prospect_id,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((res) => {
        let data = res.data;
        if (data.record) {
          setTimelineData(data.record);
        } else {
          setTimelineData([]);
        }
      });
  };

  const getDefCsID = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: prospectSession.company_id,
          pipeline_id: pipelineId,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record[0].cs_id;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getContactPref = () => {
    axios
      .get("ws/ws_contact_preferences.php", {
        params: {
          task: "2",
          prospect_id: prospect_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setContactPrefData(data.record[0]);
        } else {
          setContactPrefData({});
        }
      });
  };

  const getProspectDeals = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDeals",
          prospect: prospect_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.total_open) > 0) {
          setIsProsDeal(true);
          setProsDealRowData(data.record_open);
        } else {
          setIsProsDeal(false);
          setProsDealRowData([]);
        }
      })
      .catch((error) => {
        setIsProsDeal(false);
        setProsDealRowData([]);
      });
  };

  const getEdm = (data, user_info) => {
    var email = "";

    if (data) {
      if (data.email1 !== "") {
        email = data.email1;
      } else if (data.email2 !== "") {
        email = data.email2;
      } else if (data.email3 !== "") {
        email = data.email3;
      }
    }

    axios
      .get("ext/glRedemption/api_edm.php", {
        params: {
          task: "getProspectEDM",
          email: email,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setIsEdm(true);
          setEdmRowData(data.record);
        } else {
          setIsEdm(false);
          setEdmRowData([]);
        }
      })
      .catch((error) => {
        setIsEdm(false);
        setEdmRowData([]);
      });
  };

  const getAuditHistory = async () => {
    try {
      const response = await axios.get("ws/ws_auditHistory.php", {
        params: {
          task: "view",
          prospect_id: prospect_id,
          hq_prospect_id: 0,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      const possible_field = {
        prospect_name: "Prospect Name",
        contact1: "Contact 1",
        contact2: "Contact 2",
        contact3: "Contact 3",
        contact4: "Contact 4",
        contact1_prefix: "Contact 1 Prefix",
        contact2_prefix: "Contact 2 Prefix",
        contact3_prefix: "Contact 3 Prefix",
        contact4_prefix: "Contact 4 Prefix",
        email1: "Email 1",
        email2: "Email 2",
        email3: "Email 3",
        address_line1: "Address 1",
        address_line2: "Address 2",
        address_line3: "Address 3",
        address_line4: "Address 4",
        city_title: "City",
        city_id: "City",
        state_title: "State",
        state_id: "State",
        country_title: "Country",
        country_id: "Country",
        postal_code: "Postal Code",
        industry_title: "Industry",
        organization_title: "Organization",
        organization_id: "Organization",
        designation_id: "Designation",
        designation_title: "Designation",
        ds_id: "Source",
        ds_title: "Source",
        user_name: "User",
      };

      if (data.record && data.record.filter((record) => record.log_old_data[0] && record.log_new_data[0]).length > 0) {
        setIsAuHis(true);

        const audit_data = data.record.filter((record) => record.log_old_data[0] && record.log_new_data[0]);
        const audit_record = audit_data.map((record) => {
          record.field = [];
          if (
            (record.log_old_data[0]?.cf_id && record.log_old_data[0].cf_id.length > 0) ||
            (record.log_new_data[0]?.cf_id && record.log_new_data[0].cf_id.length > 0)
          ) {
            if (record.log_old_data[0] && record.log_old_data[0]?.label.length > 0) {
              record.log_old_data[0]?.label.forEach((_, i) => {
                if (record.log_old_data[0]?.value[i] !== record.log_new_data[0]?.value[i]) {
                  record.field.push({
                    label: record.log_old_data[0]?.label[i].replaceAll("_", " "),
                    value: record.log_old_data[0]?.value[i].trim() ? record.log_old_data[0]?.value[i] : "No value",
                    new_value: record.log_new_data[0]?.value[i].trim() ? record.log_new_data[0]?.value[i] : "No value",
                  });
                }
              });
            } else if (record.log_new_data[0] && record.log_new_data[0]?.label.length > 0) {
              record.log_new_data[0]?.label.forEach((_, i) => {
                if (record.log_old_data[0]?.value[i] !== record.log_new_data[0]?.value[i]) {
                  record.field.push({
                    label: record.log_new_data[0]?.label[i].replaceAll("_", " "),
                    value: record.log_old_data[0]?.value[i].trim() ? record.log_old_data[0]?.value[i] : "No value",
                    new_value: record.log_new_data[0]?.value[i].trim() ? record.log_new_data[0]?.value[i] : "No value",
                  });
                }
              });
            }
          } else {
            if (record.log_old_data[0] && record.log_old_data[0]?.label.length > 0) {
              record.log_old_data[0]?.label.forEach((_, i) => {
                if (record.log_old_data[0]?.value[i] !== record.log_new_data[0]?.value[i]) {
                  record.field.push({
                    label: possible_field[record.log_old_data[0]?.label[i]] || record.log_old_data[0]?.label[i],
                    value: record.log_old_data[0]?.value[i].trim() ? record.log_old_data[0]?.value[i] : "No value",
                    new_value: record.log_new_data[0]?.value[i].trim() ? record.log_new_data[0]?.value[i] : "No value",
                  });
                }
              });
            } else if (record.log_new_data[0] && record.log_new_data[0]?.label.length > 0) {
              record.log_new_data[0]?.label.forEach((_, i) => {
                if (record.log_old_data[0]?.value[i] !== record.log_new_data[0]?.value[i]) {
                  record.field.push({
                    label: possible_field[record.log_new_data[0]?.label[i]] || record.log_new_data[0]?.label[i],
                    value: record.log_old_data[0]?.value[i].trim() ? record.log_old_data[0]?.value[i] : "No value",
                    new_value: record.log_new_data[0]?.value[i].trim() ? record.log_new_data[0]?.value[i] : "No value",
                  });
                }
              });
            }
          }

          if (record.field.length > 0) return record;
        });

        setAuHisRowData(audit_record);
      } else {
        setIsAuHis(false);
        setAuHisRowData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProspectLinkedUser = async () => {
    try {
      const response = await axios.get("ws/ws_linked_user.php", {
        params: {
          task: "GetProspectLinkedUser",
          prospect: prospect_id,
          user_email: session.user_email,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setProspectSession(data.record);
        await getprospectDet(data.record);
        await getProsAnalytic(data.record);
        await getAllUSer(data.record);
        await getAllPipeline(data.record);
        await getAllSource(data.record);
        await getCountry(data.record);
        await getState(data.record);
        await getCity(data.record);
        await getProsTimeline(data.record);
        await getIndustry(data.record);
        await getOrganizationCountry(data.record);
      } else {
        await getprospectDet(session);
        await getProsAnalytic(session);
        await getAllUSer(session);
        await getAllPipeline(session);
        await getAllSource(session);
        await getCountry(session);
        await getState(session);
        await getCity(session);
        await getProsTimeline(session);
        await getIndustry(session);
        await getOrganizationCountry(session);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      init.current = true;
    }
  };

  const getDuplicateContact = async (contact, index) => {
    let isDuplicateExists;

    const contactFields = [`contact1`, `contact2`, `contact3`, `contact4`];
    const fieldToCheck = contactFields[index - 1];

    if (index >= 1 && index <= 3 && prosDetData[fieldToCheck] === contact) {
      return true;
    }

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          task: "13",
          company: session.company_id,
          // people: deal.prospect_id,
          user: session.user_id,
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) === 0;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const debounceDuplicateContact = debounce(getDuplicateContact, 1000);

  const getExistingDesignation = (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: prospectSession.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getExistOrg = (inputValue, callback) => {
    setIsFindOrg(false);
    axios
      .get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: prospectSession.company_id,
          q: inputValue,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (Number(data.status) === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.organization_id) > 0;
            })
            .map((record) => ({
              label: record.organization_title,
              value: record.organization_id,
              customLabel: "",
              organization_address: record.organization_address ? record.organization_address : "",
              organization_web_url: record.organization_web_url ? record.organization_web_url : "",
              organization_contact: record.organization_contact ? record.organization_contact : "",
              organization_country_id: record.organization_country_id ? record.organization_country_id : "",
              in_id: record.in_id ? record.in_id : "",
            }));

          var newOptions = data.record.find((record) => Number(record.organization_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              organization_address: "",
              organization_web_url: "",
              organization_contact: "",
              organization_country_id: "",
              in_id: "",
            });
          }

          callback(options);
          setIsFindOrg(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindOrg(true);
        }
      });
  };

  const getOrganizationData = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_gplaces.php", {
        params: {
          task: "getPlaces",
          placeTitle: encodeURIComponent(prosDetData.organization_title),
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        setFindOrganizationData(data.record);
        setIsFindOrganization(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: "We couldn't find any organization with that name. Please try again.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIndustry = async (user_info) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "getOrganizations",
          company_id: user_info.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.in_title,
          value: record.in_id,
        }));

        setIndustryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationCountry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 10,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.country_nicename,
          value: record.country_id,
        }));
        setCountryOrganizationList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganization = async (user_info, organization) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "12",
          organization: organization,
          utoken: user_info.user_ac_token,
          ctoken: user_info.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setOrganizationData(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ------------------------------------------------------------

  const onchangeActionTabs = (key) => {
    setActiveTab(key);
    setIsActiveTab(false);
  };

  // LIGHTBOX FUNCTION -------------------------------------------------------------

  const openLightbox = (src) => {
    setLightboxOpen(true);
    setLightboxImages([src]);
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderActionTab = useCallback(
    ({ tab, active, userListNotes, activityUser, activityLoading }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={noteSchema}
                onSubmit={(values) => {
                  setActivityLoading(true);
                  sendProsNote(values);
                }}
                initialValues={{
                  prosNotes: "",
                  user: prospectSession.user_id,
                }}
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <NoteEditor
                          value={values.prosNotes}
                          onChangeValue={(value) => {
                            handleChange("prosNotes")(value);
                          }}
                        />
                        {errors.prosNotes && touched.prosNotes && <div className="op-error-message">{errors.prosNotes}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={activityLoading}>
                        {activityLoading ? <Spinner animation="border" size="sm" /> : t("ProspectDetail_cardFooter_btn_submit")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setActivityLoading(false);
                  setIsActiveTab(true);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("ProspectDetail_click_take_note")}
              </Card.Body>
            );
          }
        case "activity":
          if (active) {
            return (
              <>
                <Formik
                  validationSchema={addActivitySchema}
                  onSubmit={(values) => {
                    setActivityLoading(true);
                    sendAddActivity(values);
                  }}
                  initialValues={{
                    deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                    activity_title: "",
                    activity_type: "1",
                    activity_date: moment(new Date()).format("YYYY-MM-DD"),
                    activity_time: setLatestTime(timings).value,
                    activity_dur: "",
                    activity_user: prospectSession.user_id,
                    activity_notes: "",
                    activity_mad: "no",
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder={t("ProspectDetail_activity_activity_title_placeholder") + " *"}
                                isInvalid={errors.activity_title && touched.activity_title}
                                onChange={handleChange("activity_title")}
                              />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <ButtonGroup className="activity-tab">
                                <ToggleButton
                                  variant=""
                                  type="radio"
                                  name="activity_type"
                                  id="activity_type_1"
                                  value="1"
                                  checked={values.activity_type === "1"}
                                  onChange={() => handleChange("activity_type")("1")}
                                >
                                  <FontAwesomeIcon icon={faPhoneVolume} className="me-2" />
                                  {t("ProspectDetail_activity_toggleButton_1")}
                                </ToggleButton>
                                <ToggleButton
                                  variant=""
                                  type="radio"
                                  name="activity_type"
                                  id="activity_type_2"
                                  value="2"
                                  checked={values.activity_type === "2"}
                                  onChange={() => handleChange("activity_type")("2")}
                                >
                                  <FontAwesomeIcon icon={faUsers} className="me-2" />
                                  {t("ProspectDetail_activity_toggleButton_2")}
                                </ToggleButton>
                                <ToggleButton
                                  variant=""
                                  type="radio"
                                  name="activity_type"
                                  id="activity_type_3"
                                  value="3"
                                  checked={values.activity_type === "3"}
                                  onChange={() => handleChange("activity_type")("3")}
                                >
                                  <FontAwesomeIcon icon={faStopwatch} className="me-2" />
                                  {t("ProspectDetail_activity_toggleButton_3")}
                                </ToggleButton>
                                <ToggleButton
                                  variant=""
                                  type="radio"
                                  name="activity_type"
                                  id="activity_type_4"
                                  value="4"
                                  checked={values.activity_type === "4"}
                                  onChange={() => handleChange("activity_type")("4")}
                                >
                                  <FontAwesomeIcon icon={faFlag} className="me-2" />
                                  {t("ProspectDetail_activity_toggleButton_4")}
                                </ToggleButton>
                                <ToggleButton
                                  variant=""
                                  type="radio"
                                  name="activity_type"
                                  id="activity_type_5"
                                  value="5"
                                  checked={values.activity_type === "5"}
                                  onChange={() => handleChange("activity_type")("5")}
                                >
                                  <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-2" />
                                  {t("ProspectDetail_activity_toggleButton_5")}
                                </ToggleButton>
                              </ButtonGroup>
                              {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Stack direction="horizontal" gap={1}>
                              <Row style={{ width: "100%" }}>
                                <Col sm={4}>
                                  <Form.Group>
                                    <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect
                                      placeholder={t("ProspectDetail_activity_time_placeholder")}
                                      options={timings}
                                      valueDefault={timings.filter((option) => option.value === values.activity_time)}
                                      onChange={(e) => handleChange("activity_time")(e.value)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect
                                      placeholder={t("ProspectDetail_activity_duration_placeholder")}
                                      options={durationList}
                                      onChange={(e) => handleChange("activity_dur")(e.value)}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Stack>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>

                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelect
                                placeholder={t("ProspectDetail_activity_user_placeholder") + " *"}
                                options={activityUser}
                                isDisabled={values.deal_linked}
                                valueDefault={activityUser.filter((option) => Number(option.value) === Number(values.activity_user))}
                                isInvalid={errors.activity_user && touched.activity_user}
                                isSearchable={true}
                                onChange={(e) => {
                                  handleChange("activity_user")(e ? e.value : "");
                                }}
                              />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <NoteEditor
                                value={values.activity_notes}
                                onChangeValue={(value) => {
                                  handleChange("activity_notes")(value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        <button type="submit" className="btn op-button op-primary-color text-light" disabled={activityLoading}>
                          {activityLoading ? <Spinner animation="border" size="sm" /> : t("ProspectDetail_cardFooter_btn_submit")}
                        </button>
                        <Form.Check
                          type="switch"
                          label={t("ProspectDetail_activity_mad_label")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              handleChange("activity_mad")("yes");
                            } else {
                              handleChange("activity_mad")("no");
                            }
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              </>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setActivityLoading(false);
                  setIsActiveTab(true);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_add_an_activity")}
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab]
  );

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note" || record.record_type === "Note_Prospect") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else if (record.record_type === "ProspectTransfer") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Deal") {
      return <FontAwesomeIcon icon={faDollarSign} />;
    } else {
      return <FontAwesomeIcon icon={faTimelineArrow} />;
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `${t("DealDetails_deal_transfer_to")} ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return (
          <RenderFile
            url={`${session.hostUrl}/${session.hostUrlType}/${record.record_file_url}`}
            title={`${record.record_title}.${record.record_file_ext}`}
          />
        );
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== ",") {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" className="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note":
      case "Note_Prospect":
        element = record.record_title;
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div style={{ whiteSpace: "pre-wrap", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
      case "Email":
        element = record.record_title;
        break;
      case "ProspectTransfer":
        return (
          <div style={{ overflow: "auto" }}>
            <div>{record.record_description}</div>
          </div>
        );
      case "Deal":
        element = `${record.deal_title} - ${t("ProspectDetail_accordian_timeline_header_deal")}`;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
        break;
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const RenderTimelineDescription = useCallback(({ record }) => {
    const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;
    if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
      if (record_activity_type === "Call" && record_file_url) {
        return (
          <div style={{ padding: "10px 0" }}>
            <RenderAudio url={record_file_url} />
          </div>
        );
      } else if (record_activity_type === "Call" && record_file_url === "") {
        return "";
      } else {
        return (
          <Stack direction="vertical">
            <div
              className="overflow-auto positon-relative w-100"
              style={{ whiteSpace: "pre-wrap", maxHeight: 500 }}
              dangerouslySetInnerHTML={{ __html: record_description }}
            />
          </Stack>
        );
      }
    } else if (record_type === "Archieve") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
    } else if (record_type === "Email") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
    } else {
      return "";
    }
  }, []);

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const RenderTimelineAction = ({ record }) => {
    const customToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown}>
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={customToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                editActivityModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteActivity(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note") && (
            <Dropdown.Item
              onClick={() => {
                editNoteModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_note")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note") && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteNote(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_note")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const RenderOtherTab = ({ tab }) => {
    switch (tab) {
      case "deal":
        return (
          <div className="section-content w-100 px-3">
            <Container fluid>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={prosDealGridRef}
                    columnDefs={prosDealColumn}
                    rowData={prosDealRowData}
                    defaultColDef={reportColDef}
                    rowHeight={70}
                    pagination={true}
                    paginationPageSize={30}
                    paginationPageSizeSelector={false}
                  />
                </div>
              </div>
              {!isProsDeal && (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No deals added yet
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    You can create a new deal that could lead to exciting opportunities!{" "}
                    <a className="text-decoration-none cursor-pointer" onClick={setIsAddDeal}>
                      Create new deal
                    </a>
                  </div>
                </div>
              )}
            </Container>
          </div>
        );

      case "edm":
        return (
          <div className="section-content w-100 px-3">
            <Container fluid>
              <div style={containerStyleEdm}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleEdm }}>
                  <AgGridReact
                    ref={edmGridRef}
                    columnDefs={edmColumn}
                    rowData={edmRowData}
                    defaultColDef={reportColDef}
                    rowHeight={70}
                    pagination={true}
                    paginationPageSize={30}
                    paginationPageSizeSelector={false}
                  />
                </div>
              </div>
              {!isEdm && (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 396.6L16 231.6 16 200l48-36 0 61.7 16 11L80 80l352 0 0 156.7 16-11 0-61.7 48 36 0 31.6-240 165zM64 80l0 64L0 192l0 48L0 496l0 16 16 0 480 0 16 0 0-16 0-256 0-48-64-48 0-64 0-16-16 0-90.7 0L256 0 170.7 64 80 64 64 64l0 16zM256 20l58.7 44L197.3 64 256 20zm0 396L496 251l0 245L16 496l0-245L256 416zM168 160l-8 0 0 16 8 0 176 0 8 0 0-16-8 0-176 0zm0 80l-8 0 0 16 8 0 176 0 8 0 0-16-8 0-176 0z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No records found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    <p>It seems there are currently no records of EDM available yet.</p>
                  </div>
                </div>
              )}
            </Container>
          </div>
        );

      case "contactPref":
        return (
          <div className="section-content w-100 position-relative" style={{ height: "calc(100vh - 300px)" }}>
            <Container>
              <Row className="mt-5 justify-content-md-center">
                <Col sm={9}>
                  <Card className="shadow-sm border-0 rounded">
                    <Card.Header className="rounded border-0">
                      <div style={{ padding: "10px 20px" }}>
                        <Stack direction="horizontal" gap={1}>
                          <h6 className="mt-2 fw-bold">{t("ProspectDetail_contactPref_header")} Details</h6>
                          <button type="button" className="btn btn-light border ms-auto" onClick={setIsEditContactPref}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </Stack>
                      </div>
                    </Card.Header>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-3 justify-content-md-center">
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_1")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.email === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_2")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.email_bulk === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelopesBulk} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_3")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.phone === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faPhone} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-md-center">
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_4")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.fax === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faFax} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_5")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.mail === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelopeOpenText} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_6")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.whatsapp === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faWhatsapp} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-md-center">
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_7")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.pdpa === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faShieldCheck} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );

      case "auHis":
        return (
          <div className="section-content w-100 px-3">
            <Container fluid>
              <div style={containerStyleAuHis}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleAuHis }}>
                  <AgGridReact
                    ref={auHisGridRef}
                    columnDefs={auHisColumn}
                    rowData={auHisRowData}
                    defaultColDef={reportColDef}
                    rowHeight={70}
                    autoHeight={true}
                    pagination={true}
                    paginationPageSize={30}
                    paginationPageSizeSelector={false}
                  />
                </div>
              </div>
            </Container>
          </div>
        );

      default:
        return null;
    }
  };

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }}
        className={`${className} animate slideIn`}
        aria-labelledby={labeledBy}
      >
        <Card>
          <Formik
            validationSchema={transferProspectSchema}
            onSubmit={sendTransferProspect}
            initialValues={{
              user: "",
              role_id: "",
              transferOption: 2,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {t("DealDetails_transfer_ownership")}
                  <Button variant="" as={CloseButton} onClick={() => setIsTransferOwner(false)} />
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <FormSelect
                      name="user"
                      value={values.user}
                      isInvalid={errors.user && touched.user}
                      onChange={(info) => {
                        handleChange("user")(info.value);
                        handleChange("role_id")(info.role_id);
                      }}
                      options={userList}
                      placeholder={t("DealDetails_select_user")}
                    />
                    {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t("DealDetails_save")}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderProspectAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <Button ref={ref} variant="light" className="shadow-sm border" onClick={handleClick} aria-label="Toggle dropdown">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={getEditProspectV2}>Edit Prospect</Dropdown.Item>
          {Number(prospectSession.role_id) === 1 && <Dropdown.Item onClick={sendDeleteProspectConfirmation}>Delete Prospect</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderOrganizationAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <button
          ref={ref}
          variant="light"
          className="btn btn-light border"
          onClick={handleClick}
          aria-label="Toggle dropdown"
          style={{ borderLeft: "none", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={sendDeleteOrganizationConfirmation}>Unlink this organization</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // SEND FUNCTION ----------------------------------------------------------------

  const sendProsNote = async (values) => {
    const noteData = new FormData();

    noteData.append("task", "addProspectOrgNotes");
    noteData.append("user", values.user);
    noteData.append("content", values.prosNotes);
    noteData.append("prospectid", prospect_id);
    noteData.append("type", "prospect");
    noteData.append("utoken", prospectSession.user_ac_token);
    noteData.append("ctoken", prospectSession.company_token);

    try {
      const res = await axios.post("ws/ws_post_note.php", noteData);

      let data = res.data;

      if (data) {
        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_add_note_text"),
          timer: 1500,
        }).then((result) => {
          setIsActiveTab(false);
          getProsTimeline(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    } finally {
      setActivityLoading(false);
    }
  };

  const sendAddActivity = async (values) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 2,
          create_user: prospectSession.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_dur,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.activity_mad,
          assign_user: values.activity_user,
          dat: values.activity_type,
          deal: "",
          organization: "",
          people: prospect_id,
        },
      });

      let data = response.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_add_activity_text"),
          timer: 1500,
        }).then((result) => {
          setIsActiveTab(false);
          getProsTimeline(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setActivityLoading(false);
    }
  };

  const sendMarkAsDone = (record) => {
    if (record.record_activity_type_id === 6 || record.record_activity_type_id === 7) {
      Swal.fire({
        icon: "error",
        title: t("ProspectDetail_alert_error_mad_title"),
        text: t("ProspectDetail_alert_error_mad_text"),
      });
    } else {
      var mark = record.record_activity_mad === "yes" ? "no" : "yes";

      axios
        .get("ws/ws_deal.php", {
          params: {
            task: "16",
            activity: record.record_activity_id,
            status: mark,
            user: prospectSession.user_id,
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status === 0) {
            setTimelineData((prev) => {
              return prev.map((info) => {
                if (info.record_activity_id === record.record_activity_id) {
                  return {
                    ...info,
                    record_activity_mad: mark,
                  };
                }
                return info;
              });
            });
          } else {
            Swal.fire({
              icon: "error",
              title: t("ProspectDetail_alert_failed_title"),
              text: t("ProspectDetail_alert_failed_title"),
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
          });
        });
    }
  };

  const sendEditActivity = async (values) => {
    try {
      const res = await axios.get("ws/ws_deal.php", {
        params: {
          task: 11,
          user: prospectSession.user_id,
          dt: values.activity_date,
        },
      });

      let data = res.data;

      if (data.status === 0) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: 12,
              create_user: prospectSession.user_id,
              dated: values.activity_date,
              timed: values.activity_time,
              duration: values.activity_dur,
              title: values.activity_title,
              content: values.activity_note,
              mad: values.activity_mad,
              dat: values.activity_type,
              ca_id: 0,
              assign_user: values.activity_user,
              aid: values.activity_id,
            },
          })
          .then((response) => {
            let rec = response.data;

            if (rec.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_edit_activity_text"),
                timer: 1500,
              }).then((result) => {
                setIsEditActivity(false);
                getProsTimeline(prospectSession);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendEditNote = async (values) => {
    var editNoteData = new FormData();

    if (values.activity_type === "prospect") {
      editNoteData.append("task", "editProspectOrgNotes");
      editNoteData.append("source", values.activity_type);
      editNoteData.append("user", prospectSession.user_id);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("note", values.activity_id);
      editNoteData.append("utoken", prospectSession.user_ac_token);
      editNoteData.append("ctoken", prospectSession.company_token);
    } else {
      editNoteData.append("task", "editNotes");
      editNoteData.append("note", values.activity_id);
      editNoteData.append("deal", values.activity_dealid);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("utoken", prospectSession.user_ac_token);
      editNoteData.append("ctoken", prospectSession.company_token);
    }

    try {
      const res = await axios.post("ws/ws_post_note.php", editNoteData);

      let data = res.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_edit_note_text"),
          timer: 1500,
        }).then((result) => {
          setIsEditNote(false);
          getProsTimeline(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendDeleteActivity = (rec) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_1"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: 23,
              activity: rec.record_activity_id,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_activity_text"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline(prospectSession);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const sendDeleteNote = (rec) => {
    var paramsData;

    if (rec.record_type === "Note") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        prospectNote: 0,
        utoken: prospectSession.user_ac_token,
        ctoken: prospectSession.company_token,
      };
    } else if (rec.record_type === "Note_Prospect") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        type: "prospect",
        utoken: prospectSession.user_ac_token,
        ctoken: prospectSession.company_token,
      };
    }

    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      text: t("ProspectDetail_alert_warning_text_1"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_1"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: paramsData,
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_note_text"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline(prospectSession);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const sendAddDeal = async (values) => {
    var customField = values.cf_deal;

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.ds_id,
          pipeid: values.pipe_id,
          close_date: values.deal_close_date,
          prospect: prosDetData.prospect_id,
          prospect_name: prosDetData.prospect_name,
          organization: prosDetData.organization_id,
          organization_name: prosDetData.organization_title,
          user: prospectSession.user_id,
          company: prospectSession.company_id,
          cs: values.cs_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          cfid_arr: "",
          cfval_arr: "",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          var cf_id = customField.map((record) => record.name);
          var cf_label = customField.map((record) => record.cf_label);
          var deal_id = Array(customField.length).fill(data.record);
          var fd_values = customField.map((record) => {
            if (Array.isArray(record.fd_values)) {
              var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
              return filteredValues.length > 0 ? filteredValues.join("|") : null;
            } else {
              return record.fd_values;
            }
          });
          await sendAddDealCF(values, cf_id, cf_label, deal_id, fd_values);
        }

        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_add_deal_text"),
          timer: 1500,
        }).then((result) => {
          setIsAddDeal(false);
          getprospectDet(prospectSession);
          getProsTimeline(prospectSession);
          getProsAnalytic(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendAddDealCF = async (values, cf_id, cf_label, deal_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCFV2",
          record: deal_id.join("[-0-]"),
          hq_prospect_id: "",
          cf_label: cf_label.join("[-0-]"),
          cf: cf_id.join("[-0-]"),
          value: fd_values.join("[-0-]"),
          user_id: prospectSession.user_id,
          company_id: prospectSession.company_id,
          secret_key: prospectSession.company_token,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspect = async (values) => {
    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var prospect_id = Array(customField.length).fill(values.prospect_id);

    var fd_values = customField.map((record) => {
      if (Array.isArray(record.fd_values)) {
        var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
        return filteredValues.length > 0 ? filteredValues.join("|") : null;
      } else {
        return record.fd_values;
      }
    });

    await sendEditProspectData(values);
    if (customField.length > 0) {
      await sendEditProspectCF(values, cf_id, cf_label, prospect_id, fd_values);
    }

    const response_prospect_extra = await sendEditProspectExtra(values);
    const status = response_prospect_extra ? 0 : 1;

    if (status === 0) {
      Swal.fire({
        icon: "success",
        title: t("ProspectDetail_alert_success_title"),
        text: t("ProspectDetail_alert_success_edit_deal_text"),
        timer: 1500,
      }).then((result) => {
        setIsEditProspect(false);
        getprospectDet(prospectSession);
      });
    } else {
      Swal.fire({
        icon: "error",
        title: t("ProspectDetail_alert_failed_title"),
        text: t("ProspectDetail_alert_failed_text"),
        timer: 1500,
      });
    }
  };

  const sendConnectProspectOrg = async (organization) => {
    if (organization.value && Number(organization.value) > 0) {
      return organization.value;
    }

    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "1a",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          title: organization.label,
          address: "",
          weburl: "",
          industry: "",
          country: "",
          contact: "",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record;
      } else if (Number(data.status) === 2) {
        const organization_id = data.error.replace("This name already existed in this company which belong to organization id ", "");
        return organization_id;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectData = async (values) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "2",
          des_title: values.designation.label,
          des: values.designation.value,
          organization: Number(session.company_type_id) !== 2 ? prosDetData.organization_id : "",
          prospect_address: "",
          prospect: values.prospect_id,
          name: values.prospect_name,
          owner_id: prosDetData.prospect_user_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectExtra = async (values) => {
    const contacts = values.contact.map((contact) => ({ contact: contact.contact, contactPrefix: contact.contact_prefix }));
    const contact1 = contacts[0] ? contacts[0] : { contact: "", contactPrefix: "" };
    const contact2 = contacts[1] ? contacts[1] : { contact: "", contactPrefix: "" };
    const contact3 = contacts[2] ? contacts[2] : { contact: "", contactPrefix: "" };

    const emails = values.email.map((email) => email.email);
    const email1 = emails[0] || "";
    const email2 = emails[1] || "";

    try {
      const response = await axios.get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: values.prospect_id,
          contact1_prefix: contact1.contactPrefix,
          contact1: contact1.contact,
          contact1_old: "",
          contact2_prefix: contact2.contactPrefix,
          contact2: contact2.contact,
          contact3_prefix: contact3.contactPrefix,
          contact3: contact3.contact,
          email1: email1,
          email2: email2,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.state && values.state?.value ? values.state.value : "",
          city_id: values.city && values.city?.value ? values.city.value : "",
          postcode: values.postcode,
          country_id: values.country && values.country?.value ? values.country.value : "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectCF = async (values, cf_id, cf_label, prospect_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCFV2",
          record: prospect_id.join("[-0-]"),
          hq_prospect_id: Number(prosDetData.hq_prospect_id) > 0 ? prosDetData.hq_prospect_id : "",
          cf_label: cf_label.join("[-0-]"),
          cf: cf_id.join("[-0-]"),
          value: fd_values.join("[-0-]"),
          user_id: prospectSession.user_id,
          company_id: prospectSession.company_id,
          secret_key: prospectSession.company_token,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendAddContr = (values) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "addProspectContributor",
          prospectid: prospect_id,
          userid: values.contr_user_id,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_add_contr"),
            timer: 1500,
          }).then((result) => {
            setIsAddContr(false);
            getprospectDet(prospectSession);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendEditContactPref = (values) => {
    //console.log(values);
    axios
      .get("ws/ws_contact_preferences.php", {
        params: {
          task: "1",
          prospect_id: prospect_id,
          email: values.email,
          email_bulk: values.email_bulk,
          phone: values.phone,
          fax: values.fax,
          mail: values.mail,
          whatsapp: values.whatsapp,
          pdpa: values.pdpa,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_edit_contactPref_text"),
            timer: 1500,
          }).then((result) => {
            setIsEditContactPref(false);
            getContactPref(prospectSession);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendTransferProspect = async (values) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "transferProspectOwnership",
          prospect: prosDetData.prospect_id,
          user: values.user,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Successfully transferred the ownership of the prospect",
          timer: 1500,
        }).then(() => {
          transferOwnerRef.current.click();
          getprospectDet(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to transfer the ownership of the prospect",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNewOrganization = async (values) => {
    setIsLoadingAddOrganization(true);
    if (values.organization_id && Number(values.organization_id) > 0) {
      await sendConnectOrganization(values.organization_id);
    } else {
      await sendCreateOrganization(values);
    }
  };

  const sendCreateOrganization = async (values) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "addOrgAndConnectProspect",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
          title: values.organization_title,
          address: values.organization_address ? values.organization_address : "",
          weburl: values.organization_web_url ? values.organization_web_url : "",
          industry: values.in_id ? values.in_id : "",
          contact: values.organization_contact ? values.organization_contact : "",
          country: values.organization_country_id ? values.organization_country_id : "",
          prospect: prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getprospectDet(prospectSession);
        Swal.fire({
          icon: "success",
          text: "Successfully created the organization",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Sorry, this organization name already exists!",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAddOrganization(false);
      setIsAddOrganization(false);
    }
  };

  const sendConnectOrganization = async (organization_id) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: prosDetData.designation_title,
          des: prosDetData.designation_id,
          name: prosDetData.prospect_name,
          organization: organization_id,
          prospect_address: "",
          prospect: Number(prosDetData.hq_prospect_id) > 0 ? prosDetData.hq_prospect_id : prosDetData.prospect_id,
          owner_id: prosDetData.prospect_owner,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getprospectDet(prospectSession);
        Swal.fire({
          icon: "success",
          text: "Successfully created the organization",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Sorry, this organization name already exists!",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAddOrganization(false);
      setIsAddOrganization(false);
    }
  };

  const sendEditOrganization = async (values) => {
    setIsLoadingEditOrganization(true);
    try {
      var customField = values.cf_organization;
      var cf_id = customField.map((record) => record.name);
      var cf_label = customField.map((record) => record.cf_label);
      var organization_id = Array(customField.length).fill(prosDetData.organization_id);
      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 2,
          organization: prosDetData.organization_id,
          company: prospectSession.company_id,
          title: values.organization_title,
          address: values.organization_address,
          weburl: values.organization_web_url,
          industry: values.in_id,
          contact: values.organization_contact,
          country: values.organization_country_id,
          owner: organizationData?.owner_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          const response2 = await axios.get("ws/ws_custom_field.php", {
            params: {
              task: "updateOrAddCFV2",
              secret_key: prospectSession.company_token,
              value: fd_values.join("[-0-]"),
              cf: cf_id.join("[-0-]"),
              record: organization_id.join("[-0-]"),
              cf_label: cf_label.join("[-0-]"),
              utoken: prospectSession.user_ac_token,
              ctoken: prospectSession.company_token,
            },
          });

          const data2 = response2.data;
          if (data2.status === 0) {
            Swal.fire({
              icon: "success",
              text: "Successfully updated the organization",
              timer: 2000,
            });
            getprospectDet(prospectSession);
            setIsEditOrganization(false);
          } else {
            Swal.fire({
              icon: "success",
              text: "There is some of information has been updated but there is something wrong with your entry, please try again to update or contact our support IT",
              timer: 2000,
            });
            getprospectDet(prospectSession);
            setIsEditOrganization(false);
          }
        } else {
          Swal.fire({
            icon: "success",
            text: "Successfully updated the organization",
            timer: 2000,
          });
          getprospectDet(prospectSession);
          setIsEditOrganization(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEditOrganization(false);
    }
  };

  const sendDeleteProspectConfirmation = () => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectList_alert_warning_2_title"),
      text: t("ProspectList_alert_warning_2_text"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectList_alert_warning_2_confirm"),
      cancelButtonText: t("ProspectList_alert_warning_cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await sendDeleteProspect(prospectSession);
        if (Number(response) === 0) {
          Swal.fire({
            title: t("ProspectList_alert_success_title"),
            text: t("ProspectList_alert_success_text_delete"),
            icon: "success",
            timer: 1500,
          }).then(() => {
            navigate("/prospect/list");
          });
        } else {
          Swal.fire({
            title: t("ProspectList_alert_warning_3_title"),
            text: `${t("ProspectList_alert_warning_3_text_1")} ${t("ProspectList_alert_warning_3_text_2")} ${prosDetData.prospect_name}, ${t(
              "ProspectList_alert_warning_3_text_3"
            )}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("ProspectList_alert_warning_3_confirm"),
            cancelButtonText: t("ProspectList_alert_warning_cancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              sendForceDeleteProspect();
            }
          });
        }
      }
    });
  };

  const sendDeleteProspect = async () => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "10",
          prospect: prosDetData.prospect_id,
          forceDelete: "",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      return response.data.status;
    } catch (error) {
      console.error(error);
    }
  };

  const sendForceDeleteProspect = async () => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "10",
          prospect: prosDetData.prospect_id,
          forceDelete: "yes",
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        Swal.fire({
          title: t("ProspectList_alert_success_title"),
          text: t("ProspectList_alert_success_text_delete2"),
          icon: "success",
          timer: 1500,
        }).then(() => {
          navigate("/prospect/list");
        });
      } else {
        Swal.fire({
          title: t("ProspectList_alert_warning_4_title"),
          text: t("ProspectList_alert_warning_4_text"),
          icon: "warning",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendChooseOrganization = async (record) => {
    try {
      await axios.get("ws/ws_organization.php", {
        params: {
          task: "updateOrgData",
          organization: organizationData.organization_id,
          title: encodeURIComponent(record.name),
          address: record.address,
          weburl: record.website,
          industry: organizationData.in_id,
          contact: encodeURIComponent(record.phone),
          country: organizationData.organization_country_id,
          latitude: record.lat,
          longitude: record.lon,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });
      getprospectDet(prospectSession);
      setIsFindOrganization(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteOrganizationConfirmation = async () => {
    Swal.fire({
      icon: "warning",
      title: "Pay attention!",
      text: "You are about to unlink this organization from this prospect. This action cannot be undone.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        sendDeleteOrganization();
      }
    });
  };

  const sendDeleteOrganization = async () => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: prosDetData.designation_title,
          des: prosDetData.designation_id,
          name: prosDetData.prospect_name,
          organization: "",
          prospect_address: "",
          prospect: Number(prosDetData.hq_prospect_id) > 0 ? prosDetData.hq_prospect_id : prosDetData.prospect_id,
          owner_id: prosDetData.prospect_owner,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          text: "Successfully unlinked the organization",
          timer: 2000,
        }).then(() => {
          getprospectDet(prospectSession);
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // EDIT FUNCTION ----------------------------------------------------------------

  const editActivityModal = (rec) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: rec.record_activity_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var timeOri = data.record.activity_timed.split(":");
          var timeNew = `${timeOri[0]}:${timeOri[1]}`;

          const editData = {
            deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
            activity_dealid: data.record.deal_id,
            activity_id: data.record.activity_id,
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: timeNew,
            activity_dur: data.record.activity_duration,
            activity_user: data.record.assign_user_id,
            activity_note: data.record.activity_content,
            activity_mad: data.record.activity_mark_as_done,
          };

          setEditActivityInit(editData);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const editNoteModal = async (rec) => {
    var type;
    var dealid;
    if (rec.record_type === "Note") {
      type = "deal";
      dealid = rec.deal_id;
    } else {
      type = "prospect";
      dealid = "";
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 19,
          note: rec.record_activity_id,
          type: type,
          utoken: prospectSession.user_ac_token,
          ctoken: prospectSession.company_token,
        },
      });

      let data = response.data;
      if (data.status === 0) {
        const editData = {
          activity_dealid: dealid,
          activity_type: type,
          activity_id: rec.record_activity_id,
          activity_note: data.record.note_content,
        };

        setEditNoteInit(editData);
        setIsEditNote(true);
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------------------------------------------

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note_Prospect" || record.record_type === "Note") {
      icon = faUser;
      content = record.CREATE_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faUser;
      content = record.ASSIGN_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faBuilding;
      content = "";
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      icon = faEnvelopeOpen;
      content = record.record_comment;
    } else if (record.record_type === "Deal") {
      icon = faDollarSign;
      content = (
        <div
          onClick={() => {
            window.open(`${session.origin}/deal/${record.deal_id}`, "_blank");
          }}
          style={{ cursor: "pointer" }}
        >
          {record.deal_title}
        </div>
      );
    }

    return (
      <Stack direction="horizontal" gap={3}>
        <div>{setDateTimeStr(record.record_date_time)}</div>
        <div className="d-flex">
          {icon ? <FontAwesomeIcon icon={icon} size="lg" className="me-2" /> : ""}
          {content}
        </div>
      </Stack>
    );
  };

  const setTimelineAction = (record) => {
    const hasReadWritePermission = Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1;
    if (hasReadWritePermission) {
      if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp") {
        if (record.record_activity_mad === "yes") {
          return false;
        } else {
          return true;
        }
      } else if (record.record_type === "Note" || record.record_type === "Note_Prospect") {
        return true;
      } else if (record.record_type === "File") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setWidthTimeline = (record) => {
    if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === true) {
      return "90%";
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === false) {
      return "95%";
    } else if ((record.record_type !== "Activity" || record.record_type !== "CustomActivity") && setTimelineAction(record) === true) {
      return "95%";
    } else {
      return "100%";
    }
  };

  const setPhoneNumber = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined || Number(contact) === 0) {
      return "-";
    } else if (prefix === "" || prefix === null || prefix === undefined || Number(prefix) === 0) {
      return contact;
    } else {
      let realPrefix = prefix.replace("+", "");
      var prefix2 = realPrefix.substring(0, 2);
      var prefix3 = realPrefix.substring(0, 3);
      var prefix4 = realPrefix.substring(0, 4);

      if (MobilePrefix.find((prefix) => prefix.value === prefix2)) {
        realPrefix = prefix2;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix3)) {
        realPrefix = prefix3;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix4)) {
        realPrefix = prefix4;
      } else {
        return `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      }

      const contactNumber = `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      console.log(phoneNumber);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
      }

      return contactNumber;
    }
  };

  const setProspectAddress = (record) => {
    let address = "";
    let comma = false;

    if (record.address_line1) {
      address += record.address_line1;

      if (record.address_line2) {
        address += ", ";
        address += record.address_line2;
      }

      if (record.address_line3) {
        address += ", ";
        address += record.address_line3;
      }

      if (record.postal_code) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.postal_code;
      }

      if (record.city_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.city_title;
      }

      if (record.state_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.state_title;
      }

      if (record.country_title) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.country_title;
      }
    } else {
      address += record.prospect_address;
    }

    return address ? address.toUpperCase() : "-";
  };

  const setEditProspectContactError = (errors, touched, index) => {
    let error = "";

    if (
      errors.contact &&
      errors.contact[index] &&
      errors.contact[index].contact_prefix &&
      errors.contact[index].contact_prefix.value &&
      touched.contact &&
      touched.contact[index] &&
      touched.contact[index].contact_prefix &&
      touched.contact[index].contact_prefix.value
    ) {
      error = errors.contact[index].contact_prefix.value;
    }

    if (
      errors.contact &&
      errors.contact[index] &&
      errors.contact[index].contact &&
      touched.contact &&
      touched.contact[index] &&
      touched.contact[index].contact
    ) {
      if (error !== "") {
        error += " & ";
      }
      error += errors.contact[index].contact;
    }

    return error ? <div className="op-error-message mb-2">{error}</div> : null;
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const ScrollToFirstError2 = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        const findFirstError = (obj, keyPath = "") => {
          if (obj === undefined || obj === null) {
            return "";
          }
          return Object.keys(obj).reduce((acc, key) => {
            if (acc) return acc;
            const isArrayIndex = !isNaN(key);
            const newKeyPath = keyPath ? `${keyPath}${isArrayIndex ? `[${key}]` : `.${key}`}` : key;
            if (typeof obj[key] === "string") return newKeyPath;
            return findFirstError(obj[key], newKeyPath);
          }, "");
        };
        const firstErrorKey = findFirstError(errors);
        const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const setCheckImg = () => {
    setImgError(true);
  };

  const setCheckValidUser = () => {
    var valid = true;
    if (Number(prosDetData.owner_id) === Number(prospectSession.user_id) || Number(prospectSession.role_id) === 1) {
      valid = true;
    } else if (isContributor) {
      valid = true;
    } else {
      valid = false;
    }

    if (Number(prospectSession.role_id) === 1) {
      valid = true;
    }
    return valid;
  };

  const setCheckValidAdminUser = () => {
    if (Number(prospectSession.role_id) === 1) {
      return true;
    } else {
      return false;
    }
  };

  const setCustomFieldValues = (record) => {
    if (record.ctf_title === "Checkbox") {
      if (record.fd_value) {
        return record.fd_value.split(",").map((data, index) => (
          <div className="fw-bold" key={index} style={{ wordBreak: "break-word" }}>
            {data}
          </div>
        ));
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else if (record.ctf_title === "Date") {
      if (record.fd_value) {
        return (
          <div className="fw-bold" style={{ wordBreak: "break-word" }}>
            {moment(record.fd_value).format("LL")}
          </div>
        );
      } else {
        return <div className="fw-bold">-</div>;
      }
    } else {
      return (
        <div className="fw-bold" style={{ wordBreak: "break-word" }}>
          {record.fd_value ? record.fd_value : "-"}
        </div>
      );
    }
  };

  const setOrganizationCountryValue = () => {
    if (organizationData?.organization_country_id && Number(organizationData?.organization_country_id) > 0) {
      const country = countryOrganizationList.find((data) => Number(data.value) === Number(organizationData.organization_country_id));
      return <div className="fw-bold">{country ? country.label : "-"}</div>;
    }

    return <div className="fw-bold">-</div>;
  };

  const setTimelineFilterRecord = (record) => {
    if (timelineFilter === "all") {
      return record;
    } else if (timelineFilter === "activity") {
      return (
        (record.record_type === "Activity" || record.record_type === "NoEditActivity") &&
        [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id))
      );
    } else if (timelineFilter === "note") {
      return record.record_type === "Note" || record.record_type === "Note_Prospect";
    } else if (timelineFilter === "file") {
      return record.record_type === "File";
    } else if (timelineFilter === "email") {
      return record.record_type === "Email";
    } else if (timelineFilter === "whatsapp") {
      return (record.record_type === "Activity" || record.record_type === "NoEditActivity") && [8].includes(Number(record.record_activity_type_id));
    } else if (timelineFilter === "changelog") {
      return ["Ownership", "Status", "Archieve", "Reopen", "ProspectTransfer"].includes(record.record_type);
    }

    return record;
  };

  const setTimelineFilterCount = (timeline) => {
    if (timelineFilter === "all" || timelineFilter === "changelog") {
      return true;
    } else if (["activity", "note", "file", "email", "whatsapp"].includes(timelineFilter)) {
      return timeline.filter(setTimelineFilterRecord).length > 0;
    }

    return false;
  };

  const setActivityType = (actvity_type, ca_id) => {
    if ([1, 2, 3, 4, 5].includes(Number(actvity_type))) {
      if (Number(actvity_type) === 1) {
        return "Call";
      } else if (Number(actvity_type) === 2) {
        return "Meeting";
      } else if (Number(actvity_type) === 3) {
        return "Task";
      } else if (Number(actvity_type) === 4) {
        return "Whatsapp";
      } else if (Number(actvity_type) === 5) {
        return "Email";
      }
    } else {
      return "Custom Activity";
    }

    return "";
  };

  // REMOVE FUNCTION ----------------------------------------------------------------

  const removeProsContr = (userID) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      text: t("ProspectDetail_alert_warning_text_2"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_2"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_prospect.php", {
            params: {
              task: "delContributor",
              prospectid: prospect_id,
              userid: userID,
              utoken: prospectSession.user_ac_token,
              ctoken: prospectSession.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_contr"),
                timer: 1500,
              }).then((result) => {
                getprospectDet(prospectSession);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  // HANDLE FUNCTION ----------------------------------------------------------------

  const handleProsTab = (values) => {
    setIsProsTab(values);

    if (values === "contact") {
      setIsMain("");
    } else {
      setIsMain("d-none");
    }
  };

  // GRID STYLE ----------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: isProsDeal ? "70vh" : "0vh", paddingTop: 20 }), [isProsDeal]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleEdm = useMemo(() => ({ width: "100%", height: isEdm ? "70vh" : "0vh", paddingTop: 20 }), [isEdm]);
  const gridStyleEdm = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleAuHis = useMemo(() => ({ width: "100%", height: isAuHis ? "70vh" : "0vh", paddingTop: 20 }), [isAuHis]);
  const gridStyleAuHis = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleOpenDeal = useMemo(() => ({ width: "100%", height: isOpenDeal ? "70vh" : "0vh" }), [isOpenDeal]);
  const gridStyleOpenDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleWonDeal = useMemo(() => ({ width: "100%", height: isWonDeal ? "70vh" : "0vh" }), [isWonDeal]);
  const gridStyleWonDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleInbLd = useMemo(() => ({ width: "100%", height: isInbLd ? "70vh" : "0vh" }), [isInbLd]);
  const gridStyleInbLd = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleLostDeal = useMemo(() => ({ width: "100%", height: isLostDeal ? "70vh" : "0vh" }), [isLostDeal]);
  const gridStyleLostDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [analyticColumn] = useState([
    {
      headerName: t("ProspectDetail_analytic_header_1"),
      field: "deal_title",
      resizable: true,
      width: 250,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(session.origin + "/deal/" + params.data.deal_id);
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_2"),
      field: "deal_value",
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.value) {
          return setCurrency(params.data.deal_value);
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_3"),
      field: "archieve_remark",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_analytic_header_4"),
      field: "deal_expected_close_date",
      resizable: true,
      width: 250,
    },
  ]);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const [prosDealColumn] = useState([
    {
      headerName: t("ProspectDetail_prosDeal_headername_7"),
      field: "deal_date_time_create",
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.value) {
          return moment(params.value).format("LLL");
        }
      },
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_1"),
      field: "deal_title",
      resizable: true,
      width: 250,
      onCellClicked: function (params) {
        if (params.data) {
          if (params.data.deal_archieve !== "Duplicate") {
            window.open(session.origin + "/deal/" + params.data.deal_id, "_blank");
          }
        }
      },
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_2"),
      field: "deal_archieve",
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return "Open";
          } else {
            return params.data.deal_archieve;
          }
        }
      },
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_5"),
      field: "ds_title",
      resizable: true,
      width: 250,
    },
    {
      headerName: "Business Unit",
      field: "unit_title",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_3"),
      field: "cs_title",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_4"),
      field: "pipeline_title",
      resizable: true,
      width: 250,
    },

    {
      headerName: t("ProspectDetail_prosDeal_headername_6"),
      field: "user_name",
      resizable: true,
      width: 250,
    },

    {
      headerName: t("ProspectDetail_prosDeal_headername_8"),
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return moment(params.data.deal_date_time_update).format("LLL");
          } else if (params.data.deal_archieve === "won") {
            return moment(params.data.deal_archieve_won_log).format("LLL");
          } else if (params.data.deal_archieve === "lost") {
            return moment(params.data.deal_archieve_lost_log).format("LLL");
          } else if (params.data.deal_archieve === "deleted") {
            return moment(params.data.deal_archieve_deleted_log).format("LLL");
          } else {
            return "";
          }
        }
      },
    },
  ]);

  const [edmColumn] = useState([
    {
      headerName: t("ProspectDetail_edm_headername_1"),
      field: "event_date_time",
      resizable: true,
      width: 300,
      cellRenderer: (params) => {
        if (params.value) {
          return moment(params.value).format("LLL");
        }
      },
    },
    {
      headerName: t("ProspectDetail_edm_headername_3"),
      field: "event_type",
      resizable: true,
      cellClass: "center",
      headerClass: "center",
    },
    {
      headerName: t("ProspectDetail_edm_headername_2"),
      field: "email_template_name",
      resizable: true,
      flex: 1,
    },
  ]);

  const [auHisColumn] = useState([
    {
      headerName: t("ProspectDetail_auHis_headername_1"),
      field: "log_timestamp",
      resizable: true,
      width: 150,
      cellRenderer: (params) => {
        if (params.value) {
          return moment(params.value).format("lll");
        }
      },
    },
    {
      headerName: t("ProspectDetail_auHis_headername_2"),
      field: "username",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("ProspectDetail_auHis_headername_3"),
      resizable: true,
      field: "event",
      width: 150,
      cellRenderer: (params) => (params.value ? params.value : "Update"),
    },
    {
      headerName: t("ProspectDetail_auHis_headername_4"),
      resizable: true,
      flex: 1,
      autoHeight: true,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div>
              {params.data.field.map((field, index) => (
                <div key={index} className="d-flex align-items-center my-2 w-100">
                  <div className="rounded me-2 fw-bold" style={{ backgroundColor: "transparent" }}>
                    {field.label}
                  </div>
                  <div className="me-2" style={{ maxWidth: 400, textWrap: "wrap", overflowWrap: "break-word" }}>
                    <span style={{ padding: "2px 5px", backgroundColor: "rgba(255, 0, 0, 0.3)", lineHeight: 2 }}>{field.value}</span>
                  </div>
                  <div className="" style={{ maxWidth: 400, textWrap: "wrap", overflowWrap: "break-word" }}>
                    <span style={{ padding: "2px 5px", backgroundColor: "rgba(0, 128, 0, 0.3)", lineHeight: 2 }}>{field.new_value}</span>
                  </div>
                </div>
              ))}
            </div>
          );
        }
      },
    },
    // {
    //   headerName: t("ProspectDetail_auHis_headername_5"),
    //   resizable: true,
    //   flex: 1,
    //   cellRenderer: (params) => {
    //     if (params.data) {
    //       if (params.data.log_old_data[0]) {
    //         var log = params.data.log_old_data[0].value;
    //         var log2 = [];

    //         Object.keys(log).forEach((key) => {
    //           if (key) {
    //             if (params.data.log_old_data[0].value[key]) {
    //               log2.push(params.data.log_old_data[0].value[key]);
    //             }
    //           }
    //         });

    //         return (
    //           <div style={{ overflow: "auto", width: "300px", height: "100px" }}>
    //             {log2.map((data, index) => (
    //               <p key={index}>{data}</p>
    //             ))}
    //           </div>
    //         );
    //       }
    //     }
    //   },
    // },
    // {
    //   headerName: t("ProspectDetail_auHis_headername_6"),
    //   resizable: true,
    //   flex: 1,
    //   cellRenderer: (params) => {
    //     if (params.data) {
    //       if (params.data.log_new_data[0]) {
    //         var log = params.data.log_new_data[0].value;
    //         var log2 = [];

    //         Object.keys(log).forEach((key) => {
    //           if (key) {
    //             if (params.data.log_new_data[0].value[key]) {
    //               log2.push(params.data.log_new_data[0].value[key]);
    //             }
    //           }
    //         });

    //         return (
    //           <div style={{ overflow: "auto", width: "300px", height: "100px" }}>
    //             {log2.map((data, index) => (
    //               <p key={index}>{data}</p>
    //             ))}
    //           </div>
    //         );
    //       }
    //     }
    //   },
    // },
  ]);

  // USE EFFECT ----------------------------------------------------------------
  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getProspectLinkedUser();
        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    initializeData();
  }, [init.current]);

  useLayoutEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth - 50);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, [containerRef.current]);

  return (
    <Container fluid className="p-0">
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="main">
          <div className="section-header position-relative">
            <Stack direction="horizontal" gap={2} className="w-100 pt-3 px-4 mb-2">
              <div className="d-flex">
                <div className="avatar me-2">
                  <div className="avatar-img" style={{ width: 70, height: 70, backgroundColor: "#eee" }}>
                    <div className="avatar-txt text-uppercase" style={{ fontSize: "35px" }}>
                      {initial}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex align-items-center">
                  <div className="op-text-bigger fs-5 me-2">{prosDetData.prospect_name}</div>
                </div>
                <div className="op-text-small">
                  {prosDetData.organization_title === "" ? (
                    t("ProspectDetail_Individual")
                  ) : (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(`${session.origin}/organizations/${prosDetData.organization_id}`, "_blank");
                      }}
                    >
                      {prosDetData.organization_title}
                    </label>
                  )}
                </div>
              </div>

              <div className="ms-auto" />

              <div className="d-flex align-items-center justify-content-center">
                {imgError ? (
                  <div className="avatar me-2">
                    <div className="avatar-img op-primary-color" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                      <div className="avatar-txt text-uppercase fs-5 text-light">
                        {prosDetData?.owner_name ? prosDetData.owner_name.split("")[0] : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="me-2">
                    <img
                      className="rounded-circle"
                      alt="owner_image"
                      src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${prosDetData.prospect_user_id}.png?c=${Math.random()}`}
                      width={35}
                      height={35}
                      onError={setCheckImg}
                    />
                  </div>
                )}

                <div className="h-100" style={{ lineHeight: 1.2 }}>
                  <span className="op-text-bigger fw-bold">{prosDetData.owner_name}</span> <br />
                  <span className="op-text-medium">{!isOwner ? t("DealDetails_contributor") : t("DealDetails_owner")}</span>
                </div>

                {Number(prospectSession.role_id) === 1 && (
                  <>
                    <Dropdown show={isTransferOwner} align="end" className="ms-2">
                      <Dropdown.Toggle ref={transferOwnerRef} className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                      <Dropdown.Menu as={RenderTransferOwnership} />
                    </Dropdown>
                  </>
                )}
              </div>

              <button className="btn op-primary-color text-light" onClick={setIsAddDeal}>
                <FontAwesomeIcon icon={faPlusSolid} className="me-1" /> Deal
              </button>
              <RenderProspectAction />
            </Stack>

            <ButtonGroup className="activity-tab2 rounded-0 px-4" style={{ width: "100%" }}>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="contact"
                checked={isProsTab === "contact"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("contact");
                }}
              >
                {t("ProspectDetail_toggleButton_1")}
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="deal"
                checked={isProsTab === "deal"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("deal");
                  getProspectDeals();
                }}
              >
                Deals
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="edm"
                checked={isProsTab === "edm"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("edm");
                }}
              >
                {t("ProspectDetail_toggleButton_3")}
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="contactPref"
                checked={isProsTab === "contactPref"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("contactPref");
                  getContactPref();
                }}
              >
                {t("ProspectDetail_toggleButton_4")}
              </ToggleButton>
              <ToggleButton
                variant=""
                type="radio"
                name="pros_tab"
                value="auHis"
                checked={isProsTab === "auHis"}
                style={{ padding: "10px" }}
                onClick={() => {
                  handleProsTab("auHis");
                  getAuditHistory();
                }}
              >
                {t("ProspectDetail_toggleButton_5")}
              </ToggleButton>
            </ButtonGroup>
          </div>

          <div className="section-body">
            <div className={`section-sidebar ${isMain}`}>
              <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="0">{t("ProspectDetail_accordian_personal_Info")}</RenderAccordionButton>
                    {(setCheckValidUser() || setCheckValidAdminUser()) &&
                      isProspectAllowEditContact &&
                      Number(prospectSession.company_id) === Number(session.company_id) && (
                        <div className="btn-group shadow-sm">
                          <button type="button" className="btn btn-light border" onClick={() => getEditProspectV2()}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </div>
                      )}
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <div className="accordion-body px-0">
                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBriefcase} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Designation</div>
                          <div className="fw-bold">{prosDetData.designation_title ? prosDetData.designation_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Contact Number</div>
                          <div className="fw-bold">
                            {setPhoneNumber(
                              prosDetData.contact1_prefix,
                              prosDetData.contact1
                                ? prosDetData.contact1
                                : prosDetData.prospect_contact_count > 0
                                ? prosDetData.prospect_contact_info[0].contact_num
                                : ""
                            )}
                          </div>
                          {prosDetData.contact2 && (
                            <div className="fw-bold">
                              {setPhoneNumber(prosDetData.contact2_prefix, prosDetData.contact2 ? prosDetData.contact2 : "")}
                            </div>
                          )}
                          {prosDetData.contact3 && (
                            <div className="fw-bold">
                              {setPhoneNumber(prosDetData.contact3_prefix, prosDetData.contact3 ? prosDetData.contact3 : "")}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faEnvelope} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Email</div>
                          <div className="fw-bold">
                            {prosDetData.email1
                              ? prosDetData.email1
                              : prosDetData.prospect_email_count > 0
                              ? prosDetData.prospect_email_info[0].email_title
                              : "-"}
                          </div>
                          {prosDetData.email2 && <div className="fw-bold">{prosDetData.email2 ? prosDetData.email2 : ""}</div>}
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Address</div>
                          <div className="fw-bold">{setProspectAddress(prosDetData)}</div>
                        </div>
                      </div>

                      {Number(prosDetData.custom_field) > 0 &&
                        prosDetData.custom_field_data.filter((data) => data.cf_view_detail === "yes").length > 0 && (
                          <>
                            {prosDetData.custom_field_data
                              .filter((data) => data.cf_view_detail === "yes")
                              .map((data, i) => (
                                <div className="d-flex p-2 w-100" key={i}>
                                  <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                    <FontAwesomeIcon icon={faPlus} size="xl" />
                                  </div>
                                  <div className="w-100">
                                    <div className="text-muted">{data.cf_label}</div>
                                    {setCustomFieldValues(data)}
                                  </div>
                                </div>
                              ))}
                          </>
                        )}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(session.company_type_id) !== 2 && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="3">Organization</RenderAccordionButton>
                      {(setCheckValidUser() || setCheckValidAdminUser()) &&
                        isOrganizationAllowEditContact &&
                        Number(prospectSession.company_id) === Number(session.company_id) && (
                          <div className="btn-group shadow-sm">
                            {prosDetData.organization_title && (
                              <button type="button" className="btn btn-light border" onClick={() => getOrganizationData(prospectSession)}>
                                Find Info
                              </button>
                            )}

                            {prosDetData.organization_title && (
                              <button
                                type="button"
                                className="btn btn-light border"
                                onClick={() => {
                                  setIsLoadingEditOrganization(false);
                                  setIsEditOrganization(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </button>
                            )}

                            {prosDetData.organization_title && <RenderOrganizationAction />}

                            {!prosDetData.organization_title && (
                              <button
                                type="button"
                                className="btn btn-light border"
                                onClick={() => {
                                  setIsLoadingAddOrganization(false);
                                  setIsAddOrganization(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            )}
                          </div>
                        )}
                    </div>

                    <Accordion.Collapse eventKey="3">
                      <div className="accordion-body px-0">
                        {prosDetData.organization_title ? (
                          <>
                            <div
                              className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                              onClick={() => {
                                if (isOrganizationAllowEditContact) {
                                  window.open(`${session.origin}/organizations/${prosDetData.organization_id}`, "_blank");
                                } else {
                                  Swal.fire({
                                    text: "You are not allowed to view this organization",
                                    icon: "warning",
                                  });
                                }
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faBuildings} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">Organization Name</div>
                                  <div className="fw-bold">{prosDetData.organization_title ? prosDetData.organization_title : "-"}</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Address</div>
                                <div className="fw-bold">{prosDetData.organization_address ? prosDetData.organization_address : "-"}</div>
                              </div>
                            </div>

                            <div
                              className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                              onClick={() => {
                                if (prosDetData?.organization_web_url) {
                                  window.open(`${prosDetData?.organization_web_url}`, "_blank");
                                } else {
                                  Swal.fire({
                                    text: "The website URL is currently unavailable. Please check back your organization information to update the website URL or contact support for assistance.",
                                    icon: "warning",
                                  });
                                }
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faGlobe} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">Website</div>
                                  <div className="fw-bold">{prosDetData?.organization_web_url ? prosDetData?.organization_web_url : "-"}</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faPhone} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Contact</div>
                                <div className="fw-bold">{prosDetData.organization_contact ? prosDetData.organization_contact : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faIndustryWindows} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Industry</div>
                                <div className="fw-bold">{organizationData?.in_title ? organizationData.in_title : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faIndustryWindows} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Country</div>
                                {setOrganizationCountryValue()}
                              </div>
                            </div>

                            {organizationData?.custom_field > 0 &&
                              organizationData?.custom_field_data.filter((data) => data.cf_view_detail === "yes").length > 0 &&
                              organizationData?.custom_field_data
                                .filter((data) => data.cf_view_detail === "yes")
                                .map((record, index) => (
                                  <div className="d-flex p-2 w-100" key={index}>
                                    <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                      <FontAwesomeIcon icon={faPlus} size="xl" />
                                    </div>
                                    <div className="w-100">
                                      <div className="text-muted">{record.cf_label}</div>
                                      {setCustomFieldValues(record)}
                                    </div>
                                  </div>
                                ))}
                          </>
                        ) : (
                          <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t("ProspectDetail_accordian_analytics")}</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body px-0">
                      <div
                        className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                        onClick={() => setAnalyticOpen(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleDollar} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_open) === 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : Number(analyticData.total_open) > 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : t("ProspectDetail_accordian_analytics_content_1_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData.open_amount)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div
                        className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                        onClick={() => setAnalyticInbLd(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChartLineUp} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_inbound) === 1
                                ? t("ProspectDetail_accordian_analytics_content_2_record", { count: analyticData.total_inbound })
                                : Number(analyticData.total_inbound) > 1
                                ? t("ProspectDetail_accordian_analytics_content_2_record", { count: analyticData.total_inbound })
                                : t("ProspectDetail_accordian_analytics_content_2_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData.inbound_amount)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div
                        className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                        onClick={() => setAnalyticWon(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleCheckLight} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_won) === 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : Number(analyticData.total_won) > 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : t("ProspectDetail_accordian_analytics_content_3_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData.won_amount)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div
                        className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded"
                        onClick={() => setAnalyticLost(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faCircleXMarkLight} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">
                              {Number(analyticData.total_lost) === 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : Number(analyticData.total_lost) > 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : t("ProspectDetail_accordian_analytics_content_4_record_no")}
                            </div>
                            <div className="fw-bold">{setCurrency(analyticData.lost_amount)}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="2">{t("ProspectDetail_accordian_contributor")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button
                        type="button"
                        className="btn btn-light border"
                        onClick={() => {
                          setIsAddContr(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="2">
                    {Number(prosDetData.contributor_count) > 0 ? (
                      <div className="accordion-body">
                        {prosDetData.contributor.map((data, i) => (
                          <Stack className="mb-2" direction="horizontal" key={i}>
                            <FontAwesomeIcon icon={faUserTie} size="lg" className="me-2" />
                            <span>{data.user_name}</span>
                            <FontAwesomeIcon
                              className="ms-auto"
                              icon={faCircleXmark}
                              size="lg"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeProsContr(data.user_id);
                              }}
                            />
                          </Stack>
                        ))}
                      </div>
                    ) : (
                      <div className="accordion-body">{t("ProspectDetail_accordian_contributor_content_no")}</div>
                    )}
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>

            <div className={`section-content ${isMain}`} ref={containerRef}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t("ProspectDetail_note")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t("ProspectDetail_activity")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab
                    tab={activeTab}
                    active={isActiveTab}
                    userListNotes={userList}
                    activityUser={userList}
                    activityLoading={activityLoading}
                  />
                </Card>

                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("ProspectDetail_accordian_timeline")}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0" style={{ width }}>
                      <div className="accordion-body">
                        <ButtonGroup className="timeline-tab mb-3">
                          <ToggleButton
                            variant=""
                            type="radio"
                            name="timeline_filter"
                            id="timeline_filter_1"
                            value="1"
                            checked={timelineFilter === "all"}
                            onClick={() => setTimelineFilter("all")}
                          >
                            All
                          </ToggleButton>
                          <ToggleButton
                            variant=""
                            type="radio"
                            name="timeline_filter"
                            id="timeline_filter_2"
                            value="2"
                            checked={timelineFilter === "note"}
                            onClick={() => setTimelineFilter("note")}
                          >
                            Notes (
                            {
                              timelineData.filter(
                                (record) =>
                                  record.record_type === "Note" ||
                                  record.record_type === "Note_Prospect" ||
                                  record.record_type === "Note_Organization"
                              ).length
                            }
                            )
                          </ToggleButton>
                          <ToggleButton
                            variant=""
                            type="radio"
                            name="timeline_filter"
                            id="timeline_filter_2"
                            value="2"
                            checked={timelineFilter === "activity"}
                            onClick={() => setTimelineFilter("activity")}
                          >
                            Activities (
                            {
                              timelineData.filter(
                                (record) =>
                                  (record.record_type === "Activity" || record.record_type === "NoEditActivity") &&
                                  [1, 2, 3, 4, 5].includes(Number(record.record_activity_type_id))
                              ).length
                            }
                            )
                          </ToggleButton>
                          <ToggleButton
                            variant=""
                            type="radio"
                            name="timeline_filter"
                            id="timeline_filter_2"
                            value="2"
                            checked={timelineFilter === "changelog"}
                            onClick={() => setTimelineFilter("changelog")}
                          >
                            Changelog
                          </ToggleButton>
                        </ButtonGroup>

                        {timelineData && setTimelineFilterCount(timelineData) ? (
                          <VerticalTimeline layout="1-column-left">
                            {timelineData.filter(setTimelineFilterRecord).map((record, index) => (
                              <VerticalTimelineElement
                                key={index}
                                date={setTimelineDateTime(record)}
                                iconStyle={{ background: "#fff" }}
                                icon={<RenderTimelineIcon record={record} />}
                              >
                                <Stack direction="horizontal" gap={1} style={{ alignItems: "normal" }}>
                                  <div style={{ width: setWidthTimeline(record) }}>
                                    <div className={`op-text-bigger fw-bold`}>
                                      <RenderTimelineHeader record={record} />
                                    </div>
                                    <RenderTimelineDocument record={record} />
                                  </div>

                                  {(record.record_type === "Activity" || record.record_type === "CustomActivity") && (
                                    <div className="mt-1 me-1" style={{ width: "3%" }}>
                                      <div
                                        className="w-100 d-flex justify-content-center float-end"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sendMarkAsDone(record)}
                                      >
                                        {record.record_activity_mad === "yes" ? (
                                          <FontAwesomeIcon icon={faCircleCheck} size="2xl" />
                                        ) : (
                                          <FontAwesomeIcon icon={faCircleLight} size="2xl" />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {setTimelineAction(record) ? (
                                    <div style={{ width: "5%" }}>
                                      <div className="float-end">
                                        <RenderTimelineAction record={record} />
                                      </div>
                                    </div>
                                  ) : null}
                                </Stack>
                                <div style={{ fontSize: 11, maxWidth: "95%", wordWrap: "break-word" }}>
                                  <RenderTimelineDescription record={record} />
                                </div>
                              </VerticalTimelineElement>
                            ))}

                            {["all", "changelog"].includes(timelineFilter) && (
                              <VerticalTimelineElement
                                date={moment(new Date(prosDetData.prospect_date_time_create)).format("lll")}
                                iconStyle={{ background: "#fff" }}
                                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                              >
                                <div className="op-text-bigger fw-bold">Person Created</div>
                              </VerticalTimelineElement>
                            )}
                          </VerticalTimeline>
                        ) : timelineFilter === "note" ? (
                          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                            <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#a)">
                                <path
                                  d="M72.8 54.813H56.913a2.05 2.05 0 0 0-2.05 2.05V72.75M72.8 56.35V14.325a3.075 3.075 0 0 0-3.075-3.075h-59.45A3.075 3.075 0 0 0 7.2 14.325v55.35a3.075 3.075 0 0 0 3.075 3.075H56.4l16.4-16.4Z"
                                  stroke="#B6BBC3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="m27.33 48.883 6.788 6.789 22.009-22.009 2.541-2.541a2.4 2.4 0 0 0 0-3.394l-3.394-3.394a2.4 2.4 0 0 0-3.394 0l-1.984 1.983 6.787 6.788-1.414 1.414-6.787-6.787L27.33 48.883Zm5.12 7.949-6.28-6.28-2.064 6.191-.697 2.09a.6.6 0 0 0 .76.76l2.087-.697 6.194-2.064Z"
                                  fill="#B6BBC3"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="a">
                                  <path fill="#fff" d="M0 0h80v80H0z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mt-2" style={{ fontSize: 14 }}>
                              No notes added yet
                            </span>
                          </div>
                        ) : timelineFilter === "activity" ? (
                          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center mt-3">
                            <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M56 6v8M24 6v8M10 24.5h60M67 70H13a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v54a3 3 0 0 1-3 3Z"
                                stroke="#B6BBC3"
                                stroke-width="2"
                                stroke-linecap="round"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 42a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM24 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM40 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM56 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                fill="#B6BBC3"
                              ></path>
                            </svg>
                            <span className="mt-2" style={{ fontSize: 14 }}>
                              No schedule activity added yet
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Container>
            </div>

            <RenderOtherTab tab={isProsTab} />
          </div>

          <Modal show={isAddDeal} onHide={setIsAddDeal} backdrop="static" size="lg">
            <Formik
              onSubmit={sendAddDeal}
              validationSchema={addDealSchema}
              initialValues={{
                prospect: prosDetData.prospect_id,
                prospectName: prosDetData.prospect_name,
                deal_title: "New Deal - " + prosDetData.prospect_name,
                ds_id: "",
                cs_id: "",
                deal_value: "0",
                deal_close_date: "",
                pipe_id: "",
                customField: [],
                cf_deal: [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_ModalHeader_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_5")} *</Form.Label>
                          <FormSelect
                            name="pipe_id"
                            options={pipeData}
                            isSearchable={true}
                            isInvalid={errors.pipe_id && touched.pipe_id}
                            onChange={async (e) => {
                              setFieldValue("pipe_id", e ? e.value : "");
                              if (e.value) {
                                const cs_id = await getDefCsID(e.value);
                                setFieldValue("cs_id", cs_id);
                                const customField = await getAddDealCF("");
                                if (customField.length > 0) {
                                  setFieldValue("customField", customField);
                                  setFieldValue(
                                    "cf_deal",
                                    customField.map((record) => ({
                                      cf_id: record.cf_id,
                                      name: record.cf_id,
                                      fd_values: record.ctf_title === "Checkbox" ? [] : "",
                                      cf_label: record.cf_label,
                                    }))
                                  );
                                } else {
                                  setFieldValue("customField", []);
                                  setFieldValue("cf_deal", []);
                                }
                              } else {
                                setFieldValue("customField", []);
                                setFieldValue("cf_deal", []);
                              }
                            }}
                          />
                          {errors.pipe_id && touched.pipe_id && <div className="op-error-message">{errors.pipe_id}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_1")} *</Form.Label>
                          <Form.Control
                            type="text"
                            isInvalid={errors.deal_title && touched.deal_title}
                            value={values.deal_title}
                            name="deal_title"
                            onChange={handleChange("deal_title")}
                          />
                          {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_2")} *</Form.Label>
                          <FormSelect
                            name="ds_id"
                            options={dsData}
                            isSearchable={true}
                            isInvalid={errors.ds_id && touched.ds_id}
                            onChange={(e) => {
                              handleChange("ds_id")(e ? e.value : "");
                            }}
                          />
                          {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_3")}</Form.Label>
                          <FormCurrency value={values.deal_value} onChange={handleChange("deal_value")} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_4")}</Form.Label>
                          <Form.Control type="date" value={values.deal_close_date} onChange={handleChange("deal_close_date")} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Data Fields</div>
                        {values.customField.length > 0 ? (
                          <>
                            {values.customField.map((record, index) => {
                              return (
                                (values.pipe_id === record.pipe_id || record.pipe_id === "0") && (
                                  <FormCustomField
                                    key={record.cf_id}
                                    id={record.cf_id}
                                    label={record.cf_label}
                                    options={record.cf_value}
                                    type={record.ctf_title}
                                    name={record.cf_id}
                                    placeholder=""
                                    firstOptions={false}
                                    valueDefault={false}
                                    isClearable={true}
                                    value={values.cf_deal[index].fd_values}
                                    onChange={(info) => {
                                      const updatedCustomFields = [...values.cf_deal];
                                      if (record.ctf_title === "Select") {
                                        updatedCustomFields[index].fd_values = info.value;
                                      } else if (record.ctf_title === "Radio") {
                                        const { value, checked } = info.target;
                                        updatedCustomFields[index].fd_values = checked ? value : "";
                                      } else if (record.ctf_title === "Checkbox") {
                                        const { value, checked } = info.target;
                                        if (checked) {
                                          updatedCustomFields[index].fd_values.push(value);
                                        } else {
                                          const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                          if (indexToRemove !== -1) {
                                            updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                          }
                                        }
                                      } else if (record.ctf_title === "Date") {
                                        updatedCustomFields[index].fd_values = info;
                                      } else {
                                        updatedCustomFields[index].fd_values = info.target.value;
                                      }

                                      setFieldValue("cf_deal", updatedCustomFields);
                                    }}
                                  />
                                )
                              );
                            })}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No data fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditProspect} onHide={setIsEditProspect} backdrop="static" size="lg">
            <Formik onSubmit={sendEditProspect} validationSchema={editProspectSchema} initialValues={initialVal}>
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <ScrollToFirstError2 />
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_edit_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectDetail_modalBody_edit_title_1")} *</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.prospect_name}
                            isInvalid={errors.prospect_name && touched.prospect_name}
                            onChange={handleChange("prospect_name")}
                          />
                          {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("DealDetails_designation")}</Form.Label>
                          <FormSelectCreatable
                            name="designation_title"
                            loadOptions={getExistingDesignation}
                            isLoading={isFindDesignation}
                            withValue={values.designation.label}
                            value={values.designation.label}
                            width="100%"
                            onChange={(e) => setFieldValue("designation", e)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Contact Number</Form.Label>
                          {values.contact.map((record, index) => (
                            <div className="w-100" key={index}>
                              <Stack direction="horizontal" className="mb-2" gap={2}>
                                <FormSelect
                                  name={`contact[${index}].contact_prefix`}
                                  options={MobilePrefix}
                                  isSearchable
                                  isInvalid={
                                    errors.contact &&
                                    errors.contact[index] &&
                                    errors.contact[index].contact_prefix &&
                                    touched.contact &&
                                    touched.contact[index] &&
                                    touched.contact[index].contact_prefix
                                  }
                                  valueDefault={{
                                    label: record.contact_prefix_label,
                                    value: record.contact_prefix,
                                    regionCode: record.contact_prefix_region,
                                  }}
                                  width="80px"
                                  onChange={(e) => {
                                    const updatedContact = [...values.contact];
                                    updatedContact[index].contact_prefix = e.value;
                                    updatedContact[index].contact_prefix_label = e.label;
                                    updatedContact[index].contact_prefix_region = e.regionCode;
                                    setFieldValue("contact", updatedContact);
                                  }}
                                />
                                <Form.Control
                                  name={`contact[${index}].contact`}
                                  type="text"
                                  isInvalid={
                                    errors.contact &&
                                    errors.contact[index] &&
                                    errors.contact[index].contact &&
                                    touched.contact &&
                                    touched.contact[index] &&
                                    touched.contact[index].contact
                                  }
                                  value={record.contact}
                                  onChange={(e) => {
                                    const updatedContact = [...values.contact];
                                    updatedContact[index].contact = e.target.value.replace(/\D/g, "");
                                    setFieldValue("contact", updatedContact);
                                  }}
                                />

                                {index > 0 && (
                                  <Button
                                    variant="light"
                                    className="border"
                                    onClick={() => {
                                      const updatedContact = [...values.contact];
                                      updatedContact.splice(index, 1);
                                      updatedContact.forEach((record, index) => (record.index = index + 1));
                                      setFieldValue("contact", updatedContact);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                )}
                              </Stack>
                              {setEditProspectContactError(errors, touched, index)}
                            </div>
                          ))}

                          {values.contact.length < 3 && (
                            <Button
                              style={{ textDecoration: "none", padding: 0 }}
                              variant="link"
                              onClick={() => {
                                setFieldValue("contact", [
                                  ...values.contact,
                                  {
                                    contact_prefix: "",
                                    contact_prefix_label: "",
                                    contact_prefix_region: "",
                                    contact: "",
                                    main: false,
                                    index: values.contact.length + 1,
                                  },
                                ]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> Add contact
                            </Button>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Email Address</Form.Label>
                          {values.email.map((record, index) => (
                            <div className="w-100" key={index}>
                              <Stack direction="horizontal" className="mb-2" gap={2} key={index}>
                                <Form.Control
                                  name={`email[${index}].email`}
                                  type="email"
                                  value={record.email}
                                  onChange={(e) => {
                                    const updatedEmail = [...values.email];
                                    updatedEmail[index].email = e.target.value;
                                    setFieldValue("email", updatedEmail);
                                  }}
                                  isInvalid={
                                    errors.email &&
                                    errors.email[index] &&
                                    errors.email[index].email &&
                                    touched.email &&
                                    touched.email[index] &&
                                    touched.email[index].email
                                  }
                                />

                                {index > 0 && (
                                  <Button
                                    variant="light"
                                    className="border"
                                    onClick={() => {
                                      const updatedEmail = [...values.email];
                                      updatedEmail.splice(index, 1);
                                      setFieldValue("email", updatedEmail);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                )}
                              </Stack>
                              {errors.email &&
                                errors.email[index] &&
                                errors.email[index].email &&
                                touched.email &&
                                touched.email[index] &&
                                touched.email[index].email && <div className="op-error-message">{errors.email[index].email}</div>}
                            </div>
                          ))}

                          {values.email.length < 2 && (
                            <Button
                              style={{ textDecoration: "none", padding: 0 }}
                              variant="link"
                              onClick={() => {
                                setFieldValue("email", [...values.email, { email: "" }]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> Add email
                            </Button>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Address Line</Form.Label>
                          <Form.Control name="address1" className="mb-2" type="text" value={values.address1} onChange={handleChange("address1")} />
                          <Form.Control name="address2" className="mb-2" type="text" value={values.address2} onChange={handleChange("address2")} />
                          <Form.Control name="address3" type="text" value={values.address3} onChange={handleChange("address3")} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectList_modalBody_edit_title_14")}</Form.Label>
                          <Form.Control name="postcode" type="number" value={values.postcode} onChange={handleChange("postcode")} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("ProspectList_modalBody_edit_title_15")}</Form.Label>
                          <FormSelect
                            name="country_id"
                            options={countryData}
                            valueDefault={values.country}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => {
                              setFieldValue("country", e);
                              if (values.state !== "") {
                                setFieldValue("state_show", false);
                                setFieldValue("state", "");
                              }

                              if (values.city !== "") {
                                setFieldValue("city_show", false);
                                setFieldValue("city", "");
                              }

                              setTimeout(() => {
                                setFieldValue("state_show", true);
                                setFieldValue("city_show", true);
                              }, 100);
                            }}
                          />
                        </Form.Group>

                        {values.state_show && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("ProspectList_modalBody_edit_title_16")}</Form.Label>
                            <FormSelect
                              name="state_id"
                              options={
                                values.country && values.country?.value && Number(values.country?.value) > 0
                                  ? stateData.filter((record) => record.country === values.country.value)
                                  : stateData
                              }
                              valueDefault={values.state}
                              disabled={values.country === "" || values.country?.value === ""}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => {
                                setFieldValue("state", e.value ? e : "");
                                if (values.city !== "") {
                                  setFieldValue("city", "");
                                  setFieldValue("city_show", false);
                                  setTimeout(() => {
                                    setFieldValue("city_show", true);
                                  }, 100);
                                }
                              }}
                            />
                          </Form.Group>
                        )}

                        {values.city_show && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("ProspectList_modalBody_edit_title_17")}</Form.Label>
                            <FormSelect
                              name="city_id"
                              disabled={values.state === "" || values.state?.value === ""}
                              options={
                                values.state && values.state?.value && Number(values.state?.value) > 0
                                  ? cityData.filter((record) => record.state === values.state.value)
                                  : cityData
                              }
                              valueDefault={values.city}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => setFieldValue("city", e.value ? e : "")}
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Data Fields</div>
                        {prosDetData.custom_field > 0 &&
                        prosDetData.custom_field_data.filter((record) => record.cf_view_detail === "yes").length > 0 ? (
                          <>
                            {prosDetData.custom_field_data
                              .filter((record) => record.cf_view_detail === "yes")
                              .map((record, index) => (
                                <FormCustomField
                                  key={record.cf_id}
                                  id={record.cf_id}
                                  label={record.cf_label}
                                  options={record.cf_value}
                                  type={record.ctf_title}
                                  name={record.cf_id}
                                  placeholder=""
                                  firstOptions={false}
                                  valueDefault={false}
                                  isClearable={true}
                                  value={values?.cf_person[index]?.fd_values}
                                  onChange={(info) => {
                                    const updatedCustomFields = [...values.cf_person];
                                    if (record.ctf_title === "Select") {
                                      updatedCustomFields[index].fd_values = info.value;
                                    } else if (record.ctf_title === "Radio") {
                                      if (info) {
                                        const { value, checked } = info.target;
                                        updatedCustomFields[index].fd_values = checked ? value : "";
                                      } else {
                                        updatedCustomFields[index].fd_values = "";
                                      }
                                    } else if (record.ctf_title === "Checkbox") {
                                      const { value, checked } = info.target;
                                      if (checked) {
                                        updatedCustomFields[index].fd_values.push(value);
                                      } else {
                                        const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                        if (indexToRemove !== -1) {
                                          updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                        }
                                      }
                                    } else if (record.ctf_title === "Date") {
                                      updatedCustomFields[index].fd_values = info;
                                    } else {
                                      updatedCustomFields[index].fd_values = info.target.value;
                                    }

                                    setFieldValue("cf_person", updatedCustomFields);
                                  }}
                                />
                              ))}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No data fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddContr} onHide={setIsAddContr}>
            <Formik
              onSubmit={sendAddContr}
              validationSchema={addContrSchema}
              initialValues={{
                contr_user_id: "",
                pros_owner_id: prosDetData.prospect_user_id,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_contributor_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label>{t("ProspectDetail_modalBody_contributor_title_1")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_contributor_title_1_placeholder")}
                        options={userList.filter((option) => selectedContr.includes(option.value) === false && option.value !== values.pros_owner_id)}
                        isClearable={true}
                        isSearchable={true}
                        isInvalid={errors.contr_user_id && touched.contr_user_id}
                        onChange={(e) => {
                          handleChange("contr_user_id")(e ? e.value : "");
                        }}
                      />
                      {errors.contr_user_id && touched.contr_user_id && <div className="op-error-message">{errors.contr_user_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setIsEditNote} size="lg">
            <Formik validationSchema={editNoteSchema} onSubmit={sendEditNote} initialValues={editNoteInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_edit_note_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <NoteEditor
                        value={values.activity_note}
                        onChangeValue={(value) => {
                          handleChange("activity_note")(value);
                        }}
                        height={300}
                      />

                      {errors.activity_note && touched.activity_note && <div className="op-error-message">{errors.activity_note}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik validationSchema={editActivitySchema} onSubmit={sendEditActivity} initialValues={editActivityInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>
                      {t("ProspectDetail_modalHeader_edit_activity_title")} - {setActivityType(values.activity_type, values.ca_id)}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={t("ProspectDetail_modalBody_edit_activity_title_Placeholder") + " *"}
                            value={values.activity_title}
                            isInvalid={errors.activity_title && touched.activity_title}
                            onChange={handleChange("activity_title")}
                          />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    {/* <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                              <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_1")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                              <FontAwesomeIcon icon={faUsers} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_2")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                              <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_3")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                              <FontAwesomeIcon icon={faFlag} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_4")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_5")}
                            </ToggleButton>
                          </ButtonGroup>
                          {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                        </Form.Group>
                      </div>
                    </div> */}

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Stack direction="horizontal" gap={1}>
                          <Row style={{ width: "100%" }}>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect
                                  placeholder={t("ProspectDetail_modalBody_edit_activity_time_placeholder")}
                                  options={timings}
                                  valueDefault={timings.filter((option) => option.value === values.activity_time)}
                                  onChange={(e) => handleChange("activity_time")(e.value)}
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect
                                  placeholder={t("ProspectDetail_modalBody_edit_activity_dur_placeholder")}
                                  options={durationList}
                                  valueDefault={durationList.filter((option) => option.value === values.activity_dur)}
                                  onChange={(e) => handleChange("activity_dur")(e.value)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Stack>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>

                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_activity_user_placeholder") + " *"}
                            options={userList}
                            isDisabled={values.deal_linked}
                            valueDefault={userList.filter((option) => option.value === values.activity_user)}
                            isInvalid={errors.activity_user && touched.activity_user}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => {
                              handleChange("activity_user")(e ? e.value : "");
                            }}
                          />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor
                            value={values.activity_note}
                            onChangeValue={(value) => {
                              handleChange("activity_note")(value);
                            }}
                            height={300}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label={t("ProspectDetail_modalBody_edit_activity_mad_label")}
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.activity_mad === "yes"}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChange("activity_mad")("yes");
                        } else {
                          handleChange("activity_mad")("no");
                        }
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditContactPref} onHide={setIsEditContactPref}>
            <Formik
              onSubmit={sendEditContactPref}
              initialValues={{
                email: contactPrefData.email === "Allow" ? "1" : "0",
                email_bulk: contactPrefData.email_bulk === "Allow" ? "1" : "0",
                phone: contactPrefData.phone === "Allow" ? "1" : "0",
                fax: contactPrefData.fax === "Allow" ? "1" : "0",
                mail: contactPrefData.mail === "Allow" ? "1" : "0",
                whatsapp: contactPrefData.whatsapp === "Allow" ? "1" : "0",
                pdpa: contactPrefData.pdpa === "Allow" ? "1" : "0",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3 mt-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_1")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.email)}
                            onChange={(e) => {
                              handleChange("email")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_2")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.email_bulk)}
                            onChange={(e) => {
                              handleChange("email_bulk")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_3")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.phone)}
                            onChange={(e) => {
                              handleChange("phone")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_4")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.fax)}
                            onChange={(e) => {
                              handleChange("fax")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_5")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.mail)}
                            onChange={(e) => {
                              handleChange("mail")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_6")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.whatsapp)}
                            onChange={(e) => {
                              handleChange("whatsapp")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_7")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.pdpa)}
                            onChange={(e) => {
                              handleChange("pdpa")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={analyticOpen} onHide={setAnalyticOpen} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_openDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isOpenDeal ? (
                <div className="mt-0" style={containerStyleOpenDeal}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleOpenDeal, borderRadius: 8 }}>
                    <AgGridReact
                      ref={openDealGridRef}
                      columnDefs={analyticColumn}
                      rowData={openDealRowData}
                      defaultColDef={reportColDef}
                      rowHeight={50}
                      pagination={true}
                      paginationPageSize={30}
                      paginationPageSizeSelector={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c0 0 0 0 0 0s0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c0 0 0 0 0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c0 0 0 0 0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5c0 0 0 0 0 0s0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for open deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticWon} onHide={setAnalyticWon} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_wonDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isWonDeal ? (
                <div style={containerStyleWonDeal}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleWonDeal, borderRadius: 8 }}>
                    <AgGridReact
                      ref={wonDealGridRef}
                      columnDefs={analyticColumn}
                      rowData={wonDealRowData}
                      defaultColDef={reportColDef}
                      rowHeight={50}
                      pagination={true}
                      paginationPageSize={30}
                      paginationPageSizeSelector={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm58.3-13.7c3.1-3.1 8.2-3.1 11.3 0L480 396.7l74.3-74.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3l-80 80c-3.1 3.1-8.2 3.1-11.3 0l-48-48c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for won deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticInbLd} onHide={setAnalyticInbLd} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_inbLd")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isInbLd ? (
                <div style={containerStyleInbLd}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleInbLd, borderRadius: 8 }}>
                    <AgGridReact
                      ref={inbLdGridRef}
                      columnDefs={analyticColumn}
                      rowData={inbLdRowData}
                      defaultColDef={reportColDef}
                      rowHeight={50}
                      pagination={true}
                      paginationPageSize={30}
                      paginationPageSizeSelector={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 72C0 49.9 17.9 32 40 32l64 0c22.1 0 40 17.9 40 40l0 184c0 44.2 35.8 80 80 80s80-35.8 80-80l0-184c0-22.1 17.9-40 40-40l64 0c22.1 0 40 17.9 40 40l0 126.6c-5.4 1.5-10.8 3.3-16 5.4l0-44-112 0 0 96c0 53-43 96-96 96s-96-43-96-96l0-96L16 160l0 96c0 114.9 93.1 208 208 208c39.8 0 77-11.2 108.6-30.6c2 5 4.2 9.9 6.7 14.6C305.6 468.4 266.2 480 224 480C100.3 480 0 379.7 0 256L0 72zm16 0l0 72 112 0 0-72c0-13.3-10.7-24-24-24L40 48C26.7 48 16 58.7 16 72zm304 0l0 72 112 0 0-72c0-13.3-10.7-24-24-24l-64 0c-13.3 0-24 10.7-24 24zm32 296c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm72.1-31.5c-1.1-16.5 8.9-27.1 20.4-32.9c8.5-4.3 18.5-6.5 27.4-7.3l0-16.3c0-4.4 3.6-8 8-8s8 3.6 8 8l0 16.4c10.2 .8 20.4 2.8 29.8 5c4.3 1 7 5.3 5.9 9.6s-5.3 7-9.6 5.9c-11.4-2.7-23.1-4.8-34.3-5c-8.8-.1-19.8 1.6-28.1 5.8c-8 4.1-12.3 9.7-11.7 17.6c.4 5.2 3.6 9.5 11.5 13.5c7.9 4 18.3 6.8 30 10l1 .3c11.5 3.1 24.3 6.6 34.1 12.1c10.2 5.7 18.8 14.5 19.2 28.4c.5 16.4-8.9 27-20.4 33c-8.5 4.4-18.5 6.5-27.6 7.1l0 17.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-18.1c-12.6-1.4-24.7-5.1-35.9-8.5c-2.1-.7-4.3-1.3-6.3-1.9c-4.2-1.3-6.7-5.7-5.4-9.9s5.7-6.7 9.9-5.4c2.3 .7 4.5 1.3 6.7 2c13.6 4.1 26.1 7.9 39.2 8.3c9.4 .3 20.1-1.4 28-5.5c7.6-3.9 12-9.6 11.7-18.3c-.2-6.2-3.6-10.7-11.1-14.9c-7.8-4.4-18.5-7.4-30.5-10.6l-1.8-.5c-11-2.9-22.9-6.2-32.3-11c-10.1-5.2-19.3-13.3-20.2-26.7z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for inbound leads at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={analyticLost} onHide={setAnalyticLost} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_lostDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isLostDeal ? (
                <div style={containerStyleLostDeal}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm"} style={{ ...gridStyleLostDeal, borderRadius: 8 }}>
                    <AgGridReact
                      ref={lostDealGridRef}
                      columnDefs={analyticColumn}
                      rowData={lostDealRowData}
                      defaultColDef={reportColDef}
                      rowHeight={50}
                      pagination={true}
                      paginationPageSize={30}
                      paginationPageSizeSelector={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-5">
                  <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-2.9 0-5.8 .1-8.6 .2c-16.5-60-56-112.1-111.4-144.1c-74.3-42.9-165.7-42.9-240 0S16 170.3 16 256s45.7 165 120 207.8c67.6 39.1 149.6 42.5 219.8 10.4c3.3 4.4 6.9 8.6 10.6 12.7c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256zm176.4 87.1c1.4-4.2 5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c11.4-4.6 19.5-11.5 23.7-20.6c-2.1 9.1-3.5 18.5-4.1 28.2c-4.1 2.8-8.7 5.3-13.6 7.2c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1zm.9-153.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4l0-35.9c0-4.4 3.6-8 8-8l-.2-.2c4.4 0 8 3.6 8 8l0 35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c7.9 5.4 13.9 12.2 17.8 19.9c-4.3 8.6-8 17.7-10.8 27.1c1.4-13.9-4.1-25.4-16-33.6c-14.5-9.9-32.1-14.8-49-19.5c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm16 0c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm66.3-61.7c3.1-3.1 8.2-3.1 11.3 0L496 356.7l50.3-50.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3L507.3 368l50.3 50.3c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L496 379.3l-50.3 50.3c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L484.7 368l-50.3-50.3c-3.1-3.1-3.1-8.2 0-11.3z" />
                  </svg>
                  <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                    No record found
                  </div>
                  <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                    Unfortunately, we couldn't find any records for lost deals at this time.
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isAddOrganization} onHide={setIsAddOrganization}>
            <Formik
              onSubmit={sendNewOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_id: "",
                organization_title: "",
                in_id: "",
                organization_web_url: "",
                organization_contact: "",
                organization_address: "",
                organization_country_id: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <Modal.Header closeButton={isLoadingAddOrganization ? false : true}>
                    <Modal.Title as={"h6"}>Link an organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Organization *</Form.Label>
                      <FormSelectCreatable
                        loadOptions={getExistOrg}
                        isInvalid={errors.organization_title && touched.organization_title}
                        isLoading={isFindOrg}
                        width="100%"
                        onChange={(info) => {
                          setFieldValue("organization_id", info.value);
                          setFieldValue("organization_title", info.label);
                          setFieldValue("organization_address", info.organization_address);
                          setFieldValue("organization_web_url", info.organization_web_url);
                          setFieldValue("organization_contact", info.organization_contact);
                          setFieldValue("organization_country_id", info.organization_country_id);
                          setFieldValue("in_id", info.in_id);
                        }}
                      />
                      {errors.organization_title && touched.organization_title && <div className="op-error-message">{errors.organization_title}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingAddOrganization}>
                      {isLoadingAddOrganization ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditOrganization} onHide={setIsEditOrganization} size="lg" backdrop="static">
            <Formik
              onSubmit={sendEditOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_title: organizationData && organizationData?.organization_title ? organizationData?.organization_title : "",
                in_id: organizationData && organizationData?.in_id && Number(organizationData?.in_id) > 0 ? organizationData?.in_id : "",
                organization_web_url: organizationData && organizationData?.organization_web_url ? organizationData?.organization_web_url : "",
                organization_contact: organizationData && organizationData?.organization_contact ? organizationData?.organization_contact : "",
                organization_address: organizationData && organizationData?.organization_address ? organizationData?.organization_address : "",
                organization_country_id:
                  organizationData && organizationData?.organization_country_id && Number(organizationData?.organization_country_id) > 0
                    ? organizationData?.organization_country_id
                    : "",
                deal_linked: Number(prospectSession.company_id) === Number(session.company_id) ? false : true,
                cf_organization:
                  organizationData?.custom_field > 0 && organizationData?.custom_field_data.filter((data) => data.cf_view_detail === "yes").length > 0
                    ? organizationData?.custom_field_data
                        .filter((data) => data.cf_view_detail === "yes")
                        .map((record) => ({
                          cf_id: record.cf_id,
                          name: record.cf_id,
                          fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                          cf_label: record.cf_label,
                        }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <ScrollToFirstError />
                  <Modal.Header closeButton={isLoadingEditOrganization ? false : true}>
                    <Modal.Title as={"h6"}>Edit Organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Organization Name *</Form.Label>
                          <Form.Control value={values.organization_title} name="organization_title" onChange={handleChange("organization_title")} />
                          {errors.organization_title && touched.organization_title && (
                            <div className="op-error-message">{errors.organization_title}</div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Industry</Form.Label>
                          <FormSelect
                            name="in_id"
                            options={industryList}
                            valueDefault={values.in_id ? industryList.find((record) => Number(record.value) === Number(values.in_id)) : ""}
                            onChange={(e) => handleChange("in_id")(e.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Website URL</Form.Label>
                          <Form.Control
                            value={values.organization_web_url}
                            name="organization_web_url"
                            onChange={handleChange("organization_web_url")}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            value={values.organization_contact}
                            name="organization_contact"
                            onChange={handleChange("organization_contact")}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Organization Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={values.organization_address}
                            name="organization_address"
                            onChange={handleChange("organization_address")}
                            style={{ height: 100, resize: "none" }}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Country</Form.Label>
                          <FormSelect
                            name="organization_country_id"
                            options={countryOrganizationList}
                            valueDefault={
                              values.organization_country_id
                                ? countryOrganizationList.find((record) => Number(record.value) === Number(values.organization_country_id))
                                : ""
                            }
                            onChange={(e) => handleChange("organization_country_id")(e.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <div className="op horizontal-start divider">Custom Fields</div>
                        {organizationData &&
                        organizationData?.custom_field > 0 &&
                        organizationData?.custom_field_data.filter((data) => data.cf_view_detail === "yes").length > 0 ? (
                          <>
                            {organizationData?.custom_field_data
                              .filter((data) => data.cf_view_detail === "yes")
                              .map((record, index) => (
                                <FormCustomField
                                  key={record.cf_id}
                                  id={record.cf_id}
                                  label={record.cf_label}
                                  options={record.cf_value}
                                  type={record.ctf_title}
                                  name={record.cf_id}
                                  placeholder=""
                                  firstOptions={false}
                                  valueDefault={false}
                                  isClearable={true}
                                  value={values.cf_organization[index].fd_values}
                                  onChange={(info) => {
                                    const updatedCustomFields = [...values.cf_organization];
                                    if (record.ctf_title === "Select") {
                                      updatedCustomFields[index].fd_values = info.value;
                                    } else if (record.ctf_title === "Radio") {
                                      if (info) {
                                        const { value, checked } = info.target;
                                        updatedCustomFields[index].fd_values = checked ? value : "";
                                      } else {
                                        updatedCustomFields[index].fd_values = "";
                                      }
                                    } else if (record.ctf_title === "Checkbox") {
                                      const { value, checked } = info.target;
                                      if (checked) {
                                        updatedCustomFields[index].fd_values.push(value);
                                      } else {
                                        const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                        if (indexToRemove !== -1) {
                                          updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                        }
                                      }
                                    } else if (record.ctf_title === "Date") {
                                      updatedCustomFields[index].fd_values = info;
                                    } else {
                                      updatedCustomFields[index].fd_values = info.target.value;
                                    }

                                    setFieldValue("cf_organization", updatedCustomFields);
                                  }}
                                />
                              ))}
                          </>
                        ) : (
                          <div className="mt-5 text-center">No custom fields found</div>
                        )}
                      </Col>
                      <div className="op vertical fluid divider"></div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isLoadingEditOrganization}>
                      {isLoadingEditOrganization ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isFindOrganization} onHide={setIsFindOrganization} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Find Organization Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {findOrganizationData.length > 0 ? (
                <ListGroup as="ol" numbered>
                  {findOrganizationData.map((record, index) => (
                    <ListGroup.Item as="li" key={index} className="d-flex align-items-start">
                      <div className="ms-3">
                        <div className="fw-bold">{record.name}</div>
                        <p className="op-text-small">{record.address}</p>
                        <Button variant="" className="op-primary-color text-light" onClick={() => sendChooseOrganization(record)}>
                          Use Data
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <FsLightbox toggler={lightboxOpen} sources={lightboxImages} />
        </div>
      )}
    </Container>
  );
};

export default ProspectDetails;
