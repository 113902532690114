import { useState, useEffect, useMemo, useRef, forwardRef, useCallback } from "react";
import { Stack, Form, Dropdown, Offcanvas, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import FormSelect from "../includes/FormSelect";
import "../lib/scss/op-report-style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

const ReportDealLost = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const gridRef = useRef();
  const { Formik } = formik;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(0);
  const [isReport, setIsReport] = useState(false);
  const [recordFound, setRecordFound] = useState(0);

  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(1, "year").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [teamID, setTeamID] = useState("");
  const [userID, setUserID] = useState([]);
  const [buID, setBuID] = useState("");
  const [catDsID, setCatDsID] = useState("");
  const [dsID, setDsID] = useState([]);
  const [csID, setCsID] = useState([]);
  const [pipeID, setPipeID] = useState("");
  const [ldStat, setLdStat] = useState([]);

  const [teamList, setTeamList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [buList, setBuList] = useState([]);
  const [dsCatList, setDsCatList] = useState([]);
  const [dsList, setDsList] = useState([]);
  const [csList, setCsList] = useState([]);
  const [pipeList, setPipeList] = useState([]);

  const [showActivity, setShowActivity] = useState(0);
  const [showCF, setShowCF] = useState(0);
  const [showProsCtc, setShowProsCtc] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [isAddToCur, setIsAddToCur] = useState(false);

  const [curationList, setCurationList] = useState([]);

  const handleClick = () => {
    if (isIndeterminate) {
      if (gridRef.current.api) {
        gridRef.current.api.forEachNode((node) => node.setSelected(false));
      }
    } else {
      setIsChecked((prevChecked) => {
        const newChecked = !prevChecked;
        setIsIndeterminate(false);

        if (gridRef.current.api) {
          gridRef.current.api.forEachNode((node) => node.setSelected(newChecked));
        }

        return newChecked;
      });
    }
  };

  const onReportSelected = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;
    const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

    setIsChecked(allSelected);
    setIsIndeterminate(atLeastOneSelected && !allSelected);

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  const RenderBulkActionToggle = forwardRef(({ children, onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <button ref={ref} onClick={openDropdown} className="btn btn-light shadow">
        <FontAwesomeIcon icon={faBullhorn} size="lg" />
        {children}
      </button>
    );
  });

  const RenderBulkActionMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Dropdown.Header className="fw-semibold op-text-medium">{t("Report_BulkAction")}</Dropdown.Header>
        <Dropdown.Item onClick={openAddToCuration} disabled={Number(session.company_id) === 251 ? true : false}>
          {t("Report_BulkAction")}
        </Dropdown.Item>
      </div>
    );
  });

  const openAddToCuration = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("Report_addToCur_maxRecord_alert_Title"),
        text: t("Report_addToCur_maxRecord_alert_Text"),
      });
    } else {
      setIsAddToCur(true);
    }
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const [reportColumn] = useState([
    {
      // headerComponent: CustomHeaderCheckbox,
      headerName: "",
      field: "",
      checkboxSelection: true,
      resizable: false,
      width: 50,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return "";
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName1"),
      field: "deal_archieve_lost_log",
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          return moment(params.data.deal_archieve_lost_log).format('lll');
        }
      }
    },
    {
      headerName: t("ReportDealLost_HeaderName2"),
      field: "deal_title",
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div
              onClick={() => {
                window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
              }}
            >
              {params.data.deal_title}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName3"),
      field: "owner_name",
      width: 250,
    },
    {
      headerName: t("ReportDealLost_HeaderName4"),
      width: 250,
      hide: true,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.ACTIVITY_LIST.length > 0) {
            var activityList = params.data.ACTIVITY_LIST.map((data) => {
              return data.activity_title;
            });

            return activityList.toString().replaceAll(",", " ");
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.ACTIVITY_LIST.length > 0) {
            var activityList = params.data.ACTIVITY_LIST.map((data) => {
              return data.activity_title;
            });

            if (activityList.length > 0) {
              return (
                <OverlayTrigger
                  trigger={["click", "focus"]}
                  placement="left"
                  rootClose
                  overlay={
                    <Popover>
                      <Popover.Header as={"h6"}>Activities</Popover.Header>
                      <Popover.Body className="p-0">
                        <div className="p-3" style={{ overflow: "auto", height: "auto", maxHeight: "250px", width: "auto" }}>
                          {activityList.map((data, i) => (
                            <div key={i}>{ReactHtmlParser(data)}</div>
                          ))}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span>{ReactHtmlParser(activityList.toString().replaceAll(",", "<br/>"))}</span>
                </OverlayTrigger>
              );
            }
          } else {
            return "";
          }
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName5"),
      field: "deal_value",
      width: 200,
    },
    {
      headerName: t("ReportDealLost_HeaderName6"),
      field: "cs_title",
      width: 200,
    },
    {
      headerName: t("ReportDealLost_HeaderName7"),
      field: "pipe_title",
      width: 200,
    },
    {
      headerName: t("ReportDealLost_HeaderName8"),
      field: "archieve_remark",
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          var value = "";
          if (params.data.deal_archieve) {
            value = `${params.data.deal_archieve}`;
            if (params.data.lost_reason && params.data.deal_archieve === "lost") {
              value = value + ` | Reason: ${params.data.lost_reason}`;
            }

            if (params.data.archieve_remark) {
              value = value + ` | Remark: ${params.data.archieve_remark}`;
            }
          }

          return value;
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName9"),
      field: "deal_status",
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.deal_status === "new") {
            return "Not Contacted";
          } else if (params.data.deal_status === "inactive") {
            return "Need Follow Up";
          } else if (params.data.deal_status === "active") {
            return "Progressing";
          } else {
            return "";
          }
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName10"),
      field: "prospect_name",
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div
              onClick={() => {
                window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
              }}
            >
              {params.data.prospect_name}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName11"),
      resizable: true,
      width: 200,
      hide: true,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.prospect_contact) {
            return params.data.prospect_contact;
          }
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName12"),
      resizable: true,
      width: 200,
      hide: true,
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.prospect_email) {
            return params.data.prospect_email;
          }
        }
      },
    },
    {
      headerName: t("ReportDealLost_HeaderName13"),
      field: "ds_title",
      width: 200,
    },
    {
      headerName: t("ReportDealLost_HeaderName14"),
      field: "deal_expected_close_date",
      width: 200,
      valueGetter: (params) => {
        if (params.data) {
          return moment(params.data.deal_expected_close_date).format("lll");
        }
      }
    },
  ]);

  const reportDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ws/ws_dealreport.php", {
            params: {
              task: "concludedDealsReport",
              ds: startDate,
              de: endDate,
              user: userID.toString().replaceAll(",", "|"),
              source: dsID.toString().replaceAll(",", "|"),
              stage: csID.toString().replaceAll(",", "|"),
              pipeid: pipeID,
              cfid: "",
              cfvalue: "",
              prospectname: searchVal,
              sourcecat: catDsID,
              bu_id: buID,
              leadstatus: ldStat.toString().replaceAll(",", "|"),
              user_tag: teamID,
              mode: "lost",
              activityNeeded: showActivity,
              cfNeeded: showCF,
              contactNeeded: showProsCtc,
              startRow: params.startRow,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            var reportInfo = [];
            var totalRecord = 0;

            if (Number(data.status) === 0) {
              reportInfo = data.record;
              totalRecord = Number(data.totalRecord);

              var colDef = [];

              data.deal_custom_field_cf_info.forEach((data) => {
                colDef.push({
                  headerName: data.cf_label,
                  field: "cfList",
                  width: 200,
                  hide: true,
                  valueGetter: (params) => {
                    if (params.data) {
                      if (Number(params.data.deal_custom_field) > 0) {
                        for (let index = 0; index < params.data.deal_custom_field_data.length; index++) {
                          if (params.data.deal_custom_field_data[index].cf_id === data.cf_id) {
                            return params.data.deal_custom_field_data[index].fd_value;
                          }
                        }
                      } else if (Number(params.data.prospect_custom_field) > 0) {
                        for (let index = 0; index < params.data.prospect_custom_field_data.length; index++) {
                          if (params.data.prospect_custom_field_data[index].cf_id === data.cf_id) {
                            return params.data.prospect_custom_field_data[index].fd_value;
                          }
                        }
                      } else if (Number(params.data.organization_custom_field) > 0) {
                        for (let index = 0; index < params.data.organization_custom_field_data.length; index++) {
                          if (params.data.organization_custom_field_data[index].cf_id === data.cf_id) {
                            return params.data.organization_custom_field_data[index].fd_value;
                          }
                        }
                      }
                    }
                  },
                });
              });

              if (Number(data.SHOW_CF) === 0) {
                colDef.forEach((data) => {
                  if (data.field === "cfList") {
                    data.hide = true;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              } else {
                colDef.forEach((data) => {
                  if (data.field === "cfList") {
                    data.hide = false;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              }

              if (Number(data.SHOW_ACTIVITY) === 0) {
                reportColumn.forEach((data) => {
                  if (data.headerName === "All Activities") {
                    data.hide = true;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              } else {
                reportColumn.forEach((data) => {
                  if (data.headerName === "All Activities") {
                    data.hide = false;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              }

              if (Number(data.SHOW_PROSPECT_CONTACT) === 0) {
                reportColumn.forEach((data) => {
                  if (data.headerName === "Prospect Contact") {
                    data.hide = true;
                  }

                  if (data.headerName === "Prospect Email") {
                    data.hide = true;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              } else {
                reportColumn.forEach((data) => {
                  if (data.headerName === "Prospect Contact") {
                    data.hide = false;
                  }

                  if (data.headerName === "Prospect Email") {
                    data.hide = false;
                  }
                });

                gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
              }

              setRecordFound(Number(data.totalRecord));
              setIsReport(true);
            } else {
              setRecordFound(0);
              setIsReport(false);
              Swal.fire({
                icon: "error",
                title: t("Report_alert_Title"),
                text: t("Report_alert_Text"),
              });
            }

            setTimeout(() => {
              var rowsThisPage = reportInfo;
              var lastRow = -1;

              if (totalRecord <= params.endRow) {
                lastRow = totalRecord;
              }

              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          })
          .catch((error) => {
            setRecordFound(0);
            setIsReport(false);
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      },
    };
  }, [trigger, session]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllTeam = () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "getUserTags",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status === 0)) {
          var dataList = data.record.map((data) => {
            return { label: data.user_tag_title, value: data.user_tag_id };
          });

          setTeamList(dataList);
        } else {
          setTeamList([]);
        }
      })
      .catch((error) => {
        setTeamList([]);
      });
  };

  useEffect(() => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          user_tag: teamID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status === 0)) {
          var dataList = data.record.map((data) => {
            return { label: data.user_name, value: data.user_id };
          });

          setUserList(dataList);
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        setUserList([]);
      });
  }, [teamID, session]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllBU = () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllBU",
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          var dataList = data.record.map((data) => {
            return { label: data.unit_title, value: data.business_unit_id };
          });

          setBuList(dataList);
        } else {
          setBuList([]);
        }
      })
      .catch((error) => {
        setBuList([]);
      });
  };

  const getAllSource = () => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: 2,
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          var catList = data.source_cat.map((data) => {
            return { label: data.source_category_title, value: data.source_category_id };
          });

          var dsList = data.record.map((data) => {
            return { label: data.ds_title, value: data.ds_id, catID: data.source_category_id };
          });

          setDsCatList(catList);
          setDsList(dsList);
        } else {
          setDsCatList([]);
          setDsList([]);
        }
      })
      .catch((error) => {
        setDsCatList([]);
        setDsList([]);
      });
  };

  const getAllStage = () => {
    axios
      .get("ws/ws_pipeline.php", {
        params: {
          task: 3,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status === 0)) {
          var dataList = data.record.map((data) => {
            return { label: data.cs_title, value: data.cs_id };
          });

          setCsList(dataList);
        } else {
          setCsList([]);
        }
      })
      .catch((error) => {
        setCsList([]);
      });
  };

  const getAllPipeline = () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          var dataList = data.record.map((data) => {
            return { label: data.pipeline_title, value: data.pipeline_id };
          });

          setPipeList(dataList);
        } else {
          setPipeList([]);
        }
      })
      .catch((error) => {
        setPipeList([]);
      });
  };

  const getAllLdStat = [
    { label: t("Report_AllLeadStatus_NotContacted"), value: "Not Contacted" },
    { label: t("Report_AllLeadStatus_NeedFollowUp"), value: "Need Follow Up" },
    { label: t("Report_AllLeadStatus_Progressing"), value: "Progressing" },
  ];

  const getAllCuration = () => {
    axios
      .get("ws/ws_curation.php", {
        params: {
          task: "listCuration",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          var dataList = data.record.map((data) => {
            return { label: data.curation_title, value: data.curation_id };
          });

          setCurationList(dataList);
        } else {
          setCurationList([]);
        }
      })
      .catch((error) => {
        setCurationList([]);
      });
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getAllTeam();
      getAllBU();
      getAllSource();
      getAllStage();
      getAllPipeline();
      getAllCuration();
      setIsLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = () => {
    var params = {
      fileName: "deal-lost-report-dataExport.csv",
      processCellCallback: function (params) {
        if (params.column.colId === "auto_increment_num") {
          return params.node.data.temprecord_id;
        } else if (params.column.colId === "deal_title") {
          return params.node.data.deal_title;
        } else {
          var res = params.value;
          if (res !== undefined && res !== null && res !== "") {
            res = res.replace(/<br>/g, "\r\n");
            res = res.replace(/<li>/g, "\r\n");
            res = res.replace(/<[^>]+>/g, "");
            res = res.replace(/&nbsp;/g, "");
          }

          return res;
        }
      },
    };

    gridRef.current.api.exportDataAsCsv(params);
  };

  const addToCurSchema = yup.object().shape({
    curation_id: yup.string().required("This field is required"),
  });

  const sendAddToCur = async (values) => {
    var prospectID = rowData.map((params) => {
      return params.data.prospect_id;
    });

    var params = new FormData();

    params.append("task", "addCurationProspect");
    params.append("curation", values.curation_id);
    params.append("prospects", prospectID.toString());
    params.append("utoken", session.user_ac_token);
    params.append("ctoken", session.company_token);

    try {
      let response = await axios.post("ws/ws_curation.php", params);

      let data = response.data;

      if (Number(data.status) === 0) {
        Swal.fire({
          icon: "success",
          title: t("Report_addToCur_Alert_Success_Title"),
          text: t("Report_addToCur_Alert_Success_Text"),
          timer: 1500,
        }).then((result) => {
          setIsAddToCur(false);
          gridRef.current.api.forEachNode((node) => node.setSelected(false));
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("Report_addToCur_Alert_Failed_Title"),
          text: t("Report_addToCur_Alert_Failed_Text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="report-main">
          <div className="report-filter">
            <section className="header shadow-sm">
              <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t("Report_FilterCriteria")}</div>
            </section>
            <section className="content">
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={t("Report_Deal_SearchByDealProsOrg")}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllTeam")}
                  options={teamList}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(e) => {
                    setTeamID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllUser")}
                  options={userList}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  onChange={(e) => {
                    e ? setUserID(e.map((d) => d.value)) : setUserID("");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllBU")}
                  options={buList}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(e) => {
                    setBuID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllDSCat")}
                  options={dsCatList}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(e) => {
                    setCatDsID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllDS")}
                  options={catDsID ? dsList.filter((opt) => opt.catID === catDsID) : dsList}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  onChange={(e) => {
                    setDsID(e ? e.map((d) => d.value) : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllStage")}
                  options={csList}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  onChange={(e) => {
                    setCsID(e ? e.map((d) => d.value) : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllPipeline")}
                  options={pipeList}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(e) => {
                    setPipeID(e ? e.value : "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t("Report_AllLeadStatus")}
                  options={getAllLdStat}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  onChange={(e) => {
                    setLdStat(e ? e.map((d) => d.value) : "");
                  }}
                />
              </Form.Group>
              <div className="op horizontal-start divider op-text-medium my-3">Custom Field</div>
              <Form.Group className="mb-1">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowActivity")}
                  onChange={(e) => {
                    e.target.checked ? setShowActivity(1) : setShowActivity(0);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowAllField")}
                  onChange={(e) => {
                    e.target.checked ? setShowCF(1) : setShowCF(0);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label={t("Report_ShowProspectContact")}
                  onChange={(e) => {
                    e.target.checked ? setShowProsCtc(1) : setShowProsCtc(0);
                  }}
                />
              </Form.Group>
            </section>
            <section className="footer">
              <div className="d-flex justify-content-center align-items-center w-100">
                <button
                  className="btn op-button op-primary-color text-light shadow me-2"
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                >
                  {t("Report_ViewReport")}
                </button>
                <button className="btn btn-dark" onClick={handleDownload}>
                  {t("Report_Download")}
                </button>
              </div>
            </section>
          </div>
          <div className="report-content">
            <Stack direction="horizontal" gap={2}>
              {isReport && (
                <div className="op-label bg-light shadow-sm">
                  <Form.Check type="checkbox" onClick={handleClick} checked={isChecked} />
                </div>
              )}
              {isRowSelected === true ? (
                <>
                  <Dropdown>
                    <Dropdown.Toggle as={RenderBulkActionToggle}></Dropdown.Toggle>
                    <Dropdown.Menu className="shadow border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                  </Dropdown>
                  <h6 className="op-text-medium mt-2 ms-2">
                    {rowCount} {rowCount > 1 ? "rows" : "row"} {t("Report_Selected")}
                  </h6>
                </>
              ) : null}
              <div className="ms-auto">{Number(recordFound) === 1 ? t("Report_Record_one", { count: recordFound }) : Number(recordFound) > 1 ? t("Report_Record_other", { count: recordFound }) : t("Report_NoRecord")}</div>
            </Stack>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  onSelectionChanged={onReportSelected}
                  columnDefs={reportColumn}
                  datasource={reportDataSource}
                  defaultColDef={reportColDef}
                  rowSelection={"multiple"}
                  rowModelType={"infinite"}
                  rowHeight={50}
                  cacheBlockSize={1000}
                  cacheOverflowSize={2}
                  maxConcurrentDatasourceRequests={1}
                  infiniteInitialRowCount={1}
                  maxBlocksInCache={200}
                  // getRowId={dealRowId}
                  pagination={true}
                  paginationPageSize={1000}
                  paginationPageSizeSelector={false}
                  suppressRowClickSelection={true}
                  animateRows={true}
                />
              </div>
            </div>
          </div>

          <Offcanvas show={isAddToCur} onHide={setIsAddToCur} backdrop="static" placement="end">
            <Formik
              validationSchema={addToCurSchema}
              onSubmit={sendAddToCur}
              initialValues={{
                curation_id: "",
              }}
            >
              {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title as={"h6"}>{t("Report_Add_To_Curation")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <h6 className="op-text-bigger fw-semibold mb-3">
                      {rowCount > 1 ? rowCount + ` ${t("Report_Offcanvas_records")}` : rowCount + ` ${t("Report_Offcanvas_record")}`} {t("Report_Effected")}
                    </h6>
                    <Form.Group>
                      <FormSelect
                        placeholder={t("Report_Offcanvas_SelectCur")}
                        options={curationList}
                        valueDefault={curationList.filter((opt) => opt.value === values.curation_id)}
                        isSearchable={true}
                        isClearable={true}
                        isInvalid={errors.curation_id && touched.curation_id}
                        onChange={(e) => {
                          setFieldValue("curation_id", e ? e.value : "");
                        }}
                      />
                      {errors.curation_id && touched.curation_id && <div className="op-error-message">{errors.curation_id}</div>}
                    </Form.Group>
                  </Offcanvas.Body>
                  <div className="offcanvas-footer">
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("Report_Offcanvas_Footer")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Offcanvas>
        </div>
      )}
    </div>
  );
};

export default ReportDealLost;
