import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Row, Col, Form, Button, Card, Modal, Container } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { FormDate } from "../includes/FormCustom";
import { Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Bar } from 'recharts';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAuth } from "../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUser } from "@fortawesome/pro-duotone-svg-icons";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactDOMServer from 'react-dom/server';
import Loader from "../includes/Loader";
import moment from "moment";
import '../lib/css/AIOutreach.css';
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const AIOutreach = () => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const initialized = useRef(false);
    const { session } = useAuth();
    const cToken = session.company_token;
    const uToken = session.user_ac_token;
    const [blueprint, setblueprint] = useState([{ value: '', label: 'All Blueprint' }]);
    const [selectedBlueprint, setSelectedBlueprint] = useState({ value: '', label: 'All Blueprint' });
    const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'All Status' });
    const [chart, setChart] = useState(null);
    const [chartRes, setChartRes] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showNoDataModal, setShowNoDataModal] = useState(false);
    const [gridKey, setGridKey] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [recordFound, setRecordFound] = useState("")

    const formattedTwoMonthsAgoDate = moment().subtract(2, 'months').format('YYYY-MM-DD');
    const formattedTodayDate = moment().format('YYYY-MM-DD');
    const [dateRange, setdateRange] = useState({
        ds: formattedTwoMonthsAgoDate,
        de: formattedTodayDate,
    });
    const [reportDataSource, setReportDataSource] = useState(null);


    // test

    const [tempDateRange, setTempDateRange] = useState({ ds: '', de: '' });

    const handleDateChange = (key, value) => {
        setTempDateRange(prev => ({ ...prev, [key]: value }));
    };

    // ====================

    const dateFormat = (params) => {
        const dateTime = params.value;
        const providedDate = moment(dateTime);

        if (dateTime === '0000-00-00 00:00:00') {
            return '';
        } else {
            return providedDate.format('DD-MMM-YYYY');
        }

    };

    const actionBtn = (params) => {
        return (
            <FontAwesomeIcon
                icon={faTrash}
                size="lg"
                onClick={() => sendDeleteActivity(params.data.blueprint_deal_id)}
            />
        );
    };

    const sendDeleteActivity = (blueprintDealId) => {
        MySwal.fire({
            title: t("AiOutreach_are_you_sure"),
            text: t("AiOutreach_you_wont_be_able_to_revert_this"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("AiOutreach_yes_delete_it"),
        }).then((result) => {
            if (result.isConfirmed) {
                performDeleteActivity(blueprintDealId);
            }
        });
    };

    const performDeleteActivity = (blueprintDealId) => {
        axios
            .get("/ws/ws_conversation.php", {
                params: {
                    blueprint_deal_id: blueprintDealId,
                    ctoken: cToken,
                    task: 'updateBlueprintDealStatus',
                    utoken: uToken
                },
            })
            .then(() => {
                setGridKey(Math.random());
                MySwal.fire(t("AiOutreach_deleted"), t("AiOutreach_your_record_has_been_deleted"), "success");
            })
            .then(() => {
                // getUpdatedGridData(dateRange, selectedStatus, searchTerm, selectedBlueprint);
                setReportDataSource(fetchReportData(selectedStatus.value, searchTerm, selectedBlueprint.value));
            })
            .catch((error) => {
                console.error("Error deleting record:", error);
                MySwal.fire(t("AiOutreach_error"), t("AiOutreach_an_error_occured_while_deleting_the_record"), "error");
            });
    };

    const userRenderer = (params) => {
        if (params.data && params.data.prospect_name) {
            const prospect = params.data.prospect_name || '';
            const iconHtml = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faUser} size="lg" className="px-2" />);

            const typeContent = `
                <div style="white-space: normal; line-height: 1.2;">
                    ${iconHtml}${prospect}
                </div>
            `;

            return <div dangerouslySetInnerHTML={{ __html: typeContent }} />;
        } else {
            return '';
        }
    };

    // GET BLUEPRINT OPTION
    const getBlueprint = async () => {
        try {
            const response = await axios.get("ws/ws_ai_campaign.php", {
                params: {
                    ctoken: cToken,
                    task: 'getBlueprintListing',
                    utoken: uToken
                },
            });

            const data = response.data;

            const blueprints = data.record.map((blueprint) => ({
                value: blueprint.blueprint_id,
                label: blueprint.title
            }));

            setblueprint([{ value: '', label: 'All Blueprint' }, ...blueprints]);
        } catch (error) {
            console.error("Error fetching blueprint data:", error);
        }
    };

    // GET CHART
    const getChart = async () => {
        try {
            const response = await axios.get("ws/ws_conversation.php", {
                params: {
                    blueprint_id: '',
                    ctoken: cToken,
                    date_range1: dateRange.ds,
                    date_range2: dateRange.de,
                    task: 'getBlueprintStatisticV2',
                    utoken: uToken,
                },
            });

            const data = response.data.record;
            setChart(data);
            setChartRes(response.data);
        } catch (error) {
            console.error("Error fetching monthly deal data:", error);
            setChart({});
            setChartRes({});
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };

    const getUpdatedChart = async (dateRange = {}, blueprint) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;
        const blueprintValue = blueprint.value;

        try {
            const response = await axios.get("ws/ws_conversation.php", {
                params: {
                    blueprint_id: blueprintValue,
                    ctoken: cToken,
                    date_range1: startDate,
                    date_range2: endDate,
                    task: 'getBlueprintStatisticV2',
                    utoken: uToken,
                },
            });

            const data = response.data.record;
            setChart(data);
            setChartRes(response.data);
            setLoading2(false);
        } catch (error) {
            console.error("Error fetching monthly deal data:", error);
            setChart({});
            setChartRes({});
            setLoading2(false);
        }
    };

    // GET GRID DATA
    const getGridData = async () => {
        try {
            const response = await axios.get("ws/ws_conversation.php", {
                params: {
                    task: 'getBlueprintDealListPaginationV2',
                    utoken: uToken,
                    ctoken: cToken,
                    date_range1: dateRange.ds,
                    date_range2: dateRange.de,
                    status: '',
                    searchStr: '',
                    blueprint_id: '',
                    startRow: '0'
                },
            });

            const data = response.data.record;
            if (data) {
                setGridData(data);
            } else {
                setGridData([]);
            }

        } catch (error) {
            console.error("Error fetching grid data:", error);
        }
    }

    const getUpdatedGridData = (dateRange = {}, status, searchTerm, blueprint) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;
        const statusValue = status.value;
        const blueprintValue = blueprint.value;


        try {
            axios
                .get("ws/ws_conversation.php", {
                    params: {
                        task: 'getBlueprintDealListPaginationV2',
                        utoken: uToken,
                        ctoken: cToken,
                        date_range1: startDate,
                        date_range2: endDate,
                        status: statusValue,
                        searchStr: searchTerm,
                        blueprint_id: blueprintValue,
                        startRow: '0'
                    },
                })
                .then((response) => {
                    setGridData(response.data.record);
                    setLoading2(false);
                });
        } catch (error) {
            console.error("Error fetching grid data:", error);
            setLoading2(false);
        }
    }

    const statusCellRenderer = (params) => {
        const statusMap = {
            'invalid_num': 'Invalid Num',
            'ended': 'Ended',
            'qualified': 'Qualified',
            'active': 'Active',
            'dropped': 'Dropped',
            'ended_transfered_salesrep': 'Salesrep Transfered',
            'ended_transfered_admin': 'Admin Transfered',
            'ended_qualified': 'Tele Qualified',
        };

        return statusMap[params.value] || params.value;
    };

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridColumn = [
        {
            headerName: t("AiOutreach_lead_capture_date"),
            field: "deal_system_date_time",
            // cellRenderer: dateFormat, 
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                    const dateTime = params.data.date_time_create;
                    const providedDate = moment(dateTime);
                    const getDate = providedDate.format('DD-MMM-YYYY');

                    return (
                        <div className="d-flex">
                            <span>{getDate}</span>
                        </div>
                    );
                } else {
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            }
        },
        {
            headerName: t("AiOutreach_prospect_name"),
            field: "prospect_name",
            cellRenderer: userRenderer,
            onCellClicked: function (params) {
                if (params.data) {
                    sessionStorage.setItem('op-session_conversation', JSON.stringify({
                        user_contact: params.data.user_contact,
                        prospect_name: params.data.prospect_name,
                        chat_instance_id: params.data.chat_instance_id
                    }));

                    window.open(`${session.origin}/conversation`, "_blank");

                    sessionStorage.removeItem('conversationParams');
                }
            }
        },
        { headerName: t("AiOutreach_opportunity"), field: "deal_id" },
        { headerName: t("AiOutreach_contact_number"), field: "contact1" },
        { headerName: t("AiOutreach_status"), field: "status", cellRenderer: statusCellRenderer },
        { headerName: t("AiOutreach_deal_source"), field: "ds_title" },
        { headerName: t("AiOutreach_date_qualified"), field: "date_qualified", cellRenderer: dateFormat },
        { headerName: t("AiOutreach_date_dropped"), field: "date_dropped", cellRenderer: dateFormat },
        { headerName: t("AiOutreach_date_ended"), field: "date_ended", cellRenderer: dateFormat },
        { headerName: t("AiOutreach_action"), field: "", cellRenderer: actionBtn, cellStyle: { cursor: 'pointer' } },
    ];

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const customStatusOptions = [
        { value: '', label: t("AiOutreach_all_status") },
        { value: 'active', label: t("AiOutreach_active") },
        { value: 'responded', label: t("AiOutreach_responded") },
        { value: 'ended', label: t("AiOutreach_ended") },
        { value: 'qualified', label: t("AiOutreach_qualified") },
        { value: 'dropped', label: t("AiOutreach_dropped") },
        { value: 'meeting_scheduled', label: t("AiOutreach_meeting_scheduled") },
        { value: 'meeting_attended', label: t("AiOutreach_meeting_attended") },
    ];

    const dataForChart = [
        {
            label: t("AiOutreach_total_leads"),
            // value: parseInt(chart ? chart.total_leads : 0) - parseInt(chart ? chart.total_meeting_scheduled : 0),
            value: parseInt(chartRes ? chartRes.overallTotalLead : 0),
            color: "#5bc0de"
        },
        { label: t("AiOutreach_in_progress"), value: parseInt(chart ? chart.total_active : ''), color: "#f0ad4e" },
        { label: t("AiOutreach_responded"), value: parseInt(chart ? chart.total_responded : ''), color: "#36A2EB" },
        { label: t("AiOutreach_ended"), value: parseInt(chart ? chart.total_ended : ''), color: "#d9534f" },
        { label: t("AiOutreach_tele_qualified"), value: parseInt(chart ? chart.total_ended_qualified : ''), color: "#f48d8a" },
        { label: t("AiOutreach_qualified"), value: parseInt(chart ? chart.total_qualified : ''), color: "#5cb85c" },
        {
            label: t("AiOutreach_contacted"),
            value: parseInt(chart ? chart.total_qualified_contacted : 0) + parseInt(chart ? chart.total_ended_qualified_contacted : 0),
            color: "#94dc94"
        },
        { label: t("AiOutreach_dropped"), value: parseInt(chart ? chart.total_dropped : ''), color: "#6D6E6D" },
        { label: t("AiOutreach_invalid_number"), value: parseInt(chart ? chart.total_invalid_num : ''), color: "#BEBEBE" },
        { label: t("AiOutreach_transferred_to_srm"), value: parseInt(chart ? chart.total_ended_transfered_admin : ''), color: "#D9456E" },
        { label: t("AiOutreach_transferred_to_sr"), value: parseInt(chart ? chart.total_ended_transfered_salesrep : ''), color: "#E394AA" },
        { label: t("AiOutreach_total_meeting_scheduled"), value: parseInt(chart ? chart.total_meeting_scheduled : ''), color: "#65A847" },
        { label: t("AiOutreach_total_meeting_attended"), value: parseInt(chart ? chart.total_meeting_attended : ''), color: "#D6B133" },
    ];

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getBlueprint();
            getChart();
            getGridData();

            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleApplyButtonClick = () => {
        const dateRangeValues = {
            ds: dateRange.ds,
            de: dateRange.de,
        };

        setLoading2(true);
        getUpdatedChart(dateRangeValues, selectedBlueprint);
        getUpdatedGridData(dateRangeValues, selectedStatus, searchTerm, selectedBlueprint);

        setReportDataSource(fetchReportData(selectedStatus.value, searchTerm, selectedBlueprint.value));
    }

    const fetchReportData = useCallback((status, searchterm, blueprint) => {
        return {
            rowCount: undefined,
            getRows: (params) => {
                axios.get("ws/ws_conversation.php", {
                    params: {
                        task: 'getBlueprintDealListPaginationV2',
                        utoken: uToken,
                        ctoken: cToken,
                        date_range1: dateRange.ds,
                        date_range2: dateRange.de,
                        status: status || '',
                        searchStr: searchterm || '',
                        blueprint_id: blueprint || '',
                        startRow: params.startRow
                    },
                })
                    .then(res => {
                        let data = res.data;
                        if (Number(data.status) === 0) {
                            if (Number(data.totalRecord) > 0) {
                                setIsReport(true);
                            } else {
                                setIsReport(false);
                            }
                        } else {
                            setIsReport(false);

                            Swal.fire({
                                icon: "error",
                                title: "Ooops",
                                text: "There aren't any data available for your chosen Date Range or Filters. Try tweaking your Date Range & Filters"
                            });
                        }

                        setTimeout(() => {
                            var dealInfo = [];
                            var totalRecord = 0;
                            if (Number(data.status) === 0) {
                                dealInfo = data.record;
                                totalRecord = Number(data.totalRecord);
                                setRecordFound(totalRecord);
                            } else {
                                dealInfo = [];
                                totalRecord = 0;
                                setRecordFound(totalRecord);
                            }

                            var rowsThisPage = dealInfo;
                            var lastRow = -1;

                            if (Number(totalRecord) <= params.endRow) {
                                lastRow = totalRecord;
                            }

                            params.successCallback(rowsThisPage, lastRow);
                        }, 500);
                    });
            }
        };
    }, [dateRange, session, uToken, cToken, selectedStatus, searchTerm, selectedBlueprint]);


    // In useEffect or other places where you set reportDataSource

    // useEffect(() => {
    //     if (!selectedStatus.value && !selectedBlueprint.value && !searchTerm) {
    //         const reportData = fetchReportData(selectedStatus.value, searchTerm, selectedBlueprint.value);
    //         setReportDataSource(reportData);
    //     }
    // }, [fetchReportData, selectedStatus, searchTerm, selectedBlueprint]);

    useEffect(() => {
        if (!selectedStatus.value && !selectedBlueprint.value && !searchTerm) {
            const reportData = fetchReportData(selectedStatus.value, searchTerm, selectedBlueprint.value);
            setReportDataSource(reportData);
        }
    }, [selectedStatus, searchTerm, selectedBlueprint]);

    const handleDownload = () => {
        var params = {
            fileName: "AI-outreach.csv",
            processCellCallback: function (params) {
                if (params.column.colId === "ds_title") {
                    return params.node.data.ds_title;
                } else {
                    var res = params.value;
                    if (res !== undefined && res !== null) {
                        res = String(res).replace(/<br>/g, "\r\n")
                            .replace(/<li>/g, "\r\n")
                            .replace(/<[^>]+>/g, "")
                            .replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="py-3 px-5 container-fluid font-family" style={{ height: '90vh', overflow: 'auto' }}>
                    <div className="d-flex justify-content-between" id="filter">
                        <Row>
                            <div className="px-1" style={{ width: '180px' }}>
                                <Form.Group as={Col}>
                                    <FormSelect
                                        options={customStatusOptions}
                                        value={selectedStatus || { value: '', label: t("AiOutreach_all_status") }}
                                        onChange={selectedOption => {
                                            setSelectedStatus(selectedOption);
                                        }}
                                        placeholder={t("AiOutreach_all_status")}
                                        border={false}
                                        shadow={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1" style={{ width: '180px' }}>
                                <Form.Group as={Col}>
                                    <FormSelect
                                        options={blueprint}
                                        value={selectedBlueprint}
                                        onChange={selectedOption => {
                                            setSelectedBlueprint(selectedOption);
                                        }}
                                        placeholder={t("AiOutreach_all_blueprint")}
                                        border={false}
                                        shadow={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1" style={{ width: '180px' }}>
                                <Form.Control type="text" className="me-1 shadow-sm" placeholder={t("AiOutreach_search")} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </Row>

                        <div className="text-family float-end mb-4" id="date">
                            <Form.Group className="d-flex">
                                <Form.Group className="me-2">
                                    <Form.Control
                                        type="date"
                                        placeholder={t("AiOutreach_from")}
                                        className="shadow-sm border-0"
                                        value={moment(dateRange.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="me-2">
                                    <Form.Control
                                        type="date"
                                        placeholder={t("AiOutreach_to")}
                                        className="shadow-sm border-0"
                                        value={moment(dateRange.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                        }}
                                    />
                                </Form.Group>
                                <Button variant="primary" className="btn shadow-sm" onClick={handleApplyButtonClick}>{t("AiOutreach_apply")}</Button>
                            </Form.Group>
                        </div>
                    </div>

                    {loading2 ? (
                        <Loader />
                    ) : (
                        <>
                            <div>
                                <Row>
                                    <Col xl={7}>
                                        <Card className="border-0 shadow-sm">
                                            <Card.Header>
                                                <div className="font h6 col-lg-10">{t("AiOutreach_chat_condition")}</div>
                                            </Card.Header>
                                            <Card.Body>
                                                <ResponsiveContainer width="100%" height={400}>
                                                    {chart && Object.keys(chart).length > 0 ? (
                                                        <BarChart data={dataForChart} margin={{ bottom: 80 }}>
                                                            <XAxis dataKey="label" interval={0} angle={315} textAnchor="end" />
                                                            <YAxis type="number" />
                                                            <Tooltip
                                                                formatter={(value, name, props) => {
                                                                    const { payload } = props;
                                                                    return [value, payload.label];
                                                                }}
                                                            />
                                                            <Bar dataKey="value" fill="#8884d8">
                                                                {dataForChart.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </ResponsiveContainer>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col xl={5}>
                                        <Row className="mb-3">
                                            <Col xl={6}>
                                                <Card className="border-0 shadow-sm w-100">
                                                    <Card.Header className="h6 text-center py-3">
                                                        {t("AiOutreach_response_rate")}
                                                    </Card.Header>
                                                    <Card.Body className="text-center align-middle my-3" style={{ fontSize: '30px', fontWeight: '700' }}>
                                                        {chart && chart.total_reply !== undefined && chart.total_leads !== undefined && chart.total_leads - chart.total_invalid_num !== 0 ? (
                                                            `${((chart.total_reply / (chart.total_leads - chart.total_invalid_num)) * 100).toFixed(1)}%`
                                                        ) : (
                                                            '0.0%'
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={6}>
                                                <Card className="border-0 shadow-sm w-100">
                                                    <Card.Header className="h6 text-center py-3">
                                                        {t("AiOutreach_qualification_rate")}
                                                    </Card.Header>
                                                    <Card.Body className="text-center align-middle my-3" style={{ fontSize: '30px', fontWeight: '700' }}>
                                                        {(() => {
                                                            if (chart) {
                                                                const qualifiedCount = parseInt(chart.total_qualified) + parseInt(chart.total_ended_qualified);
                                                                const totalLeads = parseInt(chart.total_leads);
                                                                const totalInvalidNum = parseInt(chart.total_invalid_num);

                                                                if (Number.isFinite(qualifiedCount) && Number.isFinite(totalLeads) && totalLeads - totalInvalidNum !== 0) {
                                                                    const qualificationRate = (qualifiedCount / (totalLeads - totalInvalidNum)) * 100;
                                                                    return `${qualificationRate.toFixed(1)}%`;
                                                                } else {
                                                                    return '0.0%';
                                                                }
                                                            } else {
                                                                return '0.0%';
                                                            }
                                                        })()}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={6}>
                                                <Card className="border-0 shadow-sm w-100">
                                                    <Card.Header className="h6 text-center py-3">
                                                        {t("AiOutreach_total_reply")}
                                                    </Card.Header>
                                                    <Card.Body className="text-center align-middle my-3" style={{ fontSize: '30px', fontWeight: '700' }}>
                                                        {chart ? chart.total_reply : '0'}
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={6}>
                                                <Card className="border-0 shadow-sm w-100">
                                                    <Card.Header className="h6 text-center py-3">
                                                        {t("AiOutreach_total_leads")}
                                                    </Card.Header>
                                                    <Card.Body className="text-center align-middle my-3" style={{ fontSize: '30px', fontWeight: '700' }}>
                                                        {/* {chart ? chart.total_leads : '0'} */}
                                                        {/* {chart ? (chart.total_leads - chart.total_meeting_scheduled - chart.ended) : '0'} */}
                                                        {/* {chart 
                                                        ? ((chart.total_leads || 0) - (chart.total_meeting_scheduled || 0) - (chart.total_ended_qualified || 0)) 
                                                        : '0'} */}
                                                        {chartRes ? chartRes.overallTotalLead : '0'}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-5">
                                <div className="h6" style={{ fontWeight: '700' }}>{t("AiOutreach_list_of_conversation_messages")}</div>
                                <div className="d-flex justify-content-between">
                                    <div className="h7" style={{ fontSize: '14px', lineHeight: '1.4285em', fontWeight: '700' }}>
                                        {t("AiOutreach_result", { length: recordFound })}
                                    </div>
                                    <div>
                                        <button className="btn btn-dark" onClick={handleDownload} disabled={recordFound === 0}>
                                            {t("AiOutreach_download")}
                                        </button>
                                    </div>
                                </div>
                                <Col xxl={12}>
                                    <div style={containerStyle}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                key={gridKey}
                                                columnDefs={gridColumn}
                                                datasource={reportDataSource}
                                                rowModelType={"infinite"}
                                                rowHeight={70}
                                                maxBlocksInCache={10}
                                                animateRows={true}
                                                cacheBlockSize={200}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </>
                    )}

                    {/* Modal for No Data Found */}
                    <Modal show={showNoDataModal} onHide={() => setShowNoDataModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("AiOutreach_no_data_found")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {t("AiOutreach_oops_there_isnt_any_data_available_for_your_chosen_date_range_or_filters")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowNoDataModal(false)}>
                                {t("AiOutreach_close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div >
            )}
        </Container>
    );
}

export default AIOutreach;
