import { useState, useRef, useEffect } from "react";
import { Container, Form, Spinner, Stack, ListGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { FormSelect } from "../includes/FormCustom";

const UserRedirection = () => {
  const init = useRef(false);
  const { session } = useAuth();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");

  // GET FUNCTION ----------------------------------------------------------------

  const getUsers = async () => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "get_user_login_redirection",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setUserList(data.record);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // HANDLE FUNCTION -----------------------------------------------------------

  const handleChange = async (values) => {
    try {
      await axios.get("ws/ws_permission.php", {
        params: {
          task: "update_user_redirection",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user_id: values.user_id,
          load_screen: values.load_screen,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const init_ready = async () => {
      if (!init.current) {
        try {
          await getUsers();
          init.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    };

    init_ready();
  }, [init.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="px-0">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-3 mb-3">
            <Stack direction="horizontal" gap={1} className="mb-2">
              <Form.Group className="ms-auto" style={{ width: 250 }}>
                <Form.Control type="text" placeholder="Search user" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} />
              </Form.Group>
            </Stack>
            <ListGroup>
              {userList
                .filter((user) => user.user_name.toLowerCase().includes(searchVal.toLowerCase()))
                .map((user) => (
                  <ListGroup.Item className="mb-2 rounded border d-flex justify-content-between align-items-center" key={user.user_id}>
                    <span>{user.user_name}</span>
                    <FormSelect
                      valueDefault={[
                        { label: "Deal", value: "deal" },
                        { label: "Contact", value: "contacts" },
                        Number(session.company_type_id) !== 2 && { label: "Organization", value: "organization" },
                        { label: "Activity", value: "activity" },
                        { label: "Curation", value: "curation" },
                        { label: "Booking", value: "booking" },
                        Number(session.role_id) === 1 && { label: "Report", value: "report" },
                        Number(session.role_id) === 1 && { label: "Statistics", value: "statistics" },
                      ]
                        .filter(Boolean)
                        .find((item) => (user.auto_load_screen === "" ? item.value === "deal" : item.value === user.auto_load_screen))}
                      options={[
                        { label: "Deal", value: "deal" },
                        { label: "Contact", value: "contacts" },
                        Number(session.company_type_id) !== 2 && { label: "Organization", value: "organization" },
                        { label: "Activity", value: "activity" },
                        { label: "Curation", value: "curation" },
                        { label: "Booking", value: "booking" },
                        Number(user.role_id) === 1 && { label: "Report", value: "report" },
                        Number(user.role_id) === 1 && { label: "Statistics", value: "statistics" },
                      ].filter(Boolean)}
                      onChange={(e) => handleChange({ user_id: user.user_id, load_screen: e.value })}
                      width="250px"
                    />
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        </div>
      )}
    </Container>
  );
};

export default UserRedirection;
