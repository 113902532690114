import { useState, useEffect, useRef, forwardRef } from "react";
import { useAuth } from "../auth/AuthContext";
import { Container, Form, Stack, InputGroup, Dropdown, CloseButton, Card, OverlayTrigger, Tooltip, Modal, Spinner, ButtonGroup, Button, Image, Row, Col, Badge, DropdownDivider } from "react-bootstrap";
import Loader from "../includes/Loader";
import "../lib/scss/conversation.scss";
import { FormSelect } from "../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faClock,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileDoc,
  faFileExcel,
  faFileGif,
  faFileImage,
  faFileJpg,
  faFileMov,
  faFileMp3,
  faFilePdf,
  faFilePng,
  faFilePowerpoint,
  faFileSvg,
  faFileText,
  faFileVector,
  faFileVideo,
  faFileXml,
  faFileZip,
  faMagnifyingGlass,
  faRotateRight,
  faX,
} from "@fortawesome/pro-light-svg-icons";
import { faCircleExclamation, faPaperPlaneTop, faBullhorn, faPaperclip, faXmark, faBarsFilter, faChevronDown, faImage as faImageSolid, faFiles } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faEnvelope, faFile, faPeopleArrows, faPhone, faUser, faUserLock, faUserPlus, faUserTie, faUserUnlock, faImage, faLink } from "@fortawesome/pro-light-svg-icons";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import moment from "moment";
import NoRecord from "../includes/NoRecord";
import Swal from "sweetalert2/dist/sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
import { io } from "socket.io-client";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import WSBackground from "../assets/ws_background.png";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FsLightbox from "fslightbox-react";
const Conversation = () => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginFileValidateType);
  const { session } = useAuth();
  const { chat_instance_id } = useParams();
  const init = useRef(false);
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [instanceList, setInstanceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [instanceData, setInstanceData] = useState({});
  const [instanceData2, setInstanceData2] = useState({});
  const [prospectSearchData, setProspectSearchData] = useState("");
  const [prospectSearchData2, setProspectSearchData2] = useState({});
  const [prospectOwnerData, setProspectOwnerData] = useState("");
  const [prospectList, setProspectList] = useState([]);
  const [prospectData, setProspectData] = useState({});
  const [isProspect, setIsProspect] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isProspectFilter, setIsProspectFilter] = useState(0);
  const [messagesData, setMessagesData] = useState({});
  const [messagesList, setMessagesList] = useState(null);
  const [isSendFile, setIsSendFile] = useState(false);
  const [isSendFileLoading, setIsSendFileLoading] = useState(false);
  const [messageAction, setMessageAction] = useState({
    file: false,
    broadcast: false,
    input: false,
    inputPlaceholder: "Write a message",
    send: false,
  });
  const [blueprintAction, setBlueprintAction] = useState({
    addDealShow: true,
    addDealDisabled: false,
    qualifyLeadShow: true,
    qualifyLeadDisabled: false,
    dropLeadShow: true,
    dropLeadDisabled: false,
    transferAdminShow: true,
    transferAdminDisabled: false,
    transferRAShow: true,
    transferRADisabled: false,
    lockConversation: false,
  });

  const [lockConversation, setLockConversation] = useState(false);
  const [isBroadcast, setIsBroadcast] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [inputData, setInputData] = useState("");
  const [lastConversationId, setLastConversationId] = useState([]);
  const [isAddDeal, setIsAddDeal] = useState(false);
  const [addDealUser, setAddDealUser] = useState([]);
  const [isQualifyLead, setIsQualifyLead] = useState(false);
  const [lostReasonList, setLostReasonList] = useState([]);
  const [isDropLead, setIsDropLead] = useState(false);
  const [sessionRecord, setSessionRecord] = useState(null);
  const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: "AIzaSyACc3wiPr3UpBXlQuZ4i1BOGEIvpAUY1TY" });
  const [chatbotData, setChatbotData] = useState([]);
  const [chatbotDataContact, setChatbotDataContact] = useState([]);
  const [isSendBroadcastLoading, setIsSendBroadcastLoading] = useState(false);
  const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [imgUrl, setImgUrl] = useState([]);
  const [isSendQualifyLeadLoading, setIsSendQualifyLeadLoading] = useState(false);
  const [isSendDropLeadLoading, setIsSendDropLeadLoading] = useState(false);

  const containerRef = useRef(null);
  const sendFileSchema = yup.object().shape({
    file: yup.array().min(1, "File is required"),
  });

  const addDealSchema = yup.object().shape({
    name: yup.string().required("Prospect name is required"),
    contact_num: yup.string().required("Phone number is required"),
    user_id: yup.string().required("Owner is required"),
  });

  const dropLeadSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
  });

  // GET FUNCTION ----------------------------------------------------------------

  const getChatInstance = async () => {
    try {
      const { data } = await axios.get("ws/ws_ai_campaign.php", {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: session.company_id,
        },
      });

      if (data.status === 0) {
        let sessionData = null;
        let sessionRecordData = null;

        var prospectSearch = "";
        const sessionRecord = sessionStorage.getItem("op-session_conversation");
        const sessionRecord2 = sessionStorage.getItem("op-session_chatbot_conversation");
        if (sessionRecord) {
          sessionData = JSON.parse(sessionRecord);
          prospectSearch = sessionData.prospect_name;
          setProspectSearchData(sessionData.prospect_name);
          setSessionRecord(sessionData);
        }

        if (sessionRecord2) {
          sessionRecordData = JSON.parse(sessionRecord2);
          getMoreProspect(sessionRecordData.prospect_name);
          setChatbotData(sessionRecordData);
          setProspectSearchData(sessionRecordData.prospect_name);
          setChatbotDataContact(sessionRecordData.chat_contact_id);
        }

        const recordOptions = data.record.map((record) => ({
          ...record,
          label: `${record.instance_title} - ${record.user_number}`,
          value: record.chat_instance_id,
        }));

        const selectedInstance = sessionData ? recordOptions.find((record) => record.value === sessionData?.chat_instance_id) : chat_instance_id ? recordOptions.find((record) => record.value === chat_instance_id) : recordOptions[0];

        const selectedInstance2 = sessionRecordData ? recordOptions.find((record) => record.value === sessionRecordData?.chat_instance_id) : chat_instance_id ? recordOptions.find((record) => record.value === chat_instance_id) : recordOptions[0];

        setInstanceData(selectedInstance);
        setInstanceData2(selectedInstance2);
        setInstanceList(recordOptions);
        await getProspect(prospectSearch, "", selectedInstance || recordOptions[0], sessionData, sessionRecord2, selectedInstance2 || recordOptions[0]);
      }
    } catch (error) {
      console.error("Failed to fetch chat instance data:", error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        const recordOptions = data.record.filter((record) => Number(record.permission_id) === 1).map((record) => ({ label: record.user_name, value: record.user_id }));
        const recordOptions2 = data.record.filter((record) => Number(record.permission_id) === 1).map((record) => ({ label: record.user_name, value: record.user_id }));
        recordOptions2.unshift({ label: "Select a sales rep", value: "" });
        setAddDealUser(recordOptions2);
        setUserList(recordOptions);
      }
    } catch (error) {}
  };

  const getProspect = async (searchStr, owner, instance, sessionData = null, sessionRecord2, instance2) => {
    let sessionRecordData = null;

    if (sessionRecord2) {
      sessionRecordData = JSON.parse(sessionRecord2);
      getMoreProspect(sessionRecordData.prospect_name);
      setChatbotData(sessionRecordData);
      setProspectSearchData(sessionRecordData.prospect_name);
      setChatbotDataContact(sessionRecordData.chat_contact_id);
    }

    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          searchStr: sessionData?.prospect_name || searchStr || sessionRecordData?.prospect_name || "",
          startRow: 0,
          // chat_instance_id: sessionRecordData
          //   ? (instanceData2.value || instance2.value || instance.value)
          //   : (instanceData2.value || instance.value),
          // instance_id: sessionRecordData
          //   ? (instanceData2.instance_id || instance2.instance_id || instance.instance_id)
          //   : (instanceData2.instance_id || instance.instance_id),
          chat_instance_id: chatbotData?.from === "chatbot-dashboard" ? instanceData2.value : sessionRecordData ? instance2.value || instance.value : instance.value,
          instance_id: chatbotData?.from === "chatbot-dashboard" ? instanceData2.instance_id : sessionRecordData ? instance2.instance_id || instance.instance_id : instance.instance_id,
          filter_user: owner ? owner : "",
        },
      });

      const data = response.data;

      if (data.status === 0) {
        data.record.forEach((record, index) => {
          record["index"] = index;
        });

        setProspectList(data.record);
        getMessages(data.record[0], 0, data.record, sessionData, sessionRecordData);
        if (data.totalRecord > data.record.length) {
          setIsLoadMore(true);
        } else {
          setIsLoadMore(false);
        }
      } else {
        setProspectList([]);
        setProspectData({});
        setIsLoadMore(false);
        setMessagesList(null);
        // getMessages({}, 0);
      }
    } catch (error) {
      setProspectList([]);
      setProspectData({});
      setIsLoadMore(false);
      getMessages({}, 0);
    }
  };

  const getMoreProspect = () => {
    var startRow = prospectList.length + 1;
    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "getConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          searchStr: prospectSearchData ? prospectSearchData : "",
          startRow: startRow,
          chat_instance_id: instanceData2.value || instanceData.value,
          instance_id: instanceData2.instance_id || instanceData.instance_id,
          filter_user: prospectOwnerData ? prospectOwnerData : "",
        },
      })
      .then((response) => {
        const data = response.data;

        if (data.status === 0) {
          setProspectList((prevState) => [...prevState, ...data.record]);
          if (prospectList.length + data.record.length + 1 >= data.totalRecord) {
            setIsLoadMore(false);
          }
        }
      });
  };

  const getMessages = (record, index, prospect, sessionData = null, sessionRecordData) => {
    setIsProspect(index);
    setProspectData(record);

    var chatId = record.chat_id;
    // chatId = chatId.substring(1);
    // chatId = chatId.replace("@c.us", "");

    if (chatId) {
      chatId = chatId.substring(1); // Remove the leading character
      chatId = chatId.replace("@c.us", ""); // Remove "@c.us" suffix
    }

    if (init.current && prospect) {
      prospect[index].unread = 0;
      prospect[index].require_attention = 0;
      setProspectList(prospect);
    }

    axios
      .get("ws/ws_conversation.php", {
        params: {
          // task: "getConversationMessagesV2",
          // utoken: session.user_ac_token,
          // ctoken: session.company_token,
          // chat_id: sessionData?.chat_instance_id ? sessionData.chat_instance_id : chatId,
          // prospect_id: sessionData?.prospect_id ? sessionData.prospect_id : record.prospect_id,
          // startRow: 0,
          // chat_contact_id: sessionData?.chat_contact_id ? sessionData.chat_contact_id : record.chat_contact_id,
          task: "getConversationMessagesV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: sessionData?.chat_instance_id || chatId,
          prospect_id: sessionData?.prospect_id || record.prospect_id,
          // chat_id: sessionData?.chat_instance_id || sessionRecordData?.chat_instance_id || chatId,
          // prospect_id: sessionData?.prospect_id || sessionRecordData?.prospect_id || record.prospect_id,
          startRow: 0,
          chat_contact_id: sessionData?.chat_contact_id || record.chat_contact_id,
          // chat_contact_id: sessionData?.chat_contact_id || sessionRecordData?.chat_contact_id || record.chat_contact_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setMessagesData(data);

        if (data.status === 0) {
          let message = {};
          data.record = data.record.slice().reverse();
          data.record.forEach((input) => {
            var newDate = new Date(input.date_time_create);
            var momentDate = moment(newDate).format("YYYY-MM-DD");
            input.date = momentDate;

            if (message[input.date]) {
              if (input.direction === "inbound") {
                input.firstchild = message[input.date][message[input.date].length - 1].direction === "inbound" ? false : true;
              } else {
                input.firstchild = message[input.date][message[input.date].length - 1].direction === "outbound" ? false : true;
              }
              message[input.date].push(input);
            } else {
              input.firstchild = true;
              message[input.date] = [input];
            }
          });

          setMessagesList(message);
          setLastConversationId((prevIds) => [...prevIds, data.record[data.record.length - 1].chat_conversation_id]);
          onchangeMessageAction(data.record);
        } else {
          setMessagesList(null);
          onchangeMessageAction([]);
        }

        const status = {
          addDealShow: true,
          addDealDisabled: false,
          qualifyLeadShow: true,
          qualifyLeadDisabled: false,
          dropLeadShow: true,
          dropLeadDisabled: false,
          transferAdminShow: true,
          transferAdminDisabled: false,
          transferRAShow: true,
          transferRADisabled: false,
          unlock_conversation: false,
        };

        if (data.blueprintStatus === 0) {
          if (data.blueprintRecord.status === "responded") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "qualified") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "dropped") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = instanceData.admin_transfer_button === 1 ? true : false;
            status.transferAdminDisabled = false;
            status.transferRAShow = true;
            status.transferRADisabled = false;
          } else if (data.blueprintRecord.status === "ended_transfered_admin") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended_transfered_salesrep") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended_qualified") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "active") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "invalid_num") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = false;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = false;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          }
        } else {
          status.addDealShow = true;
          status.addDealDisabled = false;
          status.qualifyLeadShow = false;
          status.qualifyLeadDisabled = true;
          status.dropLeadShow = false;
          status.dropLeadDisabled = true;
          status.transferAdminShow = false;
          status.transferAdminDisabled = true;
          status.transferRAShow = false;
          status.transferRADisabled = true;
        }

        setBlueprintAction(status);
      });

    sessionStorage.removeItem("conversationParams");
    sessionStorage.removeItem("op-session_chatbot_conversation");
  };

  const getRefreshMessages = (message) => {
    axios
      .get(`ws/ws_conversation.php`, {
        params: {
          task: "getWAMediaFile",
          company_id: session.company_id,
          chat_instance_id: instanceData.instance_id,
          media_id: message.media_id,
          conversation_id: message.chat_conversation_id,
          mime_type: message.media_mime_type,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getMessages(prospectData, isProspect, prospectList);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Cannot refresh it, Please try again!",
          });
        }
      });
  };

  const getTemplate = async () => {
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getWATemplateListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          record.label = record.whatsapp_template_name;
          record.value = record.whatsapp_template_name;
        });

        setTemplateList(data.record);
      } else {
        setTemplateList([]);
      }
    } catch {
      setTemplateList([]);
    }
  };

  const getLiveContact = async () => {
    let latestConversationId = setMax(lastConversationId);
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getLatestConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_conversation_id: latestConversationId,
          chat_contact_id: prospectData.chat_contact_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        let currentContact = [...prospectList];
        data.record
          .sort((a, b) => new Date(b.last_update) - new Date(a.last_update))
          .forEach((record) => {
            if (Number(record.chat_contact_id) === Number(prospectData.chat_contact_id)) {
              getIncomingMessage(false);
            }

            const contactIndex = prospectList.findIndex((val) => Number(val.chat_contact_id) === Number(record.chat_contact_id));
            if (contactIndex !== -1) {
              currentContact[contactIndex] = {
                ...currentContact[contactIndex],
                unread: 1,
                require_attention: 0,
                last_update: record.last_update,
                last_message: record.last_message,
              };

              const [updatedContact] = currentContact.splice(contactIndex, 1);
              currentContact = [updatedContact, ...currentContact];
            } else {
              currentContact.unshift({ ...record, unread: 1 });
            }
          });

        const currentContactIndex = currentContact.findIndex((val) => Number(val.chat_contact_id) === Number(prospectData.chat_contact_id));
        setProspectList(currentContact.map((record, index) => ({ ...record, index })));
        setIsProspect(currentContactIndex);
      } else {
        getIncomingMessage(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIncomingMessage = async (prospectUpdate = false) => {
    try {
      const chat_id = prospectData.chat_id.substring(1).replace("@c.us", "");
      const lastDate = messagesData.record[messagesData.record.length - 1].date || "0000-00-00";

      const today = moment(new Date()).format("YYYY-MM-DD");
      const timeLastDate = new Date(lastDate);
      const timeTodayDate = new Date(today);
      var latestChatConversationID = null;

      if (timeLastDate.getTime() !== timeTodayDate.getTime()) {
        const lastIndex = messagesList[lastDate].length - 1;
        latestChatConversationID = messagesList[lastDate][lastIndex].chat_conversation_id;
        setLastConversationId((prevIds) => [...prevIds, latestChatConversationID]);
      } else {
        const todayIndex = messagesList[today].length - 1;
        if (todayIndex === -1 || !messagesList[today]) {
          setMessagesList((prev) => ({ ...prev, [today]: [] }));
        }
        latestChatConversationID = messagesList[today][todayIndex].chat_conversation_id;
        setLastConversationId((prevIds) => [...prevIds, latestChatConversationID]);
      }

      var latestChatConversationId = setMax(lastConversationId);
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getConversationMessagesV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: chat_id,
          startRow: 0,
          chat_conversation_id: latestChatConversationId,
          chat_contact_id: prospectData.chat_contact_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record = data.record.slice().reverse();
        const newMessages = data.record.filter((record) => !messagesData.record.some((currentMessage) => currentMessage.chat_conversation_id === record.chat_conversation_id));
        if (newMessages.length > 0) {
          const updatedMessagesList = { ...messagesList };
          newMessages.forEach((message) => {
            var newDate = new Date(message.date_time_create);
            var momentDate = moment(newDate).format("YYYY-MM-DD");
            message.date = momentDate;
            if (updatedMessagesList[message.date]) {
              updatedMessagesList[message.date].push(message);
            } else {
              updatedMessagesList[message.date] = [message];
            }
          });
          setMessagesList(updatedMessagesList);
        }

        if (prospectUpdate) {
          const findContact = prospectList.find((val) => val.chat_contact_id === prospectData.chat_contact_id);
          const updatedProspect = [...prospectList];
          updatedProspect[findContact.index].last_update = data.record[0].date_time_create;
          updatedProspect[findContact.index].last_message = data.record[0].message_body;
          updatedProspect[findContact.index].unread = 1;
          updatedProspect[findContact.index].require_attention = 0;

          var findIndex = prospectList.findIndex((el) => el.chat_contact_id === prospectData.chat_contact_id);
          if (findIndex !== -1) {
            const updatedProspectList = [findContact, ...prospectList.slice(0, findIndex), ...prospectList.slice(findIndex + 1)];
            setProspectList(updatedProspectList);
          }

          const updateProspectIndex = [...prospectList];
          updateProspectIndex.forEach((record, index) => {
            record.index = index;
          });
          setProspectList(updateProspectIndex);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLostReason = async () => {
    const response = await axios.get("ws/ws_deal_close.php", {
      params: {
        task: "getLostReason",
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      },
    });

    const data = response.data;
    if (data.status === 0) {
      const recordOptions = data.record.map((record) => ({
        label: record.reason_title,
        value: record.lost_reason_id,
      }));

      recordOptions.unshift({
        label: "Select a lost reason",
        value: "",
      });
      setLostReasonList(recordOptions);
    }
  };

  // ONCHANGE FUNCTION -----------------------------------------------------------

  const onchangeMessageAction = (record) => {
    let status = {
      file: false,
      broadcast: false,
      input: false,
      inputPlaceholder: "Write a message",
      send: false,
    };

    if (record.length) {
      let currLockStatus = Number(record[0].unlock_conversation);
      let currOutside24Hours = Number(record[0].outside_24h);

      if (currLockStatus === 0) {
        if (currOutside24Hours === 1) {
          status.input = true;
          status.inputPlaceholder = "Its outside 24 hours. The conversation has been closed.";
          status.file = true;
          status.broadcast = false;
          status.send = true;
          setLockConversation(true);
        } else {
          status.input = true;
          status.inputPlaceholder = "The conversation has been closed.";
          status.file = true;
          status.broadcast = true;
          status.send = true;
          setLockConversation(false);
        }
      } else {
        if (currOutside24Hours === 1) {
          status.input = true;
          status.inputPlaceholder = "Its outside 24 hours. The conversation has been closed.";
          status.file = true;
          status.broadcast = false;
          status.send = true;
          setLockConversation(true);
        } else {
          status.input = false;
          status.inputPlaceholder = "Write a message";
          status.file = false;
          status.broadcast = false;
          status.send = false;
          setLockConversation(false);
        }
      }
    }

    setMessageAction(status);
  };

  // SEND FUNCTION ---------------------------------------------------------------

  const sendFile = async (values) => {
    setIsSendFileLoading(true);

    const regex = /[^\\]*\.(\w+)$/;
    const filenameMatch = values.file[0].name.match(regex);

    if (!filenameMatch) {
      setIsSendFileLoading(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
      return;
    }

    let filename = filenameMatch[0].replace(/ /g, "_");
    const fileToUpload = await setBase64(values.file[0]);

    const formData = new FormData();
    const ctrl = new AbortController();

    filename = filename[0].replace(/ /g, "_");
    formData.append("task", "getMediaURL");
    formData.append("fileToUpload", fileToUpload);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("mime_type", values.file[0].type);
    formData.append("file_name", filename);

    setTimeout(() => ctrl.abort(), 20000);

    try {
      const response = await axios.post("ws/ws_conversation.php", formData, {
        signal: ctrl.signal,
      });

      const data = response.data;
      sendMedia(data.media_url, values.file[0].type, values.file[0].name);
    } catch {
      setIsSendFileLoading(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
    }
  };

  const sendMedia = async (link, mime, filename) => {
    var mimeType = mime.split("/");
    if (mimeType[0] === "application") {
      mime = "document/" + mimeType[1];
    }

    var chatId = prospectData.chat_id;
    if (chatId) {
      chatId = chatId.substring(1);
      chatId = chatId.replace("@c.us", "");
    }

    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "sendFileV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          mime_type: mime,
          media_url: link,
          media_op_file_name: filename,
          contact_num: prospectData.contact_num ? prospectData.contact_num : chatId ? chatId : "",
          prospect_id: prospectData.prospect_id,
          chat_instance_id: instanceData.chat_instance_id,
          chat_contact_id: prospectData.chat_contact_id,
        },
      });
      const data = response.data;
      if (Number(data.status) === 200) {
        getMessages(prospectData, isProspect, prospectList);
        const updatedProspect = [...prospectList];
        updatedProspect[isProspect].last_message = link;
        updatedProspect[isProspect].last_update = new Date();
        setProspectList(updatedProspect);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendFileLoading(false);
      setIsSendFile(false);
    }
  };

  const sendBroadcastImage = async (file) => {
    const regex = /[^\\]*\.(\w+)$/;
    const filenameMatch = file.name.match(regex);

    if (!filenameMatch) {
      return Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
    }

    let filename = filenameMatch[0].replace(/ /g, "_");
    const fileToUpload = await setBase64(file);

    const formData = new FormData();
    const ctrl = new AbortController();

    filename = filename[0].replace(/ /g, "_");
    formData.append("task", "getMediaURL");
    formData.append("fileToUpload", fileToUpload);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("mime_type", file.type);
    formData.append("file_name", filename);

    setTimeout(() => ctrl.abort(), 20000);

    try {
      const response = await axios.post("ws/ws_conversation.php", formData, {
        signal: ctrl.signal,
      });

      const data = response.data;
      return data.media_url;
    } catch {
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });

      return false;
    }
  };

  const sendBroadcast = async (values) => {
    setIsSendBroadcastLoading(true);
    var media_op_file_name = "";
    var media_mime_type = "";

    if (Number(values.whatsapp_template.whatsapp_template_header_image) === 1) {
      if (values.whatsapp_header_type.value === "image") {
        media_op_file_name = values.file[0].name;
        media_mime_type = values.file[0].type;
      } else if (values.whatsapp_header_type.value === "link") {
        const image_url = values.whatsapp_link;
        const filename = image_url.substring(image_url.lastIndexOf("/") + 1);
        const mime = filename.split(".").pop();
        media_op_file_name = filename.split("#")[0].split("?")[0];
        media_mime_type = "image/" + (mime === "jpg" ? "jpeg" : mime);
      }
    }

    var chatId = prospectData.chat_id;
    if (chatId) {
      chatId = chatId.replace("@c.us", "");
    }

    try {
      await axios.get("ws/ws_conversation.php", {
        params: {
          task: "sendWATemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_instance_id: instanceData.value,
          chat_contact_id: prospectData.chat_contact_id,
          whatsapp_template_name: values.whatsapp_template.whatsapp_template_name,
          whatsapp_template_params: values.whatsapp_params_input ? values.whatsapp_params_input.join("|") : "",
          whatsapp_template_header_image: values.whatsapp_image ? values.whatsapp_image : values.whatsapp_link,
          media_op_file_name: media_op_file_name,
          media_mime_type: media_mime_type,
          api_token: instanceData["360D_api_key"],
          msg: values.whatsapp_message_preview,
          contact_num: prospectData.contact_num ? prospectData.contact_num : chatId ? chatId : "",
          prospect_id: prospectData.prospect_id || "",
        },
      });

      getMessages(prospectData, isProspect, prospectList);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendBroadcastLoading(false);
      setIsBroadcast(false);
    }
  };

  const sendMessage = async () => {
    if (!inputData && isSendMessageLoading) return;
    setIsSendMessageLoading(true);
    setInputData("");

    var chatId = prospectData.chat_id;
    if (chatId) {
      chatId = chatId.replace("@c.us", "");
      if (chatId.startsWith("60")) {
        chatId = chatId.substring(1);
      }
    }
    
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "sendMessage",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          contact_num: prospectData.contact_num ? prospectData.contact_num : chatId ? chatId : "",
          message: inputData.toString().replace(/'/g, "\\'"),
          chat_instance_id: instanceData.value,
          instance_id: instanceData.instance_id,
          chat_contact_id: prospectData.chat_contact_id,
          prospect_id: prospectData.prospect_id || "",
        },
      });

      const data = response.data;

      if (Number(data.status) === 200) {
        getMessages(prospectData, isProspect, prospectList);
        const updatedProspect = [...prospectList];
        updatedProspect[isProspect].last_message = inputData;
        updatedProspect[isProspect].last_update = new Date();
        setProspectList(updatedProspect);
        setLastConversationId((prevIds) => [...prevIds, data.chat_conversation_id]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendMessageLoading(false);
    }
  };

  const setMax = (input) => {
    if (toString.call(input) !== "[object Array]") return false;
    return Math.max.apply(null, input);
  };

  const sendOpenContact = () => {
    if (!prospectData.prospect_id) {
      Swal.fire({
        icon: "warning",
        title: "Opps...",
        text: "Sorry, this contact is not a prospect in Outperform. Please add a new deal to make this contact as a prospect in Outperform",
      });
    } else {
      if (messagesData.blueprintStatus === 0) {
        window.open(`${session.origin}/deal/${messagesData.blueprintRecord.deal_id}`);
      } else {
        window.open(`${session.origin}/prospect/${prospectData.prospect_id}`);
      }
    }
  };

  const sendCreateDeal = async (values) => {
    var csid = instanceData.cs_id > 0 ? instanceData.cs_id : "";

    try {
      const response = await axios.get("toolbox/api_addNewDeal.php", {
        params: {
          task: "addNewDeal",
          source_token: instanceData.ds_token,
          cs_id: csid,
          secret_key: session.company_token,
          outcome: "",
          name: values.name,
          contact: values.contact_num,
          email: values.email,
          content: values.notes,
          owner_id: values.user_id,
          inbound_deal: 1,
          send_notification: 0,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await sendAssociateConversationInstance(data);
      } else {
        setIsAddDeal(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendAssociateConversationInstance = async (record) => {
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "associateConversationInstance",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: prospectData.chat_id,
          prospect_id: record.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getProspect(prospectSearchData, prospectOwnerData, instanceList, sessionRecord);
        setIsAddDeal(false);
        Swal.fire({
          icon: "success",
          text: "Successfully create a new contact",
          timer: 1500,
        });
      } else {
        setIsAddDeal(false);
        Swal.fire({
          icon: "error",
          title: "Opps...",
          text: "Something wrong with your entry, please try again",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendQualifyLead = async (values) => {
    setIsSendQualifyLeadLoading(true);
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "qualifyBlueprintConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          prospect_id: prospectData.prospect_id,
          blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
          deal_id: messagesData.blueprintRecord.deal_id || 0,
          remark: values.remark,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getMessages(prospectData, isProspect, prospectList);
        setBlueprintAction((prevState) => ({
          ...prevState,
          qualifyLeadDisabled: true,
          dropLeadDisabled: true,
        }));

        Swal.fire({
          icon: "success",
          title: "Yeayyyy!!",
          text: "The lead has been qualified.",
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Opps...",
          text: "Something wrong to qualify the lead. Please try again.",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsQualifyLead(false);
      setIsSendQualifyLeadLoading(false);
    }
  };

  const sendDropLead = async (values) => {
    setIsSendDropLeadLoading(true);
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "dropBlueprintConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          prospect_id: prospectData.prospect_id || 0,
          blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
          deal_id: messagesData.blueprintRecord.deal_id || 0,
          reasonid: values.reason,
          remark: values.remark,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getMessages(prospectData, isProspect, prospectList);
        setBlueprintAction((prevState) => ({
          ...prevState,
          qualifyLeadDisabled: false,
          dropLeadDisabled: true,
        }));

        Swal.fire({
          icon: "success",
          text: "The lead has been dropped.",
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Opps...",
          text: "Something wrong to drop the lead. Please try again.",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDropLead(false);
      setIsSendDropLeadLoading(false);
    }
  };

  const sendLeadAdmin = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Would you like to transfer the lead to the admin?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#eee",
      confirmButtonText: "Transfer",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_conversation.php", {
            params: {
              task: "transferBlueprintToAdmin",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              prospect_id: prospectData.prospect_id || 0,
              blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
              deal_id: messagesData.blueprintRecord.deal_id || 0,
            },
          })
          .then((response) => {
            const data = response.data;
            if (data.status === 0) {
              getMessages(prospectData, isProspect, prospectList);
              Swal.fire({
                icon: "success",
                text: "The lead has been transferred to the admin.",
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Opps...",
                text: "The lead has not transferred to the admin. Please try again.",
              });
            }
          });
      }
    });
  };

  const sendLeadRA = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Would you like to transfer the lead to the Sales rep?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#eee",
      confirmButtonText: "Transfer",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_conversation.php", {
            params: {
              task: "transferBlueprintToSalesrep",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              prospect_id: prospectData.prospect_id || 0,
              blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
              deal_id: messagesData.blueprintRecord.deal_id || 0,
            },
          })
          .then((response) => {
            const data = response.data;
            if (data.status === 0) {
              getMessages(prospectData, isProspect, prospectList);
              Swal.fire({
                icon: "success",
                text: "The lead has been transferred to the sales rep.",
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Opps...",
                text: "The lead has not transferred to the sales rep. Please try again.",
              });
            }
          });
      }
    });
  };

  const sendUnlockConversation = async () => {
    let status = messageAction.input ? 1 : 0;

    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "unlockConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: prospectData.chat_id,
          mode: status,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getMessages(prospectData, isProspect, prospectList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------------------------------------------

  const setInitial = (input) => {
    var str = "";
    if (input) {
      if (/\s/.test(input)) {
        str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() + input.split(" ")[1].charAt(0).toUpperCase() : input;
      } else {
        str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() + input.split(" ")[0].charAt(1).toUpperCase() : input;
      }
    }
    return str;
  };

  const setShortMessage = (input) => {
    var maxlength = 34;

    if (input.length >= maxlength) {
      return input.substring(0, 31) + "...";
    }

    return input;
  };

  const setTimeStamp = (input) => {
    var today = new Date();
    var oriDate = new Date(input);
    var createdOn = new Date(input);
    var msInDay = 24 * 60 * 60 * 1000;

    createdOn.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    var diff = (+today - +createdOn) / msInDay;
    var str = "";

    if (diff === 0) {
      str = moment(oriDate).format("LT");
    } else if (diff === 1) {
      str = "Yesterday";
    } else if (diff < 6) {
      str = diff + " days ago";
    } else {
      str = moment(oriDate).format("ll");
    }
    return str;
  };

  const setFormatMessages = ({ message_type, message_body, media_url, location_lat, location_long, contact_info }, isLoaded) => {
    const fileTypes = [
      { fullName: "Document", shortForm: "doc", icon: faFileDoc },
      { fullName: "Portable Document Format", shortForm: "pdf", icon: faFilePdf },
      { fullName: "Image", shortForm: "img", icon: faFileImage },
      { fullName: "Text File Document", shortForm: "txt", icon: faFileText },
      { fullName: "Excel Spreadsheet", shortForm: "xlsx", icon: faFileExcel },
      { fullName: "PowerPoint Presentation", shortForm: "pptx", icon: faFilePowerpoint },
      { fullName: "Compressed File", shortForm: "zip", icon: faFileZip },
      { fullName: "JavaScript File", shortForm: "js", icon: faFileCode },
      { fullName: "HTML File", shortForm: "html", icon: faFileCode },
      { fullName: "CSS File", shortForm: "css", icon: faFileCode },
      { fullName: "Audio File", shortForm: "aud", icon: faFileAudio },
      { fullName: "Video File", shortForm: "vid", icon: faFileVideo },
      { fullName: "Rich Text Format", shortForm: "rtf", icon: faFileCode },
      { fullName: "Markdown File", shortForm: "md", icon: faFileCode },
      { fullName: "Extensible Markup Language File", shortForm: "xml", icon: faFileXml },
      { fullName: "JavaScript Object Notation File", shortForm: "json", icon: faFileCode },
      { fullName: "Comma-Separated values File", shortForm: "csv", icon: faFileCsv },
      { fullName: "Tag Image File Format", shortForm: "tiff", icon: faFileImage },
      { fullName: "Opus Audio", shortForm: "opus", icon: faFileAudio },
      { fullName: "OGG Audio", shortForm: "ogg", icon: faFileAudio },
      { fullName: "JPEG Image", shortForm: "jpg", icon: faFileJpg },
      { fullName: "SVG Image", shortForm: "svg", icon: faFileSvg },
      { fullName: "PNG Image", shortForm: "png", icon: faFilePng },
      { fullName: "GIF Image", shortForm: "gif", icon: faFileGif },
      { fullName: "Vector Image", shortForm: "vector", icon: faFileVector },
      { fullName: "EPS File", shortForm: "eps", icon: faFileExcel },
      { fullName: "MP3 Audio", shortForm: "mp3", icon: faFileMp3 },
      { fullName: "MOV Video", shortForm: "mov", icon: faFileMov },
    ];

    const renderImage = (imageUrl) => (
      <div style={{ maxWidth: 400 }}>
        <Image
          src={imageUrl}
          width={400}
          className="mb-2 rounded shadow-sm cursor-pointer"
          onClick={() => {
            setImgUrl(imageUrl);
            setToggler(true);
          }}
        />
      </div>
    );

    const renderAudio = (url) => (
      <audio controls controlsList="nodownload noplaybackrate" style={{ width: 400 }}>
        <source src={url} type="audio/ogg" />
      </audio>
    );

    const renderFile = (url, filename) => {
      const fileType = fileTypes.find((file) => file.shortForm === url.split(".").pop());
      return (
        <a className="file w-100 shadow-sm rounded mb-2" href={url} target="_blank" rel="noreferrer">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center" style={{ width: 40 }}>
                <FontAwesomeIcon icon={fileType ? fileType.icon : faFile} size="2x" className="me-2" />
              </div>
              <div>
                <div className="fileName mb-0" style={{ maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {filename}
                </div>
                <p className="m-0 text-muted" style={{ fontSize: 10 }}>
                  {fileType ? fileType.fullName : "File Document"}
                </p>
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} size="xl" />
          </div>
        </a>
      );
    };

    const renderText = (input, url) => {
      if (url !== "") {
        input = input.replace(url, "");
        const isImage = /\.(jpeg|jpg|png|gif)$/i.test(url);
        const fileType = fileTypes.find((file) => file.shortForm === url.split(".").pop());

        return (
          <div style={{ maxWidth: 400 }}>
            {isImage ? (
              <Image
                src={url}
                width={400}
                className="mb-2 rounded shadow-sm cursor-pointer"
                onClick={() => {
                  setImgUrl(url);
                  setToggler(true);
                }}
              />
            ) : (
              <a className="file w-100 shadow-sm rounded mb-2" href={url} target="_blank" rel="noreferrer">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center" style={{ width: 40 }}>
                      <FontAwesomeIcon icon={fileType ? fileType.icon : faFile} size="2x" className="me-2" />
                    </div>
                    <div>
                      <div className="fileName mb-0" style={{ maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {url.split("/").pop()}
                      </div>
                      <p className="m-0 text-muted" style={{ fontSize: 10 }}>
                        {fileType ? fileType.fullName : "File Document"}
                      </p>
                    </div>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} size="xl" />
                </div>
              </a>
            )}
            <div dangerouslySetInnerHTML={{ __html: processText(input) }}></div>
          </div>
        );
      } else if (input.trim() === "") {
        return (
          <div className="d-flex align-items-center mb-0">
            <FontAwesomeIcon icon={faClock} className="me-1" size="lg" color="red" />
            <div className="m-0" style={{ lineHeight: 1, paddingTop: 2, color: "red" }}>
              Unable to display the chat message, use Whatsapp to see the message.
            </div>
          </div>
        );
      }

      return <div dangerouslySetInnerHTML={{ __html: processText(input) }}></div>;
    };

    const processText = (input) => {
      const regLink = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const regBreakLine = /(?:\r\n|\r|\n)/g;

      let formattedText = input.toString().replace(/\\'/g, "'").replace(regBreakLine, "<br>");
      formattedText = formattedText.replace(regLink, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    };

    const processMedia = (text, type) => {
      const regFile = /(https?:\/\/\S+(\.docx|\.pdf|\.tiff|\.html|\.php|\.js|\.xlsx|\.pptx|\.zip|\.csv|\.txt))/g;
      const regImage = /(https?:\/\/\S+(\.jpeg|\.png|\.jpg|\.gif))/g;
      const regAudio = /(https?:\/\/\S+(\.ogg|\.opus))/g;

      let parts = text.toString().split(/\s+/);

      return parts.map((part, index) => {
        if (regFile.test(part)) {
          const filename = part.split("/").pop().split("#")[0].split("?")[0];
          return <span key={index}>{renderFile(part, filename)}</span>;
        } else if (regImage.test(part)) {
          return <span key={index}>{renderImage(part)}</span>;
        } else if (regAudio.test(part)) {
          return <span key={index}>{renderAudio(part)}</span>;
        }
        return <span key={index}>{part} </span>;
      });
    };

    const processContact = (contact) => {
      return (
        <div className="file cursor-pointer">
          <FontAwesomeIcon icon={faUser} size="xl" className="me-2" />
          <div className="fileName">{contact?.[0]?.name?.formatted_name || "No name"}</div>
        </div>
      );
    };

    const processLocation = (lat, long) => {
      const openGoogleMap = () => {
        window.open("https://www.google.com/maps?q=" + lat + "," + long, "_blank");
      };

      return (
        <div style={{ position: "relative", cursor: "pointer" }} onClick={openGoogleMap}>
          {isLoaded ? (
            <GoogleMap
              center={{ lat: parseFloat(lat), lng: parseFloat(long) }}
              zoom={15}
              mapContainerStyle={{ width: "300px", height: "300px" }}
              options={{
                disableDefaultUI: true, // This removes all default buttons and controls
                zoomControl: false, // Optional: specifically disable zoom control if needed
                draggable: false, // Disable dragging the map
                scrollwheel: false, // Disable zooming with the scroll wheel
                disableDoubleClickZoom: true, // Disable double-click zoom
              }}
            >
              <Marker position={{ lat: parseFloat(lat), lng: parseFloat(long) }} />
            </GoogleMap>
          ) : (
            "Loading...."
          )}
        </div>
      );
    };

    const renderFormattedMessage = () => {
      if (message_type === "text" || message_type === "chat") {
        return renderText(message_body, media_url);
        return <div dangerouslySetInnerHTML={{ __html: processText(message_body) }}></div>;
      } else if (message_type === "voice" || message_type === "audio") {
        return renderAudio(media_url);
      } else if (message_type === "image") {
        return renderImage(media_url);
      } else if (message_type === "document") {
        return <div>{processMedia(media_url, "document")}</div>;
      } else if (message_type === "location") {
        return <div>{processLocation(location_lat, location_long)}</div>;
      } else if (message_type === "contacts") {
        return processContact(contact_info);
      } else {
        return renderText(message_body, media_url);
        return <div dangerouslySetInnerHTML={{ __html: processText(message_body) }}></div>;
      }
    };

    return <div>{renderFormattedMessage()}</div>;
  };

  const setBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const setBlueprintStatus = (input) => {
    let str = "";

    if (input === "responded") {
      str = "Responded";
    } else if (input === "qualified") {
      str = "Qualified";
    } else if (input === "dropped") {
      str = "Dropped";
    } else if (input === "ended") {
      str = "Ended";
    } else if (input === "ended_transfered_admin") {
      str = "Transfered to SRM";
    } else if (input === "ended_transfered_salesrep") {
      str = "Transfered to SR";
    } else if (input === "ended_qualified") {
      str = "Tele Qualified";
    } else if (input === "active") {
      str = "In Progress";
    } else if (input === "invalid_num") {
      str = "Invalid Number";
    } else {
      str = input;
    }

    return str;
  };

  const setFormatMessages2 = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    let formattedText = input.replace(regBold, "<strong>$1</strong>").replace(regItalic, "<em>$1</em>").replace(regStrikethrough, "<s>$1</s>").replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  // KEYPRESS FUNCTION -----------------------------------------------------------

  const keypressSearchProspect = (event) => {
    if (event.key === "Enter") {
      setIsProspectFilter(Math.random());
    }
  };

  const keypressInput = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setInputData((prevInputData) => prevInputData + "\n");
    }
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderProspectFilter = ({ userOption, userInput }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const RenderToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
        handleShow();
      };

      return (
        <button ref={ref} className="btn op-button op-primary-color text-light ms-1" onClick={openDropdown}>
          <FontAwesomeIcon icon={faBarsFilter} size="lg" />
        </button>
      );
    });

    const RenderFilter = forwardRef(({ style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
          <Card>
            <Formik
              onSubmit={(values) => {
                setProspectOwnerData(values.user);
                setIsProspectFilter(Math.random());
                handleClose();
              }}
              initialValues={{
                user: userInput,
              }}
            >
              {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    Filter prospect
                    <Dropdown.Item as={CloseButton} onClick={handleClose} />
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Owner</Form.Label>
                      <FormSelect options={userOption} valueDefault={userInput ? userOption.find((record) => record.value === userInput) : null} placeholder="Select owner" onChange={(e) => handleChange("user")(e.value)} />
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-row-reverse">
                    <button type="submit" className="btn op-button op-primary-color text-light" style={{ zIndex: 0 }}>
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{ backgroundColor: "#eee" }}
                      onClick={() => {
                        setProspectOwnerData("");
                        setIsProspectFilter(Math.random());
                        handleClose();
                      }}
                    >
                      Clear
                    </button>
                  </Card.Footer>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      );
    });

    return (
      <Dropdown size={100} show={show}>
        <Dropdown.Toggle as={RenderToggle} />
        <Dropdown.Menu as={RenderFilter} />
      </Dropdown>
    );
  };

  const RenderMediaAction = forwardRef(({ onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <button ref={ref} type="button" className="btn op-button btn-circle btn-sm op-primary-color" onClick={openDropdown}>
        <FontAwesomeIcon icon={faPaperclip} size="lg" style={{ color: "#fff" }} />
      </button>
    );
  });

  // USEEFFECT FUNCTION ----------------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getChatInstance();
          await getUsers();
          await getTemplate();
          await getLostReason();

          setTimeout(() => {
            setLoading(false);
            init.current = true;
          }, 500);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      getProspect(prospectSearchData, prospectOwnerData, instanceData);
    }
  }, [isProspectFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=" + instanceData.instance_id,
      });

      socket.on("chat message", function (msg) {
        getLiveContact();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [instanceData, init.current, prospectData, messagesData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [containerRef.current, messagesList]);

  // const testbtn = () => {
  //   getLiveContact();
  // };

  const handleInputChange = (e) => {
    const newValue = String(e.target.value || "");
    setProspectSearchData(newValue);
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-wrapper">
          <section className="page-start">
            <div className="header">
              <Stack className="justify-content-center" direction="vertical" gap={2}>
                <FormSelect
                  options={instanceList}
                  valueDefault={Object.keys(chatbotData).length > 0 ? instanceList.find((record) => record.value == instanceData2.value) : instanceList.find((record) => record.value == instanceData.value)}
                  // valueDefault={instanceData.value ? instanceList.find((record) => record.value === instanceData.value) : chat_instance_id ? instanceList.find((record) => record.value === chat_instance_id) : instanceList[0]}
                  placeholder="Select an instance"
                  width={"100%"}
                  border={false}
                  shadow={true}
                  onChange={(e) => {
                    setInstanceData(e);
                    setInstanceData2(e);
                    setIsProspectFilter(Math.random());
                  }}
                />
                <Form.Group className="d-flex">
                  <InputGroup>
                    {/* <Form.Control placeholder="Search prospect..." value={prospectSearchData ? prospectSearchData : prospectSearchData2} onKeyDown={keypressSearchProspect} onChange={(e) => setProspectSearchData(e.target.value)} /> */}
                    {/* <Form.Control placeholder="Search prospect..." value={prospectSearchData ? prospectSearchData : ""} onKeyDown={keypressSearchProspect} onChange={(e) => setProspectSearchData(e.target.value)} /> */}
                    <Form.Control
                      placeholder="Search prospect..."
                      value={prospectSearchData}
                      onKeyDown={keypressSearchProspect}
                      // onChange={(e) => setProspectSearchData(e.target.value)}
                      onChange={handleInputChange}
                    />
                    <button
                      className="btn op-button op-primary-color text-light"
                      style={{ zIndex: 0 }}
                      onClick={() => {
                        // getLiveContact();
                        setIsProspectFilter(Math.random());
                      }}
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </InputGroup>
                  <RenderProspectFilter userOption={userList} userInput={prospectOwnerData} />
                </Form.Group>
              </Stack>
            </div>
            <div className="content">
              {prospectList.length > 0 ? (
                prospectList.map((record, index) => (
                  <div key={index} className={`item ${isProspect === index ? "active" : null}`} onClick={() => getMessages(record, index, prospectList)}>
                    <div className="item-start">
                      <div className="initial-wrapper">
                        <div className="initial">{setInitial(record.prospect_name)}</div>
                      </div>
                      <div className="content-item">
                        {record.prospect_name && <h6>{record.prospect_name}</h6>}
                        {record.last_message && <p>{setShortMessage(record.last_message)}</p>}
                      </div>
                      <div className="content-time">
                        {record.last_update && <p>{setTimeStamp(record.last_update)}</p>}
                        {Number(record.unread) === 1 && <span className="new-message"></span>}
                        {Number(record.require_attention) === 1 && <span className="new-attention"></span>}
                      </div>
                    </div>
                    <div className="item-footer">{record.user_name && <span>{record.user_name}</span>}</div>
                  </div>
                ))
              ) : (
                <div className="px-4">
                  <NoRecord description="No matching prospect were found based on your criteria. Consider resetting your filters, or making adjustments to your current filter settings." iconSize={50} height={150} />
                </div>
              )}
              {isLoadMore && (
                <div className="item" onClick={getMoreProspect}>
                  <div className="item-start">
                    <div className="initial-wrapper">
                      <div className="initial">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </div>
                    <div className="content-item">
                      <h6>Load more prospect</h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <section className="page-content">
            <div ref={containerRef} className="content">
              <div className="content-wrapper">
                {messagesList &&
                  Object.entries(messagesList).map(([key, value]) => (
                    <div key={key} className="chat-wrapper">
                      <div className="chat-timestamp">
                        <span>{moment(new Date(key)).format("ll")}</span>
                      </div>

                      {value.map((record, index) => (
                        <div className="chat" key={index}>
                          {record.direction === "inbound" && (
                            <div className="inbound-wrapper">
                              <div className={`inbound ${record.firstchild ? "firstchild" : ""}`}>
                                {record.message_body && (
                                  <div className="mb-2" style={{ lineHeight: 1.5 }}>
                                    {setFormatMessages(record, isLoaded)}
                                  </div>
                                )}
                                {!record.media_url && (record.message_type === "image" || record.message_type === "document" || record.message_type === "voice") && (
                                  <div className="d-flex align-items-center mb-3">
                                    <FontAwesomeIcon icon={faClock} className="me-2" />
                                    <p style={{ margin: 0 }}>Waiting for this message. This may take a while or you may refresh it</p>
                                  </div>
                                )}
                                <p className="inbound-extra">
                                  <span className="inbound-user me-2"> {messagesData.sender_name}</span>
                                  <span className="inbound-created">{moment(new Date(record.date_time_create)).format("LT")}</span>
                                </p>
                              </div>
                              {!record.media_url && (record.message_type === "image" || record.message_type === "document" || record.message_type === "voice") && (
                                <div className="inbound-refresh">
                                  <button className="btn btn-circle btn-sm" style={{ backgroundColor: "#eee" }} onClick={() => getRefreshMessages(record)}>
                                    <FontAwesomeIcon icon={faRotateRight} size="xl" />
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                          {record.direction === "outbound" && (
                            <div className="outbound-wrapper">
                              {Number(record.error) === 1 && (
                                <div className="outbound-error">
                                  <OverlayTrigger trigger={["hover", "hover"]} placement="left" overlay={(props) => <Tooltip {...props}>{record.error_message}</Tooltip>}>
                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                  </OverlayTrigger>
                                </div>
                              )}

                              <div className={`outbound ${record.firstchild ? "firstchild" : ""}`}>
                                {record.message_body && (
                                  <div className="mb-2" style={{ lineHeight: 1.5 }}>
                                    {setFormatMessages(record)}
                                  </div>
                                )}
                                <p className="outbound-extra">
                                  <span className="outbound-user me-2">{record.user_name}</span>
                                  <span className="outbound-created">{moment(new Date(record.date_time_create)).format("LT")}</span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>

            <div className="footer">
              <div className="input-tools">
                <Stack direction="horizontal" gap={2}>
                  <Dropdown drop="up">
                    <Dropdown.Toggle as={RenderMediaAction} />
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={setIsSendFile} disabled={messageAction.file}>
                        Send File
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button type="button" className="btn op-button btn-circle btn-sm op-primary-color" disabled={messageAction.broadcast} onClick={setIsBroadcast}>
                    <FontAwesomeIcon icon={faBullhorn} size="lg" style={{ color: "#fff" }} />
                  </button>
                </Stack>
              </div>

              <Form.Group className="w-100 ms-2">
                <Stack direction="horizontal" gap={2}>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={1}
                    onKeyDown={keypressInput}
                    className="h-100 input"
                    disabled={messageAction.input}
                    placeholder={messageAction.inputPlaceholder}
                    value={inputData}
                    style={{ resize: "none", borderRadius: "100rem" }}
                    onChange={(e) => setInputData(e.target.value)}
                  />
                  <button type="button" className="btn op-button btn-circle btn-sm op-primary-color" disabled={messageAction.send} onClick={sendMessage}>
                    {isSendMessageLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faPaperPlaneTop} style={{ color: "#fff" }} />}
                  </button>
                </Stack>
              </Form.Group>
            </div>
          </section>

          <section className="page-end">
            <div className="content">
              <div className="content-user">
                <Stack direction="horizontal" gap={2} className="cursor-pointer" onClick={sendOpenContact}>
                  <div className="initial-wrapper me-2">
                    <p className="initial">{setInitial(prospectData.prospect_name)}</p>
                  </div>
                  <Stack direction="vertical" className="justify-content-center">
                    <h6 className="op-text-medium m-0">{prospectData.prospect_name}</h6>
                    {messagesData && Number(messagesData.blueprintStatus) !== 1 && messagesData.blueprintRecord && messagesData.blueprintRecord.status && (
                      <p className="m-0" style={{ color: "#999", fontSize: 11 }}>
                        {messagesData.blueprintRecord.status ? setBlueprintStatus(messagesData.blueprintRecord.status) : ""}
                      </p>
                    )}
                  </Stack>
                </Stack>
                <Stack className="mt-2" direction="vertical" gap={3} style={{ padding: "10px 10px" }}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faUser} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {messagesData && messagesData.sender_name ? messagesData.sender_name : "No name"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faPhone} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.contact_num ? prospectData.contact_num : "No contact"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.email_title ? prospectData.email_title : "No email"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faUserTie} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.user_name ? prospectData.user_name : "No ownership"}
                    </span>
                  </div>
                </Stack>
              </div>

              <div className="footer">
                {blueprintAction.addDealShow && (
                  <div className={`item mt-2 ${blueprintAction.addDealDisabled ? "disabled" : ""}`} onClick={setIsAddDeal}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faUserPlus} size="lg" />
                      </div>
                      <span className="ms-2">Add New Deal</span>
                    </div>
                  </div>
                )}

                {blueprintAction.qualifyLeadShow && (
                  <div className={`item mt-2 ${blueprintAction.qualifyLeadDisabled ? "disabled" : ""}`} onClick={setIsQualifyLead}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                      </div>
                      <span className="ms-2">Qualify Lead</span>
                    </div>
                  </div>
                )}

                {blueprintAction.dropLeadShow && (
                  <div className={`item mt-2 ${blueprintAction.dropLeadDisabled ? "disabled" : ""}`} onClick={setIsDropLead}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faXmark} size="lg" />
                      </div>
                      <span className="ms-2">Drop Lead</span>
                    </div>
                  </div>
                )}

                {blueprintAction.transferAdminShow && (
                  <div className={`item mt-2 ${blueprintAction.transferAdminDisabled ? "disabled" : ""}`} onClick={sendLeadAdmin}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faPeopleArrows} size="lg" />
                      </div>
                      <span className="ms-2">Transfer to admin</span>
                    </div>
                  </div>
                )}

                {blueprintAction.transferRAShow && (
                  <div className={`item mt-2 ${blueprintAction.transferRADisabled ? "disabled" : ""}`} onClick={sendLeadRA}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faPeopleArrows} size="lg" />
                      </div>
                      <span className="ms-2">Transfer to sales rep</span>
                    </div>
                  </div>
                )}

                <div className={`item mt-2 ${lockConversation ? "disabled" : ""}`} onClick={sendUnlockConversation}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center" style={{ width: 20 }}>
                      <FontAwesomeIcon icon={messageAction.input ? faUserUnlock : faUserLock} size="lg" />
                    </div>

                    <span className="ms-2"> {messageAction.input ? "Unlock conversation" : "Lock conversation"} </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <Modal show={isSendFile} onHide={setIsSendFile} backdrop="static">
        <Formik
          validationSchema={sendFileSchema}
          onSubmit={sendFile}
          initialValues={{
            file: [],
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Attach a file</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group>
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    credits={false}
                    onupdatefiles={(fileItems) => {
                      setFieldValue(
                        "file",
                        fileItems.map((fileItem) => fileItem.file)
                      );
                    }}
                  />
                  {errors.file && touched.file && <div className="op-error-message">{errors.file}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSendFileLoading}>
                  {isSendFileLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send"}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isBroadcast} onHide={setIsBroadcast} backdrop="static" size="xl">
        <Formik
          onSubmit={sendBroadcast}
          initialValues={{
            whatsapp_template: {},
            whatsapp_content: false,
            whatsapp_params: false,
            whatsapp_params_input: [],
            whatsapp_template_header_image: false,
            whatsapp_link: "",
            whatsapp_link_show: false,
            whatsapp_image: "",
            whatsapp_image_show: false,
            whatsapp_message_preview: "",
            whatsapp_header_type: { value: "image", label: "Upload an image" },
            file: [],
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>{!values.whatsapp_image_show && !values.whatsapp_link_show ? "Broadcast Message" : values.whatsapp_image_show ? "Upload your image" : "Insert your link image"}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Whatsapp Template</Form.Label>
                      <FormSelect
                        options={templateList}
                        valueDefault={values.whatsapp_template ? templateList.find((record) => record.whatsapp_template_op_id === values.whatsapp_template.whatsapp_template_op_id) : null}
                        onChange={(e) => {
                          if (e) {
                            const templateParamsCount = Number(e.whatsapp_template_params);
                            const params = Array.from({ length: templateParamsCount }, () => "");
                            setFieldValue("whatsapp_params_input", params);
                            setFieldValue("whatsapp_params", templateParamsCount > 0 ? true : false);
                            setFieldValue("whatsapp_template", e);
                            setFieldValue("whatsapp_message_preview", e.message);
                            setFieldValue("whatsapp_template_header_image", Number(e.whatsapp_template_header_image) === 1 ? true : false);
                            setFieldValue("whatsapp_link", "");
                            setFieldValue("whatsapp_image", "");
                            setFieldValue("whatsapp_content", true);
                          }
                        }}
                      />
                    </Form.Group>

                    {values.whatsapp_message_preview === "" && <div className="h-100 mt-5 text-center">You haven't choose any Whatsapp template yet</div>}

                    {Number(values.whatsapp_template.whatsapp_template_header_image) === 0 && Number(values.whatsapp_template.whatsapp_template_params) === 0 && <div className="h-100 mt-5 text-center">This template haven't used any variable placeholders or image header in your text</div>}

                    {Number(values.whatsapp_template_header_image) === 1 && (
                      <Form.Group className="mb-3">
                        <div className="d-flex align-items-center mb-2">
                          <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                            Header
                          </Button>

                          <FormSelect
                            options={[
                              { value: "image", label: "Upload an image" },
                              { value: "link", label: "Insert a Link" },
                            ]}
                            valueDefault={{ value: "image", label: "Upload an image" }}
                            onChange={(e) => {
                              setFieldValue("whatsapp_header_type", e);
                              setFieldValue("whatsapp_image", "");
                              setFieldValue("whatsapp_link", "");
                              setFieldValue("file", []);
                            }}
                            width="200px"
                          />
                        </div>

                        {values.whatsapp_header_type.value === "image" ? (
                          <FilePond
                            allowMultiple={false}
                            acceptedFileTypes={["image/*"]}
                            files={values.file}
                            maxFiles={1}
                            credits={false}
                            instantUpload={false}
                            onremovefile={() => {
                              setFieldValue("whatsapp_image", "");
                              setFieldValue("file", []);
                            }}
                            onupdatefiles={(fileItems) => {
                              setFieldValue(
                                "file",
                                fileItems.map((fileItem) => fileItem.file)
                              );
                            }}
                            server={{
                              process: async (fieldName, file, metadata, load, error, progress, abort) => {
                                const whatsapp_image = await sendBroadcastImage(file);
                                setFieldValue("whatsapp_image", whatsapp_image);
                                load(whatsapp_image);
                              },
                            }}
                          />
                        ) : (
                          <Form.Control type="text" placeholder="Insert image link" onChange={(e) => handleChange("whatsapp_link")(e.target.value)} />
                        )}
                      </Form.Group>
                    )}

                    {values.whatsapp_params_input.length > 0 && (
                      <>
                        {values.whatsapp_params_input.map((record, index) => (
                          <Form.Group className="mb-3" key={index}>
                            <div className="d-flex align-items-center mb-2">
                              <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                                Body
                              </Button>
                              Variable: {`{{${index + 1}}}`}
                            </div>
                            <Form.Control
                              value={record}
                              onChange={(e) => {
                                const params_value = [...values.whatsapp_params_input];
                                params_value[index] = e.target.value;

                                let message_content = values.whatsapp_template.message;
                                params_value.forEach((message, idx) => {
                                  if (message) {
                                    message_content = message_content.replace(`{{${idx + 1}}}`, message);
                                  }
                                });

                                setFieldValue("whatsapp_params_input", params_value);
                                setFieldValue("whatsapp_message_preview", message_content);
                              }}
                            />
                          </Form.Group>
                        ))}
                      </>
                    )}
                  </Col>
                  <Col sm={6}>
                    <div className="border w-100 px-4 py-4">
                      <h6>Preview</h6>
                      <div className="rounded position-relative p-2" style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}>
                        <div className="ws-bubble">
                          {Number(values.whatsapp_template.whatsapp_template_header_image) === 1 && (
                            <>
                              {values.whatsapp_header_type.value === "image" && values.whatsapp_image === "" ? (
                                <div className="ws-bubble-header">
                                  <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                </div>
                              ) : values.whatsapp_header_type.value === "image" && values.whatsapp_image !== "" ? (
                                <img src={values.whatsapp_image} alt="header" className="w-100" />
                              ) : null}

                              {values.whatsapp_header_type.value === "link" && values.whatsapp_link === "" ? (
                                <div className="ws-bubble-header">
                                  <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                </div>
                              ) : values.whatsapp_header_type.value === "link" && values.whatsapp_link !== "" ? (
                                <img src={values.whatsapp_link} alt="header" className="w-100" />
                              ) : null}
                            </>
                          )}

                          <p className="ws-bubble-message" dangerouslySetInnerHTML={{ __html: setFormatMessages2(values.whatsapp_message_preview) }} />
                          <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  type="submit"
                  className="btn op-button op-primary-color text-light"
                  disabled={
                    values.whatsapp_message_preview === "" ||
                    (Number(values.whatsapp_template.whatsapp_template_header_image) === 1 && values.whatsapp_header_type.value === "image" && values.whatsapp_image === "") ||
                    (Number(values.whatsapp_template.whatsapp_template_header_image) === 1 && values.whatsapp_header_type.value === "link" && values.whatsapp_link === "") ||
                    (values.whatsapp_params_input.length > 0 && values.whatsapp_params_input.includes("")) ||
                    isSendBroadcastLoading
                  }
                >
                  {isSendBroadcastLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isAddDeal} onHide={setIsAddDeal} size="lg" backdrop="static">
        <Formik
          validationSchema={addDealSchema}
          onSubmit={sendCreateDeal}
          initialValues={{
            name: prospectData.prospect_name || "",
            contact_num: prospectData.contact_name || "",
            email: prospectData.email_title || "",
            notes: "",
            user_id: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add New Deal</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={values.name} onChange={(e) => handleChange("name")(e.target.value)} />
                  {errors.name && touched.name && <div className="op-error-message">{errors.name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={values.contact_num} onChange={(e) => handleChange("contact_num")(e.target.value)} />
                  {errors.contact_num && touched.contact_num && <div className="op-error-message">{errors.contact_num}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={values.email} onChange={(e) => handleChange("email")(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as={"textarea"} rows={3} value={values.notes} onChange={(e) => handleChange("notes")(e.target.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Ownership</Form.Label>
                  <FormSelect options={addDealUser} valueDefault={addDealUser[0]} onChange={(e) => handleChange("user_id")(e.value)} />
                  {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isQualifyLead} onHide={setIsQualifyLead} size="lg" backdrop="static">
        <Formik
          onSubmit={sendQualifyLead}
          initialValues={{
            remark: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Qualify Lead</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group className="mb-3">
                  <Form.Label>Would you like to qualify this lead?</Form.Label>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSendQualifyLeadLoading}>
                  {isSendQualifyLeadLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Qualify"}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isDropLead} onHide={setIsDropLead} size="lg" backdrop="static">
        <Formik
          validationSchema={dropLeadSchema}
          onSubmit={sendDropLead}
          initialValues={{
            remark: "",
            reason: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Drop Lead</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Reason</Form.Label>
                  <FormSelect options={lostReasonList} valueDefault={lostReasonList[0]} onChange={(e) => handleChange("reason")(e.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Remark (optional)</Form.Label>
                  <Form.Control as="textarea" rows={3} value={values.remark} onChange={(e) => handleChange("remark")(e.target.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSendDropLeadLoading}>
                  {isSendDropLeadLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Drop"}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={toggler} onHide={setToggler} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button
              variant="link"
              className="m-0 text-light text-decoration-none"
              onClick={() => {
                setToggler(false);
              }}
            >
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center" style={{ background: "#505050" }}>
          <Image src={imgUrl} style={{ maxWidth: "80%" }} alt="image" />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Conversation;
