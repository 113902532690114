import { useState, useRef, useEffect, useMemo } from "react";
import { Card, Row, Col, Stack, Form, Modal, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2/dist/sweetalert2";
import { faDownload, faPrint } from "@fortawesome/pro-solid-svg-icons";

const SRMDashboard = () => {
    const init = useRef(false);
    const gridRef = useRef(null);
    const gridRefRAList = useRef(null);
    const gridRefProspectList = useRef(null);
    const { session } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState("");
    const [monthDashboard, setMonthDashboard] = useState(moment().format('MMMM'));
    const [projDash, setProjDash] = useState("");

    const [yearList, setYearList] = useState([
        {label: new Date().getFullYear(), value: new Date().getFullYear()}
    ]);
    const [monthList] = useState(
        [
            { label: "January", value: "January" },
            { label: "February", value: "February" },
            { label: "March", value: "March" },
            { label: "April", value: "April" },
            { label: "May", value: "May" },
            { label: "June", value: "June" },
            { label: "July", value: "July" },
            { label: "August", value: "August" },
            { label: "September", value: "September" },
            { label: "October", value: "October" },
            { label: "November", value: "November" },
            { label: "December", value: "December" }
        ]
    );
    const [projectList, setProjectList] = useState([
        {
            label: 'Gamuda Gardens', 
            value: 'gardens', 
            companyID: 180,
        },
        {
            label: 'Gamuda Jadehills', 
            value: 'jadehill', 
            companyID: 190,
        },
        {
            label: 'Gamuda Cove', 
            value: 'cove', 
            companyID: 200,
        },
        {
            label: 'Gamuda 257', 
            value: 'twentyfive7', 
            companyID: 203,
        },
        {
            label: 'Gamuda Horizon', 
            value: 'horizon', 
            companyID: 210,
        },
        {
            label: 'Gamuda Bantayan', 
            value: 'bantayan', 
            companyID: 212,
        },
        {
            label: 'Gamuda Highpark', 
            value: 'highpark', 
            companyID: 213,
        }
    ]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [project, setProject] = useState("");
    const [dashboardRowData, setDashboardRowData] = useState([]);
    const [dashboardColumn, setDashboardColumn] = useState([]);

    const containerStyleDashboard = useMemo(() => ({ width: "100%", height: "75vh", paddingTop: 0 }), []);
    const gridStyleDashboard = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDefDashboard = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [RAListRowData, setRAListRowData] = useState([]);
    const [RAListColumn, setRAListColumn] = useState([]);
    const [RAListModal, setRAListModal] = useState(false);

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const containerStyleRAList = useMemo(() => ({ width: "100%", height: "70vh", paddingTop: 0 }), []);
    const gridStyleRAList = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDefRAList = useMemo(() => {
        return {
            sortable: true,
            unSortIcon: true,
            filter: false,
        };
    }, []);

    const containerStyleProspectList = useMemo(() => ({ width: "100%", height: "40vh", paddingTop: 0 }), []);
    const gridStyleProspectList = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDefProspectList = useMemo(() => {
        return {
            sortable: true,
            unSortIcon: true,
            filter: false,
        };
    }, []);

    const [prosRowData, setProsRowData] = useState([]);
    const [prosColumn] = useState([
        {
            headerName: 'Prospect Name',
            field: 'prospect_name',
            flex: 1,
            onCellClicked: (params) => {
                if (params.data) {
                    window.open(`${session.origin}/prospect/${Number(session.company_id) === 251 && Number(params.data.hq_prospect_id) > 0 ? params.data.hq_prospect_id : params.data.prospect_id}`, '_blank');
                }
            }
        },
        {
            headerName: 'Contact',
            field: 'contact1',
            flex: 1
        }
    ]);
    const [isProsList, setIsProsList] = useState(false);

    const getDashboard = () => {
        axios.get('ws/ws_srm_dashboard.php', {
            params: {
                task: Number(session.company_id) === 251 ? 'getlistofSRMAssessmentHQ1' : 'getlistofSRMAssessmentV1',
                assessYear: year,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var column = [];

                if (Number(session.company_id) === 251) {
                    column.push(
                        {
                            headerName: 'Month',
                            field: 'assessment_month',
                            flex: 1
                        },
                        {
                            headerName: 'Total Survey Submitted',
                            children: [
                                {
                                    headerName: 'Gamuda Gardens',
                                    flex: 1,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[0].total_ra_submitted}/${params.data.companies[0].total_user}`
                                        }
                                    },
                                    hide: projDash === 'gardens' ? false :  projDash ? true : false,
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('gardens');

                                            handleListHQRA(params.data.assessment_month, 'gardens', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Jade Hills',
                                    flex: 1,
                                    hide: projDash === 'jadehill' ? false :  projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[1].total_ra_submitted}/${params.data.companies[1].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('jadehill');

                                            handleListHQRA(params.data.assessment_month, 'jadehill', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Cove',
                                    flex: 1,
                                    hide: projDash === 'cove' ? false :  projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[2].total_ra_submitted}/${params.data.companies[2].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('cove');

                                            handleListHQRA(params.data.assessment_month, 'cove', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Twentyfive.7',
                                    flex: 1,
                                    hide: projDash === 'twentyfive7' ? false :  projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[3].total_ra_submitted}/${params.data.companies[3].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('twentyfive7');

                                            handleListHQRA(params.data.assessment_month, 'twentyfive7', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Bukit Bantayan',
                                    flex: 1,
                                    hide: projDash === 'bantayan' ? false :  projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[4].total_ra_submitted}/${params.data.companies[4].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('bantayan');

                                            handleListHQRA(params.data.assessment_month, 'bantayan', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Highpark Suites',
                                    flex: 1,
                                    hide: projDash === 'highpark' ? false :  projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[5].total_ra_submitted}/${params.data.companies[5].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('highpark');

                                            handleListHQRA(params.data.assessment_month, 'highpark', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                },
                                {
                                    headerName: 'Gamuda Horizon Hills',
                                    flex: 1,
                                    hide: projDash === 'horizon' ? false : projDash ? true : false,
                                    cellRenderer: (params) => {
                                        if (params.data) {
                                            return `${params.data.companies[6].total_ra_submitted}/${params.data.companies[6].total_user}`
                                        }
                                    },
                                    onCellClicked: function(params){
                                        if (params.data) {
                                            var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                            var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                            setMonth(params.data.assessment_month);
                                            setStartDate(moment(ds).format("YYYY-MM-DD"));
                                            setEndDate(moment(de).format("YYYY-MM-DD"));
                                            setProject('horizon');

                                            handleListHQRA(params.data.assessment_month, 'horizon', moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                        }
                                    }
                                }
                            ]
                        },
                    );
                }else{
                    column.push(
                        {
                            headerName: 'Months',
                            field: 'assessment_month',
                            flex: 1
                        },
                        {
                            headerName: 'Responded Received',
                            field: 'total_sent',
                            flex: 1,
                            onCellClicked: function(params){
                                if (params.data) {
                                    var ds = new Date(`${year} ${params.data.assessment_month} 1`);
                                    var de = new Date(year, monthNames.indexOf(params.data.assessment_month) + 1, 0);

                                    setMonth(params.data.assessment_month);
                                    setStartDate(moment(ds).format("YYYY-MM-DD"));
                                    setEndDate(moment(de).format("YYYY-MM-DD"));

                                    
                                    handleListRA(params.data.assessment_month, moment(ds).format("YYYY-MM-DD"), moment(de).format("YYYY-MM-DD"));
                                    
                                }
                            },
                            cellRenderer: (params) => {
                                if (params.data) {
                                    return `${params.data.total_sent}/${data.total_users}`
                                }
                            }
                        }
                    );
                }

                var filterRecord = monthDashboard ? data.record.filter(opt => opt.assessment_month === monthDashboard) : data.record;

                setDashboardColumn(column);
                setDashboardRowData(filterRecord);
                setIsLoading(false);
                setIsLoading2(false);
            }else{
                var column = [];

                if (Number(session.company_id) === 251) {
                    column.push(
                        {
                            headerName: 'Month',
                            field: '',
                            flex: 1
                        },
                        {
                            headerName: 'Total Survey Submitted',
                            children: [
                                {
                                    headerName: 'Gamuda Gardens',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Jade Hills',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Cove',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Twentyfive.7',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Bukit Bantayan',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Highpark Suites',
                                    field: '',
                                    flex: 1
                                },
                                {
                                    headerName: 'Gamuda Horizon Hills',
                                    field: '',
                                    flex: 1
                                }
                            ]
                        },
                    );
                }else{
                    column.push(
                        {
                            headerName: 'Months',
                            field: 'assessment_month',
                            flex: 1
                        },
                        {
                            headerName: 'Responded Received',
                            field: 'total_sent',
                            flex: 1,
                        }
                    );
                }

                setDashboardColumn(column);
                setDashboardRowData([]);
                setIsLoading(false);
                setIsLoading2(false);
            }
        });
    }

    const getPastYear = () => {
        var currentYear = new Date().getFullYear();
        var yearCount = Number(currentYear) - 2024;
        var yearArr = [];

        if (Number(yearCount) > 0) {
            for (let index = 0; index <= yearCount; index++) {
                yearArr.push({label: currentYear - (index), value: currentYear - (index)});
            }

            setYearList(yearArr);
        }
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getDashboard();
            getPastYear();
        }
    }, []);

    const handleListRA = (month, ds, de) => {
        
        axios.get('ws/ws_srm_dashboard.php', {
            params: {
                task: 'getlistofSRMAssessmentV2',
                assessMonth: month,
                assessYear: year,
                ds: ds,
                de: de,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            

            if (Number(data.status) === 0) {
                var column = [
                    {
                        headerName: 'Full Name',
                        field: 'user_name',
                        flex: 1
                    },
                    {
                        headerName: 'SRM Date of Submission',
                        field: 'date_time_submitted',
                        flex: 1,
                        // filter: true,
                        // filter: 'agDateColumnFilter',
                        // filterParams: {
                        //     comparator: (filterLocalDateAtMidnight, cellValue) => {
                        //     const cellDate = new Date(cellValue);
                        //     if (filterLocalDateAtMidnight < cellDate) return 1;
                        //     if (filterLocalDateAtMidnight > cellDate) return -1;
                        //     return 0;
                        //     }
                        // },
                    },
                    {
                        headerName: 'SRM Status',
                        field: 'status',
                        flex: 1
                    },
                    {
                        headerName: 'SRM Evaluation Average Score',
                        field: '',
                        width: 250,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${params.data.final_mark.calculation_details.srm_average}/5`;
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${params.data.final_mark.calculation_details.srm_average}/5`;
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'SRM Score',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.srm_score)}`
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.srm_score)}`
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'CS1 count',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                return(
                                    <div style={{color: count >= 3 ? 'green' : 'red', cursor: 'pointer'}} onClick={() => {handleListProspect(params.data, ds, de, '')}}>
                                        {count}
                                    </div>
                                );
                            }
                        }
                    },
                    {
                        headerName: 'CS1 Average Score',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                // var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                // return `${count > 2 ? params.data.satisfaction_metrics.average_score : 0}/5`;
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.cs1_final)}`
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                // var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                // return `${count > 2 ? params.data.satisfaction_metrics.average_score : 0}/5`;
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.cs1_final)}`
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Final Score',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return(
                                            <div style={{color: Number(params.data.final_mark.calculation_details.final_score) > 4.5 ? 'green' : 'red'}}>
                                                {`${Number(params.data.final_mark.calculation_details.final_score)}/5`}
                                            </div>
                                        );
                                    }   
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return params.data.final_mark.calculation_details.final_score;
                                    }   
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Remarks',
                        field: 'srm_remark',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return Number(params.data.final_mark.calculation_details.final_score) >= 4.5 ? 'Eligible' : 'Not eligible';
                                    }else{
                                        return 'Not eligible';
                                    }   
                                }else{
                                    return 'Not eligible';
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return Number(params.data.final_mark.calculation_details.final_score) >= 4.5 ? 'Eligible' : 'Not eligible';
                                    }else{
                                        return 'Not eligible';
                                    }   
                                }else{
                                    return 'Not eligible';
                                }
                            }
                        }
                    }
                ];

                setRAListColumn(column);
                setRAListRowData(data.record);
                setRAListModal(true);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'No record found'
                });
                setRAListColumn([]);
                setRAListRowData([]);
                setRAListModal(false);
            }
        });
    }

    const handleListHQRA = (month, project, ds, de) => {

        axios.get('ws/ws_srm_dashboard.php', {
            params: {
                task: 'getlistofSRMAssessmentHQ2',
                assessMonth: month,
                project: project,
                ds: ds,
                de: de,
                assessYear: year,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var column = [
                    {
                        headerName: 'Full Name',
                        field: 'user_name',
                        flex: 1
                    },
                    {
                        headerName: 'SRM Date of Submission',
                        field: 'date_time_submitted',
                        // filter: true,
                        flex: 1,
                        // filter: 'agDateColumnFilter',
                        // filterParams: {
                        //     comparator: (filterLocalDateAtMidnight, cellValue) => {
                        //     const cellDate = new Date(cellValue);
                        //     if (filterLocalDateAtMidnight < cellDate) return 1;
                        //     if (filterLocalDateAtMidnight > cellDate) return -1;
                        //     return 0;
                        //     }
                        // },
                    },
                    {
                        headerName: 'SRM Status',
                        field: 'status',
                        flex: 1
                    },
                    {
                        headerName: 'SRM Evaluation Average Score',
                        field: '',
                        width: 250,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${params.data.final_mark.calculation_details.srm_average}/5`;
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${params.data.final_mark.calculation_details.srm_average}/5`;
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'SRM Score',
                        field: 'score',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.srm_score)}`
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.srm_score)}`
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'CS1 count',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                return(
                                    <div style={{color: count >= 3 ? 'green' : 'red', cursor: 'pointer'}} onClick={() => {handleListProspect(params.data, ds, de, project)}}>
                                        {count}
                                    </div>
                                );
                            }
                        }
                    },
                    {
                        headerName: 'CS1 Average Score',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                // var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                // return `${count > 2 ? params.data.satisfaction_metrics.average_score : 0}/5`;
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.cs1_final)}`
                                    }
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                // var count = params.data.total_responses ? Number(params.data.total_responses) : 0;
                                // return `${count > 2 ? params.data.satisfaction_metrics.average_score : 0}/5`;
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return `${Number(params.data.final_mark.calculation_details.cs1_final)}`
                                    }
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Final Score',
                        field: '',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return(
                                            <div style={{color: Number(params.data.final_mark.calculation_details.final_score) > 4.5 ? 'green' : 'red'}}>
                                                {`${Number(params.data.final_mark.calculation_details.final_score)}/5`}
                                            </div>
                                        );
                                    }   
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return params.data.final_mark.calculation_details.final_score;
                                    }   
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Remarks',
                        field: 'srm_remark',
                        flex: 1,
                        cellRenderer: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return Number(params.data.final_mark.calculation_details.final_score) >= 4.5 ? 'Eligible' : 'Not eligible';
                                    }else{
                                        return 'Not eligible';
                                    }   
                                }else{
                                    return 'Not eligible';
                                }
                            }
                        },
                        valueGetter: (params) => {
                            if (params.data) {
                                if (params.data.final_mark) {
                                    if (Number(params.data.final_mark.status) === 0) {
                                        return Number(params.data.final_mark.calculation_details.final_score) >= 4.5 ? 'Eligible' : 'Not eligible';
                                    }else{
                                        return 'Not eligible';
                                    }   
                                }else{
                                    return 'Not eligible';
                                }
                            }
                        }
                    }
                ];

                setRAListColumn(column);
                setRAListRowData(data.record);
                setRAListModal(true);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'No record found'
                });
                setRAListColumn([]);
                setRAListRowData([]);
                setRAListModal(false);
            }
        });
    }

    const handleDownload = () => {
        var params = {
            fileName : `srm-dashboard-ra-list.csv`,
            processCellCallback : function(params) {

                var res = params.value;
                // if(res && res !== undefined && res !== null && res !== '' ){
                //     res = res.replace(/<br>/g, "\r\n");
                //     res = res.replace(/<li>/g, "\r\n");
                //     res = res.replace(/<[^>]+>/g, '');
                //     res = res.replace(/&nbsp;/g, '');
                // }
                
                return res;

            }
        };

        gridRefRAList.current.api.exportDataAsCsv(params);
    }

    const handleFilterDate = () => {
        if (Number(session.company_id) === 251) {
            handleListHQRA(month,  project, startDate, endDate);
        }else{
            handleListRA(month, startDate, endDate);
        }
    }

    const handleListProspect = (dataset, ds, de, proj) => {
        var company_title = projectList.filter(opt => Number(opt.companyID) === Number(session.company_id));
        axios.get("ws/ws_srm_dashboard.php", {
            params: {
                task: 'getlistProspect',
                ds: ds,
                de: de,
                project: Number(session.company_id) !== 251 ? company_title[0].value : proj,
                uid: dataset.ra_user_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setProsRowData(data.record);
                setIsProsList(true);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'No record found.'
                });
                setProsRowData([]);
                setIsProsList(false);
            }
        });
    }

    return(
        <div style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
            {
                isLoading ? <Loader/> : 
                <div className="p-4">
                    <Stack direction="horizontal" gap={2} className="mb-4">
                        <Form.Group style={{width: '120px'}} className="shadow-sm border rounded">
                            <FormSelect
                                options={yearList}
                                valueDefault={yearList.filter(opt => Number(opt.value) === Number(year))}
                                isSearchable={true}
                                onChange={(e) => {setYear(e ? e.value : '')}}
                            />
                        </Form.Group>
                        {Number(session.company_id) === 251 && <Form.Group style={{width: '160px'}} className="shadow-sm border rounded">
                            <FormSelect
                                placeholder="All Month"
                                options={monthList}
                                valueDefault={monthList.filter(opt => opt.value === monthDashboard)}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(e) => {setMonthDashboard(e ? e.value : '')}}
                            />
                        </Form.Group>}
                        {Number(session.company_id) === 251 && <Form.Group style={{width: '200px'}} className="shadow-sm border rounded">
                            <FormSelect
                                options={projectList}
                                placeholder="All Project"
                                isClearable={true}
                                isSearchable={true}
                                onChange={(e) => {setProjDash(e ? e.value : '')}}
                            />
                        </Form.Group>}
                        <button type="button" className="btn op-button op-primary-color text-light" onClick={() => {getDashboard(); setIsLoading2(true);}}>Apply</button>
                    </Stack>
                    <div>
                        {isLoading2 ? <Loader/>:
                        <div style={containerStyleDashboard}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleDashboard }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={dashboardColumn}
                                    rowData={dashboardRowData}
                                    defaultColDef={reportColDefDashboard}
                                    rowHeight={50}
                                    // pagination={false}
                                    // paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            }

            <Offcanvas show={RAListModal} onHide={setRAListModal} placement="bottom" style={{left: 57, height: "100vh"}} backdrop="static">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title as={'h6'}>RA List</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <Stack direction="horizontal" gap={2} className="mb-3">
                            <Form.Group style={{width: '150px'}} className="ms-auto">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    min={moment(new Date(`${year} ${month} 1`)).format('YYYY-MM-DD')}
                                    max={moment(new Date(year, monthNames.indexOf(month) + 1, 0)).format('YYYY-MM-DD')}
                                    onChange={(e) => {setStartDate(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '150px'}}>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    min={moment(new Date(`${year} ${month} 1`)).format('YYYY-MM-DD')}
                                    max={moment(new Date(year, monthNames.indexOf(month) + 1, 0)).format('YYYY-MM-DD')}
                                    onChange={(e) => {setEndDate(e.target.value)}}
                                />
                            </Form.Group>
                            <button 
                                className="btn op-button op-primary-color text-light"
                                type="button"
                                onClick={() => handleFilterDate()}
                            >
                                Apply
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-light border rounded shadow-sm"
                                onClick={handleDownload}
                            >
                                <FontAwesomeIcon icon={faDownload} size="lg"/>
                            </button>
                        </Stack>
                        <div style={containerStyleRAList}>
                            <div className={"ag-theme-quartz ag-op ag-op-secondary border rounded shadow-sm"} style={{ ...gridStyleRAList }}>
                                <AgGridReact
                                    ref={gridRefRAList}
                                    columnDefs={RAListColumn}
                                    rowData={RAListRowData}
                                    defaultColDef={reportColDefRAList}
                                    rowHeight={50}
                                    // pagination={false}
                                    // paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={isProsList} onHide={setIsProsList} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as={'h6'}>CS1 prospect list</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={containerStyleProspectList}>
                        <div className={"ag-theme-quartz ag-op ag-op-secondary border rounded shadow-sm"} style={{ ...gridStyleProspectList }}>
                            <AgGridReact
                                ref={gridRefProspectList}
                                columnDefs={prosColumn}
                                rowData={prosRowData}
                                defaultColDef={reportColDefProspectList}
                                rowHeight={50}
                                // pagination={false}
                                // paginationPageSize={30}
                                paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default SRMDashboard;