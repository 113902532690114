import moment from "moment";
import CryptoJS from "crypto-js";
import { toJpeg } from "html-to-image";

export function setRelativeTime(data, lang = "en") {
  if (data === "0000-00-00 00:00:00") {
    return lang === "vi" ? "Chưa được cập nhật" : "Not updated yet";
  }

  const providedDate = moment(new Date(data));
  const currentDate = moment();
  const newDate = moment(new Date(data)).format("YYYY-MM-DD");
  const diffInDays = currentDate.diff(newDate, "days");

  if (diffInDays === 0) {
    return lang === "vi" ? `Hôm nay lúc ${providedDate.format("h:mm A")}` : `Today at ${providedDate.format("h:mm A")}`;
  } else if (diffInDays === 1) {
    return lang === "vi" ? `Hôm qua lúc ${providedDate.format("h:mm A")}` : `Yesterday at ${providedDate.format("h:mm A")}`;
  } else if (diffInDays <= 5) {
    return lang === "vi" ? `${diffInDays} ngày trước` : `${diffInDays} days ago`;
  } else {
    return providedDate.format("ll");
  }
}

export const countryCurrencyMap = {
  MY: { locale: "en-MY", currency: "MYR", symbol: "RM" },
  AUS: { locale: "en-AU", currency: "AUD", symbol: "AUD" },
  VN: { locale: "vi-VN", currency: "VND", symbol: "VND" },
};

export function setCurrency(data, countryCode = "MY") {
  if (countryCode == "vi") {
    const langToCountryCodeMap = {
      vi: "VN",
    };

    const getCountryCode = langToCountryCodeMap[countryCode] || "MY";
    const countryInfo = countryCurrencyMap[getCountryCode];

    if (!countryInfo) {
      throw new Error(`Unsupported country code: ${getCountryCode}`);
    }

    data = parseFloat(data);
    const formattedAmount = data.toLocaleString(countryInfo.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (countryInfo.symbol) {
      return `${countryInfo.symbol} ${formattedAmount}`;
    }
  } else {
    const countryInfo = countryCurrencyMap[countryCode] || countryCurrencyMap["MY"];
    data = Number(data);
    const formattedAmount = data.toLocaleString(countryInfo.locale, {
      style: "currency",
      currency: countryInfo.currency,
    });

    return formattedAmount;
  }
}

export function setDateStr(data) {
  if (data && data !== "0000-00-00 00:00:00" && data !== "0000-00-00") {
    var newDate = new Date(data);
    var momentDate = moment(newDate).format("ll");
    return momentDate || "-";
  }
}

export function setDateTimeStr(data) {
  var newDate = new Date(data);
  var momentDate = moment(newDate).format("lll");
  return momentDate;
}

export function setDateTimeStrVN(data) {
  var newDate = new Date(data);
  var momentDate = moment(newDate).subtract(1, "hour").format("lll");
  return momentDate;
}

export function setLatestTime(array) {
  var now = new Date();
  var latestTime = now.getHours() + ":" + (now.getMinutes() < 10 ? "0" : "") + now.getMinutes();

  function timeToMinutes(time) {
    var parts = time.split(":");
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  }

  var latestTimeInMinutes = timeToMinutes(latestTime);

  var filteredTimings = array.filter(function (timing) {
    return timeToMinutes(timing.value) > latestTimeInMinutes;
  });

  if (filteredTimings.length === 0) {
    return array[0]; // or handle the case when no timing is found
  }

  filteredTimings.sort(function (a, b) {
    return timeToMinutes(a.value) - timeToMinutes(b.value);
  });

  var nearestTime = filteredTimings[0];
  return nearestTime;
}

export function prefixList() {
  const prefix = [
    { label: "Prefix", value: "" },
    { label: "+61", value: "61" },
    { label: "+60", value: "60" },
    { label: "+65", value: "65" },
  ];

  return {
    list: prefix,
    selected: { label: "Prefix", value: "" },
  };
}

export const setEncryptData = (data) => {
  try {
    const password = "outperform123";

    // Convert data to Base64 before encryption
    const dataBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(data)));

    const encrypted = CryptoJS.AES.encrypt(dataBase64, password).toString();
    return encrypted;
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
};

export const setDecryptData = (data) => {
  try {
    const password = "outperform123";
    const decrypted = CryptoJS.AES.decrypt(data, password).toString(CryptoJS.enc.Utf8);

    // Convert decrypted data from Base64
    const decryptedBase64 = CryptoJS.enc.Base64.parse(decrypted).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedBase64);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

export const setHTMLtoImage = async (html) => {
  try {
    toJpeg(html, { cacheBust: false })
      .then(async (dataUrl) => {
        return dataUrl;
      })
      .catch((err) => {
        return err;
      });
  } catch (error) {
    return error;
  }
};

export const MobilePrefix = [
  { regionCode: "MY", label: "+60", value: "60" }, // Malaysia
  { regionCode: "AF", label: "+93", value: "93" }, // Afghanistan
  { regionCode: "AL", label: "+355", value: "355" }, // Albania
  { regionCode: "DZ", label: "+213", value: "213" }, // Algeria
  { regionCode: "AS", label: "+1684", value: "1684" }, // American Samoa
  { regionCode: "AD", label: "+376", value: "376" }, // Andorra
  { regionCode: "AO", label: "+244", value: "244" }, // Angola
  { regionCode: "AI", label: "+1264", value: "1264" }, // Anguilla
  { regionCode: "AQ", label: "+672", value: "672" }, // Antarctica
  { regionCode: "AG", label: "+1268", value: "1268" }, // Antigua and Barbuda
  { regionCode: "AR", label: "+54", value: "54" }, // Argentina
  { regionCode: "AM", label: "+374", value: "374" }, // Armenia
  { regionCode: "AW", label: "+297", value: "297" }, // Aruba
  { regionCode: "AU", label: "+61", value: "61" }, // Australia
  { regionCode: "AT", label: "+43", value: "43" }, // Austria
  { regionCode: "AZ", label: "+994", value: "994" }, // Azerbaijan
  { regionCode: "BS", label: "+1242", value: "1242" }, // Bahamas
  { regionCode: "BH", label: "+973", value: "973" }, // Bahrain
  { regionCode: "BD", label: "+880", value: "880" }, // Bangladesh
  { regionCode: "BB", label: "+1246", value: "1246" }, // Barbados
  { regionCode: "BY", label: "+375", value: "375" }, // Belarus
  { regionCode: "BE", label: "+32", value: "32" }, // Belgium
  { regionCode: "BZ", label: "+501", value: "501" }, // Belize
  { regionCode: "BJ", label: "+229", value: "229" }, // Benin
  { regionCode: "BM", label: "+1441", value: "1441" }, // Bermuda
  { regionCode: "BT", label: "+975", value: "975" }, // Bhutan
  { regionCode: "BO", label: "+591", value: "591" }, // Bolivia
  { regionCode: "BA", label: "+387", value: "387" }, // Bosnia and Herzegovina
  { regionCode: "BW", label: "+267", value: "267" }, // Botswana
  { regionCode: "BR", label: "+55", value: "55" }, // Brazil
  { regionCode: "IO", label: "+246", value: "246" }, // British Indian Ocean Territory
  { regionCode: "BN", label: "+673", value: "673" }, // Brunei Darussalam
  { regionCode: "BG", label: "+359", value: "359" }, // Bulgaria
  { regionCode: "BF", label: "+226", value: "226" }, // Burkina Faso
  { regionCode: "BI", label: "+257", value: "257" }, // Burundi
  { regionCode: "KH", label: "+855", value: "855" }, // Cambodia
  { regionCode: "CM", label: "+237", value: "237" }, // Cameroon
  { regionCode: "CA", label: "+1", value: "1" }, // Canada
  { regionCode: "CV", label: "+238", value: "238" }, // Cape Verde
  { regionCode: "KY", label: "+1345", value: "1345" }, // Cayman Islands
  { regionCode: "CF", label: "+236", value: "236" }, // Central African Republic
  { regionCode: "TD", label: "+235", value: "235" }, // Chad
  { regionCode: "CL", label: "+56", value: "56" }, // Chile
  { regionCode: "CN", label: "+86", value: "86" }, // China
  { regionCode: "CX", label: "+61", value: "61" }, // Christmas Island
  { regionCode: "CC", label: "+61", value: "61" }, // Cocos (Keeling) Islands
  { regionCode: "CO", label: "+57", value: "57" }, // Colombia
  { regionCode: "KM", label: "+269", value: "269" }, // Comoros
  { regionCode: "CG", label: "+242", value: "242" }, // Congo
  { regionCode: "CD", label: "+243", value: "243" }, // Congo, Democratic Republic of the
  { regionCode: "CK", label: "+682", value: "682" }, // Cook Islands
  { regionCode: "CR", label: "+506", value: "506" }, // Costa Rica
  { regionCode: "HR", label: "+385", value: "385" }, // Croatia
  { regionCode: "CU", label: "+53", value: "53" }, // Cuba
  { regionCode: "CY", label: "+357", value: "357" }, // Cyprus
  { regionCode: "CZ", label: "+420", value: "420" }, // Czech Republic
  { regionCode: "DK", label: "+45", value: "45" }, // Denmark
  { regionCode: "DJ", label: "+253", value: "253" }, // Djibouti
  { regionCode: "DM", label: "+1767", value: "1767" }, // Dominica
  { regionCode: "DO", label: "+1849", value: "1849" }, // Dominican Republic
  { regionCode: "EC", label: "+593", value: "593" }, // Ecuador
  { regionCode: "EG", label: "+20", value: "20" }, // Egypt
  { regionCode: "SV", label: "+503", value: "503" }, // El Salvador
  { regionCode: "GQ", label: "+240", value: "240" }, // Equatorial Guinea
  { regionCode: "ER", label: "+291", value: "291" }, // Eritrea
  { regionCode: "EE", label: "+372", value: "372" }, // Estonia
  { regionCode: "ET", label: "+251", value: "251" }, // Ethiopia
  { regionCode: "FK", label: "+500", value: "500" }, // Falkland Islands (Malvinas)
  { regionCode: "FO", label: "+298", value: "298" }, // Faroe Islands
  { regionCode: "FJ", label: "+679", value: "679" }, // Fiji
  { regionCode: "FI", label: "+358", value: "358" }, // Finland
  { regionCode: "FR", label: "+33", value: "33" }, // France
  { regionCode: "GF", label: "+594", value: "594" }, // French Guiana
  { regionCode: "PF", label: "+689", value: "689" }, // French Polynesia
  { regionCode: "TF", label: "+262", value: "262" }, // French Southern Territories
  { regionCode: "GA", label: "+241", value: "241" }, // Gabon
  { regionCode: "GM", label: "+220", value: "220" }, // Gambia
  { regionCode: "GE", label: "+995", value: "995" }, // Georgia
  { regionCode: "DE", label: "+49", value: "49" }, // Germany
  { regionCode: "GH", label: "+233", value: "233" }, // Ghana
  { regionCode: "GI", label: "+350", value: "350" }, // Gibraltar
  { regionCode: "GR", label: "+30", value: "30" }, // Greece
  { regionCode: "GL", label: "+299", value: "299" }, // Greenland
  { regionCode: "GD", label: "+1473", value: "1473" }, // Grenada
  { regionCode: "GP", label: "+590", value: "590" }, // Guadeloupe
  { regionCode: "GU", label: "+1671", value: "1671" }, // Guam
  { regionCode: "GT", label: "+502", value: "502" }, // Guatemala
  { regionCode: "GN", label: "+224", value: "224" }, // Guinea
  { regionCode: "GW", label: "+245", value: "245" }, // Guinea-Bissau
  { regionCode: "GY", label: "+592", value: "592" }, // Guyana
  { regionCode: "HT", label: "+509", value: "509" }, // Haiti
  { regionCode: "HM", label: "+672", value: "672" }, // Heard Island and McDonald Islands
  { regionCode: "VA", label: "+379", value: "379" }, // Holy See (Vatican City State)
  { regionCode: "HN", label: "+504", value: "504" }, // Honduras
  { regionCode: "HK", label: "+852", value: "852" }, // Hong Kong
  { regionCode: "HU", label: "+36", value: "36" }, // Hungary
  { regionCode: "IS", label: "+354", value: "354" }, // Iceland
  { regionCode: "IN", label: "+91", value: "91" }, // India
  { regionCode: "ID", label: "+62", value: "62" }, // Indonesia
  { regionCode: "IR", label: "+98", value: "98" }, // Iran, Islamic Republic of
  { regionCode: "IQ", label: "+964", value: "964" }, // Iraq
  { regionCode: "IE", label: "+353", value: "353" }, // Ireland
  { regionCode: "IL", label: "+972", value: "972" }, // Israel
  { regionCode: "IT", label: "+39", value: "39" }, // Italy
  { regionCode: "JM", label: "+1876", value: "1876" }, // Jamaica
  { regionCode: "JP", label: "+81", value: "81" }, // Japan
  { regionCode: "JO", label: "+962", value: "962" }, // Jordan
  { regionCode: "KZ", label: "+7", value: "7" }, // Kazakhstan
  { regionCode: "KE", label: "+254", value: "254" }, // Kenya
  { regionCode: "KI", label: "+686", value: "686" }, // Kiribati
  { regionCode: "KP", label: "+850", value: "850" }, // Korea, Democratic People's Republic of
  { regionCode: "KR", label: "+82", value: "82" }, // Korea, Republic of
  { regionCode: "KW", label: "+965", value: "965" }, // Kuwait
  { regionCode: "KG", label: "+996", value: "996" }, // Kyrgyzstan
  { regionCode: "LA", label: "+856", value: "856" }, // Lao People's Democratic Republic
  { regionCode: "LV", label: "+371", value: "371" }, // Latvia
  { regionCode: "LB", label: "+961", value: "961" }, // Lebanon
  { regionCode: "LS", label: "+266", value: "266" }, // Lesotho
  { regionCode: "LR", label: "+231", value: "231" }, // Liberia
  { regionCode: "LY", label: "+218", value: "218" }, // Libyan Arab Jamahiriya
  { regionCode: "LI", label: "+423", value: "423" }, // Liechtenstein
  { regionCode: "LT", label: "+370", value: "370" }, // Lithuania
  { regionCode: "LU", label: "+352", value: "352" }, // Luxembourg
  { regionCode: "MO", label: "+853", value: "853" }, // Macao
  { regionCode: "MK", label: "+389", value: "389" }, // Macedonia, the Former Yugoslav Republic of
  { regionCode: "MG", label: "+261", value: "261" }, // Madagascar
  { regionCode: "MW", label: "+265", value: "265" }, // Malawi
  { regionCode: "MV", label: "+960", value: "960" }, // Maldives
  { regionCode: "ML", label: "+223", value: "223" }, // Mali
  { regionCode: "MT", label: "+356", value: "356" }, // Malta
  { regionCode: "MH", label: "+692", value: "692" }, // Marshall Islands
  { regionCode: "MQ", label: "+596", value: "596" }, // Martinique
  { regionCode: "MR", label: "+222", value: "222" }, // Mauritania
  { regionCode: "MU", label: "+230", value: "230" }, // Mauritius
  { regionCode: "YT", label: "+262", value: "262" }, // Mayotte
  { regionCode: "MX", label: "+52", value: "52" }, // Mexico
  { regionCode: "FM", label: "+691", value: "691" }, // Micronesia, Federated States of
  { regionCode: "MD", label: "+373", value: "373" }, // Moldova, Republic of
  { regionCode: "MC", label: "+377", value: "377" }, // Monaco
  { regionCode: "MN", label: "+976", value: "976" }, // Mongolia
  { regionCode: "MS", label: "+1664", value: "1664" }, // Montserrat
  { regionCode: "MA", label: "+212", value: "212" }, // Morocco
  { regionCode: "MZ", label: "+258", value: "258" }, // Mozambique
  { regionCode: "MM", label: "+95", value: "95" }, // Myanmar
  { regionCode: "NA", label: "+264", value: "264" }, // Namibia
  { regionCode: "NR", label: "+674", value: "674" }, // Nauru
  { regionCode: "NP", label: "+977", value: "977" }, // Nepal
  { regionCode: "NL", label: "+31", value: "31" }, // Netherlands
  { regionCode: "AN", label: "+599", value: "599" }, // Netherlands Antilles
  { regionCode: "NC", label: "+687", value: "687" }, // New Caledonia
  { regionCode: "NZ", label: "+64", value: "64" }, // New Zealand
  { regionCode: "NI", label: "+505", value: "505" }, // Nicaragua
  { regionCode: "NE", label: "+227", value: "227" }, // Niger
  { regionCode: "NG", label: "+234", value: "234" }, // Nigeria
  { regionCode: "NU", label: "+683", value: "683" }, // Niue
  { regionCode: "NF", label: "+672", value: "672" }, // Norfolk Island
  { regionCode: "MP", label: "+1670", value: "1670" }, // Northern Mariana Islands
  { regionCode: "NO", label: "+47", value: "47" }, // Norway
  { regionCode: "OM", label: "+968", value: "968" }, // Oman
  { regionCode: "PK", label: "+92", value: "92" }, // Pakistan
  { regionCode: "PW", label: "+680", value: "680" }, // Palau
  { regionCode: "PS", label: "+970", value: "970" }, // Palestinian Territory, Occupied
  { regionCode: "PA", label: "+507", value: "507" }, // Panama
  { regionCode: "PG", label: "+675", value: "675" }, // Papua New Guinea
  { regionCode: "PY", label: "+595", value: "595" }, // Paraguay
  { regionCode: "PE", label: "+51", value: "51" }, // Peru
  { regionCode: "PH", label: "+63", value: "63" }, // Philippines
  { regionCode: "PN", label: "+870", value: "870" }, // Pitcairn
  { regionCode: "PL", label: "+48", value: "48" }, // Poland
  { regionCode: "PT", label: "+351", value: "351" }, // Portugal
  { regionCode: "PR", label: "+1787", value: "1787" }, // Puerto Rico
  { regionCode: "QA", label: "+974", value: "974" }, // Qatar
  { regionCode: "RE", label: "+262", value: "262" }, // Reunion
  { regionCode: "RO", label: "+40", value: "40" }, // Romania
  { regionCode: "RU", label: "+7", value: "7" }, // Russian Federation
  { regionCode: "RW", label: "+250", value: "250" }, // Rwanda
  { regionCode: "SH", label: "+290", value: "290" }, // Saint Helena
  { regionCode: "KN", label: "+1869", value: "1869" }, // Saint Kitts and Nevis
  { regionCode: "LC", label: "+1758", value: "1758" }, // Saint Lucia
  { regionCode: "PM", label: "+508", value: "508" }, // Saint Pierre and Miquelon
  { regionCode: "VC", label: "+1784", value: "1784" }, // Saint Vincent and the Grenadines
  { regionCode: "WS", label: "+684", value: "684" }, // Samoa
  { regionCode: "SM", label: "+378", value: "378" }, // San Marino
  { regionCode: "ST", label: "+239", value: "239" }, // Sao Tome and Principe
  { regionCode: "SA", label: "+966", value: "966" }, // Saudi Arabia
  { regionCode: "SN", label: "+221", value: "221" }, // Senegal
  { regionCode: "CS", label: "+381", value: "381" }, // Serbia and Montenegro
  { regionCode: "SC", label: "+248", value: "248" }, // Seychelles
  { regionCode: "SL", label: "+232", value: "232" }, // Sierra Leone
  { regionCode: "SG", label: "+65", value: "65" }, // Singapore
  { regionCode: "SK", label: "+421", value: "421" }, // Slovakia
  { regionCode: "SI", label: "+386", value: "386" }, // Slovenia
  { regionCode: "SB", label: "+677", value: "677" }, // Solomon Islands
  { regionCode: "SO", label: "+252", value: "252" }, // Somalia
  { regionCode: "ZA", label: "+27", value: "27" }, // South Africa
  { regionCode: "GS", label: "+500", value: "500" }, // South Georgia and the South Sandwich Islands
  { regionCode: "ES", label: "+34", value: "34" }, // Spain
  { regionCode: "LK", label: "+94", value: "94" }, // Sri Lanka
  { regionCode: "SD", label: "+249", value: "249" }, // Sudan
  { regionCode: "SR", label: "+597", value: "597" }, // Suriname
  { regionCode: "SJ", label: "+47", value: "47" }, // Svalbard and Jan Mayen
  { regionCode: "SZ", label: "+268", value: "268" }, // Swaziland
  { regionCode: "SE", label: "+46", value: "46" }, // Sweden
  { regionCode: "CH", label: "+41", value: "41" }, // Switzerland
  { regionCode: "SY", label: "+963", value: "963" }, // Syrian Arab Republic
  { regionCode: "TW", label: "+886", value: "886" }, // Taiwan, Province of China
  { regionCode: "TJ", label: "+992", value: "992" }, // Tajikistan
  { regionCode: "TZ", label: "+255", value: "255" }, // Tanzania, United Republic of
  { regionCode: "TH", label: "+66", value: "66" }, // Thailand
  { regionCode: "TL", label: "+670", value: "670" }, // Timor-Leste
  { regionCode: "TG", label: "+228", value: "228" }, // Togo
  { regionCode: "TK", label: "+690", value: "690" }, // Tokelau
  { regionCode: "TO", label: "+676", value: "676" }, // Tonga
  { regionCode: "TT", label: "+1868", value: "1868" }, // Trinidad and Tobago
  { regionCode: "TN", label: "+216", value: "216" }, // Tunisia
  { regionCode: "TR", label: "+90", value: "90" }, // Turkey
  { regionCode: "TM", label: "+993", value: "993" }, // Turkmenistan
  { regionCode: "TC", label: "+1649", value: "1649" }, // Turks and Caicos Islands
  { regionCode: "TV", label: "+688", value: "688" }, // Tuvalu
  { regionCode: "UG", label: "+256", value: "256" }, // Uganda
  { regionCode: "UA", label: "+380", value: "380" }, // Ukraine
  { regionCode: "AE", label: "+971", value: "971" }, // United Arab Emirates
  { regionCode: "GB", label: "+44", value: "44" }, // United Kingdom
  { regionCode: "US", label: "+1", value: "1" }, // United States
  { regionCode: "UY", label: "+598", value: "598" }, // Uruguay
  { regionCode: "UZ", label: "+998", value: "998" }, // Uzbekistan
  { regionCode: "VU", label: "+678", value: "678" }, // Vanuatu
  { regionCode: "VE", label: "+58", value: "58" }, // Venezuela
  { regionCode: "VN", label: "+84", value: "84" }, // Viet Nam
  { regionCode: "VG", label: "+1284", value: "1284" }, // Virgin Islands, British
  { regionCode: "VI", label: "+1340", value: "1340" }, // Virgin Islands, U.S.
  { regionCode: "WF", label: "+681", value: "681" }, // Wallis and Futuna
  { regionCode: "EH", label: "+212", value: "212" }, // Western Sahara
  { regionCode: "YE", label: "+967", value: "967" }, // Yemen
  { regionCode: "ZM", label: "+260", value: "260" }, // Zambia
  { regionCode: "ZW", label: "+263", value: "263" }, // Zimbabwe
];

export const financingList = [
  { label: "Select financing", value: "" },
  { label: "Bank", value: "bank" },
  { label: "Cash", value: "cash" },
  { label: "Government Loan", value: "government_loan" },
  { label: "Staff Loan", value: "staff_loan" },
  { label: "Rent To Own", value: "other" },
];

export const firstTimeBuyerList = [
  { label: "Select first time buyer", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const bumiputeraList = [
  { label: "Select bumiputera", value: "" },
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

export const paymentMethodList = [
  { label: "Select payment method", value: "" },
  { label: "Online Banking", value: "bank_draft" },
  { label: "Cash", value: "cash" },
  { label: "Credit Card", value: "cc" },
  { label: "Cheque", value: "cheque" },
  { label: "Others", value: "others" },
];

export const salutationList = [
  { label: "Select salutation", value: "" },
  { label: "Madam", value: "Madam" },
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Datin", value: "Datin" },
  { label: "Datin Seri", value: "Datin Seri" },
  { label: "Dato", value: "Dato" },
  { label: "Dato Seri", value: "Dato Seri" },
  { label: "Dr", value: "Dr" },
  { label: "Prof", value: "Prof" },
  { label: "Puan", value: "Puan" },
  { label: "Puan Sri", value: "Puan Sri" },
  { label: "Tan Sri", value: "Tan Sri" },
  { label: "Others", value: "Others" },
];

export const identityTypeList = [
  { label: "Select identity type", value: "" },
  { label: "NRIC", value: "NRIC" },
  { label: "Passport", value: "Passport" },
  { label: "Military", value: "Military" },
  { label: "Company Registration Number", value: "Company Registration Number" },
];

export const occupationList = [
  { label: "Select occupation", value: "" },
  { label: "Accountant", value: "Accountant" },
  { label: "Accounts Executive", value: "Accounts Executive" },
  { label: "Accounts Manager", value: "Accounts Manager" },
  { label: "Admin Human Resources", value: "Admin Human Resources" },
  { label: "Admin Officer", value: "Admin Officer" },
  { label: "Advertising", value: "Advertising" },
  { label: "Air Hostess", value: "Air Hostess" },
  { label: "Air Steward", value: "Air Steward" },
  { label: "Analyst", value: "Analyst" },
  { label: "Architect", value: "Architect" },
  { label: "Assistant General Manager", value: "Assistant General Manager" },
  { label: "Assistant Manager", value: "Assistant Manager" },
  { label: "Associate Director", value: "Associate Director" },
  { label: "Asst Vice President", value: "Asst Vice President" },
  { label: "Auditors", value: "Auditors" },
  { label: "Banker", value: "Banker" },
  { label: "Banking & Investment", value: "Banking & Investment" },
  { label: "Bio Engineer", value: "Bio Engineer" },
  { label: "Businessman", value: "Businessman" },
  { label: "Businesswomen", value: "Businesswomen" },
  { label: "Chairman", value: "Chairman" },
  { label: "Chef", value: "Chef" },
  { label: "Chief Executive Officer", value: "Chief Executive Officer" },
  { label: "Chief Operating Officer", value: "Chief Operating Officer" },
  { label: "Clerical", value: "Clerical" },
  { label: "Clerk", value: "Clerk" },
  { label: "Clerk of Works", value: "Clerk of Works" },
  { label: "Company Director", value: "Company Director" },
  { label: "Consultant", value: "Consultant" },
  { label: "Contractor", value: "Contractor" },
  { label: "Contractor/Consultant", value: "Contractor/Consultant" },
  { label: "Corporate Secretary", value: "Corporate Secretary" },
  { label: "Creative Director", value: "Creative Director" },
  { label: "Customer Service", value: "Customer Service" },
  { label: "Dentist", value: "Dentist" },
  { label: "Designer/ ID", value: "Designer/ ID" },
  { label: "Despatch Clerk", value: "Despatch Clerk" },
  { label: "Director", value: "Director" },
  { label: "Doctor", value: "Doctor" },
  { label: "Engineer", value: "Engineer" },
  { label: "Executive", value: "Executive" },
  { label: "Executive Director", value: "Executive Director" },
  { label: "Financial Controller", value: "Financial Controller" },
  { label: "General Manager", value: "General Manager" },
  { label: "Government Servant", value: "Government Servant" },
  { label: "Group Director", value: "Group Director" },
  { label: "Group Financial Controller", value: "Group Financial Controller" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Homemaker", value: "Homemaker" },
  { label: "Hotelier", value: "Hotelier" },
  { label: "Housewife", value: "Housewife" },
  { label: "Insurance", value: "Insurance" },
  { label: "Insurance Agent", value: "Insurance Agent" },
  { label: "Interior Designer", value: "Interior Designer" },
  { label: "IT & Computer Science", value: "IT & Computer Science" },
  { label: "IT Analyst", value: "IT Analyst" },
  { label: "IT Consultant", value: "IT Consultant" },
  { label: "IT Executive", value: "IT Executive" },
  { label: "IT Manager", value: "IT Manager" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Lecturer", value: "Lecturer" },
  { label: "Management", value: "Management" },
  { label: "Manager", value: "Manager" },
  { label: "Managing Director", value: "Managing Director" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Marketing Executive", value: "Marketing Executive" },
  { label: "Mechanic", value: "Mechanic" },
  { label: "Medical Doctor", value: "Medical Doctor" },
  { label: "Medical Sales Consultant", value: "Medical Sales Consultant" },
  { label: "Medical/Doctor", value: "Medical/Doctor" },
  { label: "Middle Management Post", value: "Middle Management Post" },
  { label: "Nurse", value: "Nurse" },
  { label: "Office Administrator", value: "Office Administrator" },
  { label: "Officer", value: "Officer" },
  { label: "Oil & Gas Fields", value: "Oil & Gas Fields" },
  { label: "Operator", value: "Operator" },
  { label: "OTR Professional", value: "OTR Professional" },
  { label: "Personel Assistant (PA)", value: "Personel Assistant (PA)" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Photographer", value: "Photographer" },
  { label: "Pilot", value: "Pilot" },
  { label: "Portfolio Specialist", value: "Portfolio Specialist" },
  { label: "Principal", value: "Principal" },
  { label: "Product Consultant", value: "Product Consultant" },
  { label: "Professional", value: "Professional" },
  { label: "Programmer", value: "Programmer" },
  { label: "Project Executive", value: "Project Executive" },
  { label: "Project Foreman", value: "Project Foreman" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Property/Construction", value: "Property/Construction" },
  { label: "Quantity Surveyor", value: "Quantity Surveyor" },
  { label: "Restaurateur", value: "Restaurateur" },
  { label: "Retiree", value: "Retiree" },
  { label: "Sales & Marketing", value: "Sales & Marketing" },
  { label: "Sales Engineer", value: "Sales Engineer" },
  { label: "Sales Executive", value: "Sales Executive" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Scientist", value: "Scientist" },
  { label: "Secretarial", value: "Secretarial" },
  { label: "Secretary", value: "Secretary" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Senior Clerk of Works", value: "Senior Clerk of Works" },
  { label: "Senior Consultant", value: "Senior Consultant" },
  { label: "Senior Executive", value: "Senior Executive" },
  { label: "Senior General Manager", value: "Senior General Manager" },
  { label: "Senior Lecturer", value: "Senior Lecturer" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Senior Quantity Surveyor", value: "Senior Quantity Surveyor" },
  { label: "Senior Sales Executive", value: "Senior Sales Executive" },
  { label: "Solicitor", value: "Solicitor" },
  { label: "Stewardess", value: "Stewardess" },
  { label: "Student", value: "Student" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Teacher", value: "Teacher" },
  { label: "Technical Trainer", value: "Technical Trainer" },
  { label: "Technician", value: "Technician" },
  { label: "Top Management Post", value: "Top Management Post" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Unknown", value: "Unknown" },
  { label: "Veterinarian", value: "Veterinarian" },
  { label: "Vice President", value: "Vice President" },
  { label: "Others", value: "Others" },
];

export const genderList = [
  { label: "Select gender", value: "" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  // { label: "Other", value: "other" },
];

export const raceList = [
  { label: "Select race", value: "" },
  { label: "Malay", value: "Malay" },
  { label: "Chinese", value: "Chinese" },
  { label: "Indian", value: "Indian" },
  { label: "Bidayuh", value: "Bidayuh" },
  { label: "Bumiputra", value: "Bumiputra" },
  { label: "Caucasian", value: "Caucasian" },
  { label: "Chinese Muslim", value: "Chinese Muslim" },
  { label: "Dutch", value: "Dutch" },
  { label: "Eurasian", value: "Eurasian" },
  { label: "European", value: "European" },
  { label: "Foreigner", value: "Foreigner" },
  { label: "Hellenic", value: "Hellenic" },
  { label: "Kadazan", value: "Kadazan" },
  { label: "Philippines", value: "Philippines" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Sino-kadazan", value: "Sino-kadazan" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Others", value: "Others" },
];

export const nationalityList = [
  { label: "Select nationality", value: "" },
  { label: "Malaysian", value: "Malaysian" },
  { label: "Afghan", value: "Afghan" },
  { label: "Albanian", value: "Albanian" },
  { label: "Algerian", value: "Algerian" },
  { label: "American", value: "American" },
  { label: "Andorran", value: "Andorran" },
  { label: "Angolan", value: "Angolan" },
  { label: "Anguillan", value: "Anguillan" },
  { label: "Argentine", value: "Argentine" },
  { label: "Armenian", value: "Armenian" },
  { label: "Australian", value: "Australian" },
  { label: "Austrian", value: "Austrian" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bahamian", value: "Bahamian" },
  { label: "Bahraini", value: "Bahraini" },
  { label: "Bangladeshi", value: "Bangladeshi" },
  { label: "Barbadian", value: "Barbadian" },
  { label: "Belarusian", value: "Belarusian" },
  { label: "Belgian", value: "Belgian" },
  { label: "Belizean", value: "Belizean" },
  { label: "Bermudian", value: "Bermudian" },
  { label: "Bhutanese", value: "Bhutanese" },
  { label: "Bolivian", value: "Bolivian" },
  { label: "Botswanan", value: "Botswanan" },
  { label: "Brazilian", value: "Brazilian" },
  { label: "British", value: "British" },
  { label: "British Virgin Islander", value: "British Virgin Islander" },
  { label: "Bruneian", value: "Bruneian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Burundian", value: "Burundian" },
  { label: "Cambodian", value: "Cambodian" },
  { label: "Canadian", value: "Canadian" },
  { label: "Chinese", value: "Chinese" },
  { label: "Cook Islander", value: "Cook Islander" },
  { label: "Costa Rican", value: "Costa Rican" },
  { label: "Croatian", value: "Croatian" },
  { label: "Cuban", value: "Cuban" },
  { label: "Cymro", value: "Cymro" },
  { label: "Cypriot", value: "Cypriot" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Dominican", value: "Dominican" },
  { label: "Dutch", value: "Dutch" },
  { label: "East Timorese", value: "East Timorese" },
  { label: "Ecuadorean", value: "Ecuadorean" },
  { label: "Egyptian", value: "Egyptian" },
  { label: "Emirati", value: "Emirati" },
  { label: "English", value: "English" },
  { label: "Equatorial Guinean", value: "Equatorial Guinean" },
  { label: "Estonian", value: "Estonian" },
  { label: "Ethiopian", value: "Ethiopian" },
  { label: "Faroese", value: "Faroese" },
  { label: "Fijian", value: "Fijian" },
  { label: "Filipino", value: "Filipino" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "Georgian", value: "Georgian" },
  { label: "German", value: "German" },
  { label: "Ghanaian", value: "Ghanaian" },
  { label: "Gibraltarian", value: "Gibraltarian" },
  { label: "Greek", value: "Greek" },
  { label: "Grenadian", value: "Grenadian" },
  { label: "Guinean", value: "Guinean" },
  { label: "Guyanese", value: "Guyanese" },
  { label: "Haitian", value: "Haitian" },
  { label: "Hong Konger", value: "Hong Konger" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Indian", value: "Indian" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Iranian", value: "Iranian" },
  { label: "Iraqi", value: "Iraqi" },
  { label: "Irish", value: "Irish" },
  { label: "Italian", value: "Italian" },
  { label: "Jamaican", value: "Jamaican" },
  { label: "Japanese", value: "Japanese" },
  { label: "Jordanian", value: "Jordanian" },
  { label: "Kazakh", value: "Kazakh" },
  { label: "Kenyan", value: "Kenyan" },
  { label: "Latvian", value: "Latvian" },
  { label: "Lebanese", value: "Lebanese" },
  { label: "Liberian", value: "Liberian" },
  { label: "Libyan", value: "Libyan" },
  { label: "Luxembourger", value: "Luxembourger" },
  { label: "Maldivian", value: "Maldivian" },
  { label: "Maltese", value: "Maltese" },
  { label: "Marshallese", value: "Marshallese" },
  { label: "Martiniquais", value: "Martiniquais" },
  { label: "Mauritanian", value: "Mauritanian" },
  { label: "Mauritian", value: "Mauritian" },
  { label: "Mexican", value: "Mexican" },
  { label: "Moroccan", value: "Moroccan" },
  { label: "Mozambican", value: "Mozambican" },
  { label: "Namibian", value: "Namibian" },
  { label: "Nepalese", value: "Nepalese" },
  { label: "New Zealander", value: "New Zealander" },
  { label: "Nicaraguan", value: "Nicaraguan" },
  { label: "Nigerian", value: "Nigerian" },
  { label: "Nigerien", value: "Nigerien" },
  { label: "Niuean", value: "Niuean" },
  { label: "North Korean", value: "North Korean" },
  { label: "Northern Irish", value: "Northern Irish" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Pakistani", value: "Pakistani" },
  { label: "Palauan", value: "Palauan" },
  { label: "Palestinian", value: "Palestinian" },
  { label: "Papua New Guinean", value: "Papua New Guinean" },
  { label: "Paraguayan", value: "Paraguayan" },
  { label: "Peruvian", value: "Peruvian" },
  { label: "Pitcairn Islander", value: "Pitcairn Islander" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Puerto Rican", value: "Puerto Rican" },
  { label: "Qatari", value: "Qatari" },
  { label: "Romanian", value: "Romanian" },
  { label: "Russian", value: "Russian" },
  { label: "Saudi Arabian", value: "Saudi Arabian" },
  { label: "Scottish", value: "Scottish" },
  { label: "Senegalese", value: "Senegalese" },
  { label: "Serbian", value: "Serbian" },
  { label: "Sierra Leonean", value: "Sierra Leonean" },
  { label: "Singaporean", value: "Singaporean" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovenian", value: "Slovenian" },
  { label: "Solomon Islander", value: "Solomon Islander" },
  { label: "Somali", value: "Somali" },
  { label: "South African", value: "South African" },
  { label: "South Korean", value: "South Korean" },
  { label: "Spanish", value: "Spanish" },
  { label: "Sri Lankan", value: "Sri Lankan" },
  { label: "Swedish", value: "Swedish" },
  { label: "Swiss", value: "Swiss" },
  { label: "Syrian", value: "Syrian" },
  { label: "Taiwanese", value: "Taiwanese" },
  { label: "Tanzanian", value: "Tanzanian" },
  { label: "Thai", value: "Thai" },
  { label: "Turkish", value: "Turkish" },
  { label: "Ugandan", value: "Ugandan" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Vatican citizen", value: "Vatican citizen" },
  { label: "Venezuelan", value: "Venezuelan" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Zimbabwean", value: "Zimbabwean" },
  { label: "Others", value: "Others" },
];

export const maritalStatusList = [
  { label: "Select marital status", value: "" },
  { label: "Married", value: "married" },
  { label: "Single", value: "single" },
];

export const relationList = [
  { label: "Select relation", value: "" },
  { label: "Spouse", value: "Spouse" },
  { label: "Parent", value: "Parent" },
  { label: "Child", value: "Child" },
  { label: "Sibling", value: "Sibling" },
  { label: "Grandparent", value: "Grandparent" },
  { label: "Grandchild", value: "Grandchild" },
  { label: "Aunt", value: "Aunt" },
  { label: "Uncle", value: "Uncle" },
  { label: "Niece", value: "Niece" },
  { label: "Nephew", value: "Nephew" },
  { label: "Cousin", value: "Cousin" },
  { label: "In-Law", value: "In-Law" },
  { label: "Friend", value: "Friend" },
  { label: "Neighbor", value: "Neighbor" },
  { label: "Guardian", value: "Guardian" },
  { label: "Colleague", value: "Colleague" },
  { label: "Employer", value: "Employer" },
  { label: "Employee", value: "Employee" },
  { label: "Others", value: "Others" },
];
