import { Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "../lib/scss/op-deal-pipeline.scss";
import axios from "../api/axios";
import { memo, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { setCurrency } from "../lib/js/Function";
import { faGripDotsVertical, faShieldExclamation, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeal } from "../auth/DealContext";
import { faChartKanban } from "@fortawesome/pro-duotone-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2";
import { t } from "i18next";
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, useDroppable, closestCorners, DragOverlay, useDraggable } from "@dnd-kit/core";

const OwnerAvatar = ({ url, user_name }) => {
  const { session } = useAuth();
  const [error, setError] = useState(false);
  if (error) {
    return <img className="d-block rounded-circle me-2" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/${url}?c=${Math.random()}`} width={30} height={30} onError={() => setError(true)} />;
  }

  return (
    <div className="avatar" style={{ alignItems: "start" }}>
      <div className="avatar-img op-primary-color" style={{ width: 30, height: 30, backgroundColor: "#eee" }}>
        <div className="avatar-txt text-uppercase fs-5 text-light">{user_name.charAt(0)}</div>
      </div>
    </div>
  );
};

const DealPriority = memo(({ rating, onchangePriority, deal_id }) => {
  const [hoverRating, setHoverRating] = useState(null);
  const [rate, setRate] = useState(rating);

  return (
    <div className="d-flex align-items-center">
      {[...Array(5)].map((star, index) => {
        const currentRating = index + 1;
        return (
          <label key={index} style={{ zIndex: 1 }}>
            <input
              className="d-none"
              type="radio"
              name="rating"
              value={currentRating}
              onClick={() => {
                setRate(currentRating);
                onchangePriority(deal_id, currentRating);
              }}
            />
            <FontAwesomeIcon icon={faStar} style={{ color: currentRating <= (hoverRating || rate) ? "#ffd43b" : "#ddd", cursor: "pointer" }} onMouseEnter={() => setHoverRating(currentRating)} onMouseLeave={() => setHoverRating(null)} />
          </label>
        );
      })}
    </div>
  );
});

const KanbanColumn = ({ record, index, setCurrency, setDouble, pipeList, sendMoveStage, sendPriority, onLoadMore, openDeals }) => {
  const { isOver, setNodeRef } = useDroppable({ id: record.id, data: record });

  return (
    <div ref={setNodeRef} className={`kanban-item ${isOver ? "kanban-hightlight" : ""}`}>
      <KanbanHeader record={record} index={index} setCurrency={setCurrency} setDouble={setDouble} pipeList={pipeList} />
      <KanbanBody id={record.id} title={record.title} customer={record.customer} index={index} record={record} sendMoveStage={sendMoveStage} sendPriority={sendPriority} onLoadMore={onLoadMore} openDeals={openDeals} />
    </div>
  );
};

const KanbanHeader = ({ record, index, highlightedIndex, setCurrency, setDouble, pipeList }) => {
  const { session } = useAuth();
  const [isHovered, setIsHovered] = useState(false);

  const extractPercentValue = (description) => {
    const percentMatch = description.match(/(\d+%)/);
    return percentMatch ? percentMatch[0] : null;
  };

  const renderTooltip = (props, text) => <Tooltip {...props}>{text}</Tooltip>;

  return (
    <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, record.cs_title)}>
      <div className="kanban-header shadow-sm" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {index !== 0 && (
          <svg aria-hidden="true" width="9" height="63" viewBox="0 0 9 63" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" className="arrow-left">
            <rect width="8" height="62" transform="translate(0.905762 0.165466)" fill="white" stroke="white" />
            <path fill={highlightedIndex === index ? "#ddd" : "rgb(243, 243, 243)"} stroke="transparent" d="M1.52703 2.65054C1.21146 1.38824 2.16617 0.165466 3.46731 0.165466H8.90576V32.1655V62.1655H0.905762L8.90576 32.1655L1.52703 2.65054Z" />
          </svg>
        )}

        <div className="kanban-header-content">
          <h6 className="fw-bold" style={{ fontSize: 13 }}>
            {record.cs_title}
          </h6>

          {isHovered ? (
            <p className="text-muted">
              {record.probability}% of {setCurrency(record.probability_value, session.default_lang)}
            </p>
          ) : (
            <p className="text-muted">
              {setCurrency(record.deal_value, session.default_lang)} · {record.deal_count > 1 ? t("DealList_record_many", { count: record.deal_count }) : record.deal_count === 1 ? t("DealList_record_one", { count: record.deal_count }) : t("DealList_record_none")}
            </p>
          )}
        </div>
        {index !== pipeList.length - 1 && (
          <svg aria-hidden="true" width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" className="arrow-right">
            <rect width="9.3999" height="62" transform="translate(0.495605 0.845825)" fill="white" stroke="transparent" />
            <path fill={highlightedIndex === index ? "#ddd" : "rgb(243, 243, 243)"} d="M0.495605 0.845825H1.19556V0.845825C1.60692 0.845825 1.9655 1.12579 2.06527 1.52488L9.89551 32.8458L1.89551 62.8458H0.495667L0.495605 0.845825Z" />
          </svg>
        )}
      </div>
    </OverlayTrigger>
  );
};

const KanbanBody = ({ customer, index, record, sendPriority, onLoadMore, openDeals }) => {
  const kanbanRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleLoadMore = async (record, index) => {
    if (loading) return;
    if (Number(record.totalRecord) <= Number(record.currentTotalRecord)) return;
    setLoading(true);
    try {
      const loadMore = await onLoadMore(record, index);
      if (loadMore) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleScroll = () => {
    if (kanbanRef.current) {
      const currentHeight = kanbanRef.current.scrollHeight - kanbanRef.current.scrollTop;
      const containerHeight = kanbanRef.current.clientHeight;
      if (Number(Math.floor(currentHeight)) === Number(containerHeight)) {
        handleLoadMore(record, index);
      }
    }
  };

  return (
    <div ref={kanbanRef} className="kanban-body w-100 p-2 position-relative overflow-y-auto" style={{ overflowX: "hidden" }} onScroll={handleScroll}>
      {customer.map((rec) => (
        <KanbanItem key={rec.id} rec={rec} id={rec.id} title={rec.title} openDeals={openDeals} setCurrency={setCurrency} sendPriority={sendPriority} />
      ))}
      {loading && (
        <>
          <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 2 }}>
            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
          <div style={{ padding: "10px 0" }} />
        </>
      )}
    </div>
  );
};

const KanbanItem = ({ id, title, rec, openDeals, setCurrency, sendPriority }) => {
  const { session } = useAuth();
  const [requireAttention, setRequireAttention] = useState(Number(rec.require_attention) === 1);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useDraggable({
    id: id,
    data: rec,
  });

  const handleOpenDeal = () => {
    if (requireAttention) {
      setRequireAttention(false);
    }
  };

  const style = {
    borderLeft: rec.deal_status === "new" ? `5px solid #dc3545` : rec.deal_status === "inactive" ? "5px solid #ffc107" : rec.deal_status === "active" ? "5px solid #198754" : "5px solid #6c757d",
    backgroundColor: requireAttention ? "rgba(220, 53, 69, 0.2)" : "#fff",
  };

  return (
    <div ref={setNodeRef} id={id}>
      <Card className={`shadow-sm mb-3 cursor-pointer`} style={style} onClick={() => handleOpenDeal(rec)}>
        <Card.Header {...attributes} {...listeners} className="py-1 d-flex justify-content-center" style={{ cursor: isDragging ? "grabbing" : "grab" }}>
          <FontAwesomeIcon icon={faGripDotsVertical} rotation={90} size="sm" />
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center" onClick={() => openDeals(rec)}>
            <OwnerAvatar url={rec.user_avatar_url} user_name={rec.owner_name} />
            <div className="ms-2 w-100">
              <h6 className="text-dark mb-0 fw-bold" style={{ fontSize: 13, lineHeight: 1.5, maxWidth: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {title}
              </h6>
              {rec.organization_title && (
                <p className="mb-0 text-muted" style={{ fontSize: 12, lineHeight: 1, maxWidth: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {rec.organization_title}
                </p>
              )}
            </div>
          </div>

          <div className="mt-2 d-flex justify-content-between align-items-center">
            {rec.deal_value && <p className="mb-0 mt-1">{setCurrency(rec.deal_value, session.default_lang)}</p>}
            <DealPriority rating={rec.deal_priority} onchangePriority={(deal_id, currentRating) => sendPriority(deal_id, currentRating, rec.cs_id)} deal_id={rec.deal_id} cs_id={rec.cs_id} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const DealPipeline = () => {
  const { session } = useAuth();
  const { pipeData, pipeList, setPipeList, getAllPipe, getPipeUser, pipeUser, loading, isNoRecord, getDeals, dealCache, setDealCache } = useDeal();

  const sendPriority = async (deal_id, priority, cs_id) => {
    try {
      const currentList = [...pipeList];
      const currentColumnIdx = currentList.findIndex((column) => column.id === cs_id);
      const currentDealIdx = currentList[currentColumnIdx].customer.findIndex((column) => column.id === deal_id);
      currentList[currentColumnIdx].customer[currentDealIdx].deal_priority = priority;
      setPipeList(currentList);

      await axios.get("ws/ws_deal.php", {
        params: {
          task: 34,
          priority: priority,
          deal: deal_id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setDouble = (text) => {
    if (text) {
      const result = text.replace(/,/g, "");
      return result;
    }

    return 0;
  };

  const sendMoveStage = async (event, currentList, overColumnIdx, currentDealIdx, currentColumnIdx, currentCS) => {
    try {
      const response = await axios.get("ws/ws_stage.php", {
        params: {
          task: "checkStageEnabled",
          csid: event.over.id,
          dealid: event.active.data.current.deal_id,
          prospectid: event.active.data.current.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 7,
            user: session.user_id,
            deal: event.active.data.current.deal_id,
            status: event.active.data.current.cs_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });
      } else {
        const movedItem = currentList[overColumnIdx].customer.splice(currentDealIdx, 1)[0];
        movedItem.cs_id = currentCS;
        currentList[currentColumnIdx].customer.unshift(movedItem);

        setPipeList(currentList);
        var element = data.record.map((record, index) => (
          <h6 key={index} className="d-flex">
            <FontAwesomeIcon icon={faShieldExclamation} className="me-2" size="lg" /> {record.title}
          </h6>
        ));

        Swal.fire({
          icon: "warning",
          title: t("DealDetails_stage_locked_please_fulfill_these_conditions_to_proceed"),
          content: {
            element: "div",
            attributes: {
              innerHTML: element,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onLoadMore = async (record, index) => {
    try {
      const currentTotalRecord = pipeList[index].currentTotalRecord;
      const deals = await getDeals(Number(currentTotalRecord) - 1, record.cs_id, pipeData.value, pipeUser.value);
      if (Number(deals.status) === 0) {
        pipeList[index].customer = [...pipeList[index].customer, ...deals.record];
        pipeList[index].currentTotalRecord += deals.record.length;
        setPipeList([...pipeList]);
      }

      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const openDeals = (record) => {
    window.open(`${session.origin}/deal/${record.deal_id}`, "_blank");
  };

  useEffect(() => {
    getAllPipe();
    getPipeUser();
  }, []);

  useEffect(() => {
    if (Number(dealCache) > 0) {
      getAllPipe();
      setDealCache(0);
    }
  }, [dealCache]);

  // DND KIT SETUP ------------------------------------------------------

  const [activeItem, setActiveItem] = useState(null);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const handleDragEnd = (event) => {
    setActiveItem(null);
    if (Number(event.active.data.current.cs_id) === Number(event.over.id)) return;

    const currentList = [...pipeList];
    const currentItem = event.active.data.current;
    const currentColumnId = event.active.data.current.cs_id;
    const overColumn = event.over.data.current;

    const overColumnIdx = pipeList.findIndex((column) => Number(column.id) === Number(overColumn.id));
    const currentColumnIdx = pipeList.findIndex((column) => Number(column.id) === Number(currentColumnId));
    const currentDealIdx = pipeList[currentColumnIdx].customer.findIndex((column) => Number(column.id) === Number(currentItem.id));

    const movedItem = currentList[currentColumnIdx].customer.splice(currentDealIdx, 1)[0];
    movedItem.cs_id = overColumn.cs_id;
    currentList[overColumnIdx].customer.unshift(movedItem);

    setPipeList(currentList);
    sendMoveStage(event, currentList, overColumnIdx, currentDealIdx, currentColumnIdx, currentColumnId);
  };

  const handleDragStart = (event) => {
    setActiveItem(event.active);
  };

  return (
    <div className="kanban-deal bg-light">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : isNoRecord ? (
        <div className={`d-flex justify-content-center align-items-center`} style={{ height: 500 }}>
          <div className="text-center">
            <FontAwesomeIcon icon={faChartKanban} style={{ "--fa-primary-color": "#183153", "--fa-secondary-color": "#ffd43b", "--fa-secondary-opacity": "1", fontSize: 100 }} size="2xl" />
            <div className="mt-3" style={{ fontSize: 20, fontWeight: "bold" }}>
              No CRM Status found.
            </div>
            <div className="mt-1" style={{ fontSize: 15 }}>
              Create new leads or opportunities and move them through the pipeline to close your deal. Click Add Deal to get started
            </div>
          </div>
        </div>
      ) : (
        <>
          {pipeList.length > 0 ? (
            <DndContext sensors={sensors} collisionDetection={closestCorners} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <div className="kanban-view" id="kanban-view">
                <div className="kanban-wrapper">
                  {pipeList.map((record, index) => (
                    <KanbanColumn key={record.id} record={record} index={index} setCurrency={setCurrency} setDouble={setDouble} pipeList={pipeList} sendMoveStage={sendMoveStage} sendPriority={sendPriority} onLoadMore={onLoadMore} openDeals={openDeals} />
                  ))}
                </div>
                <DragOverlay>
                  {activeItem && (
                    <Card className={`shadow-sm mb-3 cursor-pointer`}>
                      <Card.Header className="py-1 d-flex justify-content-center">
                        <FontAwesomeIcon icon={faGripDotsVertical} rotation={90} size="sm" />
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <OwnerAvatar url={activeItem.data.current.user_avatar_url} user_name={activeItem.data.current.owner_name} />
                          <div className="ms-2 w-100">
                            <h6 className="text-dark mb-0 fw-bold" style={{ fontSize: 13, lineHeight: 1.5, maxWidth: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {activeItem.data.current.deal_title}
                            </h6>
                            {activeItem.data.current.organization_title && (
                              <p className="mb-0 text-muted" style={{ fontSize: 12, lineHeight: 1 }}>
                                {activeItem.data.current.organization_title}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="mt-2 d-flex justify-content-between align-items-center">
                          {activeItem.data.current.deal_value && <p className="mb-0 mt-1">{setCurrency(activeItem.data.current.deal_value, session.default_lang)}</p>}
                          <DealPriority rating={activeItem.data.current.deal_priority} deal_id={activeItem.data.current.deal_id} />
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </DragOverlay>
              </div>
            </DndContext>
          ) : (
            <div className={`d-flex justify-content-center align-items-center`} style={{ height: 500 }}>
              <div className="text-center">
                <FontAwesomeIcon icon={faChartKanban} style={{ "--fa-primary-color": "#183153", "--fa-secondary-color": "#ffd43b", "--fa-secondary-opacity": "1", fontSize: 100 }} size="2xl" />
                <div className="mt-3" style={{ fontSize: 20, fontWeight: "bold" }}>
                  No CRM stages found on this pipeline.
                </div>
                <div className="mt-1" style={{ fontSize: 15 }}>
                  There are currently no CRM stages found in this pipeline. To effectively manage your deals, please create stages for this pipeline to get started.
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DealPipeline;
