import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, Stack, Offcanvas, Card } from "react-bootstrap";
import { AreaChart, Area, PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar } from "recharts";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const ChatbotConversation = () => {
    const gridRef = useRef(null);
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [piechartData, setPiechartData] = useState([]);
    const [listingData, setListingData] = useState([]);
    const [chatInstanceData, setChatInstanceData] = useState([]);
    const [chart1Size, setChart1Size] = useState(6);
    const [chart2Size, setChart2Size] = useState(6);
    const [selectedChatInstance, setSelectedChatInstance] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [recordFound, setRecordFound] = useState("");
    const [selectedConcluded, setSelectedConcluded] = useState([]);

    // START/END DATE
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // YYYY-MM-DD

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0]; // YYYY-MM-DD

    const [dateRange, setdateRange] = useState({
        ds: formattedOneMonthAgo,
        de: formattedToday,
    });

    const options = [
        {
            label: 'Positive',
            value: 'Positive',
        },
        {
            label: 'Negative',
            value: 'Negative',
        },
        {
            label: 'Neutral',
            value: 'Neutral',
        }
    ]

    const concludedOption = [
        {
            label: 'Concluded',
            value: '1'
        },
        {
            label: 'Not Concluded',
            value: '0'
        }
    ]


    const getPiechartData = async (ds, de, instance, sentiment, concluded) => {
        try {
            const res = await axios.get("ws/ws_ai_agent_conversation.php", {
                params: {
                    task: 'getAiAgentDashboard',
                    ds: dateRange.ds || ds,
                    de: dateRange.de || de,
                    cid: instance || 0,
                    sentiment: sentiment || '',
                    concluded: concluded || '',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })

            const data = res.data;
            if (data.status === 0) {
                setPiechartData(data.record);
                setLoading(false);
            }
        } catch {

        }
    }

    const getChatInstanceData = async () => {
        try {
            const res = await axios.get("ws/ws_blueprint2.php", {
                params: {
                    task: 'getChatInstance',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })

            const data = res.data;
            if (data.status === 0) {
                const transformedData = data.record.map(chat => ({
                    label: chat.instance_title,
                    value: chat.chat_instance_id
                }));

                transformedData.unshift({ label: "All Chat Instance", value: "0" });

                setChatInstanceData(transformedData);
                setLoading(false);
            }
        } catch {

        }
    }


    // GRID FUNCTION ------------------------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "65vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const setConcluded = (params) => {
        const concluded = params.data?.conversation_concluded;

        if (concluded == 1) {
            return "Yes";
        } else {
            return "No";
        }
    };

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: 'Prospect Name',
            field: "prospect_name",
            width: 250,
            headerClass: "center",
            cellClass: "center",
            onCellClicked: function (params) {
                if (params.data) {
                    sessionStorage.setItem('op-session_chatbot_conversation', JSON.stringify({
                        user_contact: params.data.user_contact,
                        prospect_id: params.data.prospect_id,
                        prospect_name: params.data.prospect_name,
                        chat_instance_id: params.data.chat_instance_id,
                        chat_contact_id: params.data.ChatContactID,
                        from: 'chatbot-dashboard'
                    }));

                    window.open(`${session.origin}/conversation`, "_blank");

                    sessionStorage.removeItem('op-session_chatbot_conversation');
                }
            }
        },
        {
            headerName: 'Type Of Inquiry',
            field: "conversation_type",
            width: 450,
            headerClass: "center",
            cellClass: "center",
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        {
            headerName: 'Concluded',
            field: "conversation_concluded",
            width: 250,
            headerClass: "center",
            cellClass: "center",
            cellRenderer: setConcluded
        },
        {
            headerName: 'Action',
            field: "conversation_action",
            width: 250,
            headerClass: "center",
            cellClass: "center",
        },
        {
            headerName: 'Date',
            field: "conversation_date",
            width: 200,
            headerClass: "center",
            cellClass: "center",
            sortable: true
        },
        {
            headerName: 'Sentiment',
            field: "conversation_sentiment",
            width: 200,
            headerClass: "center",
            cellClass: "center",
        },
        {
            headerName: 'Summary',
            field: "conversation_summary",
            width: 450,
            headerClass: "center",
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        }
    ];

    const handleExpand = (chart) => {
        if (chart == "chart1") {
            if (chart1Size === 6) {
                setChart1Size(12);
            } else {
                setChart1Size(6);
            }
        } else if (chart == "chart2") {
            if (chart2Size === 6) {
                setChart2Size(12);
            } else {
                setChart2Size(6);
            }
        }
    }

    //  DATE RANGE FILTER
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };


    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getPiechartData();
            getChatInstanceData();
            setLoading(false);
            setLoading2(false);
        }
    }, []);


    const transformedData = useMemo(() => {
        if (piechartData.length > 0) {
            return [
                {
                    name: 'Concluded',
                    value: parseInt(piechartData[0].Concluded, 10),
                    color: '#28a745' // Green for Concluded
                },
                {
                    name: 'Negative',
                    value: parseInt(piechartData[0].Negative, 10),
                    color: '#dc3545' // Red for Negative
                },
                {
                    name: 'Neutral',
                    value: parseInt(piechartData[0].Neutral, 10),
                    color: '#ffc107' // Yellow for Neutral
                },
                {
                    name: 'Not Concluded',
                    value: parseInt(piechartData[0].NotConcluded, 10),
                    color: '#fd7e14' // Orange for Not Concluded
                },
                {
                    name: 'Positive',
                    value: parseInt(piechartData[0].Positive, 10),
                    color: '#007bff' // Blue for Positive
                }
            ];
        }
        return [];
    }, [piechartData]);

    const pieChartData = useMemo(() => {
        if (piechartData.length > 0) {
            return [
                {
                    name: 'Positive',
                    value: parseInt(piechartData[0].Positive, 10),
                    color: '#007bff' // Blue for Positive
                },
                {
                    name: 'Negative',
                    value: parseInt(piechartData[0].Negative, 10),
                    color: '#dc3545' // Red for Negative
                },
                {
                    name: 'Neutral',
                    value: parseInt(piechartData[0].Neutral, 10),
                    color: '#ffc107' // Yellow for Neutral
                },
            ].filter(item => item.value > 0); // Filter out zero values
        }
        return [];
    }, [piechartData]);


    const handleApplyButtonClick = () => {
        setLoading2(true);

        const dateRangeValues = {
            ds: dateRange.ds,
            de: dateRange.de,
        };

        getPiechartData(dateRangeValues.ds, dateRangeValues.de, selectedChatInstance.value, selectedOptions.value, selectedConcluded.value);

        setTimeout(() => {
            setLoading2(false);
        }, 2000);
    };


    const handleDownload = async () => {
        try {

            // Fetch all data using the existing API endpoint
            const allData = [];
            let page = 1;
            const pageSize = 200; // Larger page size for faster fetching
            let hasMoreData = true;

            while (hasMoreData) {
                const res = await axios.get("ws/ws_ai_agent_conversation.php", {
                    params: {
                        task: 'getAIagentConversation',
                        ds: dateRange.ds,
                        de: dateRange.de,
                        cid: selectedChatInstance.value || 0,
                        sentiment: selectedOptions.value || '',
                        concluded: selectedConcluded.value || '',
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        page: page,
                        size: pageSize,
                    }
                });

                if (res.data.status === 0) {
                    const records = res.data.record || [];
                    allData.push(...records);

                    if (records.length < pageSize) {
                        hasMoreData = false;
                    } else {
                        page++;
                    }
                } else {
                    hasMoreData = false;
                }
            }

            if (allData.length === 0) {
                MySwal.fire({
                    title: 'No Data',
                    text: 'No data available to download',
                    icon: 'info'
                });
                return;
            }

            // Filter columns and prepare CSV data
            const columns = gridColumn
                .filter(col => col.field) // Remove columns without field names
                .map(col => ({
                    field: col.field,
                    headerName: col.headerName
                }));

            // Prepare CSV header
            const headers = columns.map(col => col.headerName);

            // Prepare CSV rows
            const csvRows = allData.map(row => {
                return columns.map(col => {
                    let value = row[col.field];

                    // Handle conversation_concluded field
                    if (col.field == "conversation_concluded") {
                        value = value == 1 ? "Yes" : value == 0 ? "No" : "";
                    }

                    if (value === null || value === undefined) {
                        return '';
                    }

                    // Clean the value and wrap in quotes if it contains commas
                    const cleanValue = String(value)
                        .replace(/<br\s*\/?>/g, ' ')
                        .replace(/<[^>]+>/g, '')
                        .replace(/&nbsp;/g, ' ')
                        .trim();
                    return cleanValue.includes(',') ? `"${cleanValue}"` : cleanValue;
                }).join(',');
            });


            // Combine headers and rows
            const csvContent = [headers.join(','), ...csvRows].join('\n');

            // Create and download the file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'chatbot-conversation.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Download error:', error);
            MySwal.fire({
                title: 'Error',
                text: 'Failed to download data',
                icon: 'error'
            });
        }
    };


    // grid data source 

    const gridDataSource = useMemo((ds, de, instance, sentiment) => {
        return {
            rowCount: undefined,
            getRows: (params) => {
                axios
                    .get("ws/ws_ai_agent_conversation.php", {
                        params: {
                            task: 'getAIagentConversation',
                            ds: dateRange.ds || ds,
                            de: dateRange.de || de,
                            page: Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
                            size: params.endRow - params.startRow,
                            cid: selectedChatInstance.value || 0,
                            sentiment: selectedOptions.value || '',
                            concluded: selectedConcluded.value || '',
                            utoken: session.user_ac_token,
                            ctoken: session.company_token
                        }
                    })
                    .then((response) => {
                        const data = response.data;
                        const gridInfo = data.status === 0 ? data.record : [];
                        const totalRecord = Number(data.total_records);

                        // const rowsThisPage = gridInfo;
                        // let lastRow = rowsThisPage.length < totalRecord ? totalRecord : rowsThisPage.length;

                        // params.successCallback(rowsThisPage, lastRow);

                        if (gridInfo.length === 0) {
                            // If there are no records, clear the grid
                            params.api.setRowData([]);  // Clears the grid
                        } else {
                            const rowsThisPage = gridInfo;
                            let lastRow = rowsThisPage.length < totalRecord ? totalRecord : rowsThisPage.length;
                            params.successCallback(rowsThisPage, lastRow);  // Pass the records to the grid
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        params.failCallback(); // Ensure failCallback is called on error
                    });
            },
        };
    }, [dateRange, selectedChatInstance, selectedOptions, session, selectedConcluded]);

    return (
        <div p-0 m-0>
            {loading ? (
                <Loader />
            ) : (
                <div className="mt-3" style={{ height: "calc(100vh - 56px)", overflowY: "auto", overflowX: "clip" }}>
                    <Stack
                        direction="horizontal"
                        style={{
                            padding: '10px 30px'
                        }}
                        gap={4}
                    >
                        <Form.Group className="ms-auto">
                            <FormSelect
                                options={chatInstanceData}
                                valueDefault={selectedChatInstance.value}
                                onChange={(selectedOption) => {
                                    setSelectedChatInstance(selectedOption);
                                }}
                                placeholder="Select Chat Instance"
                                isSearchable
                                isClearable
                            />
                        </Form.Group>

                        <Form.Group>
                            <FormSelect
                                options={concludedOption}
                                valueDefault={selectedConcluded.value}
                                onChange={(selectedOption) => {
                                    setSelectedConcluded(selectedOption);
                                }}
                                placeholder="Select Concluded"
                                isSearchable
                                isClearable
                            />
                        </Form.Group>

                        <Form.Group>
                            <FormSelect
                                options={options}
                                valueDefault={options.value}
                                width="250px"
                                onChange={(selectedOption) => {
                                    setSelectedOptions(selectedOption);
                                }}
                                placeholder="Select Conversation Sentiment"
                                isSearchable
                                isClearable
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="date"
                                placeholder='from'
                                className="shadow-sm border-0"
                                value={dateRange.ds}
                                onChange={(e) => {
                                    onchangeDateRange("ds", e.target.value); // No need for moment here
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="date"
                                placeholder='to'
                                className="shadow-sm border-0"
                                value={dateRange.de}
                                onChange={(e) => {
                                    onchangeDateRange("de", e.target.value); // No need for moment here
                                }}
                            />
                        </Form.Group>

                        <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
                            Apply
                        </Button>
                    </Stack>

                    {loading2 ? (
                        <Loader />
                    ) : (
                        <div>
                            <Row
                                style={{
                                    padding: '10px 30px'
                                }}
                            >
                                <Col sm={chart1Size}>
                                    <Card className="border-0 shadow-sm">
                                        <Card.Header className="d-flex">
                                            <div className="font h6">Conversation Sentiment & Concluded</div>
                                            <div className="ms-auto" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart1') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart data={transformedData}>
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend
                                                        payload={[
                                                            { value: 'Concluded', type: 'square', color: '#28a745' },
                                                            { value: 'Negative', type: 'square', color: '#dc3545' },
                                                            { value: 'Neutral', type: 'square', color: '#ffc107' },
                                                            { value: 'Not Concluded', type: 'square', color: '#fd7e14' },
                                                            { value: 'Positive', type: 'square', color: '#007bff' }
                                                        ]}
                                                    />
                                                    <Bar dataKey="value" fill="#8884d8">
                                                        {transformedData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col sm={chart1Size}>
                                    <Card className="border-0 shadow-sm">
                                        <Card.Header className="d-flex">
                                            <div className="font h6">Conversation Sentiment</div>
                                            <div className="ms-auto" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart2') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <PieChart>
                                                    <Pie
                                                        data={pieChartData}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={100}
                                                        fill="#8884d8"
                                                        label={({ name, value }) => value > 0 ? `${name} - (${value})` : ''}
                                                        labelLine={false}
                                                    >
                                                        {pieChartData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row
                                style={{
                                    padding: '20px 30px 0px'
                                }}
                            >
                                <div>
                                    <button className="btn btn-dark" onClick={handleDownload} disabled={recordFound === 0}>
                                        Download
                                    </button>
                                </div>
                            </Row>

                            <Row
                                style={{
                                    padding: '10px 30px'
                                }}
                            >
                                <Col lg={12}>
                                    <div className="d-flex justify-content-center">
                                        <div style={containerStyle}>
                                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    columnDefs={gridColumn}
                                                    datasource={gridDataSource}
                                                    rowModelType="infinite"
                                                    cacheBlockSize={100}
                                                    cacheOverflowSize={2}
                                                    maxConcurrentDatasourceRequests={2}
                                                    infiniteInitialRowCount={10}
                                                    maxBlocksInCache={2}
                                                    rowHeight={150}
                                                    pagination={true}
                                                    paginationPageSize={100}
                                                    paginationPageSizeSelector={false}
                                                    suppressRowClickSelection={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )};
                </div>
            )};
        </div>
    )
}

export default ChatbotConversation;