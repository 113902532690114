import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Button, OverlayTrigger, Tooltip, Offcanvas, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const SettingBlueprint1Element = ({ blueprintData }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;

    const [isLoading, setIsLoading] = useState(true);
    const [elementData, setElementData] = useState([]);
    const [isAddBlueprintSeq, setisAddBlueprintSeq] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState([]);


    // Validation Schema
    const validationSchema = yup.object().shape({
        message: yup.string().required("Message is required"),
    });

    const method = [
        { value: 'whatsapp', label: 'Whatsapp' },
        { value: 'sms', label: 'SMS' },
    ];


    const getListingData = async () => {
        try {
            const res = await axios.get(`ws/ws_blueprint_setting.php`, {
                params: {
                    task: "blueprint1ElementList",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    blueprint_id: blueprintData.blueprint_id
                }
            });

            let data = res.data;
            console.log(data.record)

            if (data.status === 0) {
                setElementData(data.record);
            }
        } catch (error) {
            setElementData([]);
        } finally {
            setIsLoading(false);
        }
    };


    // ADD FUNCTION ---------------------------------------------------------


    const handleSubmitForm = async (values) => {
        try {
            const maxSequenceIndex = elementData.reduce((max, item) => {
                const currentSequence = parseInt(item.sequence_index, 10);
                return currentSequence > max ? currentSequence : max;
            }, -1); // Default to -1 if no elements are found

            // Increment the maxSequenceIndex by 1
            const newSequenceIndex = maxSequenceIndex + 1;

            axios.get("ws/ws_blueprint_setting.php", {
                params: {
                    task: 'setBlueprint1Sequence',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    blueprint_id: blueprintData.blueprint_id,
                    message: values.message,
                    index: newSequenceIndex,
                    days: 0,
                    minutes: 0,
                    method: values.method,
                    template_name: values.template_name,
                    template_params: values.template_params,
                    template_image: values.template_image,
                    ai_prompt: values.ai_prompt
                }
            }).then((res) => {
                if (res.data.status === 0) {
                    setisAddBlueprintSeq(false);
                    getListingData();

                    MySwal.fire({
                        icon: 'success',
                        title: 'Successfully Added',
                        text: 'Record has been added successfully',
                        timer: 1500
                    });
                } else {
                    console.error(res.data.message);
                }
            })
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // UPDATE FUNCTION ----------------------------------------------------

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get(`ws/ws_blueprint_setting.php`, {
                params: {
                    task: "updateBlueprint1Sequence",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    blueprint_sequence_id: values.blueprint_sequence_id,
                    blueprint_id: values.blueprint_id,
                    index: values.sequence_index,
                    message: values.message,
                    method: values.method,
                    template_image: values.template_image || '',
                    template_name: values.template_name || '',
                    template_params: values.template_params || '',
                    ai_prompt: values.ai_prompt || ''
                }
            });

            if (response.data.status === 0) {
                setShowModal(false);
                getListingData();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Message",
            field: "message",
            width: 400,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'auto', 'line-height': '1.2' },
        },
        {
            headerName: "Message Method",
            field: "message_method",
            width: 200,
        },
        {
            headerName: "Whatsapp Template Header Image",
            field: "whatsapp_template_header_image",
            width: 400,
        },
        {
            headerName: "Whatsapp Template Name",
            field: "whatsapp_template_name",
            width: 300,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'auto', 'line-height': '1.2' },
        },
        {
            headerName: "Whatsapp Template Params",
            field: "whatsapp_template_params",
            width: 250,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'auto', 'line-height': '1.2' },
        },
        {
            headerName: "AI Prompt",
            field: "ai_prompt",
            width: 250,
        },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };


    const handleEditClick = (params) => {
        console.log(params)
        setCurrentData(params);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const toggleAddSequenceModal = () => {
        setisAddBlueprintSeq(prevState => !prevState);
    };


    // USE EFFECT ----------------------------------------------------------

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getListingData();
        }
    }, []);


    return (
        <div>
            <Container fluid className="p-0 m-0">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="p-4">
                        <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddSequenceModal} >
                            <FontAwesomeIcon icon={faPlus} className="me-2" /> Blueprint
                        </button>

                        <Row>
                            <Col xxl={12}>
                                <div style={containerStyle}>
                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            columnDefs={gridColumn}
                                            rowData={isLoading ? [] : elementData}
                                            rowHeight={150}
                                            pagination={true}
                                            onCellClicked={onCellClicked}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* Add function */}

                        <Modal show={isAddBlueprintSeq} onHide={toggleAddSequenceModal}>
                            <Formik
                                initialValues={{
                                    message: "",
                                    method: "",
                                    template_name: "",
                                    template_params: "",
                                    template_image: "",
                                    ai_prompt: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmitForm}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title className="op-text-bigger">Add Blueprint</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Message
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="message"
                                                    placeholder="Please enter a message"
                                                    isInvalid={touched.message && !!errors.message}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.message}
                                                </Form.Control.Feedback>
                                                {/* {errors.message && touched.message && <div className="op-error-message">{errors.message}</div>} */}
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Message Method</Form.Label>
                                                <FormSelect
                                                    options={method}
                                                    placeholder="Please choose a method"
                                                    isSearchable
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        handleChange("method")(e.value)
                                                    }} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_name")}
                                                    name="template_name"
                                                    placeholder="Please enter a template name"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Params
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_params")}
                                                    name="template_params"
                                                    placeholder="Please enter a template params"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Header Image
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_image")}
                                                    name="template_image"
                                                    placeholder="Please enter a template image"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    AI Prompt
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("ai_prompt")}
                                                    name="ai_prompt"
                                                    placeholder="Please enter ai prompt"
                                                />
                                            </Form.Group>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                                Submit
                                            </button>
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        {/* Edit function */}

                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Formik
                                initialValues={{
                                    blueprint_sequence_id: currentData ? currentData.blueprint_sequence_id : "",
                                    blueprint_id: currentData ? currentData.blueprint_id : "",
                                    sequence_index: currentData ? currentData.sequence_index : "",
                                    message: currentData ? currentData.message : "",
                                    method: currentData ? currentData.message_method : "",
                                    template_name: currentData ? currentData.whatsapp_template_name : "",
                                    template_params: currentData ? currentData.whatsapp_template_params : "",
                                    template_image: currentData ? currentData.whatsapp_template_header_image : "",
                                    ai_prompt: currentData ? currentData.ai_prompt : ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleUpdate}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title className="op-text-bigger">Edit Blueprint</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Message
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="message"
                                                    value={values.message}
                                                    placeholder="Please enter a message"
                                                    isInvalid={touched.message && !!errors.message}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Message Method</Form.Label>
                                                <FormSelect
                                                    options={method}
                                                    placeholder="Please choose a method"
                                                    isSearchable
                                                    valueDefault={
                                                        values.method !== null && values.method !== undefined && values.method !== ""
                                                            ? {
                                                                label: method.find(item => item.value === values.method)?.label || '',
                                                                value: values.method
                                                            }
                                                            : ""
                                                    }
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        handleChange("method")(e.value)
                                                    }} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_name")}
                                                    name="template_name"
                                                    value={values.template_name}
                                                    placeholder="Please enter a template name"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Params
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_params")}
                                                    name="template_params"
                                                    value={values.template_params}
                                                    placeholder="Please enter a template params"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Whatsapp Template Header Image
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("template_image")}
                                                    name="template_image"
                                                    value={values.template_image}
                                                    placeholder="Please enter a template image"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    AI Prompt
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChange("ai_prompt")}
                                                    name="ai_prompt"
                                                    value={values.ai_prompt}
                                                    placeholder="Please enter ai prompt"
                                                />
                                            </Form.Group>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                                Submit
                                            </button>
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                    </div>
                )}
            </Container>
        </div>
    )

}

export default SettingBlueprint1Element;