import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "./AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { changePassword, logoutUser } = useAuth();
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .max(25, "Password cannot exceed 25 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[\W_]/, "Password must contain at least one special character"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your new password"),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await changePassword(values);
      if (response) {
        await logoutUser();
        onClose();
        Swal.fire({
          icon: "success",
          text: "Your new password has been changed",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "warning",
          text: "The current password does not match!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Formik initialValues={{ currentPassword: "", currentPasswordShow: false, newPassword: "", newPasswordShow: false, confirmPassword: "", confirmPasswordShow: false }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as="h6">{t("Container_change_password")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3 position-relative">
                <Form.Label>{t("ChangePassword_current_password")}</Form.Label>
                <Form.Control type={values.currentPasswordShow ? "text" : "password"} value={values.currentPassword} onChange={handleChange("currentPassword")} />
                <Button variant="link" className="text-decoration-none text-dark m-0 p-0" style={{ position: "absolute", top: 28, right: 10, cursor: "pointer" }} onClick={() => setFieldValue("currentPasswordShow", !values.currentPasswordShow)}>
                  {values.currentPasswordShow ? <FontAwesomeIcon icon={faEye} size="xl" /> : <FontAwesomeIcon icon={faEyeSlash} size="xl" />}
                </Button>
                {errors.currentPassword && touched.currentPassword && <div className="op-error-message">{errors.currentPassword}</div>}
              </Form.Group>

              <Form.Group className="mb-3 position-relative">
                <Form.Label>{t("ChangePassword_new_password")}</Form.Label>
                <Form.Control type={values.newPasswordShow ? "text" : "password"} value={values.newPassword} onChange={handleChange("newPassword")} />
                <Button variant="link" className="text-decoration-none text-dark m-0 p-0" style={{ position: "absolute", top: 28, right: 10, cursor: "pointer" }} onClick={() => setFieldValue("newPasswordShow", !values.newPasswordShow)}>
                  {values.newPasswordShow ? <FontAwesomeIcon icon={faEye} size="xl" /> : <FontAwesomeIcon icon={faEyeSlash} size="xl" />}
                </Button>
                {errors.newPassword && touched.newPassword && <div className="op-error-message">{errors.newPassword}</div>}
              </Form.Group>

              <Form.Group className="mb-3 position-relative">
                <Form.Label>{t("ChangePassword_confirm_password")}</Form.Label>
                <Form.Control type={values.confirmPasswordShow ? "text" : "password"} value={values.confirmPassword} onChange={handleChange("confirmPassword")} />
                <Button variant="link" className="text-decoration-none text-dark m-0 p-0" style={{ position: "absolute", top: 28, right: 10, cursor: "pointer" }} onClick={() => setFieldValue("confirmPasswordShow", !values.confirmPasswordShow)}>
                  {values.confirmPasswordShow ? <FontAwesomeIcon icon={faEye} size="xl" /> : <FontAwesomeIcon icon={faEyeSlash} size="xl" />}
                </Button>
                {errors.confirmPassword && touched.confirmPassword && <div className="op-error-message">{errors.confirmPassword}</div>}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="" className="op-primary-color text-light shadow-sm" onClick={handleSubmit}>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
