import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup, Nav } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";
import { faArrowRightToArc, faBuilding, faDollarCircle, faHouse, faPerson, faUser, faUserCheck } from "@fortawesome/pro-light-svg-icons";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const Users = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/users" || location.pathname === "/users/") {
      navigate("/users/list", { replace: true });
    }
  }, [navigate, location]);

  useEffect(() => {
    if (!init.current) {
      setLoading(false);
      init.current = true;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid className="p-4">
          <h5 className="mt-2 mb-1">Manage Users</h5>
          <p className="mb-3">This page allows you to efficiently manage your company's user base by adding new users, assigning appropriate permissions, and overseeing user login redirection settings.</p>
          <Nav variant="underline" defaultActiveKey="deal" style={{ borderBottom: "1px solid #ddd" }}>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/users/list" activeclassname="active">
                <FontAwesomeIcon icon={faUser} className="me-2" size="lg" />
                Users and access
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/users/permission" activeclassname="active">
                <FontAwesomeIcon icon={faUserCheck} className="me-2" size="lg" />
                Permission
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/users/user-login-redirection" activeclassname="active">
                <FontAwesomeIcon icon={faArrowRightToArc} className="me-2" size="lg" />
                User Login Redirection
              </NavLink>
            </Nav.Item>
          </Nav>
          <div className="mt-3">
            <Outlet />
          </div>
        </Container>
      )}
    </div>
  );
};

export default Users;
