import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Button, Form, InputGroup } from "react-bootstrap";
import { usePopper } from "react-popper";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async-creatable";
import debounce from "lodash/debounce";
import "react-day-picker/dist/style.css";
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";
import { faBroomWide, faPencilAlt, faXmark } from "@fortawesome/pro-light-svg-icons";
import { countryCurrencyMap, setCurrency } from "../lib/js/Function";
import moment from "moment";

export const FormSelect = ({
  options,
  name,
  valueDefault = null,
  onChange,
  className,
  placeholder = "",
  width = "100%",
  isInvalid = false,
  isSearchable = false,
  isClearable = false,
  disabled = false,
  isOptionDisabled = false,
  closeMenuOnSelect = true,
  shadow = false,
  border = true,
  borderLeft = false,
  borderRight = false,
  isMulti = false,
}) => {
  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "1px 0",
      minHeight: "12px",
      width: width,
      fontSize: "12px",
      borderRadius: "0.375rem",
      borderTopRightRadius: borderRight ? "0px" : "0.375rem",
      borderBottomRightRadius: borderRight ? "0px" : "0.375rem",
      borderTopLeftRadius: borderLeft ? "0px" : "0.375rem",
      borderBottomLeftRadius: borderLeft ? "0px" : "0.375rem",
      border: border ? (state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : "1px solid #dee2e6") : "none",
      boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
      "&:hover": {
        backgroundolor: "var(--op-primary-hover-color)",
        border: "1px solid hsl(0, 0%, 80%)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginRight: "5px",
    }),
    options: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} />
    </components.DropdownIndicator>
  );

  const onInputChange = (event) => {
    if ((event && event.label !== undefined && event.label !== null && event.value !== undefined && event.value !== null) || (isMulti && event.length > 0)) {
      onChange(event);
    } else {
      onChange(!isMulti ? { label: "", value: "" } : "");
    }
  };

  return (
    <div name={name}>
      <Select
        className={className + (shadow ? " shadow-sm" : "")}
        defaultValue={valueDefault}
        options={options}
        styles={selectStyles}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={(options) => (isOptionDisabled ? options.disabled : null)}
        placeholder={placeholder}
        onChange={onInputChange}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isDisabled={disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--op-primary-hover-color)",
            primary: "var(--op-primary-color)",
          },
        })}
      />
    </div>
  );
};

export const FormSelectV2 = ({
  options,
  name,
  value = "",
  onChange,
  className,
  placeholder = "",
  width = "100%",
  isInvalid = false,
  isSearchable = false,
  isClearable = false,
  disabled = false,
  isOptionDisabled = false,
  closeMenuOnSelect = true,
  shadow = false,
  border = true,
  borderLeft = false,
  borderRight = false,
  isMulti = false,
}) => {
  const selectRef = useRef(null);

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "1px 0",
      minHeight: "12px",
      width: width,
      fontSize: "12px",
      borderRadius: "0.375rem",
      borderTopRightRadius: borderRight ? "0px" : "0.375rem",
      borderBottomRightRadius: borderRight ? "0px" : "0.375rem",
      borderTopLeftRadius: borderLeft ? "0px" : "0.375rem",
      borderBottomLeftRadius: borderLeft ? "0px" : "0.375rem",
      border: border ? (state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : "1px solid #dee2e6") : "none",
      boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
      "&:hover": {
        backgroundolor: "var(--op-primary-hover-color)",
        border: "1px solid hsl(0, 0%, 80%)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginRight: "5px",
    }),
    options: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} />
    </components.DropdownIndicator>
  );

  const onInputChange = (event) => {
    if ((event && event.label !== undefined && event.label !== null && event.value !== undefined && event.value !== null) || (isMulti && event.length > 0)) {
      onChange(event);
    } else {
      onChange(!isMulti ? { label: "", value: "" } : "");
    }
  };

  return (
    <div name={name}>
      <Select
        ref={selectRef}
        className={className + (shadow ? " shadow-sm" : "")}
        value={value}
        options={options}
        styles={selectStyles}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={(options) => (isOptionDisabled ? options.disabled : null)}
        placeholder={placeholder}
        onChange={onInputChange}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isDisabled={disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--op-primary-hover-color)",
            primary: "var(--op-primary-color)",
          },
        })}
      />
    </div>
  );
};

export const FormSelectCreatable = ({ className, onChange, name, value = "", withValue = false, loadOptions, placeholder = "", width = "100%", isClearable = false, isInvalid = false, shadow = false, disabled = false }) => {
  const [inputValue, setInputValue] = useState("");
  const debouncedLoadOptions = inputValue ? debounce(loadOptions, 1000) : null;
  const [isEdit, setIsEdit] = useState(false);

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "10px",
      fontSize: "12px",
      width: width,
      borderRadius: "0.375rem",
      border: state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : provided.border,
      boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
      "&:hover": {
        border: "1px solid hsl(0, 0%, 80%)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginRight: "5px",
    }),
    options: (provided) => ({
      ...provided,
      zIndex: 4,
    }),
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const onInputChange = (event) => {
    if (event && (event.label || event.value)) {
      onChange(event);
    } else {
      onChange({ label: "", value: "", customLabel: "" });
    }

    if (withValue) {
      setIsEdit(false);
    }
  };

  const formatOptionLabel = (option, { context }) => {
    if (option) {
      if (Number(option.value) === 0) {
        if (context === "value") {
          return option.label;
        }
        return option.customLabel;
      } else {
        return option.label;
      }
    }
    return null;
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.value === 0 ? <div className="op-label badge position-absolute end-0 me-2 bg-primary text-light">New</div> : null}
      {children}
    </components.SingleValue>
  );

  const renderAsyncSelect = () => (
    <AsyncSelect
      noOptionsMessage={() => (inputValue.trim() === "" ? "Type to search" : "No results found")}
      className={`${className} ${shadow ? "shadow-sm" : ""} w-100 me-2`}
      cacheOptions
      defaultOptions={false}
      isClearable={isClearable}
      name={name}
      formatOptionLabel={formatOptionLabel}
      allowCreateWhileLoading={false}
      isValidNewOption={() => false}
      loadOptions={debouncedLoadOptions}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        SingleValue,
      }}
      styles={selectStyles}
      placeholder={placeholder}
      onChange={onInputChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isDisabled={disabled}
      menuIsOpen={inputValue.trim().length > 0} // Show menu only when input has value
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "var(--op-primary-hover-color)",
          primary: "var(--op-primary-color)",
          primary50: "var(--op-primary-hover-color)",
        },
      })}
    />
  );

  if (withValue) {
    if (isEdit) {
      return (
        <div className="d-flex align-items-center w-100">
          {renderAsyncSelect()}
          <Button variant="light" onClick={() => setIsEdit(false)} className="border" disabled={disabled}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center w-100">
          <Form.Control type="text" value={value} className="me-2" onChange={onInputChange} style={{ pointerEvents: "none" }} disabled={disabled} />
          <Button variant="light" onClick={() => setIsEdit(true)} className="border" disabled={disabled}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        </div>
      );
    }
  } else {
    return renderAsyncSelect();
  }
};

export const FormDate = ({ onChange, name, isClearable = false, placeholder, value = "", className, fromYear = 2000, toYear = 2040 }) => {
  const [inputValue, setInputValue] = useState(value ? moment(new Date(value)).format("YYYY-MM-DD") : "");
  return (
    <Form.Control
      name={name}
      placeholder={placeholder}
      className={className}
      type="date"
      value={inputValue ? inputValue : ""}
      onChange={(e) => {
        setInputValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

export const FormCustomField = ({ id, className, label, options, type, onChange, placeholder, name, isInvalid, firstOptions = true, isClearable = false, valueDefault = false, value, error = "" }) => {
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const optionsArr = JSON.parse(options);
      const optionsRecord = Object.values(optionsArr).map((record) => ({
        label: record,
        value: record,
      }));

      if (type === "Select" && firstOptions) {
        optionsRecord.unshift({
          label: "Select " + label,
          value: "",
        });
      }

      setOptionList(optionsRecord);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [options]);

  // TEXT AND TEXTAREA VALUE ---------------------------------------

  const [inputValue, setInputValue] = useState(value);
  const inputOnChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  // DATE VALUE ----------------------------------------------------

  const [inputDateValue, setInputDateValue] = useState(value ? value : "");
  const inputDateOnChange = (e) => {
    setInputDateValue(e.target.value);
    onChange(e.target.value);
  };

  // CHECKBOX CHECKED ----------------------------------------------

  const onCheckedInput = (optionValue) => {
    if (value.length > 0) {
      return value.includes(optionValue);
    } else {
      return false;
    }
  };

  // RADIO CHECKED -------------------------------------------------

  const onCheckedRadio = (optionValue) => {
    // const recordOptions
  };

  const renderCustomField = () => {
    switch (type) {
      case "Text":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} type="text" value={inputValue} onChange={inputOnChange} isInvalid={isInvalid} />
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      case "Textarea":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} as="textarea" value={inputValue} onChange={inputOnChange} rows={3} isInvalid={isInvalid} />
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      case "Date":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control type="date" value={inputDateValue} onChange={inputDateOnChange} isInvalid={isInvalid} />
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      case "Radio":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label className="d-flex">
              <span style={{ width: value ? "95%" : "100%" }}>{label}</span>
              {value && (
                <div>
                  <Button variant="light" className="border" size="sm" style={{ fontSize: 11 }} onClick={() => onChange("")}>
                    <FontAwesomeIcon icon={faBroomWide} />
                  </Button>
                </div>
              )}
            </Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={index} type="radio" label={option.label} name={name} value={option.value} onChange={onChange} isInvalid={isInvalid} checked={value && option.value === value ? true : false} />
            ))}
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      case "Checkbox":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={`${id}-${type}-${index}`} type={type.toLowerCase()} label={option.label} name={name} value={option.value} onChange={onChange} isInvalid={isInvalid} checked={onCheckedInput(option.value)} />
            ))}
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      case "Select":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <FormSelectV2 options={optionList} isClearable={isClearable} value={value ? optionList.find((record) => record.value === value) : valueDefault ? optionList[0] : ""} name={name} onChange={onChange} placeholder={placeholder} isInvalid={isInvalid} />
            {error && <div className="op-error-message">{error}</div>}
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return <>{!loading && renderCustomField()}</>;
};

export const NewFormCustomField = ({ id, className, label, options, type, onChange, placeholder, name, isInvalid, firstOptions = true, isClearable = false, valueDefault = false, value }) => {
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const optionsArr = JSON.parse(options);
      const optionsRecord = Object.values(optionsArr).map((record) => ({
        label: record,
        value: record,
      }));

      if (type === "Select" && firstOptions) {
        optionsRecord.unshift({
          label: "Select " + label,
          value: "",
        });
      }

      setOptionList(optionsRecord);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [options]);

  // TEXT AND TEXTAREA VALUE ---------------------------------------

  const [inputValue, setInputValue] = useState(value);
  const inputOnChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  // DATE VALUE ----------------------------------------------------

  const [inputDateValue, setInputDateValue] = useState(value ? new Date(value) : "");
  const inputDateOnChange = (e) => {
    setInputDateValue(e);
    onChange(e);
  };

  // CHECKBOX CHECKED ----------------------------------------------
  // RADIO CHECKED -------------------------------------------------

  const onCheckedInput = (optionValue) => {
    if (value.length > 0) {
      return value.includes(optionValue);
    } else {
      return false;
    }
  };

  const onCheckedRadio = (optionValue) => {
    // const recordOptions
  };

  const [selectedRadio, setSelectedRadio] = useState(value);

  const onRadioChange = (value) => {
    setSelectedRadio(value);
    onChange({ target: { name, value } });
  };

  const renderCustomField = () => {
    switch (type) {
      case "Text":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} type="text" value={inputValue} onChange={inputOnChange} isInvalid={isInvalid} />
          </Form.Group>
        );
      case "Textarea":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} as="textarea" value={inputValue} onChange={inputOnChange} rows={3} isInvalid={isInvalid} />
          </Form.Group>
        );
      case "Date":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <FormDate value={inputDateValue} isClearable={true} onChange={inputDateOnChange} />
          </Form.Group>
        );
      case "Radio":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={index} type="radio" label={option.label} name={name} value={option.value} onChange={() => onRadioChange(option.value)} isInvalid={isInvalid} checked={selectedRadio === option.value} />
            ))}
          </Form.Group>
        );
      case "Checkbox":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={`${id}-${type}-${index}`} type={type.toLowerCase()} label={option.label} name={name} value={option.value} onChange={() => onRadioChange(option.value)} isInvalid={isInvalid} checked={selectedRadio === option.value} />
            ))}
          </Form.Group>
        );
      case "Select":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <FormSelect options={optionList} isClearable={isClearable} valueDefault={value ? optionList.find((record) => record.value === value) : valueDefault ? optionList[0] : ""} name={name} onChange={onChange} placeholder={placeholder} isInvalid={isInvalid} />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return <>{!loading && renderCustomField()}</>;
};

export const FormDate2 = ({ onChange, name, placeholder, value = "", className }) => {
  const [inputValue, setInputValue] = useState(value);

  const onchangeDate = (e) => {
    onChange(e.target.value);
    setInputValue(e.target.value);
  };

  return (
    <Form.Group name={name}>
      <Form.Control type="date" value={inputValue} placeholder={placeholder} onChange={onchangeDate} style={{ border: "1px solid #dee2e6", borderRight: 0, boxShadow: "none" }} />
    </Form.Group>
  );
};

export const FormCurrency = ({ onChange, name, placeholder, disabled = false, value = "", className, currency = "MY" }) => {
  const [inputValue, setInputValue] = useState(setCurrency(value, currency));

  const onChangeText = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length > 0) {
      inputValue = (parseFloat(inputValue, 10) / 100).toFixed(2);
    } else {
      inputValue = "0.00";
    }

    setInputValue(setCurrency(inputValue, currency));
    onChange(inputValue);
  };

  return (
    <Form.Group name={name}>
      <Form.Control type="text" className={className} value={inputValue} placeholder={placeholder} onChange={onChangeText} disabled={disabled} />
    </Form.Group>
  );
};

// export const FormDate = ({ onChange, name, isClearable = false, placeholder, value = "", className, fromYear = 2000, toYear = 2040 }) => {
//   const [inputValue, setInputValue] = useState(value);
//   const [pickerValue, setPickerValue] = useState(value ? new Date(value) : "");
//   const [isOpen, setIsOpen] = useState(false);
//   const inputRef = useRef();
//   const popperRef = useRef();

//   const [popperElement, setPopperElement] = useState(null);

//   const { styles, attributes } = usePopper(popperRef.current, popperElement, {
//     placement: "bottom-start",
//   });

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (popperRef.current && !popperRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [popperRef]);

//   const onChangePicker = (date) => {
//     setPickerValue(date);
//     if (date) {
//       setInputValue(format(date, "dd/MM/y"));
//       setIsOpen(false);
//       onChange(format(date, "y-MM-dd"));
//     } else {
//       setInputValue("");
//     }
//   };

//   const clearDate = () => {
//     setInputValue("");
//     setPickerValue("");
//     onChange("");
//   };

//   return (
//     <Form.Group ref={popperRef} name={name}>
//       <InputGroup onClick={setIsOpen} className={className}>
//         <Form.Control type="text" value={inputValue} ref={inputRef} placeholder={placeholder} style={{ border: "1px solid #dee2e6", borderRight: 0, boxShadow: "none" }} readOnly />
//         {isClearable ? (
//           <InputGroup.Text style={{ borderLeft: 0, backgroundColor: "#fff" }}>{pickerValue ? <FontAwesomeIcon icon={faCircleXmark} size="lg" onClick={clearDate} style={{ cursor: "pointer", zIndex: 3 }} /> : <FontAwesomeIcon icon={faCalendar} />}</InputGroup.Text>
//         ) : (
//           <InputGroup.Text style={{ borderLeft: 0, backgroundColor: "#fff" }}>
//             <FontAwesomeIcon icon={faCalendar} />
//           </InputGroup.Text>
//         )}
//       </InputGroup>

//       {isOpen && (
//         <div tabIndex={-1} className="dialog-sheet" role="dialog" ref={setPopperElement} style={{ ...styles.popper, backgroundColor: "white", border: "1px solid #dee2e6", zIndex: 100, boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", margin: "8px 0", borderRadius: 8 }} {...attributes.popper}>
//           <DayPicker style={{ color: "black" }} initialFocus={isOpen} mode="single" captionLayout="dropdown-buttons" fromYear={fromYear} toYear={toYear} numberOfMonths={2} value={value} onSelect={onChangePicker} />
//         </div>
//       )}
//     </Form.Group>
//   );
// };

// export const FormSelectCreatable = ({ className, onChange, name, value = "", withValue = false, loadOptions, placeholder = "", width = "100%", isClearable = false, isInvalid = false, shadow = false, isLoading = false }) => {
//   const [inputValue, setInputValue] = useState("");
//   const debouncedLoadOptions = inputValue ? debounce(loadOptions, 1000) : null;
//   const [isEdit, setIsEdit] = useState(false);

//   const selectStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       minHeight: "10px",
//       fontSize: "12px",
//       width: width,
//       borderRadius: "0.375rem",
//       border: state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : provided.border,
//       boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
//       "&:hover": {
//         border: "1px solid hsl(0, 0%, 80%)",
//       },
//     }),
//     dropdownIndicator: (provided) => ({
//       ...provided,
//       fontSize: "12px",
//       marginRight: "5px",
//     }),
//     options: (provided) => ({
//       ...provided,
//       zIndex: 4,
//     }),
//   };

//   const handleInputChange = (value) => {
//     setInputValue(value);
//   };

//   const onInputChange = (event) => {
//     if ((event && event.label !== undefined) || event.label !== null || event.value !== undefined || event.value !== null) {
//       onChange(event);
//     } else {
//       onChange({ label: "", value: "", customLabel: "" });
//     }

//     if (withValue) {
//       setIsEdit(false);
//     }
//   };

//   const formatOptionLabel = (option, { context }) => {
//     if (option && option.value !== undefined && option.label !== undefined) {
//       if (Number(option.value) === 0) {
//         if (context === "value") {
//           return option.label;
//         }
//         return option.customLabel;
//       } else {
//         return option.label;
//       }
//     }
//     return null;
//   };

//   const SingleValue = ({ children, ...props }) => {
//     return (
//       <components.SingleValue {...props}>
//         {props.data.value === 0 ? <div className="op-label badge position-absolute end-0 me-2 bg-primary text-light">New</div> : null}
//         {children}
//       </components.SingleValue>
//     );
//   };

//   if (withValue) {
//     if (isEdit) {
//       return (
//         <div className="d-flex align-items-center w-100">
//           <AsyncSelect
//             noOptionsMessage={() => (inputValue.trim() === "" ? "Type to search" : "No results found")}
//             defaultMenuIsOpen={false}
//             menuIsOpen={inputValue ? true : false}
//             className={`${className} ${shadow ? "shadow-sm" : ""} w-100 me-2`}
//             cacheOptions
//             defaultOptions={false}
//             isClearable={isClearable}
//             name={name}
//             formatOptionLabel={formatOptionLabel}
//             allowCreateWhileLoading={false}
//             isValidNewOption={() => false}
//             loadOptions={debouncedLoadOptions}
//             components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, SingleValue }}
//             styles={selectStyles}
//             placeholder={placeholder}
//             onChange={onInputChange}
//             inputValue={inputValue}
//             onInputChange={handleInputChange}
//             theme={(theme) => ({
//               ...theme,
//               borderRadius: 0,
//               colors: {
//                 ...theme.colors,
//                 primary25: "var(--op-primary-hover-color)",
//                 primary: "var(--op-primary-color)",
//                 primary50: "var(--op-primary-hover-color)",
//               },
//             })}
//           />

//           <Button variant="light" onClick={() => setIsEdit(false)} className="border">
//             <FontAwesomeIcon icon={faXmark} />
//           </Button>
//         </div>
//       );
//     } else {
//       return (
//         <div className="d-flex align-items-center w-100">
//           <Form.Control pointer-events="none" type="text" value={value} className="me-2" onChange={onInputChange} style={{ pointerEvents: "none" }} />
//           <Button variant="light" onClick={() => setIsEdit(true)} className="border">
//             <FontAwesomeIcon icon={faPencilAlt} />
//           </Button>
//         </div>
//       );
//     }
//   } else {
//     return (
//       <AsyncSelect
//         className={className + (shadow ? "shadow-sm" : "")}
//         cacheOptions
//         defaultOptions
//         isClearable={isClearable}
//         name={name}
//         formatOptionLabel={formatOptionLabel}
//         allowCreateWhileLoading={false}
//         isValidNewOption={() => false}
//         loadOptions={debouncedLoadOptions}
//         components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, SingleValue }}
//         styles={selectStyles}
//         placeholder={placeholder}
//         onChange={onInputChange}
//         onInputChange={handleInputChange}
//         theme={(theme) => ({
//           ...theme,
//           borderRadius: 0,
//           colors: {
//             ...theme.colors,
//             primary25: "var(--op-primary-hover-color)",
//             primary: "var(--op-primary-color)",
//             primary50: "var(--op-primary-hover-color)",
//           },
//         })}
//       />
//     );
//   }
// };
